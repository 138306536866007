import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { IComponentProps } from './CoachItem.connector';

export class CoachItem extends React.Component<IComponentProps> {

  public render() {
    const { coach } = this.props;

    return (
      <ListItem button onClick={ this.onSelect}>
        <ListItemText>{coach?.personMatchName}</ListItemText>
      </ListItem>
    );
  }

  private onSelect = () => {
    const {
      side,
      coach,
      chooseCoach
    } = this.props;
    chooseCoach(side,coach)
  };
}
