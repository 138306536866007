import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClubIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(2.75 1.29)">
        <polygon points="9.241 3.26 15.154 3.26 15.154 13.129 9.241 17.211" />
        <path d="M0,-0.000110798547 L0,14.8188257 L9.24131633,21.2022994 L18.4871633,14.8188257 L18.4871633,-0.000110798547 L0,-0.000110798547 Z M2.11881633,13.7451877 L2.11881633,2.07219138 L16.3683469,2.07219138 L16.3683469,13.7448184 L9.24131633,18.6653819 L2.11881633,13.7451877 Z" />
      </g>
    </SvgIcon>
  );
}
