import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Form from '../../transfers/Detail/Form';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

@injectIntl
export default class Transfer extends Component {
  render() {
    const {
      transfer,
      onChange,
      intl: { messages: m },
    } = this.props;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_transferInfo}</Typography>
        </Toolbar>
        {transfer && <Form onSubmit={onChange} data={transfer} />}
      </div>
    );
  }
}
