import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import Layout from '../../common/components/Layout';
import { hasAccessToRestrictedArticles } from '../../common/services/me';
import { getProjectNameFromPath } from '../../common/services/project';
import { TableCreated } from '../articles/components/TableCreated';
import { TableFixed } from '../articles/components/TableFixed';
import { TablePostPublished } from '../articles/components/TablePostPublished';
import { TablePublished } from '../articles/components/TablePublished';
import TopBar from '../components/TopBar';
import { IComponentProps } from './Dashboard.connector';

export class Dashboard extends Component<IComponentProps> {
  public componentDidMount() {
    this.fetchData();
  }

  private fetchData = () => {
    this.props.fetchNewestArticles();
    this.props.fetchOwnPrewrittenArticles();
    this.props.fetchOwnInProgressArticles();
    this.props.fetchOwnPostPublishedArticles();
    this.props.fetchProposalArticles();
    this.props.fetchProposalLiveTransfers();
    this.props.fetchFixedArticles();
    this.props.fetchTemplateArticles();
    if (hasAccessToRestrictedArticles()) {
      this.props.fetchRestrictedArticles();
    }
  };

  public componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (getProjectNameFromPath(prevProps.location.pathname) !== getProjectNameFromPath(location.pathname)) {
      this.fetchData();
    }
  }

  private paginateNewestArticles = query => {
    this.props.fetchNewestArticles(query);
  };

  private paginateInprogressArticles = query => {
    this.props.fetchOwnInProgressArticles(query);
  };

  private paginatePrewrittenArticles = query => {
    this.props.fetchOwnPrewrittenArticles(query);
  };

  private paginateFixedArticles = query => {
    this.props.fetchFixedArticles(query);
  };

  private paginateProposalArticles = query => {
    this.props.fetchProposalArticles(query);
  };

  private paginateProposalBriefs = query => {
    this.props.fetchProposalLiveTransfers(query);
  };

  private paginateTemplateArticles = query => {
    this.props.fetchTemplateArticles(query);
  };

  private paginateRestrictedArticles = query => {
    this.props.fetchRestrictedArticles(query);
  };

  private showCategory = type => (type.list ? type.list.length > 0 : false);

  public render() {
    const {
      newestArticles = {},
      proposalArticles = {},
      inprogressArticles = {},
      prewrittenArticles = {},
      postpublishedArticles = {},
      proposalBriefs = {},
      fixedArticles = {},
      templateArticles = {},
      restrictedArticles = {},
      classes,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="dashboard-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_dashboard' }]} />}
        main={[
          this.showCategory(newestArticles) && (
            <Paper key="newestArticles" elevation={2}>
              <Toolbar>
                <Typography variant="h6">{m.article_newest}</Typography>
              </Toolbar>
              <TablePublished
                collection={newestArticles}
                defaultItemPerPage={5}
                onPaginate={this.paginateNewestArticles}
              />
            </Paper>
          ),
          this.showCategory(proposalArticles) && (
            <Paper key="proposalArticles" elevation={2} className={`${classes.highligth} ${classes.marginTop}`}>
              <Toolbar>
                <Typography variant="h6">{m.article_proposal}</Typography>
              </Toolbar>
              <TableCreated collection={proposalArticles} onPaginate={this.paginateProposalArticles} />
            </Paper>
          ),
          this.showCategory(postpublishedArticles) && (
            <Paper key="postpublishedArticles" elevation={2} className={classes.marginTop}>
              <Toolbar>
                <Typography variant="h6">{m.article_postpublished}</Typography>
              </Toolbar>
              <TablePostPublished collection={postpublishedArticles} onPaginate={this.paginatePrewrittenArticles} />
            </Paper>
          ),
          this.showCategory(proposalBriefs) && (
            <Paper key="proposalBriefs" elevation={2} className={`${classes.highligth} ${classes.marginTop}`}>
              <Toolbar>
                <Typography variant="h6">{m.brief_proposal}</Typography>
              </Toolbar>
              <TableCreated collection={proposalBriefs} onPaginate={this.paginateProposalBriefs} />
            </Paper>
          ),
          this.showCategory(inprogressArticles) && (
            <Paper key="inprogressArticles" elevation={2} className={classes.marginTop}>
              <Toolbar>
                <Typography variant="h6">{m.article_inprogress}</Typography>
              </Toolbar>
              <TableCreated collection={inprogressArticles} onPaginate={this.paginateInprogressArticles} />
            </Paper>
          ),
          this.showCategory(prewrittenArticles) && (
            <Paper key="prewrittenArticles" elevation={2} className={`${classes.highligth} ${classes.marginTop}`}>
              <Toolbar>
                <Typography variant="h6">{m.article_prewritten}</Typography>
              </Toolbar>
              <TableCreated collection={prewrittenArticles} onPaginate={this.paginatePrewrittenArticles} />
            </Paper>
          ),
          this.showCategory(fixedArticles) && (
            <Paper key="fixedArticles" elevation={2} className={`${classes.marginTop}`}>
              <Toolbar>
                <Typography variant="h6">{m.article_fixed}</Typography>
              </Toolbar>
              <TableFixed collection={fixedArticles} onPaginate={this.paginateFixedArticles} />
            </Paper>
          ),
          this.showCategory(templateArticles) && (
            <Paper key="templateArticles" elevation={2} className={`${classes.marginTop}`}>
              <Toolbar>
                <Typography variant="h6">{m.article_template}</Typography>
              </Toolbar>
              <TableCreated collection={templateArticles} onPaginate={this.paginateTemplateArticles} />
            </Paper>
          ),
          this.showCategory(restrictedArticles) && (
            <Paper key="restrictedArticles" elevation={2} className={`${classes.marginTop}`}>
              <Toolbar>
                <Typography variant="h6">{m.article_restricted}</Typography>
              </Toolbar>
              <TableCreated collection={restrictedArticles} onPaginate={this.paginateRestrictedArticles} />
            </Paper>
          ),
        ]}
      />
    );
  }
}
