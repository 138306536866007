import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { Component } from 'react';
import ClubAvatar from '../../clubs/Avatar';
import { getMatchDetailHref } from '../../utils/href';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class Row extends Component {
  renderTeamCell = ({ name, club }) => {
    const { classes } = this.props;
    return (
      <div className={classes.rowContent}>
        <ClubAvatar club={{ logo: club && club.logo }} />
        <div className={classes.label}>{name}</div>
      </div>
    );
  };

  render() {
    const { tableData } = this.props;
    const { date, awayTeam, homeTeam, phaseTournamentCompetitionName } = tableData;
    const href = getMatchDetailHref(tableData.id);

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          {`${moment(date).format('L')} - ${moment(date).format('LT')}`}
        </TableCell>
        <TableCell>{homeTeam && this.renderTeamCell(homeTeam)}</TableCell>
        <TableCell>{awayTeam && this.renderTeamCell(awayTeam)}</TableCell>
        <TableCell>{phaseTournamentCompetitionName}</TableCell>
      </TableRow>
    );
  }
}
