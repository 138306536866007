// @ts-ignore
import logoEs from '../img/fichajes.svg';
// @ts-ignore
import logoFr from '../img/footmercato.svg';
// @ts-ignore
import logoDe from '../img/fussball.svg';

interface IProjectSharedConfig {
  apiUrlFD: string;
  apiPrefixFD: string;
  apiUrlFM: string;
  baseUrlFM: string;
  enableDeeplinkingV2: boolean
}
export type ILocale = 'fr' | 'es' | 'de' | 'it' | 'en' | 'br';
type IBaseProject = IProjectSharedConfig & {
  name: string;
  lang: ILocale;
  url: string;
};

type IFMProject = IBaseProject & { logo: any; urlFD: string  , featureFlagsFmV2: boolean};
type IFDProject = IBaseProject & { label: string };
type IProject = IFMProject | IFDProject;

let project: IProject;

/* global process*/
const sharedConfig: {
  [key: string]: IProjectSharedConfig;
} = {
  fr: {
    apiUrlFD: `${process.env.FOOTDATA_BASE_URL}${process.env.FD_API_PREFIX}fr/`,
    apiPrefixFD: `${process.env.FD_API_PREFIX}fr/`,
    apiUrlFM: `${process.env.FOOTMERCATO_BASE_URL}${process.env.FM_API_PREFIX}`,
    baseUrlFM: `${process.env.FOOTMERCATO_BASE_URL}`,
    enableDeeplinkingV2: Boolean(process.env.ENABLE_DEEPLINKING_V2_FOOTMERCATO_FR),
  },
  de: {
    apiUrlFD: `${process.env.FOOTDATA_BASE_URL}${process.env.FD_API_PREFIX}de/`,
    apiPrefixFD: `${process.env.FD_API_PREFIX}de/`,
    apiUrlFM: `${process.env.FUSSBALL_BASE_URL}${process.env.FM_API_PREFIX}`,
    baseUrlFM: `${process.env.FUSSBALL_BASE_URL}`,
    enableDeeplinkingV2: Boolean(process.env.ENABLE_DEEPLINKING_V2_FOOTMERCATO_DE),
  },
  es: {
    apiUrlFD: `${process.env.FOOTDATA_BASE_URL}${process.env.FD_API_PREFIX}es/`,
    apiPrefixFD: `${process.env.FD_API_PREFIX}es/`,
    apiUrlFM: `${process.env.FICHAJES_BASE_URL}${process.env.FM_API_PREFIX}`,
    baseUrlFM: `${process.env.FICHAJES_BASE_URL}`,
    enableDeeplinkingV2: Boolean(process.env.ENABLE_DEEPLINKING_V2_FOOTMERCATO_ES),
  },
  it: {
    apiUrlFD: `${process.env.FOOTDATA_BASE_URL}${process.env.FD_API_PREFIX}it/`,
    apiPrefixFD: `${process.env.FD_API_PREFIX}it/`,
    apiUrlFM: '',
    baseUrlFM: '',
    enableDeeplinkingV2: true,
  },
  en: {
    apiUrlFD: `${process.env.FOOTDATA_BASE_URL}${process.env.FD_API_PREFIX}en/`,
    apiPrefixFD: `${process.env.FD_API_PREFIX}en/`,
    apiUrlFM: '',
    baseUrlFM: '',
    enableDeeplinkingV2: true,
  },
  br: {
    apiUrlFD: `${process.env.FOOTDATA_BASE_URL}${process.env.FD_API_PREFIX}br/`,
    apiPrefixFD: `${process.env.FD_API_PREFIX}br/`,
    apiUrlFM: '',
    baseUrlFM: '',
    enableDeeplinkingV2: true,
  }
};

const fm: {
  [key: string]: IFMProject;
} = {
  fr: {
    name: 'footmercato_fr',
    lang: 'fr',
    url: '/footmercato',
    urlFD: '/footdatafr',
    logo: logoFr,
    featureFlagsFmV2: Boolean(process.env.FEATURE_FLAGS_FOOTMERCATO_V2_FR),
    ...sharedConfig.fr,
  },
  de: {
    name: 'footmercato_de',
    lang: 'de',
    url: '/fussballtransfers',
    urlFD: '/footdatade',
    logo: logoDe,
    featureFlagsFmV2: Boolean(process.env.FEATURE_FLAGS_FOOTMERCATO_V2_DE),
    ...sharedConfig.de,
  },
  es: {
    name: 'footmercato_es',
    lang: 'es',
    url: '/fichajes',
    urlFD: '/footdataes',
    logo: logoEs,
    featureFlagsFmV2: Boolean(process.env.FEATURE_FLAGS_FOOTMERCATO_V2_ES),
    ...sharedConfig.es,
  },
};
const fd: {
  [key: string]: IFDProject;
} = {
  fr: {
    name: 'footdataapi_fr',
    lang: 'fr',
    url: '/footdatafr',
    label: 'FDFR',
    ...sharedConfig.fr,
  },
  de: {
    name: 'footdataapi_de',
    lang: 'de',
    url: '/footdatade',
    label: 'FDDE',
    ...sharedConfig.de,
  },
  es: {
    name: 'footdataapi_es',
    lang: 'es',
    url: '/footdataes',
    label: 'FDES',
    ...sharedConfig.es,
  },
  it: {
    name: 'footdataapi_it',
    lang: 'it',
    url: '/footdatait',
    label: 'FDIT',
    ...sharedConfig.it,
  },
  en: {
    name: 'footdataapi_en',
    lang: 'en',
    url: '/footdataen',
    label: 'FDEN',
    ...sharedConfig.en,
  },
  br: {
    name: 'footdataapi_br',
    lang: 'br',
    url: '/footdatabr',
    label: 'FDBR',
    ...sharedConfig.br
  }
};

const projects: IProject[] = [fm.fr, fm.es, fm.de, fd.fr, fd.es, fd.de, fd.it, fd.en, fd.br];

export const getProject = () => {
  return project;
};
export const getProjects = () => {
  return projects;
};
export const setProject = (pathName: string) => {
  const p = findProjectFromPath(pathName);
  if (!p) return;
  project = p;
};
export const findProjectFromPath = (pathName: string) => projects.find(p => pathName.indexOf(p.url) === 0);

export const getFMProject = (lang: string) => {
  return fm[lang];
};

export const getFDProjectUrl = () => {
  const currentProject = getProject();
  return (currentProject as IFMProject).urlFD || currentProject.url;
};

export const buildFMLink = async (url = '', noSlashAtEnd = false) => {
  const base =
    noSlashAtEnd && getProject().baseUrlFM.endsWith('/') ? getProject().baseUrlFM.slice(0, -1) : getProject().baseUrlFM;
  return `${base}${url}`;
};

export const getProjectNameFromPath = (pathName: string) => {
  const p = findProjectFromPath(pathName);
  if (!p) return '';
  return p.name;
};
