import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'MEMBERSHIP',
  inside: {
    club: { get: 'memberships' },
    player: { get: 'memberships' },
  },
  collections: {
    contractsIds: { get: 'contracts', set: 'contract' },
  },
  details: {
    transferId: { get: 'transfer' },
  },
});
