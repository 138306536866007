import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import InjuryTypeForm from './Form';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class Row extends Component {

  handleChange = injury => this.props.update(injury);

  render() {
    const { tableData } = this.props;
    const { code } = tableData;

    return (
      <TableRow>
        <TableCell>
          {code}
        </TableCell>
        <TableCell>
          <InjuryTypeForm onSubmit={this.handleChange} data={tableData} />
        </TableCell>
      </TableRow>
    );
  }
}
