import React, { Component } from 'react';
import RevisionChange from './Change';

const styles = {
  title: {
    color: 'rgba(0, 0, 0, .5)',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '48px',
  },
  changes: {
    display: 'flex',
  },
  field: {
    fontWeight: 'bold',
    color: 'grey',
    marginTop: 8,
  },
  before: {
    flex: '1',
    background: 'rgba(255, 255, 255, .5)',
    padding: '0 8px',
    marginRight: 16,
  },
  after: {
    flex: '1',
    background: 'white',
    padding: '0 8px',
  },
};

export default class Diff extends Component {
  isntSet(val) {
    return val === null || val === '' || val === undefined;
  }

  significantDiff(update) {
    return !(this.isntSet(update[0]) && this.isntSet(update[1]));
  }

  render() {
    const { rev } = this.props;

    if (rev.diff.translations) {
      rev.diff = {
        ...rev.diff,
        ...rev.diff.translations,
      };
    }
    return (
      <div>
        {rev && <div style={styles.title}>Diff</div>}
        {rev &&
          Object.entries(rev.diff).map(
            ([field, update]) =>
              this.significantDiff(update) && (
                <div>
                  <div style={styles.field}>{field}</div>
                  <div style={styles.changes}>
                    <span style={styles.before}>
                      <RevisionChange change={update[0]} />
                    </span>
                    <span style={styles.after}>
                      <RevisionChange change={update[1]} />
                    </span>
                  </div>
                </div>
              )
          )}
      </div>
    );
  }
}
