import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateCell from 'Components/TableList/cell/Date';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { ArticleStatusIcon } from '../../../articles/components/ArticleStatusIcon';

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
});

@withStyles(styles)
export default class Row extends Component {
  renderAuthors = (authors = []) => {
    const { classes } = this.props;
    return authors.map(a => (
      <Chip
        avatar={<Avatar>{a.publicName[0]}</Avatar>}
        label={a.publicName}
        className={classes.chip}
      />
    ));
  };

  render() {
    const { tableData } = this.props;
    const { status, title, authors, publishedAt } = tableData;
    const href = `#${getProject().url}/live-transfers/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell size="small">
          <ArticleStatusIcon status={status} />
        </TableCell>
        <TableCell size="small">{title}</TableCell>
        <TableCell size="small">{this.renderAuthors(authors)}</TableCell>
        <TableCell size="small">
          <DateCell date={publishedAt} />
        </TableCell>
      </TableRow>
    );
  }
}
