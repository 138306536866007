import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContentAdd from '@material-ui/icons/Add';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { UserRestriction } from '../../../../common/components/UserRestiction';
import { getProject } from '../../../../common/services/project';
import { addPartnerOffer, fetchPartnerOffersByPartner } from '../../actions';
import PartnerOfferDialog from '../../PartnerOfferDialog';
import { PartnerOffersPanels } from '../../PartnerOffersPanels';
import { IPartnerOffer } from '../../typings';

const styles = {
  icon: {
    marginRight: 8,
  },
  buttonLeft: {
    marginRight: 8,
  },
};

// @ts-ignore
@withStyles(styles)
// @ts-ignore
@injectIntl
// @ts-ignore
@connect(store => ({
  // @ts-ignore
  partnerOffers: store.partnerOfferByPartnerList,
}))
export class PartnerOffers extends Component<any> {
  public componentDidMount() {
    const { dispatch, partner } = this.props;
    dispatch(fetchPartnerOffersByPartner(partner.id, getProject().lang));
  }

  public render() {
    const {
      intl: { messages: m },
      partnerOffers,
      classes,
    } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_partnerOffers}</Typography>
          <div style={{ flex: 1 }} />
          <UserRestriction>
            <PartnerOfferDialog title={m.dialog_addPartnerOffers} onCreate={this.handleAdd}>
              <Button variant="contained" color="primary" className={classes.buttonLeft}>
                <ContentAdd className={classes.icon} />
                {m.button_add}
              </Button>
            </PartnerOfferDialog>
          </UserRestriction>
        </Toolbar>
        <PartnerOffersPanels partnerOffers={partnerOffers.list} />
      </div>
    );
  }

  private handleAdd = (partnerOffer: Partial<IPartnerOffer>) => {
    const { dispatch, partner } = this.props;
    partnerOffer.partner = partner['@id'];
    partnerOffer.locale = getProject().lang;

    // @ts-ignore
    dispatch(addPartnerOffer(partnerOffer)).then(() => {
      dispatch(fetchPartnerOffersByPartner(partner.id, getProject().lang));
    });
  };
}
