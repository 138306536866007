import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { parseQuery } from '../../../common/services/parseQuery';
import { IRootState } from '../../../typings';
import { addQuizz, fetchQuizzes } from '../actions';

type IOwnProps = RouteComponentProps;

const mapDispatchToProps = {
    fetchQuizzes,
    addQuizz
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
    me: state.me,
    quizzes: state.quizzList.list,
    pagination: state.quizzList.pagination,
    fetching: state.surveyList.fetching,
    total: state.surveyList.totalItems,
    query: parseQuery(ownProps.location.search),
});

export type IComponentProps = typeof mapDispatchToProps &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps &
    IOwnProps;

const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
    reduxConnector,
    injectIntl
);
