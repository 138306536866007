import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';

export default function fetchAllChannels() {
  const params = {
    pagination: 0,
    groups: ['read', '_image'],
  };
  return function(dispatch) {

    dispatch({ type: 'FETCH_CHANNELS' });

    return rest
      .get(`channels`, { params })
      .then(
        res => dispatch({ type: 'FETCH_CHANNELS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_CHANNELS_REJECTED', payload: err.response })
      );
  };
}

export function fetchChannelsList(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read', '_image' , '_area', '_parent'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CHANNELS' });
    return rest
      .get('channels', { params })
      .then(
        res => dispatch({ type: 'FETCH_CHANNELS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_CHANNELS_REJECTED', payload: err.response })
      );
  };
}

export function fetchChannel(id) {
  const params = {
    groups: ['read', '_image', '_area' , '_parent'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CHANNEL' });

    return rest
      .get(`channels/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_CHANNEL_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_CHANNEL_REJECTED', payload: err.response })
      );
  };
}

export function updateChannel(id, channel) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_CHANNEL', payload: id });
    return rest.put(`channels/${id}`, channel).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: 'Channel modifié', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_CHANNEL_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_CHANNEL_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchMatchChannels(match) {
  const params = { match };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCH_CHANNELS', payload: match });

    return rest.get('channels', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_MATCH_CHANNELS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_MATCH_CHANNELS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function addChannel(channel) {
  if (!channel.shortName) {
    channel.shortName = channel.name;
  }
  return function(dispatch) {
    dispatch({ type: 'ADD_CHANNEL' });

    return rest.post('channels', channel).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: 'Channel Créé', type: 'success' },
        });
        return dispatch({ type: 'ADD_CHANNEL_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'ADD_CHANNEL_REJECTED', payload: err.response })
    );
  };
}

export function deleteChannel(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_CHANNEL', payload: id });

    return rest
      .delete(`channels/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_CHANNEL_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_CHANNEL_REJECTED', payload: err.response })
      );
  };
}
