import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { getProject } from '../../../../../common/services/project';
import { IComponentProps } from './PeriodSelector.connector';
import { EPeriod, ORDERED_PERIODS, PERIODS, isFollowingPeriod } from './utils';

interface IState {
  value: EPeriod;
  lastPeriod: EPeriod;
}

const styleSheet = {
  container: {
    flex: 1,
    display: 'flex',
    textAlign: 'center',
  },
  button: {
    marginLeft: 8,
  },
};

// @ts-ignore
@withStyles(styleSheet)
export class PeriodSelector extends React.Component<IComponentProps, IState> {
  public static getDerivedStateFromProps(props: IComponentProps, state: IState) {
    if (state.lastPeriod !== props.period) {
      return { value: props.period, lastPeriod: props.period };
    }
    return {};
  }
  public state: IState = {
    value: this.props.period,
    lastPeriod: this.props.period,
  };
  public shouldComponentUpdate(nextProps: IComponentProps, nextState: IState) {
    const { isMatchUpdating, period } = this.props;
    const { value } = this.state;
    return nextProps.period !== period || nextState.value !== value || nextProps.isMatchUpdating !== isMatchUpdating;
  }

  public render() {
    const {
      classes,
      period,
      isMatchUpdating,
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.container}>
        <FormControl style={{ width: 200 }} fullWidth>
          <Select
            name="period"
            value={value}
            onChange={this.handleChange}
            inputProps={{
              id: 'select_period',
            }}
            disabled={mainCommentatorLocale != getProject().lang}
          >
            {this.renderItems()}
          </Select>
        </FormControl>
        <div>
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={this.handleClick}
            size="small"
            disabled={value === period || isMatchUpdating || mainCommentatorLocale != getProject().lang}
          >
            {m.button_update}
          </Button>
        </div>
      </div>
    );
  }

  private handleChange = (event: any) => {
    this.setState({ value: event.target.value });
  };
  private handleClick = () => {
    this.props.updateLivePeriod(this.state.value);
  };

  private getLabel = (period: EPeriod) => {
    const {
      intl: { messages: m },
    } = this.props;
    return [m[`live_${PERIODS[period]}`], m[`live_${PERIODS[period]}_helper`]].filter(Boolean).join(' ');
  };

  private renderItems = () =>
    ORDERED_PERIODS.map(period => (
      <MenuItem key={period} value={period} disabled={!isFollowingPeriod(this.props.period, period)}>
        {this.getLabel(period)}
      </MenuItem>
    ));
}
