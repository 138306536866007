import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProject } from 'Services/project';

import { addArticle, fetchArticles } from '../actions';
import { fetchAuthorMe } from '../../authors/actions';
import filterList from 'Actions/lists';

import debounce, { getLinkId } from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';

import { TableList } from '../../../common/components/TableList';
import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';

import { ArticleRow } from './ArticleRow.component';
import ArticleDialog from '../Dialog';
import TopBar from '../../components/TopBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import AuthorInput from '../../authors/Input';
import TagInput from '../../tags/Input';
import SelectConstant from '../../components/constants/Select';
import DateInput from 'Components/inputs/Date';
import CompetitionInput from 'FD/competitions/Input';
import PersonInput from 'FD/people/Input';
import MatchInput from 'FD/matches/Input';
import TeamInput from 'FD/teams/Input';
import { getDefaultInputsValue } from '../../../common/services/getDefaultInputsValue';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { hasAccessToRestrictedArticles } from "Services/me";

@injectIntl
@connect((store, props) => ({
  articles: store.articles.list,
  fetching: store.articles.fetching,
  pagination: store.articles.pagination,
  total: store.articles.totalItems,
  query: parseQuery(props.location.search),
  me: store.me,
}))
export default class Articles extends Component {
  config = {
    filters: [
      {
        type: 'title',
        labelKey: 'filter_title',
      },
      {
        type: 'status',
        input: SelectConstant,
        entity: 'article',
        labelKey: 'filter_status',
        defaultValue: 'publish',
      },
      {
        type: 'authors',
        input: AuthorInput,
        labelKey: 'filter_author',
      },
      {
        type: 'tags',
        input: TagInput,
        labelKey: 'filter_tag',
      },
      {
        type: 'publishedAt',
        input: DateInput,
      },
      {
        type: 'publishedAtAfter',
        input: DateInput,
        labelKey: 'filter_publishedAt_after',
      },
      {
        type: 'publishedAtBefore',
        input: DateInput,
        labelKey: 'filter_publishedAt_before',
      },
      {
        type: 'unpublishedAt',
        input: DateInput,
      },
      {
        type: 'footdataCompetitionIds',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
      {
        type: 'footdataPersonIds',
        input: PersonInput,
        labelKey: 'filter_person',
      },
      {
        type: 'footdataMatchIds',
        input: MatchInput,
        labelKey: 'filter_match',
      },
      {
        type: 'footdataTeamIds',
        input: TeamInput,
        labelKey: 'filter_team',
      },
    ],
    headers: [
      { labelKey: 'field_status', size: 'small' },
      { id: 'title', labelKey: 'field_title', size: 'small' },
      { labelKey: 'field_authors', size: 'small' },
      { labelKey: 'field_tags', size: 'small' },
      { id: 'publishedAt', labelKey: 'field_publishedAt', size: 'small' },
      {
        id: 'unpublishedAt',
        labelKey: 'field_unpublishedAt',
        size: 'small',
      },
      {
        id: 'analyticsWebCount',
        labelKey: 'app_statistics',
        size: 'small',
      },
    ],
  };

  defaultInputsValue = getDefaultInputsValue(this.config.filters);

  componentDidMount() {
    const { location } = this.props;
    if (this.props.location.search === '') {
      return this.changeParams({
        type: 'SET_FILTER',
        payload: { ...this.defaultInputsValue },
      });
    } else {
      this.fetchData(location.search);
    }
  }

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (prevProps.location.search === '' && nextProps.location.search === '') {
      return this.changeParams({
        type: 'SET_FILTER',
        payload: { ...this.defaultInputsValue },
      });
    }

    if (prevProps.location.search !== nextProps.location.search && nextProps.location.search !== '') {
      return this.fetchData(nextProps.location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchArticles(filter));
    dispatch(filterList('articles', filter));
  };

  handleCreate = article => {
    const { dispatch, history, me } = this.props;
    // Get author id of user to create article

    dispatch(fetchAuthorMe(me.username)).then(({ payload }) => {
      const articleWithAuthor = {
        authors: [payload['@id']],
        ...article,
      };
      dispatch(addArticle(articleWithAuthor)).then(({ payload }) => {
        if (payload && payload.id) {
          history.push(`${getProject().url}/articles/${payload.id}`);
        }
      });
    });
  };

  handleFilters = change => {
    const {
      title,
      status,
      authors,
      tags,
      publishedAtAfter,
      publishedAtBefore,
      publishedAt,
      unpublishedAt,
      footdataCompetitionIds,
      footdataTeamIds,
      footdataMatchIds,
      footdataPersonIds,
    } = change;
    const filtersQuery = {
      title,
      status,
      publishedAtAfter,
      publishedAtBefore,
      publishedAt,
      unpublishedAt,
      authors: getLinkId(authors),
      tags: getLinkId(tags),
      footdataCompetitionIds: getLinkId(footdataCompetitionIds),
      footdataTeamIds: getLinkId(footdataTeamIds),
      footdataMatchIds: getLinkId(footdataMatchIds),
      footdataPersonIds: getLinkId(footdataPersonIds),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);

    location.search != search && location.search === ''
      ? history.replace(`${getProject().url}/articles${search}`)
      : history.push(`${getProject().url}/articles${search}`);
  }, 500);

  render() {
    const {
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;
    // Prevent direct access (URL filter) to restricted articles if user not trusted
    let { articles } = this.props;
    if (articles.at(0) && articles.at(0).status === 'restricted' && !hasAccessToRestrictedArticles()) {
        articles = [];
    }
    return (
      <Layout
        id="articles-layout"
        dataTest="LayoutArticlesList"
        top={<TopBar crumbStack={[{ labelKey: 'app_articles' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_articlesList}</Typography>
              <div style={{ flex: 1 }} />
              <ArticleDialog title={m.dialog_addArticle} form="article_add" onCreate={this.handleCreate}>
                <Button variant="contained" color="primary" data-test="ArticleList__buttonAdd">
                  <AddIcon style={{ marginRight: 8 }} />
                  {m.button_add}
                </Button>
              </ArticleDialog>
            </Toolbar>
            <SearchFilter
              form="articles_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={articles}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={ArticleRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
