import rest from '../../common/services/restFootData';
import { ISide } from '../matches/typings';
import { IComposition } from './typings';
import { decodeComposition, encodeComposition } from './utils';

export const getMatchComposition = (matchId: string) =>
  rest.get(`matches/formations/${matchId}`);

export const extractMatchComposition = ({ data }) => {
  if (!data || !data.collection) return null;
  const [home, away] = data.collection;
  const composition = { home, away };
  return decodeComposition(composition);
};

export const postSideComposition = (
  matchId: string,
  side: ISide,
  composition: IComposition
) =>
  rest.post(
    `formations/match/${matchId}?matchTeamPosition=${side}`,
    encodeComposition(composition)
  );
