import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const styles = () => ({
  link: {
    lineHeight: '23px',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

@withStyles(styles)
@injectIntl
export default class Bloc extends Component {
  renderProgress = r => {
    if (r.lastRank - r.rank > 0)
      return <ArrowDropUpIcon style={{ verticalAlign: 'middle', color: 'green' }} />;
    if (r.lastRank - r.rank < 0)
      return <ArrowDropDownIcon style={{ verticalAlign: 'middle', color: 'red' }} />;
    return null;
  };

  render() {
    const {
      classement,
      classes,
      intl: { messages: m },
    } = this.props;

    if (!classement) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <div>
        <Toolbar>
          <Typography variant="headline" component="h2">
            {m.app_classement}: {classement.type}
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right" padding="none" />
              <TableCell>{m.app_team}</TableCell>
              <TableCell align="right" padding="none">
                {m.abr_points}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_played}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_won}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_drawn}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_lost}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_goalsFor}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_goalsAgainst}
              </TableCell>
              <TableCell align="right" padding="none">
                {m.abr_goalDifference}
              </TableCell>
              <TableCell>{m.classement_rankingRule}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(classement.rankings).map(r => (
              <TableRow key={r.id}>
                <TableCell align="right" padding="none">
                  {this.renderProgress(r)} {r.rank}
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    <a
                      href={`#${getProject().url}/teams/${r.teamSlug}`}
                      className={classes.link}
                    >
                      {r.teamName}
                    </a>
                  </div>
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.points}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.played}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.won}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.drawn}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.lost}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.goalsFor}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.goalsAgainst}
                </TableCell>
                <TableCell align="right" padding="none">
                  {r.goalDifference}
                </TableCell>
                <TableCell>{r.rankingRuleName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
