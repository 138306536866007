import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    padding: theme.spacing(0.25),
  },
  divider: {
    alignSelf: 'stretch',
    height: 'auto',
    margin: theme.spacing(1, 0.5),
  },
  group: {
    margin: theme.spacing(0.25),
  },

  button: {
    '&:disabled': {
      background: theme.palette.grey[100],
    },
  },
});
