import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { updateContract, deleteContract } from '../actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Form from './Form';
import moment from 'moment';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
  secondaryHeading: {
    width: '100%',
  },
});

@connect()
@withStyles(styles)
export default class ContractsPanels extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updateContract(id, data));
  };

  handleRemove = id => () => {
    const { dispatch } = this.props;
    return dispatch(deleteContract(id));
  };

  renderPanel = contract => {
    const { classes } = this.props;
    const { expanded } = this.state;

    if (!contract) return null;

    return (
      <ExpansionPanel
        expanded={expanded === contract.id}
        onChange={this.handleExpand(contract.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{this.renderTitle(contract)}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === contract.id && (
            <Form onSubmit={this.handleChange(contract.id)} onDelete={this.handleRemove(contract.id)} data={contract} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { contracts = [] } = this.props;
    return contracts.sort(this.sortContracts).map(this.renderPanel);
  }

  renderTitle = contract => {
    let title = contract.typeTranslated;
    if (contract.startDate) {
      const date = moment(contract.startDate).format('L');
      title += ` (${date})`;
    }
    return title;
  };

  sortContracts = (a, b) => {
    if (!a.startDate) {
      return -1;
    }
    if (!b.startDate) {
      return 1;
    }
    return a.startDate < b.startDate ? 1 : -1;
  };
}
