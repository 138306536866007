import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function TextIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 500 500">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M403 306l-20 12c-3 2-7 1-9-2l-12-20c-2-3-1-7 2-9 3-2 7-1 9 2l4 6c7-26-5-54-30-65-5-3-11-5-17-5-3 12-16 19-29 16-12-4-19-17-15-29 3-12 16-19 28-16 7 2 13 7 15 14 0 1 0 1 1 1 8 1 15 3 23 6 31 13 45 48 37 80l6-4c3-2 7-1 9 2 2 5 1 9-2 11z m-142-105c2-2 2-5 0-7-2-3-6-3-8 0l-6 5-5-6c-2-2-6-2-8 0-2 2-2 6 0 8l5 6-5 5c-2 2-2 6 0 8 2 2 5 2 8 0l5-6 6 6c2 2 5 2 7 0 2-2 2-5 0-8l-5-5z m-102 54c1-1 1-3 0-4-1-1-2-1-3 0l-3 3-3-3c-1-1-2-1-3 0-1 1-1 3 0 4l2 3-2 2c-1 1-1 3 0 4 1 1 2 1 3 0l3-3 3 3c1 1 2 1 3 0 1-1 1-3 0-4l-2-2z m217 85c2-2 2-5 0-7-2-2-6-2-8 0l-6 5-5-6c-2-2-5-2-8 0-2 2-2 6 0 8l6 6-6 5c-2 2-2 6 0 8 2 2 5 2 8 0l5-6 6 6c2 2 5 2 8 0 2-2 2-5 0-8l-6-5z m-236 12c-2 2-2 5 0 8 2 2 5 2 7 0l6-6 5 6c2 2 6 2 8 0 2-2 2-6 0-8l-5-6 5-5c3-2 3-5 0-8-2-2-5-2-7 0l-6 6-5-6c-2-2-6-2-8 0-2 2-2 6 0 8l5 5z m57 9l25 0 0-105-25 0z m34-22l25 0 0-83-25 0z m33 9l26 0 0-92-26 0z m34-40l26 0 0-52-26 0z m5 129c-1 0-3 0-5 0l0 0-87 0c-81 0-148-66-148-148 0-70 49-130 118-145 1-13 0-27-4-40-4-12 3-25 15-28 6-2 11-1 17 1 23 12 54 32 73 64l16 0c82-2 149 63 151 145 1 82-64 150-146 151z m0-272c-1 0-3 0-5 0l-22 0c-4 0-9-3-11-7-15-28-42-47-64-59 5 19 6 38 3 57-1 5-5 9-10 10-68 10-115 73-105 141 9 61 61 106 123 106l87 0c68 1 125-53 127-122 0-68-55-125-123-126z"
        />
      </g>
    </SvgIcon>
  );
}
