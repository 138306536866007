import React from 'react';
import moment from 'moment';

export default function DateCell({ date, dateFormat = 'L' }) {
  if (!date) {
    return <span>-</span>;
  }

  if (dateFormat === 'L') {
    const displayDate = moment(date.substring(0, 10));
    return <span>{displayDate.format(dateFormat)}</span>;
  }

  return <span>{moment(date).format(dateFormat)}</span>;
}
// Test
// var date = "1994-02-13T00:00:00+01:00"
// console.log(moment.utc(date).format('L'));
// console.log(moment.utc(date.substring(0,10)).format('L'));
