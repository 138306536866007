import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import TextInput from '../../../../common/components/inputs/Text';
import isAdmin from '../../../../common/services/me';
import SelectConstant from '../../../components/constants/Select';
import ImageInput from '../../../images/Input';
import { IPartner } from '../../typings';
import moment from "moment";

interface IProps {
  data: any;
  onSubmit: (partner: IPartner) => void;
  onDelete?: (partner: IPartner) => void;
  onRemove?: (id: string) => void;
}

export class PartnerForm extends Component<IProps> {
  public config = {
    fields: ['id', 'name', 'keyCode', 'sector', 'createdAt', 'updatedAt'],
    objectFields: ['logo'],
    disposition: [
      {
        title: 'form_general',
        fields: [
          { name: 'name', component: TextInput, size: 6, length: 100, required: true },
          { name: 'sector', component: SelectConstant, entity: 'partner', size: 6, required: true },
          { name: 'keyCode', component: TextInput, size: 6, length: 20, disabled: !isAdmin() },
          { name: 'logo', component: ImageInput, size: 6, required: true },
          { name: 'createdAt', component: TextInput, size: 6, disabled: true, format: this.formatDate },
          { name: 'updatedAt', component: TextInput, size: 6, disabled: true, format: this.formatDate },
        ],
      },
    ],
  };

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`partner_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }

  public formatDate(date) {
    if (!date) return '-';

    return `${moment(date).format('L')} - ${moment(date).format('LT')}`;
  }
}
