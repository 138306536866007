import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';
import { getProject } from 'Services/project';

const groups = ['write', 'read_user_game'];

export function getQuizz(id) {
    const params = {
        groups,
    };
    return function(dispatch) {
        dispatch({ type: 'GET_QUIZZ' });

        return rest
            .get(`quizzs/${id}`, { params })
            .then(
                res => dispatch({ type: 'GET_QUIZZ_FULFILLED', payload: res.data }),
                err => dispatch({ type: 'GET_QUIZZ_REJECTED', payload: err.response })
            );
    };
}

export function fetchQuizzes(search) {
    const params = {
        itemsPerPage: 10,
        ...qs.parse(search),
        partial: 0,
        ignoreLocale: 1,
        groups,
    };

    return function(dispatch) {
        dispatch({ type: 'FETCH_QUIZZ_LIST' });

        return rest
            .get('quizzs', { params })
            .then(
                res => dispatch({ type: 'FETCH_QUIZZ_LIST_FULFILLED', payload: res.data }),
                err => dispatch({ type: 'FETCH_QUIZZ_LIST_REJECTED', payload: err.response })
            );
    };
}

export function translateQuizz(quizzId) {
    return function(dispatch) {
        dispatch({ type: 'TRANSLATE_QUIZZ' });

        return rest.get(`quizzs/${quizzId}/translate`).then(
            () =>
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_quizzTranslated', type: 'success' },
                }),
            err => dispatch({ type: 'TRANSLATE_QUIZZ_REJECTED', payload: err.response })
        );
    };
}

export function updateQuizz(id, quizz) {
    const params = {
        groups: ['write', 'read_quizz', 'read_user_game'],
    };

    return function(dispatch) {
        dispatch({ type: 'UPDATE_QUIZZ', payload: id });

        return rest.put(`quizzs/${id}`, quizz, { params }).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_quizzUpdated', type: 'success' },
                });
                return dispatch({
                    type: 'UPDATE_QUIZZ_FULFILLED',
                    payload: res.data,
                });
            },
            err => {
                if (err.response && err.response.data.type === 'ConstraintViolationList') {
                    const { errors } = err.response.data;
                    throw new SubmissionError(errors);
                }
                return dispatch({
                    type: 'UPDATE_QUIZZ_REJECTED',
                    payload: err.response,
                });
            }
        );
    };
}

export function addQuizz(quizz) {
    quizz.mainLocale = getProject().lang;

    return function(dispatch) {
        dispatch({ type: `ADD_QUIZZ` });

        return rest.post('quizzs', quizz).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_quizzAdded', type: 'success' },
                });
                return dispatch({
                    type: `ADD_QUIZZ_FULFILLED`,
                    payload: res.data,
                });
            },
            err => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_surveyAdd_error', type: 'error' },
                });
                return dispatch({
                    type: `ADD_QUIZZ_REJECTED`,
                    payload: err.response,
                });
            }
        );
    };
}

export function searchQuizz(id) {
    const params = {
        id,
    };
    return function(dispatch) {
        dispatch({ type: 'SEARCH_QUIZZ_LIST' });

        return rest
            .get(`quizzs`, { params })
            .then(
                res => dispatch({ type: 'SEARCH_QUIZZ_LIST_FULFILLED', payload: res.data }),
                err => dispatch({ type: 'SEARCH_QUIZZY_LIST_REJECTED', payload: err.response })
            );
    };
}

