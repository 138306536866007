import { IUser } from './typings';

export const isUserAdmin = (user: IUser): boolean => user.roles.includes('ROLE_ADMIN');

export const isUserLimited = (user: IUser): boolean =>
  user.roles.includes('ROLE_LIMITED') && !isUserAdmin(user);

// Access restricted to some articles. See docs/user_access.md
const trustedUsers = [
    'bsimon', // Brieuc Simon
    'sdenis', // Sebastien Denis
    'NajyaounaSLink', // Santi Aouna
    'Aurelien', // Aurélien Léger-Moëc
    'albant', // Alban Truc
    'remoschatz', // Remo Schatz
    'tfeldhoff', // Tobias Feldhoff
    'mrudolph', // Matthias Rudolph
    'ivargas', // Ivan Vargas
];
export const hasUserAccessToRestrictedArticles = (user: IUser): boolean => trustedUsers.includes(user.username);
