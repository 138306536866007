import article from './articles/reducers/detail';
import articles from './articles/reducers/list';
import author from './authors/reducers/detail';
import authors from './authors/reducers/list';
import brief from './briefs/liveTransfers/reducers/detail';
import briefs from './briefs/liveTransfers/reducers/list';
import constantsFM from './components/constants/reducers';
import configurations from './configurations/reducers';
import highlighting from './highlighting/reducers';
import homepage from "./highlighting/components/focusBlocks/reducers";
import pageseo from './pageseos/reducers/detail';
import pageseos from './pageseos/reducers/list';
import constantsSeo from './pageseos/reducers/constants';
import revision from './revisions/reducers';
import searchFM from './search/reducers';
import tag from './tags/reducers/detail';
import tags from './tags/reducers/list';
import articleRelations from './articles/components/ArticleRelations/reducers/reducer';
export default {
  article,
  articles,
  author,
  authors,
  configurations,
  constantsFM,
  highlighting,
  homepage,
  brief,
  briefs,
  pageseo,
  pageseos,
  constantsSeo,
  revision,
  searchFM,
  tag,
  tags,
  articleRelations
};
