import React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';

function Float({
  input,
  labelKey,
  intl: { formatMessage: f },
  meta: { touched, error } = {},
  hintText,
  startAdornment,
  endAdornment,
  ...custom
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });
  return (
    <TextField
      label={label}
      error={Boolean(touched && error)}
      helperText={touched && error}
      type="number"
      InputProps={{
        placeholder: hintText || label,
        startAdornment,
        endAdornment,
      }}
      {...input}
      {...custom}
      fullWidth
    />
  );
}

export default injectIntl(Float);
