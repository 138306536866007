import React, { Component } from 'react';
import { FormEdit } from '../../../common/components/FormEdit';
import Date from '../../../common/components/inputs/Date';
import TextInput from '../../../common/components/inputs/Text';
import ClubInput from '../../clubs/Input';
import PersonInput from '../../people/Input';

export default class Form extends Component {
  config = {
    fields: ['id', 'name', 'filename', 'description', 'alt', 'sourceOfPurchase', 'copyright', 'expirationDate'],
    objectFields: ['club', 'person'],
    disposition: [
      {
        size: 3,
        imageBloc: true,
      },
      {
        size: 9,
        fields: [
          { name: 'filename', component: TextInput, disabled: true },
          { name: 'name', component: TextInput },
          { name: 'description', component: TextInput, required: true },
          { name: 'club', component: ClubInput, size: 6 },
          { name: 'person', component: PersonInput, size: 6 },
          { name: 'alt', component: TextInput, size: 6 },
          { name: 'sourceOfPurchase', component: TextInput, size: 6 },
          { name: 'copyright', component: TextInput, size: 6 },
          { name: 'expirationDate', component: Date, size: 6 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit, onDelete } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`image_${data.id}`}
        img={data.originalUrl}
        onSubmit={onSubmit}
        onDelete={onDelete}
        data={data}
        config={this.config}
      />
    );
  }
}
