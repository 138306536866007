import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import { isPristine } from 'redux-form';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styleSheet = theme => ({
  tabsContainer: {
    background: theme.palette.grey[100],
  },
  tabWithIcon: {
    alignItems: 'center',
    flexDirection: 'row-reverse',

    '& svg': {
      marginBottom: '0 !important',
    },
  },
});

@withStyles(styleSheet)
@injectIntl
@connect(store => ({
  form: store.form,
}))
export default class DetailTabs extends Component {
  state = {
    open: false,
  };

  redirect = tab => {
    const { history, match } = this.props;

    if (tab != '/') {
      history.push(`${match.url}${tab}`);
    } else {
      history.push(match.url);
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleConfirm = () => {
    this.handleClose();
    this.redirect(this.state.requestedTab);
  };

  handleChange = (event, tab) => {
    // Looks if all actives form are pristine
    const { form } = this.props;

    for (const variable in form) {
      if (!isPristine(variable)({ form })) {
        this.setState({ requestedTab: tab });
        return this.handleOpen();
      }
    }

    this.redirect(tab);
  };

  getCurrentTab = () => {
    const { history, match } = this.props;
    return history.location.pathname.substr(match.url.length) || '/';
  };

  render() {
    const {
      classes,
      menu,
      intl: { messages: m, formatMessage: f },
    } = this.props;
    return (
      <div>
        <Tabs
          value={this.getCurrentTab()}
          onChange={this.handleChange}
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          classes={{ flexContainer: classes.tabsContainer }}
        >
          {menu.map(tab => (
            <Tab
              classes={{ wrapper: classes.tabWithIcon }}
              label={f({ id: tab.label })}
              value={tab.link}
              icon={tab.hasBeenPushed && <CheckBoxIcon />}
            />
          ))}
        </Tabs>

        <Dialog open={this.state.open}>
          <DialogTitle>{f({ id: 'dialog_confirmTitle' })}</DialogTitle>
          <DialogContent>{f({ id: 'dialog_stillUpdating' })}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleClose}>
              {m.button_stay}
            </Button>
            <Button color="secondary" onClick={this.handleConfirm}>
              {m.button_continue}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
