import rest from 'Services/restFootData';

export function fetchBookmakers() {
  const params = {
    pagination: 0,
  };

  return function(dispatch, getState) {
    const storedBookmakers = getState().bookmakers;
    if (storedBookmakers.list && storedBookmakers.list.length) {
      return Promise.resolve({
        type: 'CACHED_BOOKMAKERS',
        payload: storedBookmakers,
      });
    }

    dispatch({ type: 'FETCH_BOOKMAKERS' });
    return rest
      .get('bookmakers', { params })
      .then(
        res => dispatch({ type: 'FETCH_BOOKMAKERS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_BOOKMAKERS_REJECTED', payload: err.response })
      );
  };
}
