import { Button } from '@material-ui/core';
import * as React from 'react';
import { CoachBloc } from './components/CoachBloc';
import { CompositionGrid } from './components/CompositionGrid';
import { CompositionsSelector } from './components/CompositionsSelector';
import { MatchTeamPlayerPanel } from './components/MatchTeamPlayerPanel';
import { OfficialLineup } from './components/OfficialLineup';
import { SubstitutesBloc } from './components/SubstitutesBloc';
import { IComponentProps } from './Composition.connector';
import {getProject} from "../../../common/services/project";

export class Composition extends React.Component<IComponentProps> {
  public state = {
    isRequesting: false,
  };

  public render() {
    const {
      intl: { messages: m },
      classes,
      mainCommentatorLocale,
    } = this.props;

    return (
      <div className={classes.container}>
        <div>
          <OfficialLineup side="home" />
          <MatchTeamPlayerPanel side="home" />
          <CompositionsSelector side="home" />
        </div>
        <div className={classes.mainContainer}>
          <div>
            <SubstitutesBloc key="substitutes-home" side="home" />
            <CoachBloc key="coach-home" side="home" />
          </div>
          <div>
            <CompositionGrid />
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.onPost}
                disabled={this.state.isRequesting || mainCommentatorLocale != getProject().lang}
              >
                {m.button_save}
              </Button>
            </div>
          </div>
          <div>
            <SubstitutesBloc key="substitutes-away" side="away" />
            <CoachBloc key="coach-away" side="away" />
          </div>
        </div>
        <div>
          <OfficialLineup side="away" />
          <MatchTeamPlayerPanel side="away" />
          <CompositionsSelector side="away" />
        </div>
      </div>
    );
  }

  private onPost = () => {
    this.setState({ isRequesting: true });
    // @ts-ignore dispatch type error
    this.props.postLiveComposition().then(() => this.setState({ isRequesting: false }));
  };
}
