import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import fetchAllChannels from './actions';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

@injectIntl
@connect(store => ({
  channels: store.channels,
}))
export default class SelectChannel extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAllChannels());
  }

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error } = {},
      intl: { formatMessage: f },
      labelKey,
      channels,
      style,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <FormControl fullWidth error={Boolean(touched && error)} style={style}>
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={event => onChange(event.target.value)}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem>
          {channels &&
            channels.list &&
            channels.list.map(value => (
              <MenuItem key={value.id} value={value['@id']}>
                {value.name}
              </MenuItem>
            ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
