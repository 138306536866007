import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import { createTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    ...blue,
    500: '#2277ef',
  },
  secondary: pink,
};

export default createTheme({
  palette,
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiList: {
      root: {
        // to fix MuiListSubheader transparency
        background: 'inherit',
      },
    },
    MuiTableRow: {
      root: {
        // for <a> rows
        textDecoration: 'none',
      },
    },
  },
});
