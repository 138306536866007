import React from 'react';
import { IComponentProps } from './Advanced.connector';
import { AwardMatch } from './components/AwardMatch';
import { ChangeMatchDate } from './components/ChangeMatchDate';
import { ChangeMatchScores } from './components/ChangeMatchScores';
import { CleanMatch } from './components/CleanMatch';
import { MatchEventChecker } from './components/MatchEventChecker';
import { PostponeMatch } from './components/PostponeMatch';
import { SuspendMatch } from './components/SuspendMatch';

export class Advanced extends React.PureComponent<IComponentProps> {
  public render() {
    const { match } = this.props;
    if (!match) return null;

    return (
      <div style={{ padding: 8, background: 'white' }}>
        <MatchEventChecker />
        <CleanMatch />
        <PostponeMatch />
        <SuspendMatch />
        <AwardMatch />
        <ChangeMatchDate />
        <ChangeMatchScores />
      </div>
    );
  }
}
