import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function AdditionnalTimeIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 500 500">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M125 247l31 0 0-12-31 0z m229 0l31 0 0-12-31 0z m-6 86l-31-31-2 2 31 31z m-153-153l-31-31-2 3 31 30z m153 153l-31-31-2 3 31 30z m-153-153l-31-31-2 2 31 31z m54 191l12 0 0-31-12 0z m0-229l12 0 0-31-12 0z m99 9l-2-2-31 31 2 2z m-153 154l-2-3-31 31 3 2z m60-73c-5 0-10 4-10 9 0 5 5 10 10 10 5 0 9-5 9-10 0-5-4-9-9-9z m0 12c-2 0-3-1-3-3 0-1 1-2 3-2 1 0 2 1 2 2 0 2-1 3-2 3z m111 221c-39 0-71-33-71-72 0-40 32-72 71-72 40 0 72 32 72 72 0 39-32 72-72 72z m38-76l-29 0 0-28c0-4-2-7-6-7-3 0-6 3-6 7l0 28-29 0c-3 0-6 3-6 6 0 4 3 6 6 6l29 0 0 29c0 3 3 6 6 6 4 0 6-3 6-6l0-29 29 0c3 0 6-2 6-6 0-3-3-6-6-6z m-29-15l0-13c0-4-3-7-7-7-3 0-6 3-6 7l0 23c-2 2-4 4-6 5l-22 0c-3 0-6 3-6 6 0 4 3 6 6 6l1 0c-11 5-22 10-33 13l4 17-12 2-4-16c-7 1-14 2-21 3l0 29-26 0 0-29c-7-1-14-2-21-3l-4 17-12-3 4-17c-76-20-132-89-132-171 0-98 80-178 178-178 98 0 178 80 178 178-1 51-24 98-59 131z m-120-282c-83 0-150 67-150 150 0 83 67 151 150 151 83 0 151-68 151-151 0-83-68-150-151-150z"
        />
      </g>
    </SvgIcon>
  );
}
