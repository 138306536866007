import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { ISide } from '../../../../matches/typings';
import { styles } from './styles';

interface IOwnProps {
  side: ISide;
}

export type IComponentProps = WithStyles & InjectedIntlProps & IOwnProps;

export const connector = compose<IComponentProps, IOwnProps>(
  withStyles(styles),
  injectIntl
);
