import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { parseQuery } from '../../../common/services/parseQuery';
import { IRootState } from '../../../typings';
import { addBrand, fetchAllBrands } from '../actions';

type IOwnProps = RouteComponentProps;

const mapDispatchToProps = {
  fetchAllBrands,
  addBrand,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    me: state.me,
    brands: state.brands.list,
    pagination: state.brands.pagination,
    fetching: state.brands.fetching,
    total: state.brands.totalItems,
    query: parseQuery(ownProps.location.search),
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
