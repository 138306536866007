import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';

import TextInput from 'Components/inputs/Text';
import SelectConstant from 'FDComponents/constants/Select';
import SelectSeason from 'FDComponents/seasons/Select';
import ImageInput from '../../images/Input';

export default class Form extends Component {
  config = {
    fields: ['id', 'name', 'category', 'mainColor', 'shopLink', 'type'],
    objectFields: ['season', 'image'],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'image', component: ImageInput }],
      },
      {
        size: 9,
        fields: [
          { name: 'name', component: TextInput },
          { name: 'category', component: SelectConstant, entity: 'product' },
          { name: 'type', component: SelectConstant, entity: 'product' },
        ],
      },
      {
        fields: [
          { name: 'season', component: SelectSeason, size: 6 },
          { name: 'mainColor', component: TextInput, size: 6 },
          { name: 'shopLink', component: TextInput, size: 6 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit, onDelete } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`product_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
