import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getProject } from 'Services/project';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { fetchTransfer, updateTransfer } from '../actions';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  transfer: store.transfer.detail[props.match.params.topicId],
  fetching: store.transfer.fetching,
}))
export default class Detail extends Component {
  menu = [{ label: 'app_transferInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchTransfer(targetId || id));
  };

  handleChange = transfer => {
    const { dispatch, id } = this.props;
    dispatch(updateTransfer(id, transfer));
  };

  render() {
    const {
      transfer,
      match,
      history,
      fetching,
      intl: { messages: m, formatMessage: f },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!transfer) {
      return <div>{m.error_noData}</div>;
    }
    return (
      <Layout
        id="transfer-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_transfers',
                href: `#${getProject().url}/transfers`,
              },
              { label: transfer.id },
            ]}
          />
        }
        main={
          <div>
            <Paper elevation={2}>
              <Toolbar>
                <Typography variant="h6">
                  {f(
                    { id: 'transfer_title' },
                    {
                      player: transfer.membership.personMatchName,
                      srcClub: transfer.previousMainTeam && transfer.previousMainTeam.name,
                      dstClub: transfer.mainTeam.name,
                    }
                  )}
                </Typography>
                <div style={{ flex: 1 }} />
                <ReturnButton entity="transfers" />
              </Toolbar>

              <TabsRoute match={match} menu={this.menu} history={history} />

              <Route exact path={match.url} render={() => <Form onSubmit={this.handleChange} data={transfer} />} />
            </Paper>
          </div>
        }
      />
    );
  }
}
