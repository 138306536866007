import { getLinkId } from 'Services/tools';

export function getItemBySlug(store, slug) {
  return store.slugs[slug] ? store.detail[store.slugs[slug]] : null;
}

export function getCollection(field, { detail: srcDetail, selectedId: id }, { detail: dstDetail }) {
  return (srcDetail[id] && srcDetail[id][field] && srcDetail[id][field].map(t => dstDetail[t]).filter(Boolean)) || [];
}

// Copy of getCollection method without selectedId
export const getInnerCollection = (collectionProperty, subEntityState, entityState, entityId) => {
  const entity = entityState.detail && entityState.detail[entityId];
  const innerStateDetail = subEntityState.detail;
  if (!entity) return null;
  const collectionIds = entity[collectionProperty];
  if (!collectionIds) return null;
  return collectionIds.map(subEntityId => innerStateDetail[subEntityId]).filter(Boolean);
};

export function getItemCollection(field, srcDetail, { detail: dstDetail }) {
  return (srcDetail && srcDetail[field] && srcDetail[field].map(t => dstDetail[t]).filter(Boolean)) || [];
}
export function getArray(ids, { detail }) {
  return ids && ids.map(t => detail[getLinkId(t)]).filter(Boolean);
}

export function getObject(field, { detail: srcDetail, selectedId: id }, { detail: dstDetail }) {
  return srcDetail[id] && srcDetail[id][field] && dstDetail[srcDetail[id][field]];
}
