import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { injectIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';

const CLUBTYPE = [
    {
        label: 'club',
        value: 'club',
    },
    {
        label: 'selection',
        value: 'selection',
    },
];

function clubTypeSelect({
                            input: { name, value, onChange },
                            labelKey,
                            intl: { formatMessage: f },
                            meta: { error, touched },
                        }) {
    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
    return (
        <FormControl style={{ minWidth: 450 }} error={touched && error}>
            <InputLabel htmlFor={`select_${name}`}>{label}</InputLabel>
            <Select value={value} label={label} onChange={event => onChange(event.target.value || '')}>
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {CLUBTYPE.map(cur => (
                    <MenuItem key={cur.value} value={cur.value}>
                        {cur.label}
                    </MenuItem>
                ))}
            </Select>
            {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
    );
}

export default injectIntl(clubTypeSelect);
