import * as React from 'react';
import { getSrc } from '../../../../../images/tools';
import { getPersonUsualName } from '../../../../../people/utils';
import { Content } from '../Content.component';
import { IComponentProps } from './CardContent.connector';

export class CardContent extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { commentary, membership } = this.props;
    return (
      !nextProps.membership ||
      !membership ||
      nextProps.commentary.type !== commentary.type ||
      nextProps.commentary.content !== commentary.content ||
      nextProps.membership.id !== membership.id
    );
  }

  public render() {
    const { commentary, membership, team } = this.props;
    if (!membership) {
      return null;
    }
    const person = membership.person;
    const text = this.getText(person, commentary.type);
    const teamLogo = this.getTeamPhoto(team);
    const personPhoto = this.getPersonPhoto(person);
    const className = this.getClassName()[commentary.type];

    return (
      <React.Fragment>
        <div className={'comment comment--event comment--card ' + className}>
          <div className="comment__pictures comment__pictures--round">
            <div className="comment__pictures__player">{personPhoto}</div>
            <div className="comment__pictures__team comment__pictures__team--right">{teamLogo}</div>
          </div>
          <div className="comment__text">
            <p className="comment__text__title">{text}</p>
          </div>
        </div>
        <Content content={commentary.content} />
      </React.Fragment>
    );
  }

  private getClassName = () => ({
    red_card: 'comment--card--red',
    yellow_card: 'comment--card--yellow',
    second_yellow_card: 'comment--card--second_yellow',
  });

  private getText = (person, type) => {
    const {
      intl: { formatMessage: f },
    } = this.props;
    const param = { person: getPersonUsualName(person) };
    let key = '';
    switch (type) {
      case 'red_card':
        key = 'live_commentary_card_red';
        break;
      case 'yellow_card':
        key = 'live_commentary_card_yellow';
        break;
      case 'second_yellow_card':
        key = 'live_commentary_card_second_yellow';
        break;
    }

    return key ? f({ id: key }, param) : '';
  };

  private getPersonPhoto = person => {
    const src = getSrc(person.photo) || '/img/person.jpg';
    return <img src={src} alt={getPersonUsualName(person)} width="50" height="50" />;
  };
  private getTeamPhoto = team => {
    return team.club.logo ? <img src={getSrc(team.club.logo, 20)} alt={team.name} width="25" height="25" /> : null;
  };
}
