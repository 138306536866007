import rest from 'Services/restFootMercato';
import { SubmissionError } from 'redux-form';

export function fetchFocusBlocks() {
    const params = {
        'pagination[enablePagination]': 0
    };

    return function(dispatch) {
    dispatch({ type: 'FETCH_FOCUS_BLOCKS' });

    return rest.get('focus_blocks', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_FOCUS_BLOCKS_FULFILLED',
          payload: { list: res.data },
        }),
      err =>
        dispatch({
          type: 'FETCH_FOCUS_BLOCKS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateFocusBlock(id, item) {
    return function(dispatch) {
        dispatch({ type: 'UPDATE_FOCUS_BLOCK', payload: id });

        return rest.put(`focus_blocks/${id}`, item).then(
        res => {
            dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { messageKey: 'message_focusBlockUpdated', type: 'success' },
            });
            return dispatch({
            type: 'UPDATE_FOCUS_BLOCK_FULFILLED',
            payload: res.data,
            });
        },
        err => {
            if (
            err.response &&
            err.response.data.type === 'ConstraintViolationList'
            ) {
            const { description, errors } = err.response.data;
            dispatch({
                type: 'ADD_NOTIFICATION',
                payload: { message: description, type: 'error' },
            });
            throw new SubmissionError(errors);
            }
        }
        );
    };
}
