import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import RulePanel from './Panel';

@injectIntl
export class TournamentRankingRules extends Component {
  render() {
    const {
      rankingRulesIds,
      intl: { messages: m },
    } = this.props;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_rankingRules}</Typography>
        </Toolbar>
        <div style={{ padding: 16 }}>
          {rankingRulesIds ? rankingRulesIds.map(rr => <RulePanel id={rr} />) : null}
        </div>
      </div>
    );
  }
}
