import { Paper, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import ToggleFilter from '../../../common/components/ToggleFilter';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce, { getLinkId } from '../../../common/services/tools';
import CompetitionInput from '../../competitions/Input';
import MatchInput from '../../matches/Input';
import PersonInput from '../../people/Input';
import TeamInput from '../../teams/Input';
import SurveyDialog from '../SurveyDialog';
import { ISurvey } from '../typings';
import { SurveyRow } from './SurveyRow.component';
import { IComponentProps } from './SurveysList.connector';

// @ts-ignore
export class SurveysList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'surveyId',
        labelKey: 'field_id',
      },
      {
        type: 'surveyCompetition',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
      {
        type: 'surveyTeam',
        input: TeamInput,
        labelKey: 'filter_team',
      },
      {
        type: 'surveyMatch',
        input: MatchInput,
        labelKey: 'filter_match',
      },
      {
        type: 'surveyPerson',
        input: PersonInput,
        labelKey: 'filter_person',
      },
    ],
    toggles: [
      {
        type: 'surveyCurrentLocaleOnly',
        labelKey: 'field_currentLocaleOnly',
      },
    ],
    headers: [
      { labelKey: 'field_question' },
      { labelKey: 'field_isAvailable' },
      { labelKey: 'field_isEnabled' },
      { id: 'startDate', labelKey: 'field_startDate' },
      { id: 'endDate', labelKey: 'field_endDate' },
      { labelKey: 'field_translations' },
      { labelKey: 'field_hasPartner' },
      { id: 'totalParticipantsCount', labelKey: 'field_totalParticipantsCount' },
      { labelKey: 'field_translate' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/surveys${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      surveys,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;
    return (
      <Layout
        id="surveys-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_surveys' }]} />
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_surveys}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <SurveyDialog title={m.dialog_addSurvey} form="survey_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </SurveyDialog>
              </UserRestriction>
            </Toolbar>
            <ToggleFilter
              form="surveys_togglefilters"
              toggles={this.config.toggles}
              onChange={this.handleToggles}
              initialValues={query.toggles}
            />
            <SearchFilter
              form="surveys_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={surveys}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={SurveyRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchSurveys, filterList } = this.props;
    fetchSurveys(filter);
    filterList('surveys', filter);
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  private handleFilters = change => {
    const { surveyTeam, surveyCompetition, surveyMatch, surveyPerson, surveyId } = change;
    const filtersQuery = {
      surveyTeam: getLinkId(surveyTeam),
      surveyCompetition: getLinkId(surveyCompetition),
      surveyMatch: getLinkId(surveyMatch),
      surveyPerson: getLinkId(surveyPerson),
      surveyId,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private handleToggles = change => {
    this.changeParams({ type: 'SET_TOGGLE', payload: change });
  };

  private submitAdd = (survey: Partial<ISurvey>) => {
    const { addSurvey, history } = this.props;

    // @ts-ignore
    addSurvey(survey).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/surveys/${payload.id}`);
      }
    });
  };
}
