import { WithStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import { selectMatch } from '../../matches/selector';

const mapStateToProps = (state: IRootState) => {
  return {
    isTypeCompleted: state.live.commentary.isTypeCompleted,
    game: selectMatch(state, state.live.matchId),
  };
};

export type IComponentProps = ReturnType<typeof mapStateToProps> & WithStyles;

const reduxConnector = connect(mapStateToProps);
export const connector = compose<IComponentProps, {}>(reduxConnector);
