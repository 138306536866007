import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { IRootState } from '../../typings';
import { setCommentaryDefaultContent } from '../commentaries/actions';
import { selectMatch } from '../matches/selector';
import { fetchLiveData } from './actions';

import { styles } from './styles';

const mapDispatchToProps = {
  fetchLiveData,
  setCommentaryDefaultContent,
};

type IOwnProps = RouteComponentProps<{ topicId: string }>;

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const id = ownProps.match.params.topicId;
  return {
    id,
    game: selectMatch(state, id),
    isTypeCompleted: state.live.commentary.isTypeCompleted,
    error: state.live.error,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  WithStyles &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
