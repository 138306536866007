import * as React from 'react';
import AsyncSelect from 'react-select/async';
import debounce from "../../../../common/services/tools";
import {IComponentProps, IPartnerOfferOption} from "./PartnerInput.connector";

export interface IComponentState {
    open: boolean;
    anchorEl: undefined;
    selectedValue: IPartnerOfferOption | null;
}

export class PartnerInput extends React.Component<IComponentProps,IComponentState> {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            anchorEl: undefined,
            selectedValue: this.props.input.value,
        };
        const { fetchPartnerOffers } = this.props;
        fetchPartnerOffers()
    }


    public render() {
        const { selectedValue } = this.state;
        let selectedOffer : any = {};
        let value = { value: '', label: ''}
        if (selectedValue) {
            if (selectedValue.label && selectedValue.value)
            {
               value = { value: selectedValue.value, label: selectedValue.label};
            }
            else if (this.props.partnerOffers.list.length > 0) {
                selectedOffer = selectedValue ? this.props.partnerOffers.list.find(offer => offer['@id'] === selectedValue) : null;
                value = { value: selectedOffer.id, label: this.getLabel(selectedOffer)};
            }
        }

        return (
            <AsyncSelect
                value={value}
                onChange={this.handleChange}
                isClearable
                defaultOptions={this.loadOptions}
                loadOptions={ this.loadOptions }
                placeholder="PartnerOffer"
            />
        );
    }
    private  getLabel = result => {

        if (result.label)
        {
            return result.label;
        }
        return result.id + ' : '+result.partner.name + ' - '+result.shortText;
    }

    private loadOptions = debounce((inputValue: string, callback: (options: IPartnerOfferOption[]) => void) => {
        const { partnerOffers } = this.props ;
        if (Array.isArray(partnerOffers.list)) {
            const partnerOffersList = partnerOffers.list.filter(
                (offer) => {
                    return offer.partner.id = inputValue || offer.partner.name.includes(inputValue) === false || offer.shortText.includes(inputValue) === false;
                })
            const options = partnerOffersList.map(object => ({
                value: object.id,
                label: this.getLabel(object),
            }));
            callback(options);
        }
        callback([]);
    }, 300);



    private handleChange = selectedValue => {
        const {input} = this.props;
        this.setState({selectedValue});
        input.onChange(selectedValue ? selectedValue.value : null);
    };
}
