import { IconButton, InputAdornment, TextField, ThemeProvider } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Close from '@material-ui/icons/Close';
import * as React from 'react';
import { IMembership } from '../../../../memberships/typings';
import { getPersonUsualName } from '../../../../people/utils';

interface IProps {
  title: string;
  helperText: string;
  player: any;
  onRemove: () => void;
  theme: any;
}

export class PlayerParams extends React.Component<IProps> {
  public render() {
    const { player, title, helperText, onRemove, theme } = this.props;

    const person = player ? player.membership.person : null;
    let playerName = '';
    if (player && person) {
      playerName = this.getMembershipLabel(player.membership);
    }
    const startAdornment = (
      <InputAdornment position="start">
        <AccountCircle color="primary" />
      </InputAdornment>
    );
    const endAdornment = person ? (
      <InputAdornment position="end">
        <IconButton onClick={onRemove}>
          <Close />
        </IconButton>
      </InputAdornment>
    ) : null;

    return (
      <ThemeProvider theme={theme}>
        <div style={{ marginTop: 16 }}>
          <TextField
            id={title}
            label={title}
            value={playerName}
            disabled={!person}
            variant="outlined"
            InputProps={{
              startAdornment,
              endAdornment,
            }}
            helperText={helperText}
          />
        </div>
      </ThemeProvider>
    );
  }

  private getMembershipLabel = (membership: IMembership) => {
    if (!membership) return '';
    return getPersonUsualName(membership.person);
  };
}
