export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_ME': {
      return {
        ...state,
        fetching: true,
      };
    }

    case 'FETCH_ME_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
