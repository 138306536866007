import { Button, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { EShortcut } from '../ShortcutHandler/ShortcutHandler.component';
import { IComponentProps } from './ActionButtons.connector';

const styleSheet = (theme: Theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(),
  },
});

// @ts-ignore
@withStyles(styleSheet)
export class ActionButtons extends React.PureComponent<IComponentProps> {
  public render() {
    const { classes, editingCommentaryId } = this.props;

    return (
      <div className={classes!.buttonContainer}>
        {editingCommentaryId ? this.renderEditionActions() : this.renderActions()}
      </div>
    );
  }

  private renderEditionActions = () => {
    const {
      updateLiveCommentary,
      resetLive,
      isSubmitButtonEnable,
      intl: { messages: m },
    } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          onClick={updateLiveCommentary}
          disabled={!isSubmitButtonEnable}
          title={EShortcut.Save}
        >
          {m.button_update}
        </Button>
        <Button onClick={resetLive}>{m.button_cancel}</Button>
      </React.Fragment>
    );
  };

  private renderActions = () => {
    const {
      createLiveCommentary,
      resetLive,
      isSubmitButtonEnable,
      intl: { messages: m },
    } = this.props;
    return (
      <React.Fragment>
        <div style={{ width: 80 }} />
        <Button
          variant="contained"
          color="primary"
          onClick={createLiveCommentary}
          disabled={!isSubmitButtonEnable}
          title={EShortcut.Save}
        >
          {m.button_publish}
        </Button>
        <Button onClick={resetLive}>{m.button_reset}</Button>
      </React.Fragment>
    );
  };
}
