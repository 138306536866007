import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap' as 'wrap',
    padding: 4,
    background: theme.palette.grey[100],
  },
  blockField: {
    display: 'flex',
    margin: 4,
  },
  field: {
    margin: 0,
    width: 250,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  closeButton: {
    width: 24,
    height: 24,
    marginTop: 16,
    padding: 0,
  },
});
