import React, { Component } from 'react';

const inlineStyles = {
  string: 'color:red',
  number: 'color:blue',
  boolean: 'color:blue',
  null: 'color:grey',
  key: 'color:purple',
};

export default class Change extends Component {
  syntaxHighlight(rawJson) {
    let json = rawJson;
    if (typeof json != 'string') {
      json = JSON.stringify(json, null, 2);
    }

    if (!json) return `<span style="${inlineStyles.null}">null</span>`;

    json = json
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null|undefined)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      match => {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null|undefined/.test(match)) {
          cls = 'null';
        }
        return `<span style="${inlineStyles[cls]}">${match}</span>`;
      }
    );
  }

  render() {
    const { change } = this.props;
    const htmlRender = this.syntaxHighlight(JSON.stringify(change, null, 2));
    return <pre dangerouslySetInnerHTML={{ __html: htmlRender }} />;
  }
}
