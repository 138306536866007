import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

export function updateVenue(id, venue) {
  const params = {
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_VENUE', payload: id });

    return rest.put(`venues/${id}`, venue, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_venueUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_VENUE_FULFILLED', payload: res.data });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_VENUE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addVenue(venue, target = '') {
  const entity = target ? `_${target.toUpperCase()}` : target;
  return function(dispatch) {
    dispatch({ type: `ADD${entity}_VENUE` });

    return rest.post(`venues`, venue).then(
      res =>
        dispatch({ type: `ADD${entity}_VENUE_FULFILLED`, payload: res.data }),
      err =>
        dispatch({
          type: `ADD${entity}_VENUE_REJECTED`,
          payload: err.response,
        })
    );
  };
}

export function deleteVenue(venue) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_VENUE' });

    return rest
      .delete(`venues/${venue.id}`)
      .then(
        () => dispatch({ type: 'DELETE_VENUE_FULFILLED', payload: venue }),
        err =>
          dispatch({ type: 'DELETE_VENUE_REJECTED', payload: err.response })
      );
  };
}

export function fetchClubVenues(club) {
  const params = {
    club,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUB_VENUES', payload: club });

    return rest.get(`venues`, { params }).then(
      res =>
        dispatch({ type: 'FETCH_CLUB_VENUES_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_CLUB_VENUES_REJECTED',
          payload: err.response,
        })
    );
  };
}
