import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SurveyDetail } from './Detail';
import { SurveysList } from './List';

export const SurveysRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={SurveysList} />
      <Route path={`${match.url}/:topicId`} component={SurveyDetail} />
    </Switch>
  );
};
