import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Detail from './Detail';

export default function CorrectionsRoutes({ match }) {
  return (
    <Switch>
      <Route exact path={match.url} component={List} />
      <Route path={`${match.url}/:topicId`} component={Detail} />
    </Switch>
  );
}
