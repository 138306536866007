import { Paper, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce from '../../../common/services/tools';
import PartnerDialog from '../PartnerDialog';
import { IPartner } from '../typings';
import { PartnerRow } from './PartnerRow.component';
import { IComponentProps } from './PartnersList.connector';

// @ts-ignore
export class PartnersList extends React.Component<IComponentProps> {
  private config = {
    headers: [{ labelKey: 'field_name' }, { labelKey: 'field_sector' }],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/partners${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      partners,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;
    return (
      <Layout
        id="partners-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_partners' }]} />
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_partners}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <PartnerDialog title={m.dialog_addPartner} form="partner_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </PartnerDialog>
              </UserRestriction>
            </Toolbar>
            <TableList
              tableData={partners}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={PartnerRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchPartners } = this.props;
    fetchPartners(filter);
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  private submitAdd = (partner: Partial<IPartner>) => {
    const { addPartner, history } = this.props;

    // @ts-ignore
    addPartner(partner).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/partners/${payload.id}`);
      }
    });
  };
}
