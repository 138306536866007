import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import fetchConstants from './actions';

@injectIntl
@connect((store, props) => ({
  constants: store.constants[props.entity][props.input.name],
}))
export default class SelectConstant extends Component {
  componentDidMount() {
    const { dispatch, entity, input } = this.props;
    dispatch(fetchConstants(entity, input.name));
  }

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error } = {},
      intl: { formatMessage: f },
      labelKey,
      constants,
      className,
      nullable,
      disabled,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
    const isSelectReady = Boolean(constants && constants.list);

    return (
      <FormControl
        fullWidth
        error={Boolean(touched && error)}
        className={className}
        disabled={disabled}
      >
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        {isSelectReady ? (
          <Select
            name={name}
            value={String(value)}
            onChange={event => onChange(event.target.value)}
            input={<Input id={`select_${label}`} />}
            MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
          >
            {nullable && (
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
            )}
            {Object.entries(constants.list).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </Select>
        ) : null}
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
