import React from 'react';
import { ICommentary } from '../../../../commentaries/typings';
import { CardContent } from './CardContent';
import { Content } from './Content.component';
import { GoalContent } from './GoalContent';
import { PenaltyContent } from './PenaltyContent';
import { SubstituteContent } from './SubstituteContent';

interface IProps {
  commentary: ICommentary;
}

export class CommentaryContent extends React.Component<IProps> {
  public shouldComponentUpdate(nextProps: IProps) {
    const { commentary } = this.props;
    if (nextProps.commentary.updatedAt !== commentary.updatedAt) {
      return true;
    }
    if (nextProps.commentary.matchEvent) {
      if (nextProps.commentary.matchEvent.updatedAt !== commentary.matchEvent.updatedAt) {
        return true;
      }
    }
    return false;
  }

  public render() {
    const { commentary } = this.props;
    if (commentary.matchEvent) {
      switch (commentary.type) {
        case 'yellow_card':
        case 'second_yellow_card':
        case 'red_card':
          return <CardContent commentary={commentary} />;

        case 'goal':
        case 'penalty_goal':
        case 'own_goal':
          return <GoalContent commentary={commentary} />;
        case 'substitute':
          return <SubstituteContent commentary={commentary} />;
        case 'penalty_shot_scored':
        case 'penalty_shot_saved':
        case 'penalty_shot_missed':
        case 'missed_penalty':
          return <PenaltyContent commentary={commentary} />;
      }
    }
    return <Content content={commentary.content} />;
  }
}
