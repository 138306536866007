import { EditorView } from '@uiw/react-codemirror';

export interface SuperSubError {
  category: SSCategory;
  context: string;
  length: number;
  message: string;
  offset: number;
  replacements: string[];
  type: string;
}
type SSCategory =
  | 'TYPOGRAPHY' // Faute de frappe possible : une espace est répétée
  | 'TYPOS' // Faute de frappe possible trouvée.
  | 'CAT_TOURS_CRITIQUES' // Une autre préposition semble plus appropriée
  | 'MISC' //Le guillemet ouvrant est suivi d’une espace insécable
  | 'CAT_TYPOGRAPHIE' // Une virgule semble être requise dans ce contexte
  | 'STYLE' // Ce mot apparaît déjà dans l’une des phrases précédant immédiatement celle-ci. Utilisez un synonyme pour apporter plus de variété à votre texte, excepté si la répétition est intentionnelle
  | 'CAT_ANGLICISMES' // « Deal » peut être considéré comme un anglicisme.
  | 'REPETITIONS_STYLE' // "Dans un contexte formel, d’autres structures peuvent être utilisées pour enrichir votre style."
  | 'CAT_GRAMMAIRE' // Vérifiez l’accord entre le pronom « je » et le verbe « boit ».
  | 'PONCTUATION_VIRGULE'; // Une virgule est requise dans ce contexte.

export type WordError = Pick<SuperSubError, 'category' | 'message' | 'replacements'> & {
  tag: string;
  from: number;
  to: number;
  empty: boolean;
  selection: string;
};

export class SuperSub {
  languageToolURL: string;
  maxSuggestions: number;
  wordErrors: WordError[];
  ignoredWords: string[];

  constructor() {
    this.languageToolURL = 'https://supersub-api-xpnxc4putq-uc.a.run.app';
    this.wordErrors = [];
    this.ignoredWords = ['iframe', 'frameborder', 'width', 'height', 'src', 'allowfullscreen', 'allow', 'autoplay'];
    this.maxSuggestions = 5; // TODO use it to limit suggestions in the request to avoid
  }

  async getGrammaticalErrors(text: string): Promise<SuperSubError[] | null> {
    try {
      const response = await fetch(`${this.languageToolURL}/grammar-checker`, {
        method: 'POST',
        body: JSON.stringify({ text, lang: 'all' }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      return data.errors;
    } catch (error) {
      return null;
    }
  }
  setWordErrors(errors: WordError[]) {
    this.wordErrors = errors;
  }

  formatWordErrors(errors: SuperSubError[], view: EditorView): WordError[] {
    const wordErrors = errors
      .map(error => ({
        from: error.offset,
        to: error.offset + error.length,
        empty: false,
        replacements: error.replacements.slice(0, 5),
        message: error.message,
        category: error.category,
        tag: getTagFromCategory(error.category),
        selection: view.state.doc.sliceString(error.offset, error.offset + error.length),
      }))
      .filter(({ selection }) => {
        // remove ignored words
        return !this.ignoredWords.includes(selection);
      });

    this.setWordErrors(wordErrors);
    return wordErrors;
  }

  ignoreWord(word: string) {
    this.ignoredWords.push(word);
  }
}

const tagGroups = {
  spelling: ['TYPOGRAPHY', 'TYPOS'],
  grammar: ['CAT_GRAMMAIRE'],
  syntax: ['CAT_TOURS_CRITIQUES', 'CAT_TYPOGRAPHIE', 'MISC', 'PONCTUATION_VIRGULE'],
  sense: [],
  content: ['STYLE', 'CAT_ANGLICISMES', 'REPETITIONS_STYLE'],
};
const getTagFromCategory = (category: WordError['category']) => {
  for (const tag in tagGroups) {
    if (tagGroups[tag].includes(category)) {
      return tag;
    }
  }
  return 'other';
};
