import React from "react";
import {Typography} from "@material-ui/core";
import {IMatch, IMatchPronosticStat, IPronosticParticipantByWinner} from "../typings";
import {injectIntl} from "react-intl";
import {getAtInternetStat} from "../actions";

interface IProps {
    match: IMatch;
    matchPronosticStat: IMatchPronosticStat;
    intl: any;
}

// @ts-ignore
@injectIntl
export class MatchPronosticStat extends React.Component<IProps> {
    state = {};

    public componentDidMount() {
        const { match } = this.props;
        getAtInternetStat(match).then(payload => {
            this.setState({ analyticsData: payload });
        });
    }

    public render() {
        const {
            matchPronosticStat,
            intl: { messages: m },
        } = this.props;

        if (!matchPronosticStat) {
            return (
                <div style={{ padding: 16 }}>
                    <span>{m.pronostic_notFound}</span>
                </div>
            )
        }
        // @ts-ignore
        return (
            <div>
                <div style={{ padding: 16 }}>
                    <Typography variant="h6">{m.app_statistics}</Typography>
                    <ul>
                        <li>
                            {m.survey_totalParticipantsCount}: {matchPronosticStat.totalParticipantsCount || '-'}
                        </li>
                        <li>
                            {m.survey_totalParticipantsBySource}: {this.renderCountBySource()}
                        </li>
                        <li>
                            {m.survey_countByOptions}:
                            <ul>
                                {matchPronosticStat.stats &&
                                    matchPronosticStat.stats.length > 0 &&
                                    matchPronosticStat.stats.map((option, index) => (
                                        <li key={index}>
                                            {m['pronostic_winner' + option.winner]}: {option.voteCount} ({this.getPercentageByOption(option)}%)
                                        </li>
                                    ))}
                            </ul>
                        </li>
                    </ul>
                    {this.renderAnalyticsData()}
                </div>
            </div>
        );
    }

    public getPercentageByOption(option: IPronosticParticipantByWinner) {
        const { matchPronosticStat } = this.props;
        // @ts-ignore
        if (matchPronosticStat.totalParticipantsCount > 0) {
            // @ts-ignore
            return Math.ceil((option.voteCount * 100) / matchPronosticStat.totalParticipantsCount);
        }

        return 0;
    }

    public renderCountBySource() {
        const { matchPronosticStat } = this.props;
        if (!matchPronosticStat) {
            return null;
        }
        if (!matchPronosticStat.participantsCountBySource || Object.entries(matchPronosticStat.participantsCountBySource).length === 0) {
            return '-';
        }

        let countBySource: {label: string, count: number}[] = [];
        for (const [sourceName, count] of Object.entries(matchPronosticStat.participantsCountBySource)) {
            countBySource.push({label: sourceName, count});
        }
        return (
            <ul>
                {countBySource.map((countBySource, index) => (
                    <li key={index}>
                        {countBySource.label}: {countBySource.count}
                    </li>
                ))}
            </ul>
        );
    }

    public renderAnalyticsData() {
        // @ts-ignore
        const { analyticsData } = this.state;
        const {
            intl: { messages: m },
        } = this.props;
        if (!analyticsData || !analyticsData.DataFeed || !analyticsData.DataFeed.Rows) {
            return '';
        }

        return (
            <div>
                <Typography variant="h6">{m.analytics_stats}</Typography>
                <ul>
                    <li>
                        {m.analytics_totalViews}: {this.getAnalyticsEventCountByName('prono.visible')}
                    </li>
                    <li>
                        {m.analytics_totalAnswersCreated}: {this.getAnalyticsEventCountByName('prono.answer')}
                    </li>
                    <li>
                        {m.analytics_totalAnswersUpdated}: {this.getAnalyticsEventCountByName('prono.answer.updated')}
                    </li>
                    <li>
                        {m.analytics_totalAffiliationClicks}: {this.getAnalyticsEventCountByName('prono.clickaffiliation')}
                    </li>
                    <li>
                        {m.analytics_totalShares}: {this.getAnalyticsEventCountByName('prono.share_modal.share')}
                    </li>
                </ul>
            </div>
        );
    }

    public getAnalyticsEventCountByName(eventName) {
        // @ts-ignore
        const { analyticsData } = this.state;
        const row = analyticsData.DataFeed.Rows.find(x => x.event_name === eventName);
        if (!row) {
            return '-';
        }

        return row.m_visits;
    }
}
