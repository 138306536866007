import { WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { updateMatch } from '../../../../matches/actions';

interface IOwnProps {}

const mapDispatchToProps = {
  updateMatch,
};

const mapStateToProps = (state: IRootState) => {
  return {
    matchId: state.live.matchId,
    isUpdating: state.match.updating,
    mainCommentatorLocale: state.live.mainCommentatorLocale,
  };
};

export type IComponentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedIntlProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
