import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import DateCell from 'Components/TableList/cell/Date';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import debounce from 'Services/tools';
import { ArticleStatusIcon } from '../ArticleStatusIcon';
import search from './actions';

const styles = theme => ({
    flex: {
        flex: 1,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
        zIndex: '10',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        maxHeight: '60vh',
        overflowY: 'auto',
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
    textField: {
        width: '100%',
    },
    avatar: {
        background: 'transparent',
        color: 'grey',
    },
    highlight: {
        color: theme.palette.primary[500],
    },
    label: {
        flex: 1,
        fontWeight: 500,
    },
    date: {
        color: 'grey',
        fontSize: 14,
    },
    input: {
        width: 'calc(100% - 32px)',
    },
});

@injectIntl
@withStyles(styles)
@withRouter
@connect(store => ({
    results: store.searchFM.article,
}))
export default class Search extends Component {
    state = {
        searchText: '',
        suggestions: [],
        input: this.props.input,
    };

    handleSuggestionsFetchRequested = debounce(({ value }) => {
        const { dispatch } = this.props;
        dispatch(search(value)).then(() => {
            this.setState({
                suggestions: this.getSuggestions(value),
            });
        });
    }, 500);

    handleChange = (event, { newValue }) => {
        this.setState({ searchText: newValue });
    };

    handleSuggestionSelected = (event, { suggestion }) => {
        const { input } = this.state;

        input.onChange(`/api/1.0/articles/${suggestion.id}`);
        this.setState({
            searchText: '',
            input: { ...input, value: suggestion }, });
    };

    renderInput = inputProps => {
        const { classes, value, ref, label, hintText, ...other } = inputProps;
        return (
            <TextField
                className={classes.textField}
                value={value}
                inputRef={ref}
                label={label}
                InputProps={{
                    classes: { input: classes.input },
                    placeholder: hintText || label,
                    ...other,
                }}
            />
        );
    };

        renderSuggestion = (suggestion, { query, isHighlighted }) => {
        const { classes } = this.props;
        const label = this.getSuggestionValue(suggestion);
        const matches = match(label, query);
        const parts = parse(label, matches);

        return (
            <MenuItem selected={isHighlighted} component="div" style={{ display: 'flex' }}>
                {suggestion.status ? (
                    <Avatar classes={{ root: classes.avatar }}>
                        <ArticleStatusIcon status={suggestion.status} />
                    </Avatar>
                ) : null}
                <div className={classes.label}>
                    {parts.map((part, index) =>
                            part.highlight ? (
                                <span key={index} className={classes.highlight}>
                {part.text}
              </span>
                            ) : (
                                <span key={index}>{part.text}</span>
                            )
                    )}
                </div>
                <div className={classes.date}>
                    <DateCell date={suggestion.publishedAt || suggestion.createdAt} />
                </div>
            </MenuItem>
        );
    };

    renderSuggestionsContainer = ({ containerProps, children }) => (
        <div>
            {children && (
                <Paper {...containerProps} square>
                    {children}
                </Paper>
            )}
        </div>
    );
    getSuggestionValue = suggestion => suggestion.title;

    getSuggestions = value => {
        const { results } = this.props;
        const inputLength = value.trim().length;
        return inputLength === 0 ? [] : results;
    };

    render() {
        const {
            autoFocus,
            classes,
            intl: { messages: m },
        } = this.props;
        const { searchText, suggestions, input } = this.state;
        return (
            <div id="main-search" className={classes.flex}>
                <Autosuggest
                    id="search"
                    theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                    }}
                    renderInputComponent={this.renderInput}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
                    onSuggestionSelected={this.handleSuggestionSelected}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={{
                        fullWidth: true,
                        value: input.value.title || searchText || '',
                        placeholder: m.app_articleRelated,
                        autoFocus,
                        onChange: this.handleChange,
                        classes,
                        label: m.app_articleRelated,
                        name: input.name,
                        required: input.required,
                    }}
                />
            </div>
        );
    }
}
