import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Route } from 'react-router-dom';
import { IdBadge } from '../../../common/components/IdBadge';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { TYPE_SQUAD } from '../typings';
import { IComponentProps } from './LineupSurveyDetail.connector';
import { LineupSurveyForm } from './LineupSurveyForm';
import { LineupSurveyStat } from './LineupSurveyStat';

export class LineupSurveyDetail extends React.Component<IComponentProps> {
  private POSITION_STRIKER = 'striker';
  private POSITION_MIDFIELDER = 'midfielder';
  private POSITION_DEFENDER = 'defender';
  private POSITION_GOALKEEPER = 'goalkeeper';

  public componentDidMount() {
    const { id, getLineupSurvey } = this.props;
    getLineupSurvey(id);
  }

  public render() {
    const {
      fetching,
      lineupSurvey,
      match,
      history,
      intl: { messages: m },
    } = this.props;
    const menu = [{ label: 'app_info', link: '/' }, { label: 'app_statistics', link: '/stats' }];

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }

    if (!lineupSurvey) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="survey-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_lineup_surveys', href: `#${getProject().url}/lineup-surveys?type=${TYPE_SQUAD}` },
              { label: lineupSurvey.title },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{lineupSurvey.title}</Typography>
              <IdBadge id={lineupSurvey.id} />
              <div style={{ flex: 1 }} />
              {this.renderPreviewButton()}
              <ReturnButton entity="lineup-surveys" />
            </Toolbar>
            <TabsRoute match={match} menu={menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
            <Route exact path={`${match.url}/stats`} render={this.renderStats} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { lineupSurvey } = this.props;
    const formData: any = {...lineupSurvey};
    formData.strikers = lineupSurvey.selectablePlayers
      .filter(lineupSurveyPerson => lineupSurveyPerson.position === this.POSITION_STRIKER)
      .map(lineupSurveyPerson => lineupSurveyPerson.person)
    ;
    formData.midfielders = lineupSurvey.selectablePlayers
      .filter(lineupSurveyPerson => lineupSurveyPerson.position === this.POSITION_MIDFIELDER)
      .map(lineupSurveyPerson => lineupSurveyPerson.person)
    ;
    formData.defenders = lineupSurvey.selectablePlayers
      .filter(lineupSurveyPerson => lineupSurveyPerson.position === this.POSITION_DEFENDER)
      .map(lineupSurveyPerson => lineupSurveyPerson.person)
    ;
    formData.goalkeepers = lineupSurvey.selectablePlayers
      .filter(lineupSurveyPerson => lineupSurveyPerson.position === this.POSITION_GOALKEEPER)
      .map(lineupSurveyPerson => lineupSurveyPerson.person)
    ;

    return <LineupSurveyForm data={formData} onSubmit={this.handleUpdate}/>
  }

  private renderStats = () => {
    const { lineupSurvey, intl} = this.props;
    return <LineupSurveyStat lineupSurvey={lineupSurvey} intl={intl} />;
  };

  private handleUpdate = (lineupSurvey) => {
    const { id, updateLineupSurvey } = this.props;
    lineupSurvey.selectablePlayers = [
      ...this.addSelectablePlayer(lineupSurvey.strikers, this.POSITION_STRIKER),
      ...this.addSelectablePlayer(lineupSurvey.midfielders, this.POSITION_MIDFIELDER),
      ...this.addSelectablePlayer(lineupSurvey.defenders, this.POSITION_DEFENDER),
      ...this.addSelectablePlayer(lineupSurvey.goalkeepers, this.POSITION_GOALKEEPER),
    ]

    return updateLineupSurvey(id, lineupSurvey);
  }

  private renderPreviewButton = () => {
    const {
      lineupSurvey,
      intl: { messages: m },
    } = this.props;

    if (lineupSurvey.type !== TYPE_SQUAD) {
      return null;
    }

    let uri: string|null;
    switch (getProject().lang) {
      case 'fr':
        uri = `fais-ta-liste/${lineupSurvey.id}-${lineupSurvey.slug}`;
        break;
      case 'de':
        uri = `mache-deine-aufstellung/${lineupSurvey.id}-${lineupSurvey.slug}`;
        break;
      case 'es':
        uri = `hacer-una-lista/${lineupSurvey.id}-${lineupSurvey.slug}`;
        break;
      default:
        uri = null;
        break
    }

    if (uri === null) {
      return null;
    }

    return (
      <Button
        variant="contained"
        href={getProject().baseUrlFM + uri}
        style={{ marginRight: 8 }}
        target="_blank">
        {m.button_preview}
      </Button>
    )
  }

  private addSelectablePlayer(playerIRIs: string[], position: string): any[] {
    const { lineupSurvey } = this.props;

    /**
     * The form returns only IRIs.
     * Generate the following array for persons add in the form to prepare data for API
     * [
     *   { existing Data
     *     id: "/api/1.0/fr/lineup_survey_people/lineupSurvey=8046745482455492109;person=1413196293879979982",
     *     person: "/api/1.0/fr/people/1413196293879979982",
     *     position: "striker"
     *   },
     *   { new Data
     *     person: "/api/1.0/fr/people/1413196293879979982",
     *     position: "striker"
     *   },
     * ]
     */
    return playerIRIs.map((playerIRI) => {
      const lineupSurveyPersonData: any = {person: playerIRI, position};
      const lineupSurveyPerson: any = lineupSurvey.selectablePlayers.find(item => item.person['@id'] === playerIRI);
      if (lineupSurveyPerson) {
        lineupSurveyPersonData.id = lineupSurveyPerson['@id'];
      }

      return lineupSurveyPersonData
    });
  }
}
