import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusInProgressIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g>
        <rect
          fill="#fff"
          x="1"
          y="1"
          width="22"
          height="22"
          stroke="#221f20"
          strokeWidth="2"
        />
      </g>
    </SvgIcon>
  );
}
