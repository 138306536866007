import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ContentAddCircle from '@material-ui/icons/AddCircle';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { UserRestriction } from '../../../common/components/UserRestiction';
import {
  addAgentCompany,
  fetchAllAgentCompanies,
  getAllAgentCompanies,
} from '../actions';
import { AgentCompanyDialog } from './AgentCompanyDialog';

@injectIntl
@connect(store => ({
  agentCompanies: store.agentCompanies,
}))
export class AgentCompanySelect extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getAllAgentCompanies());
  }

  handleAdd = values => {
    const { dispatch } = this.props;
    return dispatch(addAgentCompany({ ...values })).then(() => {
      dispatch(fetchAllAgentCompanies());
    });
  };

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error },
      intl: { formatMessage: f },
      labelKey,
      agentCompanies,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <div style={{ display: 'flex' }}>
        <FormControl fullWidth error={Boolean(touched && error)}>
          <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
          <Select
            name={name}
            value={value}
            onChange={event => onChange(event.target.value)}
            input={<Input id={`select_${label}`} />}
            MenuProps={{
              PaperProps: { style: { maxHeight: 500 } },
            }}
          >
            {agentCompanies &&
              agentCompanies.list &&
              agentCompanies.list.map(value => (
                <MenuItem value={value['@id']}>{value.name}</MenuItem>
              ))}
          </Select>
          {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
        <UserRestriction>
          <AgentCompanyDialog
            title="Création de la compagnie d'agents"
            form="agentCompany_add"
            onCreate={this.handleAdd}
          >
            {!agentCompanies.adding && (
              <IconButton aria-label="add agent company">
                <ContentAddCircle />
              </IconButton>
            )}
          </AgentCompanyDialog>
        </UserRestriction>
      </div>
    );
  }
}
