import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { getSrc } from '../images/tools';

export default class PersonAvatar extends Component {
  render() {
    const { person = {} } = this.props;

    return person.photo ? (
      <Avatar src={person && getSrc(person.photo, 40)} alt={person.matchName} />
    ) : (
      <Avatar>
        {person.firstName && person.firstName[0]}
        {person.lastName && person.lastName[0]}
      </Avatar>
    );
  }
}
