import rest from 'Services/restFootData';

export default function fetchAllAgeGroups() {
  const params = { pagination: 0 };

  return function(dispatch, getState) {
    const ageGroups = getState().ageGroups;
    if (ageGroups.fetched) {
      return dispatch({ type: 'CACHED_AGEGROUPS' });
    } else {
      dispatch({ type: 'FETCH_AGEGROUPS' });

      // cached data
      return rest.get(`age_groups`, { params }).then(
        res => dispatch({ type: 'FETCH_AGEGROUPS_FULFILLED', payload: res.data }),
        err =>
          dispatch({
            type: 'FETCH_AGEGROUPS_REJECTED',
            payload: err.response,
          })
      );
    }
  };
}
