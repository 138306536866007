import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { getSrc } from '../images/tools';

export default class CompetitionAvatar extends Component {
  render() {
    const { data = {} } = this.props;

    return data.image ? (
      <Avatar src={data && getSrc(data.image, 40)} alt={data.name} />
    ) : (
      <Avatar>{data.code}</Avatar>
    );
  }
}
