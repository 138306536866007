import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';

export function fetchCompetitions(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_COMPETITIONS' });

    return rest.get('competitions', { params }).then(
      res => dispatch({ type: 'FETCH_COMPETITIONS_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_COMPETITIONS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchCompetition(slug, area) {
  const params = {
    slug,
    'area.translations.slug': area,
    groups: ['read', '_image', '_bookmaker'],
  };
  return function(dispatch, getState) {
    dispatch({ type: 'FETCH_COMPETITION' });

    // cached data
    const competition = getState().competition.detail[slug];
    if (competition) {
      return Promise.resolve({ payload: competition });
    } else {
      return rest.get('competitions', { params }).then(
        res => {
          if (res.data.collection && res.data.collection[0]) {
            return dispatch({
              type: 'FETCH_COMPETITION_FULFILLED',
              payload: res.data.collection[0],
            });
          }
          return dispatch({ type: 'FETCH_COMPETITION_NOTFOUND' });
        },
        err =>
          dispatch({
            type: 'FETCH_COMPETITION_REJECTED',
            payload: err.response,
          })
      );
    }
  };
}

export function getCompetitionId(id) {
  return function(dispatch) {
    dispatch({ type: 'GET_COMPETITION' });
    return rest.get(`competitions/${id}`).then(
      res => dispatch({ type: 'GET_COMPETITION_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'GET_COMPETITION_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateCompetition(id, competition) {
  const params = {
    groups: ['read', '_image', '_bookmaker'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_COMPETITION', payload: id });
    return rest.put(`competitions/${id}`, competition, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            messageKey: 'message_competitionUpdated',
            type: 'success',
          },
        });
        return dispatch({
          type: 'UPDATE_COMPETITION_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_COMPETITION_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function deleteCompetition(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_COMPETITION', payload: id });

    return rest.delete(`competitions/${id}`).then(
      () => dispatch({ type: 'DELETE_COMPETITION_FULFILLED', payload: { id } }),
      err =>
        dispatch({
          type: 'DELETE_COMPETITION_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchCompetitionLink(id) {
  const params = {
    groups: ['read_link'],
  };

  return function(dispatch, getState) {
    const link = getState().competition.link[id];
    if (link) return Promise.resolve({ payload: link });

    dispatch({ type: 'FETCH_COMPETITION_LINK', payload: { id } });
    return rest.get(`competitions/${id}`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_COMPETITION_LINK_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_COMPETITION_LINK_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function searchCompetitions(search) {
  const params = {
    targets: ['Competition'],
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_COMPETITIONS', payload: { search } });
    return rest.get(`search/${search}`, { params }).then(
      res =>
        dispatch({
          type: 'SEARCH_COMPETITIONS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'SEARCH_COMPETITIONS_REJECTED',
          payload: err.response,
        })
    );
  };
}
