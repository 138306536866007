import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import ListIcon from '@material-ui/icons/List';

@injectIntl
@connect(store => ({
  lists: store.lists,
}))
export default class ReturnButton extends Component {
  getHref = () => {
    const { entity, lists } = this.props;
    if (lists.entity && lists.entity.toLowerCase() === entity) {
      return `#${getProject().url}/${entity}${lists.filter}`;
    }
    return `#${getProject().url}/${entity}`;
  };

  render() {
    const {
      className,
      intl: { messages: m },
    } = this.props;

    return (
      <Button variant="contained" href={this.getHref()} className={className}>
        <ListIcon style={{ marginRight: 8 }} />
        {m.button_returnList}
      </Button>
    );
  }
}
