import { Input } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import * as React from 'react';
import { IComponentProps } from './MatchTeamPlayerItem.connector';

interface IState {
  value: number | null;
}

export class MatchTeamPlayerItem extends React.Component<IComponentProps> {
  public state: IState = { value: this.props.matchTeamPlayer.shirtNumber };

  public shouldComponentUpdate(nextProps: IComponentProps, nextState: IState) {
    const {
      isSelectedMatchTeamPlayer,
      isMatchTeamPlayerInComposition,
      isMatchTeamPlayerInSubstitutes,
      usedShirtNumbers,
    } = this.props;
    const { value } = this.state;

    return (
      isMatchTeamPlayerInSubstitutes !== nextProps.isMatchTeamPlayerInSubstitutes ||
      isMatchTeamPlayerInComposition !== nextProps.isMatchTeamPlayerInComposition ||
      isSelectedMatchTeamPlayer !== nextProps.isSelectedMatchTeamPlayer ||
      usedShirtNumbers.length !== nextProps.usedShirtNumbers.length ||
      value !== nextState.value
    );
  }

  public render() {
    const {
      matchTeamPlayer,
      isMatchTeamPlayerInComposition,
      isMatchTeamPlayerInSubstitutes,
      isSelectedMatchTeamPlayer,
      selectedMatchTeamPlayer,
      classes,
    } = this.props;
    const { value } = this.state;

    // We remove substitutes and starting players (always disabled) to clean list
    if (isMatchTeamPlayerInSubstitutes || isMatchTeamPlayerInComposition) return null;

    const itemClassName = isSelectedMatchTeamPlayer ? classes!.highlighted : '';
    const isShortNumberUsed = this.isShortNumberUsed(value);
    const inputDisabled = !!selectedMatchTeamPlayer;
    const itemDisabled = isShortNumberUsed || !Number.isInteger(value as number);
    const swappable =
      !itemDisabled &&
      !isSelectedMatchTeamPlayer &&
      selectedMatchTeamPlayer &&
      selectedMatchTeamPlayer.onBench !== undefined;

    return (
      <div
        className={classes!.container}
        key={`match-team-player-${matchTeamPlayer.membership.id}`}
      >
        <Input
          id="shirt-number"
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          type="number"
          inputProps={{
            min: '0',
            max: '100',
          }}
          className={classes!.input}
          disabled={inputDisabled}
          error={isShortNumberUsed}
        />
        <ListItem
          button
          onClick={swappable ? this.onSwap : this.onSelect}
          disabled={itemDisabled}
          className={classes!.item + ' ' + itemClassName}
        >
          <ListItemText>{matchTeamPlayer.membership.personMatchName}</ListItemText>
          {swappable ? <SwapHorizIcon className={classes.icon} /> : null}
          {isSelectedMatchTeamPlayer ? (
            <ChevronRightIcon className={classes.icon} />
          ) : null}
        </ListItem>
      </div>
    );
  }

  private onSelect = () => {
    const {
      side,
      matchTeamPlayer,
      selectMatchTeamPlayer,
      unselectMatchTeamPlayer,
      isSelectedMatchTeamPlayer,
    } = this.props;
    isSelectedMatchTeamPlayer
      ? unselectMatchTeamPlayer(side)
      : selectMatchTeamPlayer(matchTeamPlayer, side);
  };

  private onSwap = () => {
    const { side, matchTeamPlayer, swapMatchTeamPlayerInList } = this.props;
    swapMatchTeamPlayerInList(side, matchTeamPlayer);
  };

  private isShortNumberUsed = value => this.props.usedShirtNumbers.includes(value);

  private handleChange = ev => {
    const value = +ev.target.value;
    this.setState({ value });
  };

  private handleBlur = () => {
    const { updateMatchTeamPlayer, matchTeamPlayer, side } = this.props;
    const { value } = this.state;
    if (matchTeamPlayer.shirtNumber === value) return;
    updateMatchTeamPlayer({ ...matchTeamPlayer, shirtNumber: value as number }, side);
  };
}
