import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import IntInput, { normalizeInt } from '../../../../common/components/inputs/Integer';
import TextInput from '../../../../common/components/inputs/Text';
import { IBookmaker } from '../../typings';

interface IProps {
  data: any;
  onSubmit: (bookmaker: IBookmaker) => void;
  onDelete?: (bookmaker: IBookmaker) => void;
  onRemove?: (id: string) => void;
}

export class BookmakerForm extends Component<IProps> {
  public config = {
    fields: ['id', 'name', 'code', 'priority', 'affiliationLink', 'appAffiliationLink', 'affiliationText', 'cssColor'],
    disposition: [
      {
        fields: [
          { name: 'name', component: TextInput, size: 6 },
          { name: 'code', component: TextInput, size: 6 },
          {
            name: 'priority',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          { name: 'affiliationLink', component: TextInput, size: 6 },
          { name: 'appAffiliationLink', component: TextInput, size: 6 },
          { name: 'affiliationText', component: TextInput, size: 6 },
          { name: 'cssColor', component: TextInput, size: 6 },
        ],
      },
    ],
  };

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`bookmaker_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }
}
