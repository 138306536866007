import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

const styles = {
  span: {
    fontSize: 14,
    color: 'grey',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};

function Note({ createdAt, updatedAt, toggle, style, fromBrief, intl: { messages: m } }) {
  const relativeTime = moment(updatedAt).calendar(null, { sameElse: 'lll' });

  let fromBriefInfo = '';
  if(fromBrief) {
    fromBriefInfo = <span>         - {m.fromBrief}        </span>
  }
  if (createdAt === updatedAt) {
    return (
      <div style={style}>
        <span style={styles.span}>
          {m.revision_creation} : <span>{relativeTime}</span>
          {fromBriefInfo}
        </span>

      </div>
    );
  }
  if (!toggle) {
    return (
      <div style={style}>
        <span style={styles.span}>
          {m.revision_lastUpdate} : {relativeTime}
          {fromBriefInfo}
        </span>
      </div>
    );
  }

  return (
    <div style={style}>
      <span style={styles.span}>
        {m.revision_lastUpdate} :&nbsp;
        <a style={styles.link} onClick={toggle}>
          {relativeTime}
        </a>
        {fromBriefInfo}
      </span>
    </div>
  );
}

export default injectIntl(Note);
