import { createDetailReducer } from '../../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'AGENT',
  collections: {
    playersIds: { get: 'players' },
  },
  inside: {
    agentCompany: { get: 'agents' },
  },
});
