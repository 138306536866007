import {
  notifyErrorAction,
  notifySuccessAction,
} from '../../../common/actions/notifications';
import {
  extractMatchComposition,
  getMatchComposition,
  postSideComposition,
} from '../../compositions/requests';
import { getNumberOfPlayer } from '../../compositions/utils';
import { fetchMatch, updateMatch } from '../../matches/actions';
import { getTeamLastMatches } from '../../matches/requests';
import { selectMatch } from '../../matches/selector';
import { ISide } from '../../matches/typings';
import {
  getClubActiveMemberships,
  getTeamSeasonMemberships,
} from '../../memberships/requests';
import { removeDuplicateMemberships } from '../../memberships/utils';
import { ILiveState } from '../Commentary/typings';
import {
  setClubMembershipsAction,
  setLiveCompositionAction,
  setTeamLastMatchesAction,
  setTeamSeasonMembershipsAction,
} from './actions';
import { ICompositionState } from './typings';

// Composition
export const getLiveComposition = (id: string) => dispatch =>
  getMatchComposition(id)
    .then(extractMatchComposition)
    .then(composition =>
      composition ? dispatch(setLiveCompositionAction(composition)) : null
    );

export const postLiveComposition = () => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const liveCompositionState: ICompositionState = getState().liveComposition;
    const matchId = liveState.matchId;
    const composition = liveCompositionState.composition;
    const homePlayers = getNumberOfPlayer(composition.home);
    const awayPlayers = getNumberOfPlayer(composition.away);
    if (homePlayers > 11 || awayPlayers > 11) {
      return Promise.resolve(
        dispatch(notifyErrorAction('message_compositionTooManyPlayers'))
      );
    }
    const isLineupsAvailable = homePlayers > 0 && awayPlayers > 0;
    return postSideComposition(matchId, 'home', composition).then(
      postSideComposition(matchId, 'away', composition)
        .then(() => {
          dispatch(updateMatch(matchId, { isLineupsAvailable }))
            .then(() => {
              dispatch(notifySuccessAction('message_compositionUpdated'));
              // refetch match to update team mtp
              return dispatch(fetchMatch(matchId));
            })
            .catch(error => {
              console.error(error);
              dispatch(notifyErrorAction('message_compositionError'));
            });
        })
        .catch(error => {
          console.error(error);
          dispatch(notifyErrorAction('message_compositionError'));
        })
    );
  };
};

export const getLiveMembershipsAndLastMatches = (id: string) => {
  return (dispatch, getState) => {
    const game = selectMatch(getState(), id);
    if (game && game.awayTeam && game.awayTeam.club && game.awayTeam.club.id) {
      const team = game.awayTeam;
      dispatch(getLiveClubMemberships('away', team.club.id, team.gender));
      dispatch(getLiveTeamLastMatches('away', team.id));

      if (game.matchTeams!.away!.teamSeason) {
        const teamSeasonId = game.matchTeams!.away!.teamSeason.id;
        dispatch(getLiveTeamSeasonMemberships('away', teamSeasonId, team.gender));
      }
    }
    if (game && game.homeTeam && game.homeTeam.club && game.homeTeam.club.id) {
      const team = game.homeTeam;
      dispatch(getLiveClubMemberships('home', team.club.id, team.gender));
      dispatch(getLiveTeamLastMatches('home', team.id));

      if (game.matchTeams!.home!.teamSeason) {
        const teamSeasonId = game.matchTeams!.home!.teamSeason.id;
        dispatch(getLiveTeamSeasonMemberships('home', teamSeasonId, team.gender));
      }
    }
  };
};

// Memberships
const getLiveClubMemberships = (
  side: ISide,
  clubId: string,
  gender: 'male' | 'female'
) => dispatch =>
  getClubActiveMemberships(clubId, gender).then(res => {
    if (!res.data || !res.data.collection) return null;
    return dispatch(
      setClubMembershipsAction(side, removeDuplicateMemberships(res.data.collection))
    );
  });

const getLiveTeamSeasonMemberships = (
  side: ISide,
  seasonId: string,
  gender: 'male' | 'female'
) => dispatch =>
  getTeamSeasonMemberships(seasonId, gender).then(res => {
    if (!res.data || !res.data.collection) return null;
    return dispatch(setTeamSeasonMembershipsAction(side, res.data.collection));
  });

// Matches
const getLiveTeamLastMatches = (side: ISide, teamId: string) => dispatch =>
  getTeamLastMatches(teamId).then(res => {
    if (!res.data || !res.data.collection) return null;
    return dispatch(setTeamLastMatchesAction(side, res.data.collection));
  });
