import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { getProject, getProjects, setProject } from '../services/project';
import logoFD from '../img/footdata.svg';
import logoWiki from '../img/wiki.svg';

const styles = () => ({
  menuList: {
    background: '#2e4e86',
  },
  mainLogo: {
    marginRight: 16,
  },
  bracket: {
    fontSize: '24px',
    fontWeight: 'normal',
  },
  projectLabel: {
    color: 'white',
    margin: 8,
  },
});

@connect(store => ({
  me: store.me,
}))
@withRouter
@withStyles(styles)
export default class ProjectMenu extends Component {
  state = {
    anchorEl: undefined,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };
  handleChange = ({ url }) => {
    const { history } = this.props;
    this.handleClose();
    setProject(url);
    history.push(url);
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  displayProject = ({ logo, label }) => {
    const { classes } = this.props;
    if (logo) {
      return <img height="30" src={logo} />;
    }
    return [
      <img height="30" src={logoFD} />,
      <span className={classes.projectLabel}>{label}</span>,
    ];
  };

  render() {
    const { me, classes } = this.props;
    return [
      <Button
        aria-owns={this.state.open ? 'projects-menu' : null}
        aria-haspopup="true"
        color="inherit"
        onClick={this.handleClick}
      >
        <img height="30" src={logoWiki} className={classes.mainLogo} />
        <span className={classes.bracket}>[</span>
        {this.displayProject(getProject())}
        <span className={classes.bracket}>]</span>
      </Button>,
      <Menu
        id="projects-menu"
        anchorEl={this.state.anchorEl}
        open={this.state.open}
        onClose={this.handleClose}
        MenuListProps={{ className: classes.menuList }}
      >
        {me &&
          me.websites &&
          getProjects()
            .filter(p => me.websites.includes(p.name))
            .map(p => (
              <MenuItem value={p.name} onClick={() => this.handleChange(p)}>
                {this.displayProject(p)}
              </MenuItem>
            ))}
      </Menu>,
    ];
  }
}
