import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import FooterPagination from '../../../../common/components/TableList/FooterPagination';
import DateCell from '../../../../common/components/TableList/cell/Date';
import { getProject } from '../../../../common/services/project';
import { IArticle } from '../../typings';
import { ArticleAuthorsCell } from '../ArticleAuthorsCell';
import { ArticleStatusIcon } from '../ArticleStatusIcon';
import { ArticleTagsCell } from '../ArticleTagsCell';
import { ArticleTitleCell } from '../ArticleTitleCell';
import { IComponentProps } from './index';

export class TablePostPublished extends Component<IComponentProps> {
  public render() {
    const {
      collection,
      defaultItemPerPage,
      onPaginate,
      intl: { messages: m },
    } = this.props;
    const { list = [], pagination, totalItems } = collection;

    if (list.length == 0) {
      return (
        <Typography style={{ padding: 24 }} variant="body1">
          {m.error_noData}
        </Typography>
      );
    }

    return (
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size="small">{m.field_status}</TableCell>
              <TableCell size="small">{m.field_title}</TableCell>
              <TableCell size="small">{m.field_authors}</TableCell>
              <TableCell size="small">{m.field_tags}</TableCell>
              <TableCell size="small">{m.field_publishedAt}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{list ? list.map(this.renderRow) : null}</TableBody>
          <FooterPagination
            pagination={pagination}
            total={totalItems}
            onPaginate={onPaginate}
            defaultItemPerPage={defaultItemPerPage}
          />
        </Table>
      </div>
    );
  }

  private renderRow = (article: IArticle) => {
    const { status, authors, tags, publishedAt, id } = article;
    const href = `#${getProject().url}/articles/${id}`;

    return (
      // @ts-ignore
      <TableRow hover href={href} component="a">
        <TableCell size="small">
          <ArticleStatusIcon status={status} />
        </TableCell>
        <TableCell size="small">
          <ArticleTitleCell article={article} />
        </TableCell>
        <TableCell size="small">
          <ArticleAuthorsCell authors={authors} />
        </TableCell>
        <TableCell size="small">
          <ArticleTagsCell tags={tags} />
        </TableCell>
        <TableCell size="small">
          <DateCell date={publishedAt} dateFormat="DD[/]MM[/]YYYY HH[:]mm[:]ss" />
        </TableCell>
      </TableRow>
    );
  };
}
