import React from 'react';
import StatusDeleteIcon from '../../../../common/components/icons/StatusDelete';
import StatusInProgressIcon from '../../../../common/components/icons/StatusInProgress';
import StatusPrewrittenIcon from '../../../../common/components/icons/StatusPrewritten';
import StatusProposalIcon from '../../../../common/components/icons/StatusProposal';
import StatusPublishIcon from '../../../../common/components/icons/StatusPublish';
import StatusRefuseIcon from '../../../../common/components/icons/StatusRefuse';
import StatusRestrictedIcon from "../../../../common/components/icons/StatusRestricted";
import StatusTemplateIcon from "../../../../common/components/icons/StatusTemplate";

interface IComponentProps {
  status: string;
}

export class ArticleStatusIcon extends React.Component<IComponentProps> {
  public render() {
    switch (this.props.status) {
      case 'in_progress':
        return <StatusInProgressIcon />;
      case 'prewritten':
        return <StatusPrewrittenIcon />;
      case 'refuse':
        return <StatusRefuseIcon />;
      case 'publish':
        return <StatusPublishIcon />;
      case 'proposal':
        return <StatusProposalIcon />;
      case 'delete':
        return <StatusDeleteIcon />;
      case 'template':
        return <StatusTemplateIcon />;
      case 'restricted':
        return <StatusRestrictedIcon />;
      default:
        return status;
    }
  }
}
