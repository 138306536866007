import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { getProject } from 'Services/project';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/core/styles';

const styleSheet = theme => ({
  itemText: {
    padding: 0,
    fontSize: '15px',
    fontFamily: 'Roboto',
    color: theme.palette.grey[700],
  },
  list: {
    flex: 'inherit',
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  active: {
    borderRight: `4px solid ${theme.palette.primary[500]}`,
    background: theme.palette.grey[50],
    '& $itemText': {
      color: theme.palette.primary[500],
    },
    '& svg': {
      color: theme.palette.primary[500],
    },
  },
});

@withRouter
@withStyles(styleSheet)
@injectIntl
export default class Nav extends Component {
  state = {
    open: {},
  };

  handleGroupClick = ref => {
    const { open } = this.state;
    open[ref] = !open[ref];
    this.setState({ open });
  };

  addClassIfActive = item => {
    const {
      classes,
      location: { pathname },
    } = this.props;
    let isActive = false;
    const href = item.href.split('?')[0];
    if (href === getProject().url) {
      isActive = pathname === getProject().url;
    } else {
      isActive = pathname.indexOf(href) == 0;
    }
    if (isActive) return classes.active;
  };

  render() {
    const { menu, menuOption, menuSmallDevice, isAdmin } = this.props;
    return (
      <div>
        {this.renderMenu(menu)}
        <Hidden mdUp>{this.renderMenu(menuSmallDevice)}</Hidden>
        {isAdmin && this.renderMenu(menuOption)}
      </div>
    );
  }

  renderMenu = menu => {
    const { classes } = this.props;
    if (!menu) return null;
    return [
      <List classes={{ root: classes.list }}>
        {menu.map(nav =>
          nav.group ? this.renderMenuGroupItem(nav) : this.renderMenuItem(nav)
        )}
      </List>,
      <Divider />,
    ];
  };

  renderMenuItem = nav => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <ListItem
        button
        data-test={`main_nav-item-${nav.labelKey}`}
        component="a"
        href={`#${nav.href}`}
        className={`${classes.listItem} ${this.addClassIfActive(nav)}`}
        dense
      >
        <ListItemIcon>
          <nav.img />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={nav.label || m[nav.labelKey]}
          classes={{ root: classes.itemText }}
        />
      </ListItem>
    );
  };
  renderMenuGroupItem = nav => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    const { open } = this.state;

    return [
      <ListItem
        button
        component="a"
        onClick={() => this.handleGroupClick(nav.ref)}
        className={classes.listItem}
        dense
      >
        <ListItemIcon>
          <nav.img />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={nav.label || m[nav.labelKey]}
          classes={{ root: classes.itemText }}
        />
        {open[nav.ref] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>,
      <Collapse component="li" in={open[nav.ref]} timeout="auto" unmountOnExit>
        <List disablePadding>{nav.group.map(this.renderMenuSubItem)}</List>
      </Collapse>,
    ];
  };

  renderMenuSubItem = nav => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <ListItem
        button
        data-test={`main_nav-item-${nav.labelKey}`}
        component="a"
        href={`#${nav.href}`}
        className={`${classes.nested} ${classes.listItem} ${this.addClassIfActive(nav)}`}
        dense
      >
        <ListItemIcon>
          <nav.img />
        </ListItemIcon>
        <ListItemText
          disableTypography
          inset
          primary={nav.label || m[nav.labelKey]}
          classes={{ root: classes.itemText }}
        />
      </ListItem>
    );
  };
}
