import rest from 'Services/restFootData';

export default function search(query) {
  const params = {
    targets: ['Team', 'Competition', 'Person'],
    limit: 30,
    groups: ['read_link', '_image', '_country'],
  };

  return function(dispatch, getState) {
    dispatch({ type: 'FETCH_SEARCH_FD' });

    // cached data
    const cachedSearch = getState().searchFD[query];
    if (cachedSearch) {
      return Promise.resolve({ payload: cachedSearch });
    } else {
      return rest.get(`search/${query}`, { params }).then(
        res => dispatch({ type: 'FETCH_SEARCH_FD_FULFILLED', payload: res.data }),
        err =>
          dispatch({
            type: 'FETCH_SEARCH_FD_REJECTED',
            payload: err.response,
          })
      );
    }
  };
}
