export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_REVISION': {
      return {
        ...state,
        fetching: true,
      };
    }

    case 'FETCH_REVISION_FULFILLED': {
      const { id, list } = action.payload;
      return {
        ...state,
        [id]: { list },
        fetching: false,
      };
    }
    case 'FETCH_DIFF_FULFILLED': {
      return {
        ...state,
        diff: action.payload,
      };
    }
    default:
      return state;
  }
}
