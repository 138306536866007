import { IChronoUnit } from '../../../../commentaries/typings';

export enum EPeriod {
  FirstHalf = 1,
  SecondHalf = 2,
  ExtraTimeFirstHalf = 3,
  ExtraTimeSecondHalf = 4,
  PenaltyShootout = 5,
  HalfTime = 10,
  EndSecondHalf = 11,
  ExtraTimeHalfTime = 12,
  EndExtraTime = 13,
  FullTime = 14,
  PreMatch = 16,
}

export const PERIODS = {
  [EPeriod.PreMatch]: 'period_pre_match',
  [EPeriod.FirstHalf]: 'period_first_half',
  [EPeriod.HalfTime]: 'period_half_time',
  [EPeriod.SecondHalf]: 'period_second_half',
  [EPeriod.EndSecondHalf]: 'period_end_second_half',
  [EPeriod.ExtraTimeFirstHalf]: 'period_extra_time_first_half',
  [EPeriod.ExtraTimeHalfTime]: 'period_extra_time_half_time',
  [EPeriod.ExtraTimeSecondHalf]: 'period_extra_time_second_half',
  [EPeriod.EndExtraTime]: 'period_end_extra_time',
  [EPeriod.PenaltyShootout]: 'period_penalty_shootout',
  [EPeriod.FullTime]: 'period_full_time',
};
export const ORDERED_PERIODS = [
  EPeriod.PreMatch,
  EPeriod.FirstHalf,
  EPeriod.HalfTime,
  EPeriod.SecondHalf,
  EPeriod.EndSecondHalf,
  EPeriod.ExtraTimeFirstHalf,
  EPeriod.ExtraTimeHalfTime,
  EPeriod.ExtraTimeSecondHalf,
  EPeriod.EndExtraTime,
  EPeriod.PenaltyShootout,
  EPeriod.FullTime,
];

const NEXT_PERIODS: { [key: number]: EPeriod[] } = {
  [EPeriod.PreMatch]: [EPeriod.FirstHalf],
  [EPeriod.FirstHalf]: [EPeriod.HalfTime],
  [EPeriod.HalfTime]: [EPeriod.SecondHalf],
  [EPeriod.SecondHalf]: [EPeriod.EndSecondHalf, EPeriod.FullTime],
  [EPeriod.EndSecondHalf]: [
    EPeriod.ExtraTimeFirstHalf,
    EPeriod.PenaltyShootout,
    EPeriod.FullTime,
  ],
  [EPeriod.ExtraTimeFirstHalf]: [EPeriod.ExtraTimeHalfTime],
  [EPeriod.ExtraTimeHalfTime]: [EPeriod.ExtraTimeSecondHalf],
  [EPeriod.ExtraTimeSecondHalf]: [EPeriod.EndExtraTime, EPeriod.FullTime],
  [EPeriod.EndExtraTime]: [EPeriod.PenaltyShootout],
  [EPeriod.PenaltyShootout]: [EPeriod.FullTime],
  [EPeriod.FullTime]: [],
};
export const ACTIVE_PERIODS = [
  EPeriod.FirstHalf,
  EPeriod.SecondHalf,
  EPeriod.ExtraTimeFirstHalf,
  EPeriod.ExtraTimeSecondHalf,
];

export const getPeriodInfo = (period: number): Array<null | number> => {
  switch (period) {
    case EPeriod.FirstHalf:
      return [0, 45];
      break;
    case EPeriod.SecondHalf:
      return [45, 90];
      break;
    case EPeriod.ExtraTimeFirstHalf:
      return [90, 105];
      break;
    case EPeriod.ExtraTimeSecondHalf:
      return [105, 120];
      break;
    default:
      return [null, null];
      break;
  }
};

export const getPeriodIndex = (period: EPeriod): number => {
  return ORDERED_PERIODS.indexOf(period);
};

export const isFollowingPeriod = (currentPeriod: EPeriod, period: EPeriod): boolean => {
  return NEXT_PERIODS[currentPeriod].includes(period);
};

export const getDefaultPeriodTime = (period: EPeriod): IChronoUnit => {
  switch (period) {
    case EPeriod.PreMatch:
    case EPeriod.FirstHalf:
      return 0;
      break;
    case EPeriod.HalfTime:
    case EPeriod.SecondHalf:
      return 45;
      break;
    case EPeriod.EndSecondHalf:
    case EPeriod.ExtraTimeFirstHalf:
    case EPeriod.FullTime:
      return 90;
      break;
    case EPeriod.ExtraTimeHalfTime:
    case EPeriod.ExtraTimeSecondHalf:
      return 105;
      break;
    case EPeriod.PenaltyShootout:
    default:
      return null;
      break;
  }
};
