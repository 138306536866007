import debounce from 'Services/tools';

const DEBOUNCE_TIME = 1000;
const fetched = {
  twitter: false,
  instagram: false,
  tiktokKey: 0,
};

export const getTwitter = () => {
  if (fetched.twitter) {
    refreshTwitter();
  } else {
    fetched.twitter = true;
    loadTwitterScript();
  }
};

export const getTiktok = () => {
  loadTiktokScript();
};

export const getInstagram = () => {
  if (fetched.instagram) {
    refreshInstagram();
  } else {
    fetched.instagram = true;
    loadInstagramScript();
  }
};

const refreshTwitter = debounce(() => {
  window.twttr && window.twttr.widgets.load();
}, DEBOUNCE_TIME);

const refreshInstagram = debounce(() => {
  window.instgrm && window.instgrm.Embeds.process();
}, DEBOUNCE_TIME);

const loadTwitterScript = () => {
  loadScript('https://platform.twitter.com/widgets.js', 'twitter-widgets');
};

const loadTiktokScript = () => {
  // missing js method to refresh embed
  fetched.tiktokKey += 1;
  loadScript('https://www.tiktok.com/embed.js', `tiktok-widgets${fetched.tiktokKey}`);
};

const loadInstagramScript = () => {
  loadScript('https://www.instagram.com/embed.js', 'instagram-widgets');
};

const loadScript = (src, id) => {
  if (document.getElementById(id)) return;
  console.log(`add ${id}`);
  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  document.body.appendChild(js);
};
