import { withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { removePlayer2Live, removePlayerLive } from '../../actions';
import { styles } from './styles';

const mapDispatchToProps = {
  removePlayerLive,
  removePlayer2Live,
};

const mapStateToProps = (state: IRootState) => ({
  type: state.live.commentary.type,
  player: state.live.commentary.player,
  player2: state.live.commentary.player2,
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
