import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import diff from './diff';

const styles = {
  diff: {
    '& del': {
      background: '#ffd0c0',
      color: '#802020',
    },
    '& ins': {
      background: '#d0ffc0',
    },
  },
};

@injectIntl
@withStyles(styles)
export default class Diff extends Component {
  format(value) {
    const {
      intl: { messages: m },
    } = this.props;

    const type = typeof value;
    if (type == 'string') return value.replace(/\s+$/, '');
    if (type == 'boolean') return value ? m.common_yes : m.common_no;
    if (type == 'object' && Array.isArray(value))
      return `[ ${value.join(' , ')} ]`;
    return value ? value : '';
  }

  render() {
    const { oldValue = '', newValue = '', classes } = this.props;

    const f1 = this.format(oldValue);
    const f2 = this.format(newValue);
    const result = diff(f1, f2);

    return (
      <div
        className={classes.diff}
        dangerouslySetInnerHTML={{ __html: result }}
      />
    );
  }
}
