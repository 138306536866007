import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import MediaLibrary from '../Input/MediaLibrary';
import Upload from '../Input/Upload';
import { UrlMedia } from '../Input/UrlMedia';
import { closeImageMedia } from './actions';

/* Move to transitions components ? */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  label: {
    color: '#757575',
    fontSize: 16,
    position: 'absolute',
    left: 8,
    top: 8,
  },
  container: {
    position: 'relative',
    minWidth: 150,
    minHeight: 150,
    height: '100%',
  },
  image: {
    maxWidth: '100%',
  },
  actions: {
    display: 'flex',
    position: 'absolute',
    bottom: 12,
    right: 0,
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});
@injectIntl
@withStyles(styles)
@connect(store => ({
  editorImage: store.editor.image,
}))
@withMobileDialog()
export default class ImageInput extends Component {
  state = {
    tab: 0,
  };

  handleClose = answer => {
    const { dispatch } = this.props;
    return dispatch(closeImageMedia(answer));
  };

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const {
      editorImage,
      fullScreen,
      imageType,
      width,
      intl: { messages: m },
    } = this.props;
    const { tab } = this.state;

    const input = { value: null };

    return (
      <Dialog
        open={editorImage.open || false}
        onClose={() => this.handleClose()}
        TransitionComponent={Transition}
        maxWidth={false}
        fullScreen={fullScreen}
        fullWidth
      >
        <AppBar position="static" color="inherit">
          <Tabs value={tab} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" fullWidth>
            <Tab label={m.media_url} />
            <Tab label={m.media_upload} />
            <Tab label={m.media_library} />
          </Tabs>
        </AppBar>
        {tab === 0 && <UrlMedia close={this.handleClose} />}
        {tab === 1 && <Upload input={input} close={this.handleClose} imageType={imageType} chooseFormat />}
        {tab === 2 && <MediaLibrary input={input} close={this.handleClose} width={width} chooseFormat />}
      </Dialog>
    );
  }
}
