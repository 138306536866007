const nextSort = (payload, direction) => {
  if (direction == null) {
    return {
      field: payload,
      order: 'asc',
    };
  }
  if (direction == 'asc') {
    return {
      field: payload,
      order: 'desc',
    };
  }
  return {};
};

/**
 * This reducer is for the react-router query string, NOT for redux.
 */
export default (state, { type, payload }) => {
  switch (type) {
    case 'SET_SORT_FILTER':
      return {
        ...state,
        page: 1,
        filters: payload.filters,
        sort: nextSort(payload.sort, state.sort.order),
      };
    case 'SET_SORT':
      if (state.sort && payload === state.sort.field) {
        return {
          ...state,
          sort: nextSort(payload, state.sort.order),
          page: 1,
        };
      }
      return {
        ...state,
        sort: nextSort(payload, null),
        page: 1,
      };

    case 'SET_PAGE':
      return { ...state, page: payload };

    case 'SET_FILTER':
      return { ...state, page: 1, filters: payload };

    case 'SET_TOGGLE':
      return { ...state, page: 1, toggles: payload };

    case 'SET_ITEMS':
      return { ...state, page: 1, itemsPerPage: payload };

    default:
      return state;
  }
};
