import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { IAgent } from '../../typings';
import { AgentsColumn as AgentsColumnComponent } from '././AgentsColumn.component';

interface IOwnProps {
  agents: IAgent[];
  onRemove: (id: string) => void;
  onSelect: (id: string) => void;
}
export type IComponentProps = InjectedIntlProps & IOwnProps;

const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const AgentsColumn = connector(AgentsColumnComponent);
