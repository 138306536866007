import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import fetchAllChannels from '../../channels/actions';
import { fetchNextMatches } from '../../matches/actions';
import { addBroadcast, deleteBroadcast, updateBroadcast } from '../actions';
import { styles } from './styles';

const mapDispatchToProps = {
  fetchAllChannels,
  fetchNextMatches,
  updateBroadcast,
  deleteBroadcast,
  addBroadcast,
};

const mapStateToProps = (state: IRootState) => ({
  channels: state.channels.list || [],
  matches: state.matches.next && state.matches.next.list,
  fetching: state.matches.next && state.matches.next.fetching,
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles &
  InjectedIntlProps;
const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
