import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TransfersIcon from '@material-ui/icons/CompareArrows';
import ActionDashboard from '@material-ui/icons/Dashboard';
import AgentCompaniesIcon from '@material-ui/icons/Domain';
import CorrectionIcon from '@material-ui/icons/Edit';
import StatsIcon from '@material-ui/icons/Equalizer';
import GamesIcon from '@material-ui/icons/Games';
import HealingIcon from '@material-ui/icons/Healing';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import BroadcastIcon from '@material-ui/icons/LiveTv';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import PollIcon from '@material-ui/icons/Poll';
import SportsEsports from '@material-ui/icons/SportsEsports';
import DeviceHub from '@material-ui/icons/DeviceHub';
import ClubIcon from 'Components/icons/Club';
import CompetitionIcon from 'Components/icons/Competition';
import PersonIcon from 'Components/icons/Person';
import Nav from 'Components/Nav';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import isAdmin from 'Services/me';
import { getProject } from 'Services/project';
import { AccountBalance, QuestionAnswer } from "@material-ui/icons";
import SportsSoccer from '@material-ui/icons/SportsSoccer';
import { TYPE_SQUAD } from 'FD/lineupSurveys/typings';

@connect(store => ({
  me: store.me,
}))
export default class Navigation extends Component {
  render() {
    const { me } = this.props;

    const menu = [
      { labelKey: 'app_dashboard', href: getProject().url, img: ActionDashboard },
      { labelKey: 'app_clubs', href: `${getProject().url}/clubs`, img: ClubIcon },
      {
        labelKey: 'app_competitions',
        href: `${getProject().url}/competitions`,
        img: CompetitionIcon,
      },
      {
        labelKey: 'app_matches',
        href: `${getProject().url}/matches`,
        img: GamesIcon,
      },
      {
        labelKey: 'app_people',
        img: PersonIcon,
        ref: 'people',
        group: [
          {
            labelKey: 'app_players',
            href: `${getProject().url}/players`,
            img: PersonIcon,
          },
          {
            labelKey: 'app_coaches',
            href: `${getProject().url}/coaches`,
            img: PersonIcon,
          },
          {
            labelKey: 'app_presidents',
            href: `${getProject().url}/presidents`,
            img: PersonIcon,
          },
          {
            labelKey: 'app_staffs',
            href: `${getProject().url}/staffs`,
            img: PersonIcon,
          },
          {
            labelKey: 'app_referees',
            href: `${getProject().url}/referees`,
            img: PersonIcon,
          },
        ],
      },
      {
        labelKey: 'app_transfers',
        href: `${getProject().url}/transfers`,
        img: TransfersIcon,
      },
      {
        labelKey: 'app_injury_types',
        href: `${getProject().url}/injury-types`,
        img: HealingIcon,
      },
      {
        labelKey: 'app_agents',
        img: AgentCompaniesIcon,
        ref: 'agents',
        group: [
          {
            labelKey: 'app_agents',
            href: `${getProject().url}/agents`,
            img: PersonIcon,
          },
          {
            labelKey: 'app_agentCompanies',
            href: `${getProject().url}/agent-companies`,
            img: AgentCompaniesIcon,
          },
        ],
      },
      {
        labelKey: 'app_images',
        href: `${getProject().url}/images`,
        img: PermMediaIcon,
      },
      {
        labelKey: 'app_broadcasts',
        href: `${getProject().url}/broadcasts`,
        img: BroadcastIcon,
      },
      {
        labelKey: 'app_statistics',
        img: StatsIcon,
        ref: 'statistics',
        group: [
          {
            labelKey: 'app_statsClubs',
            href: `${getProject().url}/stats-clubs`,
            img: ClubIcon,
          },
          {
            labelKey: 'app_statsPlayers',
            href: `${getProject().url}/stats-players`,
            img: PersonIcon,
          },
        ],
      },
      {
        labelKey: 'app_products',
        href: `${getProject().url}/products`,
        img: LocalOfferIcon,
      },
      {
        labelKey: 'app_partners',
        img: DeviceHub,
        ref: 'partners',
        group: [
          {
            labelKey: 'app_partners',
            href: `${getProject().url}/partners`,
            img: DeviceHub,
          },
          {
            labelKey: 'app_partnerOffers',
            href: `${getProject().url}/partner-offers`,
            img: AccountBalance,
          },
        ],
      },
      {
        labelKey: 'app_bookmakers',
        href: `${getProject().url}/bookmakers`,
        img: GamesIcon,
      },
      {
        labelKey: 'app_userGame',
        img: SportsEsports,
        ref: 'userGame',
        group: [
          {
            labelKey: 'app_surveys',
            href: `${getProject().url}/surveys?locale=${getProject().lang}`,
            img: PollIcon,
          },
          {
            labelKey: 'app_quizz',
            href: `${getProject().url}/quizzes?locale=${getProject().lang}`,
            img: QuestionAnswer,
          },
          {
            labelKey: 'app_lineup_surveys',
            href: `${getProject().url}/lineup-surveys?type=${TYPE_SQUAD}`,
            img: SportsSoccer,
          },
          {
            labelKey: 'app_mercatoSurveys',
            href: `${getProject().url}/mercatosurveys?order[startDate]=desc`,
            img: TransfersIcon,
          },
        ],
      },
      {
        labelKey: 'app_corrections',
        href: `${getProject().url}/corrections`,
        img: CorrectionIcon,
      },
    ];

    const menuOption = [
      {
        labelKey: 'app_users',
        href: `${getProject().url}/users`,
        img: AccountBoxIcon,
      },
      {
        labelKey: 'app_documentation',
        href: `${getProject().url}/documentation`,
        img: ImportContactsIcon,
      },
    ];

    const menuSmallDevice = [
      {
        label: `${me.firstname}.${me.lastname && me.lastname[0]}`,
        href: `${getProject().url}/users/${me.id}`,
        img: AccountCircleIcon,
      },
    ];

    return (
      <Nav
        path={this.props.path}
        menu={menu}
        menuOption={menuOption}
        menuSmallDevice={menuSmallDevice}
        isAdmin={isAdmin()}
      />
    );
  }
}
