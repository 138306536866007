import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import { isUserLimited } from '../../users/utils';

interface IOwnProps {
  children: JSX.Element;
}

const mapStateToProps = (state: IRootState) => {
  return {
    isRestricted: isUserLimited(state.me),
  };
};

export type IComponentProps = ReturnType<typeof mapStateToProps> & IOwnProps;

const reduxConnector = connect(mapStateToProps);
export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
