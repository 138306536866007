import { withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../typings';
import { getProduct, updateProduct } from '../../actions';
import { styles } from './styles';

interface IOwnProps {
  id: string;
  isExpanded: boolean;
  onDelete: (id: string) => void;
  onRemove: (id: string) => void;
  onExpend: (id: string, expanded: boolean) => void;
}

const mapDispatchToProps = {
  getProduct,
  updateProduct,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    product: state.product.detail[ownProps.id],
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  withStyles(styles),
  injectIntl,
  reduxConnector
);
