import * as React from 'react';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { MatchTeamPlayerCell } from '../MatchTeamPlayerCell';
import { IComponentProps } from './SubstituteCell.connector';

export class SubstituteCell extends React.Component<IComponentProps> {
  public render() {
    const { substitute, side } = this.props;

    return (
      <MatchTeamPlayerCell
        title="substitute"
        matchTeamPlayer={substitute}
        side={side}
        onAdd={this.onAdd}
        onDelete={this.onDelete}
        onSwap={this.onSwap}
        isSubtitute
      />
    );
  }

  private onAdd = () => {
    const { side, addMatchTeamPlayerOnSubstitutes } = this.props;
    addMatchTeamPlayerOnSubstitutes(side);
  };

  private onDelete = () => {
    const { side, removeMatchTeamPlayerFromSubtitutes } = this.props;
    removeMatchTeamPlayerFromSubtitutes(side);
  };

  private onSwap = () => {
    const { side, substitute, swapMatchTeamPlayerFromSubtitutes } = this.props;
    swapMatchTeamPlayerFromSubtitutes(side, substitute as IMatchTeamPlayer);
  };
}
