import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function TimerIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 500 500">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M255 233c-5 0-9 3-9 8 0 5 4 9 9 9 5 0 9-4 9-9 0-5-4-8-9-8z m0 10c-1 0-2-1-2-2 0-1 1-2 2-2 1 0 2 1 2 2 0 1-1 2-2 2z m-131 3l32 0 0-12-32 0z m232 0l31 0 0-12-31 0z m-40 58l32 31 2-2-31-31z m-154-155l31 31 2-2-31-31z m154 155l32 31 2-2-31-31z m-154-155l31 31 2-2-31-31z m87 223l13 0 0-32-13 0z m54 43l4 17-12 2-4-17c-7 2-15 3-22 3l0 31-26 0 0-31c-8 0-15-1-22-2l-4 17-12-3 4-17c-76-20-133-91-133-174 0-99 81-180 180-180 99 0 180 81 180 180-1 83-57 153-133 174z m-47-327c-84 0-153 69-153 153 0 84 69 152 153 152 84 0 152-68 152-152 0-84-68-153-152-153z m-7 52l13 0 0-32-13 0z m67 38l3 2 31-31-2-2z m-154 155l2 2 31-31-2-2z"
        />
      </g>
    </SvgIcon>
  );
}
