import { Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { IComponentProps } from './AdvancedBloc.connector';

interface IState {
  isOpen: boolean;
}

export class AdvancedBloc extends React.Component<IComponentProps, IState> {
  public state: IState = {
    isOpen: false,
  };
  public render() {
    const { title, children, description, classes } = this.props;
    const { isOpen } = this.state;

    const iconClass = isOpen ? `${classes.expandIcon} ${classes.expanded}` : classes.expandIcon;
    return (
      <div className={classes.container}>
        <div className={classes.title} onClick={this.toggle}>
          <Typography variant="h5">{title}</Typography>
          <ExpandMoreIcon className={iconClass} />
        </div>
        <Typography variant="body1" color="textSecondary">
          {description}
        </Typography>
        {isOpen ? <div className={classes.content}>{children}</div> : null}
      </div>
    );
  }
  private toggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };
}
