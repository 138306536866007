// TODO pass filters in params
import { getProject } from 'Services/project';
import { normalizeBooleanToInteger } from "Components/inputs/Toggle";

const CONFIG = [
  { name: 'id', filter: 'id' },
  { name: 'name', filter: 'name' },
  { name: 'translationsName', filter: 'translations.name' },
  { name: 'publicName', filter: 'publicName' },
  { name: 'description', filter: 'description' },
  { name: 'title', filter: 'title' },
  { name: 'player', filter: 'player' },
  { name: 'person', filter: 'person' },
  { name: 'filename', filter: 'filename' },
  { name: 'search', filter: 'search' },
  { name: 'username', filter: 'username' },
  { name: 'dateAfter', filter: 'date[after]' },
  { name: 'dateBefore', filter: 'date[before]' },
  { name: 'dateStrictlyBefore', filter: 'date[strictly_before]' },
  { name: 'dateStrictlyAfter', filter: 'date[strictly_after]' },
  { name: 'url', filter: 'url' },
  { name: 'area', filter: 'area' },
  { name: 'createAtAfter', filter: 'createdAt[after]' },
  { name: 'createAtBefore', filter: 'createdAt[before]' },
  { name: 'publishedAtAfter', filter: 'publishedAt[after]' },
  { name: 'publishedAtBefore', filter: 'publishedAt[before]' },
  { name: 'publishedAt', filter: 'publishedAt' },
  { name: 'unpublishedAt', filter: 'unpublishedAt' },
  { name: 'authors', filter: 'authors' },
  { name: 'tags', filter: 'tags.id' },
  { name: 'field', filter: 'field' },
  { name: 'status', filter: 'status' },
  { name: 'email', filter: 'email' },
  { name: 'competition', filter: 'club_competition' },
  { name: 'phaseCompetition', filter: 'phase.tournament.competition' },
  { name: 'country', filter: 'country' },
  { name: 'mainNationality', filter: 'mainNationality' },
  { name: 'club', filter: 'club' },
  { name: 'sourceClub', filter: 'previousMainTeam.club' },
  { name: 'targetClub', filter: 'mainTeam.club' },
  { name: 'current_team_team', filter: 'current_team[team]' },
  { name: 'fuzzy', filter: 'fuzzy' },
  { name: 'hdOnly', filter: 'hdOnly' },
  {
    name: 'footdataCompetitionIds',
    filter: 'articleCompetitions.footdataCompetitionId',
  },
  { name: 'footdataTeamIds', filter: 'articleTeams.footdataTeamId' },
  { name: 'footdataMatchIds', filter: 'footdataMatchIds' },
  { name: 'footdataPersonIds', filter: 'articlePersons.footdataPersonId' },
  { name: 'broadcastRelationsCompetitions', filter: 'broadcastRelations[competitions]' },
  { name: 'broadcastRelationsTeams', filter: 'broadcastRelations[teams]' },
  { name: 'locale', filter: 'locale' },
  { name: 'surveyTeam', filter: 'teams.id' },
  { name: 'surveyCompetition', filter: 'competitions.id' },
  { name: 'surveyMatch', filter: 'matches.id' },
  { name: 'surveyPerson', filter: 'persons.id' },
  { name: 'surveyId', filter: 'id' },
  { name: 'lineupSurveyTeam', filter: 'team.id' },
  { name: 'lineupSurveyCompetition', filter: 'tournament.competition' },
  { name: 'lineupSurveyMatch', filter: 'match.id' },
  { name: 'type', filter: 'type' },
  { name: 'lineupSurveyId', filter: 'id' },
  { name: 'isActive', filter: 'available[isActive]', type: 'bool' },
  { name: 'isEnabled', filter: 'isEnabled', type: 'bool' },
  { name: 'tournament.competition.id', filter: 'tournament.competition.id' },
];

export function parseQuery(search) {
  const searchArray = search.substr(1).split('&');
  const queries = {
    sort: {},
    toggles: {},
    filters: {},
  };
  searchArray.forEach(q => {
    if (q.startsWith('page=')) {
      queries.page = q.split('page=').pop();
    } else if (q.startsWith('itemsPerPage=')) {
      queries.itemsPerPage = q.split('itemsPerPage=').pop();
    } else if (q.startsWith('order[')) {
      const parts = q
        .split('order[')
        .pop()
        .split(']=');
      queries.sort.field = parts[0];
      queries.sort.order = parts[1];
    } else if (q == 'live=human') {
      queries.toggles.live = true;
    } else if (q === `locale=${getProject().lang}`) {
      queries.toggles.surveyCurrentLocaleOnly = true;
    } else {
      CONFIG.forEach(cfg => {
        if (q.startsWith(`${cfg.filter}=`)) {
          if ('type' in cfg && cfg.type === 'bool') {
            queries.toggles[cfg.name] = decodeURIComponent(q.split(`${cfg.filter}=`).pop());
          } else {
            queries.filters[cfg.name] = decodeURIComponent(q.split(`${cfg.filter}=`).pop());
          }
        }
      });
    }
  });
  return queries;
}

export function stringifyQuery(query) {
  const params = [];
  if (query.page && query.page > 1) {
    params.push(`page=${query.page}`);
  }
  if (query.itemsPerPage) {
    params.push(`itemsPerPage=${query.itemsPerPage}`);
  }
  if (query.sort.field) {
    params.push(`order[${query.sort.field}]=${query.sort.order}`);
  }
  if (query.toggles.live) {
    params.push(`live=human`);
  }
  if (query.toggles.surveyCurrentLocaleOnly) {
    params.push(`locale=${getProject().lang}`);
  }
  CONFIG.forEach(cfg => {
    if (query.filters[cfg.name]) {
      params.push(`${cfg.filter}=${encodeURIComponent(query.filters[cfg.name])}`);
    } else if (query.toggles[cfg.name]) {
      params.push(`${cfg.filter}=${normalizeBooleanToInteger(encodeURIComponent(query.toggles[cfg.name]))}`);
    }
  });
  return `?${params.join('&')}`;
}
