import axios from 'axios';
import getAuthorizationToken from 'Services/authorizationToken';

const rest = axios.create({
  /* global process*/
  baseURL: process.env.INTERNAL_API_URL,

  transformResponse: [
    function(data) {
      if (!data) return null;
      // TODO verify data is string
      const res = JSON.parse(data);
      if (res['@type'] === 'hydra:Collection') {
        return {
          collection: res['hydra:member'],
          totalItems: res['hydra:totalItems'],
          pagination:
            res['hydra:view'] && formatHydraPagination(res['hydra:view']),
        };
      } else if (res['@type'] === 'ConstraintViolationList') {
        const errors = {};
        res.violations.forEach(v => (errors[v.propertyPath] = v.message));
        return {
          type: res['@type'],
          description: res['hydra:description'],
          errors,
        };
      }

      return res;
    },
  ],
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});

// Add a request interceptor
rest.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${await getAuthorizationToken()}`;

  const { url, baseURL } = config;
  // insert locale in request url
  const newURL = `${baseURL}${url.split(baseURL)[1]}`;

  return { ...config, url: newURL };
});

/**
 * Transform hydra view object into readeable pagination object
 */
/* input
    @id:"/app_dev.php/api/fr/clubs?page=1"
    @type:"hydra:PartialCollectionView"
    hydra:first:"/app_dev.php/api/fr/clubs?page=1"
    hydra:last:"/app_dev.php/api/fr/clubs?page=4"
hydra:next:"/app_dev.php/api/fr/clubs?page=2"
 */
const formatHydraPagination = view => {
  const current = getRelativeUrl(view['@id']);
  const first = getRelativeUrl(view['hydra:first']);
  const last = getRelativeUrl(view['hydra:last']);

  const pagination = {
    current: { url: current, page: getPageNumber(current) },
    first: { url: first, page: getPageNumber(first) },
    last: { url: last, page: getPageNumber(last) },
    template: first.slice(0, -1),
    itemsPerPage: getItemsPerPage(current),
  };
  return pagination;
};
const getPageNumber = url => {
  if (url.indexOf('page=') != -1) {
    return Number(
      url
        .split('page=')
        .pop()
        .split('&')
        .shift()
    );
  } else {
    return 1;
  }
};
const getItemsPerPage = url => {
  if (url.indexOf('itemsPerPage=') != -1) {
    return Number(
      url
        .split('itemsPerPage=')
        .pop()
        .split('&')
        .shift()
    );
  } else {
    return null;
  }
};
const getRelativeUrl = url => (url ? url.split('/').pop() : '');

export default rest;
