import rest from 'Services/restFootData';

export function fetchRevisions(entity, id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_REVISIONS', payload: { entity, id } });
    return rest.get(`revisions/${entity}/${id}`).then(
      res =>
        dispatch({
          type: 'FETCH_REVISIONS_FULFILLED',
          payload: { entity, id, list: res.data },
        }),
      err =>
        dispatch({ type: 'FETCH_REVISIONS_REJECTED', payload: err.response })
    );
  };
}

export function rollbackRevision(id) {
  return function(dispatch) {
    dispatch({ type: 'ROLLBACK_REVISION' });
    return rest.post(`revisions/rollback/${id}`).then(
      res =>
        dispatch({ type: 'ROLLBACK_REVISION_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'ROLLBACK_REVISION_REJECTED',
          payload: err.response,
        })
    );
  };
}
