import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import SelectConstant from 'FDComponents/constants/Select';
import TextInput from 'Components/inputs/Text';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import ImageInput from '../../images/Input';
import AreaInput from 'FDComponents/areas/Input';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'name',
      'type',
      'surface',
      'capacity',
      'yearOfConstruction',
      'turfHeating',
      'runningTrack',
      'size',
      'ticketLink',
      'UEFAStadiumClassification',
      'street',
      'city',
      'postalCode',
      'isActive',
    ],
    objectFields: ['area', 'picture'],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'picture', component: ImageInput }],
      },
      {
        size: 9,
        fields: [{ name: 'name', component: TextInput }],
      },
      {
        fields: [
          { name: 'street', component: TextInput, size: 6 },
          { name: 'city', component: TextInput, size: 6 },
          { name: 'postalCode', component: TextInput, size: 6 },
          { name: 'area', component: AreaInput, size: 6 },
          {
            name: 'capacity',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          {
            name: 'yearOfConstruction',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          {
            name: 'turfHeating',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'runningTrack',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'surface',
            component: SelectConstant,
            entity: 'venue',
            size: 6,
          },
          { name: 'type', component: TextInput, size: 6 },
          {
            name: 'size',
            component: TextInput,
            hintText: '105m x 68m',
            size: 6,
          },
          {
            name: 'ticketLink',
            component: TextInput,
            hintText: 'http://www.link.com',
            size: 6,
          },
          {
            name: 'UEFAStadiumClassification',
            component: SelectConstant,
            normalize: normalizeInt,
            entity: 'venue',
            size: 6,
          },
          {
            name: 'isActive',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`venue_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
