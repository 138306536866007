import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { Typography, CircularProgress } from '@material-ui/core';

const styles = () => ({
  container: {
    padding: 15,
    textAlign: 'center',
  },
  progressContainer: {
    marginTop: 15,
    height: 50,
  },
});

@injectIntl
@withStyles(styles)
export class DeleteLoading extends Component {
  render() {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <div className={classes.container}>
        <Typography>{m.image_deleting}</Typography>
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      </div>
    );
  }
}
