import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

@injectIntl
export default class Teams extends Component {
  state = {
    anchorEl: undefined,
    open: false,
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      tournaments,
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <List subheader={<ListSubheader>{m.app_tournaments}</ListSubheader>}>
          {tournaments && tournaments[0] && (
            <ListItem
              button
              component="a"
              href={`#${getProject().url}/tournaments/${tournaments[0].id}`}
            >
              <ListItemText primary={tournaments[0].name} />
            </ListItem>
          )}
        </List>
        <Button
          style={{ margin: 8 }}
          aria-owns="tournaments-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Historique
        </Button>
        <Menu
          id="tournaments-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
        >
          {tournaments &&
            tournaments.map(tournament => (
              <MenuItem
                component="a"
                href={`#${getProject().url}/tournaments/${tournament.id}`}
                onClick={this.handleClose}
              >
                {tournament.name}
              </MenuItem>
            ))}
        </Menu>
      </div>
    );
  }
}
