import { MarkdownUtil } from 'react-mde';
import { store } from '../../../store';
import { asyncImageMedia } from './actions';

export const getMediaCommand = () => ({
  name: 'media',
  keyCommand: 'media',
  execute: async (state0, api) => {
    const response = await store.dispatch(asyncImageMedia());
    if (!response) return state0;
    const { image, size, format, url } = response;
    let urlText = '';
    let defaultAltAttribute = '';
    let imageSizeInfo = '';

    // image from media or upload
    if (image) {
      const { w, h } = size;
      const { alt, name, description } = image;
      const { urlFromFormat, sizesText } = getFormatUrl(image, format);

      defaultAltAttribute = defineDefaultAltAttribute(alt, name, description);
      urlText = urlFromFormat;
      imageSizeInfo = w && h ? ` =${sizesText}` : '';

      // image from url "import"
    } else if (url) {
      urlText = url;
    }

    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = MarkdownUtil.selectWord({
      text: state0.text,
      selection: state0.selection,
    });
    const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the current word
    const template = `![${state1.selectedText || defaultAltAttribute}](${urlText}${imageSizeInfo})`;

    const state2 = api.replaceSelection(template);
    // Adjust the selection to not contain the markdown syntax
    const letterOffset = urlText.length + imageSizeInfo.length + 3;
    api.setSelectionRange({
      start: state2.selection.end - letterOffset - state1.selectedText.length,
      end: state2.selection.end - letterOffset,
    });
  },
});

export const getMediaCommandInfo = async () => {
  const response = await store.dispatch(asyncImageMedia());
  if (!response) return null;
  const { image, size, format, url } = response;
  let urlText = '';
  let defaultAltAttribute = '';
  let imageSizeInfo = '';

  // image from media or upload
  if (image) {
    const { w, h } = size;
    const { alt, name, description } = image;
    const { urlFromFormat, sizesText } = getFormatUrl(image, format);

    defaultAltAttribute = defineDefaultAltAttribute(alt, name, description);
    urlText = urlFromFormat;
    imageSizeInfo = w && h ? ` =${sizesText}` : '';

    // image from url "import"
  } else if (url) {
    urlText = url;
  }

  return {
    start: '![',
    end: `](${urlText}${imageSizeInfo})`,
    defaultAltAttribute,
  };
};

const defineDefaultAltAttribute = (alt, name, description) => alt || name || description || '';

const getFormatUrl = (image, format) => {
  switch (format) {
    case 'original':
      return {
        urlFromFormat: image.originalUrl,
        sizesText: `${image.width}x${image.height}`,
      };
    case 'large':
      return {
        urlFromFormat: image.urls['1200x675'],
        sizesText: `1200x675`,
      };
    case 'medium':
    default:
      return {
        urlFromFormat: image.urls['640xauto'],
        sizesText: `640x${Math.round((640 * image.height) / image.width)}`,
      };
  }
};
