import React, { Component } from 'react';
import { getProject } from 'Services/project';
import Nav from 'Components/Nav';
import isAdmin from 'Services/me';

import DescriptionIcon from '@material-ui/icons/Description';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FolderIcon from '@material-ui/icons/Folder';
import StarsIcon from '@material-ui/icons/Stars';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import LabelIcon from '@material-ui/icons/Label';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';

export default class Navigation extends Component {
  render() {
    const menu = [
      { labelKey: 'app_dashboard', href: getProject().url, img: DashboardIcon },
      {
        labelKey: 'app_articles',
        href: `${getProject().url}/articles`,
        img: DescriptionIcon,
      },
      {
        labelKey: 'app_liveTransfer',
        href: `${getProject().url}/live-transfers`,
        img: ReceiptIcon,
      },
      {
        labelKey: 'app_authors',
        href: `${getProject().url}/authors`,
        img: PersonIcon,
      },
      { labelKey: 'app_tags', href: `${getProject().url}/tags`, img: FolderIcon },
      {
        labelKey: 'app_highlighting',
        href: `${getProject().url}/highlighting`,
        img: StarsIcon,
      },
      {
        labelKey: 'app_pageseos',
        href: `${getProject().url}/pageseos`,
        img: LabelIcon,
      },
      {
        labelKey: 'app_push',
        href: `${getProject().url}/push`,
        img: NotificationsIcon,
      },
      {
        labelKey: 'app_configurations',
        href: `${getProject().url}/configurations`,
        img: SettingsIcon,
      },
    ];

    const menuOption = [
      {
        labelKey: 'app_users',
        href: `${getProject().url}/users`,
        img: AccountBoxIcon,
      },
      {
        labelKey: 'app_documentation',
        href: `${getProject().url}/documentation`,
        img: ImportContactsIcon,
      },
    ];

    return (
        <Nav
            path={this.props.path}
            menu={menu}
            menuOption={menuOption}
            isAdmin={isAdmin()}
        />
    );
  }
}
