import { FormEdit } from 'Components/FormEdit';
import Date from 'Components/inputs/Date';
import SelectConstant from 'FDComponents/constants/Select';
import React, { Component } from 'react';
import { AmountInput } from '../../../common/components/inputs/AmountInput';
import { SelectBrand } from '../../brands/components/SelectBrand';

export default class Form extends Component {
  config = {
    fields: ['id', 'startedAt', 'endedAt', 'type', 'amount'],
    objectFields: ['brand'],
    disposition: [
      {
        fields: [
          { name: 'startedAt', component: Date, size: 6 },
          { name: 'endedAt', component: Date, size: 6 },
          {
            name: 'type',
            component: SelectConstant,
            entity: 'clubSponsor',
            size: 6,
          },
          { name: 'brand', component: SelectBrand, size: 6 },
          {
            name: 'amount',
            names: ['amount.value', 'amount.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit, onDelete } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`clubSponsor_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
