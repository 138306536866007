import React, { Component } from 'react';
import IconGoal from 'Components/icons/Goal';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  time: {
    margin: '0 8px',
  },
  events: {
    display: 'flex',
    background: '#ebebec',
    padding: 4,
  },
  event: {
    display: 'flex',
    margin: 4,
    background: 'white',
    padding: '4px 8px',
    fontSize: '12px',
    lineHeight: '24px',
  },
  player: {
    fontWeight: '500',
  },
};

@withStyles(styles)
export default class Goals extends Component {
  renderPlayerName = p => p && p.membership.personMatchName;

  renderMatchEvent = ev => {
    const { classes, playersMap } = this.props;
    return (
      <div className={classes.event}>
        <IconGoal />
        <div className={classes.time}>{ev.timeMin}'</div>
        <div>{ev.type != 'goal' && <small>({ev.typeTranslated})</small>}</div>
        <div style={{ flex: 1 }} />
        <div className={classes.player}>
          {this.renderPlayerName(playersMap.get(ev.matchTeamPlayerId))}
        </div>
      </div>
    );
  };

  render() {
    const { formations, classes } = this.props;

    return (
      <div className={classes.events}>
        <div style={{ flex: 1 }}>
          {formations.home.goals &&
            Object.values(formations.home.goals).map(me =>
              this.renderMatchEvent(me)
            )}
        </div>
        <div style={{ flex: 1 }}>
          {formations.away.goals &&
            Object.values(formations.away.goals).map(me =>
              this.renderMatchEvent(me)
            )}
        </div>
      </div>
    );
  }
}
