import { commands } from 'react-mde';
import { getMediaCommand } from '../../../../../../footdata/images/Command';
import { getEmbedCommand } from '../../../../../../footmercato/articles/embed/Command';
import { getProject } from '../../../../../services/project';
import { buttonCommand } from './buttonCommand';
import { headerCommand } from './headerCommand';
import { quoteCommand } from './quoteCommand';
import { simpleQuoteCommand } from './simpleQuoteCommand';

export const getCommands = () => [
  {
    commands: [headerCommand, commands.boldCommand, commands.italicCommand],
  },
  {
    commands: [
      commands.linkCommand,
      buttonCommand,
      quoteCommand,
      addSimpleQuoteCommandByLang(),
    ],
  },
  {
    commands: [commands.unorderedListCommand, commands.orderedListCommand],
  },
  {
    commands: [getMediaCommand()],
  },
  {
    commands: [getEmbedCommand()],
  }
];

const addSimpleQuoteCommandByLang = () => {
  const { lang } = getProject();
  return simpleQuoteCommand(lang);
};
