import React, { Component } from 'react';

import { updateMenuItem } from '../actions';
import { FormEdit } from 'Components/FormEdit';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import TextInput from 'Components/inputs/Text';
import { connect } from 'react-redux';
import SelectIconName from "FM/highlighting/components/selectIconName";
import { getProject } from "Services/project";
import { addFieldConfigToDisposition } from 'Components/FormEdit/utils'


@connect()
export default class Form extends Component {
  config = () => {
    let config = {
      fields: ['id', 'title', 'link', 'isActive','iconName'],
      disposition: [
        {
          fields: [
            { name: 'title', component: TextInput, size: 4 },
            { name: 'link', component: TextInput, size: 4 },
            {
              name: 'isActive',
              component: Toggle,
              normalize: normalizeBoolean,
              size: 4,
            },

          ],
        },
      ],
    };
    if (getProject().featureFlagsFmV2) {
      config.fields.push('iconName');
      config = addFieldConfigToDisposition(config, { name: 'iconName', component: SelectIconName, size: 4 }, 2);
    }
    return config;
  }

  handleSubmit = item => {
    const { dispatch } = this.props;
    return dispatch(updateMenuItem(item.id, item));
  };

  render() {
    const { item } = this.props;

    return (
      <FormEdit
        key={item.id}
        form={`menuItem_${item.id}`}
        onSubmit={this.handleSubmit}
        data={item}
        config={this.config()}
      />
    );
  }
}
