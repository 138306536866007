import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import { AmountInput } from '../../../../common/components/inputs/AmountInput';
import TextInput from '../../../../common/components/inputs/Text';
import { SelectBrand } from '../../../brands/components/SelectBrand';
import ClubInput from '../../../clubs/Input';
import SelectConstant from '../../../components/constants/Select';
import SelectSeason from '../../../components/seasons/Select';
import ImageInput from '../../../images/Input';
import { IProduct } from '../../typings';

interface IProps {
  data: any;
  onSubmit: (product: IProduct) => void;
  onDelete: (product: IProduct) => void;
  onRemove?: (id: string) => void;
}

export class ProductForm extends Component<IProps> {
  public config = {
    fields: ['id', 'name', 'category', 'mainColor', 'shopLink', 'type', 'shopName', 'price', 'priceAfterDiscount'],
    objectFields: ['season', 'image', 'brand', 'club'],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'image', component: ImageInput }],
      },
      {
        size: 9,
        fields: [
          { name: 'name', component: TextInput },
          { name: 'category', component: SelectConstant, entity: 'product' },
          { name: 'type', component: SelectConstant, entity: 'product' },
          { name: 'brand', component: SelectBrand },
          { name: 'club', component: ClubInput },
        ],
      },
      {
        fields: [
          { name: 'season', component: SelectSeason, size: 6 },
          { name: 'mainColor', component: TextInput, size: 6 },
          { name: 'shopName', component: TextInput, size: 6 },
          { name: 'shopLink', component: TextInput, size: 6 },
          {
            name: 'price',
            labelKey: 'field_price',
            names: ['price.value', 'price.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
          {
            name: 'priceAfterDiscount',
            labelKey: 'field_priceAfterDiscount',
            names: ['priceAfterDiscount.value', 'priceAfterDiscount.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
        ],
      },
    ],
  };

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`product_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }
}
