import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { updateTag } from '../actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Form from './Form';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
});

@connect()
@withStyles(styles)
export default class TagsPanels extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updateTag(id, data));
  };

  renderPanel = tag => {
    const { classes, prefix } = this.props;
    const { expanded } = this.state;

    if (!tag) return null;

    return (
      <ExpansionPanel
        expanded={expanded === tag.id}
        onChange={this.handleExpand(tag.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {prefix}
            {tag.title}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === tag.id && <Form onSubmit={this.handleChange(tag.id)} data={tag} />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { collection = [] } = this.props;
    return collection.map(this.renderPanel);
  }
}
