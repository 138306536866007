import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InjuriesPanel from '../../../injuries/Panels';

@injectIntl
export default class Injuries extends Component {
  render() {
    const {
      injuries,
      intl: { messages: m },
    } = this.props;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_injuries}</Typography>
        </Toolbar>
        <div style={{ padding: 16 }}>
          <InjuriesPanel injuries={injuries} />
        </div>
      </div>
    );
  }
}
