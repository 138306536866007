import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';
import React, { Component } from 'react';
import ChannelSelect from '../Select';
import ImageInput from '../../images/Input';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import Toggle, { normalizeBoolean } from "Components/inputs/Toggle";
import AreaInput from 'FDComponents/areas/Input';

export class ChannelForm extends Component {
  config = {
    fields: ['id', 'name', 'shortName', 'isActive', 'position', 'slug', 'website', 'isStreamingAvailable'],
    objectFields: ['logo', 'area', 'parent'],

    disposition: [
      {
        size: 3,
        fields: [{ name: 'logo', component: ImageInput }],
      },
      {
        size: 9,
        fields: [
          { name: 'name', component: TextInput },
          { name: 'shortName', component: TextInput },
          { name: 'parent', component: ChannelSelect },
          { name: 'isActive', component: Toggle, normalize: normalizeBoolean },
          { name: 'position', component: IntInput, normalize: normalizeInt },
          { name: 'slug', component: TextInput, disabled: true },
          { name: 'website', component: TextInput },
          { name: 'isStreamingAvailable', component: Toggle, normalize: normalizeBoolean },
          { name: 'area' , component: AreaInput }
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`channel_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
