import React, { Component } from 'react';
import { FormEdit } from '../../components/FormEdit';
import CheckboxInput from '../../components/inputs/Checkbox';
import { LocaleSelect } from '../../components/inputs/LocaleSelect';
import MultiSelect from '../../components/inputs/MultiSelect';
import TextInput from '../../components/inputs/Text';
import { IUser } from '../typings';

interface IProps {
  data: any;
  onSubmit: (user: IUser) => void;
}
export class UserForm extends Component<IProps> {
  public config = {
    fields: ['id', 'username', 'firstname', 'lastname', 'email', 'roles', 'websites', 'language', 'isNewEditorEnabled'],
    disposition: [
      {
        fields: [
          { name: 'email', component: TextInput, size: 6 },
          { name: 'username', component: TextInput, disabled: true, size: 6 },
          { name: 'firstname', component: TextInput, size: 6 },
          { name: 'lastname', component: TextInput, size: 6 },
          {
            name: 'roles',
            component: MultiSelect,
            isAdmin: true,
            list: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_LIMITED'],
            size: 6,
          },
          {
            name: 'websites',
            component: MultiSelect,
            isAdmin: true,
            list: [
              'footdataapi_fr',
              'footdataapi_es',
              'footdataapi_de',
              'footdataapi_it',
              'footdataapi_en',
              'footdataapi_br',
              'footmercato_fr',
              'footmercato_es',
              'footmercato_de',
            ],
            size: 6,
          },
          { name: 'language', component: LocaleSelect, size: 6 },
        ],
      },
      {
        fields: [{ name: 'isNewEditorEnabled', component: CheckboxInput, size: 6 }],
      },
    ],
  };

  public render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit key={data.id} form={`user_${data.id}`} onSubmit={onSubmit} data={data} config={this.config} main />
    );
  }
}
