import { WithStyles, withStyles } from '@material-ui/core/styles';
// @ts-ignore
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { IFilter } from '../../../typings';
import { styles } from './styles';

interface IOwnProps {
  form: string;
  filters: IFilter[];
  onChange: any;
  initialValues: any;
}

export type IComponentProps = WithStyles & InjectedFormProps & RouteComponentProps & IOwnProps;

export const connector = compose<IComponentProps, IOwnProps>(
  withRouter,
  withStyles(styles),
  reduxForm({})
);
