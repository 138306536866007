import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';
import { getProject } from 'Services/project';

const groups = ['read_user_game'];

export function getSurvey(id) {
  const params = {
    groups,
  };
  return function(dispatch) {
    dispatch({ type: 'GET_SURVEY' });

    return rest
      .get(`surveys/${id}`, { params })
      .then(
        res => dispatch({ type: 'GET_SURVEY_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_SURVEY_REJECTED', payload: err.response })
      );
  };
}

export function fetchSurveys(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    ignoreLocale: 1,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_SURVEY_LIST' });

    return rest
      .get('surveys', { params })
      .then(
        res => dispatch({ type: 'FETCH_SURVEY_LIST_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_SURVEY_LIST_REJECTED', payload: err.response })
      );
  };
}

export function translateSurvey(surveyId) {
  return function(dispatch) {
    dispatch({ type: 'TRANSLATE_SURVEY' });

    return rest.get(`surveys/${surveyId}/translate`).then(
      () =>
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_surveyTranslated', type: 'success' },
        }),
      err => dispatch({ type: 'TRANSLATE_SURVEY_REJECTED', payload: err.response })
    );
  };
}

export function updateSurvey(id, survey) {
  const params = {
    groups: ['read_user_game', 'write'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_SURVEY', payload: id });

    return rest.put(`surveys/${id}`, survey, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_surveyUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_SURVEY_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_SURVEY_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addSurvey(survey) {
  survey.mainLocale = getProject().lang;

  return function(dispatch) {
    dispatch({ type: `ADD_SURVEY` });

    return rest.post('surveys', survey).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_surveyAdded', type: 'success' },
        });
        return dispatch({
          type: `ADD_SURVEY_FULFILLED`,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_surveyAdd_error', type: 'error' },
        });
        return dispatch({
          type: `ADD_SURVEY_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}

export function searchSurvey(id) {
  const params = {
    id,
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_SURVEY_LIST' });

    return rest
      .get(`surveys`, { params })
      .then(
        res => dispatch({ type: 'SEARCH_SURVEY_LIST_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_SURVEY_LIST_REJECTED', payload: err.response })
      );
  };
}

export function duplicateSurvey(surveyId) {
  return function(dispatch) {
    dispatch({ type: 'DUPLICATE_SURVEY' });

    return rest.get(`surveys/${surveyId}/duplicate`).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_surveyDuplicated', type: 'success' },
        });
        return dispatch({ type: 'ADD_SURVEY_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'DUPLICATE_SURVEY_REJECTED', payload: err.response })
    );
  };
}
