import { getFDProjectUrl, getProject } from '../../common/services/project';
import { IClub } from '../clubs/typings';
import { IMatch } from '../matches/typings';

type IHrefMatchLive = 'live' | 'directo' | 'spiel';

const hrefMatchLive = (): IHrefMatchLive => {
  const { lang } = getProject();

  if (lang === 'de') return 'spiel';
  if (lang === 'es') return 'directo';
  return 'live';
};

// Target
// FWiki

// to use with history.push
export const getMatchDetailLink = (id: string) => `${getFDProjectUrl()}/matches/${id}`;

// to use with href
export const getClubDetailHref = (club: IClub) =>
  `#${getFDProjectUrl()}/clubs/${club.slug}`;
export const getMatchDetailHref = (id: string) => `#${getMatchDetailLink(id)}`;
export const getMatchLiveHref = (id: string) =>
  `#${getFDProjectUrl()}/${hrefMatchLive()}/${id}`;
export const getClubStatsHref = () => `#${getFDProjectUrl()}/stats-clubs`;

// Target
// APP
export const getAppMatchLink = (id: string, slug?: string) => slug ? `match/${id}-${slug}` : 'matches';

// Target
// Footmercato
export const getSiteMatchHref = (match: IMatch) =>
  `${getProject().baseUrlFM}${hrefMatchLive()}/${match.id}-${match.slug}#tabLive`;
