import { getItemCollection } from '../../common/services/connectors';
import { IRootState } from '../../typings';
import { IChronoUnit, ICommentary } from '../commentaries/typings';
import { getPeriodIndex } from '../live/Commentary/components/PeriodSelector/utils';
import { IMatchEvent } from '../matchEvents/typings';
import { IMatch } from './typings';

export const selectMatch = (state: IRootState, matchId: string): IMatch | null => {
  return state.match.detail[matchId] || null;
};

export const selectMatchCommentaries = (
  state: IRootState,
  matchId: string
): ICommentary[] => {
  const commentaries = getItemCollection(
    'commentariesIds',
    state.match.detail[matchId],
    state.commentary
  );

  return commentaries.sort(commentariesSort);
};

export const selectMatchCommentariesEvents = (state: IRootState, matchId: string) => {
  const commentaries = selectMatchCommentaries(state, matchId);
  const matchEvents: IMatchEvent[] = [];
  if (commentaries) {
    commentaries.forEach(commentary => {
      if (commentary.matchEvent) matchEvents.push(commentary.matchEvent);
    });
  }
  return matchEvents;
};

export const selectMatchCommentariesWithMatchEvent = (
  state: IRootState,
  matchId: string
) => selectMatchCommentaries(state, matchId).filter(commentary => commentary.matchEvent);

const commentariesSort = (a: ICommentary, b: ICommentary): number => {
  const perdiodDiff = getPeriodIndex(b.period) - getPeriodIndex(a.period);
  if (perdiodDiff !== 0) return perdiodDiff;

  const diffChrono = compareChrono(a, b);
  if (diffChrono !== 0) return diffChrono;

  return compareCreatedAt(a, b);
};
const compareChrono = (a: ICommentary, b: ICommentary): number => {
  const diffTimeMin = compareChronoDigit(a.timeMin, b.timeMin);
  if (diffTimeMin !== 0) return diffTimeMin;

  const diffAdditionnalTimeMin = compareChronoDigit(
    a.additionnalTimeMin,
    b.additionnalTimeMin
  );
  if (diffAdditionnalTimeMin !== 0) return diffAdditionnalTimeMin;

  return 0;
};
const compareChronoDigit = (a: IChronoUnit, b: IChronoUnit): number => {
  if (a === null && b === null) return 0;
  if (a === null) return 1;
  if (b === null) return -1;
  return b - a;
};

const compareCreatedAt = (a: ICommentary, b: ICommentary): number =>
  a.createdAt < b.createdAt ? 1 : -1;