export const getPushTargetUrl = (isDeepLinkingV2Enabled, data) => {
  // let redirection be handled by app for deeplinking v1
  if (data.redirectUrl && data.content === null && isDeepLinkingV2Enabled) {
    const regExp = /\/(live|directo|spiel)\/(\d{5,})-([^\/#?]+)/;
    const found = data.redirectUrl.match(regExp);
    console.log('found ?', found);

    if (found) {
      return `match/${found[2]}-${found[3]}`;
    }
  }

  return isDeepLinkingV2Enabled ? `article/a${data.id}-${data.slug}` : `articles/${data.id}`;
}
