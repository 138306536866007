import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Detail from './Detail';

export default function TeamsRoutes({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/:topicSlug`} component={Detail} />
    </Switch>
  );
}
