import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { fetchImage, updateImage, deleteImage } from '../actions';
import { isUserAdmin } from '../../../common/users/utils';

import TabsRoute from 'Components/TabsRoute';

import Form from './Form';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReturnButton from 'Components/ReturnButton';
import { DeleteLoading } from '../Components/DeleteLoading';

import Layout from 'Components/Layout';
import Similars from './Similars';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';

@withRouter
@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  image: store.image.detail[props.match.params.topicId],
  imageDeleting: store.image.deleting,
  fetching: store.image.fetching,
  me: store.me,
}))
export default class Detail extends Component {
  state = {
    width: null,
    height: null,
  };

  menu = [{ label: 'app_imageInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchImage(targetId || id));
  };

  handleImageChange = image => {
    const { dispatch } = this.props;
    return dispatch(updateImage(image.id, image));
  };

  handleDelete = () => {
    const { image, dispatch, history } = this.props;
    return dispatch(
      deleteImage(image.id, () => history.push(`${getProject().url}/images`)) // push declared here because component re-rendered. Image not exist after and props.history not exist.
    );
  };

  getLabel = () => {
    const {
      image,
      intl: { messages: m },
    } = this.props;
    return image.name || image.filename || `${m.app_image}: ${image.id}`;
  };

  onImgLoad = e => {
    const { width, height } = this.state;

    if (!width && !height) {
      const img = e.currentTarget;
      this.setState({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    }
  };

  renderHiddenImage = image => (
    <img
      width="1"
      height="1"
      src={image.originalUrl}
      style={{ opacity: 0, position: 'absolute', zIndex: -10 }}
      onLoad={this.onImgLoad}
    />
  );

  render() {
    const {
      me,
      fetching,
      image,
      match,
      history,
      imageDeleting,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!image) {
      return <div>{m.error_noData}</div>;
    }

    const { width, height } = this.state;

    const showDimensions = width && height ? `(${width}x${height})` : null;

    return (
      <Layout
        id="image-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_images', href: `#${getProject().url}/images` },
              { label: this.getLabel() },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">
                {this.getLabel()} {showDimensions}
              </Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="images" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            {this.renderHiddenImage(image)}
            <Route
              exact
              path={match.url}
              render={() => {
                if (imageDeleting) {
                  return <DeleteLoading />;
                }

                return (
                  <Form
                    onSubmit={this.handleImageChange}
                    onDelete={isUserAdmin(me) ? this.handleDelete : null}
                    data={image}
                  />
                );
              }}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Similars club={image.club} person={image.person} />
          </Paper>
        }
      />
    );
  }
}
