
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { closeEmbed } from './actions';
import EmbedLibrary from '../EmbedLibrary';
import { EmbedForm } from "../EmbedForm";
import { getEmbedConfig } from "../utils";

/* Move to transitions components ? */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  label: {
    color: '#757575',
    fontSize: 16,
    position: 'absolute',
    left: 8,
    top: 8,
  },
  container: {
    position: 'relative',
    minWidth: 150,
    minHeight: 150,
    height: '100%',
  },
  image: {
    maxWidth: '100%',
  },
  actions: {
    display: 'flex',
    position: 'absolute',
    bottom: 12,
    right: 0,
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});
@injectIntl
@withStyles(styles)
@connect(store => ({
  editorEmbed: store.editor.embed,
}))
@withMobileDialog()
export default class EmbedInput extends Component {
  state = {
    tab: 0,
  };

  handleClose = answer => {
    const { dispatch } = this.props;
    return dispatch(closeEmbed(answer));
  };

  handleChangeTab = (tab) => {
    this.setState({ tab });
  };




  render() {
    const {
      editorEmbed,
      fullScreen,
    } = this.props;
    const { tab } = this.state;

    return (
      <Dialog
        open={editorEmbed.open || false}
        onClose={() => this.handleClose()}
        TransitionComponent={Transition}
        maxWidth={false}
        PaperProps={{ style: {
            minHeight: '60vh',
          } }}
        fullScreen={fullScreen}
        fullWidth
      >
        {tab === 0 &&
            <EmbedLibrary
                embeds={getEmbedConfig()} handleChangeTab={this.handleChangeTab} onclose={this.handleClose}
            />}
        {tab !== 0 &&
            <EmbedForm
                data={getEmbedConfig()[tab]} name={tab} handleChangeTab={this.handleChangeTab}
            />}
      </Dialog>
    );
  }
}
