import { store } from '../../store';
import { isUserAdmin, hasUserAccessToRestrictedArticles } from '../users/utils';

export default function isAdmin() {
  const me = store.getState().me;
  return me.roles && isUserAdmin(me);
}

export function hasAccessToRestrictedArticles() {
  const me = store.getState().me;
  return me.username && hasUserAccessToRestrictedArticles(me);
}
