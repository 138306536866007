import { IMatch } from '../matches/typings';
import { IPerson } from '../people/typings';
import { ITeam } from '../teams/typings';
import { ITournament } from '../tournaments/typings';

export interface ILineupSurvey {
  id: string;
  type: string;
  typeTranslated: string;
  participantsCountBySource?: IParticipantBySource[];
  totalParticipantsCount?: number;
  startDate: string;
  endDate?: string;
  isEnabled: boolean;
  isActive: boolean;
  title: string;
  slug: string;
  sharedImageTitle: string;
  team: ITeam;
  tournament: ITournament;
  maxSelectablePlayers: number;
  selectablePlayers: ILineupSurveyPerson[];
  match: IMatch;
  isHomepageDisabled: boolean;
  mostUsedFormation: string;
  countByFormation: ICountByFormation[];
  availableFormations: string;
}

export interface IParticipantBySource {
  source: string;
  count: number;
}

export interface ICountByFormation {
  formation: string;
  count: number;
}

export interface ILineupSurveyPerson {
  person: IPerson;
  position: string;
}

export const TYPE_SQUAD = 'squad';
