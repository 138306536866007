import { Chip } from '@material-ui/core';
import React, { Component } from 'react';
import { ITag } from '../../../tags/typings';

interface IComponentProps {
  tags: ITag[];
}
export class ArticleTagsCell extends Component<IComponentProps> {
  public render() {
    const { tags } = this.props;

    const [first, second, ...rest] = tags;
    const chips = [first, second].map(t => t && <Chip key={t.title} label={t.title} style={{ margin: 4 }} />);
    if (rest.length) {
      chips.push(<Chip key={rest.length} label={`+${rest.length}`} style={{ margin: 4 }} />);
    }
    return chips;
  }
}
