import React, { Component } from 'react';
import moment from 'moment';

import { fetchHighlightedMatches } from '../actions';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { TableList } from '../../../common/components/TableList';
import Row from '../List/Row';
import AddHighlightedMatches from './Add';

@injectIntl
@connect(store => ({
  games: store.matches.highlighted && store.matches.highlighted.list,
}))
export default class FeaturedMatches extends Component {
  day = moment()
    .add(4, 'days')
    .format('l');
  state = {
    open: false,
  };
  config = {
    headers: [
      { labelKey: 'field_date' },
      { labelKey: 'match_homeTeam' },
      { labelKey: 'match_awayTeam' },
      { labelKey: 'app_competition' },
    ],
  };
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    const { dispatch } = this.props;
    dispatch(fetchHighlightedMatches(this.day));
  };

  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false }, this.fetchData);
  };

  render() {
    const {
      games = [],
      intl: { messages: m },
    } = this.props;

    const { open } = this.state;

    return open ? (
      <AddHighlightedMatches onClose={this.close} />
    ) : (
      <div>
        <Toolbar style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            {m.match_nextHighlighted}
          </Typography>
          <Button variant="contained" color="primary" onClick={this.open}>
            {m.match_highlight}
          </Button>
        </Toolbar>
        <TableList
          tableData={games}
          tableHeaders={this.config.headers}
          onSort={this.handleSort}
          Row={Row}
        />
      </div>
    );
  }
}
