import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';
import { pauseMatch, resumeMatch, suspendMatch } from './requests';
import restATInternet from 'Services/restATInternet';
import moment from "moment";

const detailGroups = [
  'read_match_details',
  'read_match_players',
  '_image',
  '_chrono',
  '_team_season',
];

export function fetchMatches(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCHES' });

    return rest
      .get('matches', { params })
      .then(
        res => dispatch({ type: 'FETCH_MATCHES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MATCHES_REJECTED', payload: err.response })
      );
  };
}

export function fetchMatch(id) {
  const params = { groups: detailGroups };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCH' });

    return rest
      .get(`matches/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_MATCH_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MATCH_REJECTED', payload: err.response, id })
      );
  };
}

export function getMatchId(id) {
  const params = { groups: detailGroups };

  return function(dispatch) {
    dispatch({ type: 'GET_MATCH' });

    return rest
      .get(`matches/${id}`, { params })
      .then(
        res => dispatch({ type: 'GET_MATCH_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_MATCH_REJECTED', payload: err.response })
      );
  };
}

export function fetchMatchPreview(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCHPREVIEW' });

    return rest.get(`matches/preview/${id}`).then(
      res => dispatch({ type: 'FETCH_MATCHPREVIEW_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_MATCHPREVIEW_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchMatchFormations(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCHFORMATIONS' });

    return rest.get(`matches/formations/${id}`).then(
      res =>
        dispatch({
          type: 'FETCH_MATCHFORMATIONS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_MATCHFORMATIONS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchNextMatches(search, number = 10) {
  const params = {
    itemsPerPage: number,
    ...qs.parse(search),
    status: 'fixture',
    'order[date]': 'asc',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCHES_NEXT' });

    return rest.get('matches', { params }).then(
      res => dispatch({ type: 'FETCH_MATCHES_NEXT_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_MATCHES_NEXT_REJECTED',
          payload: err.response,
        })
    );
  };
}


export function fetchFutureMatches(search, number = 10) {
  const params = {
    itemsPerPage: number,
    ...qs.parse(search),
    status: ['fixture', 'playing'],
    'order[date]': 'asc',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCHES_FUTURE' });

    return rest.get('matches', { params }).then(
      res => dispatch({ type: 'FETCH_MATCHES_FUTURE_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_MATCHES_FUTURE_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchHighlightedMatches(limit) {
  const params = {
    'date[before]': limit,
    'translations.isHighlighted': 1,
    status: ['fixture', 'playing'],
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCHES_HIGHLIGHTED' });

    return rest.get('matches', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_MATCHES_HIGHLIGHTED_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_MATCHES_HIGHLIGHTED_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchMatchLink(id) {
  const params = {
    groups: ['read_link'],
  };
  return function(dispatch, getState) {
    const link = getState().match.link[id];
    if (link) return Promise.resolve({ payload: link });

    dispatch({ type: 'FETCH_MATCH_LINK', payload: { id } });
    return rest
      .get(`matches/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_MATCH_LINK_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MATCH_LINK_REJECTED', payload: err.response })
      );
  };
}

export function updateMatch(id, match) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_MATCH', payload: id });

    return rest.put(`matches/${id}`, match).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_matchUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_MATCH_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_MATCH_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function updateMatchPeriod(id, param) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_MATCH', payload: id });
    return rest.post(`matches/update-period/${id}`, param).then(
      res => dispatch({ type: 'UPDATE_MATCH_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'UPDATE_MATCH_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function searchMatches(search) {
  const params = {
    targets: ['Match'],
    orderBy: ['moreRecent'],
  };

  const formatSearch = search.replace ? search.replace(/\//gi, '') : search;

  return function(dispatch) {
    dispatch({ type: 'SEARCH_MATCHES', payload: { search: formatSearch } });
    return rest
      .get(`search/${formatSearch}`, { params })
      .then(
        res => dispatch({ type: 'SEARCH_MATCHES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_MATCHES_REJECTED', payload: err.response })
      );
  };
}

export function pauseMatchAction(id) {
  return function(dispatch) {
    dispatch({ type: 'PAUSE_MATCH' });
    return pauseMatch(id).then(
      res => dispatch({ type: 'PAUSE_MATCH_FULFILLED', payload: res.data }),
      err => dispatch({ type: 'PAUSE_MATCH_REJECTED', payload: err.response })
    );
  };
}

export function resumeMatchAction(id) {
  return function(dispatch) {
    dispatch({ type: 'RESUME_MATCH' });
    return resumeMatch(id).then(
      res => dispatch({ type: 'RESUME_MATCH_FULFILLED', payload: res.data }),
      err => dispatch({ type: 'RESUME_MATCH_REJECTED', payload: err.response })
    );
  };
}

export function suspendMatchAction(id) {
  return function(dispatch) {
    dispatch({ type: 'SUSPEND_MATCH' });
    return suspendMatch(id).then(
      res => dispatch({ type: 'SUSPEND_MATCH_FULFILLED', payload: res.data }),
      err => dispatch({ type: 'SUSPEND_MATCH_REJECTED', payload: err.response })
    );
  };
}

export function getMatchPronosticStat(matchId) {
  const params = {
    'match.id': matchId,
    groups: ['read']
  };

  return function (dispatch) {
    dispatch({ type: 'FETCH_MATCH_PRONOSTIC_STAT' });

    return rest.get('match_pronostic_stats', { params }).then(
        res =>
            dispatch({
              type: 'FETCH_MATCH_PRONOSTIC_STAT_FULFILLED',
              payload: res.data
            }),
        err =>
            dispatch({
              type: 'FETCH_MATCH_PRONOSTIC_STAT_REJECTED',
              payload: err.response,
            })
    );
  }
}

export function getAtInternetStat(match) {
  const params = {
    columns: ['eventlabel', 'event_name', 'm_visits'],
    sort: ['-m_visits'],
    filter: {
      property: {
        $OR: [
          {
            $AND: [
              {
                eventlabel: {
                  $eq: match.id,
                },
              },
              {
                event_name: {
                  $in: ['prono', 'prono.answer', 'prono.answer.updated', 'prono.clickaffiliation'],
                },
              },
            ],
          },
          {
            $AND: [
              {
                matchid: {
                  $eq: match.id,
                },
              },
              {
                event_name: {
                  $in: ['prono.share_modal.share'],
                },
              },
            ],
          }
        ],
      },
    },
    space: {
      /* global process*/
      s: process.env.ATINTERNET_IDS,
    },
    period: {
      p1: [
        {
          type: 'D',
          start: moment(match.date).subtract('2', "weeks").format('YYYY-MM-DD'),
          end: moment(new Date()).format('YYYY-MM-DD'),
        },
      ],
    },
    'max-results': 50,
    'page-num': 1,
    options: {
      ignore_null_properties: true,
      eco_mode: true,
    },
  };

  return restATInternet.post('data/getData', params).then(res => res.data, err => err.response);
}
