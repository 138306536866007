import { Theme } from '@material-ui/core';
import { PLAYER_CELL_SIZE } from '../MatchTeamPlayerCell/styles';

export const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    flexWrap: 'wrap' as 'wrap',
    width: PLAYER_CELL_SIZE.WIDTH * 2 + 10,
    padding: 2,
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[200],
    lineHeight: '31px',
  },
});
