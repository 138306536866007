import qs from 'query-string';
import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

const groups = ['read_user_game'];

export function fetchLineupSurveys(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_LINEUP_SURVEY_LIST' });

    return rest
      .get('lineup_surveys', { params })
      .then(
        res => dispatch({ type: 'FETCH_LINEUP_SURVEY_LIST_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_LINEUP_SURVEY_LIST_REJECTED', payload: err.response })
      );
  };
}

export function getLineupSurvey(id) {
  const params = {
    groups,
  };
  return function(dispatch) {
    dispatch({ type: 'GET_LINEUP_SURVEY' });

    return rest
      .get(`lineup_surveys/${id}`, { params })
      .then(
        res => dispatch({ type: 'GET_LINEUP_SURVEY_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_LINEUP_SURVEY_REJECTED', payload: err.response })
      );
  };
}

export function updateLineupSurvey(id, lineupSurvey) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_LINEUP_SURVEY', payload: id });

    return rest.put(`lineup_surveys/${id}`, lineupSurvey).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_lineupSurveyUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_LINEUP_SURVEY_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_LINEUP_SURVEY_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addLineupSurvey(lineupSurvey) {
  return function(dispatch) {
    dispatch({ type: 'ADD_LINEUP_SURVEY' });

    return rest.post(`lineup_surveys`, lineupSurvey).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_lineupSurveyAdded', type: 'success' },
        });
        return dispatch({
          type: 'ADD_LINEUP_SURVEY_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_lineupSurveyAdd_error', type: 'error' },
        });
        return dispatch({
          type: `ADD_LINEUP_SURVEY_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}
