import { Paper, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Text from '../../../common/components/inputs/Text';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce, { getLinkId } from '../../../common/services/tools';
import CompetitionInput from '../../competitions/Input';
import SelectConstant from '../../components/constants/Select';
import MatchInput from '../../matches/Input';
import TeamInput from '../../teams/Input';
import { ILineupSurvey, TYPE_SQUAD } from '../typings';
import { LineupSurveyDialog } from './LineupSurveyDialog';
import { IComponentProps } from './LineupSurveyList.connector';
import { LineupSurveyRow } from './LineupSurveyRow.component';

export class LineupSurveyList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'type',
        input: SelectConstant,
        entity: 'lineupSurvey',
        labelKey: 'field_type',
        defaultValue: TYPE_SQUAD,
      },
      {
        type: 'title',
        input: Text,
        labelKey: 'field_title',
      },
      {
        type: 'id',
        input: Text,
        labelKey: 'field_id',
      },
      {
        type: 'lineupSurveyCompetition',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
      {
        type: 'lineupSurveyTeam',
        input: TeamInput,
        labelKey: 'filter_team',
      },
      {
        type: 'lineupSurveyMatch',
        input: MatchInput,
        labelKey: 'filter_match',
      },
    ],
    headers: [
      { labelKey: 'field_title' },
      { labelKey: 'field_type' },
      { labelKey: 'field_isAvailable' },
      { labelKey: 'field_isEnabled' },
      { id: 'startDate', labelKey: 'field_startDate' },
      { id: 'endDate', labelKey: 'field_endDate' },
      { labelKey: 'field_team' },
      { labelKey: 'field_competition' },
      { id: 'totalParticipantsCount', labelKey: 'field_totalParticipantsCount' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/lineup-surveys${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      intl: { messages: m },
      fetching,
      pagination,
      total,
      query,
      lineupSurveys,
    } = this.props;

    return (
      <Layout
        id="lineup-survey-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_lineup_surveys' }]} />
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_lineup_surveys}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <LineupSurveyDialog title={m.dialog_addLineupSurvey} form="lineup_survey_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </LineupSurveyDialog>
              </UserRestriction>
            </Toolbar>
            <SearchFilter
              form="lineup_surveys_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={lineupSurveys}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={LineupSurveyRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  private handleFilters = (change) => {
    const { lineupSurveyTeam, lineupSurveyCompetition, lineupSurveyMatch, type, title, id } = change;
    const filtersQuery = {
      lineupSurveyTeam: getLinkId(lineupSurveyTeam),
      lineupSurveyCompetition: getLinkId(lineupSurveyCompetition),
      lineupSurveyMatch: getLinkId(lineupSurveyMatch),
      type,
      lineupSurveyId: id,
      title,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private submitAdd = (lineupSurvey: Partial<ILineupSurvey>) => {
    const { addLineupSurvey, history } = this.props;

    // @ts-ignore
    addLineupSurvey(lineupSurvey).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/lineup-surveys/${payload.id}`);
      }
    });
  };

  private fetchData = (filter: string) => {
    const { fetchLineupSurveys, filterList } = this.props;
    fetchLineupSurveys(filter);
    filterList('lineup-surveys', filter);
  };
}
