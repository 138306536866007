import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';
import TextEdit from 'Components/inputs/TextEdit';
import Typography from '@material-ui/core/Typography';
import Variables from './Variables';

const styles = () => ({
  title: {
    marginTop: 24,
    padding: '0 14px',
  },
  wrapperVariables: {
    padding: 14,
  },
});

@withStyles(styles)
export default class PageseoForm extends Component {
  config = {
    fields: ['id', 'url', 'seoMetaTitle', 'seoText', 'seoTitle', 'seoMetaDescription'],
    disposition: [
      {
        fields: [
          { name: 'url', component: TextInput, size: 6 },
          {
            name: 'seoMetaTitle',
            component: TextInput,
            size: 6,
            helperKey: 'field_pageSeoVariablesHelper',
          },
          { name: 'seoMetaDescription', component: TextInput, multiline: true },
          {
            name: 'seoTitle',
            component: TextInput,
            size: 6,
          },
          { name: 'seoText', component: TextEdit },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit, constantsSeo, classes } = this.props;

    return (
      <React.Fragment>
        <FormEdit
          key={data.id}
          form={`pageseo_${data.id}`}
          onSubmit={onSubmit}
          data={data}
          config={this.config}
          main
        />
        <Typography className={classes.title} variant="h6">
          Variables available
        </Typography>
        <div className={classes.wrapperVariables}>
          <Variables data={constantsSeo} />
        </div>
      </React.Fragment>
    );
  }
}
