export default function reducer(
  state = {
    results: [],
    fetching: false,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_SEARCH_FD': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_SEARCH_FD_FULFILLED': {
      return {
        ...state,
        results: action.payload,
        fetching: true,
      };
    }
    case 'FETCH_SEARCH_FD_REJECTED': {
      return {
        ...state,
        fetching: false,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }

    default:
      return state;
  }
}
