import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function SubstituteOutIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path transform="matrix(1 0 0 -1 0 512)" fill="red" d="M165 265l182 0-89-183z" />
      </g>
    </SvgIcon>
  );
}
