import rest from 'Services/restInternal';

export default function fetchMeOnce() {
  return function(dispatch, getState) {
    const storedMe = getState().me;
    if (storedMe.fetched) {
      return dispatch({ type: 'FETCH_ME_ALREADY_FULFILLED' });
    }

    dispatch({ type: 'FETCH_ME' });
    return rest
      .get('me')
      .then(
        res => dispatch({ type: 'FETCH_ME_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_ME_REJECTED', payload: err.response })
      );
  };
}
