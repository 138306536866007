import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import { getLineupSurvey, updateLineupSurvey } from '../action';

type IOwnProps = RouteComponentProps<{ topicId: string }>;

const mapDispatchToProps = {
  getLineupSurvey,
  updateLineupSurvey
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const id = ownProps.match.params.topicId;

  return {
    id,
    lineupSurvey: state.lineupSurvey.detail[id],
    fetching: state.lineupSurvey.fetching,
    me: state.me,
  }
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
