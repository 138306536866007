import { IRootState } from '../../../typings';
import { ISide } from '../../matches/typings';
import { IMatchTeamPlayer } from '../../matchTeamPlayers/typings';
import { ACTIVE_PERIODS } from './components/PeriodSelector/utils';

export const getLiveTeam = (state: IRootState, teamSide: ISide) => {
  return teamSide === 'home' ? state.live.homeTeam : state.live.awayTeam;
};
export const getLivePlayerAndSide = (
  state: IRootState,
  matchTeamPlayerId?: string
): [IMatchTeamPlayer | null, ISide | null] => {
  const { homeMatchTeamPlayers, awayMatchTeamPlayers } = state.live;
  if (!matchTeamPlayerId) return [null, null];
  let mtp = homeMatchTeamPlayers[matchTeamPlayerId];
  if (mtp) {
    return [mtp, 'home'];
  }
  mtp = awayMatchTeamPlayers[matchTeamPlayerId];
  if (mtp) {
    return [mtp, 'away'];
  }
  return [null, null];
};
export const getLiveMembership = (state: IRootState, matchTeamPlayerId?: string) => {
  const mtp = getLivePlayerAndSide(state, matchTeamPlayerId)[0];
  return mtp ? mtp.membership : null;
};

export const getPlayerInfo = (state: IRootState, playerId: string) => {
  const mtp = getLivePlayerAndSide(state, playerId)[0];
  return mtp ? mtp.membership : null;
};

export const getLiveMatchTeamPlayers = (state: IRootState) => {
  const { homeMatchTeamPlayers, awayMatchTeamPlayers } = state.live;
  return { home: homeMatchTeamPlayers, away: awayMatchTeamPlayers };
};

export const selectIsActiveMatchPeriod = (state: IRootState) => {
  const { period } = state.live;
  return ACTIVE_PERIODS.includes(period);
};

export const isActionEnable = (state: IRootState) => {
  return !(
    state.commentary.posting ||
    state.commentary.updating ||
    state.match.updating ||
    state.goal.posting ||
    state.goal.updating ||
    state.card.posting ||
    state.card.updating ||
    state.substitute.posting ||
    state.substitute.updating ||
    state.missedPenalty.posting ||
    state.missedPenalty.updating ||
    state.penaltyShot.posting ||
    state.penaltyShot.updating
  );
};

export const selectPlayersSuggestions = (state: IRootState) =>
  state.live.playersSuggestions;

export const selectTeamsSuggestions = (state: IRootState) => state.live.teamsSuggestions;
