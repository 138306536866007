import { getProject } from 'Services/project';

let debounceTimer = null;
export default function debounce(fn, delay, forceClear) {
  if (forceClear) {
    clearTimeout(debounceTimer);
  }

  return function(...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const getLinkId = link => link && link.split('/').pop();

const typeMap = {
  Club: 'clubs',
  Competition: 'competitions',
  Match: 'matches',
  Person: 'people',
  Team: 'teams',
  Tournament: 'tournaments',
  partnerOffer: 'partner_offers',
};

export const buildLink = (type, id) =>
  `/${getProject().apiPrefixFD}${typeMap[type]}/${id}`;

