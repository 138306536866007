import rest from 'Services/restFootMercato';

export function fetchGlobalConfiguration() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CONFIGURATION' });
    return rest.get('configurations').then(
      res =>
        dispatch({
          type: 'FETCH_CONFIGURATION_GLOBAL_FULFILLED',
          payload: res.data.collection[0],
        }),
      err =>
        dispatch({
          type: 'FETCH_CONFIGURATION_REJECTED',
          payload: err.response,
        })
    );
  };
}
export function fetchMobileConfiguration() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CONFIGURATION' });
    return rest.get('configuration_mobiles').then(
      res =>
        dispatch({
          type: 'FETCH_CONFIGURATION_MOBILE_FULFILLED',
          payload: res.data.collection[0],
        }),
      err =>
        dispatch({
          type: 'FETCH_CONFIGURATION_REJECTED',
          payload: err.response,
        })
    );
  };
}
export function updateGlobalConfiguration(id, configuration) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_CONFIGURATION' });
    return rest.put(`configurations/${id}`, configuration).then(
      res =>
        dispatch({
          type: 'UPDATE_CONFIGURATION_GLOBAL_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'UPDATE_CONFIGURATION_REJECTED',
          payload: err.response,
        })
    );
  };
}
export function updateMobileConfiguration(id, configuration) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_CONFIGURATION' });
    return rest.put(`configuration_mobiles/${id}`, configuration).then(
      res =>
        dispatch({
          type: 'UPDATE_CONFIGURATION_MOBILE_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'UPDATE_CONFIGURATION_REJECTED',
          payload: err.response,
        })
    );
  };
}
