import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Row from '../../broadcasts/List/Row';
import BoadcastDialog from '../../broadcasts/Dialog';
import ContentAdd from '@material-ui/icons/Add';
import { UserRestriction } from '../../../common/components/UserRestiction';

const styles = {
  icon: {
    marginRight: 8,
  },
};

@injectIntl
@withStyles(styles)
export default class Broadcasts extends Component {
  handleSubmitAdd = values => {
    this.props.onAdd(values);
  };

  render() {
    const {
      broadcasts,
      classes,
      intl: { messages: m },
    } = this.props;

    return (
      <div className={classes.block}>
        <Toolbar>
          <Typography variant="h6">{m.app_broadcastList}</Typography>
          <div style={{ flex: 1 }} />
          <UserRestriction>
            <BoadcastDialog
              title={m.dialog_addBroadcast}
              form="broadcast_add"
              onCreate={this.handleSubmitAdd}
            >
              <Button variant="contained" color="primary">
                <ContentAdd className={classes.icon} />
                {m.button_add}
              </Button>
            </BoadcastDialog>
          </UserRestriction>
        </Toolbar>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{m.field_date}</TableCell>
                <TableCell padding="none">{m.field_live}</TableCell>
                <TableCell>{m.match_homeTeam}</TableCell>
                <TableCell>{m.match_awayTeam}</TableCell>
                <TableCell>{m.field_channel}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {broadcasts && broadcasts.map(b => <Row tableData={b} key={b.id} />)}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
