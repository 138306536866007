import { IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import * as React from 'react';
import { TimerIcon } from '../../svg/Timer';
import { IComponentProps } from './Chrono.connector';
import { formatChrono, getChrono } from './utils';
import {getProject} from "../../../../../common/services/project";

const TICK_INTERVAL = 995;
const SECONDE = 1000;
const SECONDES_IN_MINUTE = 60;
const MINUTE = SECONDE * SECONDES_IN_MINUTE;

interface IState {
  timeout: any;
  isEditing: boolean;
  offset: number;
}

export class Chrono extends React.PureComponent<IComponentProps, IState> {
  public state: IState = {
    timeout: null,
    isEditing: false,
    offset: 0,
  };

  public componentDidMount() {
    this.updateChrono();
  }

  public componentDidUpdate(prevProps: IComponentProps) {
    const { period, chronoStatus } = this.props;
    if (prevProps.period !== period || prevProps.chronoStatus !== chronoStatus) {
      this.updateChrono();
    }
  }

  public componentWillUnmount() {
    this.stop();
  }

  public render() {
    const { isEditing } = this.state;
    const { classes } = this.props;

    if (!isEditing) {
      return (
        <div className={classes.container}>
          <div>{this.renderChrono()}</div>
        </div>
      );
    }

    return (
      <div className={classes.container}>
        <div>{this.renderEditingChrono()}</div>
        <div>{this.renderControls()}</div>
      </div>
    );
  }

  private renderChrono = () => {
    const {
      classes,
      chronoStartTimeStamp,
      period,
      chronoEndTimeStamp,
      chronoStatus,
      mainCommentatorLocale,
    } = this.props;
    const [min, sec, additionnalMin, additionnalSec] = getChrono(
      period,
      chronoStartTimeStamp,
      chronoEndTimeStamp
    );

    const minValue = additionnalMin && min !== null ? additionnalMin + min : min;
    const secValue = additionnalSec ? additionnalSec : sec;
    const time = formatChrono(minValue, secValue);

    return (
      <div className={classes.chronoContainer}>
        <TimerIcon />
        <Typography className={classes.text}>{time}</Typography>
        {chronoStatus && mainCommentatorLocale == getProject().lang ? (
          <IconButton
            onClick={this.onEdit}
            className={classes.iconButton}
            color="inherit"
          >
            <EditIcon className={classes.icon} />
          </IconButton>
        ) : null}
      </div>
    );
  };

  private renderEditingChrono = () => {
    const { classes, chronoStartTimeStamp, period, chronoEndTimeStamp } = this.props;
    const { offset } = this.state;

    const [min, sec, additionnalMin, additionnalSec] = getChrono(
      period,
      chronoStartTimeStamp,
      chronoEndTimeStamp,
      offset
    );

    const minValue = additionnalMin && min !== null ? additionnalMin + min : min;
    const secValue = additionnalSec ? additionnalSec : sec;
    const time = formatChrono(minValue, secValue);
    const classesNames = [classes.text];
    if (offset !== 0) {
      classesNames.push(classes.textEditing);
    }

    return (
      <div className={`${classes.chronoContainer} ${classes.chronoEditingContainer}`}>
        <IconButton onClick={this.onSave} className={classes.iconButton} color="inherit">
          <SaveIcon className={classes.icon} />
        </IconButton>
        <Typography className={classesNames.join(' ')}>{time}</Typography>
        <IconButton onClick={this.onClose} className={classes.iconButton} color="inherit">
          <CloseIcon className={classes.icon} />
        </IconButton>
      </div>
    );
  };

  private renderControls = () => {
    const { classes, chronoStatus } = this.props;
    const { isEditing, offset } = this.state;
    if (!isEditing || !chronoStatus) return null;
    const offsetSecondes = offset / SECONDE;
    const secondes = offsetSecondes % SECONDES_IN_MINUTE;
    const minutes = (offsetSecondes - secondes) / SECONDES_IN_MINUTE;

    const time = formatChrono(minutes, secondes, true);

    return (
      <div className={classes.chronoEditContainer}>
        <IconButton
          onClick={this.onAddTime(-MINUTE)}
          className={classes.iconSmallButton}
          color="inherit"
        >
          <ChevronLeftIcon className={classes.bigIcon} />
        </IconButton>
        <IconButton
          onClick={this.onAddTime(-SECONDE)}
          className={classes.iconSmallButton}
          color="inherit"
        >
          <ChevronLeftIcon className={classes.icon} />
        </IconButton>
        <Typography className={classes.text}>{time}</Typography>
        <IconButton
          onClick={this.onAddTime(SECONDE)}
          className={classes.iconSmallButton}
          color="inherit"
        >
          <ChevronRightIcon className={classes.icon} />
        </IconButton>
        <IconButton
          onClick={this.onAddTime(MINUTE)}
          className={classes.iconSmallButton}
          color="inherit"
        >
          <ChevronRightIcon className={classes.bigIcon} />
        </IconButton>
      </div>
    );
  };

  private updateChrono = () => {
    const { chronoStatus } = this.props;
    const { timeout } = this.state;

    if (chronoStatus) {
      if (timeout) clearTimeout(timeout);
      this.tick();
    } else {
      this.stop();
    }
  };

  private tick = () => {
    this.setState({
      timeout: setTimeout(this.tick, TICK_INTERVAL),
    });
  };

  private stop = () => {
    this.setState(({ timeout }) => ({
      timeout: clearTimeout(timeout),
      isEditing: false,
      offset: 0,
    }));
  };

  private onEdit = () => {
    this.setState({ isEditing: true });
  };

  private onClose = () => {
    this.setState({ isEditing: false });
  };

  private onSave = () => {
    const { updateChrono, chronoStartTimeStamp } = this.props;
    const { offset } = this.state;
    updateChrono(chronoStartTimeStamp - offset);
    this.setState({ isEditing: false, offset: 0 });
  };

  private onAddTime = (secondes: number) => () => {
    this.setState(({ offset }) => ({ offset: offset + secondes }));
  };
}
