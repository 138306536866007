import React from 'react';
import { injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Alert } from "@material-ui/lab";

function Toggle(
    {
      input,
      labelKey,
      intl: { formatMessage: f },
     helpertext,
      disabled,
      checked,
    }
    ) {

  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });
  return (
      <div>
          <FormControlLabel
              control={<Switch checked={checked || !!input.value} onChange={input.onChange} />}
              label={label}
              disabled={disabled}
          />
          <div>
              {helpertext && <Alert severity="warning">{f({ id: helpertext })}</Alert>}
          </div>
      </div>
    
  );
}
export default injectIntl(Toggle);

export const normalizeBoolean = val => !!val;

export const normalizeBooleanToInteger = val => (val ? 1 : 0);
