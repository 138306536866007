import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import TextInput from '../../../../common/components/inputs/Text';
import { IBrand } from '../../typings';

interface IProps {
  data: any;
  onSubmit: (brand: IBrand) => void;
}

export class BrandForm extends Component<IProps> {
  public config = {
    fields: ['id', 'name'],
    disposition: [
      {
        fields: [{ name: 'name', component: TextInput }],
      },
    ],
  };

  public render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`brand_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }
}
