import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import Date from 'Components/inputs/Date';
import InjuryType from "../../components/injuryTypes/Input";

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'startDate',
      'expectedEndDate',
      'endDate'
    ],
    objectFields: ['type'],
    disposition: [
      {
        size:9,
        title: '',
        fields: [
          {
            name: 'startDate',
            component: Date,
            size: 6,
          },
          {
            name:'type',
            component: InjuryType,
            size: 6,
          },
        ],
      },
      {
        size:9,
        title: '',
        fields: [
          {
            name: 'expectedEndDate',
            component: Date,
            size: 6,
          },
          {
            name: 'endDate',
            component: Date,
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`injury_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
      />
    );
  }
}
