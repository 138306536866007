import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getProject } from 'Services/project';
import { sortTeamByName } from '../../teams/utils';
import ClubAvatar from '../Avatar';

@injectIntl
export default class Teams extends Component {
  getLabel = team => {
    let label = team.name;
    if (team.ageGroup && team.ageGroup.code !== 'senior') {
      label += ` ${team.ageGroup.name}`;
    }
    if (team.gender !== 'male') {
      label += ` ${team.genderTranslated}`;
    }

    return label;
  };

  render() {
    const {
      teams = [],
      intl: { messages: m },
    } = this.props;

    return (
      <List dense subheader={<ListSubheader>{m.app_teams}</ListSubheader>}>
        {teams.sort(sortTeamByName).map(team => (
          <ListItem button component="a" href={`#${getProject().url}/teams/${team.slug}`}>
            <ListItemIcon>
              <ClubAvatar club={team.club} />
            </ListItemIcon>
            <ListItemText
              primary={this.getLabel(team)}
              secondary={team.mainTournament && team.mainTournament.competitionName}
            />
          </ListItem>
        ))}
      </List>
    );
  }
}
