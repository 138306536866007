import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContentAdd from '@material-ui/icons/Add';
import React from 'react';
import { UserRestriction } from '../../../../common/components/UserRestiction';
import { ProductIdPanel } from '../../../../footdata/products/components/ProductIdPanel';
import { FindProductDialog } from '../../../../footdata/products/components/FindProductDialog';
import ProductDialog from '../../../../footdata/products/ProductDialog';
import { IProduct } from '../../../../footdata/products/typings';
import { IComponentProps } from './ArticleProducts.connector';

export class ArticleProducts extends React.Component<IComponentProps> {
  public state = {
    expanded: null,
    isSeachModalOpen: false,
  };

  public render() {
    const {
      productsIds = [],
      onRemove,
      intl: { messages: m },
    } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_products}</Typography>
          <div style={{ flex: 1 }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              padding: '16px 0',
            }}
          >
            {this.renderCreateAction()}
            {this.renderAddAction()}
          </div>
        </Toolbar>
        <div style={{ padding: 16 }}>
          {productsIds.map(productId => (
            <ProductIdPanel
              key={productId}
              id={productId}
              isExpanded={expanded === productId}
              onExpend={this.handleExpand}
              onDelete={this.handleDelete}
              onRemove={onRemove}
            />
          ))}
        </div>
      </div>
    );
  }
  private renderCreateAction = () => {
    const {
      intl: { messages: m },
    } = this.props;

    return (
      <UserRestriction>
        <ProductDialog title={m.dialog_addProduct} form="product_add" onCreate={this.handleCreate}>
          <Button variant="contained" color="primary">
            <ContentAdd />
            <span style={{ marginLeft: 8 }}>{m.button_create}</span>
          </Button>
        </ProductDialog>
      </UserRestriction>
    );
  };

  private renderAddAction = () => {
    const {
      intl: { messages: m },
    } = this.props;
    const { isSeachModalOpen } = this.state;

    return (
      <div style={{ marginLeft: 8 }}>
        <Button variant="contained" color="primary" onClick={this.openModal}>
          <ContentAdd />
          <span style={{ marginLeft: 8 }}>{m.button_add}</span>
        </Button>
        <FindProductDialog open={isSeachModalOpen} onClose={this.handleModal} />
      </div>
    );
  };

  private openModal = () => {
    this.setState({ isSeachModalOpen: true });
  };

  private handleModal = (response: IProduct | null) => {
    const { onAdd } = this.props;
    this.setState({ isSeachModalOpen: false });
    if (response) {
      onAdd(response.id);
    }
  };

  private handleCreate = values => {
    const { addProduct, onAdd } = this.props;
    // @ts-ignore
    return addProduct(values).then(({ payload }) => {
      onAdd(payload.id);
    });
  };
  private handleDelete = id => {
    const { deleteProduct, onRemove } = this.props;
    // @ts-ignore
    return deleteProduct(id).then(() => {
      onRemove(id);
    });
  };

  private handleExpand = (panel: string, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
}
