import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    position: 'relative',
    width: 300,
    padding: 16,
    minHeight: 240,
  },
  loader: {
    position: 'absolute',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
    width: 100,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

@injectIntl
@withStyles(styles)
export default class PlayerRanking extends Component {
  computeRank = list => {
    let rank = 1;
    let tie = 0;
    let lastTotal = 0;

    return Object.keys(list).map((key, index) => {
      const item = list[key];
      if (index == 0) {
        item.rank = rank;
        lastTotal = item.total;
      } else if (item.total == lastTotal) {
        item.rank = rank;
        tie += 1;
      } else {
        rank += 1 + tie;
        tie = 0;
        item.rank = rank;
        lastTotal = item.total;
      }
      return item;
    });
  };

  renderLine = (item, index) => {
    const { classes } = this.props;
    return (
      <TableRow key={index} className={classes.row}>
        <TableCell padding="none">{item.rank}</TableCell>
        <TableCell>
          {item.membership.clubShortName} - {item.membership.personMatchName}
        </TableCell>
        <TableCell padding="none">{item.total}</TableCell>
      </TableRow>
    );
  };

  render() {
    const {
      type,
      stats,
      classes,
      intl: { messages: m },
    } = this.props;
    if (!stats) return null;
    return (
      <div className={classes.container}>
        <Toolbar>
          <Typography variant="h6">{type.name}</Typography>
        </Toolbar>
        {stats.list && (
          <Table>
            <TableBody>{this.computeRank(stats.list).map(this.renderLine)}</TableBody>
          </Table>
        )}
        {stats.list && stats.list.length == 0 && <span>{m.error_noData}</span>}
        {stats.loading && <CircularProgress size={100} className={classes.loader} />}
      </div>
    );
  }
}
