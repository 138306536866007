import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { WrappedFieldProps } from 'redux-form';
import { isUserLimited } from '../../../../common/users/utils';
import { IRootState } from '../../../../typings';
import fetchConstants from '../../../components/constants/actions';

type IOwnProps = { labelKey: string } & RouteComponentProps & WrappedFieldProps;

const mapDispatchToProps = {
  fetchConstants,
};

const mapStateToProps = (state: IRootState) => {
  const articleStatusContants = state.constantsFM.article.status;
  const statuses: { [key: string]: string } | null = articleStatusContants
    ? articleStatusContants.list
    : null;

  return {
    isRestricted: isUserLimited(state.me),
    statuses,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;
const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
