import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import File from '../../../common/components/inputs/File';
import Text from '../../../common/components/inputs/Text';
import { addImage, fetchImage } from '../actions';

const DEFAULT_IMG_WIDTH = 640;

const styles = () => ({
  input: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
  bouttonContainer: {
    textAlign: 'center',
    padding: 16,
    maxWidth: '800px',
    margin: 'auto',
  },
  fileProperties: {
    fontWeight: '500',
  },
  image: {
    maxWidth: '100%',
  },
});

@withStyles(styles)
@injectIntl
@connect(store => ({
  isPosting: store.image.posting,
}))
export default class Upload extends Component {
  state = {
    format: 'medium',
    isOriginalDisabled: false,
  };

  textHelper = {
    articleMainImage: this.props.intl.messages.media_uploadWidthHeightMainImage,
    articleContentImage: this.props.intl.messages.media_uploadWidthArticleContentImage,
  };

  displayFileInfo = () => {
    const { classes } = this.props;
    const { value, imgDataURL } = this.state;

    if (!value) return null;

    return (
      <div>
        <div>
          <span className={classes.fileProperties}>name</span>: {value.name}
          <br />
        </div>
        <div>
          <span className={classes.fileProperties}>type</span>: {value.type}
          <br />
        </div>
        <div>
          <span className={classes.fileProperties}>size</span>: {value.size}
        </div>
        {imgDataURL ? <img className={classes.image} src={imgDataURL} onLoad={this.onImgLoad} /> : null}
      </div>
    );
  };

  onImgLoad = e => {
    const img = e.target;
    let format = this.state.format;
    let isOriginalDisabled = false;

    const size = { w: img.naturalWidth, h: img.naturalHeight };
    if (size.w >= DEFAULT_IMG_WIDTH) {
      isOriginalDisabled = true;
      if (format === 'original') {
        format = 'medium';
      }
    }

    this.setState({
      size,
      isOriginalDisabled,
      format,
    });
  };

  onConfirm = () => {
    const { dispatch } = this.props;
    const { value, size, format, description } = this.state;
    dispatch(addImage({ file: value, filename: value.name, description })).then(postResponse => {
      if (postResponse.payload) {
        // we need urls info, not available in post response
        dispatch(fetchImage(postResponse.payload.id)).then(getResponse => {
          const image = getResponse.payload;
          this.props.close({
            image,
            size,
            format,
          });
        });
      }
    });
  };

  handleChangeFile = event => {
    this.setState({ value: event });

    const reader = new FileReader();
    reader.onload = e => {
      this.setState({ imgDataURL: e.target.result });
    };
    reader.readAsDataURL(event);
  };

  handleChangeDescription = event => {
    this.setState({ description: event });
  };

  onCancel = () => {
    this.props.close();
  };

  handleFormat = (event, format) => {
    if (!format) {
      return null;
    }
    this.setState({ format });
  };

  renderFormatSelection = () => {
    const { format, isOriginalDisabled } = this.state;
    const { chooseFormat } = this.props;

    if (!chooseFormat) {
      return null;
    }

    return (
      <div
        style={{
          padding: '12px 24px 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogContentText>{'Format'}</DialogContentText>
        <ToggleButtonGroup
          value={format}
          exclusive
          onChange={this.handleFormat}
          aria-label="image formats"
          size="small"
        >
          <ToggleButton value="original" aria-label="original format" disabled={isOriginalDisabled}>
            Original
          </ToggleButton>
          <ToggleButton value="medium" aria-label="medium format">
            Medium
          </ToggleButton>
          <ToggleButton value="large" aria-label="large format">
            Large
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  render() {
    const {
      classes,
      isPosting,
      imageType,
      intl: { messages: m },
    } = this.props;

    const { value, description } = this.state;

    return (
      <React.Fragment>
        <DialogTitle key="dialog-title">{m.media_uploadTitle}</DialogTitle>
        <DialogContent key="dialog-content">
          <DialogContentText>{m.media_uploadDescription}</DialogContentText>

          {this.textHelper && this.textHelper[imageType] && (
            <DialogContentText>{this.textHelper[imageType]}</DialogContentText>
          )}

          <div className={classes.bouttonContainer}>
            <Field id="file_selector" name="file" component={File} required onChange={this.handleChangeFile} />
            <Field
              name="description"
              component={Text}
              label={m.field_description}
              required
              onChange={this.handleChangeDescription}
            />
            <DialogContentText>{this.displayFileInfo()}</DialogContentText>
          </div>
        </DialogContent>
        {this.renderFormatSelection()}
        <DialogActions key="dialog-actions">
          <Button onClick={this.onCancel} color="primary">
            {m.button_cancel}
          </Button>
          <Button onClick={this.onConfirm} disabled={isPosting || !value || !description} color="primary">
            {m.button_choose}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}
