import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AgeCell from 'Components/TableList/cell/Age';
import NationalityCell from 'Components/TableList/cell/Nationality';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getProject } from 'Services/project';
import PersonAvatar from '../../people/Avatar';
import { getPersonUsualName } from '../../people/utils';

const styles = {
  row: {
    cursor: 'pointer',
  },
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
@injectIntl
export default class Bloc extends Component {
  handleClick = person => {
    const { history } = this.props;
    const url = person.role === 'coach' ? `/coaches/${person.slug}` : `/${person.role}s/${person.slug}`;
    history.push(`${getProject().url}${url}`);
  };

  render() {
    const {
      teamSeasonMembers,
      classes,
      intl: { messages: m },
    } = this.props;

    if (!teamSeasonMembers) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">
            Effectif {teamSeasonMembers && teamSeasonMembers.length}
          </Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size="small">role</TableCell>
              <TableCell size="small" align="right">
                #
              </TableCell>
              <TableCell size="small">nom</TableCell>
              <TableCell size="small" align="right">
                age
              </TableCell>
              <TableCell size="small">nationalité</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamSeasonMembers &&
              teamSeasonMembers.map(({ membership: mm }) => (
                <TableRow
                  hover
                  className={classes.row}
                  onClick={() => this.handleClick(mm.person)}
                >
                  <TableCell size="small">{mm.role}</TableCell>
                  <TableCell size="small" align="right">
                    {mm.shirtNumber || '-'}
                  </TableCell>
                  <TableCell>
                    <div className={classes.rowContent}>
                      <PersonAvatar person={mm.person} />
                      <div className={classes.label}>{getPersonUsualName(mm.person)}</div>
                    </div>
                  </TableCell>
                  <TableCell size="small" align="right">
                    <AgeCell birthDate={mm.person.birthDate} />
                  </TableCell>
                  <TableCell size="small">
                    <NationalityCell
                      main={mm.person.mainNationality}
                      second={mm.person.secondNationality}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
