import { ListSubheader } from '@material-ui/core';
import List from '@material-ui/core/List';
import * as React from 'react';
import { EPosition, POSITIONS } from '../../../../compositions/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { IMembership } from '../../../../memberships/typings';
import { CoachItem } from '../CoachItem';
import { MatchTeamPlayerItem } from '../MatchTeamPlayerItem';
import { IComponentProps } from './MatchTeamPlayerList.connector';

interface IState {
  showFullMembership: boolean;
}

export class MatchTeamPlayerList extends React.Component<IComponentProps, IState> {
  public render() {
    const { classes } = this.props;
    return (
      <List dense disablePadding className={classes.list}>
        {POSITIONS.map(this.renderPositionSection)}
        {this.renderMatchCoaches()}
      </List>
    );
  }

  private renderPositionSection = (position: EPosition) => {
    const {
      classes,
      matchTeamPlayersGroupedByPosition,
      intl: { messages: m },
    } = this.props;
    const positionMatchTeamPlayers = matchTeamPlayersGroupedByPosition[position];
    if (!positionMatchTeamPlayers || positionMatchTeamPlayers.length === 0) {
      return null;
    }

    return (
      <li key={`section-${position}`} className={classes.listSection}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.listSubheader}>
            {m['live_compo_position_' + position]}
          </ListSubheader>
          {positionMatchTeamPlayers.map(this.renderMatchTeamPlayer)}
        </ul>
      </li>
    );
  };

  private renderMatchTeamPlayer = (matchTeamPlayer: IMatchTeamPlayer) => {
    const { side, selectedMatchTeamPlayer, usedShirtNumbers } = this.props;
    return (
      <MatchTeamPlayerItem
        key={`matchteamplayer-${matchTeamPlayer.membership.id}`}
        side={side}
        matchTeamPlayer={matchTeamPlayer}
        selectedMatchTeamPlayer={selectedMatchTeamPlayer}
        usedShirtNumbers={usedShirtNumbers}
      />
    );
  };

  private renderMatchCoaches = () => {
    const {
      coaches,
      classes,
      intl: { messages: m },
    } = this.props;

    if (!coaches.length) return null;

    return (
      <li key={`section-coaches`} className={classes.listSection}>
        <ul className={classes.ul}>
          <ListSubheader className={classes.listSubheader}>
            {m.live_compo_coach}
          </ListSubheader>
          {coaches.map(this.renderMatchCoach)}
        </ul>
      </li>
    );
  };

  private renderMatchCoach = (teamCoach: IMembership) => {
    return <CoachItem coach={teamCoach} side={this.props.side} />;
  };
}
