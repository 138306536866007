import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConfigurationsDetail } from './Detail';

export default function ConfigurationsRoutes({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/`} component={ConfigurationsDetail} />
    </Switch>
  );
}
