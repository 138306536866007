import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from 'Services/project';

export default class Row extends Component {
  render() {
    const { tableData } = this.props;
    const { url, seoMetaTitle } = tableData;
    const href = `#${getProject().url}/pageseos/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>{url}</TableCell>
        <TableCell>{seoMetaTitle}</TableCell>
      </TableRow>
    );
  }
}
