import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Classement from '../../classements/Bloc';
import { TournamentPhases } from '../../phases/components/TournamentPhases';
import { updateTournament } from '../actions';
import { TournamentRankingRules } from '../rankingRules';
import Form from './Form';

@connect()
export default class Championship extends Component {
  menu = [
    { label: 'app_tournamentInfo', link: '/' },
    { label: 'app_classement', link: '/classement' },
    { label: 'app_phases', link: '/phases' },
    { label: 'app_rankingRules', link: '/ranking-rules' },
  ];

  handleChange = id => tournament => {
    const { dispatch } = this.props;
    return dispatch(updateTournament(id, tournament));
  };

  render() {
    const { history, match, tournament, phase, classement, rankingRulesIds } = this.props;

    return (
      <div>
        <TabsRoute match={match} menu={this.menu} history={history} />
        <Route
          exact
          path={`${match.url}/`}
          render={() => <Form onSubmit={this.handleChange(tournament.id)} data={tournament} />}
        />
        <Route path={`${match.url}/classement`} render={() => <Classement classement={classement} />} />
        <Route path={`${match.url}/phases`} render={() => <TournamentPhases phase={phase} />} />
        <Route
          path={`${match.url}/ranking-rules`}
          render={() => <TournamentRankingRules rankingRulesIds={rankingRulesIds} />}
        />
      </div>
    );
  }
}
