import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { setParamsLive, syncChronoToCommentaryLive } from '../../actions';
import { styles } from './styles';

const mapDispatchToProps = {
  setParamsLive,
  syncChronoToCommentaryLive,
};

interface IOwnProps {
  action: any;
}

const mapStateToProps = (state: IRootState) => ({
  timeMin: state.live.commentary.timeMin,
  additionnalTimeMin: state.live.commentary.additionnalTimeMin,
  isImportant: state.live.commentary.isImportant,
  hasVideo: state.live.commentary.hasVideo,
  period: state.live.commentary.period,
  chronoPeriod: state.live.period,
  chronoStartTimeStamp: state.live.chronoStartTimeStamp,
  chronoEndTimeStamp: state.live.chronoEndTimeStamp,
  chronoStatus: state.live.chronoStatus,
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  WithStyles &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
