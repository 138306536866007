import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { injectIntl } from 'react-intl';
import { thousandUnitValue } from '../utils';

const styles = () => ({
  item: {
    width: '100%',
  },
});

@withStyles(styles)
@injectIntl
@connect()
export default class Stats extends Component {
  displayStatViews = value =>
    value
      ? `${thousandUnitValue(value)} K${this.props.intl.messages.stat_unit_views}`
      : this.props.intl.messages.message_dataNotProvided;

  render() {
    const {
      data: { commentsCount, analyticsWebCount, analyticsMobileCount },
      intl: { messages: m },
    } = this.props;

    if (!commentsCount && !analyticsWebCount && !analyticsMobileCount) {
      return (
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          style={{ padding: 16, marginTop: 16 }}
        >
          <Grid item xs={4}>
            <Typography>{m.message_dataNotProvided}</Typography>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        style={{ padding: 16, marginTop: 16 }}
      >
        {commentsCount && (
          <Grid item xs={4} className={this.props.classes.item}>
            <Typography>
              {m.stat_unit_commentaries}: <b>{commentsCount}</b>
            </Typography>
          </Grid>
        )}

        {analyticsWebCount && (
          <Grid item xs={4} className={this.props.classes.item}>
            <Typography>
              Analytics web: <b>{this.displayStatViews(analyticsWebCount)}</b>
            </Typography>
          </Grid>
        )}

        {analyticsMobileCount && (
          <Grid item xs={4} className={this.props.classes.item}>
            <Typography>
              Analytics mobile: <b>{this.displayStatViews(analyticsMobileCount)}</b>
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}
