import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import TextInput from 'Components/inputs/Text';
import TextEdit from 'Components/inputs/TextEdit';

export class MobileConfigurationForm extends Component {
  config = {
    fields: [
      'id',
      'isDfpEnabled',
      'isOutbrainEnabled',
      'helpPageContent',
      'mentionsPageContent',
      'minimumRequiredVersion',
    ],
    disposition: [
      {
        fields: [
          {
            name: 'isDfpEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isOutbrainEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'helpPageContent',
            component: TextEdit,
          },
          {
            name: 'mentionsPageContent',
            component: TextEdit,
          },
          {
            name: 'minimumRequiredVersion',
            component: TextInput,
            hintText: '4.1.50',
          }
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`configuration_mobile_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
      />
    );
  }
}
