import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import Date from 'Components/inputs/Date';

export default class Form extends Component {
  config = {
    fields: ['id', 'startDate', 'endDate', 'shirtNumber', 'isActive'],
    disposition: [
      {
        fields: [
          { name: 'startDate', component: Date, size: 6, disabled: true },
          { name: 'endDate', component: Date, size: 6, disabled: true },
          {
            name: 'shirtNumber',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          {
            name: 'isActive',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
            disabled: true,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`membership_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
