import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from 'Services/project';

export default class Row extends Component {
  render() {
    const { tableData } = this.props;
    const { title, shortDescription } = tableData;
    const href = `#${getProject().url}/tags/${tableData.slug}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>{title}</TableCell>
        <TableCell>{shortDescription}</TableCell>
      </TableRow>
    );
  }
}
