import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getInnerCollection } from '../../../../../common/services/connectors';
import { IRootState } from '../../../../../typings';
import { selectMatchCommentariesWithMatchEvent } from '../../../../matches/selector';
import { getMatchMatchEvents } from '../../../../matchEvents/actions';
import { handleMatchEventDelete } from '../../../Commentary/actions/matchEventActions';
import { styles } from './styles';
import { pairMatchEvents } from './utils';

interface IOwnProps {}

const mapDispatchToProps = {
  getMatchMatchEvents,
  handleMatchEventDelete,
};

const mapStateToProps = (state: IRootState) => {
  const matchEvents = getInnerCollection('matchEventsIds', state.matchEvent, state.match, state.live.matchId);
  const commentaries = selectMatchCommentariesWithMatchEvent(state, state.live.matchId);
  const joinMatchEvents = pairMatchEvents(matchEvents, commentaries);
  return {
    matchId: state.live.matchId,
    joinMatchEvents,
    matchEvents,
    commentaries,
    mainCommentatorLocale: state.live.mainCommentatorLocale,
  };
};
export type IComponentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedIntlProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl,
  withStyles(styles)
);
