import { notifySuccessAction } from '../../common/actions/notifications';
import rest from '../../common/services/restFootData';
import {
  IMatchEventCard,
  IMatchEventGoal,
  IMatchEventMissedPenalty,
  IMatchEventPenaltyShot,
  IMatchEventSubstitute,
} from './typings';

export const getMatchMatchEvents = (id: string) => {
  const params = {
    match: id,
  };
  return dispatch => {
    dispatch({ type: 'FETCH_MATCH_MATCHEVENTS' });

    return rest.get('match_events', { params }).then(
      res => {
        return dispatch({ type: 'FETCH_MATCH_MATCHEVENTS_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'FETCH_MATCH_MATCHEVENTS_REJECTED', payload: err.response })
    );
  };
};

export const addCard = (card: IMatchEventCard) => {
  return dispatch => {
    dispatch({ type: 'ADD_CARD' });

    return rest.post('cards', card).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventCreated'));
        return dispatch({ type: 'ADD_CARD_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'ADD_CARD_REJECTED', payload: err.response })
    );
  };
};

export const updateCard = (id: string, card: IMatchEventCard) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_CARD' });

    return rest.put(`cards/${id}`, card).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventUpdated'));
        return dispatch({ type: 'UPDATE_CARD_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'UPDATE_CARD_REJECTED', payload: err.response })
    );
  };
};

export const deleteCard = (id: string) => {
  return dispatch => {
    dispatch({ type: 'DELETE_CARD', payload: id });

    return rest.delete(`cards/${id}`).then(
      () => {
        dispatch(notifySuccessAction('message_matchEventDeleted'));
        return dispatch({ type: 'DELETE_CARD_FULFILLED', payload: { id } });
      },
      err => dispatch({ type: 'DELETE_CARD_REJECTED', payload: err.response })
    );
  };
};

export const addGoal = (goal: IMatchEventGoal) => {
  return dispatch => {
    dispatch({ type: 'ADD_GOAL' });

    return rest.post('goals', goal).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventCreated'));
        return dispatch({ type: 'ADD_GOAL_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'ADD_GOAL_REJECTED', payload: err.response })
    );
  };
};

export const updateGoal = (id: string, goal: IMatchEventGoal) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_GOAL' });

    return rest.put(`goals/${id}`, goal).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventUpdated'));
        return dispatch({ type: 'UPDATE_GOAL_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'UPDATE_GOAL_REJECTED', payload: err.response })
    );
  };
};

export const deleteGoal = (id: string) => {
  return dispatch => {
    dispatch({ type: 'DELETE_GOAL', payload: id });

    return rest.delete(`goals/${id}`).then(
      () => {
        dispatch(notifySuccessAction('message_matchEventDeleted'));
        return dispatch({ type: 'DELETE_GOAL_FULFILLED', payload: { id } });
      },
      err => dispatch({ type: 'DELETE_GOAL_REJECTED', payload: err.response })
    );
  };
};

export const addMissedPenalty = (missedPenalty: IMatchEventMissedPenalty) => {
  return dispatch => {
    dispatch({ type: 'ADD_MISSEDPENALTY' });

    return rest.post('missed_penalties', missedPenalty).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventCreated'));
        return dispatch({
          type: 'ADD_MISSEDPENALTY_FULFILLED',
          payload: res.data,
        });
      },
      err => dispatch({ type: 'ADD_MISSEDPENALTY_REJECTED', payload: err.response })
    );
  };
};

export const updateMissedPenalty = (id: string, goal: IMatchEventMissedPenalty) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_MISSEDPENALTY' });

    return rest.put(`missed_penalties/${id}`, goal).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventUpdated'));
        return dispatch({
          type: 'UPDATE_MISSEDPENALTY_FULFILLED',
          payload: res.data,
        });
      },
      err => dispatch({ type: 'UPDATE_MISSEDPENALTY_REJECTED', payload: err.response })
    );
  };
};

export const deleteMissedPenalty = (id: string) => {
  return dispatch => {
    dispatch({ type: 'DELETE_MISSEDPENALTY', payload: id });

    return rest.delete(`missed_penalties/${id}`).then(
      () => {
        dispatch(notifySuccessAction('message_matchEventDeleted'));
        return dispatch({
          type: 'DELETE_MISSEDPENALTY_FULFILLED',
          payload: { id },
        });
      },
      err => dispatch({ type: 'DELETE_MISSEDPENALTY_REJECTED', payload: err.response })
    );
  };
};

export const addSubstitute = (substitute: IMatchEventSubstitute) => {
  return dispatch => {
    dispatch({ type: 'ADD_SUBSTITUTE' });

    return rest.post('substitutes', substitute).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventCreated'));
        return dispatch({ type: 'ADD_SUBSTITUTE_FULFILLED', payload: res.data });
      },
      err =>
        dispatch({
          type: 'ADD_SUBSTITUTE_REJECTED',
          payload: err.response,
        })
    );
  };
};

export const updateSubstitute = (id: string, substitute: IMatchEventSubstitute) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_SUBSTITUTE' });

    return rest.put(`substitutes/${id}`, substitute).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventUpdated'));
        return dispatch({ type: 'UPDATE_SUBSTITUTE_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'UPDATE_SUBSTITUTE_REJECTED', payload: err.response })
    );
  };
};

export const deleteSubstitute = (id: string) => {
  return dispatch => {
    dispatch({ type: 'DELETE_SUBSTITUTE', payload: id });

    return rest.delete(`substitutes/${id}`).then(
      () => {
        dispatch(notifySuccessAction('message_matchEventDeleted'));
        return dispatch({ type: 'DELETE_SUBSTITUTE_FULFILLED', payload: { id } });
      },
      err =>
        dispatch({
          type: 'DELETE_SUBSTITUTE_REJECTED',
          payload: err.response,
        })
    );
  };
};

export const addPenaltyShot = (penaltyShot: IMatchEventPenaltyShot) => {
  return dispatch => {
    dispatch({ type: 'ADD_PENALTYSHOT' });

    return rest.post('penalty_shots', penaltyShot).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventCreated'));
        return dispatch({ type: 'ADD_PENALTYSHOT_FULFILLED', payload: res.data });
      },
      err =>
        dispatch({
          type: 'ADD_PENALTYSHOT_REJECTED',
          payload: err.response,
        })
    );
  };
};

export const updatePenaltyShot = (id: string, penaltyShot: IMatchEventPenaltyShot) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_PENALTYSHOT' });

    return rest.put(`penalty_shots/${id}`, penaltyShot).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventUpdated'));
        return dispatch({
          type: 'UPDATE_PENALTYSHOT_FULFILLED',
          payload: res.data,
        });
      },
      err => dispatch({ type: 'UPDATE_PENALTYSHOT_REJECTED', payload: err.response })
    );
  };
};

export const deletePenaltyShot = (id: string) => {
  return dispatch => {
    dispatch({ type: 'DELETE_PENALTYSHOT', payload: id });

    return rest.delete(`penalty_shots/${id}`).then(
      () => {
        dispatch(notifySuccessAction('message_matchEventDeleted'));
        return dispatch({ type: 'DELETE_PENALTYSHOT_FULFILLED', payload: { id } });
      },
      err =>
        dispatch({
          type: 'DELETE_PENALTYSHOT_REJECTED',
          payload: err.response,
        })
    );
  };
};
