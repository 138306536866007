import { Button } from '@material-ui/core';
import * as React from 'react';
import { AdvancedBloc } from '../AdvancedBloc';
import { IComponentProps } from './SuspendMatch.connector';
import {getProject} from "../../../../../common/services/project";

export class SuspendMatch extends React.Component<IComponentProps> {
  public state = {
    isRequesting: false,
  };

  public render() {
    const {
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;
    const { isRequesting } = this.state;
    return (
      <AdvancedBloc
        title={m.live_advanced_suspendTitle}
        description={m.live_advanced_suspendDescription}
      >
        <Button
            color="primary"
            onClick={this.handleClick}
            disabled={isRequesting || mainCommentatorLocale != getProject().lang}
        >
          {m.button_suspendMatch}
        </Button>
      </AdvancedBloc>
    );
  }

  private handleClick = () => {
    const { suspendMatch } = this.props;
    this.setState({ isRequesting: true });
    // @ts-ignore dispatch type error
    suspendMatch().then(() => this.setState({ isRequesting: false }));
  };
}
