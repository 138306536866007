import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { suspendLiveMatch } from '../../actions';
import {IRootState} from "../../../../../typings";

interface IOwnProps {}

const mapDispatchToProps = {
  suspendMatch: suspendLiveMatch,
};

const mapStateToProps = (state: IRootState) => ({
  mainCommentatorLocale: state.live.mainCommentatorLocale,
})

export type IComponentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
