import { Divider, WithStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import { MatchesContainer } from '../../components/MatchesContainer';
import { PhaseToolbar } from '../../components/PhaseToolbar';
import { IPhase } from '../../typings';

type IProps = {
  phase?: IPhase;
} & WithStyles;

export class TournamentPhases extends Component<IProps> {
  public render() {
    const { phase, classes } = this.props;

    if (!phase || !phase.children) {
      return null;
    }

    return (
      <div className={classes.container}>{phase.children.map(this.renderStagePhase)}</div>
    );
  }

  private renderStagePhase = (phase: IPhase, index: number) => {
    if (!phase) {
      return <div>no data</div>;
    }

    return (
      <div key={index}>
        <PhaseToolbar phase={phase} level={0} />
        {phase.children && phase.children.length ? (
          <Grid container spacing={8} style={{ padding: 8 }}>
            {phase.children.map(p => (
              <Grid key={p.id} item lg={6} md={12}>
                {this.renderChildPhase(p)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <MatchesContainer matches={phase.matches} />
        )}
        <Divider />
      </div>
    );
  };

  private renderChildPhase = (phase: IPhase) => {
    if (!phase) return null;
    return (
      <Paper elevation={1}>
        <PhaseToolbar phase={phase} level={1} />
        {phase.children && phase.children.length
          ? phase.children.map(p => {
              return (
                <div key={p.id}>
                  <Divider />
                  <PhaseToolbar phase={p} level={2} />
                  <MatchesContainer matches={p.matches} />
                </div>
              );
            })
          : null}
        <MatchesContainer matches={phase.matches} />
      </Paper>
    );
  };
}
