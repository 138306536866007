import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusPrewrittenIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g>
        <rect
          fill="#fff"
          x="1"
          y="1"
          width="22"
          height="22"
          stroke="#000"
          strokeWidth="2"
        />
        <line x1="12" y1="2" x2="12" y2="22" stroke="#000" strokeWidth="2" />
        <line x1="2" y1="12" x2="22" y2="12" stroke="#000" strokeWidth="2" />
      </g>
    </SvgIcon>
  );
}
