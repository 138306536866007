import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NavigationChevronRight from '@material-ui/icons/ChevronRight';
import Crumb from './Crumb';

const styles = {
  buttonGo: {
    width: 36,
    backgroundColor: '#f5f5f5',
    borderRadius: 0,
    padding: 0,
  },
  container: {
    display: 'flex',
    alignItems: 'stretch',
    margin: 8,
  },
  icon: {
    margin: 6,
  },
  iconContainer: {
    display: 'inline-block',
    width: 36,
    height: 36,
    backgroundColor: '#bdbdbd',
    color: 'white',
  },
  activeContainer: {
    backgroundColor: 'rgb(34, 119, 239)',
  },
};

@withStyles(styles)
export default class Bread extends Component {
  render() {
    const {
      entity,
      crumb,
      disabled,
      filter,
      onOpen,
      onSelect,
      source,
      classes,
      href,
    } = this.props;

    return (
      <div id={`breadcrumb-${entity}`} className={classes.container}>
        <div
          className={`${classes.iconContainer} ${crumb ? classes.activeContainer : ''}`}
        >
          <this.props.icon className={classes.icon} />
        </div>
        <Crumb
          entity={entity}
          key={entity}
          crumb={crumb}
          filter={filter}
          source={source}
          onOpen={onOpen}
          onSelect={onSelect}
          disabled={disabled}
        />
        <IconButton
          aria-label="navigation"
          color={disabled || !crumb ? 'default' : 'primary'}
          style={styles.buttonGo}
          disabled={disabled || !crumb}
          href={href}
        >
          <NavigationChevronRight />
        </IconButton>
      </div>
    );
  }
}
