import { FormEdit } from 'Components/FormEdit';
import Datetime, { formatUtc } from 'Components/inputs/Datetime';
import TextInput from 'Components/inputs/Text';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import React, { Component } from 'react';
import SelectChannel from '../../channels/Select';

export default class Form extends Component {
  config = {
    fields: ['id', 'name', 'date', 'live'],
    objectFields: ['channel', 'secondaryChannel'],
    disposition: [
      {
        fields: [
          { name: 'name', component: TextInput, size: 6 },
          {
            name: 'date',
            component: Datetime,
            normalize: formatUtc,
            size: 6,
          },
          {
            name: 'live',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'channel',
            component: SelectChannel,
            size: 6,
          },
          {
            name: 'secondaryChannel',
            component: SelectChannel,
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`broadcast_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
