import React, { Component } from 'react';
import { FormEdit } from '../../../common/components/FormEdit';
import { maxLength } from '../../../common/components/FormEdit/validator';
import DatetimeInput, { formatUtc } from '../../../common/components/inputs/Datetime';
import IntInput, { normalizeInt } from '../../../common/components/inputs/Integer';
import TextInput from '../../../common/components/inputs/Text';
import Toggle, { normalizeBoolean } from '../../../common/components/inputs/Toggle';
import { getProject } from '../../../common/services/project';
import SelectConstant from '../../components/constants/Select';
import MatchInput from '../../matches/Input';
import { PersonInputCollection } from '../../people/PersonInputCollection';
import TeamInput from '../../teams/Input';
import TournamentInput from '../../tournaments/Input';
import { ILineupSurvey } from '../typings';

interface IProps {
  data: any;
  onSubmit: (lineupSurvey: ILineupSurvey) => void;
  onDelete?: (lineupSurvey: ILineupSurvey) => void;
  onRemove?: (id: string) => void;
}

export class LineupSurveyForm extends Component<IProps> {
  public config = {
    fields: [
      'id',
      'type',
      'startDate',
      'maxSelectablePlayers',
      'endDate',
      'isEnabled',
      'title',
      'slug',
      'sharedImageTitle',
    ],
    objectFields: ['team', 'match', 'tournament'],
    collectionObjectFields: ['strikers', 'midfielders', 'defenders', 'goalkeepers'],
    disposition: [
      {
        title: 'form_general',
        fields: [
          { name: 'title', component: TextInput, size: 5, required: true },
          { name: 'sharedImageTitle', component: TextInput, size: 5, validate: value => maxLength(30)(value) },
          { name: 'type', component: SelectConstant, entity: 'lineupSurvey', size: 2, disabled: true },
          { name: 'maxSelectablePlayers', component: IntInput, normalize: normalizeInt, size: 2, required: true },
          { name: 'startDate', component: DatetimeInput, normalize: formatUtc, size: 5, required: true },
          { name: 'endDate', component: DatetimeInput, normalize: formatUtc, size: 5, required: true },
          { name: 'slug', component: TextInput, disabled: true, size: 5 },
          { name: 'isEnabled', component: Toggle, normalize: normalizeBoolean },
        ],
      },
      {
        title: 'form_selectablePlayers',
        fields: [
          {
            name: 'strikers',
            labelKey: 'live_compo_position_striker',
            component: PersonInputCollection,
            normalize: this.buildUriFromPersonsId,
          },
          {
            name: 'midfielders',
            labelKey: 'live_compo_position_midfielder',
            component: PersonInputCollection,
            normalize: this.buildUriFromPersonsId,
          },
          {
            name: 'defenders',
            labelKey: 'live_compo_position_defender',
            component: PersonInputCollection,
            normalize: this.buildUriFromPersonsId,
          },
          {
            name: 'goalkeepers',
            labelKey: 'live_compo_position_goalkeeper',
            component: PersonInputCollection,
            normalize: this.buildUriFromPersonsId,
          },
        ],
      },
      {
        title: 'form_linkedData',
        fields: [
          { name: 'team', component: TeamInput, size: 6 },
          { name: 'match', component: MatchInput, size: 6 },
          { name: 'tournament', component: TournamentInput, size: 6 },
        ],
      },
    ],
  };



  public buildUriFromPersonsId(values) {
    // tslint:disable-next-line:radix
    return values.map(e => (isNaN(parseInt(e)) ? e : `/api/1.0/${getProject().lang}/people/${e}`));
  }

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`lineup_survey_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }
}
