import { Card, List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import * as React from 'react';
import { IComponentProps } from './CoachBloc.connector';

export class CoachBloc extends React.Component<IComponentProps> {
  public render() {
    const {
      classes,
      teamCoach,
      intl: { messages: m },
    } = this.props;

    return (
      <Card className={classes.card}>
        <List dense disablePadding  subheader={<ListSubheader className={classes.listSubheader}>{m.live_compo_coach}</ListSubheader>}>
        <ListItem>
          <ListItemText>{teamCoach?.personMatchName}</ListItemText>
        </ListItem>
      </List>
      </Card>
    );
  }
}
