import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchTransfers } from '../actions';
import filterList from 'Actions/lists';

import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';

import { SearchFilter } from '../../../common/components/SearchFilter';
import { TableList } from '../../../common/components/TableList';
import Row from './Row';

import ClubInput from '../../clubs/Input';
import PersonInput from '../../people/Input';

import debounce, { getLinkId } from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';

@injectIntl
@connect((store, props) => ({
  transfers: store.transfers.list,
  fetching: store.transfers.fetching,
  pagination: store.transfers.pagination,
  total: store.transfers.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Transfers extends Component {
  config = {
    filters: [
      {
        type: 'person',
        input: PersonInput,
        labelKey: 'filter_person',
      },
      {
        type: 'sourceClub',
        input: ClubInput,
        labelKey: 'filter_srcClub',
      },
      {
        type: 'targetClub',
        input: ClubInput,
        labelKey: 'filter_dstClub',
      },
    ],
    headers: [
      { id: 'date', labelKey: 'field_date' },
      {
        id: 'membership.person.translations.lastName',
        labelKey: 'field_person',
      },
      {
        id: 'previousMainTeam.club.translations.name',
        labelKey: 'field_sourceClub',
      },
      { id: 'mainTeam.club.translations.name', labelKey: 'field_targetClub' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchTransfers(filter));
    dispatch(filterList('transfers', filter));
  };

  handleFilters = change => {
    const { person, targetClub, sourceClub } = change;
    const filtersQuery = {
      person: getLinkId(person),
      targetClub: getLinkId(targetClub),
      sourceClub: getLinkId(sourceClub),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/transfers${search}`);
    }
  }, 500);

  render() {
    const {
      transfers,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="transfers-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_transfers' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_transfersList}</Typography>
            </Toolbar>
            <SearchFilter
              form="transfers_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={transfers}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
