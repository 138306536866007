import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, reduxForm } from 'redux-form';
import ToggleInput, { normalizeBoolean } from './inputs/Toggle';

const styles = {
  switch: {
    paddingLeft: 16,
  },
};

@withStyles(styles)
@reduxForm({})
export default class ToggleFilter extends Component {
  render() {
    const { toggles, classes } = this.props;
    return (
      <form>
        {toggles.map(tgg => (
          <span className={classes.switch}>
            <Field
              name={tgg.type}
              component={ToggleInput}
              labelKey={tgg.labelKey}
              normalize={normalizeBoolean}
            />
          </span>
        ))}
      </form>
    );
  }
}
