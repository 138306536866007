import { createTheme } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import * as React from 'react';
import { getPlayersFieldTranslationKey, isActionWithPlayer, isActionWithPlayer2 } from '../../utils';
import { PlayerParams } from '../PlayerParams';
import { IComponentProps } from './PlayersSelector.connector';
const greenTheme = createTheme({
  palette: {
    primary: green,
  },
});
const redTheme = createTheme({
  palette: {
    primary: red,
  },
});
export class PlayersSelector extends React.PureComponent<IComponentProps> {
  public render() {
    const {
      classes,
      type,
      removePlayerLive,
      removePlayer2Live,
      player,
      player2,
      intl: { messages: m },
    } = this.props;
    const displayP1 = isActionWithPlayer(type);
    const displayP2 = isActionWithPlayer2(type);
    const keys = getPlayersFieldTranslationKey(type);

    if (!displayP1) return null;
    return (
      <div className={classes.form}>
        <PlayerParams
          title={m[keys.player1Title]}
          helperText={m[keys.player1HelperText]}
          player={player}
          onRemove={removePlayerLive}
          theme={greenTheme}
        />
        {displayP2 ? (
          <PlayerParams
            title={m[keys.player2Title]}
            helperText={m[keys.player2HelperText]}
            player={player2}
            onRemove={removePlayer2Live}
            theme={redTheme}
          />
        ) : null}
      </div>
    );
  }
}
