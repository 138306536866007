import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { updateTeamSeason } from '../../../../components/teamSeasons/actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Form from './Form';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
  title: {
    fontWeight: 'bold',
  },
});

@connect(() => ({}))
@withStyles(styles)
export default class TeamSeasonPicturesPanel extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updateTeamSeason(id, data));
  };

  renderPanel = teamSeason => {
    const { classes } = this.props;
    const { expanded } = this.state;

    if (!teamSeason) return null;

    return (
      <ExpansionPanel
        expanded={expanded === teamSeason.id}
        onChange={this.handleExpand(teamSeason.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title}>{teamSeason.season.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === teamSeason.id && <Form onSubmit={this.handleChange(teamSeason.id)} data={teamSeason} />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { teamSeasons = [] } = this.props;
    return teamSeasons.map(this.renderPanel);
  }
}
