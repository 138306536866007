import { TextFieldProps, withStyles, WithStyles } from '@material-ui/core';
import { RefObject } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Suggestion } from 'react-mde';
import { compose } from 'recompose';
import { styles } from './styles';

type IOwnProps = {
  labelKey?: string;
  key?: string;
  name?: string;
  input: any;
  setRef?: (element: RefObject<HTMLElement>) => void;
  compact: boolean;
  mode?: string;
  loadSuggestions?: (text: string, character: string) => Promise<Suggestion[]>;
  suggestionTriggerCharacters?: string[];
} & TextFieldProps;

export type IComponentProps = InjectedIntlProps & IOwnProps & WithStyles;

export const connector = compose<IComponentProps, IOwnProps>(
  withStyles(styles),
  injectIntl
);
