import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { styles } from './styles';

export type IComponentProps = WithStyles & InjectedIntlProps;

export const connector = compose<IComponentProps, {}>(
  withStyles(styles),
  injectIntl
);
