import * as React from 'react';
import { getSrc } from '../../../../../images/tools';
import { getPersonUsualName } from '../../../../../people/utils';
import { Content } from '../Content.component';
import { IComponentProps } from './SubstituteContent.connector';

export class SubstituteContent extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { commentary, playerIn, playerOff } = this.props;
    return (
      !nextProps.playerIn ||
      !playerIn ||
      !nextProps.playerOff ||
      !playerOff ||
      nextProps.commentary.content !== commentary.content ||
      nextProps.playerIn.id !== playerIn.id ||
      nextProps.playerOff.id !== playerOff.id
    );
  }

  public render() {
    const {
      commentary,
      playerOff,
      team,
      playerIn,
      intl: { messages: m, formatMessage: f },
    } = this.props;
    if (!playerIn || !playerOff) {
      return null;
    }
    const playerInPhoto = this.getPersonPhoto(playerIn.person);
    const playerOffPhoto = this.getPersonPhoto(playerOff.person);
    return (
      <React.Fragment>
        <div className="comment__text">
          <p className="comment__text__title">
            <strong>{m.live_commentary_substitute}</strong>{' '}
            {f({ id: 'live_commentary_substitute_for' }, { team: team.name })}
          </p>
        </div>

        <div className="comment__player comment__player--substituteout">
          <p className="comment__player__name">
            {playerOff.person.matchName}
            <i className="icon icon-substituteout" />
          </p>
          <div className="comment__player__picture">{playerOffPhoto}</div>
        </div>
        <div className="comment__player comment__player--substitutein">
          <p className="comment__player__name">
            {playerIn.person.matchName}
            <i className="icon icon-substitutein" />
          </p>
          <div className="comment__player__picture">{playerInPhoto}</div>
        </div>
        <Content content={commentary.content} />
      </React.Fragment>
    );
  }

  private getPersonPhoto = person => {
    const src = getSrc(person.photo, 60) || '/img/person.jpg';
    return <img src={src} alt={getPersonUsualName(person)} width="50" height="50" />;
  };
}
