import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';

export default class Form extends Component {
  config = {
    fields: ['plainPassword'],
    disposition: [
      {
        fields: [
          {
            name: 'plainPassword',
            component: TextInput,
            type: 'password',
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={'user_password'}
        form="user_password"
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
      />
    );
  }
}
