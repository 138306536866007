import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { formatUtc } from 'Components/inputs/Datetime';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProject } from '../../../common/services/project';
import { postPush } from '../../push/actions';
import { PushForm } from '../../push/ArticleForm';
import { getLinkId } from 'Services/tools';
import { fetchImage } from 'FD/images/actions';
import { getPushTargetUrl } from './Utils';

@injectIntl
@connect((store, props) => ({
  image: props.data && store.image.detail[getLinkId(props.data.footdataMainImageId)],
}))
export default class Push extends Component {
  componentDidMount() {
    const { data, dispatch } = this.props;
    if (data.footdataMainImageId) {
      dispatch(fetchImage(data.footdataMainImageId));
    }
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;

    this.initialValues = {
      text: data.title,
      title: '',
      system: 'all',
      segment: 'global_news',
      excludedFootdataCompetitionIds: data.footdataCompetitionIds || [],
      excludedFootdataTeamIds: data.footdataTeamIds || [],
      excludedFootdataMatchIds: data.footdataMatchIds || [],
      excludedFootdataPersonIds: data.footdataPersonIds || [],
      footdataCompetitionIds: [],
      footdataTeamIds: [],
      footdataMatchIds: [],
      footdataPersonIds: [],
    };
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.data.title != this.props.data.title) {
      this.initialValues.text = next.data.title;
    }
  }

  submitPush = form => {
    const { data, dispatch, image } = this.props;
    const { segment, system, scheduledAt, ...otherFields } = form;

    const push = {
      targetSegmentId: segment,
      osSegmentId: system === 'all' ? undefined : system,
      scheduledAt: formatUtc(scheduledAt),
      imageUrl: image ? image.urls['1200x675'] : null,
      targetUrl: getPushTargetUrl(getProject().enableDeeplinkingV2, data),
      idempotencyKey: `fwa_${Date.now()}`,
      ...otherFields,
    };

    return dispatch(postPush(push, data)).then(() => ({
      payload: this.initialValues,
    }));
  };

  render() {
    const {
      intl: { messages: m },
      data,
    } = this.props;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_push}</Typography>
        </Toolbar>
        <div>
          <PushForm onSubmit={this.submitPush} data={this.initialValues} disabled={data.status !== 'publish'} />
        </div>
      </div>
    );
  }
}
