import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateInjury } from '../actions';
import Form from '../Detail/Form';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
  secondaryHeading: {
    width: '100%',
  },
  injury: {
    display: 'flex',
    flex: 1,
    margin: '0 16px',
  },
  image: {
    margin: '-5px 8px -5px 0',
    display: 'inline-flex',
  },
});

@connect()
@withStyles(styles)
export default class InjuriesPanels extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updateInjury(id, data));
  };

  renderPanel = injury => {
    const { classes } = this.props;
    const { expanded } = this.state;

    if (!injury) return null;

    return (
      <ExpansionPanel
        expanded={expanded === injury.id}
        onChange={this.handleExpand(injury.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{moment(injury.startDate).format('L')}</Typography>
          <Typography className={classes.secondaryHeading}>
            <span className={classes.injury}>{injury.type.name}</span>
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === injury.id && <Form onSubmit={this.handleChange(injury.id)} data={injury} />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { injuries = [] } = this.props;
    return injuries.map(this.renderPanel);
  }
}
