import * as React from 'react';

export function getSrc(img, width, height) {
  // const sizes = ['20x20', '60x60', '150x150', '215x120', '630x350']
  let src = null;
  if (!img || !img.urls) {
    return null;
  }
  if (width <= 20) {
    src = img.urls['20x20'];
  } else if (width <= 60) {
    src = img.urls['60x60'];
  } else if (width == 215 && height == 120) {
    src = img.urls['215x120'];
  } else if (width == 630 && height == 350) {
    src = img.urls['630x350'];
  } else {
    src = img.urls['150x150'];
  }

  return src ? src.replace('assets-fr./', 'assets-fr.imgfoot.com/') : '';
}

export function getOriginalSrc(img) {
  return img && img.originalUrl ? img.originalUrl.replace('assets-fr./', 'assets-fr.imgfoot.com/') : null;
}

export function renderImg(img, width, height) {
  const src = getSrc(img, width, height);
  return src && <img src={src} width={width} height={height || width} />;
}
