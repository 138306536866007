import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchStaffs(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    role: 'staff',
    partial: 0,
    'order[translations.priority]': 'asc',
    groups: ['read', '_image'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_STAFFS' });

    return rest
      .get('people/with_club/', { params })
      .then(
        res => dispatch({ type: 'FETCH_STAFFS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_STAFFS_REJECTED', payload: err.response })
      );
  };
}

export function fetchStaff(slug) {
  const params = {
    slug,
    role: 'staff',
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_STAFF' });

    return rest.get('people', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_STAFF_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_STAFF_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_STAFF_REJECTED', payload: err.response })
    );
  };
}

export function fetchStaffById(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_STAFF' });

    return rest
      .get(`people/${id}}`)
      .then(
        res => dispatch({ type: 'FETCH_STAFF_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_STAFF_REJECTED', payload: err.response })
      );
  };
}

export function updateStaff(id, staff) {
  const params = {
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_STAFF', payload: id });
    return rest.put(`people/${id}`, staff, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_staffUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_STAFF_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_STAFF_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addStaff(staff) {
  return function(dispatch) {
    dispatch({ type: 'ADD_STAFF' });

    return rest
      .post('people', { ...staff, role: 'staff' })
      .then(
        res => dispatch({ type: 'ADD_STAFF_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_STAFF_REJECTED', payload: err.response })
      );
  };
}

export function deleteStaff(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_STAFF', payload: id });

    return rest
      .delete(`people/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_STAFF_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_STAFF_REJECTED', payload: err.response })
      );
  };
}
