import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';

import { connect } from 'react-redux';

import { fetchCorrections } from '../actions';
import filterList from 'Actions/lists';

import { TableList } from '../../../common/components/TableList';
import Row from './Row';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';

import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import debounce from 'Services/tools';

import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { SearchFilter } from '../../../common/components/SearchFilter';

@injectIntl
@connect((store, props) => ({
  corrections: store.corrections.list,
  fetching: store.corrections.fetching,
  pagination: store.corrections.pagination,
  total: store.corrections.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Corrections extends Component {
  config = {
    filters: [
      {
        type: 'field',
        labelKey: 'filter_field',
      },
    ],
    headers: [
      { id: 'createdAt', labelKey: 'field_createdAt' },
      { id: 'objectClass', labelKey: 'field_objectClass' },
      { id: 'field', labelKey: 'field_field' },
      { id: 'correctedValue', labelKey: 'field_correctedValue' },
      { id: 'inputValue', labelKey: 'field_inputValue' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchCorrections(filter));
    dispatch(filterList('corrections', filter));
  };

  handleFilters = change => {
    const { field } = change;
    const filtersQuery = {
      field,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/corrections${search}`);
    }
  }, 500);

  render() {
    const {
      corrections,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="corrections-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_corrections' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_correctionsList}</Typography>
            </Toolbar>
            <SearchFilter
              form="corrections_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={corrections}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
