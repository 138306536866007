import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import isAdmin from 'Services/me';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Form from './Form';
import { updateRankingRule } from '../actions';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
  rank: {
    width: 24,
    fontWeight: 'bold',
  },
});

@withStyles(styles)
@connect((store, props) => ({
  rankingRule: store.rankingRule.detail[props.id],
}))
export default class TournamentRankingRulePanel extends Component {
  handleChange = id => rr => {
    const { dispatch } = this.props;
    return dispatch(updateRankingRule(id, rr));
  };

  render() {
    const { rankingRule, classes } = this.props;

    if (!rankingRule) return null;

    return (
      <ExpansionPanel disabled={!isAdmin()} classes={{ root: classes.panel }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.rank}>{rankingRule.rank}</Typography>
          <Typography>{rankingRule.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          <Form onSubmit={this.handleChange(rankingRule.id)} data={rankingRule} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
