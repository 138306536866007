import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function SecondYellowCardIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          fill="yellow"
          d="M114 411l237 0 0-356-237 0z m284-313l-5 0 0 354-235 0 0 5 240 0z m45 46l-4 0 0 354-235 0 0 5 239 0z"
        />
      </g>
    </SvgIcon>
  );
}
