import { withStyles, WithStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { styles } from './styles';

interface IOwnProps {
  title: string;
  description: string;
}

export type IComponentProps = IOwnProps & WithStyles;

export const connector = compose<IComponentProps, IOwnProps>(withStyles(styles));
