export default function reducer(state = {}, action) {
    switch (action.type) {
        case 'FETCH_ARTICLE_RELATION': {
            return {
                ...state,
                fetching: true,
            };
        }

        case 'FETCH_ARTICLE_RELATION_FULFILLED': {
            const { id, list } = action.payload;
            return {
                ...state,
                [id]: { list },
                fetching: false,
            };
        }
        case 'FETCH_ARTICLE_RELATION_REJECTEDD': {
            return {
                ...state,
                diff: action.payload,
            };
        }
        default:
            return state;
    }
}
