import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SimpleQuoteGlobal({ ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="M9 17h3l2-4V7H8v6h3l-2 4z" />
    </SvgIcon>
  );
}
