import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioConstant from '../components/constants/Radio';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Text from 'Components/inputs/Text';

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.primary[500]}`,
  },
});

@withStyles(styles)
@reduxForm({
  validate: values => {
    const errors = {};
    if (!values.title) {
      errors.title = 'error_required';
    }
    return errors;
  },
})
@injectIntl
export default class ArticleDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.props.initialize({ type: 'center', title: '' });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleConfirm = article => {
    this.handleClose();
    this.props.onCreate(article);
  };

  render() {
    const {
      title,
      handleSubmit,
      pristine,
      submitting,
      classes,
      children,
      intl: { messages: m },
      valid,
    } = this.props;
    const { open } = this.state;

    return (
      <div>
        {React.cloneElement(children, { onClick: this.handleOpen, key: 'open_button' })}
        <Dialog open={open} classes={{ paper: classes.body }} data-test="ArticleDialog">
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(this.handleConfirm)}>
            <DialogContent>
              <Field
                name="title"
                component={Text}
                label={m.field_title}
                data-test="ArticleListDialog__inputTitle"
                autoFocus
              />
              <Field
                name="type"
                component={RadioConstant}
                entity="article"
                label={m.field_type}
                dataTest="ArticleList__radioType"
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} disabled={submitting}>
                {m.button_cancel}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                data-test="ArticleListDialog__buttonSubmit"
                disabled={!valid || pristine || submitting}
              >
                {m.button_create}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
