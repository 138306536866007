import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { UserRestriction } from '../../../../common/components/UserRestiction';
import {hasAccessToRestrictedArticles} from "../../../../common/services/me";
import { BriefPanel } from '../../../briefs/components/BriefPanel';
import BriefDialog from '../../../briefs/Dialog';
import { IArticle } from '../../typings';

type IProps = {
  briefs: IArticle[];
  onAdd: (brief: IArticle) => void;
} & InjectedIntlProps;

export class ArticleBriefs extends React.Component<IProps> {
  public state = {
    expanded: null,
    isSeachModalOpen: false,
  };

  public render() {
    const {
      briefs = [],
      intl: { messages: m },
    } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_briefs}</Typography>
          <div style={{ flex: 1 }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              padding: '16px 0',
            }}
          >
            <UserRestriction>
              <BriefDialog title={m.dialog_addBrief} form="liveTransfer_add" onCreate={this.handleCreate}>
                <Button variant="contained" color="primary">
                  <AddIcon style={{ marginRight: 8 }} />
                  {m.button_add}
                </Button>
              </BriefDialog>
            </UserRestriction>
          </div>
        </Toolbar>
        <div style={{ padding: 16 }}>
          {briefs.sort(this.sortBriefs).map(brief => (
            (brief.status !== 'restricted' || hasAccessToRestrictedArticles()) ?
              (<BriefPanel key={brief.id} brief={brief} isExpanded={expanded === brief.id} onExpend={this.handleExpand} />)
              : null
          ))}
        </div>
      </div>
    );
  }

  private handleCreate = values => {
    const { onAdd } = this.props;
    return onAdd(values);
  };

  private handleExpand = (panel: string, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  private sortBriefs = (brief1: IArticle, brief2: IArticle) => {
    if (brief1.publishedAt && brief2.publishedAt) {
      return brief1.publishedAt < brief2.publishedAt ? 1 : -1;
    }
    if (!brief1.publishedAt && !brief2.publishedAt) {
      return brief1.createdAt < brief2.createdAt ? 1 : -1;
    }

    return brief1.publishedAt ? 1 : -1;
  };
}
