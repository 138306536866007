import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Detail from './Detail';

export default function RevisionsRoutes({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/:topicId`} component={Detail} />
    </Switch>
  );
}
