import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { updateTransfer } from '../actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { renderImg } from '../../images/tools';

import Form from '../Detail/Form';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
  secondaryHeading: {
    width: '100%',
  },
  team: {
    display: 'flex',
    flex: 1,
    margin: '0 16px',
  },
  image: {
    margin: '-5px 8px -5px 0',
    display: 'inline-flex',
  },
});

@connect()
@withStyles(styles)
export default class TransfersPanels extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updateTransfer(id, data));
  };

  renderTeam = team => {
    const { classes } = this.props;
    if (!team) {
      return (
        <span className={classes.team}>
          <span>-</span>
        </span>
      );
    }
    return (
      <span className={classes.team}>
        {team.club && <span className={classes.image}>{renderImg(team.club.logo, 30)}</span>}
        <span>{team.name}</span>
      </span>
    );
  };

  getTransferText = t => (
    <span style={{ display: 'flex' }}>
      {this.renderTeam(t.previousMainTeam)}
      {this.renderTeam(t.mainTeam)}
    </span>
  );

  renderPanel = transfer => {
    const { classes } = this.props;
    const { expanded } = this.state;

    if (!transfer) return null;

    return (
      <ExpansionPanel
        expanded={expanded === transfer.id}
        onChange={this.handleExpand(transfer.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{moment(transfer.date).format('L')}</Typography>
          <Typography className={classes.secondaryHeading}>{this.getTransferText(transfer)}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === transfer.id && <Form onSubmit={this.handleChange(transfer.id)} data={transfer} />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { transfers = [] } = this.props;
    return transfers.map(this.renderPanel);
  }
}
