import rest from 'Services/restFootData';

export default function fetchLastActiveTeamTeamSeasonMembers(team) {
  const params = {
    team,
    last: 1,
    isActive: 1,
    pagination: 0,
    groups: ['read', 'read_teamSeasonMember', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TEAM_TEAMSEASONMEMBERS', payload: team });

    return rest.get('team_season_members', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_TEAM_TEAMSEASONMEMBERS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_TEAM_TEAMSEASONMEMBERS_REJECTED',
          payload: err.response,
        })
    );
  };
}
