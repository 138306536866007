import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { UserRestriction } from '../../../../../common/components/UserRestiction';
import { getProject } from '../../../../../common/services/project';
import PersonAvatar from '../../../Avatar';
import PersonInput from '../../../Input';
import { getPersonFullName } from '../../../utils';
import { IAgent } from '../../typings';
import { IComponentProps } from './index';

interface IState {
  personAId: string;
}
export class AgentsColumn extends React.Component<IComponentProps, IState> {
  public state: IState = {
    personAId: '',
  };

  public render() {
    return (
      <React.Fragment>
        {this.renderList()}
        {this.renderAddBox()}
      </React.Fragment>
    );
  }

  private renderList = () => {
    const {
      agents = [],
      intl: { messages: m },
    } = this.props;

    if (!agents) {
      return null;
    }

    return (
      <List
        dense
        subheader={
          <ListSubheader>
            {m.app_agents} ({agents.length})
          </ListSubheader>
        }
      >
        {agents.map((agent: IAgent) => (
          <ListItem key={agent.id} button component="a" href={`#${getProject().url}/agents/${agent.slug}`}>
            <ListItemIcon>
              <PersonAvatar person={agent} />
            </ListItemIcon>
            <ListItemText primary={getPersonFullName(agent)} />
            <ListItemSecondaryAction>
              <UserRestriction>
                <IconButton aria-label="Delete" onClick={this.handleRemove(agent)}>
                  <DeleteIcon />
                </IconButton>
              </UserRestriction>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  };

  private renderAddBox = () => {
    const {
      intl: { messages: m },
    } = this.props;
    const { personAId } = this.state;

    return (
      <div style={{ padding: 16, background: '#eeeeee' }}>
        <PersonInput input={{ onChange: this.handlePersonAId, value: personAId }} labelKey="button_add" />
        {personAId ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 16 }}>
            <Button color="primary" onClick={this.handleCancel}>
              {m.button_cancel}
            </Button>
            <UserRestriction>
              <Button color="primary" variant="contained" onClick={this.handleConfirm}>
                {m.button_add}
              </Button>
            </UserRestriction>
          </div>
        ) : null}
      </div>
    );
  };

  private handleRemove = (player: IAgent) => () => {
    this.props.onRemove(player.id);
  };

  private handlePersonAId = (personAId: string) => {
    this.setState({ personAId });
  };
  private handleCancel = () => {
    this.setState({ personAId: '' });
  };
  private handleConfirm = () => {
    this.setState({ personAId: '' });
    this.props.onSelect(this.state.personAId);
  };
}
