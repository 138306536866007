import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchClubsList } from '../actions';
import filterList from 'Actions/lists';

import Row from './Row';
import Breadcrumb from 'FDComponents/Breadcrumb';
import { resetBreadcrumb } from '../../components/Breadcrumb/actions';

import AreaInput from 'FDComponents/areas/Input';
import CompetitionInput from '../../competitions/Input';

import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import debounce, { getLinkId } from 'Services/tools';

import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { TableList } from '../../../common/components/TableList';

@injectIntl
@connect((store, props) => ({
  clubs: store.clubs.list,
  fetching: store.clubs.fetching,
  pagination: store.clubs.pagination,
  total: store.clubs.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Clubs extends Component {
  config = {
    filters: [
      {
        type: 'name',
        labelKey: 'filter_name',
      },
      {
        type: 'country',
        input: AreaInput,
        labelKey: 'filter_country',
      },
      {
        type: 'competition',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
    ],
    headers: [
      { id: 'translations.name', labelKey: 'field_name' },
      { id: 'country.translations.name', labelKey: 'field_country' },
      { id: 'clubMainCompetition', labelKey: 'field_tournament' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { dispatch, location } = this.props;
    this.fetchData(location.search);
    dispatch(resetBreadcrumb());
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchClubsList(filter));
    dispatch(filterList('clubs', filter));
  };

  handleFilters = change => {
    const { name, country, competition } = change;
    const filtersQuery = {
      name,
      country: getLinkId(country),
      competition: getLinkId(competition),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/clubs${search}`);
    }
  }, 500);

  render() {
    const {
      clubs,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="clubs-layout"
        top={<Breadcrumb />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_clubsList}</Typography>
            </Toolbar>
            <SearchFilter
              form="clubs_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={clubs}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
