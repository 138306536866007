import React, { RefObject } from 'react';
import { Suggestion } from 'react-mde';
import TextEdit from '../../../../../common/components/inputs/TextEdit';
import { IComponentProps } from './ContentEditor.connector';

export class ContentEditor extends React.PureComponent<IComponentProps> {
  private ref: RefObject<HTMLElement> | null = null;

  public componentDidUpdate(prevProps) {
    const { type, isTypeCompleted, player2, editingCommentaryId } = this.props;
    if (!this.ref || !this.ref.current || !isTypeCompleted) return;
    // If is complete
    if (
      // and change type
      prevProps.type !== type ||
      // or was not complete before
      !prevProps.isTypeCompleted ||
      // or finish second action
      (!prevProps.player2 && player2) ||
      // or has recovered a commentary
      (!prevProps.editingCommentaryId && editingCommentaryId)
    ) {
      this.ref.current.focus();
    }
  }

  public render() {
    const { content, type, editingCommentaryId, resetCount } = this.props;

    const key = editingCommentaryId ? `${type}_${editingCommentaryId}` : `${type}_${resetCount}`;

    return (
      <TextEdit
        key={key}
        input={{
          name: 'content',
          value: content,
          onChange: this.handleChange,
        }}
        setRef={this.setRef}
        compact
        suggestionTriggerCharacters={['@', '#']}
        loadSuggestions={this.loadSuggestions}
      />
    );
  }

  private handleChange = (input: string) => {
    this.props.setContentLive(input);
  };

  private setRef = (element: RefObject<HTMLElement>) => {
    this.ref = element;
  };

  private loadSuggestions = (text: string, character: string): Promise<Suggestion[]> => {
    switch (character) {
      case '@':
        return this.handleSuggestions(text, this.props.playersSuggestions);
        break;
      case '#':
        return this.handleSuggestions(text, this.props.teamsSuggestions);
      default:
        return Promise.resolve([]);
    }
  };

  private handleSuggestions = (text: string, suggestions: Suggestion[]): Promise<Suggestion[]> => {
    if (!suggestions) return Promise.resolve([]);
    return new Promise(resolve => {
      resolve(suggestions.filter(i => i.value.toLowerCase().includes(text.toLowerCase())));
    });
  };
}
