import { WithStyles, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { updateChrono } from '../../actions/liveActions';
import { styles } from './styles';

interface IOwnProps {}

const mapDispatchToProps = {
  updateChrono,
};

const mapStateToProps = (state: IRootState) => ({
  period: state.live.period,
  chronoStartTimeStamp: state.live.chronoStartTimeStamp,
  chronoEndTimeStamp: state.live.chronoEndTimeStamp,
  chronoStatus: state.live.chronoStatus,
  mainCommentatorLocale: state.live.mainCommentatorLocale,
});

export type IComponentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles)
);
