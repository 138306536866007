import { ICommentary } from '../../../commentaries/typings';
import { fetchMatch, pauseMatchAction, resumeMatchAction, updateMatch } from '../../../matches/actions';
import { selectMatch, selectMatchCommentariesEvents } from '../../../matches/selector';
import { IMatch } from '../../../matches/typings';
import { getMatchMatchPeriod } from '../../../matches/utils';
import {IMatchEvent, IMatchEventPenaltyShot} from '../../../matchEvents/typings';
import { ILiveCommentary, ILiveState } from '../typings';
import { getScores, getShootoutScores } from '../utils';

export const updateMatchScore = (
  eventName: string,
  match: IMatch,
  commentary: ILiveCommentary,
  matchEvent: IMatchEvent
) => {
  return (dispatch, getState) => {
    if (eventName !== 'goal' && eventName !== 'penalty_shot') {
      return Promise.resolve(null);
    }

    const matchEvents = selectMatchCommentariesEvents(getState(), match.id);
    const { away, home } = eventName === 'penalty_shot' ? getShootoutScores(matchEvents, matchEvent as IMatchEventPenaltyShot) : getScores(matchEvents, matchEvent);
    const scores = { home, away };
    const updates: any = {};
    const matchPeriod = getMatchMatchPeriod(match, commentary.period);

    if (matchPeriod) {
      updates.matchPeriods = {
        [matchPeriod.type]: { scores, id: matchPeriod['@id'] },
      };
      // update match scores only for in game goals
      if (eventName === 'goal') {
        updates.scores = scores;
      }
    }

    if (hasScoresAgregate(match) && eventName === 'goal') {
      updates.agregateScores = calculateAgregateScores(match.agregateScores, match.scores, scores);
    }

    return dispatch(updateMatch(match.id, updates));
  };
};

export const updateMatchScoreAfterDelete = (commentary: ICommentary) => {
  return (dispatch, getState) => {
    const eventName = commentary.matchEvent.name;
    if (eventName !== 'goal' && eventName !== 'penalty_shot') {
      return Promise.resolve(null);
    }

    // get period du commentary et update le match period
    // + les periods qui suivent ? et autres matchEvents ?
    const liveState: ILiveState = getState().live;
    const match: IMatch = selectMatch(getState(), liveState.matchId) as IMatch;
    const matchEvents = selectMatchCommentariesEvents(getState(), getState().live.matchId);
    const { away, home } = eventName === 'penalty_shot' ? getShootoutScores(matchEvents) : getScores(matchEvents);

    const scores = { home, away };
    const matchPeriod = getMatchMatchPeriod(match, commentary.period);
    const updates: any = {};
    if (matchPeriod) {
      updates.matchPeriods = {
        [matchPeriod.type]: { scores, id: matchPeriod['@id'] },
      };
      // update match scores only for in game goals
      if (eventName === 'goal') {
        updates.scores = scores;
      }
    }

    if (hasScoresAgregate(match) && eventName === 'goal') {
      updates.agregateScores = calculateAgregateScores(match.agregateScores, match.scores, scores);
    }

    return dispatch(updateMatch(liveState.matchId, updates));
  };
};

export const pauseLiveMatch = () => {
  return (dispatch, getState) => {
    const matchId: string = getState().live.matchId;
    return dispatch(pauseMatchAction(matchId)).then(() => dispatch(fetchMatch(matchId)));
  };
};

export const resumeLiveMatch = () => {
  return (dispatch, getState) => {
    const matchId: string = getState().live.matchId;
    return dispatch(resumeMatchAction(matchId)).then(() => dispatch(fetchMatch(matchId)));
  };
};

const hasScoresAgregate = match => match && match.scores && match.agregateScores;

const calculateAgregateScores = (agregateScores, currentScores, newScores) => {
  return {
    ...agregateScores,
    away: agregateScores.away + newScores.away - currentScores.away,
    home: agregateScores.home + newScores.home - currentScores.home,
  };
};
