import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NationalityCell from 'Components/TableList/cell/Nationality';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import PersonAvatar from '../../Avatar';
import { getPersonUsualName } from '../../utils';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class AgentRow extends Component {
  render() {
    const { classes, tableData } = this.props;
    const { company, mainNationality, secondNationality, roleTranslated } = tableData;
    const href = `#${getProject().url}/agents/${tableData.slug}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          <div className={classes.rowContent}>
            <PersonAvatar person={tableData} />
            <div className={classes.label}>
              <div>{getPersonUsualName(tableData)}</div>
              <div>{roleTranslated}</div>
            </div>
          </div>
        </TableCell>
        <TableCell>{company && company.name}</TableCell>
        <TableCell>
          <NationalityCell main={mainNationality} second={secondNationality} />
        </TableCell>
      </TableRow>
    );
  }
}
