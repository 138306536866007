import { FormEdit } from 'Components/FormEdit';
import DatetimeInput, { formatUtc } from 'Components/inputs/Datetime';
import TextInput from 'Components/inputs/Text';
import TextEdit from 'Components/inputs/TextEdit';
import React, { Component } from 'react';
import { TeamInputCollection } from '../../../../footdata/teams/TeamInputCollection';
import { PersonInputCollection } from '../../../../footdata/people/PersonInputCollection';
import { AuthorsInputCollection } from '../../../authors/InputCollection';
import SelectConstant from '../../../components/constants/Select';
import { TagsInputCollection } from '../../../tags/InputCollection';
import { getProject } from "Services/project";
import { addFieldConfigToDisposition } from 'Components/FormEdit/utils'
import { maxLength } from 'Components/FormEdit/validator';
import { hasAccessToRestrictedArticles } from "Services/me";

export default class BriefForm extends Component {
    config = () => {
        let config =
            {
                fields: ['id', 'title', 'specificity', 'authors', 'content', 'publishedAt', 'status', 'footdataTeamIds', 'footdataPersonIds',],
                collectionObjectFields: ['authors', 'tags'],
                disposition: [
                    {
                        fields: [
                            { name: 'title', component: TextInput, size: 6 , validate: value => maxLength(250)(value) },
                            {
                                name: 'status',
                                component: SelectConstant,
                                entity: 'article',
                                size: 6,
                            },
                            {
                                name: 'specificity',
                                component: SelectConstant,
                                entity: 'brief',
                                nullable: true,
                                size: 6,
                            },
                            {
                                name: 'publishedAt',
                                component: DatetimeInput,
                                normalize: formatUtc,
                                size: 6,
                            },
                            { name: 'authors', component: AuthorsInputCollection, size: 6 },
                            { name: 'tags', component: TagsInputCollection, disabled: true, size: 6 },
                            {
                                name: 'footdataTeamIds',
                                component: TeamInputCollection,
                                size: 6,
                                labelKey: 'field_footdataTeamIds',
                            },
                            {
                                name: 'footdataPersonIds',
                                component: PersonInputCollection,
                                size: 6,
                                labelKey: 'field_footdataPersonIds',
                            },
                            { name: 'content', component: TextEdit },
                        ],
                    },
                ],
            };
        if (getProject().featureFlagsFmV2) {
            config.fields.push('readMoreUrl');
            config = addFieldConfigToDisposition(config, { name: 'readMoreUrl', component: TextInput, size: 6 }, 8);
        }

        return config;
    };

    render() {
        const { data, onSubmit } = this.props;
        if (data.status === 'restricted' && !hasAccessToRestrictedArticles()) {
            return null;
        }

        return <FormEdit key={data.id} form={`brief_${data.id}`} onSubmit={onSubmit} data={data} config={this.config()} />;
    }
}
