import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { awardLiveMatch } from '../../actions';
import { styles } from './styles';
import {IRootState} from "../../../../../typings";

interface IOwnProps {}

const mapDispatchToProps = {
  awardMatch: awardLiveMatch,
};

const mapStateToProps = (state: IRootState) => ({
  mainCommentatorLocale: state.live.mainCommentatorLocale,
});

export type IComponentProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedIntlProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl,
  withStyles(styles)
);
