export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_COUNTERS': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_COUNTERS_FULFILLED': {
      const { Club, Competition, Match, Person } = action.payload;
      return {
        ...state,
        clubs: Club,
        competitions: Competition,
        matches: Match,
        people: Person,
      };
    }
    case 'FETCH_COUNTERS_REJECTED': {
      return {
        ...state,
        fetching: false,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }

    default:
      return state;
  }
}
