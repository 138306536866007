import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {searchCompetitions} from "../actions";
import {IRootState} from "../../../typings";
import {IInput} from "../../components/InputCollection/InputCollection.component";

export interface ICompetitionOption {
    label: string;
    value: string;
}

interface IOwnProps {
    input: IInput;
    selectedCompetitionOption: ICompetitionOption | null;
}

const mapDispatchToProps = {
    searchCompetitions,
};

const mapStateToProps = (state: IRootState) => ({
    competitions: state.competitions.list,
})

export type IComponentProps = typeof mapDispatchToProps & InjectedIntlProps & IOwnProps;

const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
    reduxConnector,
    injectIntl
);