import rest from 'Services/restFootData';

export default function fetchCounters() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_COUNTERS' });

    return rest
      .get('counters')
      .then(
        res =>
          dispatch({ type: 'FETCH_COUNTERS_FULFILLED', payload: res.data }),
        err =>
          dispatch({ type: 'FETCH_COUNTERS_REJECTED', payload: err.response })
      );
  };
}
