import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Alert} from "@material-ui/lab";
import React from "react";
import {injectIntl} from "react-intl";
import {Field, FieldArray, WrappedFieldArrayProps} from "redux-form";
import Checkbox, {normalizeCheckbox} from "../../../../common/components/inputs/Checkbox";
import IntInput, {normalizeInt} from "../../../../common/components/inputs/Integer";
import TextInput from "../../../../common/components/inputs/Text";
import { IInput } from "../../../components/InputCollection/InputCollection.component";
import { IQuizz } from "../../typings";

interface IProps {
    meta: any;
    input: IInput;
    intl: any;
    quizz: IQuizz;
    hasStarted: boolean;

}

// @ts-ignore
@injectIntl
export class QuizzQuestionInputCollection extends React.Component<IProps> {

    public render() {
        return (
                <FieldArray name="questions" component={this.renderQuestions} />
        );
    }

    private renderQuestions: React.FC<WrappedFieldArrayProps<any>> = ({ fields, meta: { error  } }) => {
        const {
            intl: {messages: m, formatMessage: f},
            hasStarted
        } = this.props;
        const disabled = hasStarted;
        return (
            <Grid container xs={12} sm={12} spacing={4} key={1}>
                {fields.map((member, index) => (

                        <Grid container item xs={12} sm={12} key={index}>
                            <Paper  elevation={3} style={{ width: '100%' }} >
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="h6" component="h6" style={{margin: 8}}>
                                            {f({id: "field_question"})} #{index + 1}
                                            <IconButton onClick={() => fields.remove(index)} color="secondary" disabled={disabled}>
                                                <DeleteIcon style={{marginRight: 0}}/>
                                            </IconButton>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid item xs={12} sm={12} key={`${member}.question`}>
                                            <Grid item xs={12} sm={12} key={`${member}.question`}>
                                                <Field
                                                    labelKey="field_question"
                                                    name={`${member}.question`}
                                                    required={true}
                                                    component={TextInput}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} key={`${member}.ordering`}>
                                                <Field
                                                    labelKey="field_ordering"
                                                    name={`${member}.ordering`}
                                                    required={true}
                                                    component={IntInput}
                                                    size={1}
                                                    normalize={normalizeInt}
                                                />
                                            </Grid>

                                            <FieldArray
                                                name={`${member}.options`}
                                                component={this.renderAnswers}
                                                props={this.props}
                                            />
                                        </Grid>

                                    </AccordionDetails>
                                </Accordion>
                            </Paper>
                        </Grid>
                ))}
                <Grid container item xs={12} sm={12}>
                    {error && <Alert severity="error">{f({id: error})}</Alert>}
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Button variant="contained" onClick={this.addQuestion(fields)} color="primary"
                            data-test="ArticleList__buttonAdd" disabled={disabled} >
                        <AddIcon style={{marginRight: 8}}/>
                        {m.button_add_question}
                    </Button>
                </Grid>
            </Grid>

        );
    }

    private renderAnswers: React.FC<WrappedFieldArrayProps<any>> = ({ fields, meta: { error  } }) => {
        const {
            intl: {messages: m, formatMessage: f},
            hasStarted
        } = this.props;
        const disabled = hasStarted;
        return (
            <Grid item xs={12} sm={12} spacing={2} key={1}>
                <Typography variant="h6" component="h6" style={{margin: 8}}>
                    Réponses
                </Typography>
                {fields.map((member, index) => (

                    <Grid container item xs={12} sm={12} key={index} spacing={4} >
                        <Grid item xs={1} sm={1} key={`${member}.value`}>
                            <Field
                                labelKey="field_value"
                                name={`${member}.value`}
                                required={true}
                                component={TextInput}
                                InputProps={{disabled: true,}}
                                size={1}
                            />
                        </Grid>
                        <Grid container item xs={12} sm={6} key={`${member}.label`}>
                            <Field
                                labelKey="field_answer"
                                name={`${member}.label`}
                                required={true}
                                component={TextInput}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} key={`${member}.rightAnswer`}>
                            <Field
                                labelKey="field_rightAnswer"
                                name={`${member}.isRightAnswer`}
                                required={true}
                                component={Checkbox}
                                size={1}
                                normalize={normalizeCheckbox()}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} key={`${member}.delete`}>
                            <IconButton onClick={() => fields.remove(index)} color="secondary" disabled={disabled}>
                                <DeleteIcon style={{marginRight: 0}} />
                            </IconButton>
                        </Grid>
                        <Grid container item xs={12} sm={12}>
                            {error && <Alert severity="error">{f({id: error})}</Alert>}
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} sm={6}>
                    <Button variant="contained" onClick={this.addAnswer(fields)} color="primary"
                            data-test="ArticleList__buttonAdd" disabled={disabled} >
                        <AddIcon style={{marginRight: 8}}/>
                        {m.button_add_answer}
                    </Button>
                </Grid>

            </Grid>

        );
    }

    private addQuestion(fields) {
            return () => {
                fields.push({
                    index: fields.length + 1,
                    questions: null,
                    options: null,
                    ordering: fields.length + 1
                })};
        }

    private addAnswer(fields) {
        return () => {
            fields.push({
                label: null,
                value: `${fields.length + 1}`,
                isRightAnswer: null
            })};
    }
}
