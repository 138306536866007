export const styles = () => ({
  container: {
    display: 'flex',
    margin: 16,
  },
  score: {
    margin: '16px 8px',
    width: 48,
    height: 48,
    borderRadius: 8,
    color: '#aab8d7',
    background: '#e6e8f5',
    fontSize: 36,
    fontWeight: 'bold' as 'bold',
  },
  shootoutScore: {
    margin: '0 16px',
    fontWeight: 'bold' as 'bold',
  },
  teamContainer: {
    margin: 16,
    textAlign: 'center' as 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  team: {
    color: '#001f4e',
    fontWeight: 'bold' as 'bold',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  infoContainer: { display: 'flex', justifyContent: 'center' },
  info: { fontSize: 13, color: '#001f4d', textTransform: 'uppercase' as 'uppercase' },
  info2: { fontSize: 9, color: '#396bb8' },
});
