import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ISide } from '../../../../matches/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import {
  addMatchTeamPlayerOnSubstitutes,
  removeMatchTeamPlayerFromSubtitutes,
  swapMatchTeamPlayerFromSubtitutes,
} from './actions';

const mapDispatchToProps = {
  addMatchTeamPlayerOnSubstitutes,
  removeMatchTeamPlayerFromSubtitutes,
  swapMatchTeamPlayerFromSubtitutes,
};
interface IOwnProps {
  substitute: IMatchTeamPlayer | null;
  side: ISide;
}

export type IComponentProps = typeof mapDispatchToProps & IOwnProps;

const reduxConnector = connect(
  null,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
