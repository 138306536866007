import rest from 'Services/restFootData';

export function fetchCountries() {
  const params = {
    pagination: 0,
  };

  return function(dispatch, getState) {
    const storedCountries = getState().areas;
    if (storedCountries.list && storedCountries.list.length) {
      return Promise.resolve({
        type: 'CACHED_AREAS',
        payload: storedCountries,
      });
    }

    dispatch({ type: 'FETCH_AREAS' });
    return rest
      .get('areas', { params })
      .then(
        res => dispatch({ type: 'FETCH_AREAS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_AREAS_REJECTED', payload: err.response })
      );
  };
}

export function fetchCountry(id) {
  return function(dispatch, getState) {
    return dispatch(fetchCountries()).then(() => {
      const storedCountries = getState().areas;
      const res = storedCountries.list.find(country => country.id == id);
      return res
        ? dispatch({ type: 'FETCH_AREA_FULFILLED', payload: res })
        : dispatch({
            type: 'FETCH_AREA_REJECTED',
            payload: { message: 'Country not found' },
          });
    });
  };
}
