import rest from 'Services/restFootData';
import { notifySuccessAction } from '../../common/actions/notifications';
import { getProject } from '../../common/services/project';

export function fetchMatchCommentaries(match) {
  const params = {
    match,
    groups: ['read', '_embed'],
    'order[createdAt]': 'asc',
    locale: getProject().lang,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCH_COMMENTARIES' });

    return rest.get('commentaries', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_MATCH_COMMENTARIES_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_MATCH_COMMENTARIES_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function addMatchCommentary(commentary) {
  return function(dispatch) {
    dispatch({ type: 'ADD_MATCH_COMMENTARY' });

    return rest.post('commentaries?groups[0]=write&groups[1]=read&groups[2]=_embed', { ...commentary, locale: getProject().lang }).then(
      res => {
        dispatch(notifySuccessAction('message_commentaryCreated'));
        return dispatch({
          type: `ADD_MATCH_COMMENTARY_FULFILLED`,
          payload: res.data,
        });
      },
      err =>
        dispatch({
          type: `ADD_MATCH_COMMENTARY_REJECTED`,
          payload: err.response,
        })
    );
  };
}

export function updateCommentary(id, commentary) {
  const params = {
      groups: ['write', 'read', '_embed'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_COMMENTARY', payload: id });
    return rest.put(`commentaries/${id}`, commentary, { params }).then(
      res => {
        dispatch(notifySuccessAction('message_commentaryUpdated'));
        return dispatch({
          type: 'UPDATE_COMMENTARY_FULFILLED',
          payload: res.data,
        });
      },
      err =>
        dispatch({
          type: 'UPDATE_COMMENTARY_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function deleteCommentary(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_COMMENTARY', payload: id });

    return rest.delete(`commentaries/${id}`).then(
      () => {
        dispatch(notifySuccessAction('message_commentaryDeleted'));
        return dispatch({ type: 'DELETE_COMMENTARY_FULFILLED', payload: { id } });
      },
      err =>
        dispatch({
          type: 'DELETE_COMMENTARY_REJECTED',
          payload: err.response,
        })
    );
  };
}

export const setCommentaryDefaultContent = contents => ({
  type: 'SET_COMMENTARY_DEFAULT_CONTENT',
  payload: contents,
});
