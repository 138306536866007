import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  secondaryAction: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteIcon: {
    marginRight: theme.spacing(1),
  },
  warnItem: { backgroundColor: '#ffedcc' },
});
