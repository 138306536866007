import * as React from 'react';
import { ICommentary } from '../../../../commentaries/typings';
import { IMatchEventPenaltyShot } from '../../../../matchEvents/typings';
interface IProps {
  commentary: ICommentary;
}
export class CommentaryIcon extends React.PureComponent<IProps> {
  public render() {
    const { commentary } = this.props;
    const { matchEvent, type } = commentary;
    if (matchEvent) {
      const { name: eventName, type: eventType } = matchEvent;
      if (eventName === 'card') {
        if (eventType === 'yellow') {
          return <i className="icon icon-yellow-card" />;
        } else if (eventType === 'second_yellow') {
          return <i className="icon icon-yellow-2nd-card" />;
        } else if (eventType === 'red') {
          return <i className="icon icon-red-card" />;
        }
      } else if (eventName === 'goal') {
        return <i className="icon icon-goal" />;
      } else if (eventName === 'substitute') {
        return (
          <React.Fragment>
            <i className="icon icon-substitutein" />
            <i className="icon icon-substituteout" />
          </React.Fragment>
        );
      } else if (eventName === 'missed_penalty' && eventType === 'saved') {
        return <i className="icon icon-penalty-shootout-saved" />;
      } else if (eventName === 'missed_penalty' && eventType === 'missed') {
        return <i className="icon icon-penalty-shootout-missed" />;
      } else if (eventName === 'penalty_shot') {
        const result = (matchEvent as IMatchEventPenaltyShot).result;
        if (result === 'scored') {
          return <i className="icon icon-penalty-shootout-goal" />;
        } else if (result === 'missed') {
          return <i className="icon icon-penalty-shootout-missed" />;
        } else if (result === 'saved') {
          return <i className="icon icon-penalty-shootout-saved" />;
        }
      }
    }
    if (type === 'score') {
      return <i className="icon icon-score" />;
    } else if (type === 'action') {
      return <i className="icon icon-action" />;
    } else if (type === 'injury') {
      return <i className="icon icon-injury" />;
    } else if (type === 'statistic') {
      return <i className="icon icon-statistic" />;
    } else if (type === 'poll') {
      return <i className="icon icon-poll" />;
    } else if (type === 'formations') {
      return <i className="icon icon-formation" />;
    } else if (type === 'classement') {
      return <i className="icon icon-classement" />;
    } else if (type === 'broadcast') {
      return <i className="icon icon-tv-broadcast" />;
    } else if (type === 'bookmaker') {
      return <i className="icon icon-bet" />;
    } else if (type === 'additional_time') {
      return <i className="icon icon-additional_time" />;
    } else if (type === 'person') {
      return <i className="icon icon-person" />;
    } else if (type === 'player_of_the_match') {
      return <i className="icon icon-player-of-the-match" />;
    } else if (type === 'champion') {
      return <i className="icon icon-champion" />;
    } else if (type === 'timer') {
      return <i className="icon icon-timer" />;
    } else if (type === 'whistle') {
      return <i className="icon icon-whistle" />;
    } else if (type === 'quote') {
      return <i className="icon icon-quote" />;
    } else if (type === 'shirt') {
      return <i className="icon icon-shirt" />;
    } else if (type === 'var') {
      return <i className="icon icon-var" />;
    } else if (type === 'penalty') {
      return <i className="icon icon-penalty" />;
    } else if (type === 'text') {
      // We use icon-period-after-match of current css for all text commentaries
      return <i className="icon icon-period-after-match" />;
    }

    return null;
  }
}
