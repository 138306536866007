import rest from 'Services/restFootData';
import qs from 'query-string';
import { SubmissionError } from 'redux-form';

export function fetchClubs(search) {
  const params = qs.parse(search);

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUBS' });

    return rest
      .get('clubs', { params })
      .then(
        res => dispatch({ type: 'FETCH_CLUBS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_CLUBS_REJECTED', payload: err.response })
      );
  };
}

export function fetchClubsList(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read', '_image', '_country'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUBS' });

    return rest
      .get(`clubs/with_tournament/`, { params })
      .then(
        res => dispatch({ type: 'FETCH_CLUBS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_CLUBS_REJECTED', payload: err.response })
      );
  };
}

export function fetchClub(slug) {
  const params = {
    slug,
    groups: ['read', '_image', '_country'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUB' });

    return rest.get('clubs/with_tournament/', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_CLUB_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_CLUB_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_CLUB_REJECTED', payload: err.response })
    );
  };
}

export function getClubId(id) {
  return function(dispatch) {
    dispatch({ type: 'GET_CLUB' });

    return rest
      .get(`clubs/${id}`)
      .then(
        res => dispatch({ type: 'GET_CLUB_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_CLUB_REJECTED', payload: err.response })
      );
  };
}

export function addClub(club) {
  return function(dispatch) {
    dispatch({ type: 'ADD_CLUB' });

    return rest
      .post('clubs', club)
      .then(
        res => dispatch({ type: 'ADD_CLUB_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_CLUB_REJECTED', payload: err.response })
      );
  };
}

export function updateClub(id, club) {
  const params = {
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_CLUB', payload: id });
    return rest.put(`clubs/${id}`, club, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_clubUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_CLUB_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_CLUB_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function deleteClub(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_CLUB', payload: id });

    return rest
      .delete(`clubs/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_CLUB_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_CLUB_REJECTED', payload: err.response })
      );
  };
}

export function searchClubs(search) {
  const params = {
    targets: ['Club'],
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_CLUBS', payload: { search } });
    return rest
      .get(`search/${search}`, { params })
      .then(
        res => dispatch({ type: 'SEARCH_CLUBS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_CLUBS_REJECTED', payload: err.response })
      );
  };
}
