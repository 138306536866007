import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ISide } from '../../../../matches/typings';
import { IMembership } from '../../../../memberships/typings';
import { chooseCoachAction } from '../../actions';

const mapDispatchToProps = {
  chooseCoach: chooseCoachAction,
};
interface IOwnProps {
  coach: IMembership;
  side: ISide;
}

const mapStateToProps = () => ({});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
