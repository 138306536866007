import { Card, CardContent, CardMedia, IconButton, LinearProgress, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as React from 'react';
import { getProject } from '../../../../../common/services/project';
import { getSrc } from '../../../../images/tools';
import { getPersonUsualName } from '../../../../people/utils';
import { IPlayer } from '../../typings';
import { IComponentProps } from './PlayerCard.connector';

export class PlayerCard extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { selectedPlayer, person, isFetching } = this.props;
    if (
      !!selectedPlayer !== !!nextProps.selectedPlayer ||
      !!person !== !!nextProps.person ||
      isFetching !== nextProps.isFetching
    ) {
      return true;
    }
    if (
      selectedPlayer &&
      nextProps.selectedPlayer &&
      selectedPlayer.id !== nextProps.selectedPlayer.id
    ) {
      return true;
    }
    if (person && person.id !== nextProps.person.id) {
      return true;
    }
    return false;
  }

  public componentDidUpdate(prevProps: IComponentProps) {
    const { selectedPlayer, person, getPersonId } = this.props;
    if (selectedPlayer && !person) {
      if (
        !prevProps.selectedPlayer ||
        prevProps.selectedPlayer.id !== selectedPlayer.id
      ) {
        getPersonId(selectedPlayer.membership.personId);
      }
    }
  }

  public render() {
    const { classes, selectedPlayer, removePlayerCard, isFetching } = this.props;
    if (!selectedPlayer) return null;
    const { membership } = selectedPlayer;
    const image = getSrc(membership.person.photo);
    const usualName = getPersonUsualName(membership.person);

    return (
      <div>
        <Card className={classes.card}>
          {isFetching ? (
            <LinearProgress variant="query" className={classes.progress} />
          ) : null}
          {image ? (
            <CardMedia className={classes.cover} image={image} title={usualName} />
          ) : null}
          {this.renderContent()}
          <IconButton href={this.getHref()} target="_blank">
            <OpenInNewIcon />
          </IconButton>
          <IconButton onClick={removePlayerCard}>
            <CloseIcon />
          </IconButton>
        </Card>
      </div>
    );
  }

  private renderContent = () => {
    const {
      classes,
      selectedPlayer,
      person,
      intl: { messages: m },
    } = this.props;
    const { positionTranslated, shirtNumber, membership } = selectedPlayer as IPlayer;
    const usualName = getPersonUsualName(membership.person);

    const position = positionTranslated || membership.person.positionTranslated;
    const subText = position ? `${shirtNumber} / ${position}` : shirtNumber;

    return (
      <CardContent className={classes.content}>
        <Typography component="h5">{usualName}</Typography>
        <Typography color="textSecondary">{subText}</Typography>
        {person && person.age ? (
          <Typography color="textSecondary">{`${person.age} ${m.common_yearsOld}`}</Typography>
        ) : null}
        {person && person.mainNationality ? (
          <Typography color="textSecondary">{person.mainNationality.name}</Typography>
        ) : null}
      </CardContent>
    );
  };

  private getHref = (): string => {
    const { selectedPlayer } = this.props;
    return selectedPlayer
      ? `#${getProject().url}/players/${selectedPlayer.membership.person.slug}`
      : '';
  };
}
