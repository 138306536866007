import { addLocaleData } from 'react-intl';
import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';
import de from 'react-intl/locale-data/de';
import it from 'react-intl/locale-data/it';
import en from 'react-intl/locale-data/en';
import br from 'react-intl/locale-data/br';
import moment from 'moment';

// json trad to import
import messageFr from '../translations/fr.json';
import messageDe from '../translations/de.json';
import messageEs from '../translations/es.json';
import messageIt from '../translations/it.json';
import messageEn from '../translations/en.json';
import messageBr from '../translations/br.json';
const localeData = {
  fr: messageFr,
  de: messageDe,
  es: messageEs,
  it: messageIt,
  en: messageEn,
  br: messageBr,
};

addLocaleData([...fr, ...es, ...de, ...it, ...en, ...br]);
moment.locale('fr');

export default function reducer(
  state = {
    locale: 'fr',
    messages: localeData.fr,
  },
  action
) {
  switch (action.type) {
    case 'CHANGE_LOCALE': {
      setLocale(action.payload.locale);
      return {
        ...state,
        locale: action.payload.locale,
        messages: localeData[action.payload.locale],
      };
    }
    case 'FETCH_ME_FULFILLED': {
      const locale = action.payload.language;
      if (!locale || locale == state.locale) {
        return state;
      }
      setLocale(locale);
      return {
        ...state,
        locale,
        messages: localeData[locale],
      };
    }

    default:
      return state;
  }
}

function setLocale(locale) {
  moment.locale(locale);
}
