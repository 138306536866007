import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContentAdd from '@material-ui/icons/Add';
import filterList from 'Actions/lists';
import Layout from 'Components/Layout';
import AreaInput from 'FDComponents/areas/Input';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { getProject } from 'Services/project';
import queryReducer from 'Services/queriesReducer';
import debounce, { getLinkId } from 'Services/tools';
import { SearchFilter } from '../../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../../common/components/TableList';
import { UserRestriction } from '../../../../common/components/UserRestiction';
import PersonDialog from '../../Dialog';
import { addStaff, fetchStaffs } from '../actions';
import Row from './Row';

@injectIntl
@connect((store, props) => ({
  staffs: store.staffs.list,
  fetching: store.staffs.fetching,
  pagination: store.staffs.pagination,
  total: store.staffs.totalItems,
  query: parseQuery(props.location.search),
}))
export default class StaffsList extends Component {
  config = {
    filters: [
      {
        type: 'name',
        labelKey: 'filter_name',
      },
      {
        type: 'mainNationality',
        input: AreaInput,
        labelKey: 'filter_nationality',
      },
    ],
    headers: [
      { id: 'translations.lastName', labelKey: 'field_lastName' },
      {
        id: 'mainNationality.translations.name',
        labelKey: 'field_mainNationality',
      },
      { labelKey: 'field_club' },
      { id: 'birthDate', labelKey: 'field_birthDate' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.search === '') {
      this.changeParams({ type: 'SET_SORT', payload: 'translations.lastName' });
    }
    if (location.search !== '') {
      this.fetchData(location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (nextProps.location.search === '') {
      this.changeParams({ type: 'SET_SORT', payload: 'translations.lastName' });
    }

    if (
      prevProps.location.search !== nextProps.location.search &&
      nextProps.location.search !== ''
    ) {
      this.fetchData(nextProps.location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchStaffs(filter));
    dispatch(filterList('staffs', filter));
  };

  handleCreate = staff => {
    const { dispatch, history } = this.props;
    dispatch(addStaff(staff)).then(({ payload }) => {
      if (payload && payload.slug) {
        history.push(`${getProject().url}/staffs/${payload.slug}`);
      }
    });
  };

  handleFilters = change => {
    const { name, mainNationality } = change;
    const filtersQuery = {
      name,
      mainNationality: getLinkId(mainNationality),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);

    location.search != search && location.search === ''
      ? history.replace(`${getProject().url}/staffs${search}`)
      : history.push(`${getProject().url}/staffs${search}`);
  }, 500);

  render() {
    const {
      staffs,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="staffs-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_staffs' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_staffsList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <PersonDialog
                  title={m.dialog_addStaff}
                  form="image_add"
                  onCreate={this.handleCreate}
                >
                  <Button variant="contained" color="primary">
                    <ContentAdd style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </PersonDialog>
              </UserRestriction>
            </Toolbar>
            <SearchFilter
              form="players_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={staffs}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
