import { IDefaultFilters, IFilter } from '../../typings';

export const getDefaultInputsValue = (filters: IFilter[]): IDefaultFilters =>
  filters.reduce(
    (acc: IDefaultFilters, cur: IFilter) =>
      cur.defaultValue ? { ...acc, [cur.type]: cur.defaultValue } : acc,
    {}
  );

export const getInitialInputsValue = (filters: IFilter[]): IDefaultFilters =>
  filters.reduce(
    (acc: IDefaultFilters, cur: IFilter) =>
      cur.initialValue ? { ...acc, [cur.type]: cur.initialValue } : acc,
    {}
  );
