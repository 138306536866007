import { MarkdownUtil } from 'react-mde';

function setHeader(state0, api, prefix) {
  // Adjust the selection to encompass the whole word if the caret is inside one
  const newSelectionRange = MarkdownUtil.selectWord({
    text: state0.text,
    selection: state0.selection,
  });
  const state1 = api.setSelectionRange(newSelectionRange);
  // Add the prefix to the selection
  const state2 = api.replaceSelection(`${prefix}${state1.selectedText}`);
  // Adjust the selection to not contain the prefix
  api.setSelectionRange({
    start: state2.selection.end - state1.selectedText.length,
    end: state2.selection.end,
  });
}

export const headerCommand = {
  name: 'header',
  execute: (state, api) => setHeader(state, api, '### '),
};
