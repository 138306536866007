import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Form from './Form';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

@injectIntl
export default class Password extends Component {
  render() {
    const {
      onSubmit,
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_userPasswordTitle}</Typography>
          <div style={{ flex: 1 }} />
        </Toolbar>
        <Form onSubmit={onSubmit} data={{}} />
      </div>
    );
  }
}
