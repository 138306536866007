import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { getProject } from '../../../common/services/project';
import BrandDialog from '../BrandDialog';
import { IBrand } from '../typings';
import { BrandRow } from './BrandRow.component';
import { IComponentProps } from './BrandsList.connector';

export class BrandsList extends React.Component<IComponentProps> {
  private config = {
    headers: [{ labelKey: 'field_name' }],
  };

  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    const {
      brands,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="brands-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_brands' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_brandsList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <BrandDialog title={m.dialog_addBrand} form="brand_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </BrandDialog>
              </UserRestriction>
            </Toolbar>
            <TableList
              tableData={brands}
              tableHeaders={this.config.headers}
              query={query}
              Row={BrandRow}
              loading={fetching}
              pagination={pagination}
              total={total}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (disableCache?: boolean) => {
    const { fetchAllBrands } = this.props;
    fetchAllBrands(disableCache);
  };

  private submitAdd = (brand: Partial<IBrand>) => {
    const { addBrand, history } = this.props;

    // @ts-ignore
    addBrand(brand).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/brands/${payload.id}`);
        this.fetchData(true);
      }
    });
  };
}
