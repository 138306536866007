import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { addAuthor, fetchAuthors } from '../actions';
import filterList from 'Actions/lists';

import debounce from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';

import { TableList } from '../../../common/components/TableList';
import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';
import { getProject } from 'Services/project';

import Row from './Row';
import AuthorDialog from '../Dialog';
import TopBar from '../../components/TopBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { SearchFilter } from '../../../common/components/SearchFilter';

@injectIntl
@connect((store, props) => ({
  authors: store.authors.list,
  fetching: store.authors.fetching,
  pagination: store.authors.pagination,
  total: store.authors.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Authors extends Component {
  config = {
    filters: [
      {
        type: 'publicName',
        labelKey: 'filter_publicName',
      },
    ],
    headers: [
      { id: 'publicName', labelKey: 'field_publicName' },
      { labelKey: 'field_username' },
      { labelKey: 'field_roles' },
      { labelKey: 'field_twitterAccount' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchAuthors(filter));
    dispatch(filterList('authors', filter));
  };

  handleCreate = author => {
    const { dispatch, history } = this.props;
    dispatch(addAuthor(author)).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/authors/${payload.id}`);
      }
    });
  };

  handleFilters = change => {
    const { publicName } = change;
    const filtersQuery = {
      publicName,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/authors${search}`);
    }
  }, 500);

  render() {
    const {
      authors,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="authors-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_authors' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_authorsList}</Typography>
              <div style={{ flex: 1 }} />
              <AuthorDialog
                title={m.dialog_addAuthor}
                form="author_add"
                onCreate={this.handleCreate}
              >
                <Button variant="contained" color="primary">
                  <AddIcon style={{ marginRight: 8 }} />
                  {m.button_add}
                </Button>
              </AuthorDialog>
            </Toolbar>
            <SearchFilter
              form="authors_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={authors}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
