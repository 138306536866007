import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { InjectedIntlProps } from 'react-intl';
import { connect } from "react-redux";
import {IArticle} from '../../typings';
import { ArticleRelation } from '../ArticleRelation';

type IProps = {
    articleSource: IArticle;
    fetching: boolean;
} & InjectedIntlProps;

// @ts-ignore
@connect((store: any, props: any) => ({
    fetching: store.articleRelations.fetching
}))
export class ArticleRelations extends React.Component<IProps> {
    public render() {
        const {
            intl: { messages: m },
            articleSource,
            fetching
        } = this.props;

        return (
            <div>
                <Toolbar>
                    <Typography variant="h6">{m.app_articleRelated}</Typography>
                </Toolbar>
                <div style={{ padding: 16 }}>
                    {!fetching
                        ?  (<ArticleRelation articleSource={articleSource} />)
                        : null}
                </div>
            </div>
        );
    }
}
