import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchImages, addImage } from '../actions';
import filterList from 'Actions/lists';
import Toggle, { normalizeBooleanToInteger } from '../../../common/components/inputs/Toggle';
import FooterPagination from 'Components/TableList/FooterPagination';
import Table from '@material-ui/core/Table';
import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Subheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MediaDialog from 'Components/dialogs/Media';

import AddIcon from '@material-ui/icons/Add';

import debounce from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { SearchFilter } from '../../../common/components/SearchFilter';

const styles = theme => ({
  container: {
    padding: theme.spacing(1),
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  size: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    background: 'black',
    padding: '0 4px',
  },
});

@withStyles(styles)
@injectIntl
@connect((store, props) => ({
  images: store.images,
  pagination: store.images.pagination,
  total: store.images.totalItems,
  query: parseQuery(props.location.search),
}))
export default class ImageList extends Component {
  config = {
    filters: [
      {
        type: 'search',
        labelKey: 'filter_image',
      },
      {
        type: 'fuzzy',
        labelKey: 'filter_fuzzy',
        input: Toggle,
        normalize: normalizeBooleanToInteger,
        required: true,
        initialValue: 1,
      },
      {
        type: 'hdOnly',
        labelKey: 'filter_hd',
        input: Toggle,
        normalize: normalizeBooleanToInteger,
        required: true,
        initialValue: 0,
      },
    ],
  };

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    if (prevProps.location.search !== nextProps.location.search) {
      return this.fetchData(nextProps.location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchImages(filter));
    dispatch(filterList('images', filter));
  };

  submitAdd = values => {
    const { dispatch, history } = this.props;
    dispatch(addImage(values)).then(({ payload }) => {
      // redirection after post
      if (payload && payload.id) {
        history.push(`${getProject().url}/images/${payload.id}`);
      }
    });
  };

  handleFilters = change => {
    this.changeParams({ type: 'SET_FILTER', payload: change });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/images${search}`);
    }
  }, 500);

  render() {
    const {
      images,
      query,
      pagination,
      total,
      intl: { messages: m },
      classes,
    } = this.props;

    const w = window.innerWidth;
    let columns = 1;
    let cellHeight = 150;
    if (w > 600) {
      columns = 2;
      cellHeight = 160;
      if (w > 960) {
        columns = 4;
        cellHeight = 180;
        if (w > 1280) {
          columns = 5;
          cellHeight = 200;
        }
      }
    }
    return (
      <Layout
        id="images-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_images' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_imagesList}</Typography>
              <div style={{ flex: 1 }} />
              <MediaDialog title={m.dialog_addMedia} form="image_add" onCreate={this.submitAdd}>
                <Button variant="contained" color="primary">
                  <AddIcon className={classes.icon} />
                  {m.button_add}
                </Button>
              </MediaDialog>
            </Toolbar>
            <SearchFilter
              form="images_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <div className={classes.container}>
              <GridList cellHeight={cellHeight} className={classes.gridList} cols={columns}>
                <GridListTile key="Subheader" cols={columns} style={{ height: 'auto' }}>
                  <Subheader component="div">{m.app_images}</Subheader>
                </GridListTile>
                {images &&
                  images.list.map(tile => (
                    <GridListTile key={tile.filename} component="a" href={`#${getProject().url}/images/${tile.id}`}>
                      <img src={tile.originalUrl} alt={tile.filename} />
                      {tile.width ? (
                        <Typography
                          variant="caption"
                          className={classes.size}
                        >{`${tile.width}x${tile.height}`}</Typography>
                      ) : null}
                    </GridListTile>
                  ))}
              </GridList>
              <Table>
                <FooterPagination
                  pagination={pagination}
                  total={total}
                  onPaginate={this.handlePagination}
                  defaultItemPerPage={50}
                />
              </Table>
            </div>
          </Paper>
        }
      />
    );
  }
}
