import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import ContentAdd from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce from '../../../common/services/tools';
import { AgentCompanyRow } from '../components/AgentCompanyRow.component';
import CompanyDialog from '../components/Dialog';
import { IComponentProps } from './AgentCompaniesList.connector';

export class AgentCompaniesList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'name',
        labelKey: 'filter_name',
      },
    ],
    headers: [{ id: 'name', labelKey: 'field_name' }],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/agent-companies${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IComponentProps) {
    const { location } = this.props;
    if (location.search !== nextProps.location.search) {
      this.fetchData(nextProps.location.search);
    }
  }

  public render() {
    const {
      agentCompanies,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="agentCompanies-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_agentCompanies' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_agentCompaniesList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <CompanyDialog
                  title={m.dialog_addAgentCompany}
                  form="agentCompanyForm"
                  onCreate={this.handleCreate}
                >
                  <Button variant="contained" color="primary">
                    <ContentAdd style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </CompanyDialog>
              </UserRestriction>
            </Toolbar>
            <SearchFilter
              form="agentCompanies_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={agentCompanies}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={AgentCompanyRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchAgentCompanies, filterList } = this.props;
    fetchAgentCompanies(filter);
    filterList('agent-companies', filter);
  };

  private handleCreate = company => {
    const { addAgentCompany } = this.props;
    addAgentCompany(company, this.props.history.push);
  };

  private handleFilters = (change: any) => {
    const { name } = change;
    const filtersQuery = {
      name,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
}
