import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from '../../../common/services/project';
interface IProps {
  tableData: any;
}
export class AgentCompanyRow extends Component<IProps> {
  public render() {
    const { tableData } = this.props;
    const { name } = tableData;
    const href = `#${getProject().url}/agent-companies/${tableData.id}`;

    return (
      // @ts-ignore href
      <TableRow hover href={href} component="a">
        <TableCell>{name}</TableCell>
      </TableRow>
    );
  }
}
