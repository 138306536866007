import Button from "@material-ui/core/Button";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { Component } from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {getProject} from "../../../common/services/project";
import {fetchQuizzes,translateQuizz} from "../actions";

import { IQuizz } from '../typings';

interface IProps {
    tableData: IQuizz;
    intl: any;
    dispatch: any;
}

// @ts-ignore
@injectIntl
// @ts-ignore
@connect()
export class QuizRow extends Component<IProps> {
    public render() {
        const {
            tableData,
            intl: { messages: m },
        } = this.props;
        const { startDate, endDate, totalParticipantsCount, mainLocale, translations } = tableData;

        return (
            // @ts-ignore href
            <TableRow hover href={this.getHref(tableData)} component="a">
                <TableCell>
                    {translations[getProject().lang]
                        ? translations[getProject().lang].title
                        : translations[mainLocale].title}
                </TableCell>
                <TableCell>{this.getIsAvailable() ? m.common_yes : m.common_no}</TableCell>
                <TableCell>
                    {translations[getProject().lang] && translations[getProject().lang].isEnabled ? m.common_yes : m.common_no}
                </TableCell>
                <TableCell>{this.formatDate(startDate)}</TableCell>
                <TableCell>{this.formatDate(endDate)}</TableCell>
                <TableCell>{this.getTranslatedLocales()}</TableCell>
                <TableCell>
                    {translations[getProject().lang] && translations[getProject().lang].hasPartner ? m.common_yes : m.common_no}
                </TableCell>
                <TableCell>{totalParticipantsCount}</TableCell>
                <TableCell>{this.renderTranslationButton(tableData)}</TableCell>
            </TableRow>
        );
    }

    private formatDate(date) {
        if (!date) return '-';

        return `${moment(date).format('L')} - ${moment(date).format('LT')}`;
    }

    private handleClick = () => {
        const { dispatch, tableData } = this.props;
        dispatch(translateQuizz(tableData.id)).then(dispatch(fetchQuizzes()));
    };

    private renderTranslationButton = ({ mainLocale, translations }) => {
        const {
            intl: { messages: m },
        } = this.props;
        if (mainLocale === getProject().lang || translations[getProject().lang]) {
            return '-';
        }

        return (
            <Button onClick={this.handleClick} variant="contained" color="primary" data-test="ArticleList__buttonAdd">
                {m.field_translate}
            </Button>
        );
    };

    private getTranslatedLocales() {
        const { tableData } = this.props;
        const locales = Object.keys(tableData.translations).filter(locale => locale !== getProject().lang);
        if (locales.length === 0) return '-';

        return locales.join(', ').toUpperCase();
    }

    private getHref({ id, mainLocale, translations }) {
        if (mainLocale === getProject().lang || translations[getProject().lang]) {
            return `#${getProject().url}/quizzes/${id}`;
        }

        return null;
    }

    private getIsAvailable() {
        const { tableData } = this.props;
        if (!tableData.translations[getProject().lang]) return false;
        if (!tableData.translations[getProject().lang].isEnabled) return false;
        if (tableData.endDate && moment(tableData.endDate).isBefore(moment())) return false;
        if (moment(tableData.startDate).isAfter(moment())) return false;

        return true;
    }
}
