import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Detail from './Detail';

export default function HighlightingRoutes({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}/`} component={Detail} />
    </Switch>
  );
}
