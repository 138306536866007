import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import AreaInput from 'FDComponents/areas/Input';
import ImageInput from '../../images/Input';
import PresidentInput from '../../people/presidents/Input';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'name',
      'shortName',
      'colors',
      'budget',
      'twitterLink',
      'facebookLink',
      'instagramLink',
      'websiteLink',
      'slug',
    ],
    objectFields: ['country', 'logo', 'president'],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'logo', component: ImageInput }],
      },
      {
        size: 9,
        fields: [
          { name: 'name', component: TextInput },
          { name: 'slug', component: TextInput, disabled: true },
          { name: 'shortName', component: TextInput },
        ],
      },
      {
        fields: [
          { name: 'country', component: AreaInput, size: 6 },
          { name: 'president', component: PresidentInput, size: 6 },
          { name: 'colors', component: TextInput, size: 6 },
          {
            name: 'budget',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
        ],
      },
      {
        size: 6,
        title: 'form_socialLinks',
        fields: [
          {
            name: 'facebookLink',
            component: TextInput,
            hintText: 'https://www.facebook.com/user',
          },
          {
            name: 'twitterLink',
            component: TextInput,
            hintText: 'https://twitter.com/user',
          },
          {
            name: 'instagramLink',
            component: TextInput,
            hintText: 'https://instagram.com/user',
          },
          {
            name: 'websiteLink',
            component: TextInput,
            hintText: 'http://www.link.com',
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`club_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
