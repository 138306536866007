import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { getSrc } from '../images/tools';

export default class ClubAvatar extends Component {
  render() {
    const { club = {} } = this.props;

    return club.logo ? (
      <Avatar src={club && getSrc(club.logo, 40)} alt={club.name} />
    ) : (
      <Avatar>{club.code}</Avatar>
    );
  }
}
