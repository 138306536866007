import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { fetchMatch } from '../../../../matches/actions';
import { selectMatch } from '../../../../matches/selector';
import { ISide } from '../../../../matches/typings';
import { updateMatchTeam } from '../../../../matchTeams/actions';
import { IMatchTeam } from '../../../../matchTeams/typings';
import { styles } from './styles';

const mapDispatchToProps = {
  updateMatchTeam,
  fetchMatch,
};
interface IOwnProps {
  side: ISide;
}
const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const match = selectMatch(state, state.live.matchId);
  if (!match || !match.matchTeams) return {};

  const matchTeam = match.matchTeams[ownProps.side] as IMatchTeam;

  return {
    matchId: state.live.matchId,
    matchTeamId: matchTeam.id,
    isLineupOfficial: matchTeam.isLineupOfficial,
    fetching: state.match.fetching || state.matchTeam.updating,
    mainCommentatorLocale: state.live.mainCommentatorLocale
  };
};

export type IComponentProps = WithStyles &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
