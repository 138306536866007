import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from '../../../../common/services/project';
import { IPartnerOffer } from '../../typings';
import {getCompetitionLabel} from "../../../utils/competition";

interface IProps {
  tableData: IPartnerOffer;
}

export class PartnerOfferRow extends Component<IProps> {
  public render() {
    const { tableData } = this.props;
    const { id, partner, shortText, usedForTranslated, locale, competition } = tableData;

    return (
      // @ts-ignore href
      <TableRow hover href={`#${getProject().url}/partners/${partner.id}/partner-offers#${id}`} component="a">
        <TableCell>{partner.name}</TableCell>
        <TableCell>{shortText}</TableCell>
        <TableCell>{usedForTranslated}</TableCell>
        <TableCell>{locale}</TableCell>
        <TableCell>{competition ? getCompetitionLabel(competition) : '-'}</TableCell>
      </TableRow>
    );
  }
}
