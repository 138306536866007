import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { renderImg } from '../../../../images/tools';
import { getClubDetailHref } from '../../../../utils/href';
import { Chrono } from '../Chrono';
import { PeriodSelector } from '../PeriodSelector';
import { IComponentProps } from './ScoreBoard.connector';
import { formatDate } from './utils';

export class ScoreBoard extends React.Component<IComponentProps> {
  public render() {
    const { game, classes, homeTeam, awayTeam } = this.props;
    if (!game) return null;
    const { date } = game;
    const venue = this.getVenueLabel();

    return (
      <div>
        <div className={classes.container}>
          {this.renderTeam(homeTeam)}
          <div>
            <div className={classes.infoContainer}>
              <Typography className={classes.info}>{formatDate(date)}</Typography>
            </div>
            <div className={classes.infoContainer}>
              <Typography className={classes.info2}>{venue}</Typography>
            </div>
            {this.renderScores()}
            {this.renderShootoutsScores()}
          </div>
          {this.renderTeam(awayTeam)}
        </div>
        <div className={classes.container}>
          <PeriodSelector />
          <Chrono />
        </div>
      </div>
    );
  }

  private getVenueLabel = () => {
    const { game } = this.props;
    if (!game || !game.venue) return '';
    return game.venue.name;
  };

  private renderTeam = team => {
    const { classes } = this.props;

    if (!team || !team.club) return null;
    const href = getClubDetailHref(team.club);
    return (
      <div className={classes.teamContainer}>
        {team.club.logo ? <a href={href}>{renderImg(team.club.logo, 64)}</a> : null}
        <Typography className={classes.team} variant="h5" component="h2" align="center">
          <a className={classes.link} href={href}>
            {team.shortName}
          </a>
        </Typography>
      </div>
    );
  };

  private renderScores = () => {
    const { classes, game } = this.props;
    if (!game || !game.scores) return null;
    return (
      <div className={classes.infoContainer}>
        <Typography className={classes.score} variant="h5" component="h2" align="center">
          {game.scores.home}
        </Typography>
        <Typography className={classes.score} variant="h5" component="h2" align="center">
          {game.scores.away}
        </Typography>
      </div>
    );
  };

  private renderShootoutsScores = () => {
    const { classes, game } = this.props;
    if (!game || !game.matchPeriods || !game.matchPeriods['5_penalties']) return null;
    const scores = game.matchPeriods['5_penalties'].scores;
    if (!scores) return null;

    return (
      <div>
        <Typography align="center">{'TAB'}</Typography>
        <Typography
          className={classes.shootoutScore}
          variant="h5"
          component="h2"
          align="center"
        >
          {`${scores.home} - ${scores.away}`}
        </Typography>
      </div>
    );
  };
}
