import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import FooterPagination from '../../../../common/components/TableList/FooterPagination';
import { ArticleRow } from '../../List/ArticleRow.component';
import { IComponentProps } from './index';

export class TablePublished extends Component<IComponentProps> {
  public render() {
    const {
      collection,
      defaultItemPerPage,
      onPaginate,
      intl: { messages: m },
    } = this.props;
    const { list = [], pagination, totalItems } = collection;

    if (list.length == 0) {
      return (
        <Typography style={{ padding: 24 }} variant="body1">
          {m.error_noData}
        </Typography>
      );
    }

    return (
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell size="small">{m.field_status}</TableCell>
              <TableCell size="small">{m.field_title}</TableCell>
              <TableCell size="small">{m.field_authors}</TableCell>
              <TableCell size="small">{m.field_tags}</TableCell>
              <TableCell size="small">{m.field_publishedAt}</TableCell>
              <TableCell size="small">{m.field_unpublishedAt}</TableCell>
              <TableCell size="small">{m.app_statistics}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(g => (
              <ArticleRow tableData={g} />
            ))}
          </TableBody>
          <FooterPagination
            pagination={pagination}
            total={totalItems}
            onPaginate={onPaginate}
            defaultItemPerPage={defaultItemPerPage}
          />
        </Table>
      </div>
    );
  }
}
