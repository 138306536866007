import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { BrandForm } from '../components/BrandForm';
import { IBrand } from '../typings';
import { IComponentProps } from './BrandDetail.connector';

export class BrandDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_info', link: '/' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      brand,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!brand) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="brand-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[{ labelKey: 'app_brands', href: `#${getProject().url}/brands` }, { label: brand.name }]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{brand.name}</Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="brands" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { brand } = this.props;
    return <BrandForm onSubmit={this.handleUpdate} data={brand} />;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchBrand } = this.props;
    fetchBrand(targetId || id);
  };

  private handleUpdate = (brand: IBrand) => {
    const { id, updateBrand } = this.props;
    return updateBrand(id, brand);
  };
}
