import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// @ts-ignore
import { withRouter } from 'react-router';
import { getProject } from '../../../common/services/project';
import { IMercatoSurvey } from '../typings';

interface IProps {
  tableData: IMercatoSurvey;
  intl: any;
  dispatch: any;
  history: any;
}

// @ts-ignore
@injectIntl
// @ts-ignore
@connect()
// @ts-ignore
@withRouter
export class MercatoSurveyRow extends Component<IProps> {
  public render() {
    const {
      tableData,
      intl: { messages: m },
    } = this.props;

    return (
      // @ts-ignore href
      <TableRow hover href={this.getHref(tableData.id)} component="a">
        <TableCell>{tableData.tournament.competitionName}</TableCell>
        <TableCell>{tableData.typeTranslated}</TableCell>
        <TableCell>{tableData.isActive ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{tableData.isEnabled ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{this.formatDate(tableData.startDate)}</TableCell>
        <TableCell>{this.formatDate(tableData.endDate)}</TableCell>
      </TableRow>
    );
  }

  private formatDate(date) {
    if (!date) return '-';

    return `${moment(date).format('DD/MM/YY')} - ${moment(date).format('hh:mm:ss')}`;
  }

  private getHref(id: string) {
    return `#${getProject().url}/mercatosurveys/${id}`;
  }
}
