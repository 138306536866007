import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { WrappedFieldProps } from 'redux-form';
import { IFormField } from '../FormEdit/typings';

type IProps = InjectedIntlProps & IFormField & WrappedFieldProps;

// @ts-ignore
@injectIntl
export class LocaleSelect extends React.Component<IProps> {
  public render() {
    const {
      input: { name, value },
      meta: { touched, error } = {},
      intl: { messages: m },
      labelKey,
      ...custom
    } = this.props;
    const label = m[labelKey ? labelKey : `field_${name}`];

    return (
      <FormControl fullWidth error={Boolean(touched && error)}>
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        <Select
          {...custom}
          name={name}
          value={value}
          onChange={this.onChange}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          <MenuItem value="en">
            <i className="flag gbr" />
            <span style={{ marginLeft: 8 }}>{m.locale_en}</span>
          </MenuItem>
          <MenuItem value="fr">
            <i className="flag fra" />
            <span style={{ marginLeft: 8 }}>{m.locale_fr}</span>
          </MenuItem>
          <MenuItem value="es">
            <i className="flag esp" />
            <span style={{ marginLeft: 8 }}>{m.locale_es}</span>
          </MenuItem>
          <MenuItem value="de">
            <i className="flag deu" />
            <span style={{ marginLeft: 8 }}>{m.locale_de}</span>
          </MenuItem>
          <MenuItem value="it">
            <i className="flag ita" />
            <span style={{ marginLeft: 8 }}>{m.locale_it}</span>
          </MenuItem>
          <MenuItem value="br">
            <i className="flag bra" />
            <span style={{ marginLeft: 8 }}>{m.locale_br}</span>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  private onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.props.input.onChange(event.target.value);
  };
}
