import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Detail from './Detail';

export function PhasesRoutes({ match }) {
  return (
    <Switch>
      <Route exact path={match.url} />
      <Route path={`${match.url}/:topicId`} component={Detail} />
    </Switch>
  );
}
