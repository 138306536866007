import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';

export default class Form extends Component {
  config = {
    fields: ['id', 'objectClass', 'field', 'correctedValue', 'inputValue'],
    disposition: [
      {
        fields: [
          {
            name: 'objectClass',
            component: TextInput,
            size: 6,
            disabled: true,
          },
          { name: 'field', component: TextInput, size: 6, disabled: true },
          {
            name: 'correctedValue',
            component: TextInput,
            size: 6,
            disabled: true,
          },
          { name: 'inputValue', component: TextInput, size: 6, disabled: true },
        ],
      },
    ],
  };

  render() {
    const { data } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`correction_${data.id}`}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
