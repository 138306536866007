import { getProject } from 'Services/project';
import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import React, { PureComponent } from 'react';
import { injectIntl } from "react-intl";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
    leftButton: {
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
});

@injectIntl
@withStyles(styles)
export class ButtonShare extends PureComponent {
  state = {
    url: '',
    text: '',
  };
  componentDidMount() {
    const { id, slug , title , specifity } = this.props;
    const url = `${getProject().baseUrlFM}/a${id}-${slug}`;
    const text = `${title} - ${specifity}`;
    this.setState({ url, text });
  }

  render() {
      const {
          classes,
          intl: { messages: m },
      } = this.props;
    const { url, text } = this.state;

    return (
        <React.Fragment>
            <Button
                variant="contained"
                className={classes.leftButton}
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${url}%3Futm_source%3DFacebook%26utm_medium%3Dsocial%26utm_campaign%3Dshare_button`}
            >
                <FacebookIcon className={classes.leftIcon} />
                {m.button_share}
            </Button>
          <Button
              variant="contained"
              className={classes.leftButton}
              target="_blank"
              href={`https://twitter.com/intent/tweet?text=${text}%20url=${url}%3Futm_source%3DTwitter%26utm_medium%3Dsocial%26utm_campaign%3Dshare_button`}
          >
              <TwitterIcon className={classes.leftIcon} />
              {m.button_share}
          </Button>
        </React.Fragment>
    );
  }
}
