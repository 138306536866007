import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IdBadge } from 'Components/IdBadge';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import Revisions from 'Components/revisions';
import RevisionNote from 'Components/revisions/Note';
import TabsRoute from 'Components/TabsRoute';
import Breadcrumb from 'FDComponents/Breadcrumb';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getCollection, getItemBySlug } from '../../../common/services/connectors';
import { fetchCompetitionTournaments } from '../../tournaments/actions';
import { fetchCompetition, updateCompetition } from '../actions';
import { fetchCompetitionProducts } from '../../products/actions';
import Form from './Form';
import Tournaments from './Tournaments';
import { Products } from './Products';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  competition: getItemBySlug(store.competition, props.match.params.topicSlug),
  area: props.match.params.topicArea,
  fetching: store.competition.fetching,
  tournaments: getCollection('tournamentsIds', store.competition, store.tournament),
}))
export default class Detail extends Component {
  state = {
    showRevisions: false,
  };

  menu = [
    { label: 'app_competitionInfo', link: '/' },
    { label: 'app_products', link: '/products' },
  ];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug || next.area != this.props.area) {
      this.fetchData(next.slug, next.area);
    }
  }

  shouldComponentUpdate(next) {
    if (this.props.slug != next.slug) {
      return true;
    }
    if (this.props.competition != next.competition) {
      return true;
    }
    if (this.props.area != next.area) {
      return true;
    }
    if (this.props.tournaments != next.tournaments) {
      if (
        this.props.tournaments &&
        next.tournaments &&
        this.props.tournaments.length !== next.tournaments.length
      ) {
        return true;
      }
    }
    return false;
  }

  fetchData = (targetSlug, targetArea) => {
    const { slug, area, dispatch } = this.props;
    dispatch(fetchCompetition(targetSlug || slug, targetArea || area)).then(
      ({ payload }) => {
        if (payload) {
          dispatch(fetchCompetitionTournaments(payload.id));
          dispatch(fetchCompetitionProducts(payload.id));
        }
      }
    );
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handleCompetitionChange = competition => {
    const { dispatch } = this.props;
    return dispatch(updateCompetition(competition.id, competition));
  };

  handleFetchData = () => {
    this.fetchData();
  };

  render() {
    const {
      fetching,
      competition,
      tournaments,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!competition) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="competition-layout"
        top={<Breadcrumb />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{competition.name}</Typography>
              <IdBadge id={competition.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="competitions" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route
              exact
              path={match.url}
              render={() => (
                <div>
                  <div>
                    <RevisionNote
                      style={{ margin: 16 }}
                      createdAt={competition.createdAt}
                      updatedAt={competition.updatedAt}
                      toggle={this.toggleRevisions}
                    />
                    {this.state.showRevisions && (
                      <Revisions
                        entity="competition"
                        id={competition.id}
                        updatedAt={competition.updatedAt}
                        onRollback={() => this.fetchData()}
                      />
                    )}
                  </div>
                  <Form onSubmit={this.handleCompetitionChange} data={competition} />
                </div>
              )}
            />
            <Route
              path={`${match.url}/products`}
              render={() => (
                <div>
                  <Products tournament={tournaments} fetchData={this.handleFetchData} />
                </div>
              )}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Tournaments tournaments={tournaments} />
          </Paper>
        }
      />
    );
  }
}
