import {Fab} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import {Alert} from "@material-ui/lab";
import React from 'react';
import {Field, FieldArray, WrappedFieldArrayProps} from "redux-form";
import IntInput, {normalizeInt} from "../../../../common/components/inputs/Integer";
import SelectConstant from "../../../components/constants/Select";
import ArticleInput from "../ArticleInput";
import {IComponentProps} from "./ArticleRelation.connector";

interface IComponentState {
    initialValues: any
}


export class ArticleRelation extends React.Component<IComponentProps,IComponentState> {

    public render() {
        const {
            intl: { messages: m },
            handleSubmit,
            classes,
        } = this.props;
        return (
            // @ts-ignore
            <form onSubmit={handleSubmit(this.handleSubmit)} >
                <FieldArray name="articleRelations" component={this.renderArticleRelations} />
                <div className={classes.fabContainer}>
                    <Fab
                        data-test={`articleRelation__buttonSubmit`}
                        color="primary"
                        aria-label={m.button_validate}
                        type="submit"
                    >
                        <SaveIcon />
                    </Fab>
                </div>
            </form>
        );
    }

    private handleSubmit = (values) => {
        const {articleRelations} = this.props;
        const {articleRelations: formArticleRelations} = values;

        const articleRelationsToDelete = articleRelations.filter(articleRelation => {
            return !formArticleRelations.find(formArticleRelation => formArticleRelation.id === articleRelation.id)
        });
        const articleRelationsToCreate = formArticleRelations.filter(formArticleRelation => {
            return formArticleRelation.id === null
        });
        const articleRelationsToUpdate = formArticleRelations.filter(formArticleRelation => {
            return articleRelations.find(articleRelation => articleRelation.id === formArticleRelation.id)
        });

        articleRelationsToDelete.forEach(articleRelation => {
            this.props.deleteArticleRelation(articleRelation.id);
        });
        articleRelationsToCreate.forEach(articleRelation => {
            this.props.addArticleRelation(articleRelation);
        });
        articleRelationsToUpdate.forEach(articleRelation => {
            this.props.updateArticleRelation(articleRelation);
        });

        setTimeout(() => {
            this.props.fetchArticle(this.props.articleSource.id);
            this.props.fetchArticleRelations(this.props.articleSource.id);
        }, 500)


    };
    private renderArticleRelations: React.FC<WrappedFieldArrayProps<any>> = ({ fields, meta: { error  } }) => {
        const {
            intl: { messages: m, formatMessage: f },
        } = this.props;
        return (
            <Grid container item xs={12} sm={12} spacing={2} key={1}>
                    {fields.map((member, index) => (
                        <Grid container item xs={12} sm={12} key={index}>
                            <Grid  item xs={12} sm={6} key={`${member}.articleLinked`}>
                                <Field
                                    name={`${member}.articleLinked`}
                                    required={true}
                                    component={ArticleInput}
                                />
                            </Grid>
                            <Grid  item xs={12} sm={2} key={`${member}.type`}>
                                <Field
                                    labelKey="field_type"
                                    name={`${member}.type`}
                                    required={true}
                                    component={SelectConstant}
                                    constantName={'type'}
                                    entity="articlesRelation"
                                    size={2}
                                />
                            </Grid>
                            <Grid  item xs={12} sm={1} key={`${member}.ordering`}>
                                <Field
                                    labelKey="field_ordering"
                                    name={`${member}.ordering`}
                                    required={true}
                                    component={IntInput}
                                    size={1}
                                    normalize={normalizeInt}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1} key={`${member}.delete`} style={{ marginLeft: 8 }}>
                                <IconButton  onClick={() => fields.remove(index)} color="secondary" data-test="ArticleList__buttonAdd">
                                    <DeleteIcon style={{ marginRight: 0 }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                <Grid container item xs={12} sm={12}>
                    {error && <Alert severity="error">{f({id: error})}</Alert>}
                </Grid>
                <Grid container item xs={12} sm={6}>
                    <Button variant="contained" onClick={this.addArticleRelationRow(fields)} color="primary" data-test="ArticleList__buttonAdd">
                        <AddIcon style={{ marginRight: 8 }} />
                        {m.button_add}
                    </Button>
                </Grid>
            </Grid>

        );

    };

    private addArticleRelationRow(fields) {
        return () => {
            fields.push({
                id: null,
                articleLinked: null,
                type: null,
                ordering: null,
                articleSource: this.props.articleSource['@id'],
            })};
    }

}
