import TextField from '@material-ui/core/TextField';
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

function TimeInput({
  input: { value, ...input },
  labelKey,
  intl: { formatMessage: f },
  meta: { touched, error },
  hintText,
  helperKey,
}) {
  const label = labelKey
    ? f({ id: labelKey })
    : f({ id: `field_${input.name}` });

  const now = new Date();
  const timeData = value.split(':');
  const localDateValue = moment(
      Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), timeData[0], timeData[1])
  ).format("HH:mm");

  return (
    <TextField
      label={label}
      value={localDateValue}
      error={Boolean(touched && error)}
      helperText={(touched && error) || (helperKey && f({ id: helperKey }))}
      type="time"
      InputLabelProps={{
        shrink: true,
        placeholder: hintText || label,
      }}
      {...input}
      fullWidth
    />
  );
}
export default injectIntl(TimeInput);

export function formatUtc(datetime) {
  if (!datetime) return null;

  return moment(datetime, 'HH:mm')
      .utc()
      .format("HH:mm");
}
