import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import * as React from 'react';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { IComponentProps } from './MatchTeamPlayerCell.connector';

export class MatchTeamPlayerCell extends React.Component<IComponentProps> {
  public render() {
    const { classes, title} = this.props;
    return <div className={classes.container} title={title}>{this.renderContent()}</div>;
  }

  private renderContent = () => {
    const {
      matchTeamPlayer,
      disabled,
      selectedMatchTeamPlayer,
      isSelectedMatchTeamPlayer,
      isSubtitute,
    } = this.props;
    if (disabled) return null;

    const shouldShowAction =
      !isSubtitute || !selectedMatchTeamPlayer || !selectedMatchTeamPlayer.onBench;

    if (!matchTeamPlayer) {
      return selectedMatchTeamPlayer && shouldShowAction
        ? this.renderAddButton()
        : this.renderEmpty();
    }

    if (isSelectedMatchTeamPlayer) return this.renderSelectedPlayer(matchTeamPlayer);
    if (selectedMatchTeamPlayer && shouldShowAction) {
      return this.renderSwapPlayer(matchTeamPlayer);
    }
    return this.renderPlayer(matchTeamPlayer);
  };

  private renderEmpty = () => {
    const { side, classes } = this.props;
    const sideClass = side === 'home' ? classes.homeEmptyCell : classes.awayEmptyCell;
    const className = `${classes.cell} ${sideClass}`;
    return <div className={className} />;
  };

  private renderSwapPlayer = (matchTeamPlayer: IMatchTeamPlayer) => {
    const { side, classes, onSwap } = this.props;
    const sideClass = side === 'home' ? classes.homeCell : classes.awayCell;
    const containerClassName = `${classes.cell} ${sideClass}`;
    const labelClassName = `${classes.backLabel} ${classes.button}`;
    const label = this.getLabel(matchTeamPlayer);

    return (
      <div className={containerClassName}>
        <Button disabled className={labelClassName}>
          {label}
        </Button>
        <Button onClick={onSwap} className={classes.button}>
          <SwapHorizIcon />
        </Button>
      </div>
    );
  };

  private renderAddButton = () => {
    const { side, classes, onAdd } = this.props;
    const sideClass = side === 'home' ? classes.homeEmptyCell : classes.awayEmptyCell;
    const className = `${classes.cell} ${sideClass}`;

    return (
      <div className={className}>
        <Button onClick={onAdd} className={classes.button}>
          <AddIcon />
        </Button>
      </div>
    );
  };

  private renderSelectedPlayer = (matchTeamPlayer: IMatchTeamPlayer) => {
    const { side, classes, onDelete } = this.props;
    const sideClass = side === 'home' ? classes.homeCell : classes.awayCell;
    const containerClassName = `${classes.cell} ${sideClass}`;
    const labelClassName = `${classes.backLabel} ${classes.button}`;
    const label = this.getLabel(matchTeamPlayer);

    return (
      <div className={containerClassName}>
        <Button disabled className={labelClassName}>
          {label}
        </Button>
        <Button onClick={this.onCancel} className={classes.button}>
          <CancelIcon />
        </Button>
        <Button onClick={onDelete} className={classes.button}>
          <DeleteIcon />
        </Button>
      </div>
    );
  };

  private renderPlayer = (matchTeamPlayer: IMatchTeamPlayer) => {
    const { side, classes } = this.props;
    const sideClass = side === 'home' ? classes.homeCell : classes.awayCell;
    const className = `${classes.cell} ${sideClass}`;
    const label = this.getLabel(matchTeamPlayer);
    return (
      <div className={className}>
        <Button onClick={this.onSelect} className={classes.button}>
          {label}
        </Button>
      </div>
    );
  };

  private onCancel = () => {
    const { side, unselectMatchTeamPlayer } = this.props;
    unselectMatchTeamPlayer(side);
  };

  private onSelect = () => {
    const { selectMatchTeamPlayer, side, matchTeamPlayer } = this.props;
    if (matchTeamPlayer) {
      selectMatchTeamPlayer(matchTeamPlayer, side);
    }
  };

  private getLabel = (matchTeamPlayer: IMatchTeamPlayer) =>
    `${matchTeamPlayer.shirtNumber} ${matchTeamPlayer.membership.personMatchName}`;

}
