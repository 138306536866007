import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

export function updateClubSponsor(id, sponsor) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_CLUBSPONSOR', payload: id });

    return rest.put(`club_sponsors/${id}`, sponsor).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_sponsorUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_CLUBSPONSOR_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_CLUBSPONSOR_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addClubSponsor(sponsor, target = '') {
  const entity = target ? `_${target.toUpperCase()}` : target;
  return function(dispatch) {
    dispatch({ type: `ADD${entity}_CLUBSPONSOR` });

    return rest.post('club_sponsors', sponsor).then(
      res =>
        dispatch({
          type: `ADD${entity}_CLUBSPONSOR_FULFILLED`,
          payload: res.data,
        }),
      err =>
        dispatch({
          type: `ADD${entity}_CLUBSPONSOR_REJECTED`,
          payload: err.response,
        })
    );
  };
}

export function deleteClubSponsor(sponsor) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_CLUBSPONSOR' });

    return rest.delete(`club_sponsors/${sponsor.id}`).then(
      () =>
        dispatch({ type: 'DELETE_CLUBSPONSOR_FULFILLED', payload: sponsor }),
      err =>
        dispatch({
          type: 'DELETE_CLUBSPONSOR_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchClubSponsors(club) {
  const params = { club };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUB_CLUBSPONSORS', payload: club });

    return rest.get('club_sponsors', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_CLUB_CLUBSPONSORS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_CLUB_CLUBSPONSORS_REJECTED',
          payload: err.response,
        })
    );
  };
}
