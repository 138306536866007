import { Card, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { IComponentProps } from './Commentary.connector';
import { ActionButtons } from './components/ActionButtons';
import { Commentaries } from './components/Commentaries';
import { ContentEditor } from './components/ContentEditor';
import { ParamsEditor } from './components/ParamsEditor';
import { PlayersSelector } from './components/PlayersSelector';
import { ShortcutHandler } from './components/ShortcutHandler';
import { TeamPanel } from './components/TeamPanel';
import { TypeSelector } from './components/TypeSelector';

const styleSheet = (theme: Theme) => ({
  container: {
    display: 'flex',
    background: 'white',
    padding: 8,
  },
  paper: { border: `1px solid ${theme.palette.divider}`, margin: theme.spacing(0, 1), overflow: 'visible' },
  card: {
    flex: 1,
    margin: theme.spacing(),
    padding: theme.spacing(),
  },
  cardFocused: {
    background: '#e6e8f5',
  },
});

// @ts-ignore
@withStyles(styleSheet)
export class Commentary extends React.PureComponent<IComponentProps> {
  public ref: HTMLDivElement | null = null;

  public render() {
    const { classes, game, isTypeCompleted } = this.props;
    if (!game) return null;
    const { matchTeams: teams } = game;
    if (!teams) return null;

    let firstBlocClasses = classes.card;
    let secondBlocClasses = classes.card;
    isTypeCompleted
      ? (secondBlocClasses += ` ${classes.cardFocused}`)
      : (firstBlocClasses += ` ${classes.cardFocused}`);

    return (
      <React.Fragment>
        <div className={classes.container} ref={this.setRef}>
          <TeamPanel team={teams.home} side="home" />
          <div style={{ flex: 1 }}>
            <Card className={classes.paper}>
              <div className={firstBlocClasses}>
                <TypeSelector />
                <PlayersSelector />
              </div>
              <div className={secondBlocClasses}>
                <ContentEditor />
                <ParamsEditor />
              </div>
              <ActionButtons />
            </Card>
            <div className={classes.card}>
              <Commentaries callback={this.scrollToTop} />
            </div>
          </div>
          <TeamPanel team={teams.away} side="away" />
        </div>
        <ShortcutHandler />
      </React.Fragment>
    );
  }

  private setRef = (element: any) => {
    this.ref = element;
  };
  private scrollToTop = () => {
    if (this.ref) window.scrollTo(0, this.ref.offsetTop);
  };
}
