import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { ICommentaryType } from '../../../../commentaries/typings';
import { POSITIONS } from '../../../../compositions/typings';
import { IMatchEvent, IMatchEventSubstitute } from '../../../../matchEvents/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { getIconNameFromEvent, getIconNameFromOffEvent, getTypeIcon } from '../../utils';
import { IComponentProps } from './TeamPanel.connector';
import { isActionPlayer1, isActionPlayer2 } from './utils';

export type ITeamPanelPlayerMode = 'default' | 'player1' | 'player2';

export class TeamPanel extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { matchEvents, player, player2, type, isOpponentSide, strictMode } = this.props;
    return (
      nextProps.type !== type ||
      nextProps.player !== player ||
      nextProps.player2 !== player2 ||
      nextProps.isOpponentSide !== isOpponentSide ||
      nextProps.matchEvents.length !== matchEvents.length ||
      nextProps.strictMode !== strictMode
    );
  }

  public render() {
    const {
      team,
      classes,
      intl: { messages: m },
    } = this.props;

    if (!team) {
      return null;
    }

    return (
      <Card className={classes.paper}>
        {this.renderPlayerListByPosition(team.matchTeamPlayers)}
        <CardContent className={classes.cardTitle}>
          <Typography variant="caption">{m.live_team_substitutes}</Typography>
        </CardContent>
        <List dense disablePadding>
          {Object.values(team.matchTeamPlayers)
            .filter((mtp: any) => mtp.onBench)
            .map(this.renderPlayerList)}
        </List>
      </Card>
    );
  }

  private renderPlayerListByPosition = (matchTeamPlayers: any) => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;

    // generate list of players by position
    return POSITIONS.map(position => {
      const listByPosition = Object.values(matchTeamPlayers).filter(
        (matchTeamPlayer: any) => position === matchTeamPlayer.position && !matchTeamPlayer.onBench
      );

      // return position list only if data inside
      return (
        listByPosition.length > 0 && (
          <React.Fragment key={position}>
            <CardContent className={classes.cardTitle}>
              <Typography variant="caption">{m['live_compo_position_' + position]}</Typography>
            </CardContent>
            <List dense disablePadding>
              {listByPosition.map(this.renderPlayerList)}
            </List>
          </React.Fragment>
        )
      );
    });
  };

  private renderPlayerList = (matchTeamPlayer: any) => {
    const { classes, matchEvents } = this.props;

    if (!matchTeamPlayer) return null;
    const playerMatchEvents = matchEvents.filter(event => event.matchTeamPlayerId === matchTeamPlayer.id);
    const playerOffMatchEvents = matchEvents.filter(
      event => (event as IMatchEventSubstitute).playerOffId === matchTeamPlayer.id
    );

    const subInCount = playerMatchEvents.filter(ev => ev.name === 'substitute').length;
    const subOutCount = playerOffMatchEvents.length;
    const isInTheField = +!matchTeamPlayer.onBench + subInCount - subOutCount === 1;
    const mode: ITeamPanelPlayerMode = this.getAction(isInTheField);
    const handlePlayer = this.getHandler(matchTeamPlayer, mode);
    const className = this.getClassName(mode);

    return (
      <ListItem key={matchTeamPlayer.id} button className={className} onClick={handlePlayer}>
        <ListItemText>
          <div className={classes.label}>
            <div className={classes.number}>{matchTeamPlayer.shirtNumber}</div>
            <div>{matchTeamPlayer.membership.personMatchName}</div>
            {this.renderPlayerMatchEvents(playerMatchEvents, playerOffMatchEvents)}
          </div>
        </ListItemText>
      </ListItem>
    );
  };

  private renderPlayerMatchEvents = (matchEvents: IMatchEvent[], offMatchEvents: IMatchEvent[]) => {
    const { classes } = this.props;
    if (matchEvents.length === 0 && offMatchEvents.length === 0) return null;

    const playerEvents = [...matchEvents.map(getIconNameFromEvent), ...offMatchEvents.map(getIconNameFromOffEvent)];
    return (
      <div className={classes.svgContainer}>
        {this.renderGoals(playerEvents, 'goal')}
        {this.renderGoals(playerEvents, 'own_goal')}
        {this.renderCards(playerEvents)}
        {this.renderSubstitutes(playerEvents)}
      </div>
    );
  };

  private renderGoals = (playerEvents: string[], type: ICommentaryType) => {
    const count = playerEvents.filter(ev => ev === type).length;
    if (!count) return null;
    const { classes } = this.props;
    const Icon = getTypeIcon(type);
    return (
      <React.Fragment>
        <Icon key={type} />
        {count > 1 ? <span className={classes.eventNumber}>{`x${count}`}</span> : null}
      </React.Fragment>
    );
  };

  private renderCards = (playerEvents: string[]) => {
    let cardToDisplay = '';

    if (playerEvents.indexOf('red_card') !== -1) {
      cardToDisplay = 'red_card';
    } else if (playerEvents.indexOf('red_card') !== -1 || playerEvents.indexOf('second_yellow_card') !== -1) {
      cardToDisplay = 'yellow_card';
    }

    const Icon = getTypeIcon(cardToDisplay);
    return Icon ? <Icon key="card" /> : null;
  };

  private renderSubstitutes = (playerEvents: string[]) => {
    const IconIn = playerEvents.indexOf('substitute_in') !== -1 ? getTypeIcon('substitute_in') : null;
    const IconOut = playerEvents.indexOf('substitute_out') !== -1 ? getTypeIcon('substitute_out') : null;
    return (
      <React.Fragment>
        {IconIn ? <IconIn key="substitute_in" /> : null}
        {IconOut ? <IconOut key="substitute_out" /> : null}
      </React.Fragment>
    );
  };

  private getHandler = (matchTeamPlayer: IMatchTeamPlayer, mode: ITeamPanelPlayerMode) => {
    switch (mode) {
      case 'player1':
        return this.onSelectPlayer(matchTeamPlayer);
      case 'player2':
        return this.onSelectPlayer2(matchTeamPlayer);
      default:
        return this.onSelectPlayerCard(matchTeamPlayer);
    }
  };

  private getClassName = (mode: ITeamPanelPlayerMode) => {
    const { classes } = this.props;
    switch (mode) {
      case 'player1':
        return `${classes.base} ${classes.actionPlayer}`;
      case 'player2':
        return `${classes.base} ${classes.actionPlayer2}`;
      default:
        return classes.base;
    }
  };

  private onSelectPlayerCard = (matchTeamPlayer: any) => () => this.props.setPlayerCard(matchTeamPlayer);

  private onSelectPlayer = (matchTeamPlayer: any) => () => {
    const { side, setPlayerLive } = this.props;
    return setPlayerLive(matchTeamPlayer, side);
  };

  private onSelectPlayer2 = (matchTeamPlayer: any) => () => {
    const { side, setPlayer2Live } = this.props;
    return setPlayer2Live(matchTeamPlayer, side);
  };

  private getAction = (isInTheField: boolean): ITeamPanelPlayerMode => {
    const { player, player2, type, isOpponentSide, strictMode } = this.props;

    if (isActionPlayer1(type, !!player, isOpponentSide, isInTheField, strictMode)) {
      return 'player1';
    }
    if (isActionPlayer2(type, !!player, !!player2, isOpponentSide, isInTheField, strictMode)) {
      return 'player2';
    }
    return 'default';
  };
}
