import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import LinkIcon from '@material-ui/icons/Link';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PageviewIcon from '@material-ui/icons/Pageview';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { buildFMLink, getProject } from 'Services/project';
import { SimpleBreadcrumbs } from '../../common/components/SimpleBreadcrumbs';
import { addArticle } from '../articles/actions';
import ArticleDialog from '../articles/Dialog';
import { addAuthor, fetchAuthorMe } from '../authors/actions';
import AuthorDialog from '../authors/Dialog';
import { addTag } from '../tags/actions';
import TagDialog from '../tags/Dialog';

const styles = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0.5, 1),
  },
  flex: {
    flex: 1,
  },
});
@withRouter
@connect(store => ({
  me: store.me,
}))
@injectIntl
@withStyles(styles)
export default class TopBar extends Component {
  state = {
    url: '',
  };

  componentDidMount() {
    buildFMLink().then(url => this.setState({ url }));
  }

  handleGoToSearch = () => this.props.history.push(`${getProject().url}/search`);

  handleCreateArticle = article => {
    const { dispatch, history, me } = this.props;

    dispatch(fetchAuthorMe(me.username)).then(({ payload }) => {
      const articleWithAuthor = {
        authors: [payload['@id']],
        ...article,
      };

      dispatch(addArticle(articleWithAuthor)).then(({ payload }) => {
        if (payload && payload.slug) {
          history.push(`${getProject().url}/articles/${payload.id}`);
        }
      });
    });
  };
  handleCreateAuthor = author => {
    const { dispatch, history } = this.props;
    dispatch(addAuthor(author)).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/authors/${payload.id}`);
      }
    });
  };
  handleCreateTag = tag => {
    const { dispatch, history } = this.props;
    dispatch(addTag(tag)).then(({ payload }) => {
      if (payload && payload.slug) {
        history.push(`${getProject().url}/tags/${payload.slug}`);
      }
    });
  };

  render() {
    const {
      classes,
      crumbStack = [],
      intl: { messages: m },
    } = this.props;
    const { url } = this.state;

    return (
      <Paper>
        <div className={classes.container}>
          <SimpleBreadcrumbs crumbStack={crumbStack} />
          <div className={classes.flex} />
          <IconButton onClick={this.handleGoToSearch}>
            <Tooltip title={m.field_search}>
              <PageviewIcon />
            </Tooltip>
          </IconButton>
          <ArticleDialog title={m.dialog_addArticle} form="article_add" onCreate={this.handleCreateArticle}>
            <IconButton>
              <Tooltip title={m.dialog_addArticle}>
                <NoteAddIcon />
              </Tooltip>
            </IconButton>
          </ArticleDialog>
          <AuthorDialog title={m.dialog_addAuthor} form="author_add" onCreate={this.handleCreateAuthor}>
            <IconButton>
              <Tooltip title={m.dialog_addAuthor}>
                <PersonAddIcon />
              </Tooltip>
            </IconButton>
          </AuthorDialog>
          <TagDialog title={m.dialog_addTag} form="tag_add" onCreate={this.handleCreateTag}>
            <IconButton>
              <Tooltip title={m.dialog_addTag}>
                <CreateNewFolderIcon />
              </Tooltip>
            </IconButton>
          </TagDialog>
          <IconButton href={url} target="_blank">
            <Tooltip title={m.link_to_website}>
              <LinkIcon />
            </Tooltip>
          </IconButton>
        </div>
      </Paper>
    );
  }
}
