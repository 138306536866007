import { createDetailReducer } from '../../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'PLAYER',
  collections: {
    membershipsIds: { get: 'memberships' },
    personSponsorsIds: { get: 'personSponsors', set: 'personSponsor' },
    productsIds: { get: 'products', set: 'product' },
    transfersIds: { get: 'transfers' },
    injuriesIds: { get: 'injuries' },
  },
  inside: {
    agent: { get: 'players' },
    agentCompany: { get: 'players' },
  },
});
