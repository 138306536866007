import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  table: {
    marginBottom: 24,
  },
  rowHeader: {
    height: 'auto',
  },
  row: {
    height: 'auto',

    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  header: {
    fontSize: 14,
    color: theme.palette.grey[900],
    fontWeight: 'bold',
    padding: '12px 24px',
  },
  cell: {
    padding: '3px 12px 0',

    '& > div': {
      marginRight: 6,
      marginBottom: 3,
    },
  },
});

@withStyles(styles)
export default class Variables extends Component {
  render() {
    const { data, classes } = this.props;

    if (!data) return;

    return Object.keys(data).map(tableType => (
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.rowHeader}>
            <TableCell className={classes.header}>{tableType}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.row}>
            <TableCell className={classes.cell}>
              {data[tableType].map(entry => (
                <Chip label={`%${entry}%`} />
              ))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    ));
  }
}
