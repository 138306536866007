import {Button, Divider} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import * as React from 'react';
import {ICommentaryType} from '../../../../commentaries/typings';
import {getCommentaryEventName} from '../../../../matchEvents/utils';
import {getTypeIcon} from '../../utils';
import {EPeriod} from '../PeriodSelector/utils';
import {IComponentProps} from './TypeSelector.connector';
import {getProject} from "../../../../../common/services/project";

export class TypeSelector extends React.PureComponent<IComponentProps> {
  public render() {
    const {
      classes,
      strictMode,
      intl: { messages: m },
    } = this.props;
    const typesGroups = this.getTypesGroups();

    return (
      <div className={classes.container}>
        {typesGroups.map(this.renderCategory)}
        <div style={{ flex: 1 }}></div>
        <Button
          title={m.live_strictMode_helper}
          onClick={this.toggleMode}
          color={strictMode ? 'default' : 'secondary'}
          startIcon={strictMode ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
        >
          {m.live_strictMode}
        </Button>
      </div>
    );
  }

  private renderCategory = (data: ICommentaryType[], key: number) => {
    const { classes, type } = this.props;

    const divider =
      key === 0 ? null : <Divider key={'divider_' + key} orientation="vertical" className={classes.divider} />;

    return (
      <React.Fragment key={'type_selector_fragment' + key}>
        {divider}
        <ToggleButtonGroup
          key={'buttonGroup_' + key}
          size="small"
          value={type}
          exclusive
          onChange={this.onClick}
          className={classes.group}
        >
          {data.map(this.renderButton)}
        </ToggleButtonGroup>
      </React.Fragment>
    );
  };

  private renderButton = (button: ICommentaryType) => {
    const {
      classes,
      type,
      editingCommentaryId,
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;

    const isLocked = editingCommentaryId ? getCommentaryEventName(type) !== getCommentaryEventName(button) : false;
    const isCommentatorAllowed = mainCommentatorLocale === getProject().lang || !this.getMatchEventTypes().includes(button);

    const Icon = getTypeIcon(button);
    return Icon && isCommentatorAllowed ? (
      <ToggleButton
        key={button}
        title={m['live_type_' + button]}
        value={button}
        disabled={isLocked}
        className={classes.button}
      >
        <Icon />
      </ToggleButton>
    ) : null;
  };

  private onClick = (_, value) => {
    if (!value) return;
    this.props.setTypeLive(value);
  };

  private getMatchEventTypes = (): ICommentaryType[] => {
    const { period } = this.props;

    const penaltyShootoutTypes: ICommentaryType[] = [
      'penalty_shot_scored',
      'penalty_shot_missed',
      'penalty_shot_saved',
    ];
    const goalTypes: ICommentaryType[] = ['goal', 'own_goal', 'penalty_goal', 'missed_penalty'];

    return [
      ...(period === EPeriod.PenaltyShootout ? penaltyShootoutTypes : goalTypes),
      'substitute',
      'yellow_card',
      'second_yellow_card',
      'red_card',
    ];
  };

  private getTypesGroups = (): ICommentaryType[][] => {
    const baseType: ICommentaryType[] = ['text'];
    const eventsTypes: ICommentaryType[] = this.getMatchEventTypes();
    const factsTypes: ICommentaryType[] = ['timer', 'additional_time', 'injury', 'action', 'whistle', 'var', 'penalty'];
    const extraTypes: ICommentaryType[] = ['statistic', 'quote', 'player_of_the_match', 'champion'];

    return [baseType, eventsTypes, factsTypes, extraTypes];
  };

  private toggleMode = () => {
    const { strictMode, setStrictModeLive } = this.props;
    setStrictModeLive(!strictMode);
  };
}
