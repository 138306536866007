import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import { ITeam } from "../../teams/typings";
import { fetchTeams, getMercatoSurvey, updateMercatoSurvey } from '../actions';

interface IProps {
  teams: ITeam[],
}

type IOwnProps = IProps & RouteComponentProps<{ topicId: string }>;

const mapDispatchToProps = {
  fetchMercatoSurvey: getMercatoSurvey,
  updateMercatoSurvey,
  fetchTeams,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const id = ownProps.match.params.topicId;
  return {
    id,
    mercatoSurvey: state.mercatoSurvey.detail[id],
    fetching: state.mercatoSurvey.fetching,
    me: state.me,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
