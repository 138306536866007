import { ITeam } from './typings';

export const getTeamLabel = (team: ITeam) => {
  let label = team.name;
  if (team.ageGroup && team.ageGroup.code !== 'senior') {
    label += ` ${team.ageGroup.name}`;
  }
  if (team.gender !== 'male') {
    label += ` ${team.genderTranslated}`;
  }
  if (team.club && team.club.country) {
    label += ` (${team.club.country.codeISO})`;
  }
  return label;
};

export const sortTeamByName = (teamA: ITeam, teamB: ITeam) => teamA.name.localeCompare(teamB.name);
