import React, { Component } from 'react';
import { IArticle } from '../../typings';

interface IComponentProps {
  article: IArticle;
}
export class ArticleTitleCell extends Component<IComponentProps> {
  public render() {
    const { article } = this.props;
    const { title, type } = article;
    if (type === 'center') {
      return <span style={{ fontWeight: 'bold' }}>{title}</span>;
    }
    return <span>{title}</span>;
  }
}
