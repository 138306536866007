import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import fetchAllSeasons from './actions';
import moment from 'moment';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

@injectIntl
@connect(store => ({
  seasons: store.seasons,
}))
export default class SelectSeason extends Component {
  year = moment().year();

  componentDidMount() {
    const { dispatch, seasons } = this.props;
    if (!seasons.fetched) {
      dispatch(fetchAllSeasons());
    }
  }

  filterLastSeasons = () => {
    const { seasons } = this.props;
    if (!seasons.list) return [];
    return seasons.list.filter(season => season.shortName - this.year < 2);
  };

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error } = {},
      intl: { formatMessage: f },
      labelKey,
      nullable,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
    const items = this.filterLastSeasons();

    return (
      <FormControl fullWidth error={Boolean(touched && error)}>
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={event => onChange(event.target.value)}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          {nullable && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {items.length ? (
            items.map(season => <MenuItem value={season['@id']}>{season.name}</MenuItem>)
          ) : (
            <MenuItem value={value}>{value}</MenuItem>
          )}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
