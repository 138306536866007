import React, { Component } from 'react';
import { MatchHead } from './MatchHead';
import MatchTeams from './Teams';
import MatchGoals from './Goals';
import MatchCards from './Cards';

export default class Bloc extends Component {
  render() {
    const { match, formations } = this.props;

    const matchPlayers = new Map();
    if (formations.home && formations.away) {
      Object.values(formations.home.positions).forEach(pos =>
        Object.values(pos).forEach(p => matchPlayers.set(p.id, p))
      );
      Object.values(formations.away.positions).forEach(pos =>
        Object.values(pos).forEach(p => matchPlayers.set(p.id, p))
      );
      Object.values(formations.home.substitutes).forEach(p =>
        matchPlayers.set(p.id, p)
      );
      Object.values(formations.away.substitutes).forEach(p =>
        matchPlayers.set(p.id, p)
      );
    }

    return (
      <div>
        <MatchHead match={match} />
        {formations && formations.home && formations.away && (
          <div>
            <MatchGoals formations={formations} playersMap={matchPlayers} />
            <MatchTeams formations={formations} match={match} />
            <MatchCards formations={formations} playersMap={matchPlayers} />
          </div>
        )}
      </div>
    );
  }
}
