export interface IEntityDetailState<T> {
  detail: { [key: string]: T };
  slugs: { [key: string]: string };
  link: { [key: string]: string };
  fetching: boolean;
  error: any;
  fetched: boolean;
  updating: boolean;
  posting: boolean;
  selectedId: string;
}

const defaultInitialState: IEntityDetailState<any> = {
  detail: {},
  slugs: {},
  link: {},
  selectedId: '',
  fetching: false,
  error: null,
  fetched: false,
  updating: false,
  posting: false,
};

interface IPropertyConfigs {
  [key: string]: { [key: string]: string };
}

interface ICreateDetailReducers {
  modelName: string;
  initialState?: IEntityDetailState<any>;
  inside?: IPropertyConfigs;
  of?: IPropertyConfigs;
  collections?: IPropertyConfigs;
  details?: IPropertyConfigs;
}

export const createDetailReducer = ({
  modelName,
  initialState = defaultInitialState,
  inside = {},
  of = {},
  collections = {},
  details = {},
}: ICreateDetailReducers) => {
  return (state = initialState, action) => {
    if (action.type === `FETCH_${modelName}`) {
      return {
        ...state,
        fetching: true,
        error: null,
      };
    }
    if (action.type === `FETCH_${modelName}_FULFILLED`) {
      const newDetail = { ...state.detail };
      newDetail[action.payload.id] = {
        ...newDetail[action.payload.id],
        ...action.payload,
      };
      if (action.payload.slug) {
        state.slugs = {
          ...state.slugs,
          [action.payload.slug]: action.payload.id,
        };
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        detail: newDetail,
        selectedId: action.payload.id,
      };
    }
    if (action.type === `FETCH_${modelName}_REJECTED`) {
      return {
        ...state,
        fetching: false,
      };
    }
    if (action.type === `FETCH_${modelName}_NOTFOUND`) {
      return {
        ...state,
        fetching: false,
        selectedId: '',
      };
    }
    // Link requests: light answer
    if (action.type === `FETCH_${modelName}_LINK`) {
      const newLink = { ...state.link };
      newLink[action.payload.id] = { ...action.payload, fetching: true };
      return {
        ...state,
        link: newLink,
      };
    }
    if (action.type === `FETCH_${modelName}_LINK_FULFILLED`) {
      const newLink = { ...state.link };
      newLink[action.payload.id] = { ...action.payload, fetching: false };
      return {
        ...state,
        link: newLink,
      };
    }

    // Get requests: Does not change selected id
    if (action.type === `GET_${modelName}`) {
      return {
        ...state,
        fetching: true,
      };
    }
    if (action.type === `GET_${modelName}_REJECTED`) {
      return {
        ...state,
        fetching: false,
      };
    }
    if (action.type === `GET_${modelName}_FULFILLED`) {
      const newDetail = { ...state.detail };
      newDetail[action.payload.id] = {
        ...action.payload,
        ...newDetail[action.payload.id],
      };
      return {
        ...state,
        detail: newDetail,
        fetching: false,
      };
    }

    if (action.type === `ADD_${modelName}`) {
      return {
        ...state,
        posting: true,
      };
    }

    if (action.type === `ADD_${modelName}_FULFILLED`) {
      const newDetail = { ...state.detail };
      newDetail[action.payload.id] = action.payload;
      return {
        ...state,
        posting: false,
        detail: newDetail,
      };
    }

    if (action.type === `ADD_${modelName}_REJECTED`) {
      return {
        ...state,
        posting: false,
      };
    }

    if (action.type === `UPDATE_${modelName}`) {
      return {
        ...state,
        updating: true,
      };
    }
    if (action.type === `UPDATE_${modelName}_FULFILLED`) {
      const newDetail = { ...state.detail };
      newDetail[action.payload.id] = {
        ...newDetail[action.payload.id],
        ...action.payload,
      };
      return {
        ...state,
        detail: newDetail,
        updating: false,
      };
    }
    if (action.type === `UPDATE_${modelName}_REJECTED`) {
      return {
        ...state,
        updating: false,
      };
    }
    if (action.type === `DELETE_${modelName}`) {
      return {
        ...state,
        deleting: true,
      };
    }
    if (action.type === `DELETE_${modelName}_FULFILLED`) {
      const { id } = action.payload;
      const newDetail = { ...state.detail };
      delete newDetail[id];
      return {
        ...state,
        detail: newDetail,
        deleting: false,
      };
    }
    if (action.type === `DELETE_${modelName}_REJECTED`) {
      return {
        ...state,
        deleting: false,
      };
    }
    for (const [key, { get, set }] of Object.entries(collections)) {
      if (get && action.type === `FETCH_${modelName}_${get.toUpperCase()}_FULFILLED`) {
        // you have to pass payload: res.data.collection
        const newDetail = { ...state.detail };
        const { collection, parentId } = action.payload;
        const id = parentId || state.selectedId;

        newDetail[id] = {
          ...newDetail[id],
          [key]: collection.map(c => c.id),
        };
        return {
          ...state,
          detail: newDetail,
        };
      }

      if (set && action.type === `ADD_${modelName}_${set.toUpperCase()}_FULFILLED`) {
        const newDetail = { ...state.detail };
        let collectionIds = newDetail[state.selectedId][key] || [];
        collectionIds = [...collectionIds, action.payload.id];
        newDetail[state.selectedId] = {
          ...newDetail[state.selectedId],
          [key]: collectionIds,
        };
        return {
          ...state,
          detail: newDetail,
        };
      }

      if (set && action.type === `DELETE_${modelName}_${set.toUpperCase()}_FULFILLED`) {
        const newDetail = { ...state.detail };
        let collectionIds = newDetail[state.selectedId][key] || [];
        const index = collectionIds.indexOf(action.payload.id);
        if (index !== -1) {
          collectionIds = [...collectionIds.slice(0, index), ...collectionIds.slice(index + 1)];
        }
        newDetail[state.selectedId][key] = collectionIds;
        return {
          ...state,
          detail: newDetail,
        };
      }
    }
    for (const [key, { get, set }] of Object.entries(inside)) {
      if (get && action.type === `FETCH_${key.toUpperCase()}_${get.toUpperCase()}_FULFILLED`) {
        const newDetail = { ...state.detail };
        const { collection } = action.payload;
        for (const element of collection) {
          newDetail[element.id] = {
            ...element,
            ...newDetail[element.id],
          };
        }

        if (action.payload.totalItems) {
          return {
            ...state,
            detail: newDetail,
            totalItems: action.payload.totalItems,
          };
        }

        return {
          ...state,
          detail: newDetail,
        };
      }

      if (set && action.type === `ADD_${key.toUpperCase()}_${set.toUpperCase()}`) {
        return {
          ...state,
          posting: true,
        };
      }

      if (set && action.type === `ADD_${key.toUpperCase()}_${set.toUpperCase()}_FULFILLED`) {
        const newDetail = { ...state.detail };
        newDetail[action.payload.id] = action.payload;
        return {
          ...state,
          detail: newDetail,
          posting: false,
        };
      }

      if (set && action.type === `ADD_${key.toUpperCase()}_${set.toUpperCase()}_REJECTED`) {
        return {
          ...state,
          posting: false,
        };
      }
    }
    for (const [key, { get }] of Object.entries(of)) {
      if (get && action.type === `FETCH_${key.toUpperCase()}_${get.toUpperCase()}_FULFILLED`) {
        const newDetail = { ...state.detail };
        newDetail[action.payload.id] = action.payload;
        return {
          ...state,
          detail: newDetail,
        };
      }
    }

    for (const [key, { get }] of Object.entries(details)) {
      if (get && action.type === `FETCH_${modelName}_${get.toUpperCase()}_FULFILLED`) {
        const newDetail = { ...state.detail };
        const { payload } = action;
        newDetail[state.selectedId] = {
          ...newDetail[state.selectedId],
          [key]: payload.id,
        };
        return {
          ...state,
          detail: newDetail,
        };
      }
    }

    return state;
  };
};
