import { EPosition } from '../../../../compositions/typings';
import { ISide } from '../../../../matches/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { selectMatchTeamPlayerAction } from '../../actions';
import { ICompositionState } from '../../typings';
import {
  addMatchTeamPlayerOnComposition,
  removeMatchTeamPlayerFromComposition,
} from '../CompositionCell/actions';
import {
  addMatchTeamPlayerOnSubstitutes,
  removeMatchTeamPlayerFromSubtitutes,
} from '../SubstituteCell/actions';

export const swapMatchTeamPlayerInList = (
  side: ISide,
  matchTeamPlayer: IMatchTeamPlayer
) => {
  return (dispatch, getState) => {
    const liveCompositionState: ICompositionState = getState().liveComposition;
    const selectedMatchTeamPlayer = liveCompositionState.selectedMatchTeamPlayer[side];
    if (!selectedMatchTeamPlayer) return;

    const originPosition = selectedMatchTeamPlayer.position;
    const originPositionSide = selectedMatchTeamPlayer.positionSide;

    if (originPosition) {
      dispatch(removeMatchTeamPlayerFromComposition(side, originPosition, originPositionSide));
      dispatch(
        selectMatchTeamPlayerAction(matchTeamPlayer, side)
      );

      return dispatch(
        addMatchTeamPlayerOnComposition(side, originPosition as EPosition, originPositionSide)
      );
    } else {
      dispatch(removeMatchTeamPlayerFromSubtitutes(side));
      dispatch(selectMatchTeamPlayerAction(matchTeamPlayer, side));
      return dispatch(addMatchTeamPlayerOnSubstitutes(side));
    }
  };
};
