import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = theme => ({
  plate: {
    border: `2px solid ${theme.palette.primary[500]}`,
    borderRadius: 2,
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    zIndex: 0,
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ',
    '&:hover': { background: theme.palette.primary[500] },
    '&:hover $title': { color: theme.palette.primary[50] },
    '&:hover $iconBack': { fill: theme.palette.primary[400] },
    '&:hover $iconTitle': { fill: 'white' },
    '&:hover $number': { color: 'white' },
  },
  title: {
    margin: theme.spacing(2),
    display: 'flex',
  },
  iconBack: {
    position: 'absolute',
    bottom: -16,
    right: -16,
    zIndex: '-1',
    fill: theme.palette.primary[50],
    width: 136,
    height: 136,
  },
  iconTitle: {
    fill: theme.palette.primary[500],
    marginRight: 8,
  },
  number: {
    fontSize: '48px',
    color: theme.palette.primary[500],
    margin: theme.spacing(2),
    textAlign: 'right',
    fontWeight: '600',
  },
});

@withStyles(styles)
export default class Plate extends Component {
  render() {
    const { title, href, number = 0, icon: Icon, classes } = this.props;
    return (
      <ButtonBase href={href} className={classes.plate} focusRipple>
        <Icon className={classes.iconBack} />
        <Typography type="title" className={classes.title}>
          <Icon className={classes.iconTitle} />
          {title}
        </Typography>
        <Typography type="display1" className={classes.number}>
          {number}
        </Typography>
      </ButtonBase>
    );
  }
}
