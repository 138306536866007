import * as React from 'react';
import { InputCollection } from '../../components/InputCollection';
import { IInput } from '../../components/InputCollection/InputCollection.component';
import { searchPeople } from '../actions';
import { getPersonUsualNameNationalityAge } from '../utils';
import { PersonCollectionChip } from './PersonCollectionChip.component';

interface IProps {
  meta: any;
  input: IInput;
  labelKey: string;
}
export class PersonInputCollection extends React.Component<IProps> {
  public render() {
    const { input, meta , labelKey} = this.props;

    return (
      // @ts-ignore
      <InputCollection
        input={input}
        meta={meta}
        search={searchPeople}
        getLabel={getPersonUsualNameNationalityAge}
        CollectionChip={PersonCollectionChip}
        labelKey={labelKey}
      />
    );
  }
}
