import { WithStyles, withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { ISide } from '../../../../matches/typings';
import { selectTeamCompositionPositions } from '../../selector';
import { styles } from './styles';

const mapDispatchToProps = {};
interface IOwnProps {
  side: ISide;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    positions: selectTeamCompositionPositions(state, ownProps.side),
  };
};

export type IComponentProps = WithStyles &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles)
);
