import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { IInput } from '../../../components/InputCollection/InputCollection.component';
import { ISurvey } from '../../typings';
import { getProject } from '../../../../common/services/project';

interface IProps {
  meta: any;
  input: IInput;
  intl: any;
  survey: ISurvey;
}

// @ts-ignore
@injectIntl
export class SurveyOptionInputCollection extends React.Component<IProps, any> {
  public render() {
    const {
      intl: { messages: m },
    } = this.props;

    return (
      <Grid container item xs={12} sm={12} spacing={2} key={1}>
        {this.props.input.value.map((option, index) => (
          <Grid container item xs={12} sm={12} key={index}>
            <Grid item xs={10} sm={11}>
              <TextField
                label={`${m.field_option} #${index + 1}`}
                name={`label_${index}`}
                defaultValue={option.label}
                onChange={event => this.handleChange(event.target.value || '', index)}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton color="secondary" onClick={this.removeElement(index)} disabled={!this.canEditOptions()}>
                <DeleteIcon style={{ marginRight: 0 }} />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Grid container item xs={12} sm={6}>
          <Button
            variant="contained"
            onClick={this.addElement()}
            color="primary"
            data-test="ArticleList__buttonAdd"
            disabled={!this.canEditOptions()}
          >
            <AddIcon style={{ marginRight: 8 }} />
            add
          </Button>
        </Grid>
      </Grid>
    );
  }

  private removeElement = index => () => {
    const options = [...this.props.input.value];
    options.splice(index, 1);
    // @ts-ignore
    this.props.input.onChange(options);
  };
  private addElement = () => () => {
    const options = [...this.props.input.value, { label: '' }];
    this.props.input.onChange(options);
  };

  private handleChange(newValue, index) {
    const options = [...this.props.input.value];
    options[index].label = newValue;
    this.props.input.onChange(options);
  }

  private canEditOptions() {
    const { survey } = this.props;
    // Survey not exist on creation
    if (!survey) {
      return true;
    }
    if (survey.mainLocale !== getProject().lang) {
      return false;
    }
    const startDate = new Date(survey.startDate);
    const now = new Date();
    if (startDate.getTime() <= now.getTime()) {
      return false;
    }

    return true;
  }
}
