import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { ICommentary } from '../../../../commentaries/typings';
import { Commentary as CommentaryComponent } from './Commentary.component';

interface IOwnProps {
  isFirst: boolean;
  commentary: ICommentary;
  isEditing: boolean;
  isEditingMode: boolean;
  onRemove: () => void;
  onEdit: () => void;
}
export type IComponentProps = InjectedIntlProps & IOwnProps;

const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const Commentary = connector(CommentaryComponent);
