export default function reducer(
  state = {
    clubSponsor: {},
    competition: {},
    contract: {},
    goal: {},
    match: {},
    matchTeam: {},
    matchTeamPlayer: {},
    organization: {},
    person: {},
    personSponsor: {},
    product: {},
    rankingRule: {},
    team: {},
    transfer: {},
    venue: {
      UEFAStadiumClassification: {
        fetched: true,
        list: { 1: 1, 2: 2, 3: 3, 4: 4 },
      },
    },
    survey: {},
    surveyTranslation: {},
    partner: {},
    partnerOffer: {},
    quizz: {},
    lineupSurvey: {},
    mercatoSurvey: {},
  },
  action
) {
  switch (action.type) {
    case 'FETCH_CONSTANTS': {
      const { entity, field } = action.payload;
      if (!state[entity]) {
        return state;
      }

      const updateEntity = {
        ...state[entity],
        [field]: {
          fetching: true,
        },
      };
      return {
        ...state,
        [entity]: updateEntity,
      };
    }

    case 'FETCH_CONSTANTS_FULFILLED': {
      const { entity, field, list } = action.payload;
      if (!state[entity]) {
        return state;
      }

      const updateEntity = {
        ...state[entity],
        [field]: {
          list,
          fetched: true,
          fetching: false,
        },
      };
      return {
        ...state,
        [entity]: updateEntity,
      };
    }

    default:
      return state;
  }
}
