import React from 'react';
import Showdown from 'showdown';
import './style.css';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export class Markdown extends React.Component<any> {
  public state = {
    html: '',
  };

  public componentDidMount() {
    fetch(this.props.filePath)
      .then(r => r.text())
      .then(this.generateMarkdownPreview)
      .then(html => this.setState({ html }));
  }

  public render() {
    const { html } = this.state;
    return <div className='mde-preview-content' dangerouslySetInnerHTML={{ __html: html }} />;
  }

  private generateMarkdownPreview = (markdown: string) =>
    Promise.resolve(converter.makeHtml(markdown));
}
