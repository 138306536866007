import React, { Component } from 'react';

import { FormEdit } from '../../common/components/FormEdit';
import TextInput from '../../common/components/inputs/Text';
import DatetimeInput, { formatUtc } from '../../common/components/inputs/Datetime';
import SelectConstant from '../components/constants/Select';
import { CompetitionInputCollection } from '../../footdata/competitions/CompetitionInputCollection';
import { PersonInputCollection } from '../../footdata/people/PersonInputCollection';
import { TeamInputCollection } from '../../footdata/teams/TeamInputCollection';
import { MatchInputCollection } from '../../footdata/matches/MatchInputCollection';

export class PushForm extends Component {
  config = {
    fields: [
      'text',
      'title',
      'system',
      'segment',
      'scheduledAt',
      'excludedFootdataCompetitionIds',
      'excludedFootdataTeamIds',
      'excludedFootdataMatchIds',
      'excludedFootdataPersonIds',
      'footdataCompetitionIds',
      'footdataTeamIds',
      'footdataMatchIds',
      'footdataPersonIds',
    ],
    disposition: [
      {
        fields: [
          {
            name: 'title',
            component: TextInput,
          },
          {
            name: 'text',
            component: TextInput,
            multiline: true,
            validate: value => !value && 'error_required',
          },
          {
            name: 'segment',
            component: SelectConstant,
            entity: 'pushFM',
            size: 6,
          },
          {
            name: 'system',
            component: SelectConstant,
            entity: 'pushFM',
            size: 6,
            allable: true,
          },
          {
            name: 'scheduledAt',
            component: DatetimeInput,
            normalize: formatUtc,
            size: 6,
            helperKey: 'field_scheduledAtHelper',
          },
        ],
      },
      {
        size: 6,
        title: 'form_dataToExclude',
        fields: [
          {
            name: 'excludedFootdataCompetitionIds',
            component: CompetitionInputCollection,
          },
          {
            name: 'excludedFootdataTeamIds',
            component: TeamInputCollection,
          },
          {
            name: 'excludedFootdataMatchIds',
            component: MatchInputCollection,
          },
          {
            name: 'excludedFootdataPersonIds',
            component: PersonInputCollection,
          },
        ],
      },
      {
        size: 6,
        title: 'form_dataToLink',
        fields: [
          {
            name: 'footdataCompetitionIds',
            component: CompetitionInputCollection,
          },
          {
            name: 'footdataTeamIds',
            component: TeamInputCollection,
          },
          {
            name: 'footdataMatchIds',
            component: MatchInputCollection,
          },
          {
            name: 'footdataPersonIds',
            component: PersonInputCollection,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit, disabled } = this.props;
    return (
      <FormEdit
        key="push"
        form="push"
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
        externalDisable={disabled}
        main
      />
    );
  }
}
