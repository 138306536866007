import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UserDetail } from './Detail';
import { UsersList } from './List';

export const UsersRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={UsersList} />
      <Route path={`${match.url}/:topicId`} component={UserDetail} />
    </Switch>
  );
};
