import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import GamesIcon from '@material-ui/icons/Games';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ClubIcon from 'Components/icons/Club';
import CompetitionIcon from 'Components/icons/Competition';
import PersonIcon from 'Components/icons/Person';
import Layout from 'Components/Layout';
import Breadcrumb from 'FDComponents/Breadcrumb';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProject } from 'Services/project';
import { getProjectNameFromPath } from '../../common/services/project';
import { resetBreadcrumb } from '../components/Breadcrumb/actions';
import { fetchNextMatches } from '../matches/actions';
import NextMatches from '../matches/NextMatches';
import { getDefaultMatchPath } from '../matches/utils';
import { getDefaultPeoplePath } from '../people/utils';
import fetchCounters from './actions';
import Plate from './Plate';

@injectIntl
@connect(store => ({
  matches: store.matches.next && store.matches.next.list,
  counters: store.counters,
}))
export default class Dashboard extends Component {
  state = {
    stepsEnabled: false,
    initialStep: 0,
    steps: [
      {
        element: '#number-title',
        intro: 'Stats',
      },
      {
        element: '#competitionPlate',
        intro: 'Competition shortcut',
      },
      {
        element: '#clubPlate',
        intro: 'Club shortcut',
      },
      {
        element: '#playerPlate',
        intro: 'Player shortcut',
      },
      {
        element: '#matchPlate',
        intro: 'Match shortcut',
      },
    ],
  };

  componentDidMount() {
    const {
      intl: { messages: m },
    } = this.props;
    const { steps } = this.state;

    steps.options = {
      nextLabel: m.intro_next,
      prevLabel: m.intro_prev,
      skipLabel: m.intro_skip,
      doneLabel: m.intro_done,
    };
    this.setState({ steps });

    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (
      getProjectNameFromPath(prevProps.location.pathname) !==
      getProjectNameFromPath(location.pathname)
    ) {
      this.fetchData();
    }
  }

  toggleSteps = () => {
    this.setState({ stepsEnabled: true });
  };

  onExit = () => {
    this.setState({ stepsEnabled: false });
  };

  fetchData = () => {
    const { dispatch } = this.props;
    dispatch(fetchNextMatches('&phase.tournament.competition.isBigFive=1'));
    dispatch(fetchCounters());
    dispatch(resetBreadcrumb());
  };

  render() {
    const {
      matches,
      counters,
      history,
      intl: { messages: m },
    } = this.props;
    const { stepsEnabled, steps, initialStep, options } = this.state;
    return (
      <div>
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          options={options}
          onExit={this.onExit}
        />
        <Layout
          id="dashboard-layout"
          top={<Breadcrumb />}
          main={[
            <Paper elevation={2}>
              <Toolbar>
                <Typography variant="h6" id="number-title">
                  {m.dashboard_numbers}
                </Typography>
                <IconButton aria-label="Help" onClick={this.toggleSteps}>
                  <HelpOutlineIcon />
                </IconButton>
              </Toolbar>
              <div style={{ display: 'flex' }}>
                <Grid container spacing={2} style={{ padding: 16 }}>
                  <Grid item xs={12} sm={6} md={3} id="competitionPlate">
                    <Plate
                      title={m.app_competitions}
                      href={`#${getProject().url}/competitions`}
                      number={counters.competitions}
                      icon={CompetitionIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} id="clubPlate">
                    <Plate
                      title={m.app_clubs}
                      href={`#${getProject().url}/clubs`}
                      number={counters.clubs}
                      icon={ClubIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} id="playerPlate">
                    <Plate
                      title={m.app_players}
                      href={`#${getDefaultPeoplePath('players')}`}
                      number={counters.people}
                      icon={PersonIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} id="matchPlate">
                    <Plate
                      title={m.app_matches}
                      href={`#${getDefaultMatchPath()}`}
                      number={counters.matches}
                      icon={GamesIcon}
                    />
                  </Grid>
                </Grid>
              </div>
            </Paper>,
            <Paper elevation={2} style={{ marginTop: 16 }}>
              <Toolbar>
                <Typography variant="h6">{m.dashboard_nextMatches}</Typography>
                <div style={{ flex: 1 }} />
                <Button href={`#${getDefaultMatchPath()}`}>{m.button_seeAll}</Button>
              </Toolbar>
              <NextMatches matches={matches} history={history} />
            </Paper>,
          ]}
        />
      </div>
    );
  }
}
