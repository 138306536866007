import rest from 'Services/restFootData';
import { SubmissionError } from "redux-form";

export function updateInjuryType(id, injury) {
  const params = {
    groups: ['read', 'write'],
  };
  return function(dispatch) {
    dispatch({ type: 'UPDATE_INJURY_TYPE' });
    return rest.put(`injury_types/${id}`, injury, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_injuryTypeUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_INJURY_TYPE_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_INJURY_TYPE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchInjuryTypes() {
  const params = {
    'order[code]': 'asc'
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_INJURY_TYPES' });

    return rest
      .get('injury_types', { params })
      .then(
        res => dispatch({ type: 'FETCH_INJURY_TYPES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_INJURY_TYPES_REJECTED', payload: err.response })
      );
  };
}
