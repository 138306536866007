import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import LinearProgress from '@material-ui/core/LinearProgress';
import Subheader from '@material-ui/core/ListSubheader';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FooterPagination from 'Components/TableList/FooterPagination';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import debounce from 'Services/tools';
import Toggle from '../../../common/components/inputs/Toggle';
import { fetchImages } from '../actions';
import ImageDialog from '../Dialog';

const DEFAULT_IMG_WIDTH = 640;

const styles = theme => ({
  grid: {
    padding: '24px 0',
    overflowY: 'auto',
  },
  selectedTile: {
    border: `4px solid ${theme.palette.primary[500]}`,
  },
  closeButton: {
    position: 'absolute',
    zIndex: '15',
    top: 0,
    right: 0,
  },
  inputContainer: {
    display: 'flex',
    margin: '0 24px',
  },
  input: {
    marginLeft: 16,
    position: 'relative',
  },
  img: {
    position: 'absolute',
  },
  infoIcon: {
    position: 'absolute',
    zIndex: '15',
    bottom: 4,
    right: 4,
  },
  size: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    background: 'black',
    padding: '0 4px',
  },
  loader: { position: 'absolute', bottom: 0, width: '100%' },
});
@withStyles(styles)
@injectIntl
@connect(store => ({
  images: store.images,
  pagination: store.images.pagination,
  total: store.images.totalItems,
}))
export default class MediaLibrary extends Component {
  state = {
    selectedImg: null,
    filename: null,
    page: null,
    itemsPerPage: null,
    club: null,
    personValue: null,
    imagesDimensions: {},
    fuzzy: 0,
    format: 'medium',
    isOriginalDisabled: false,
    hdOnly: 1,
  };

  componentDidMount() {
    this.search();
  }

  onChange = event => {
    this.setState({ filename: event.target.value }, this.search);
  };

  onChangeFuzzy = event => {
    const { checked } = event.target;
    const booleanToInteger = checked ? 1 : null;

    this.setState({ fuzzy: booleanToInteger }, this.search);
  };

  onChangeHdOnly = event => {
    const { checked } = event.target;
    const booleanToInteger = checked ? 1 : null;

    this.setState({ hdOnly: booleanToInteger }, this.search);
  };

  onChangeClub = club => {
    this.setState({ club }, this.search);
  };
  onChangePerson = person => {
    this.setState({ person }, this.search);
  };

  search = debounce(() => {
    const { dispatch } = this.props;
    const { filename, page, itemsPerPage, fuzzy, hdOnly } = this.state;
    let query = '?';
    if (filename) {
      query += `&search=${filename}`;
    }
    if (page) {
      query += `&page=${page}`;
    }
    if (itemsPerPage) {
      query += `&itemsPerPage=${itemsPerPage}`;
    }
    if (fuzzy) {
      query += `&fuzzy=${fuzzy}`;
    }
    if (hdOnly) {
      query += `&hdOnly=${hdOnly}`;
    }
    this.setState({ page: null }); // reinit pagination
    dispatch(fetchImages(query));
  }, 500);

  selectImage = selectedImg => () => {
    const { w } = this.state.imagesDimensions[selectedImg.id];
    let format = this.state.format;
    let isOriginalDisabled = false;

    if (w >= DEFAULT_IMG_WIDTH) {
      isOriginalDisabled = true;
      if (format === 'original') {
        format = 'medium';
      }
    }

    this.setState({
      selectedImg,
      isOriginalDisabled,
      format,
    });
  };

  onConfirm = () => {
    const { selectedImg, format, imagesDimensions } = this.state;
    const { w, h } = imagesDimensions[selectedImg.id];

    this.props.close({
      image: selectedImg,
      format,
      size: { w, h },
    });
  };

  onCancel = () => {
    this.props.close();
  };

  handleDeleteCallBack = () => {
    const { dispatch } = this.props;
    dispatch(fetchImages());
  };

  handlePagination = ({ page, itemsPerPage }) => {
    this.setState({ page, itemsPerPage }, this.search());
  };

  renderTile = tile => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    const { selectedImg, imagesDimensions } = this.state;
    const dimensions = imagesDimensions[tile.id];

    return (
      <GridListTile
        key={tile.filename}
        onClick={dimensions ? this.selectImage(tile) : null}
        className={selectedImg && tile.id == selectedImg.id ? classes.selectedTile : ''}
      >
        <ImageDialog
          imageId={tile.id}
          title={m.dialog_infoMedia}
          dimensions={dimensions || null}
          deleteCallback={this.handleDeleteCallBack}
        >
          <Button
            size="small"
            variant="contained"
            color="info"
            className={classes.infoIcon}
            onClick={this.handleOpenInfo(tile.id)}
          >
            {m.button_info}
            <InfoIcon />
          </Button>
        </ImageDialog>
        <img className={classes.img} src={tile.originalUrl} alt={tile.filename} onLoad={this.onImgLoad(tile.id)} />
        {!dimensions ? <LinearProgress className={classes.loader} /> : null}
        {tile.width ? (
          <Typography variant="caption" className={classes.size}>{`${tile.width}x${tile.height}`}</Typography>
        ) : null}
      </GridListTile>
    );
  };

  renderFormatSelection = () => {
    const { format, isOriginalDisabled } = this.state;
    const { chooseFormat } = this.props;

    if (!chooseFormat) {
      return null;
    }

    return (
      <div
        style={{
          padding: '12px 24px 0',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DialogContentText>{'Format'}</DialogContentText>
        <ToggleButtonGroup
          value={format}
          exclusive
          onChange={this.handleFormat}
          aria-label="image formats"
          size="small"
        >
          <ToggleButton value="original" aria-label="original format" disabled={isOriginalDisabled}>
            Original
          </ToggleButton>
          <ToggleButton value="medium" aria-label="medium format">
            Medium
          </ToggleButton>
          <ToggleButton value="large" aria-label="large format">
            Large
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  handleFormat = (event, format) => {
    if (!format) {
      return null;
    }
    this.setState({ format });
  };

  handleOpenInfo = id => e => {
    e.stopPropagation(e);
    this.onImgLoad(id);
  };

  onImgLoad = id => e => {
    const { imagesDimensions } = this.state;

    if (!imagesDimensions[id]) {
      const img = e.target;

      this.setState({
        imagesDimensions: {
          ...imagesDimensions,
          [id]: { w: img.naturalWidth, h: img.naturalHeight },
        },
      });
    }
  };

  render() {
    const {
      classes,
      images,
      width,
      pagination,
      total,
      intl: { messages: m },
    } = this.props;

    let columns = 5;
    let cellHeight = 200;

    if (width == 'xs') {
      columns = 2;
      cellHeight = 150;
    }
    if (width == 'sm') {
      columns = 4;
      cellHeight = 160;
    }

    return (
      <React.Fragment>
        <DialogTitle key="dialog-title">{m.media_libraryTitle}</DialogTitle>,
        <div style={{ margin: '0 24px' }}>
          <DialogContentText>{m.media_libraryDescription}</DialogContentText>
        </div>
        <div>
          <div className={classes.inputContainer}>
            <TextField margin="dense" name="filename" label={m.field_filename} onChange={this.onChange} />
            <Toggle
              labelKey="filter_fuzzy"
              input={{
                name: 'fuzzy',
                value: this.state.fuzzy,
                onChange: this.onChangeFuzzy,
              }}
            />
            <Toggle
              labelKey="filter_hd"
              input={{
                name: 'hdOnly',
                value: this.state.hdOnly,
                onChange: this.onChangeHdOnly,
              }}
            />
            <div style={{ flex: 1 }} />
          </div>
        </div>
        <DialogContent key="dialog-content">
          <GridList className={classes.grid} cellHeight={cellHeight} cols={columns}>
            <GridListTile key="Subheader" cols={columns} style={{ height: 'auto' }}>
              <Subheader component="div">{m.app_images}</Subheader>
            </GridListTile>
            {images && images.list.map(this.renderTile)}
          </GridList>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {this.renderFormatSelection()}
          <Table>
            <FooterPagination
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
              defaultItemPerPage={50}
            />
          </Table>
        </div>
        <DialogActions key="dialog-actions">
          <Button onClick={this.onCancel} color="primary">
            {m.button_cancel}
          </Button>
          <Button onClick={this.onConfirm} color="primary">
            {m.button_choose}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}
