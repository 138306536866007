import Diff from 'Components/Diff';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPersonLink } from './actions';
import { getPersonUsualName } from './utils';

@connect(store => ({
  links: store.person.link,
}))
export default class PersonDiff extends Component {
  componentDidMount() {
    const { dispatch, oldValue, newValue } = this.props;

    for (let i = 0; i < oldValue.length; i += 1) {
      dispatch(fetchPersonLink(oldValue[i]));
    }
    for (let i = 0; i < newValue.length; i += 1) {
      dispatch(fetchPersonLink(newValue[i]));
    }
  }

  getName(id) {
    const { links } = this.props;
    const person = links[id];
    if (!person || person.fetching) return `person:${id}`;
    return getPersonUsualName(person);
  }

  render() {
    const { oldValue, newValue } = this.props;

    const oldListWithLabel = oldValue.map(v => this.getName(v));
    const newListWithLabel = newValue.map(v => this.getName(v));

    const o = `[ ${oldListWithLabel.join(' , ')} ]`;
    const n = `[ ${newListWithLabel.join(' , ')} ]`;
    return <Diff oldValue={o} newValue={n} />;
  }
}
