// use of events to transform non-async method into async
import EventEmitter from 'events';
const myEmitter = new EventEmitter();

export function asyncImageMedia() {
  return function(dispatch) {
    dispatch({
      type: 'EDITOR_OPEN_IMAGE',
    });

    return new Promise(resolve => {
      myEmitter.on('closeImageMedia', resolve);
    });
  };
}

export function closeImageMedia(response) {
  myEmitter.emit('closeImageMedia', response);

  return {
    type: 'EDITOR_CLOSE_IMAGE',
    payload: { response },
  };
}
