import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import DatetimeInput, { formatUtc } from 'Components/inputs/Datetime';
import SelectConstant from 'FDComponents/constants/Select';
import TournamentInput from "FD/tournaments/Input";
import { Alert } from "@material-ui/lab";

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.primary[500]}`,
  },
});

@withStyles(styles)
@reduxForm({
  form: 'mercaot_survey_form',
  validate: values => {
    const errors = {};
    if (!values.type) {
      errors.type = 'error_required';
    }
    if (!values.tournament) {
      errors.type = 'error_required';
    }
    if (!values.startDate) {
      errors.startDate = 'error_required';
    }
    if (!values.endDate) {
      errors.endDate = 'error_required';
    }
    return errors;
  },
})
@injectIntl
export default class MercatoSurveyDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
    this.props.reset();
  };
  handleConfirm = mercatoSurvey => {
    this.handleClose();
    this.props.onCreate(mercatoSurvey);
  };

  render() {
    const {
      title,
      handleSubmit,
      pristine,
      submitting,
      classes,
      children,
      intl: { messages: m },
      disabled,
      valid,
    } = this.props;
    const { open } = this.state;

    return (
      <div>
        {React.cloneElement(children, { onClick: this.handleOpen, key: 'open_button', disabled })}
        <Dialog open={open} classes={{ paper: classes.body }}>
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(this.handleConfirm)}>
            <DialogContent>
              <Alert severity="warning">{m.field_mercatoSurveyOnlyOneActive}</Alert>
              <Field name="type" component={SelectConstant} label={m.field_type} entity={'mercatoSurvey'} required />
              <Alert severity="warning">{m.field_mercatoSurveySummerTournamentWarning}</Alert>
              <Alert severity="warning">{m.field_mercatoSurveyWinterTournamentWarning}</Alert>
              <Field name="tournament" component={TournamentInput} label={m.field_tournament} isActiveOnly={false} required />
              <Field
                name="startDate"
                component={DatetimeInput}
                label={m.field_startDate}
                normalize={formatUtc}
                styles={{ paddingTop: 8 }}
                required
              />
              <Field
                name="endDate"
                component={DatetimeInput}
                label={m.field_endDate}
                normalize={formatUtc}
                styles={{ paddingTop: 8 }}
                required
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} disabled={submitting}>
                {m.button_cancel}
              </Button>
              <Button color="primary" variant="contained" type="submit" disabled={!valid || pristine || submitting}>
                {m.button_create}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
