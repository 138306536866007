import { combineReducers } from 'redux';

import commonReducers from './common/reducers';
import footdataReducers from './footdata/reducers';
import footmercatoReducers from './footmercato/reducers';

export default combineReducers({
  ...commonReducers,
  ...footdataReducers,
  ...footmercatoReducers,
});
