import React, { Component } from 'react';
import { getProject } from 'Services/project';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import toggleDrawer from 'Components/Nav/actions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Search from '../search/Input';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ProjectMenu from 'Components/ProjectMenu';

const styles = {
  title: {
    marginLeft: 24,
    letterSpacing: 4,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
  },
  itemEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  avatar: {
    margin: '-5px 8px -5px -5px',
    borderRadius: '25%',
  },
};

@withStyles(styles)
@injectIntl
@connect(store => ({
  locale: store.intl.locale,
  me: store.me,
}))
export default class AppToolbar extends Component {
  state = {
    anchorAccount: null,
  };

  handleClickAccount = event => {
    this.setState({ anchorAccount: event.currentTarget });
  };
  handleCloseAccount = () => {
    this.setState({ anchorAccount: null });
  };

  openMenu = () => {
    const { dispatch } = this.props;
    dispatch(toggleDrawer(true));
  };

  getGravatar = (hash = '000000000000', size = 24) =>
    `https://www.gravatar.com/avatar/${hash}?s=${size}`;

  render() {
    const {
      classes,
      me,
      intl: { messages: m },
    } = this.props;
    const { anchorAccount } = this.state;

    return (
      <AppBar position="fixed">
        <Toolbar>
          <Grid container spacing={0}>
            <Grid item xs md={3} classes={{ item: classes.items }}>
              <Hidden lgUp>
                <IconButton color="inherit" aria-label="Menu" onClick={this.openMenu}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <ProjectMenu />
            </Grid>
            <Grid item xs md={6} classes={{ item: classes.itemEnd }}>
              <Hidden smDown>
                <Search />
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="Search"
                  href={`#${getProject().url}/search`}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  aria-owns={anchorAccount ? 'account-menu' : null}
                  color="inherit"
                  aria-label="Account"
                  aria-haspopup="true"
                  onClick={this.handleClickAccount}
                >
                  <PersonIcon />
                </IconButton>
              </Hidden>
            </Grid>
            <Hidden mdDown>
              <Grid item md={3} classes={{ item: classes.itemEnd }}>
                <Button
                  aria-owns={anchorAccount ? 'account-menu' : null}
                  color="inherit"
                  aria-haspopup="true"
                  onClick={this.handleClickAccount}
                >
                  <img className={classes.avatar} src={this.getGravatar(me.hash)} />
                  {me.firstname}.{me.lastname && me.lastname[0]}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
        <Menu
          id="account-menu"
          anchorEl={anchorAccount}
          open={Boolean(anchorAccount)}
          onClose={this.handleCloseAccount}
        >
          <MenuItem
            component="a"
            href={`#${getProject().url}/users/${me.id}`}
            onClick={this.handleCloseAccount}
          >
            {m.app_myProfile}
          </MenuItem>
          <MenuItem
            component="a"
            href={`#${getProject().url}/users/${me.id}/password`}
            onClick={this.handleCloseAccount}
          >
            {m.app_userPasswordTitle}
          </MenuItem>
          <MenuItem component="a" href="/logout" onClick={this.handleCloseAccount}>
            {m.app_deconnection}
          </MenuItem>
        </Menu>
      </AppBar>
    );
  }
}
