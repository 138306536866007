import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

export function updateMembership(id, membership) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_MEMBERSHIP', payload: id });

    return rest.put(`memberships/${id}`, membership).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_membershipUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_MEMBERSHIP_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_MEMBERSHIP_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addMembership(membership) {
  return function(dispatch) {
    dispatch({ type: 'ADD_MEMBERSHIP' });

    return rest
      .post('memberships', membership)
      .then(
        res => dispatch({ type: 'ADD_MEMBERSHIP_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_MEMBERSHIP_REJECTED', payload: err.response })
      );
  };
}

export function deleteMembership(membership) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_MEMBERSHIP' });

    return rest.delete(`memberships/${membership.id}`).then(
      () =>
        dispatch({
          type: 'DELETE_MEMBERSHIP_FULFILLED',
          payload: membership,
        }),
      err =>
        dispatch({
          type: 'DELETE_MEMBERSHIP_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchMembership(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_MEMBERSHIP' });

    return rest
      .get(`memberships/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_MEMBERSHIP_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_MEMBERSHIP_REJECTED', payload: err.response })
      );
  };
}

export function fetchClubActiveMemberships(club) {
  const params = {
    club,
    isActive: 1,
    groups: ['read', '_person', '_image'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUB_MEMBERSHIPS', payload: club });

    return rest.get('memberships', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_CLUB_MEMBERSHIPS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_CLUB_MEMBERSHIPS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchPlayerMemberships(person) {
  const params = {
    person,
    pagination: 0,
    groups: ['read', '_club', '_image'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYER_MEMBERSHIPS', payload: person });

    return rest.get('memberships', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PLAYER_MEMBERSHIPS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PLAYER_MEMBERSHIPS_REJECTED',
          payload: err.response,
        })
    );
  };
}
