import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as React from 'react';
import {Field} from "redux-form";
import { InputCollection } from '../../components/InputCollection';
import { searchCompetitions } from '../actions';
import {CompetitionCollectionChip} from "../CompetitionInputCollection/CompetitionCollectionChip.component";
import {IComponentProps} from "./CompetitionTournamentInput.connector";


export interface IComponentState {
    open: boolean;
    anchorEl: undefined,
}

export class CompetitionTournamentInput extends React.Component<IComponentProps,IComponentState> {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: undefined,
        };

    }
    public componentWillUpdate(nextProps: IComponentProps) {
        const { fetchCompetitionTournaments } = this.props;
        if (nextProps.input.name  === 'competition' && nextProps.input.value !== this.props.input.value) {
            fetchCompetitionTournaments(nextProps.input.value[0]);
        }
    }


    public render() {
        const {
            input,
            meta,
            tournaments,
        } = this.props;

        return (
            <React.Fragment>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    {/*
      // @ts-ignore */}
                    <InputCollection
                        input={input}
                        meta={meta}
                        search={searchCompetitions}
                        getLabel={this.getLabel}
                        CollectionChip={CompetitionCollectionChip}
                    />
                </Grid>
                    { tournaments && tournaments.length > 0 && (
                        <Field name="tournamentId" component={this.renderSelectTournament} autoFocus />
                    )
                    }
                </Grid>

            </React.Fragment>
        );
    }

    public renderSelectTournament = ({ input, meta: { touched, error } }) => {
    const {
        intl: { messages: m },
        tournaments,
    } = this.props;
        return (
        <Grid item xs={12} sm={4} >
            <FormControl fullWidth error={Boolean(touched && error)}>
                <InputLabel htmlFor={`select_tournamentId`}>{m.app_tournaments}</InputLabel>
                <Select
                    name={input.name}
                    value={input.value}
                    onChange={event => input.onChange(event.target.value)}
                    input={<Input id={`select_tournamenId`} />}
                    MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
                >
                    {tournaments.map(item => (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>))}
                </Select>
                {touched && error && <FormHelperText>{error}</FormHelperText>}
            </FormControl>
        </Grid>
    )
    };
    public handleClick = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    private getLabel = competition => {
        let areaLabel = '';
        if (competition.area) {
            areaLabel = competition.area.codeFIFA || competition.area.codeISO;
        }
        return areaLabel ? `${competition.name} (${areaLabel})` : competition.name;
    };




}
