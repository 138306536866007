import { SubmissionError } from 'redux-form';
import { getProject, ILocale } from '../../common/services/project';
import rest from '../../common/services/restInternal';
import { updateArticle } from '../articles/actions';

interface IPush {
  text: string;
  locale: ILocale;
  scheduledAt: string;
  targetUrl: string;
  title: string;
  project: 'footmercato';
  targetSegmentId: 'global' | 'debug' | 'preferences';
  excludedFootdataCompetitionIds: string[];
  excludedFootdataTeamIds: string[];
  excludedFootdataMatchIds: string[];
  excludedFootdataPersonIds: string[];
  footdataCompetitionIds: string[];
  footdataTeamIds: string[];
  footdataMatchIds: string[];
  footdataPersonIds: string[];
}

export const postPush = (push: IPush, article: any) => {
  push.project = 'footmercato';
  push.locale = getProject().lang;

  const data = new FormData();

  Object.entries(push).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach((subValue, index) => data.append(`${key}[${index}]`, subValue));
      } else {
        data.append(key, value);
      }
    }
  });

  return async (dispatch: any) => {
    dispatch({ type: 'ADD_PUSH' });
    return rest.post('push', data).then(
      (res: any) => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_pushSent', type: 'success' },
        });

        dispatch({ type: 'ADD_PUSH_FULFILLED', payload: res.data });

        if (article) {
          dispatch(updateArticle(article.id, { hasBeenPushed: true }));
        }

        return;
      },
      (err: any) => {
        if (
          err.response &&
          err.response.data.type === 'https://symfony.com/errors/validation'
        ) {
          const { detail, title } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: detail, type: 'error' },
          });
          throw new SubmissionError(title);
        }
        return dispatch({ type: 'ADD_PUSH_REJECTED', payload: err.response });
      }
    );
  };
};
