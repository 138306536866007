import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from '../../../common/services/project';
import { IPartner } from '../typings';

interface IProps {
  tableData: IPartner;
}

export class PartnerRow extends Component<IProps> {
  public render() {
    const { tableData } = this.props;
    const { id, name, sectorTranslated } = tableData;

    return (
      // @ts-ignore href
      <TableRow hover href={`#${getProject().url}/partners/${id}`} component="a">
        <TableCell>{name}</TableCell>
        <TableCell>{sectorTranslated}</TableCell>
      </TableRow>
    );
  }
}
