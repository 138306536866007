import React, { Component } from 'react';
import Search from './Input';


export default class ArticleInput extends Component {
    render() {
        return (
            <Search
                input={{
                    name: this.props.input.name,
                    onChange: this.props.input.onChange,
                    value: this.props.input.value,
                    required: this.props.required,
                }}
            />
        );
    }
}
