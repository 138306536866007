import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { Field, reduxForm } from 'redux-form';
import DatetimeInput, { formatUtc } from '../../../common/components/inputs/Datetime';
import IntInput, { normalizeInt } from '../../../common/components/inputs/Integer';
import Text from '../../../common/components/inputs/Text';
import TeamInput from '../../teams/Input';
import TournamentInput from '../../tournaments/Input';
import { TYPE_SQUAD } from '../typings';

interface ILineupCreateFormData {
  title?: string;
  type?: string;
  startDate?: string;
  endDate?: string;
  maxSelectablePlayers?: number;
  team?: string;
  tournament?: string;
}

class LineupSurveyDialogComponent extends React.Component<any, any> {
  public state = {
    open: false,
  }

  public render() {
    const {
      title,
      children,
      disabled,
      handleSubmit,
      pristine,
      submitting,
      valid,
      intl: { messages: m },
    } = this.props;
    const {open} = this.state;

    return (
      <div>
        {
          // @ts-ignore
          React.cloneElement(children, { onClick: this.handleOpen, key: 'open_button', disabled })
        }
        <Dialog open={open}>
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(this.handleConfirm)}>
            <DialogContent>
              <Field name="title" component={Text} required={true} />
              <Field
                name="team"
                component={TeamInput}
                styles={{ marginTop: '8px' }}
                required={true}
              />
              <Field
                name="tournament"
                component={TournamentInput}
                styles={{ marginTop: '8px' }}
                required={true}
              />
              <Field name={'maxSelectablePlayers'} component={IntInput} normalize={normalizeInt} required={true} />
              <Field
                name="startDate"
                component={DatetimeInput}
                label={m.field_startDate}
                normalize={formatUtc}
                styles={{ marginTop: '8px' }}
                required={true}
              />
              <Field
                name="endDate"
                component={DatetimeInput}
                label={m.field_endDate}
                normalize={formatUtc}
                styles={{ marginTop: '8px' }}
                margin="dense"
                required={true}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} disabled={submitting}>
                {m.button_cancel}
              </Button>
              <Button color="primary" variant="contained" type="submit" disabled={!valid || pristine || submitting}>
                {m.button_create}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }

  private handleOpen = () => {
    this.setState({ open: true });
  };

  private handleClose = () => {
    this.setState({ open: false });
    this.props.reset();
  };

  private handleConfirm = lineupSurvey => {
    this.handleClose();
    lineupSurvey.type = TYPE_SQUAD;
    this.props.onCreate(lineupSurvey);
  };
}

export const LineupSurveyDialog = compose<InjectedIntlProps>(
  injectIntl,
  reduxForm<ILineupCreateFormData>({
    form: 'lineup_survey_add',
    validate: values => {
      const errors: any = {};
      if (!values.title) {
        errors.title = 'error_required';
      }
      if (!values.startDate) {
        errors.startDate = 'error_required';
      }
      if (!values.endDate) {
        errors.endDate = 'error_required';
      }
      if (!values.maxSelectablePlayers) {
        errors.maxSelectablePlayers = 'error_required';
      }
      return errors;
    },
  })
)(LineupSurveyDialogComponent);
