import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ToggleInput, { normalizeBoolean } from 'Components/inputs/Toggle';

@injectIntl
@connect()
export default class MatchHighlightedRow extends Component {
  state = {
    isHighlighted: false,
  };

  componentDidMount() {
    this.setState({ isHighlighted: this.props.match.isHighlighted });
  }

  handleChange = value => {
    const { onChange, match } = this.props;
    this.setState({ isHighlighted: value });
    onChange(match['@id'], { isHighlighted: value });
  };

  render() {
    const { match } = this.props;
    const { isHighlighted } = this.state;

    return (
      <TableRow>
        <TableCell padding="none">
          {`${moment(match.date).format('L')} - ${moment(match.date).format('LT')}`}
        </TableCell>
        <TableCell>{match.description}</TableCell>
        <TableCell padding="none">
          <ToggleInput
            input={{
              name: 'isHighlighted',
              onChange: ev => this.handleChange(ev.target.checked),
              value: isHighlighted,
            }}
            normalize={normalizeBoolean}
            labelKey="field_setHighlighted"
          />
        </TableCell>
      </TableRow>
    );
  }
}
