import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchArticleRevision, fetchDiffRevisions, rollbackRevision } from '../actions';
import { fetchArticleId } from '../../articles/actions';

import TopBar from '../../components/TopBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';
import RestoreIcon from '@material-ui/icons/Restore';
import Versions from './Versions';
import RevisionDiff from './RevisionDiff';
import Layout from 'Components/Layout';

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  content: {
    margin: theme.spacing(2),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  id: props.match.params.topicId,
  article: store.article.detail[props.match.params.topicId],
  revision: store.revision[props.match.params.topicId],
  diff: store.revision.diff,
  fetching: store.revision.fetching,
}))
export default class Detail extends Component {
  state = {
    origin: 1,
    target: 0,
  };

  componentDidMount() {
    this.fetchData();
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  restore = index => {
    const { dispatch, revision, id } = this.props;
    dispatch(rollbackRevision(revision.list[index].id)).then(() => {
      this.fetchData(id);
    });
  };

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchArticleId(targetId || id));
    dispatch(fetchArticleRevision(targetId || id)).then(({ payload }) => {
      if (payload.list && payload.list.length > 1) {
        dispatch(fetchDiffRevisions(payload.list[0].id, payload.list[1].id)).then(
          this.setState({ origin: 1, target: 0 })
        );
      }
    });
  };

  getDiff = (target, origin) => {
    const { dispatch, revision } = this.props;
    dispatch(fetchDiffRevisions(revision.list[target].id, revision.list[origin].id)).then(
      this.setState({ origin, target })
    );
  };

  render() {
    const {
      id,
      fetching,
      revision = {},
      article = {},
      diff,
      classes,
      intl: { messages: m, formatMessage: f },
    } = this.props;
    const { origin, target } = this.state;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }

    return (
      <Layout
        id="revision-layout"
        top={
          <TopBar
            crumbStack={[{ labelKey: 'app_revisions' }, { label: article.title || id }]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">
                {`${m.app_revisions} [ ${article.title || id} ]`}
              </Typography>
              <div style={{ flex: 1 }} />
              <Button
                variant="contained"
                href={`#${getProject().url}/articles/${article.id}`}
                className={classes.leftButton}
              >
                <DescriptionIcon className={classes.leftIcon} />
                {m.revision_backArticle}
              </Button>
            </Toolbar>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <div className={classes.content}>
                  <Typography variant="headline">
                    {f(
                      { id: 'revision_differenceBetweenVersions' },
                      { origin: origin + 1, target: target + 1 }
                    )}
                  </Typography>
                  <RevisionDiff revision={diff} />
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }} />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!target}
                      className={classes.leftButton}
                      onClick={() => this.restore(target)}
                    >
                      <RestoreIcon className={classes.leftIcon} />
                      {f({ id: 'revision_restoreTarget' }, { version: target + 1 })}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.leftButton}
                      onClick={() => this.restore(origin)}
                    >
                      <RestoreIcon className={classes.leftIcon} />
                      {f({ id: 'revision_restoreOrigin' }, { version: origin + 1 })}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        }
        left={
          <Paper elevation={2}>
            <Versions
              currentOrigin={origin}
              currentTarget={target}
              revision={revision}
              onChange={this.getDiff}
            />
          </Paper>
        }
      />
    );
  }
}
