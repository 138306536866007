import React, { Component } from 'react';

import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import toggleDrawer from './Nav/actions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';

const styles = {
  title: {
    marginLeft: 24,
    letterSpacing: 4,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
  },
  itemEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  avatar: {
    margin: '-5px 8px -5px -5px',
    borderRadius: '25%',
  },
};

@withStyles(styles)
@injectIntl
@connect(store => ({
  locale: store.intl.locale,
  me: store.me,
}))
export default class AppToolbar extends Component {
  openMenu = () => {
    const { dispatch } = this.props;
    dispatch(toggleDrawer(true));
  };

  getGravatar = (hash = '000000000000', size = 24) =>
    `https://www.gravatar.com/avatar/${hash}?s=${size}`;

  render() {
    const { classes, me } = this.props;

    return (
      <AppBar position="fixed">
        <Toolbar>
          <Grid container spacing={0}>
            <Grid item xs={3} md={3} classes={{ item: classes.items }}>
              <Hidden lgUp>
                <IconButton color="inherit" aria-label="Menu" onClick={this.openMenu}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Button href="#" size="small">
                <HomeIcon color="inherit" />
              </Button>
              <Hidden mdDown>
                <Typography variant="h6" color="inherit" className={classes.title}>
                  HOME
                </Typography>
              </Hidden>
            </Grid>
            <Grid item xs md={6} classes={{ item: classes.itemEnd }} />
            <Hidden mdDown>
              <Grid item md={3} classes={{ item: classes.itemEnd }}>
                <Button color="inherit">
                  <img className={classes.avatar} src={this.getGravatar(me.hash)} />
                  {me.firstname}.{me.lastname && me.lastname[0]}
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}
