import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import Text from '../../common/components/inputs/Text';
import DatetimeInput, { formatUtc } from 'Components/inputs/Datetime';
import SelectConstant from 'FDComponents/constants/Select';

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.primary[500]}`,
  },
});

@withStyles(styles)
@reduxForm({
  form: 'quizz_form',
  initialValues: {
    question: [{ label: '' }],
  },
  validate: values => {
    const errors = {};
    if (!values.question) {
      errors.question = 'error_required';
    }
    if (!values.startDate) {
      errors.startDate = 'error_required';
    }
    if (!values.options) {
      errors.options = 'error_required';
    }
    if (!values.type) {
      errors.type = 'error_required';
    }
    return errors;
  },
})
@injectIntl
export default class QuizzDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
    this.props.reset();
  };
  handleConfirm = survey => {
    this.handleClose();
    this.props.onCreate(survey);
  };

  render() {
    const {
      title,
      handleSubmit,
      pristine,
      submitting,
      classes,
      children,
      intl: { messages: m },
      disabled,
      valid,
    } = this.props;
    const { open } = this.state;

    return (
      <div>
        {React.cloneElement(children, { onClick: this.handleOpen, key: 'open_button', disabled })}
        <Dialog open={open} classes={{ paper: classes.body }}>
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(this.handleConfirm)}>
            <DialogContent>
              <Field name="title" component={Text} label={m.field_name} autoFocus />
              <Field name="type" component={SelectConstant} label={m.field_type} entity={'quizz'} />
              <Field
                  name="startDate"
                  component={DatetimeInput}
                  label={m.field_startDate}
                  normalize={formatUtc}
                  styles={{ paddingTop: 8 }}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} disabled={submitting}>
                {m.button_cancel}
              </Button>
              <Button color="primary" variant="contained" type="submit" disabled={!valid || pristine || submitting}>
                {m.button_create}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
