import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function WhistleIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M132 261c3 1 5 4 4 8-1 3-4 5-7 4-3-1-5-4-4-8 0-3 4-5 7-4z m43 6c0 3-3 6-6 6-4 0-6-3-6-6 0-3 2-6 6-6 3 0 6 3 6 6z m33-19c0 3-3 6-6 6-4 0-6-3-6-6 0-3 2-6 6-6 3 0 6 3 6 6z m7-35c1-3 4-5 7-4 4 1 5 4 5 8-1 3-5 5-8 4-3-1-5-5-4-8z m7-31c-3 1-6-1-7-4-1-3 1-6 4-7 3-1 7 1 8 4 0 3-1 6-5 7z m-25-35c-2-2-2-6 0-8 3-2 7-2 9 0 2 2 2 6 0 8-2 3-6 3-9 0z m-22-23c0 3-3 6-6 6-4 0-6-3-6-6 0-3 2-6 6-6 3 0 6 3 6 6z m-43 6c-3 1-6-1-7-4-1-3 1-7 4-8 3-1 6 1 7 5 1 3-1 6-4 7z m-39 17c-2-2-2-6 0-8 2-2 6-2 8 0 2 2 2 6 0 8-2 3-6 3-8 0z m-9 29c0 4-3 6-6 6-3 0-6-2-6-6 0-3 3-5 6-5 3 0 6 2 6 5z m0 39c0 3-3 5-6 5-3 0-6-2-6-5 0-4 3-6 6-6 3 0 6 2 6 6z m77-8c-1 1-2 1-2 2l-57 43c-3 2-7 2-9 0-2-2-2-6 0-8l43-58c1-1 2-1 2-2 6-6 17-6 23 0 6 6 6 17 0 23z m30 137c3 0 5 2 5 5l0 43c0 3-2 5-5 5-3 0-5-2-5-5l0-43c1-3 2-5 5-5z m19-2c1 0 2 0 3 0 2 0 4 1 5 2l34 78c2 3 0 6-2 7-3 1-6 0-7-3l-35-77c-2-3 0-6 2-7z m-46 2c1-1 2-2 4-2 1 0 2 0 3 0 2 1 3 5 2 7l-43 74c-1 3-4 3-7 2-2-1-3-5-2-7z m310-40c-1 2-3 3-5 3l-310 0c-3 1-6 1-10 1-36 0-69-18-89-45-2 4-6 7-11 7-7 0-12-5-12-12 0-6 5-12 12-12l0 0c-8-15-12-32-12-51 0-62 50-112 112-112 61 0 110 48 113 108l175 34c2 0 4 1 5 3l33 70c1 2 1 5-1 6z m-431-46c0 3 3 6 6 6 3 0 5-2 5-6 0-3-2-5-5-5-3 0-6 3-6 5z m106-162c-55 0-99 44-99 99 0 55 44 100 99 100 55 0 100-45 100-100 0-55-44-99-100-99z m283 141l-170-33c-3 39-27 73-60 91l257 0z"
        />
      </g>
    </SvgIcon>
  );
}
