import moment from 'moment';
import React, { Component } from 'react';
import { PhaseMatch } from './PhaseMatch';

interface IMatch {
  id: string;
  date: string;
  displayDay: boolean;
  description: string;
  homeTeamDescription: string;
  awayTeamDescription: string;
}
interface IProps {
  matches?: IMatch[];
}

export class MatchesContainer extends Component<IProps> {
  public render() {
    const { matches } = this.props;
    if (!matches || !matches.length) {
      return null;
    }

    let currentDate = '';

    return matches.map(m => {
      const myDate = moment(m.date).format('L');
      if (currentDate !== myDate) {
        m.displayDay = true;
        currentDate = myDate;
      }
      return <PhaseMatch key={m.id} match={m} />;
    });
  }
}
