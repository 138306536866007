import {
  buildUrl,
  getEmbedConfig,
  getPathParameters,
  getQueryParameters,
} from '../../../../footmercato/articles/embed/utils';
import { getInstagram, getTiktok, getTwitter } from '../../../services/embeds';

export const handlePreviewMarkdown = (markdown: string) => {
  if (!markdown) return markdown;

  handleScriptToLoad(markdown);
  return transformMarkdown(markdown);
};

/**
 * Transform custom markdown to render standard markdown
 * @param markdown
 */
const transformMarkdown = (markdown: string) => {
  let updatedMarkdown = markdown;

  // format old markdown [link->url]
  updatedMarkdown = updatedMarkdown.replace(
    /\[(.*?)->([^\s)][\S]*?)]/g,
    (_, txt, href) => `<a href="${href}">${txt}</a>`
  );

  // format old markdown {{{title}}}
  updatedMarkdown = updatedMarkdown.replace(/\{\{\{([^\s_][\s\S]*?)\}\}\}/g, (_, txt) => `<h3>${txt}</h3>`);

  // format old markdown {{bold}}
  updatedMarkdown = updatedMarkdown.replace(/\{\{([^\s_][\s\S]*?)\}\}/g, (_, txt) => `<strong>${txt}</strong>`);

  // format [txt](url){.className}
  updatedMarkdown = updatedMarkdown.replace(
    /\[(.*?)\]\(([^\s)][\S]*?)\)\{.([^\s_][\s\S]*?)\}/g,
    (_, txt, href, className) => `<a href="${href}" class="${className}">${txt}</a>`
  );

  updatedMarkdown = updatedMarkdown.replace(
    /```(.*)```/g,
    (_, code) => `<iframe src="${getEmbedUrl(code)}" frameBorder=0 height="460" width="460"></iframe>`
  );

  return updatedMarkdown;
};

const getEmbedUrl = (code: string) => {
  const embedParams = JSON.parse(code);
  const path = embedParams.key.replace('api_embed_', '').replaceAll('_', '-');
  const embedsConfig = getEmbedConfig();
  // find in embedConfig the object with the same routeName
  const embed = Object.values(embedsConfig).find((e: any) => e.path === path);
  if (embed) {
    const pathParameters = embed.pathParameters !== undefined ? getPathParameters(embedParams, embed) : undefined;
    const queryParameters = embed.queryParameters !== undefined ? getQueryParameters(embedParams, embed) : undefined;

    return buildUrl(path, pathParameters, queryParameters);
  }
  return '';
};

const handleScriptToLoad = (markdown: string) => {
  if (markdown.indexOf('twitter-tweet') !== -1) {
    getTwitter();
  }
  if (markdown.indexOf('tiktok-embed') !== -1) {
    getTiktok();
  }
  if (markdown.indexOf('instagram-media') !== -1) {
    getInstagram();
  }
};

const whiteListSrciptSrc = 'player.daznservices.com';
const replaceScriptTag = (result: string): string => (result.indexOf(whiteListSrciptSrc) !== -1 ? result : '');

/**
 * Clean unwanted markdown before saving data
 * @param markdown
 */
export const cleanMarkdown = (markdown: string) => {
  if (!markdown) return markdown;
  return (
    markdown
      // removes <script>
      .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/g, replaceScriptTag)
      // places <iframe> at the beginning of line
      .replace(/.(<iframe)/gi, '\n<iframe')
  );
};
