export default function reducer(
  state = {
    players: {},
    clubs: {},
    types: null,
    notCombinable: [],
  },
  action
) {
  switch (action.type) {
    case 'GET_STATSTYPES_FULFILLED': {
      const { collection } = action.payload;
      const groups = {};
      
      const notCombinable = [];
      for (let i = 0; i < collection.length; i += 1) {
        const { code, name, isCombinable } = collection[i];
        const category = collection[i].category || 'other';
        if (!groups[category]) {
          groups[category] = []
        }

        groups[category].push({ code, name, isCombinable, category });
        if (!isCombinable) {
          notCombinable.push(code);
        }
      }

      return {
        ...state,
        notCombinable,
        types: groups,
      };
    }
    case 'GET_CLUBSSTATS': {
      const { type } = action.payload;
      return {
        ...state,
        clubs: {
          ...state.clubs,
          [type]: {
            ...state.clubs[type],
            loading: true,
          },
        },
      };
    }
    case 'GET_CLUBSSTATS_FULFILLED': {
      const { collection, type } = action.payload;
      return {
        ...state,
        clubs: {
          ...state.clubs,
          [type]: {
            loading: false,
            list: collection,
          },
        },
      };
    }
    case 'GET_CLUBSSTATS_NOTCOMBINABLE': {
      const { type } = action.payload;
      return {
        ...state,
        clubs: {
          ...state.clubs,
          [type]: {
            loading: false,
            list: [],
          },
        },
      };
    }
    case 'GET_PLAYERSSTATS': {
      const { type } = action.payload;
      return {
        ...state,
        players: {
          ...state.players,
          [type]: {
            ...state.players[type],
            loading: true,
          },
        },
      };
    }
    case 'GET_PLAYERSSTATS_FULFILLED': {
      const { collection, type } = action.payload;
      return {
        ...state,
        players: {
          ...state.players,
          [type]: {
            loading: false,
            list: collection,
          },
        },
      };
    }
    case 'GET_PLAYERSSTATS_NOTCOMBINABLE': {
      const { type } = action.payload;
      return {
        ...state,
        players: {
          ...state.players,
          [type]: {
            loading: false,
            list: [],
          },
        },
      };
    }

    default:
      return state;
  }
}
