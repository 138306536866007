import rest from 'Services/restFootData';

export default function fetchClassement(phase, type) {
  const params = { phase, type };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLASSEMENT' });
    return rest.get('classements', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_CLASSEMENT_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_CLASSEMENT_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_CLASSEMENT_REJECTED', payload: err.response })
    );
  };
}

export function fetchTournamentClassement(phase, type) {
  const params = { phase, type };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TOURNAMENT_CLASSEMENT' });
    return rest.get('classements', { params }).then(
      res => {
        const { collection } = res.data;
        if (collection) {
          return dispatch({
            type: 'FETCH_TOURNAMENT_CLASSEMENT_FULFILLED',
            payload: collection.length > 0 ? collection[0] : [],
          });
        }
        return dispatch({ type: 'FETCH_TOURNAMENT_CLASSEMENT_NOTFOUND' });
      },
      err =>
        dispatch({
          type: 'FETCH_TOURNAMENT_CLASSEMENT_REJECTED',
          payload: err.response,
        })
    );
  };
}
