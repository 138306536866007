import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from 'react';
import { injectIntl } from 'react-intl';
import InputLabel from "@material-ui/core/InputLabel";


const ICONNAME = [
    {
        label: 'main',
        value: 'main',
    },
    {
        label: 'attacking',
        value: 'attacking',
    },
    {
        label: 'defending',
        value: 'defending',
    },
    {
        label: 'passing',
        value: 'passing',
    },
    {
        label: 'goalkeeper',
        value: 'goalkeeper',
    },
];

function TypeCategorySelect({
                            input: { name, value, onChange },
                            labelKey,
                            intl: { formatMessage: f },
                            meta: { error, touched },
                        }
) {
    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
    return (
        <FormControl style={{ minWidth: 300 }} error={touched && error} >
            <InputLabel htmlFor={`select_${name}`}>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={event => onChange(event.target.value || '')}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {ICONNAME.map(cur => (
                    <MenuItem key={cur.value} value={cur.value}>
                        {f({ id :`app_statsCategory_${cur.label}` })}
                    </MenuItem>
                ))}
            </Select>
            {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
        </FormControl>
    );
}

export default injectIntl(TypeCategorySelect);
