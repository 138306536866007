import { EPosition, EPositionSide, IComposition, ITeamComposition } from '../../compositions/typings';
import { IMatch, ISide } from '../../matches/typings';
import { IMatchTeamPlayer } from '../../matchTeamPlayers/typings';
import { IMembership } from '../../memberships/typings';

// Composition
interface ILiveSetCompositionsAction {
  type: 'LIVE_SET_COMPOSITION';
  payload: IComposition;
}
export const setLiveCompositionAction = (composition): ILiveSetCompositionsAction => ({
  type: 'LIVE_SET_COMPOSITION',
  payload: composition,
});

interface ILiveImportTeamCompositionAction {
  type: 'LIVE_IMPORT_TEAM_COMPOSITION';
  payload: { teamComposition: ITeamComposition | null; side: ISide };
}
export const importLiveTeamCompositionAction = (
  teamComposition: ITeamComposition | null,
  side: ISide
): ILiveImportTeamCompositionAction => ({
  type: 'LIVE_IMPORT_TEAM_COMPOSITION',
  payload: { teamComposition, side },
});

// Memberships
interface ISetLiveMembershipsAction {
  type: 'LIVE_SET_CLUB_MEMBERSHIPS' | 'LIVE_SET_TEAMSEASON_MEMBERSHIPS';
  payload: {
    side: ISide;
    memberships: IMembership[];
  };
}
export const setClubMembershipsAction = (side: ISide, memberships: IMembership[]): ISetLiveMembershipsAction => ({
  type: 'LIVE_SET_CLUB_MEMBERSHIPS',
  payload: { side, memberships },
});

export const setTeamSeasonMembershipsAction = (side: ISide, memberships: IMembership[]): ISetLiveMembershipsAction => ({
  type: 'LIVE_SET_TEAMSEASON_MEMBERSHIPS',
  payload: { side, memberships },
});

// MTP
interface IUpdateMatchTeamPlayerAction {
  type: 'LIVE_UPDATE_MATCHTEAMPLAYER';
  payload: { matchTeamPlayer: IMatchTeamPlayer; side: ISide };
}
export const updateMatchTeamPlayerAction = (
  matchTeamPlayer: IMatchTeamPlayer,
  side: ISide
): IUpdateMatchTeamPlayerAction => ({
  type: 'LIVE_UPDATE_MATCHTEAMPLAYER',
  payload: { matchTeamPlayer, side },
});

interface ISelectMatchTeamPlayerAction {
  type: 'LIVE_SELECT_MATCHTEAMPLAYER';
  payload: { matchTeamPlayer: IMatchTeamPlayer; side: ISide };
}
export const selectMatchTeamPlayerAction = (
  matchTeamPlayer: IMatchTeamPlayer,
  side: ISide
): ISelectMatchTeamPlayerAction => ({
  type: 'LIVE_SELECT_MATCHTEAMPLAYER',
  payload: { matchTeamPlayer, side },
});
interface IUnselectMatchTeamPlayerAction {
  type: 'LIVE_UNSELECT_MATCHTEAMPLAYER';
  payload: { side: ISide };
}
export const unselectMatchTeamPlayerAction = (side: ISide): IUnselectMatchTeamPlayerAction => ({
  type: 'LIVE_UNSELECT_MATCHTEAMPLAYER',
  payload: { side },
});

interface IPlaceMatchTeamPlayerAction {
  type: 'LIVE_PLACE_MATCHTEAMPLAYER';
  payload: {
    side: ISide;
    position: EPosition;
    positionSide?: EPositionSide;
  };
}
export const placeMatchTeamPlayerAction = (
  side: ISide,
  position: EPosition,
  positionSide: EPositionSide | undefined
): IPlaceMatchTeamPlayerAction => ({
  type: 'LIVE_PLACE_MATCHTEAMPLAYER',
  payload: { side, position, positionSide },
});

interface IRemoveMatchTeamPlayerAction {
  type: 'LIVE_REMOVE_MATCHTEAMPLAYER';
  payload: {
    side: ISide;
    position: EPosition;
    positionSide: EPositionSide | undefined;
  };
}
export const removeMatchTeamPlayerAction = (
  side: ISide,
  position: EPosition,
  positionSide: EPositionSide | undefined
): IRemoveMatchTeamPlayerAction => ({
  type: 'LIVE_REMOVE_MATCHTEAMPLAYER',
  payload: { side, position, positionSide },
});

// Substitutes
interface IAddSubstituteAction {
  type: 'LIVE_ADD_SUBSTITUTE';
  payload: {
    side: ISide;
  };
}
export const addSubstituteAction = (side: ISide): IAddSubstituteAction => ({
  type: 'LIVE_ADD_SUBSTITUTE',
  payload: { side },
});

interface IRemoveSubstituteAction {
  type: 'LIVE_REMOVE_SUBSTITUTE';
  payload: {
    side: ISide;
  };
}
export const removeSubstituteAction = (side: ISide): IRemoveSubstituteAction => ({
  type: 'LIVE_REMOVE_SUBSTITUTE',
  payload: { side },
});

// COACH
interface IChooseCoachAction {
  type: 'LIVE_CHOOSE_COACH';
  payload: {
    side: ISide;
    coach: IMembership;
  };
}
export const chooseCoachAction = (side: ISide, coach: IMembership): IChooseCoachAction => ({
  type: 'LIVE_CHOOSE_COACH',
  payload: { side, coach },
});

// Last matches

interface ILiveSetLastMatchesAction {
  type: 'LIVE_SET_TEAM_LAST_MATCHES';
  payload: { side: ISide; collection: IMatch[] };
}
export const setTeamLastMatchesAction = (side: ISide, collection: IMatch[]): ILiveSetLastMatchesAction => ({
  type: 'LIVE_SET_TEAM_LAST_MATCHES',
  payload: { side, collection },
});

export type ILiveCompositionActions =
  | ILiveSetCompositionsAction
  | ILiveImportTeamCompositionAction
  | ISetLiveMembershipsAction
  | IUpdateMatchTeamPlayerAction
  | ISelectMatchTeamPlayerAction
  | IUnselectMatchTeamPlayerAction
  | IPlaceMatchTeamPlayerAction
  | IRemoveMatchTeamPlayerAction
  | IAddSubstituteAction
  | IRemoveSubstituteAction
  | IChooseCoachAction
  | ILiveSetLastMatchesAction;
