import { Decoration, DecorationSet, EditorView, StateEffect, StateField } from '@uiw/react-codemirror';
import { SuperSub } from '../../../../../SuperSub';

export const addUnderline = StateEffect.define<{ from: number; to: number; tag: string }>({
  map: ({ from, to, tag }, change) => ({
    from: change.mapPos(from),
    to: change.mapPos(to),
    tag,
  }),
});
export const underlineTheme = EditorView.baseTheme({
  '.cm-underline': {
    background: 'linear-gradient(to right, rgb(248 200 217), rgb(249 212 200))',
    textDecoration: 'underline 2px rgb(237 1 87)',
  },
});

const underlineMark = Decoration.mark({ class: 'cm-underline' });

export const underlineField = StateField.define<DecorationSet>({
  create() {
    return Decoration.none;
  },
  update(underlines, tr) {
    underlines = underlines.map(tr.changes);
    for (let e of tr.effects)
      if (e.is(addUnderline)) {
        underlines = underlines.update({ add: [underlineMark.range(e.value.from, e.value.to)] });
      }
    return underlines;
  },
  provide: f => EditorView.decorations.from(f),
});

export const underlineErrors = (view: EditorView, supersub: SuperSub) => {
  // define underline
  let effects: StateEffect<unknown>[] = supersub.wordErrors.map(({ from, to, tag }) =>
    addUnderline.of({ from, to, tag })
  );

  if (!effects.length) {
    return;
  }
  if (!view.state.field(underlineField, false)) {
    effects.push(StateEffect.appendConfig.of([underlineField, underlineTheme]));
  }
  view.dispatch({ effects });

  return true;
};
