import React from 'react';
import Layout from 'Components/Layout';
import Search from './Input';
import Paper from '@material-ui/core/Paper';

export default function SearchDetail() {
  return (
    <Layout
      id="search-layout"
      main={
        <Paper elevation={1} style={{ backgroundColor: 'grey' }}>
          <Search autoFocus />
        </Paper>
      }
    />
  );
}
