import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMatchLink } from './actions';
import Diff from 'Components/Diff';

@connect(store => ({
  links: store.match.link,
}))
export default class MatchDiff extends Component {
  componentDidMount() {
    const { dispatch, oldValue, newValue } = this.props;

    for (let i = 0; i < oldValue.length; i += 1) {
      dispatch(fetchMatchLink(oldValue[i]));
    }
    for (let i = 0; i < newValue.length; i += 1) {
      dispatch(fetchMatchLink(newValue[i]));
    }
  }

  getName(id) {
    const { links } = this.props;
    if (!links[id] || links[id].fetching) return `match:${id}`;
    return links[id].description;
  }

  render() {
    const { oldValue, newValue } = this.props;

    const oldListWithLabel = oldValue.map(v => this.getName(v));
    const newListWithLabel = newValue.map(v => this.getName(v));

    const o = `[ ${oldListWithLabel.join(' , ')} ]`;
    const n = `[ ${newListWithLabel.join(' , ')} ]`;
    return <Diff oldValue={o} newValue={n} />;
  }
}
