import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CompetitionAvatar from '../../competitions/Avatar';

import { fetchCompetitions } from '../../competitions/actions';

@injectIntl
@connect(store => ({
  id: store.team.selectedId,
  competitions: store.competitions.list,
}))
export default class Teams extends Component {
  componentDidMount() {
    const { dispatch, id } = this.props;
    dispatch(fetchCompetitions(`?team=${id}`));
  }

  getHref = c => `#${getProject().url}/competitions/${c.area.slug}/${c.slug}`;

  render() {
    const {
      competitions = [],
      intl: { messages: m },
    } = this.props;
    return (
      <List dense subheader={<ListSubheader>{m.app_competitions}</ListSubheader>}>
        {competitions.map(competition => (
          <ListItem button component="a" href={this.getHref(competition)}>
            <ListItemIcon>
              <CompetitionAvatar data={competition} />
            </ListItemIcon>
            <ListItemText primary={competition.name} />
          </ListItem>
        ))}
      </List>
    );
  }
}
