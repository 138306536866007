export interface IEntityBaseState {
  fetching: boolean;
  error: any;
  fetched: boolean;
  updating: boolean;
  posting: boolean;
}

const defaultInitialState: IEntityBaseState = {
  fetching: false,
  error: null,
  fetched: false,
  updating: false,
  posting: false,
};

interface ICreateBaseReducers {
  modelName: string;
  initialState?: IEntityBaseState;
}

export const createBaseReducer = ({
  modelName,
  initialState = defaultInitialState,
}: ICreateBaseReducers) => {
  return (state = initialState, action) => {
    if (action.type === `FETCH_${modelName}`) {
      return {
        ...state,
        fetching: true,
        error: null,
      };
    }
    if (action.type === `FETCH_${modelName}_FULFILLED`) {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    if (action.type === `FETCH_${modelName}_REJECTED`) {
      return {
        ...state,
        fetching: false,
      };
    }
    if (action.type === `FETCH_${modelName}_NOTFOUND`) {
      return {
        ...state,
        fetching: false,
      };
    }

    if (action.type === `ADD_${modelName}`) {
      return {
        ...state,
        posting: true,
      };
    }

    if (action.type === `ADD_${modelName}_FULFILLED`) {
      return {
        ...state,
        posting: false,
      };
    }

    if (action.type === `ADD_${modelName}_REJECTED`) {
      return {
        ...state,
        posting: false,
      };
    }

    if (action.type === `UPDATE_${modelName}`) {
      return {
        ...state,
        updating: true,
      };
    }
    if (action.type === `UPDATE_${modelName}_FULFILLED`) {
      return {
        ...state,
        updating: false,
      };
    }
    if (action.type === `UPDATE_${modelName}_REJECTED`) {
      return {
        ...state,
        updating: false,
      };
    }
    if (action.type === `DELETE_${modelName}`) {
      return {
        ...state,
        deleting: true,
      };
    }
    if (action.type === `DELETE_${modelName}_FULFILLED`) {
      return {
        ...state,
        deleting: false,
      };
    }
    return state;
  };
};
