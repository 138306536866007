import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getArray } from 'Services/connectors';
import debounce, { getLinkId } from 'Services/tools';
import { fetchAuthor, fetchAuthors } from './actions';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    zIndex: '10',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  results: store.authors.list,
  authors: getArray(props.input.value, store.author),
}))
export class AuthorsInputCollection extends Component {
  state = {
    searchText: '',
    suggestions: [],
  };

  componentDidMount() {
    const { input, dispatch } = this.props;
    input.value.forEach(author => dispatch(fetchAuthor(getLinkId(author))));
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { input } = this.props;
    if (!next.input || !input) {
      return true;
    }
    if (input.value != next.input.value && !next.input.value) {
      this.setState({ searchText: '' });
    }
  }

  getLabel = author => author.publicName;

  handleSuggestionsFetchRequested = debounce(({ value }) => {
    const { dispatch } = this.props;
    dispatch(fetchAuthors(`?publicName=${value}`)).then(() => {
      this.setState({
        suggestions: this.getSuggestions(value),
      });
    });
  }, 500);

  handleChange = (event, { newValue }) => {
    this.setState({
      searchText: newValue,
    });
  };
  handleSuggestionSelected = (event, { suggestion }) => {
    const { input, dispatch } = this.props;
    input.onChange([...input.value, suggestion['@id']]);
    this.setState({
      searchText: '',
    });
    dispatch(fetchAuthor(suggestion.id));
  };
  handleDelete = data => () => {
    const { input } = this.props;
    const newArray = [...input.value];
    const itemToRemove = newArray.indexOf(data);
    newArray.splice(itemToRemove, 1);
    input.onChange(newArray);
  };

  renderInput = inputProps => {
    const { classes, className, value, ref, label, hintText, ...other } = inputProps;

    return (
      <TextField
        className={className}
        value={value}
        inputRef={ref}
        label={label}
        fullWidth
        InputProps={{
          classes: {
            input: classes.input,
          },
          placeholder: hintText || label,
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
          ...other,
        }}
      />
    );
  };
  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const name = this.getLabel(suggestion);
    const matches = match(name, query);
    const parts = parse(name, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={index} style={{ fontWeight: 300 }}>
                {part.text}
              </span>
            ) : (
              <strong key={index} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;

    return (
      <div>
        {children && (
          <Paper {...containerProps} square>
            {children}
          </Paper>
        )}
      </div>
    );
  };

  getSuggestions = value => {
    const { results } = this.props;
    const inputLength = value.trim().length;
    return inputLength === 0 ? [] : results;
  };

  render() {
    const {
      authors,
      input,
      labelKey,
      hintText,
      intl: { formatMessage: f },
      classes,
      className,
    } = this.props;

    const { searchText, suggestions } = this.state;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });

    return (
      <div style={{ display: 'flex' }}>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={this.renderInput}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionSelected={this.handleSuggestionSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getLabel}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            value: searchText || '',
            onChange: this.handleChange,
            autoFocus: input.autoFocus,
            classes,
            className,
            label,
            hintText,
          }}
        />
        <div className={classes.chips}>
          {authors
            ? authors.map(item => (
                <Chip
                  key={item.id}
                  label={item.publicName}
                  onDelete={this.handleDelete(item['@id'])}
                  className={classes.chip}
                />
              ))
            : null}
        </div>
      </div>
    );
  }
}
