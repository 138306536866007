import React, { Component } from 'react';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import fetchAllAgeGroups from './actions';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

@injectIntl
@connect(store => ({
  ageGroups: store.ageGroups,
}))
export default class SelectAgeGroup extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchAllAgeGroups());
  }

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error },
      intl: { formatMessage: f },
      labelKey,
      ageGroups,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <FormControl fullWidth error={Boolean(touched && error)}>
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={event => onChange(event.target.value)}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          {ageGroups.list &&
            ageGroups.list.map(value => (
              <MenuItem value={value['@id']}>{value.name}</MenuItem>
            ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
