import axios from 'axios';
import { getProject, getFMProject } from 'Services/project';
import getAuthorizationToken from 'Services/authorizationToken';

const transformResponse = data => {
  if (!data) return null;
  // TODO verify data is string
  const res = JSON.parse(data);
  if (res['@type'] === 'hydra:Collection') {
    return {
      collection: res['hydra:member'],
      totalItems: res['hydra:totalItems'],
      pagination: res['hydra:view'] && formatHydraPagination(res['hydra:view']),
    };
  } else if (res['@type'] === 'ConstraintViolationList') {
    const errors = {};
    res.violations.forEach(v => (errors[v.propertyPath] = v.message));
    return {
      type: res['@type'],
      description: res['hydra:description'],
      errors,
    };
  }
  return res;
};

const rest = axios.create({
  transformResponse: [transformResponse],
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});
// Add a request interceptor
rest.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${await getAuthorizationToken()}`;

  const { url } = config;
  const newURL = `${getProject().apiUrlFM}${url}`;
  return { ...config, url: newURL };
});

const restFr = axios.create({
  transformResponse: [transformResponse],
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});
restFr.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${await getAuthorizationToken()}`;

  const { url } = config;
  const newURL = `${getFMProject('fr').apiUrlFM}${url}`;
  return { ...config, url: newURL };
});

const restEs = axios.create({
  transformResponse: [transformResponse],
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});
restEs.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${await getAuthorizationToken()}`;

  const { url } = config;
  const newURL = `${getFMProject('es').apiUrlFM}${url}`;
  return { ...config, url: newURL };
});

const restDe = axios.create({
  transformResponse: [transformResponse],
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});
restDe.interceptors.request.use(async config => {
  config.headers.Authorization = `Bearer ${await getAuthorizationToken()}`;

  const { url } = config;
  const newURL = `${getFMProject('de').apiUrlFM}${url}`;
  return { ...config, url: newURL };
});

/**
 * Transform hydra view object into readeable pagination object
 */
/* input
    @id:"/app_dev.php/api/fr/clubs?page=1"
    @type:"hydra:PartialCollectionView"
    hydra:first:"/app_dev.php/api/fr/clubs?page=1"
    hydra:last:"/app_dev.php/api/fr/clubs?page=4"
hydra:next:"/app_dev.php/api/fr/clubs?page=2"
 */
const formatHydraPagination = view => {
  const current = getRelativeUrl(view['@id']);
  const first = getRelativeUrl(view['hydra:first']);
  const last = getRelativeUrl(view['hydra:last']);

  const pagination = {
    current: { url: current, page: getPageNumber(current) },
    first: { url: first, page: getPageNumber(first) },
    last: { url: last, page: getPageNumber(last) },
    template: first.slice(0, -1),
    itemsPerPage: getItemsPerPage(current),
  };
  return pagination;
};
const getPageNumber = url => {
  if (url.indexOf('page=') != -1) {
    return Number(
      url
        .split('page=')
        .pop()
        .split('&')
        .shift()
    );
  } else {
    return 1;
  }
};
const getItemsPerPage = url => {
  if (url.indexOf('itemsPerPage=') != -1) {
    return Number(
      url
        .split('itemsPerPage=')
        .pop()
        .split('&')
        .shift()
    );
  } else {
    return null;
  }
};
const getRelativeUrl = url => (url ? url.split('/').pop() : '');

export default rest;
export { restFr, restEs, restDe };
