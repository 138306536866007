import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';

import Button from '@material-ui/core/Button';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

@injectIntl
export default class PlayerProfileButton extends Component {
  render() {
    const {
      slug,
      intl: { messages: m },
    } = this.props;

    return (
      <Button
        variant="contained"
        href={`#${getProject().url}/players/${slug}`}
        style={{ marginRight: 8 }}
      >
        <AccountBoxIcon style={{ marginRight: 8 }} />
        {m.button_seeDetailPlayer}
      </Button>
    );
  }
}
