import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { InputCollection } from '../../components/InputCollection';
import { IInput } from '../../components/InputCollection/InputCollection.component';
import { searchCompetitions } from '../actions';
import { CompetitionCollectionChip } from './CompetitionCollectionChip.component';

interface IProps {
  meta: any;
  input: IInput;
  intl: any;
}

// @ts-ignore
@injectIntl
export class CompetitionInputCollection extends React.Component<IProps> {
  public render() {
    const {
      input,
      meta,
      intl: { messages: m },
    } = this.props;

    return (
      <React.Fragment>
        {/*
      // @ts-ignore */}
        <InputCollection
          input={input}
          meta={meta}
          search={searchCompetitions}
          getLabel={this.getLabel}
          CollectionChip={CompetitionCollectionChip}
        />
        {this.needValue() && (
          <Typography
            color="inherit"
            variant="caption"
            style={{
              marginTop: 5,
              color: 'orange',
            }}
          >
            <span
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                color: 'orange',
              }}
            >
              <WarningIcon />
            </span>
            {m.article_competition_related_empty}
          </Typography>
        )}
      </React.Fragment>
    );
  }

  private getLabel = competition => {
    let areaLabel = '';
    if (competition.area) {
      areaLabel = competition.area.codeFIFA || competition.area.codeISO;
    }
    return areaLabel ? `${competition.name} (${areaLabel})` : competition.name;
  };

  private needValue = () => this.props.input.value.length === 0;
}
