export const styles = () => ({
  card: {
    flex: 1,
    margin: 8,
    padding: 4,
  },
  form: {
    display: 'flex',
    alignItems: 'baseline',
  },
  input: {
    margin: 8,
  },
  chronoInput: {
    margin: 8,
    width: 64,
  },
});
