import { WithMobileDialog, withMobileDialog, withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../typings';
import { searchProducts } from '../../actions';
import { IProduct } from '../../typings';
import { styles } from './styles';

interface IOwnProps {
  open: boolean;
  onClose: (res: IProduct | null) => void;
}

const mapDispatchToProps = { searchProducts };

const mapStateToProps = (state: IRootState) => {
  return { products: state.products.search.collection, isLoading: state.products.searching };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps &
  WithStyles &
  WithMobileDialog;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  withStyles(styles),
  withMobileDialog(),
  injectIntl,
  reduxConnector
);
