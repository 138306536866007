import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getLinkId } from 'Services/tools';

import { fetchFutureMatches, updateMatch } from '../actions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';

import TournamentInput from '../../tournaments/Input';
import TournamentCell from '../../tournaments/Cell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MatchHighlightedRow from './Row';

const styles = theme => ({
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    marginTop: 0,
    padding: theme.spacing(3),
  },
  stepContentOverflow: {
    '& div': {
      overflow: 'visible',
    },
  },
});

@injectIntl
@connect(store => ({
  matches: store.matches.future && store.matches.future.list,
}))
@withStyles(styles)
export default class AddHighlightedMatches extends Component {
  state = {
    activeStep: 0,
    tournament: null,
    updates: new Map(),
  };

  handleNext = () => {
    const { dispatch } = this.props;
    const { tournament } = this.state;
    const id = getLinkId(tournament);
    dispatch(fetchFutureMatches(`phase.tournament=${id}&itemsPerPage=15`));
    this.setState({ activeStep: 1 });
  };

  handleConfirm = () => {
    const { dispatch, onClose } = this.props;
    const { updates } = this.state;
    const promises = [];
    for (const [key, { isHighlighted }] of updates) {
      const id = getLinkId(key);
      promises.push(dispatch(updateMatch(id, { isHighlighted })));
    }
    Promise.all(promises).then(onClose);
    this.handleRestart();
  };

  handleRestart = () => {
    this.setState({
      activeStep: 0,
      tournament: null,
      updates: new Map(),
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleTournamentChange = tournament => {
    this.setState({ tournament });
  };

  handleChange = (matchId, channels) => {
    const { updates } = this.state;
    updates.set(matchId, channels);
    this.setState({ updates });
  };

  getStepOneLabel = () => {
    const {
      intl: { messages: m },
    } = this.props;
    const { tournament, activeStep } = this.state;
    if (tournament && activeStep > 0) {
      return <TournamentCell id={getLinkId(tournament)} />;
    }
    return m.broadcast_step1Title;
  };

  render() {
    const {
      matches = [],
      classes,
      intl: { messages: m },
    } = this.props;

    const { activeStep, tournament, updates } = this.state;

    return (
      <div>
        <Toolbar style={{ display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            {`Mettre en avant un match`}
          </Typography>
          <Button variant="contained" onClick={this.handleClose}>
            {m.button_back}
          </Button>
        </Toolbar>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="tournament">
            <StepLabel>{this.getStepOneLabel()}</StepLabel>
            <StepContent className={classes.stepContentOverflow}>
              <Typography>{m.broadcast_step1Description}</Typography>
              <div style={{ height: 64, position: 'relative' }}>
                <div style={{ width: 300, position: 'absolute' }}>
                  <TournamentInput
                    input={{
                      name: 'tournament',
                      onChange: this.handleTournamentChange,
                      tournament,
                    }}
                  />
                </div>
              </div>
              <div className={classes.actionsContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                  disabled={!tournament}
                >
                  {m.button_next}
                </Button>
              </div>
            </StepContent>
          </Step>
          <Step key="matches">
            <StepLabel>{m.broadcast_step2Title}</StepLabel>
            <StepContent>
              <Typography>{m.broadcast_step2Description}</Typography>
              <div className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="none">{m.field_date}</TableCell>
                      <TableCell>{m.app_match}</TableCell>
                      <TableCell padding="none">{m.field_isHighlighted}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matches.map(matchItem => (
                      <MatchHighlightedRow
                        onChange={this.handleChange}
                        match={matchItem}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className={classes.actionsContainer}>
                <Button onClick={this.handleRestart} className={classes.button}>
                  {m.button_back}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleConfirm}
                  className={classes.button}
                  disabled={!updates.size}
                >
                  {m.button_validate}
                </Button>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </div>
    );
  }
}
