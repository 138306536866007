import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { ICommentary } from '../../../../commentaries/typings';
import { CommentaryContent } from '../CommentaryContent';
import { CommentaryIcon } from '../CommentaryIcon';
import { EShortcut } from '../ShortcutHandler/ShortcutHandler.component';
import { IComponentProps } from './index';

interface IState {
  isDeleting: boolean;
}
export class Commentary extends React.Component<IComponentProps, IState> {
  public state = { isDeleting: false };

  public shouldComponentUpdate(nextProps: IComponentProps, nextState: IState) {
    const { commentary, isEditing, isEditingMode } = this.props;
    const { isDeleting } = this.state;

    return (
      commentary.updatedAt !== nextProps.commentary.updatedAt ||
      isEditing !== nextProps.isEditing ||
      isEditingMode !== nextProps.isEditingMode ||
      isDeleting !== nextState.isDeleting
    );
  }

  public render() {
    const { commentary, isEditing, isEditingMode } = this.props;
    const { isDeleting } = this.state;

    const timeText = this.getTimeText();
    const classes = this.getClassName(commentary);

    return (
      <div className={classes} id={`comment-${commentary.id}`}>
        <div className="comment__info">
          {timeText ? <span className="comment__info__minute">{timeText}</span> : null}
          <span className="comment__info__type">
            <CommentaryIcon commentary={commentary} />
          </span>
        </div>
        <div className="comment__content">
          <CommentaryContent commentary={commentary} />
        </div>
        {isEditingMode && !isEditing ? this.renderBackdrop() : null}
        {isDeleting ? this.renderDeleteConfirmation() : this.renderEditingButtons()}
      </div>
    );
  }
  private renderBackdrop = () => <div className="commentary-comment-confirmation-blackdrop" />;

  private renderDeleteConfirmation = () => {
    const {
      intl: { messages: m },
    } = this.props;
    return (
      <div className="commentary-comment-confirmation-blackdrop">
        <div className="commentary-comment-confirmation">
          <div className="commentary-comment-confirmation-buttons">
            <button onClick={this.remove}>{m.common_confirm}</button>
            <button onClick={this.hide}>{m.common_cancel}</button>
          </div>
        </div>
      </div>
    );
  };
  private renderEditingButtons = () => {
    const { onEdit, isEditingMode, isFirst } = this.props;
    if (isEditingMode) return null;
    return (
      <div className="commentary-comment-buttons">
        <button onClick={onEdit} title={isFirst ? EShortcut.Edit : ''}>
          <EditIcon style={{ fontSize: 16 }} />
        </button>
        <button onClick={this.handleRemove}>
          <CloseIcon style={{ fontSize: 16 }} />
        </button>
      </div>
    );
  };

  private handleRemove = () => {
    this.setState({ isDeleting: true });
  };
  private remove = () => {
    this.props.onRemove();
    this.hide();
  };

  private hide = () => {
    this.setState({ isDeleting: false });
  };

  private getTimeText = () => {
    const { timeMin, additionnalTimeMin } = this.props.commentary;

    if (timeMin === null) return null;

    if (additionnalTimeMin === null) {
      return `${timeMin}'`;
    }

    return `${timeMin}+${additionnalTimeMin}'`;
  };

  private getClassName = (commentary: ICommentary) => {
    let classes = 'comment';

    if (commentary.isImportant) {
      classes += ` comment--important`;
    }
    if (commentary.matchEvent) {
      classes += ` comment--event comment--${commentary.matchEvent.name}`;

      if (commentary.matchEvent.name === 'goal') {
        classes += ' comment--noPadding';
      }
      if (commentary.matchEvent.type) {
        classes += ` comment--${commentary.matchEvent.name}--${commentary.matchEvent.type}`;
      }
    }
    return classes;
  };
}
