import { Button, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { IMatchEvent } from '../../../../matchEvents/typings';
import { AdvancedBloc } from '../AdvancedBloc';
import { IComponentProps } from './MatchEventChecker.connector';
import { IMatchEventPair } from './utils';
import {getProject} from "../../../../../common/services/project";

interface IState {}

export class MatchEventChecker extends React.Component<IComponentProps, IState> {
  public state: IState = {};

  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { matchEvents, commentaries } = this.props;
    if (!nextProps.joinMatchEvents || !nextProps.commentaries) return false;
    return (
      !matchEvents ||
      !commentaries ||
      nextProps.matchEvents.length !== matchEvents.length ||
      nextProps.commentaries.length !== commentaries.length
    );
  }

  public componentDidMount() {
    this.getList();
  }

  public render() {
    const {
      joinMatchEvents,
      intl: { messages: m },
    } = this.props;

    return (
      <AdvancedBloc
        title={m.live_advanced_matchEventCheckerTitle}
        description={m.live_advanced_matchEventCheckerDescription}
      >
        <List dense>{Object.entries(joinMatchEvents).map(this.renderItem)}</List>
      </AdvancedBloc>
    );
  }

  private renderItem = ([key, value]) => {
    const {
      classes,
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;
    const isValid = Boolean(value.commentary);
    return (
      <ListItem key={key} className={isValid ? '' : classes.warnItem} divider>
        <ListItemText primary={this.getPrimaryText(value.matchEvent)} secondary={this.getSecondaryText(value)} />
        <ListItemSecondaryAction className={classes.secondaryAction}>
          {!isValid ? (
            <Button
                onClick={this.onDeleteMatchEvent(value.matchEvent)}
                disabled={mainCommentatorLocale != getProject().lang}
            >
              <DeleteIcon className={classes.deleteIcon} />
              {m.button_deleteMatchEvent}
            </Button>
          ) : null}
          {isValid ? <CheckIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  private getPrimaryText = (me: IMatchEvent) => {
    if (!me) return null;
    const infos = [
      me.matchTeamPosition,
      me.name,
      me.typeTranslated,
      me.personName,
      me.timeMin ? `${me.timeMin}'` : null,
    ].filter(Boolean);
    return infos.join(' / ');
  };

  private getSecondaryText = ({ matchEvent, commentary }: IMatchEventPair) => {
    let text = '';
    if (matchEvent) {
      text += `matchEvent:${matchEvent.id}`;
    }
    if (commentary) {
      text += ` / commentary:${commentary.id}`;
    }
    return text;
  };

  private getList = () => {
    const { matchId, getMatchMatchEvents } = this.props;
    getMatchMatchEvents(matchId);
  };

  private onDeleteMatchEvent = matchEvent => () => {
    if (!matchEvent) return;
    // @ts-ignore dispatch typing
    this.props.handleMatchEventDelete(matchEvent).then(() => this.getList());
  };
}
