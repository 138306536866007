// Copied from TooltipHost in '@uiw/react-codemirror';
// node_modules/@codemirror/view/dist/index.js
// @ts-nocheck

export class TooltipViewManager {
  tooltipViews: any;
  tooltips: any;
  facet: any;
  createTooltipView: any;
  removeTooltipView: any;
  input: any;
  constructor(view, facet, createTooltipView, removeTooltipView) {
    this.facet = facet;
    this.createTooltipView = createTooltipView;
    this.removeTooltipView = removeTooltipView;
    this.input = view.state.facet(facet);
    this.tooltips = this.input.filter(t => t);
    let prev = null;
    this.tooltipViews = this.tooltips.map(t => (prev = createTooltipView(t, prev)));
  }
  update(update, above) {
    var _a;
    let input = update.state.facet(this.facet);
    let tooltips = input.filter(x => x);
    if (input === this.input) {
      for (let t of this.tooltipViews) if (t.update) t.update(update);
      return false;
    }
    let tooltipViews = [],
      newAbove = above ? [] : null;
    for (let i = 0; i < tooltips.length; i++) {
      let tip = tooltips[i],
        known = -1;
      if (!tip) continue;
      for (let i = 0; i < this.tooltips.length; i++) {
        let other = this.tooltips[i];
        if (other && other.create == tip.create) known = i;
      }
      if (known < 0) {
        tooltipViews[i] = this.createTooltipView(tip, i ? tooltipViews[i - 1] : null);
        if (newAbove) newAbove[i] = !!tip.above;
      } else {
        let tooltipView = (tooltipViews[i] = this.tooltipViews[known]);
        if (newAbove) newAbove[i] = above[known];
        if (tooltipView.update) tooltipView.update(update);
      }
    }
    for (let t of this.tooltipViews)
      if (tooltipViews.indexOf(t) < 0) {
        this.removeTooltipView(t);
        (_a = t.destroy) === null || _a === void 0 ? void 0 : _a.call(t);
      }
    if (above) {
      newAbove.forEach((val, i) => (above[i] = val));
      above.length = newAbove.length;
    }
    this.input = input;
    this.tooltips = tooltips;
    this.tooltipViews = tooltipViews;
    return true;
  }
}
