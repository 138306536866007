import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  tableContainer: {
    width: '100%',
    overflowX: 'auto' as 'auto',
    position: 'relative' as 'relative',
  },
  input: { padding: theme.spacing(), maxWidth: 400 },
  button: {
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    marginTop: 0,
    padding: theme.spacing(3),
  },
});
