import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import FormHelperText from '@material-ui/core/FormHelperText';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from "react-redux";

const styles = theme => ({
  box: {
    color: theme.palette.primary[600],
  },
});

@withStyles(styles)
@injectIntl
@connect((store) => ({
  stats: store.stats,
}))
export default class TypeSelect extends Component {
  state = {
    open: {},
    all: {},
    openSelect: false,
  };

  handleChange = event => {

    const { onChange , multiple } = this.props;

    const values = [];
    let toggleCat = null;
    const rawValues = multiple ? event.target.value : [event.target.value];

    for (let i = 0; i < rawValues.length; i += 1) {
      if (rawValues[i].group) {
        this.openCategory(rawValues[i].group);
        event.preventDefault();
        event.stopPropagation();
        return null;
      }
      if (rawValues[i].all) {
        toggleCat = rawValues[i].all;
        this.toggleCategory(toggleCat);
        event.preventDefault();
        event.stopPropagation();
      }
    }
    if (toggleCat) {
      for (let i = 0; i < rawValues.length; i += 1) {
        if (rawValues[i].code && toggleCat != rawValues[i].category) {
          values.push(rawValues[i]);
        }
      }
      values.push(...this.getCategory(toggleCat));
    } else {
      for (let i = 0; i < rawValues.length; i += 1) {
        if (rawValues[i].code) {
          values.push(rawValues[i]);
        }
      }
    }
    onChange(values);
  };

  openCategory = category => {
    const { open } = this.state;
    open[category] = !open[category];
    this.setState({ open });
  };
  toggleCategory = category => {
    const { all } = this.state;
    all[category] = !all[category];
    this.setState({ all });
  };
  getCategory = category => {
    const { all } = this.state;
    const { stats: { types }, } = this.props;
    return all[category] ? types[category] : [];
  };

  renderValue = selected => {
    const display = Array.isArray(selected) ? selected.map(t => t.name) : [selected.name];
    if (display.length > 3) {
      display.splice(3, display.length, `+${display.length - 3}`);
    }
    return display.join(', ');
  };

  getText = (key, count) => {
    const text = key.toUpperCase();
    if (!count) {
      return <span>{text}</span>;
    }
    return [
      <span>{text}</span>,
      <span style={{ marginLeft: 8 }}>({count})</span>,
    ];
  };

  renderOptGroups = () => {
    const {
      value = [],
      stats: { types },
      classes,
      multiple,
      intl: { messages: m },
    } = this.props;
    const { open, all } = this.state;
    if (!types) return [];

    const count = {};
    if (Array.isArray(value)) {
      value.forEach(item => {
        count[item.category] = count[item.category]
            ? count[item.category] + 1
            : 1;
      });
    }else{
        count[value.category] = 1;
    }


    return Object.entries(types).map(([key, types]) => [
      <MenuItem value={{ group: key }} data-group="" data-code={`${key}-group`}>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={this.getText(key, count[key])} />
        {open[key] ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>,
      open[key] && multiple && (
        <MenuItem value={{ all: key }} data-code={`${key}-all`}>
          <Checkbox checked={all[key] == true} className={classes.box} />
          <ListItemText primary={m.common_all} />
        </MenuItem>
      ),
      open[key] &&
        types.map(option => (
          <MenuItem value={option} data-code={option.code}>
            <Checkbox checked={Array.isArray(value) ? value.includes(option) : value === option} />
            <ListItemText primary={option.name} />
            {!option.isCombinable && (
              <Tooltip id="not-combinable" title={m.app_statNotCombinable}>
                <LayersClearIcon />
              </Tooltip>
            )}
          </MenuItem>
        )),
    ]);
  };

  handleClose = (event) => {
    if (event.target && event.currentTarget.dataset.group !== undefined) {
      return;
    }
    this.setState({ openSelect: false });
  }

  handleOpen = () => {
    this.setState({ openSelect: true });
  }

  render() {
    const {
      value = [],
      intl: { messages: m },
      multiple
    } = this.props;
    const { openSelect } = this.state;
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="select-multiple-type">
          {m.filter_statsTypes}
        </InputLabel>
        <Select
          multiple={multiple}
          value={value}
          onChange={event => this.handleChange(event)}
          onClose={event => this.handleClose(event)}
          onOpen={event => this.handleOpen(event)}
          input={<Input id="select-multiple-type" />}
          renderValue={this.renderValue}
          open={openSelect}
        >
          {this.renderOptGroups()}
        </Select>
        <FormHelperText>{m.app_statNotCombinableDescription}</FormHelperText>
      </FormControl>
    );
  }
}
