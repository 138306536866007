import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchReferees(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    role: 'referee',
    partial: 0,
    'order[translations.priority]': 'asc',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_REFEREES' });

    return rest
      .get('people', { params })
      .then(
        res => dispatch({ type: 'FETCH_REFEREES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_REFEREES_REJECTED', payload: err.response })
      );
  };
}

export function fetchReferee(slug) {
  const params = {
    slug,
    role: 'referee',
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_REFEREE' });

    return rest.get('people', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_REFEREE_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_REFEREE_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_REFEREE_REJECTED', payload: err.response })
    );
  };
}

export function fetchRefereeById(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_REFEREE' });

    return rest
      .get(`people/${id}}`)
      .then(
        res => dispatch({ type: 'FETCH_REFEREE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_REFEREE_REJECTED', payload: err.response })
      );
  };
}

export function updateReferee(id, referee) {
  const params = {
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_REFEREE', payload: id });
    return rest.put(`people/${id}`, referee, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_refereeUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_REFEREE_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_REFEREE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addReferee(referee) {
  return function(dispatch) {
    dispatch({ type: 'ADD_REFEREE' });

    return rest
      .post('people', referee)
      .then(
        res => dispatch({ type: 'ADD_REFEREE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_REFEREE_REJECTED', payload: err.response })
      );
  };
}

export function deleteReferee(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_REFEREE', payload: id });

    return rest
      .delete(`people/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_REFEREE_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_REFEREE_REJECTED', payload: err.response })
      );
  };
}
