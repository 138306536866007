import { Card, ListSubheader } from '@material-ui/core';
import * as React from 'react';
import { TeamCompositionGrid } from '../TeamCompositionGrid';
import { IComponentProps } from './CompositionGrid.connector';

export class CompositionGrid extends React.Component<IComponentProps> {
  public render() {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <Card>
        <ListSubheader className={classes.listSubheader}>{m.live_compo_composition}</ListSubheader>
        <div className={classes.grid}>
          <TeamCompositionGrid side="home" />
          <TeamCompositionGrid side="away" />
        </div>
      </Card>
    );
  }
}
