import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  base: {
    borderLeftWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    paddingLeft: 6,
    paddingRight: theme.spacing(),
    '& > div': {
      padding: 0,
    },
  },
  actionPlayer: {
    borderColor: 'green',
  },
  actionPlayer2: {
    borderColor: 'red',
  },
  option: {
    borderColor: 'lightgrey',
  },
  cardTitle: {
    background: 'whitesmoke',
    padding: 6,
  },
  label: {
    display: 'flex',
  },
  svgContainer: {
    '& svg': {
      fontSize: 16,
    },
  },
  number: {
    width: 18,
    height: 18,
    background: '#bfc8ff',
    color: '#ffffff',
    fontSize: 12,
    lineHeight: '20px',
    textAlign: 'center' as 'center',
    marginRight: 4,
  },
  eventCount: {
    fontSize: 12,
    lineHeight: '20px',
  },
});
