import { ICommentary } from '../../../../commentaries/typings';
import { IMatchEvent } from '../../../../matchEvents/typings';

interface IMatchEventPairsCollection {
  [key: string]: IMatchEventPair;
}
export interface IMatchEventPair {
  matchEvent?: IMatchEvent;
  commentary?: ICommentary;
}

export const pairMatchEvents = (
  matchEvents: IMatchEvent[] | null,
  commentaries: ICommentary[] | null
): IMatchEventPairsCollection => {
  const collection: IMatchEventPairsCollection = {};

  if (matchEvents) {
    matchEvents.forEach(me => {
      if (!collection[me.id]) {
        collection[me.id] = {};
      }
      collection[me.id].matchEvent = me;
    });
  }
  if (commentaries) {
    commentaries.forEach(commentary => {
      const matchEventId = commentary.matchEvent.id;
      if (!collection[matchEventId]) {
        collection[matchEventId] = {};
      }
      collection[matchEventId].commentary = commentary;
    });
  }
  return collection;
};
