import rest from '../../common/services/restFootData';

export const getTeamLastMatches = (teamId: string) =>
  rest.get('matches', {
    params: {
      team: teamId,
      'order[date]': 'DESC',
      status: 'played',
      itemsPerPage: 5,
      groups: ['read_link'],
    },
  });

export const pauseMatch = (id: string) => {
  return rest.get(`matches/pause/${id}`);
};

export const resumeMatch = (id: string) => {
  return rest.get(`matches/resume/${id}`);
};

export const suspendMatch = (id: string) => {
  return rest.get(`matches/suspended/${id}`);
};

export const cleanMatch = (id: string) => {
  return rest.get(`matches/clean/${id}`);
};

export const awardMatch = (id: string, params) => {
  return rest.post(`matches/awarded/${id}`, params);
};

export const changeMatchDate = (id: string, params) => {
  return rest.put(`matches/${id}`, params);
};

export const changeMatchScores = (id: string, params) => {
  return rest.put(`matches/${id}`, params);
};
