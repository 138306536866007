import React from 'react';

import '../../../css/flags.css';

const styles = {
  secondFlag: {
    marginLeft: 8,
  },
};

export default function NationalityCell({ main, second }) {
  return (
    <span>
      {main && (
        <i
          className={`flag ${main.codeISO.toLowerCase()}`}
          title={main.name}
          alt={main.name}
        />
      )}
      {second && (
        <i
          className={`flag ${second.codeISO.toLowerCase()}`}
          style={styles.secondFlag}
          title={second.name}
          alt={second.name}
        />
      )}
    </span>
  );
}
