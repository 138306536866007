import MainLayout from 'Components/MainLayout';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import fetchMeOnce from '../common/actions/me';
import { getProjectNameFromPath, setProject } from '../common/services/project';
import { UsersRoutes } from '../common/users';
import ArticlesRoutes from './articles';
import AuthorsRoutes from './authors';
import LiveTransfersRoutes from './briefs/liveTransfers';
import Nav from './components/Navigation';
import Toolbar from './components/Toolbar';
import ConfigurationsRoutes from './configurations';
import { Dashboard } from './Dashboard';
import { DocumentationPages } from './documentation/DocumentationPages';
import HighlightingRoutes from './highlighting';
import PageseosRoutes from './pageseos';
import PushRoutes from './push';
import RevisionRoutes from './revisions';
import SearchAdvanced from './search/advanced';
import TagsRoutes from './tags';

@connect(store => ({
  me: store.me,
}))
export default class Footmercato extends Component {
  UNSAFE_componentWillMount() {
    const { location, dispatch } = this.props;
    setProject(location.pathname);
    dispatch(fetchMeOnce());
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (getProjectNameFromPath(prevProps.location.pathname) !== getProjectNameFromPath(location.pathname)) {
      setProject(location.pathname);
    }
  }

  render() {
    const { match, me } = this.props;
    if (!me.fetched) return null;

    return (
      <MainLayout toolbar={<Toolbar />} navigation={<Nav path={this.props.location.pathname} />}>
        <Switch>
          <Route exact path={match.url} component={Dashboard} />
          <Route path={`${match.url}/articles`} component={ArticlesRoutes} />
          <Route path={`${match.url}/authors`} component={AuthorsRoutes} />
          <Route path={`${match.url}/configurations`} component={ConfigurationsRoutes} />
          <Route path={`${match.url}/highlighting`} component={HighlightingRoutes} />
          <Route path={`${match.url}/live-transfers`} component={LiveTransfersRoutes} />
          <Route path={`${match.url}/pageseos`} component={PageseosRoutes} />
          <Route path={`${match.url}/push`} component={PushRoutes} />
          <Route path={`${match.url}/revisions`} component={RevisionRoutes} />
          <Route path={`${match.url}/search`} component={SearchAdvanced} />
          <Route path={`${match.url}/tags`} component={TagsRoutes} />
          <Route path={`${match.url}/users`} component={UsersRoutes} />
          <Route path={`${match.url}/documentation`} component={DocumentationPages} />
        </Switch>
      </MainLayout>
    );
  }
}
