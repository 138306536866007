import React from 'react';
import { injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

function CheckboxInput({ input, labelKey, helperKey, intl: { formatMessage: f }, disabled }) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!input.value}
            onChange={input.onChange}
            value={input.name}
            color="primary"
            disabled={disabled}
          />
        }
        name={input.name}
        label={label}
      />
      {helperKey && (
        <FormHelperText style={{ marginTop: 0 }}>{f({ id: helperKey })}</FormHelperText>
      )}
    </div>
  );
}
export default injectIntl(CheckboxInput);

export const normalizeCheckbox = val => !!val;
