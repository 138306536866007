import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CompetitionIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(4 3)">
        <path
          d="M14.3023587,14.277502 L8.42017391,16.0377848 L2.53798913,14.277502 L6.68136957,13.0375121 L6.68136957,14.277502 C6.68136957,14.7906939 7.7743587,14.9277242 8.42017391,14.9277242 C9.06598913,14.9277242 10.1589783,14.7906939 10.1589783,14.277502 L10.1589783,13.0375121 L14.3023587,14.277502 Z M2.05552174,14.5325626 L8.2353913,16.3820879 L8.2353913,18.4037646 L2.05552174,16.5542394 L2.05552174,14.5325626 Z M8.42017391,11.228098 C8.88638043,11.228098 9.34353261,11.1868354 9.78941304,11.1108354 L9.78941304,14.2627242 C9.72363043,14.3508152 9.26315217,14.5438859 8.42017391,14.5438859 C7.57701087,14.5438859 7.11671739,14.3508152 7.05093478,14.2627242 L7.05093478,11.1108354 C7.49681522,11.1868354 7.95396739,11.228098 8.42017391,11.228098 L8.42017391,11.228098 Z M0.40448913,3.24387576 C1.49452174,4.24281515 4.67038043,4.96577475 8.42017391,4.96577475 C12.1699674,4.96577475 15.3458261,4.24281515 16.4358587,3.24387576 C16.0642609,7.49910808 12.6121522,10.8442596 8.42017391,10.8442596 C4.22819565,10.8442596 0.776271739,7.49891616 0.40448913,3.24387576 L0.40448913,3.24387576 Z M10.1589783,11.0396333 C13.9691957,10.2053606 16.8388696,6.68767374 16.8399783,2.48579495 C16.8399783,2.48483535 16.8403478,2.48387576 16.8403478,2.48291616 C16.8403478,1.11165354 13.070413,5.75757576e-05 8.42017391,5.75757576e-05 C3.76993478,5.75757576e-05 0,1.11165354 0,2.48291616 C0,2.48387576 0.000369565217,2.48483535 0.000369565217,2.48579495 C0.00147826087,6.68767374 2.87115217,10.2053606 6.68136957,11.0396333 L6.68136957,12.6379364 L1.68595652,14.1331788 L1.68595652,16.8432697 L8.42017391,18.8586131 L15.1543913,16.8432697 L15.1543913,14.1331788 L10.1589783,12.6379364 L10.1589783,11.0396333 Z"
          mask="url(#b)"
        />
      </g>
    </SvgIcon>
  );
}
