import { ICommentaryType } from '../../../../commentaries/typings';
import { isActionWithPlayer, isActionWithPlayer2 } from '../../utils';

export const isActionPlayer1 = (
  type: ICommentaryType,
  hasActionPlayer1: boolean,
  isOpponentSide: boolean,
  inTheField: boolean,
  strictMode: boolean
): boolean => {
  if (!isActionWithPlayer(type) || hasActionPlayer1) {
    return false;
  }
  if (!strictMode) {
    return true;
  }
  switch (type) {
    case 'substitute':
      return !inTheField && !isOpponentSide;
    default:
      return inTheField && !isOpponentSide;
  }
};

export const isActionPlayer2 = (
  type: ICommentaryType,
  hasActionPlayer: boolean,
  hasActionPlayer2: boolean,
  isOpponentSide: boolean,
  inTheField: boolean,
  strictMode: boolean
): boolean => {
  if (!isActionWithPlayer2(type) || hasActionPlayer2) {
    return false;
  }

  if (!strictMode) {
    return true;
  }
  switch (type) {
    case 'substitute':
      return inTheField && !isOpponentSide;
    case 'missed_penalty':
      return hasActionPlayer && inTheField && isOpponentSide;
    default:
      return hasActionPlayer && inTheField && !isOpponentSide;
  }
};
