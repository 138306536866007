import { ICommentaryType } from '../../commentaries/typings';

// TYPES
interface ILiveResetAction {
  type: 'LIVE_RESET';
}
export const resetLive = (): ILiveResetAction => ({
  type: 'LIVE_RESET',
});

interface ILiveSetTypeAction {
  type: 'LIVE_SET_TYPE';
  payload: ICommentaryType;
}
export const setTypeLive = (action: ICommentaryType): ILiveSetTypeAction => ({
  type: 'LIVE_SET_TYPE',
  payload: action,
});

// PLAYERS
interface ILiveSetPlayerAction {
  type: 'LIVE_SET_PLAYER' | 'LIVE_SET_PLAYER2' | 'LIVE_SET_PLAYER_CARD';
  payload: any;
}
interface ILiveRemovePlayerAction {
  type: 'LIVE_REMOVE_PLAYER' | 'LIVE_REMOVE_PLAYER2' | 'LIVE_REMOVE_PLAYER_CARD';
}
export const setPlayerCard = (player: any): ILiveSetPlayerAction => ({
  type: 'LIVE_SET_PLAYER_CARD',
  payload: { player },
});
export const setPlayerLive = (player: any, side: string): ILiveSetPlayerAction => ({
  type: 'LIVE_SET_PLAYER',
  payload: { player, side },
});
export const setPlayer2Live = (player: any, side: string): ILiveSetPlayerAction => ({
  type: 'LIVE_SET_PLAYER2',
  payload: { player, side },
});
export const removePlayerLive = (): ILiveRemovePlayerAction => ({
  type: 'LIVE_REMOVE_PLAYER',
});
export const removePlayer2Live = (): ILiveRemovePlayerAction => ({
  type: 'LIVE_REMOVE_PLAYER2',
});
export const removePlayerCard = (): ILiveRemovePlayerAction => ({
  type: 'LIVE_REMOVE_PLAYER_CARD',
});

// PARAMS
interface ILiveSetParamsAction {
  type: 'LIVE_SET_PARAMS';
  payload: any;
}
export const setParamsLive = (type: string, value: any): ILiveSetParamsAction => ({
  type: 'LIVE_SET_PARAMS',
  payload: { type, value },
});
interface ILiveSyncChronoToCommentaryAction {
  type: 'LIVE_SYNC_CHRONO_TO_COMMENTARY';
}
export const syncChronoToCommentaryLive = (): ILiveSyncChronoToCommentaryAction => ({
  type: 'LIVE_SYNC_CHRONO_TO_COMMENTARY',
});

interface ILiveSetStrictModeAction {
  type: 'LIVE_SET_STRICT_MODE';
  payload: boolean;
}
export const setStrictModeLive = (strictMode: boolean): ILiveSetStrictModeAction => ({
  type: 'LIVE_SET_STRICT_MODE',
  payload: strictMode,
});

// CONTENT
interface ILiveSetContentAction {
  type: 'LIVE_SET_CONTENT';
  payload: any;
}
export const setContentLive = (content: string): ILiveSetContentAction => ({
  type: 'LIVE_SET_CONTENT',
  payload: content,
});

// COMMENTARY
interface ILiveRecoverCommentaryAction {
  type: 'RECOVER_COMMENTARY';
  payload: any;
}
export const recoverCommentary = (commentary: any): ILiveRecoverCommentaryAction => ({
  type: 'RECOVER_COMMENTARY',
  payload: commentary,
});

// cancelEdition, updateCommentary
export type ILiveAction =
  | ILiveRemovePlayerAction
  | ILiveResetAction
  | ILiveSetContentAction
  | ILiveSetParamsAction
  | ILiveSetPlayerAction
  | ILiveSetTypeAction
  | ILiveRecoverCommentaryAction;
