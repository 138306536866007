export default function reducers(state = {}, action) {
  switch (action.type) {
    case 'FETCH_FOCUS_BLOCKS': {
      return {
        ...state,
        fetching: true,
      };
    }

    case 'FETCH_FOCUS_BLOCKS_FULFILLED': {
      const { list } = action.payload;

      return {
        ...state,
        focusBlock: list.collection,
        fetching: false,
      };
    }

    case 'UPDATE_FOCUS_BLOCK_REJECTED':
    case 'UPDATE_FOCUS_BLOCK_FULFILLED': {
      const item = action.payload;
      const focusBlock = state.focusBlock.slice(0);
      focusBlock[item.position] = item;

      return {
        ...state,
        focusBlock,
        fetching: false,
      };
    }

    default:
      return state;
  }
}
