interface IPage {
  url: string;
  page: number;
}
interface IPagination {
  current: IPage;
  first: IPage;
  last: IPage;
  template: string;
  itemsPerPage: number;
}
export interface IEntityListState<T> {
  list: T[];
  search: { collection: T[]; pagination?: IPagination; totalItems: number };
  totalItems: number;
  pagination?: IPagination;
  fetched: boolean;
  fetching: boolean;
  searching: boolean;
  error: any;
}

export const createListReducer = (
  modelName: string,
  subLists: string[] = [],
  INITIAL_STATE: IEntityListState<any> = {
    list: [],
    search: { collection: [], totalItems: 0 },
    totalItems: 0,
    fetched: false,
    fetching: false,
    searching: false,
    error: null,
  }
) => {
  return (state = INITIAL_STATE, action) => {
    if (action.type === `FETCH_${modelName}`) {
      return {
        ...state,
        fetching: true,
        error: null,
      };
    }

    if (action.type === `FETCH_${modelName}_FULFILLED`) {
      const { collection, pagination, totalItems } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: collection,
        pagination,
        totalItems,
      };
    }

    if (action.type === `SEARCH_${modelName}`) {
      return {
        ...state,
        searching: true,
      };
    }
    if (action.type === `SEARCH_${modelName}_FULFILLED`) {
      return {
        ...state,
        searching: false,
        search: action.payload,
      };
    }

    if (action.type === `FETCH_${modelName}_REJECTED`) {
      return {
        ...state,
        fetching: false,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }
    for (const subList of subLists) {
      if (action.type === `FETCH_${modelName}_${subList.toUpperCase()}`) {
        return {
          ...state,
          [subList]: {
            ...state[subList],
            fetching: true,
            error: null,
          },
        };
      }

      if (action.type === `FETCH_${modelName}_${subList.toUpperCase()}_FULFILLED`) {
        const { collection, pagination, totalItems } = action.payload;
        return {
          ...state,
          [subList]: {
            ...state[subList],
            fetching: false,
            fetched: true,
            list: collection,
            pagination,
            totalItems,
          },
        };
      }
      if (action.type === `FETCH_${modelName}_${subList.toUpperCase()}_REJECTED`) {
        return {
          ...state,
          [subList]: {
            ...state[subList],
            fetching: false,
            error: action.payload ? action.payload.error : `[${action.type}] No response`,
          },
        };
      }
    }

    return state;
  };
};
