import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
});
