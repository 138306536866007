import { Card, FormControlLabel, Switch } from '@material-ui/core';
import * as React from 'react';
import { IComponentProps } from './OfficialLineup.connector';
import {getProject} from "../../../../../common/services/project";

export class OfficialLineup extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { fetching, isLineupOfficial } = this.props;
    return (
      fetching !== nextProps.fetching || isLineupOfficial !== nextProps.isLineupOfficial
    );
  }
  public render() {
    const {
      classes,
      intl: { messages: m },
      isLineupOfficial,
      matchTeamId,
      fetching,
      mainCommentatorLocale,
    } = this.props;

    if (!matchTeamId) {
      return null;
    }
    const switchLabelKey = isLineupOfficial
      ? 'live_compo_official'
      : 'live_compo_non_official';

    return (
      <Card className={classes.card}>
        <div className={classes.switchContainer}>
          <FormControlLabel
            className={classes.formControl}
            control={
              <Switch
                disabled={fetching || mainCommentatorLocale != getProject().lang}
                checked={isLineupOfficial}
                onChange={this.handleChange}
                name="isLineupOfficial"
              />
            }
            label={m[switchLabelKey]}
          />
        </div>
      </Card>
    );
  }

  private handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { updateMatchTeam, matchTeamId, matchId, fetchMatch } = this.props;
    if (!matchTeamId) {
      return;
    }
    updateMatchTeam(matchTeamId, {
      isLineupOfficial: checked,
      // @ts-ignore
    }).then(() => {
      // got to re fetch live match where isLineupOfficial comes from
      fetchMatch(matchId);
    });
  };
}
