import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import SyncIcon from '@material-ui/icons/Sync';
import * as React from 'react';
import Integer, { normalizeInt } from '../../../../../common/components/inputs/Integer';
import Select from '../../../../../common/components/inputs/Select';
import Toggle, { normalizeBoolean } from '../../../../../common/components/inputs/Toggle';
import { isSyncWithChrono } from '../Chrono/utils';
import { EPeriod, ORDERED_PERIODS, PERIODS } from '../PeriodSelector/utils';
import { EShortcut } from '../ShortcutHandler/ShortcutHandler.component';
import { IComponentProps } from './ParamsEditor.connector';

const TICK_INTERVAL = 1000 * 10;
interface IState {
  timeout: any;
}
export class ParamsEditor extends React.PureComponent<IComponentProps, IState> {
  public state: IState = {
    timeout: null,
  };

  public componentDidMount() {
    this.updateTimeout();
  }

  public componentDidUpdate(prevProps: IComponentProps) {
    const { period, chronoStatus } = this.props;
    if (prevProps.period !== period || prevProps.chronoStatus !== chronoStatus) {
      this.updateTimeout();
    }
  }

  public componentWillUnmount() {
    this.stop();
  }

  public render() {
    const {
      classes,
      chronoPeriod,
      timeMin,
      additionnalTimeMin,
      isImportant,
      hasVideo,
      chronoStartTimeStamp,
      chronoEndTimeStamp,
      period,
    } = this.props;

    const isCommentarySync = isSyncWithChrono(
      chronoPeriod,
      chronoStartTimeStamp,
      chronoEndTimeStamp,
      timeMin,
      additionnalTimeMin,
      period
    );

    return (
      <div className={classes.form}>
        <IconButton onClick={this.onSyncTime} title={EShortcut.Refresh} disabled={isCommentarySync}>
          <SyncIcon />
        </IconButton>
        <div className={classes.input}>
          {/*
          // @ts-ignore */}
          <Select
            input={{
              name: 'period',
              value: period,
              onChange: this.onPeriodChange,
            }}
          >
            {this.renderItems()}
          </Select>
        </div>
        <div className={classes.chronoInput}>
          {/*
          // @ts-ignore */}
          <Integer
            input={{
              name: 'timeMin',
              value: timeMin,
              onChange: this.onIntegerChange('timeMin'),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.chronoInput}>
          {/*
          // @ts-ignore */}
          <Integer
            input={{
              name: 'additionnalTimeMin',
              value: additionnalTimeMin,
              onChange: this.onIntegerChange('additionnalTimeMin'),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.input}>
          {/*
          // @ts-ignore */}
          <Toggle
            input={{
              name: 'isImportant',
              value: isImportant,
              onChange: this.onBooleanChange('isImportant'),
            }}
          />
        </div>
        <div className={classes.input}>
          {/*
          // @ts-ignore */}
          <Toggle
            input={{
              name: 'hasVideo',
              value: hasVideo,
              onChange: this.onBooleanChange('hasVideo'),
            }}
          />
        </div>
      </div>
    );
  }
  private updateTimeout = () => {
    const { chronoStatus } = this.props;
    const { timeout } = this.state;

    if (chronoStatus) {
      if (timeout) clearTimeout(timeout);
      this.tick();
    } else {
      this.stop();
    }
  };
  private tick = () => {
    this.setState({
      timeout: setTimeout(this.tick, TICK_INTERVAL),
    });
  };
  private stop = () => {
    this.setState(({ timeout }) => ({
      timeout: clearTimeout(timeout),
    }));
  };

  private onSyncTime = () => {
    this.props.syncChronoToCommentaryLive();
  };

  private onIntegerChange = (type: string) => (value: any) => {
    this.handleChange(type, normalizeInt(value) || null);
  };
  private onBooleanChange = (type: string) => (ev: any) => {
    const value = ev.target.checked;
    this.handleChange(type, normalizeBoolean(value));
  };
  private onPeriodChange = (period: EPeriod) => {
    this.handleChange('period', period);
  };

  private handleChange = (type: string, value: any) => {
    const { setParamsLive } = this.props;
    setParamsLive(type, value);
  };

  private renderItems = () => {
    const {
      intl: { messages: m },
    } = this.props;
    return ORDERED_PERIODS.map(period => (
      <MenuItem key={period} value={period}>
        {m['live_' + PERIODS[period]]}
      </MenuItem>
    ));
  };
}
