import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { getPersonId } from '../../../../people/actions';
import { fetchPlayer } from '../../../../people/players/actions';
import { removePlayerCard } from '../../actions';
import { styles } from './styles';

interface IOwnProps {}

const mapDispatchToProps = {
  removePlayerCard,
  fetchPlayer,
  getPersonId,
};

const mapStateToProps = (state: IRootState) => {
  const selectedPlayer = state.live.playerCard;
  const personId = selectedPlayer ? selectedPlayer.membership.personId : null;

  return {
    selectedPlayer,
    person: state.person.detail[personId],
    isFetching: state.person.fetching,
  };
};
export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps &
  WithStyles &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl,
  withStyles(styles)
);
