import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import filterList from 'Actions/lists';
import SelectConstant from 'FDComponents/constants/Select';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Date from '../../../common/components/inputs/Date';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import ToggleFilter from '../../../common/components/ToggleFilter';
import { getDefaultInputsValue } from '../../../common/services/getDefaultInputsValue';
import { parseQuery, stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce, { getLinkId } from '../../../common/services/tools';
import CompetitionInput from '../../competitions/Input';
import { fetchMatches } from '../actions';
import Row from './Row';

@injectIntl
@connect((store, props) => ({
  games: store.matches.list,
  fetching: store.matches.fetching,
  pagination: store.matches.pagination,
  total: store.matches.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Matches extends Component {
  config = {
    filters: [
      {
        type: 'description',
        labelKey: 'filter_name',
      },
      {
        type: 'dateAfter',
        input: Date,
        entity: 'match',
        labelKey: 'filter_afterDate',
        defaultValue: moment().format('YYYY-MM-DD'),
      },
      {
        type: 'status',
        input: SelectConstant,
        entity: 'match',
        labelKey: 'filter_status',
        defaultValue: 'fixture',
      },
      {
        type: 'phaseCompetition',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
    ],
    toggles: [
      {
        type: 'live',
        labelKey: 'filter_live',
      },
    ],
    headers: [
      { id: 'date', labelKey: 'field_date' },
      { id: 'matchteamHomeTeamseasonTeamName', labelKey: 'match_homeTeam' },
      { id: 'matchteamAwayTeamseasonTeamName', labelKey: 'match_awayTeam' },
      {
        id: 'phase.tournament.competition.translations.name',
        labelKey: 'app_competition',
      },
    ],
  };

  defaultInputsValue = getDefaultInputsValue(this.config.filters);

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.search === '') {
      this.changeParams({
        type: 'SET_SORT_FILTER',
        payload: {
          filters: { ...this.defaultInputsValue },
          sort: 'date',
        },
      });
    }
    if (location.search !== '') {
      this.fetchData(location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (prevProps.location.search === '' && nextProps.location.search === '') {
      this.changeParams({
        type: 'SET_SORT_FILTER',
        payload: {
          filters: { ...this.defaultInputsValue },
          sort: 'date',
        },
      });
    }

    if (
      prevProps.location.search !== nextProps.location.search &&
      nextProps.location.search !== ''
    ) {
      this.fetchData(nextProps.location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchMatches(filter));
    dispatch(filterList('matches', filter));
  };

  handleFilters = change => {
    const filtersQuery = {
      ...change,
      phaseCompetition: getLinkId(change.phaseCompetition),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handleToggles = change => {
    this.changeParams({ type: 'SET_TOGGLE', payload: change });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);

    location.search != search && location.search === ''
      ? history.replace(`${getProject().url}/matches${search}`)
      : history.push(`${getProject().url}/matches${search}`);
  }, 500);

  render() {
    const {
      games,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="matches-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_matches' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_matchesList}</Typography>
              <ToggleFilter
                form="matches_togglefilters"
                toggles={this.config.toggles}
                onChange={this.handleToggles}
                initialValues={query.toggles}
              />
            </Toolbar>
            <SearchFilter
              form="matches_searchfilters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={games}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
