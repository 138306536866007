/*
    Date Format
    2010-01-01T00:00:00+01:00
    to
    2010-01-01
*/

import React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';

function DateInput({
  input: { value, ...input },
  labelKey,
  intl: { formatMessage: f },
  meta: { touched, error },
  hintText,
  className,
  disabled,
  required,
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });
  return (
    <TextField
      label={label}
      value={value && value.substring(0, 10)}
      error={Boolean(touched && error)}
      helperText={touched && error}
      type="date"
      InputLabelProps={{
        shrink: true,
        placeholder: hintText || label,
      }}
      className={className}
      inputProps={{ max: '2999-01-01' }}
      {...input}
      fullWidth
      disabled={disabled}
      required={required}
    />
  );
}
export default injectIntl(DateInput);

export const normalizeDate = val => {
  if (val === undefined || val === '') {
    return null;
  }
  return val;
};
