import { InputAdornment } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

const CURRENCIES = [
  {
    label: 'Euro',
    value: 'EUR',
  },
  {
    label: 'US Dollar',
    value: 'USD',
  },
  {
    label: 'Livre sterling',
    value: 'GBP',
  },
];

type IProps = {
  labelKey: string;
  name: string;
} & InjectedIntlProps;

// @ts-ignore
@injectIntl
export class AmountInput extends React.Component<IProps> {
  public render() {
    return (
      <div style={{ display: 'flex' }}>
        {this.renderValueTextField()}
        {this.renderCurrencySelect()}
      </div>
    );
  }

  private renderValueTextField = () => {
    const {
      labelKey,
      intl: { messages: m },
    } = this.props;
    const amount = this.props[this.props.name];

    const {
      input: { name, value, ...inputEvents },
      meta: { touched, error },
    } = amount.value;

    const label = m[labelKey || `field_${name}`];
    const humanValue = this.getHumanValue();

    return (
      <TextField
        name={name}
        value={value}
        label={label}
        error={touched && error}
        helperText={touched && error}
        input={<Input type="number" id={`select_${name}`} />}
        {...inputEvents}
        onChange={this.handleValueChange}
        fullWidth
        InputProps={{
          endAdornment: <InputAdornment position="start">{humanValue}</InputAdornment>,
        }}
      />
    );
  };

  private renderCurrencySelect = () => {
    const { currency } = this.props[this.props.name];
    const {
      input: { name, value, ...inputEvents },
      meta: { touched, error },
    } = currency;

    return (
      <FormControl style={{ minWidth: 150 }} error={touched && error}>
        <InputLabel htmlFor={`select_${name}`}>€$£</InputLabel>
        <Select
          name={name}
          value={value}
          input={<Input id={`select_${name}`} />}
          {...inputEvents}
          onChange={this.handleCurrencyChange}
        >
          {CURRENCIES.map(cur => (
            <MenuItem key={cur.value} value={cur.value}>
              {cur.label}
            </MenuItem>
          ))}
        </Select>
        {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    );
  };

  private handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value, currency } = this.props[this.props.name];
    value.input.onChange(event.target.value);
    if (!currency.input.value) {
      currency.input.onChange(CURRENCIES[0].value);
    }
  };

  private handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { currency } = this.props[this.props.name];
    currency.input.onChange(event.target.value);
  };

  // Divide by 1000 with 1 decimal precision
  private divideValue = (value: number) => Math.floor(value / 100) / 10;

  private getHumanValue = () => {
    const {
      intl: { messages: m },
      name,
    } = this.props;
    const { value } = this.props[name];
    let valueLabel = Number(value.input.value);
    if (!valueLabel) return '';

    let quantifierLabel = '';
    if (valueLabel >= 1000) {
      valueLabel = this.divideValue(valueLabel);
      quantifierLabel = m.unit_thousands;
    }
    if (valueLabel >= 1000) {
      valueLabel = this.divideValue(valueLabel);
      quantifierLabel = m.unit_millions;
    }
    return valueLabel + quantifierLabel;
  };
}
