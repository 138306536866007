import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getLinkId } from 'Services/tools';
import {
  fetchCompetitionClubs,
  fetchCountryCompetitions,
  fetchClubPlayers,
  selectClub,
  selectCompetition,
  selectCountry,
  selectPlayer,
  getClubCompetition,
} from './actions';
import { getProject } from 'Services/project';
import { fetchCountries } from '../areas/actions';
import FlagIcon from '@material-ui/icons/Flag';
import CompetitionIcon from 'Components/icons/Competition';
import ClubIcon from 'Components/icons/Club';
import PersonIcon from 'Components/icons/Person';
import Bread from './Bread';
import { Paper } from '@material-ui/core';

@withRouter
@connect(store => ({
  club: store.breadcrumb.club,
  clubs: store.breadcrumb.clubs,
  competition: store.breadcrumb.competition,
  competitions: store.breadcrumb.competitions,
  countries: store.areas.list,
  country: store.breadcrumb.country,
  players: store.breadcrumb.players,
  player: store.breadcrumb.player,
}))
export default class ComplexBreadcrumb extends Component {
  componentDidMount() {
    this.props.dispatch(fetchCountries());
  }

  setCountry = country => {
    this.props.dispatch(selectCountry(country));
    this.getCompetitionsList(country);
  };
  setCompetition = competition => {
    this.props.dispatch(selectCompetition(competition));
    this.getClubsList(competition);
  };
  setClub = club => {
    this.props.dispatch(selectClub(club));
    this.getPlayersList(club);
  };
  setPlayer = player => {
    this.props.dispatch(selectPlayer(player));
  };

  getCompetitionsList = country => {
    const { dispatch } = this.props;
    dispatch(fetchCountryCompetitions(getLinkId(country['@id'])));
  };
  getClubsList = competition => {
    const { dispatch } = this.props;
    dispatch(fetchCompetitionClubs(getLinkId(competition['@id'])));
  };
  getPlayersList = club => {
    const { dispatch } = this.props;
    dispatch(fetchClubPlayers(getLinkId(club['@id'])));
  };

  getClubCompetition = () => {
    const { dispatch, club } = this.props;
    dispatch(getClubCompetition(club.slug));
  };

  getAreaHref = () => {
    const { country } = this.props;
    if (!country) return `#${getProject().url}/competitions`;
    return `#${getProject().url}/competitions?area=${country.id}`;
  };
  getCompetitionHref = () => {
    const { competition } = this.props;
    if (!competition) return `#${getProject().url}/competitions`;
    return `#${getProject().url}/competitions/${competition.area.slug}/${
      competition.slug
    }`;
  };
  getClubHref = () => {
    const { club } = this.props;
    if (!club) return `#${getProject().url}/clubs`;
    return `#${getProject().url}/clubs/${club.slug}`;
  };
  getPlayerHref = () => {
    const { player } = this.props;
    if (!player) return `#${getProject().url}/players`;
    return `#${getProject().url}/players/${player.slug}`;
  };

  render() {
    const {
      club,
      clubs,
      competition,
      competitions,
      country,
      countries,
      player,
      players,
    } = this.props;

    if (club && !competition) {
      this.getClubCompetition();
    }

    return (
      <Paper id="breadcrumb" style={{ display: 'flex' }}>
        <Bread
          entity="country"
          crumb={country}
          source={countries}
          disabled={false}
          filter
          onSelect={this.setCountry}
          icon={FlagIcon}
          href={this.getAreaHref()}
        />
        <Bread
          entity="competition"
          crumb={competition}
          source={competitions}
          disabled={!country && !competition}
          onOpen={() => {
            competitions || this.getCompetitionsList(country);
          }}
          onSelect={this.setCompetition}
          icon={CompetitionIcon}
          href={this.getCompetitionHref()}
        />
        <Bread
          entity="club"
          crumb={club}
          source={clubs}
          disabled={!competition && !club}
          onOpen={() => {
            clubs || this.getClubsList(competition);
          }}
          onSelect={this.setClub}
          icon={ClubIcon}
          href={this.getClubHref()}
        />
        <Bread
          entity="player"
          crumb={player}
          source={players}
          disabled={!club && !player}
          onOpen={() => {
            players || this.getPlayersList(club);
          }}
          onSelect={this.setPlayer}
          icon={PersonIcon}
          href={this.getPlayerHref()}
        />
      </Paper>
    );
  }
}
