import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CompetitionInput from 'FD/competitions/Input';
import MatchInput from 'FD/matches/Input';
import PersonInput from 'FD/people/Input';
import TeamInput from 'FD/teams/Input';
import { getProject } from '../../../common/services/project';
import ArticleInput from '../../articles/Input';

const styles = {
  groupInput: {
    display: 'flex',
  },
  select: {
    width: 200,
  },
};

/** Deeplinking V2
 * Home             | footmercato://footmercato/
 * MyFM             | footmercato://footmercato/fm
 * Live             | footmercato://footmercato/matches
 * Live Mercato     | footmercato://footmercato/live-transfers
 * TvSchedule       | footmercato://footmercato/broadcast
 * Article          | footmercato://footmercato/article/a8582036173795504176-jt-foot-mercato-nice-met-les-bouchees-doubles-sur-le-mercato
 * Team             | footmercato://footmercato/team/club/ol
 * Person           | footmercato://footmercato/player/kylian-mbappe
 * Competition      | footmercato://footmercato/tournament/france/ligue-1
 * Match            | footmercato://footmercato/match/2823197814566238621-maroc-vs-espagne
 * Contact          | footmercato://footmercato/contact
 * Preferences      | footmercato://footmercato/menu
 */

const deeplinks = [
  { name: 'home', labelKey: 'page_home', link: '' },
  { name: 'myFM', labelKey: 'page_myFM', link: 'root/main/bottom/myFM' },
  { name: 'live', labelKey: 'page_live', link: 'root/main/bottom/matches/live' },
  { name: 'liveMercato', labelKey: 'page_liveMercato', link: 'root/main/bottom/liveMercato' },
  {
    name: 'tvSchedule',
    labelKey: 'page_tvSchedule',
    link: 'root/main/bottom/tvSchedule',
  },
  {
    name: 'competitions',
    labelKey: 'page_competitions',
    link: 'competitions',
  },
  { name: 'contact', labelKey: 'page_contact', link: 'contact' },
  { name: 'preferences', labelKey: 'page_preferences', link: 'preferences' },
  {
    name: 'confidentiality',
    labelKey: 'page_confidentiality',
    link: 'confidentiality',
  },
  { name: 'article', labelKey: 'page_article', formatLink: ({ id }) => `articles/${id}` },
  { name: 'team', labelKey: 'page_team', formatLink: ({ id }) => `teams/${id}/teamArticles` },
  {
    name: 'person',
    labelKey: 'page_person',
    formatLink: ({ id }) => `people/${id}/personArticles`,
  },
  {
    name: 'competition',
    labelKey: 'page_competition',
    formatLink: ({ id }) => `competitions/${id}/competitionArticles`,
  },
  {
    name: 'match',
    labelKey: 'page_match',
    formatLink: ({ id }) => `matches/${id}/matchComments`,
  },
  { name: 'url', labelKey: 'field_url' },
];

const deeplinksV2 = [
  { name: 'home', labelKey: 'page_home', link: '' },
  { name: 'myFM', labelKey: 'page_myFM', link: 'fm' },
  { name: 'live', labelKey: 'page_live', link: 'matches' },
  { name: 'liveMercato', labelKey: 'page_liveMercato', link: 'live-transfers' },
  {
    name: 'tvSchedule',
    labelKey: 'page_tvSchedule',
    link: 'broadcast',
  },
  { name: 'contact', labelKey: 'page_contact', link: 'contact' },
  { name: 'preferences', labelKey: 'page_preferences', link: 'menu' },
  { name: 'article', labelKey: 'page_article', formatLink: ({ id, slug }) => `article/a${id}-${slug}` },
  { name: 'team', labelKey: 'page_team', formatLink:  ({ clubType, slug }) => `team/${clubType}/${slug}` },
  {
    name: 'person',
    labelKey: 'page_person',
    formatLink: ({ role, slug }) => `${role}/${slug}`,
  },
  {
    name: 'competition',
    labelKey: 'page_competition',
    formatLink: ({ area, slug }) => `tournament/${area.slug}/${slug}`,
  },
  {
    name: 'match',
    labelKey: 'page_match',
    formatLink: ({ id, slug }) => `match/${id}-${slug}`,
  },
  { name: 'url', labelKey: 'field_url' },
];

@injectIntl
@withStyles(styles)
export default class UrlInput extends Component {
  state = {
    selected: 0,
    activeDeeplinking: getProject().enableDeeplinkingV2 ? deeplinksV2 : deeplinks
  };

  componentDidMount() {
    const { input } = this.props;
    input.onChange('');
  }

  handlePageChange = event => {
    const { selected, activeDeeplinking } = this.state;
    const newSelected = event.target.value;
    const { input } = this.props;
    if (selected !== newSelected) {
      input.onChange(activeDeeplinking[newSelected].link || '');
    }
    this.setState({ selected: newSelected });
  };

  handleUrlChange = event => {
    const { input } = this.props;
    input.onChange(event.target.value || '');
  };

  handleParamPageChange = (formatLink) => value => {
    const { input } = this.props;
    value ? input.onChange(formatLink(value)) : input.onChange('');
  };

  render() {
    const {
      intl: { messages: m },
      classes,
    } = this.props;

    const { selected, activeDeeplinking } = this.state;

    return (
      <div className={classes.groupInput}>
        <FormControl className={classes.select}>
          <InputLabel htmlFor="page">{m.field_page}</InputLabel>
          <Select
            value={selected}
            onChange={this.handlePageChange}
            inputProps={{
              name: 'page',
              id: 'page',
            }}
          >
            {activeDeeplinking.map((dl, index) => (
              <MenuItem value={index}>{m[dl.labelKey]}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.renderOptionnalInput()}
      </div>
    );
  }

  renderOptionnalInput = () => {
    const { selected, activeDeeplinking } = this.state;
    const {
      value,
      intl: { messages: m },
    } = this.props;

    const deeplink = activeDeeplinking[selected];

    switch (deeplink.name) {
      case 'url':
        return (
          <TextField
            label={m[deeplink.labelKey]}
            InputProps={{
              placeholder: m[deeplink.labelKey],
            }}
            name={deeplink.name}
            value={value}
            onChange={this.handleUrlChange}
            fullWidth
          />
        );

      case 'match':
        return (
          <MatchInput
            input={{
              onChange: this.handleParamPageChange(deeplink.formatLink),
              autoFocus: true,
            }}
            labelKey="app_match"
            useObject
          />
        );
      case 'article':
        return (
          <ArticleInput
            input={{
              onChange: this.handleParamPageChange(deeplink.formatLink),
              autoFocus: true,
            }}
            labelKey="app_article"
            useObject
          />
        );

      case 'team':
        return (
          <TeamInput
            input={{
              onChange: this.handleParamPageChange(deeplink.formatLink),
              autoFocus: true,
            }}
            labelKey="app_team"
            useObject
          />
        );

      case 'person':
        return (
          <PersonInput
            input={{
              onChange: this.handleParamPageChange(deeplink.formatLink), 
              autoFocus: true,
            }}
            labelKey="app_person"
            useObject
          />
        );
      case 'competition':
        return (
          <CompetitionInput
            input={{
              onChange: this.handleParamPageChange(deeplink.formatLink),
              autoFocus: true,
            }}
            labelKey="app_competition"
            useObject
          />
        );
    }
  };
}
