import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { getInnerCollection } from '../../../common/services/connectors';
import { IRootState } from '../../../typings';
import { fetchAgentCompanyAgents, updateAgent } from '../../people/agents/actions';
import { fetchAgentCompanyPlayers, updatePlayer } from '../../people/players/actions';
import { fetchAgentCompany, updateAgentCompany } from '../actions';

type IOwnProps = RouteComponentProps<{ topicId: string }>;

const mapDispatchToProps = {
  fetchAgentCompany,
  updateAgentCompany,
  updatePlayer,
  updateAgent,
  fetchAgentCompanyPlayers,
  fetchAgentCompanyAgents,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const id = ownProps.match.params.topicId;
  return {
    id,
    agentCompany: state.agentCompany.detail[id],
    fetching: state.agentCompany.fetching,
    players: getInnerCollection('playersIds', state.player, state.agentCompany, id),
    agents: getInnerCollection('agentsIds', state.agent, state.agentCompany, id),
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
