import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'TEAM',
  collections: {
    teamSeasonMembersIds: { get: 'teamSeasonMembers' },
    teamSeasonIds: { get: 'teamSeasons' },
  },
  inside: {
    club: { get: 'teams' },
  },
});
