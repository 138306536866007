import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import TopBar from '../../components/TopBar';
import {
  fetchGlobalConfiguration,
  fetchMobileConfiguration,
  updateGlobalConfiguration,
  updateMobileConfiguration,
} from '../actions';
import { GlobalConfigurationForm } from './GlobalConfigurationForm';
import { MobileConfigurationForm } from './MobileConfigurationForm';

const styles = theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
});

@withStyles(styles)
@injectIntl
@connect(store => ({
  configurations: store.configurations,
}))
export class ConfigurationsDetail extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { dispatch } = this.props;
    dispatch(fetchGlobalConfiguration());
    dispatch(fetchMobileConfiguration());
  };

  handleGlobalSubmit = data =>
    this.props.dispatch(updateGlobalConfiguration(data.id, data));
  handleMobileSubmit = data =>
    this.props.dispatch(updateMobileConfiguration(data.id, data));

  render() {
    const {
      classes,
      configurations,
      intl: { messages: m },
    } = this.props;
    if (configurations.fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }

    return (
      <Layout
        id="configurations-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_configurations' }]} />}
        main={
          <React.Fragment>
            {configurations.global && (
              <Paper elevation={2} className={classes.paper}>
                <Toolbar>
                  <Typography variant="h6">{m.app_globalConfiguration}</Typography>
                </Toolbar>
                <GlobalConfigurationForm
                  data={configurations.global}
                  onSubmit={this.handleGlobalSubmit}
                />
              </Paper>
            )}
            {configurations.mobile && (
              <Paper elevation={2}>
                <Toolbar>
                  <Typography variant="h6">{m.app_mobileConfiguration}</Typography>
                </Toolbar>
                <MobileConfigurationForm
                  data={configurations.mobile}
                  onSubmit={this.handleMobileSubmit}
                />
              </Paper>
            )}
          </React.Fragment>
        }
      />
    );
  }
}
