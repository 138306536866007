import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import ImageInput from 'FD/images/Input';
import TextInput from 'Components/inputs/Text';
import { getLinkId } from 'Services/tools';
import TagInput from '../Input';

export default class TagForm extends Component {
  config = {
    fields: [
      'id',
      'title',
      'color',
      'slug',
      'keyCode',
      'description',
      'shortDescription',
      'footdataMainImageId',
    ],
    objectFields: ['parent'],
    disposition: [
      {
        size: 3,
        fields: [
          {
            name: 'footdataMainImageId',
            component: ImageInput,
            normalize: getLinkId,
          },
        ],
      },
      {
        size: 9,
        fields: [
          { name: 'title', component: TextInput, size: 9 },
          { name: 'color', component: TextInput, size: 3 },
          { name: 'slug', component: TextInput, disabled: true, size: 6 },
          { name: 'keyCode', component: TextInput, disabled: true, size: 6 },
          { name: 'parent', component: TagInput },
          { name: 'shortDescription', component: TextInput, multiline: true },
          { name: 'description', component: TextInput, multiline: true },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`tag_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
      />
    );
  }
}
