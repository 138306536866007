import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import DatetimeInput, { formatUtc } from '../../../../common/components/inputs/Datetime';
import TextInput from '../../../../common/components/inputs/Text';
import Toggle, { normalizeBoolean } from '../../../../common/components/inputs/Toggle';
import { getProject } from '../../../../common/services/project';
import { CompetitionInputCollection } from '../../../competitions/CompetitionInputCollection';
import SelectConstant from '../../../components/constants/Select';
import { MatchInputCollection } from '../../../matches/MatchInputCollection';
import { PartnerInput } from '../../../partners/components/PartnerInput';
import { PersonInputCollection } from '../../../people/PersonInputCollection';
import { TeamInputCollection } from '../../../teams/TeamInputCollection';
import { ISurvey } from '../../typings';
import { SurveyOptionInputCollection } from '../SurveyOptionInputCollection';

interface IProps {
  data: any;
  onSubmit: (survey: ISurvey) => void;
  onDelete?: (survey: ISurvey) => void;
  onRemove?: (id: string) => void;
}

export class SurveyForm extends Component<IProps> {

  public config = {
    fields: [
      'id',
      'question',
      'type',
      'startDate',
      'endDate',
      'isEnabled',
      'hasPartner',
      'title',
      'shortTitle',
      'partnerType',
      'partnerLink',
      'partnerText',
      'partnerMainColor',
      'partnerSecondaryColor',
      'options',
      'isHomepageDisabled',
    ],
    objectFields: ['backgroundImage', 'partnerLogo', 'partnerOffer'],

    collectionObjectFields: ['competitions', 'teams', 'persons', 'matches'],
    disposition: [
      {
        title: 'form_general',
        fields: [
          { name: 'question', component: TextInput, size: 8, required: true },
          { name: 'type', component: SelectConstant, entity: 'survey', size: 4 },
          { name: 'startDate', component: DatetimeInput, normalize: formatUtc, size: 6, required: true },
          { name: 'endDate', component: DatetimeInput, normalize: formatUtc, size: 6, required: true },
          { name: 'isEnabled', component: Toggle, normalize: normalizeBoolean },
          {
            name: 'isHomepageDisabled',
            component: Toggle,
            normalize: normalizeBoolean,
            helpertext: 'field_isHomepageDisabled_survey_helperText',
          },
          { name: 'hasPartner', component: Toggle, normalize: normalizeBoolean },
        ],
      },
      {
        title: 'form_survey_options',
        fields: [{ name: 'options', component: SurveyOptionInputCollection, survey: this.props.data }],
      },
      {
        title: 'form_linkedData',
        fields: [
          {
            name: 'competitions',
            component: CompetitionInputCollection,
            normalize: this.buildUriFromCompetitionsId,
            size: 6,
          },
          { name: 'teams', component: TeamInputCollection, normalize: this.buildUriFromTeamsId, size: 6 },
          { name: 'persons', component: PersonInputCollection, normalize: this.buildUriFromPersonsId, size: 6 },
          { name: 'matches', component: MatchInputCollection, normalize: this.buildUriFromMatchesId, size: 6 },
        ],
      },
      {
        title: 'form_partner',
        fields: [{ name: 'partnerOffer', component: PartnerInput, normalize: this.buildUriPartnerOfferId, size: 6 }],
      },
    ],
  };


  public buildUriPartnerOfferId(value) {
    if (!value) return null;
    // tslint:disable-next-line:radix
    return `/api/1.0/${getProject().lang}/partner_offers/${value}`;
  }

  public buildUriFromCompetitionsId(values) {
    // tslint:disable-next-line:radix
    return values.map(e => (isNaN(parseInt(e)) ? e : `/api/1.0/${getProject().lang}/competitions/${e}`));
  }

  public buildUriFromTeamsId(values) {
    // tslint:disable-next-line:radix
    return values.map(e => (isNaN(parseInt(e)) ? e : `/api/1.0/${getProject().lang}/teams/${e}`));
  }

  public buildUriFromMatchesId(values) {
    // tslint:disable-next-line:radix
    return values.map(e => (isNaN(parseInt(e)) ? e : `/api/1.0/${getProject().lang}/matches/${e}`));
  }

  public buildUriFromPersonsId(values) {
    // tslint:disable-next-line:radix
    return values.map(e => (isNaN(parseInt(e)) ? e : `/api/1.0/${getProject().lang}/people/${e}`));
  }

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`survey_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }
}
