import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateCell from 'Components/TableList/cell/Date';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import ClubAvatar from '../../clubs/Avatar';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class Row extends Component {
  renderClubCell = club => {
    const { classes } = this.props;
    if (!club) return null;
    return (
      <div className={classes.rowContent}>
        <ClubAvatar club={club} />
        <div className={classes.label}>{club.name}</div>
      </div>
    );
  };

  render() {
    const { tableData } = this.props;
    const { date, membership, mainTeam, previousMainTeam } = tableData;
    const href = `#${getProject().url}/transfers/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          <DateCell date={date} />
        </TableCell>
        <TableCell>{membership && membership.personMatchName}</TableCell>
        <TableCell>
          {previousMainTeam ? this.renderClubCell(previousMainTeam.club) : '-'}
        </TableCell>
        <TableCell>{mainTeam && this.renderClubCell(mainTeam.club)}</TableCell>
      </TableRow>
    );
  }
}
