import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import BooleanCell from "Components/TableList/cell/Boolean";

const styles = {
  imgChannel: {
    maxWidth: 60,
    maxHeight: 30,
    margin: '0 8px 0 0',
  },
};

@withStyles(styles)
export default class Row extends Component {
  renderLogo = () => {
    const { classes, tableData } = this.props;
    const { logo } = tableData;
    if (!logo) return null;
    return (
      <img src={logo.originalUrl} className={classes.imgChannel} title={logo.name} />
    );
  };

  render() {
    const { tableData } = this.props;
    const { name, area, isActive, parent } = tableData;
    const href = `#${getProject().url}/channels/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>{name}</TableCell>
        <TableCell>{this.renderLogo()}</TableCell>
        <TableCell>{parent && parent.name}</TableCell>
        <TableCell>{area && area.name}</TableCell>
        <TableCell>
          <BooleanCell boolean={isActive} />
        </TableCell>
      </TableRow>
    );
  }
}
