import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchAgents(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    role: 'agent',
    partial: 0,
    'order[translations.priority]': 'asc',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_AGENTS' });

    return rest
      .get('people', { params })
      .then(
        res => dispatch({ type: 'FETCH_AGENTS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_AGENTS_REJECTED', payload: err.response })
      );
  };
}

export function fetchAgent(slug) {
  const params = {
    slug,
    role: 'agent',
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_AGENT' });

    return rest.get('people', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_AGENT_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_AGENT_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_AGENT_REJECTED', payload: err.response })
    );
  };
}

export function fetchAgentById(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_AGENT' });

    return rest
      .get(`people/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_AGENT_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_AGENT_REJECTED', payload: err.response })
      );
  };
}

export function updateAgent(id, agent) {
  const params = {
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_AGENT', payload: id });
    return rest.put(`people/${id}`, agent, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_agentUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_AGENT_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_AGENT_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addAgent(agent) {
  return function(dispatch) {
    dispatch({ type: 'ADD_AGENT' });

    return rest
      .post('people', { ...agent, role: 'agent' })
      .then(
        res => dispatch({ type: 'ADD_AGENT_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_AGENT_REJECTED', payload: err.response })
      );
  };
}

export function deleteAgent(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_AGENT', payload: id });

    return rest
      .delete(`people/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_AGENT_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_AGENT_REJECTED', payload: err.response })
      );
  };
}

export function searchAgents(search) {
  const params = {
    role: 'agent',
    'translations.lastName': search,
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_AGENTS', payload: { search } });
    return rest
      .get('search/people/', { params })
      .then(
        res => dispatch({ type: 'SEARCH_AGENTS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_AGENTS_REJECTED', payload: err.response })
      );
  };
}

export function fetchAgentCompanyAgents(agentCompany) {
  const params = {
    agentCompany,
    role: 'agent',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_AGENTCOMPANY_AGENTS', payload: agentCompany });

    return rest.get('people', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_AGENTCOMPANY_AGENTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_AGENTCOMPANY_AGENTS_REJECTED',
          payload: err.response,
        })
    );
  };
}
