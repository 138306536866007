import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'CLUB',
  collections: {
    productsIds: { get: 'products', set: 'product' },
    venuesIds: { get: 'venues', set: 'venue' },
    clubSponsorsIds: { get: 'clubSponsors', set: 'clubSponsor' },
    membershipsIds: { get: 'memberships' },
    teamsIds: { get: 'teams' },
  },
});
