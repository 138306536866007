import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import Button from '@material-ui/core/Button';
// import Text from '../inputs/Text';
import Text from '../../components/inputs/Text';
import { getProject } from '../../services/project';
import File from '../inputs/File';

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.primary[500]}`,
  },
});

@withStyles(styles)
@reduxForm({})
@injectIntl
export default class MediaDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.reset();
  };

  handleConfirm = data => {
    this.handleClose();
    this.props.onCreate(data);
  };

  render() {
    const {
      title,
      handleSubmit,
      pristine,
      submitting,
      classes,
      intl: { messages: m },
      children,
    } = this.props;
    const { open } = this.state;

    return (
      <div>
        {React.cloneElement(children, { onClick: this.handleOpen })}
        <Dialog open={open} classes={{ paper: classes.body }}>
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(this.handleConfirm)}>
            <DialogContent>
              <div>
                {/* <Field name="filename" component={Text} autoFocus /> */}
                <Field id="file_selector" name="file" component={File} required />
                <Field name="description" component={Text} label={m.field_description} required />
                <Typography variant="subtitle2" style={{ marginTop: 8 }}>
                  <a href={`#${getProject().url}/documentation`} target="_blank">
                    {m.read_the_docs}
                  </a>
                </Typography>
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} disabled={submitting}>
                {m.button_cancel}
              </Button>
              <Button color="primary" variant="contained" type="submit" disabled={pristine || submitting}>
                {m.button_create}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
