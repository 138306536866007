import moment from 'moment';
import * as React from 'react';
import { InputCollection } from '../../components/InputCollection';
import { IInput } from '../../components/InputCollection/InputCollection.component';
import { searchMatches } from '../actions';
import { MatchCollectionChip } from './MatchCollectionChip.component';
interface IProps {
  meta: any;
  input: IInput;
  labelKey: string;
}
export class MatchInputCollection extends React.Component<IProps> {
  public render() {
    const { input, meta, labelKey } = this.props;

    return (
      // @ts-ignore
      <InputCollection
        input={input}
        meta={meta}
        search={searchMatches}
        getLabel={this.getLabel}
        CollectionChip={MatchCollectionChip}
        labelKey={labelKey}
      />
    );
  }

  private getLabel = data => {
    let feminineSuffix = '';
    if (data.homeTeam && data.homeTeam.gender === 'female') {
      feminineSuffix = ` (${data.homeTeam.genderTranslated})`;
    }
    return `${data.description} (${moment(data.date).format('L')})${feminineSuffix}`;
  };
}
