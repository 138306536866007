import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getProject, buildFMLink } from 'Services/project';

import {
  fetchPageseo,
  updatePageseo,
  deletePageseo,
  fetchConstantsSeo,
} from '../actions';

import TabsRoute from 'Components/TabsRoute';

import TopBar from '../../components/TopBar';
import Form from './Form';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import DeleteButton from 'Components/DeleteButton';
import PageviewIcon from '@material-ui/icons/Pageview';

const styles = theme => ({
  leftButton: {
    marginRight: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  id: props.match.params.topicId,
  pageseo: store.pageseo.detail[props.match.params.topicId],
  constantsSeo: store.constantsSeo.list,
  constantsSeoFetching: store.constantsSeo.fetching,
  fetching: store.pageseo.fetching,
}))
export default class PageseoDetail extends Component {
  menu = [{ label: 'app_pageseoInfo', link: '/' }];
  state = {
    url: '',
  };

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    } else if (next.pageseo && next.pageseo.url) {
      buildFMLink(next.pageseo.url, true).then(url => this.setState({ url }));
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchPageseo(targetId || id));
    dispatch(fetchConstantsSeo());
  };

  handlePageseoUpdate = changes => {
    const { dispatch, pageseo } = this.props;
    return dispatch(updatePageseo(pageseo.id, changes));
  };

  handleRemove = () => {
    const { pageseo, dispatch, history } = this.props;
    return dispatch(deletePageseo(pageseo.id)).then(() => {
      history.push(`${getProject().url}/pageseos`);
    });
  };

  render() {
    const {
      fetching,
      pageseo,
      constantsSeo,
      constantsSeoFetching,
      match,
      history,
      classes,
      intl: { messages: m },
    } = this.props;

    if (fetching || constantsSeoFetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!pageseo) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="pageseo-layout"
        top={
          <TopBar
            crumbStack={[
              {
                labelKey: 'app_pageseos',
                href: `#${getProject().url}/pageseos`,
              },
              { label: pageseo.url },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{pageseo.url}</Typography>
              <div style={{ flex: 1 }} />
              <Button
                variant="contained"
                href={this.state.url}
                target="_blank"
                className={classes.leftButton}
              >
                <PageviewIcon className={classes.leftButton} />
                {m.button_preview}
              </Button>
              <DeleteButton onRemove={this.handleRemove} />
              <ReturnButton className={classes.leftButton} entity="pageseos" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route
              exact
              path={match.url}
              render={() => (
                <Form
                  onSubmit={this.handlePageseoUpdate}
                  data={pageseo}
                  constantsSeo={constantsSeo}
                />
              )}
            />
          </Paper>
        }
      />
    );
  }
}
