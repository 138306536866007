import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { SimpleBreadcrumbs } from '../../../../common/components/SimpleBreadcrumbs';
import { IdBadge } from 'Components/IdBadge';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import Revisions from 'Components/revisions';
import RevisionNote from 'Components/revisions/Note';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getItemBySlug } from 'Services/connectors';
import { getDefaultPeoplePath, getPersonUsualName } from '../../utils';
import { fetchCoach, updateCoach } from '../actions';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  coach: getItemBySlug(store.coach, props.match.params.topicSlug),
  fetching: store.coach.fetching,
}))
export default class CoachDetail extends Component {
  state = {
    showRevisions: false,
  };
  menu = [{ label: 'app_personInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchCoach(targetSlug || slug));
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handleCoachChange = person => {
    const { dispatch } = this.props;
    return dispatch(updateCoach(person.id, person));
  };

  getLabel = () => {
    const { coach } = this.props;
    return getPersonUsualName(coach);
  };

  render() {
    const {
      fetching,
      coach,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!coach) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="coach-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_coaches', href: `#${getDefaultPeoplePath('coaches')}` },
              { label: this.getLabel() },
            ]}
          />
        }
        main={
          <div>
            <Paper elevation={2}>
              <Toolbar>
                <Typography variant="h6">{this.getLabel()}</Typography>
                <IdBadge id={coach.id} />
                <div style={{ flex: 1 }} />
                <ReturnButton entity="coaches" />
              </Toolbar>

              <TabsRoute match={match} menu={this.menu} history={history} />

              <Route
                exact
                path={match.url}
                render={() => (
                  <div>
                    <div>
                      <RevisionNote
                        style={{ margin: 16 }}
                        createdAt={coach.createdAt}
                        updatedAt={coach.updatedAt}
                        toggle={this.toggleRevisions}
                      />
                      {this.state.showRevisions && (
                        <Revisions
                          entity="person"
                          id={coach.id}
                          updatedAt={coach.updatedAt}
                          onRollback={() => this.fetchData()}
                        />
                      )}
                    </div>
                    <Form onSubmit={this.handleCoachChange} data={coach} />
                  </div>
                )}
              />
            </Paper>
          </div>
        }
      />
    );
  }
}
