import * as React from 'react';
import AsyncSelect from 'react-select/async';
import {ICompetitionOption, IComponentProps} from "./CompetitionInput.connector";
import debounce, { buildLink } from "../../../common/services/tools";
import {getCompetitionLabel} from "../../utils/competition";

export interface IComponentState {
    open: boolean;
    anchorEl: undefined;
    selectedValue: ICompetitionOption | null;
}

export class CompetitionInput extends React.Component<IComponentProps,IComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: undefined,
            selectedValue: props.selectedCompetitionOption,
        };
    }

    public render() {
        const { selectedValue } = this.state;

        return (
            <AsyncSelect
                value={selectedValue}
                onChange={this.handleChange}
                cacheOptions
                isClearable
                loadOptions={ this.loadOptions }
                placeholder="Competition"
            />
        );
    }

    public handleClick = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    private loadOptions = debounce((inputValue: string, callback: (options: ICompetitionOption[]) => void) => {
        const { searchCompetitions } = this.props;
        // @ts-ignore
        return searchCompetitions(inputValue).then(response => {
            if (Array.isArray(response.payload)) {
                const options = this.getCompetitionOptions(response.payload);
                callback(options);
            }
            callback([]);
        });
    }, 300);

    private getCompetitionOptions = (competitions) => {
        return competitions.map(competition =>  ({
            value: buildLink('Competition', competition.object.id),
            label: getCompetitionLabel(competition.object)
        }));
    };

    private handleChange = selectedValue => {
        const {input} = this.props;
        this.setState({selectedValue});
        input.onChange(selectedValue ? selectedValue.value : null);
    };
}
