import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import RevisionDiff from '../../revisions/Detail/RevisionDiff';

@injectIntl
export default class ConflictDialog extends Component {
  render() {
    const {
      revision,
      open,
      onClose,
      intl: { messages: m },
    } = this.props;

    if (!revision) return null;

    return (
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="conflict-dialog-title"
      >
        <DialogTitle id="conflict-dialog-title">
          {m.dialog_conflictTitle}
          <br />
          <small>
            {m.dialog_lastEditBy} {revision.userName}
          </small>
        </DialogTitle>
        <DialogContent>
          {revision && <RevisionDiff revision={revision.diff} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose('cancel')}>{m.revision_updateCancel}</Button>
          <Button onClick={() => onClose('reload')} color="secondary">
            {m.revision_updateReload}
          </Button>
          <Button onClick={() => onClose('erase')} color="secondary">
            {m.revision_updateErase}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
