import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const styleSheet = theme => ({
  container: {
    padding: '16px 16px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '4px 4px 64px',
    },
  },
});

@withStyles(styleSheet)
export default class Layout extends Component {
  render() {
    const { id, dataTest, main, top, left, right, classes } = this.props;
    return (
      <Grid id={id} data-test={dataTest} container spacing={2} classes={{ container: classes.container }}>
        {top ? (
          <Grid key="top" item xs={12}>
            {top}
          </Grid>
        ) : null}
        {left ? (
          <Grid key="left" item xs={12} md={3} lg={2}>
            {left}
          </Grid>
        ) : null}
        {main ? (
          <Grid key="main" item xs>
            {main}
          </Grid>
        ) : null}
        {right ? (
          <Grid key="right" item xs={12} md={3} lg={2}>
            {right}
          </Grid>
        ) : null}
      </Grid>
    );
  }
}
