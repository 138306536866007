import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../../typings';
import { ICommentary } from '../../../../../commentaries/typings';
import { IMatchEventMissedPenalty } from '../../../../../matchEvents/typings';
import { getLiveMembership, getLiveTeam, getPlayerInfo } from '../../../selector';

interface IOwnProps {
  commentary: ICommentary;
}

const mapDispatchToProps = {};
const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const matchEvent = ownProps.commentary.matchEvent as IMatchEventMissedPenalty;

  const hasSavedByPlayer = matchEvent.savedByPlayerId ? getPlayerInfo(state, matchEvent.savedByPlayerId) : null;

  return {
    homeTeam: getLiveTeam(state, 'home'),
    awayTeam: getLiveTeam(state, 'away'),
    membership: getLiveMembership(state, ownProps.commentary.matchEvent.matchTeamPlayerId),
    hasSavedByPlayer,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
