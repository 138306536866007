import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import DeleteButton from '../../components/DeleteButton';
import { UserRestriction } from '../../components/UserRestiction';
import { UserForm } from '../components/UserForm.component';
import { IUser } from '../typings';
import Author from './Author';
import Password from './Password';
import { IComponentProps } from './UserDetail.connector';

export class UserDetail extends React.Component<IComponentProps> {
  private menu = [
    { label: 'app_userInfo', link: '/' },
    { label: 'app_userPassword', link: '/password' },
    { label: 'app_userAuthor', link: '/author' },
  ];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id, user, me, changeLocale } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    } else if (user && user.id === me.id && user.language !== next.user.language) {
      changeLocale(next.user.language);
    }
  }

  public render() {
    const {
      fetching,
      user,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!user) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="user-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_users', href: `#${getProject().url}/users` },
              { label: user.email },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{user.email}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <DeleteButton onRemove={this.remove} />
              </UserRestriction>
              <ReturnButton entity="users" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
            <Route path={`${match.url}/password`} render={this.renderPassword} />
            <Route path={`${match.url}/author`} render={this.renderAuthor} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { user } = this.props;
    return <UserForm onSubmit={this.handleUpdateWithWebsites} data={user} />;
  };

  private renderPassword = () => {
    const { match } = this.props;
    return (
      <Password
        onSubmit={this.handleUpdate}
        url={`${match.url}/perso`}
        history={history}
      />
    );
  };
  private renderAuthor = () => {
    const { match, user } = this.props;
    return (
      <Author
        user={user}
        fetchDataAuthor={this.fetchDataAuthor}
        addAuthor={this.handleAddAuthor}
        updateAuthor={this.handleUpdateAuthor}
        url={`${match.url}/author`}
        history={history}
      />
    );
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchUser } = this.props;
    fetchUser(targetId || id);
  };

  private handleUpdate = (user: IUser) => {
    const { id, updateUser } = this.props;
    return updateUser(id, user);
  };

  private remove = () => {
    const { deleteUser, history, id } = this.props;
    // @ts-ignore
    return deleteUser(id).then(history.push(`${getProject().url}/users`));
  };

  private handleUpdateWithWebsites = (newUser: IUser) => {
    const {
      id,
      user,
      updateUser,
      activateAuthorFr,
      activateAuthorDe,
      activateAuthorEs,
      deactivateAuthorFr,
      deactivateAuthorDe,
      deactivateAuthorEs,
    } = this.props;
    const targets = ['footmercato_fr', 'footmercato_es', 'footmercato_de'];
    const websitesAdded = targets
      .filter(w => newUser.websites.includes(w))
      .filter(w => !user.websites.includes(w));
    const websitesRemoved = targets
      .filter(w => user.websites.includes(w))
      .filter(w => !newUser.websites.includes(w));

    if (websitesAdded.length) {
      if (websitesAdded.includes('footmercato_fr')) {
        activateAuthorFr(newUser.username);
      }
      if (websitesAdded.includes('footmercato_de')) {
        activateAuthorDe(newUser.username);
      }
      if (websitesAdded.includes('footmercato_es')) {
        activateAuthorEs(newUser.username);
      }
    }
    if (websitesRemoved.length) {
      if (websitesRemoved.includes('footmercato_fr')) {
        deactivateAuthorFr(newUser.username);
      }
      if (websitesRemoved.includes('footmercato_de')) {
        deactivateAuthorDe(newUser.username);
      }
      if (websitesRemoved.includes('footmercato_es')) {
        deactivateAuthorEs(newUser.username);
      }
    }

    return updateUser(id, newUser);
  };

  private fetchDataAuthor = () => {
    const { fetchAuthor, user } = this.props;
    fetchAuthor(user.username);
  };

  private handleAddAuthor = data => {
    const { addAuthor } = this.props;
    const { username, bio, publicName, twitterAccount } = data;
    const author = {
      username,
      bio,
      publicName,
      twitterAccount,
    };
    addAuthor(author);
    this.fetchData();
  };

  private handleUpdateAuthor = data => {
    const { updateAuthor } = this.props;
    const { id, username, bio, publicName, twitterAccount } = data;
    const author = {
      username,
      bio,
      publicName,
      twitterAccount,
    };
    updateAuthor(id, author);
  };
}
