import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProjects } from 'Services/project';
import MainLayout from 'Components/MainLayout';
import Toolbar from 'Components/Toolbar';
import fetchMeOnce from './common/actions/me';

class HomeComponent extends Component {
  UNSAFE_componentWillMount() {
    this.props.dispatch(fetchMeOnce());
    this.redirect(this.props.me);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.redirect(next.me);
  }

  redirect = me => {
    const { history } = this.props;

    if (me.websites && me.websites[0]) {
      const mainProject = getProjects().find(p => me.websites.includes(p.name));
      history.push(mainProject.url);
    }
  };

  render() {
    this.redirect(this.props.me);
    return <MainLayout toolbar={<Toolbar />} />;
  }
}

export const Home = connect(store => ({
  me: store.me,
}))(HomeComponent);
