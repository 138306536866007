import * as React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { IComponentProps } from './ShortcutHandler.connector';

export enum EShortcut {
  Save = 'ctrl+s',
  Refresh = 'ctrl+r',
  Edit = 'ctrl+e',
}

export class ShortcutHandler extends React.PureComponent<IComponentProps> {
  public render() {
    return (
      <KeyboardEventHandler
        handleKeys={[EShortcut.Save, EShortcut.Edit, EShortcut.Refresh]}
        handleFocusableElements
        onKeyEvent={this.handleKeyPress}
      />
    );
  }

  private handleKeyPress = (key: string, e: Event) => {
    switch (key) {
      case EShortcut.Refresh:
        this.handleRefresh();
        break;
      case EShortcut.Save:
        this.handleSave();
        break;
      case EShortcut.Edit:
        this.handleEdit();
        break;
    }
    e.preventDefault();
  };

  private handleRefresh = () => {
    this.props.syncChronoToCommentaryLive();
  };

  private handleSave = () => {
    const {
      isSubmitButtonEnable,
      editingCommentaryId,
      updateLiveCommentary,
      createLiveCommentary,
    } = this.props;
    if (isSubmitButtonEnable) {
      editingCommentaryId ? updateLiveCommentary() : createLiveCommentary();
    }
  };

  private handleEdit = () => {
    const { editingCommentaryId, lastCommentary, recoverLiveCommentary } = this.props;
    if (!editingCommentaryId && lastCommentary) {
      recoverLiveCommentary(lastCommentary);
    }
  };
}
