import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import DateCell from '../../../common/components/TableList/cell/Date';
import { getProject } from '../../../common/services/project';
import { ArticleAuthorsCell } from '../components/ArticleAuthorsCell';
import { ArticleStatusIcon } from '../components/ArticleStatusIcon';
import { ArticleTagsCell } from '../components/ArticleTagsCell';
import { ArticleTitleCell } from '../components/ArticleTitleCell';
import { IArticle } from '../typings';
import { thousandUnitValue } from '../utils';

interface IComponentProps {
  tableData: IArticle;
}
export class ArticleRow extends Component<IComponentProps> {
  public render() {
    const { tableData } = this.props;
    const { status, authors, tags, publishedAt, unpublishedAt, analyticsWebCount, analyticsMobileCount } = tableData;
    const href = `#${getProject().url}/articles/${tableData.id}`;
    const analyticsCountValue = (analyticsWebCount ?? 0) + (analyticsMobileCount ?? 0);
    return (
      // @ts-ignore
      <TableRow href={href} component="a">
        <TableCell size="small">
          <ArticleStatusIcon status={status} />
        </TableCell>
        <TableCell size="small">
          <ArticleTitleCell article={tableData} />
        </TableCell>
        <TableCell size="small">
          <ArticleAuthorsCell authors={authors} />
        </TableCell>
        <TableCell size="small">
          <ArticleTagsCell tags={tags} />
        </TableCell>
        <TableCell size="small">
          <DateCell date={publishedAt} dateFormat="DD[/]MM[/]YYYY HH[:]mm[:]ss" />
        </TableCell>
        <TableCell size="small">
          <DateCell date={unpublishedAt} dateFormat="DD[/]MM[/]YYYY HH[:]mm[:]ss" />
        </TableCell>
        <TableCell size="small">{analyticsCountValue ? `${thousandUnitValue(analyticsCountValue)}K` : '-'}</TableCell>
      </TableRow>
    );
  }
}
