import rest from 'Services/restFootMercato';
import { SubmissionError } from 'redux-form';

/*
 * Fetch all menuItems for menu 'do_not_miss' order by position
 */
export function fetchMenu() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_HIGHLIGHTING_MENU' });

    return rest
      .get('menu_items', {
        params: {
          menu: 'do_not_miss',
          'order[position]': 'asc',
          itemsPerPage: 15,
        },
      })
      .then(
        res =>
          dispatch({
            type: 'FETCH_HIGHLIGHTING_MENU_FULFILLED',
            payload: { list: res.data },
          }),
        err =>
          dispatch({
            type: 'FETCH_HIGHLIGHTING_MENU_REJECTED',
            payload: err.response,
          })
      );
  };
}

export function updateMenuItem(id, item) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_MENU_ITEM', payload: id });

    return rest.put(`menu_items/${id}`, item).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_menuItemUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_MENU_ITEM_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { description, errors } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: description, type: 'error' },
          });
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_MENU_ITEM_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}
