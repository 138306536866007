import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import React, { Component } from 'react';
import { getMatchDetailHref } from '../../utils/href';
interface IMatch {
  id: string;
  date: string;
  displayDay: boolean;
  description: string;
  homeTeamDescription: string;
  awayTeamDescription: string;
}
interface IProps {
  match: IMatch;
}

export class PhaseMatch extends Component<IProps> {
  public render() {
    const { match } = this.props;
    if (!match) {
      return <div>no data</div>;
    }

    const content = this.renderContent(match);
    const matchHref = getMatchDetailHref(match.id);

    return (
      <ListItem dense button component="a" href={matchHref}>
        <ListItemText primary={content} />
      </ListItem>
    );
  }

  private renderContent = (match: IMatch) => {
    const timeLabel = moment(match.date).format('HH:mm');

    return (
      <span>
        <span style={{ width: 96, display: 'inline-block' }}>
          {match.displayDay ? moment(match.date).format('L') : null}
        </span>
        <span style={{ width: 48, display: 'inline-block' }}>{timeLabel}</span>
        <span>{match.description} {(match.homeTeamDescription || match.awayTeamDescription) ? ': ' + (match.homeTeamDescription ?? '?') + ' - ' + (match.awayTeamDescription ?? '?') : ''}</span>
      </span>
    );
  };
}
