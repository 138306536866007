import IconButton from '@material-ui/core/IconButton';
import NavigationClose from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { IFilter } from '../../../typings';
import {
  getDefaultInputsValue,
  getInitialInputsValue,
} from '../../services/getDefaultInputsValue';
import TextInput from '../inputs/Text';
import { IComponentProps } from './SearchFilter.connector';

export class SearchFilter extends Component<IComponentProps> {
  private defaultInputsValue = getDefaultInputsValue(this.props.filters);
  private initialInputsValue = getInitialInputsValue(this.props.filters);

  public componentDidMount() {
    if (this.props.location.search === '') {
      let filters;

      // Default used to be required if search == ""
      if (Object.keys(this.defaultInputsValue).length > 0) {
        filters = { ...this.defaultInputsValue };
      }

      // Initial used to the first render
      if (Object.keys(this.initialInputsValue).length > 0) {
        filters = { ...filters, ...this.initialInputsValue };
      }

      this.props.initialize(filters);
    }
  }

  public componentDidUpdate() {
    if (
      this.props.location.search === '' &&
      Object.keys(this.defaultInputsValue).length > 0
    ) {
      this.props.initialize(this.defaultInputsValue);
    }
  }

  public render() {
    const { filters, classes } = this.props;

    if (!filters || !filters.length) {
      return null;
    }

    return (
      <div>
        <form className={classes.container} onSubmit={this.handleSubmit}>
          {filters.map((filter: IFilter) => (
            <div className={classes.blockField} key={filter.type}>
              <Field
                name={filter.type}
                component={filter.input || TextInput}
                className={`${classes.field} ${filter.classes || null}`}
                normalize={filter.normalize || null}
                entity={filter.entity}
                labelKey={filter.labelKey}
                required={filter.required}
                disabled={filter.disabled}
              />
              {!filter.required && (
                <IconButton
                  className={classes.closeButton}
                  onClick={this.onClose(filter)}
                >
                  <NavigationClose />
                </IconButton>
              )}
            </div>
          ))}
        </form>
      </div>
    );
  }

  private onClose = (extra: IFilter) => () => {
    this.clearField(extra.type);
  };

  private clearField = (type: IFilter['type']) => {
    this.props.change(type, null);
  };

  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
}
