import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import BooleanCell from '../../components/TableList/cell/Boolean';
import { getProject } from '../../services/project';

interface IProps {
  tableData: any;
}
export class UserRow extends Component<IProps> {
  public render() {
    const { tableData } = this.props;
    const { email, username, firstname, lastname, isActive } = tableData;
    const href = `#${getProject().url}/users/${tableData.id}`;

    return (
      // @ts-ignore href
      <TableRow hover href={href} component="a">
        <TableCell>{email}</TableCell>
        <TableCell>{username}</TableCell>
        <TableCell>{firstname}</TableCell>
        <TableCell>{lastname}</TableCell>
        <TableCell>
          {/*
          // @ts-ignore */}
          <BooleanCell boolean={isActive} />
        </TableCell>
      </TableRow>
    );
  }
}
