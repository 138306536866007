export default function reducer(
  state = {
    list: [],
    detail: {},
  },
  action
) {
  switch (action.type) {
    case 'FETCH_INJURY_TYPES_FIELD': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_INJURY_TYPES_FIELD_FULFILLED': {
      const { collection } = action.payload;
      // localStorage.setItem("areas", JSON.stringify(collection));

      return {
        ...state,
        list: collection,
      };
    }
    case 'FETCH_INJURY_TYPES_FIELD_REJECTED': {
      return {
        ...state,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }

    case 'FETCH_INJURY_TYPE_FIELD_FULFILLED': {
      const newDetail = { ...state.detail };
      newDetail[action.payload.id] = action.payload;
      return {
        ...state,
        detail: newDetail,
      };
    }

    default:
      return state;
  }
}
