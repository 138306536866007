import { WithStyles, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { ISide } from '../../../../matches/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import {
  selectMatchTeamPlayerAction,
  unselectMatchTeamPlayerAction,
  updateMatchTeamPlayerAction,
} from '../../actions';
import {
  selectIsMatchTeamPlayerInComposition,
  selectIsMatchTeamPlayerInSubstitutes,
  selectIsSelectedMatchTeamPlayer,
} from '../../selector';
import { swapMatchTeamPlayerInList } from './actions';
import { styles } from './styles';

const mapDispatchToProps = {
  selectMatchTeamPlayer: selectMatchTeamPlayerAction,
  unselectMatchTeamPlayer: unselectMatchTeamPlayerAction,
  updateMatchTeamPlayer: updateMatchTeamPlayerAction,
  swapMatchTeamPlayerInList,
};
interface IOwnProps {
  matchTeamPlayer: IMatchTeamPlayer;
  selectedMatchTeamPlayer: IMatchTeamPlayer | null;
  side: ISide;
  usedShirtNumbers: number[];
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    isMatchTeamPlayerInComposition: selectIsMatchTeamPlayerInComposition(
      state,
      ownProps.side,
      ownProps.matchTeamPlayer
    ),
    isMatchTeamPlayerInSubstitutes: selectIsMatchTeamPlayerInSubstitutes(
      state,
      ownProps.side,
      ownProps.matchTeamPlayer
    ),
    isSelectedMatchTeamPlayer: selectIsSelectedMatchTeamPlayer(
      state,
      ownProps.side,
      ownProps.matchTeamPlayer
    ),
  };
};

export type IComponentProps = WithStyles &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles)
);
