import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import {
  activateAuthorDe,
  activateAuthorEs,
  activateAuthorFr,
  addAuthor,
  deactivateAuthorDe,
  deactivateAuthorEs,
  deactivateAuthorFr,
  updateAuthor,
} from '../../../footmercato/authors/actions';
import { IRootState } from '../../../typings';
import { changeLocale } from '../../actions/intl';
import { deleteUser, fetchAuthor, fetchUser, updateUser } from '../actions';

type IOwnProps = RouteComponentProps<{ topicId: string }>;

const mapDispatchToProps = {
  activateAuthorDe,
  activateAuthorEs,
  activateAuthorFr,
  deactivateAuthorDe,
  deactivateAuthorEs,
  deactivateAuthorFr,
  fetchUser,
  deleteUser,
  updateUser,
  fetchAuthor,
  changeLocale,
  addAuthor,
  updateAuthor,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const id = ownProps.match.params.topicId;
  return {
    id,
    user: state.user.detail[id],
    fetching: state.user.fetching,
    me: state.me,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
