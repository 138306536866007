import Paper from '@material-ui/core/Paper';
import React from 'react';
import { Markdown } from '../../../common/components/Markdown';
import {getProject} from "../../../common/services/project";

export class Documentation extends React.Component {
  public render() {
    return (
      <div style={{ margin: 8 }}>
        <Paper elevation={2} style={{ padding: 16, margin: 'auto', maxWidth: 1000 }}>
          <Markdown filePath={"docs/footdata/" + getProject().lang + "/match_commentary.md"} />
        </Paper>
      </div>
    );
  }
}
