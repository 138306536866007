import { Paper, Toolbar, Typography } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce from '../../../common/services/tools';
import { BookmakerRow } from './BookmakerRow.component';
import { IComponentProps } from './BookmakersList.connector';

export class BookmakersList extends React.Component<IComponentProps> {
  private config = {
    headers: [
      { labelKey: 'field_name' },
      { labelKey: 'field_code' },
      { labelKey: 'field_priority' },
      { labelKey: 'field_affiliationText' },
      { labelKey: 'field_cssColor' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/bookmakers${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      bookmakers,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;
    return (
      <Layout
        id="bookmakers-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_bookmakers' }]} />
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_bookmakers}</Typography>
              <div style={{ flex: 1 }} />
            </Toolbar>
            <Alert severity="info">
              <a href={`#${getProject().url}/documentation/bookmakers`} target="_blank">
                {m.read_the_docs}
              </a>
            </Alert>
            <TableList
              tableData={bookmakers}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={BookmakerRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchBookmakers } = this.props;
    fetchBookmakers(filter);
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
}
