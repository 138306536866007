import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { ISide } from '../../../../matches/typings';
import { selectTeamOldMatches } from '../../selector';
import { importPreviousMatchComposition } from './requests';
import { styles } from './styles';

const mapDispatchToProps = {
  importPreviousMatchComposition,
};
interface IOwnProps {
  side: ISide;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return { oldMatches: selectTeamOldMatches(state, ownProps.side) };
};

export type IComponentProps = WithStyles &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
