import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import SelectConstant from 'FDComponents/constants/Select';

export default class Form extends Component {
  config = {
    fields: ['id', 'name', 'rank', 'type', 'targetDescription'],
    disposition: [
      {
        fields: [
          {
            name: 'rank',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          {
            name: 'type',
            component: SelectConstant,
            entity: 'rankingRule',
            size: 6,
          },
          { name: 'name', component: TextInput, size: 6, disabled: true },
          { name: 'targetDescription', component: TextInput, size: 6 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`rankingRule_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
