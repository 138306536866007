import { FormEdit } from '../../../common/components/FormEdit';
import TextInput from '../../../common/components/inputs/Text';
import ImageInput from 'FD/images/Input';
import Toggle, { normalizeBoolean } from '../../../common/components/inputs/Toggle';
import React, { Component } from 'react';
import { LocaleSelect } from '../../../common/components/inputs/LocaleSelect';
import SelectConstant from '../../components/constants/Select';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'live',
      'slug',
      'isCommented',
      'isHighlighted',
      'mainCommentatorLocale',
      'customCoverImageUrl',
      'homeTeamDescription',
      'awayTeamDescription',
    ],
    disposition: [
      {
        fields: [
          { name: 'slug', component: TextInput, disabled: true, size: 6 },
          {
            name: 'live',
            component: SelectConstant,
            entity: 'match',
            size: 6,
          },
          {
            name: 'isCommented',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isHighlighted',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'mainCommentatorLocale',
            component: LocaleSelect,
            size: 6,
          },
          {
            name: 'customCoverImageUrl',
            component: ImageInput,
            returnInputUrl: true,
            size: 6,
          },
          { name: 'homeTeamDescription', component: TextInput, size: 6 },
          { name: 'awayTeamDescription', component: TextInput, size: 6 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`match_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
