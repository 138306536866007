export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'TOGGLE_DRAWER': {
      return {
        open: action.payload.open,
      };
    }
    default:
      return state;
  }
}
