import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HistoryIcon from '@material-ui/icons/History';
import EventEmitter from 'events';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getFMProject, getProject } from 'Services/project';
import { cleanMarkdown } from '../../../common/components/inputs/TextEdit/utils';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import RevisionNote from '../../../common/components/revisions/Note';
import TabsRoute from '../../../common/components/TabsRoute';
import { getCollection } from '../../../common/services/connectors';
import { addLiveTransfer } from '../../briefs/liveTransfers/actions';
import TopBar from '../../components/TopBar';
import { fetchArticleRevision } from '../../revisions/actions';
import { briefFromArticle, fetchArticle, updateArticle } from '../actions';
import { ArticleBriefs } from '../components/ArticleBriefs';
import { ArticleProducts } from '../components/ArticleProducts';
import { ArticleRelations } from '../components/ArticleRelations';
import { ArticleStatusIcon } from '../components/ArticleStatusIcon';
import { ButtonPreview } from './ButtonPreview';
import ConflictDialog from './ConflictDialog';
import Form from './Form';
import Push from './Push';
// import Publisher from './Publisher';
import Stats from './Stats';
import { ButtonShare } from 'FM/articles/Detail/ButtonShare';
import { fetchArticleRelations } from 'FM/articles/components/ArticleRelations/actions';

const styles = theme => ({
  titleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  status: {
    marginLeft: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  id: props.match.params.topicId,
  article: store.article.detail[props.match.params.topicId],
  briefs: getCollection('briefsIds', store.article, store.brief),
  image: store.image.detail,
  fetching: store.article.fetching,
}))
export default class Detail extends Component {
  myEmitter = new EventEmitter();

  state = {
    conflict: false,
    lastRevision: null,
    url: '',
    previewParam: Math.random(),
  };

  menu = () => {
    const { article } = this.props;
    const menu = [
      { label: 'app_content', link: '/' },
      // { label: 'app_publication', link: '/publish' },
      {
        label: 'app_push',
        link: '/push',
        hasBeenPushed: article && article.hasBeenPushed,
      },
      { label: 'app_statistics', link: '/stats' },
    ];
    if (getProject().featureFlagsFmV2) {
      menu.push({ label: 'app_articleRelated', link: '/articleRelations' });
    }
    if (article.specificity === 'foot_player') {
      menu.push({ label: 'app_products', link: '/products' });
    }

    if (article.useBrief) {
      menu.push({ label: 'app_briefs', link: '/briefs' });
    }

    return menu;
  };

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchArticle(targetId || id)).then(this.setLastRevision);
    dispatch(fetchArticleRelations(targetId || id));
  };

  setLastRevision = () => {
    const { article, dispatch } = this.props;
    if (!article) return null;
    dispatch(fetchArticleRevision(article.id)).then(({ payload }) => {
      if (payload.list[0]) {
        this.setState({ lastRevision: payload.list[0].id });
      }
    });
  };

  handleUpdateWithoutConflicts = changes => {
    const { dispatch, article, image } = this.props;
    if (changes.content) {
      changes.content = cleanMarkdown(changes.content);
    }

    this.setState({ previewParam: Math.random() });
    // Fetch last revision
    return dispatch(fetchArticleRevision(article.id)).then(async ({ payload }) => {
      const { lastRevision } = this.state;

      // check if image selected has a description
      const { footdataMainImageId } = article;
      if (footdataMainImageId && image && image[footdataMainImageId] && !image[footdataMainImageId].description) {
        return dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            messageKey: 'notification_image_description_must_fill',
            type: 'error',
          },
        });
      }

      // Compare actual last revision and initial/lastUpdate lastRevision
      if (payload.list[0] && lastRevision && payload.list[0].id != lastRevision) {
        const warnDialog = await this.warnConflict(payload.list[0]);
        if (warnDialog == 'reload') {
          this.fetchData();
          return Promise.resolve(false);
        }
        if (warnDialog == 'cancel') {
          return Promise.resolve(false);
        }
      }

      // Else update
      return dispatch(updateArticle(article.id, changes)).then(res => {
        this.setLastRevision();
        return res;
      });
    });
  };

  warnConflict = revision => {
    this.setState({ conflict: true, revision });

    return new Promise(resolve => {
      this.myEmitter.on('closeConflictDialog', resolve);
    });
  };
  handleDialogClose = ans => {
    this.setState({ conflict: false });
    this.myEmitter.emit('closeConflictDialog', ans);
  };

  handleBriefAdd = values => {
    const { article, dispatch } = this.props;
    const brief = {
      ...values,
      article: article['@id'],
    };
    return dispatch(addLiveTransfer(brief, 'article'));
  };

  handleProductAdd = id => {
    const { article } = this.props;
    const footdataProductIds = [...(article.footdataProductIds || []), id];
    this.handleUpdateWithoutConflicts({
      footdataProductIds,
    });
  };
  handleProductRemove = id => {
    const { article } = this.props;
    const footdataProductIds = article.footdataProductIds.filter(productId => productId !== id);
    this.handleUpdateWithoutConflicts({
      footdataProductIds,
    });
  };

  getLabel = () => {
    const {
      article,
      intl: { messages: m },
    } = this.props;
    return article.title || `${m.app_article}: ${article.id}`;
  };

  handleGenerateBriefFromArticle = () => {
    const { dispatch, article, history } = this.props;

    dispatch(briefFromArticle(article.id)).then(({ payload }) => {
      if (payload && payload.id) {
        const urlFM = getFMProject(getProject().lang).url;
        history.push(`${urlFM}/live-transfers/${payload.id}`);
      }
    });
  };

  render() {
    const {
      fetching,
      article,
      briefs,
      match,
      history,
      classes,
      intl: { messages: m },
    } = this.props;

    const { revision, conflict, previewParam } = this.state;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!article) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="article-layout"
        dataTest="ArticleDetail"
        top={
          <TopBar
            crumbStack={[
              {
                labelKey: 'app_articles',
                href: `#${getProject().url}/articles`,
              },
              { label: this.getLabel() },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6" className={classes.titleFlex}>
                <ArticleStatusIcon status={article.status} />
                <div className={classes.status}>{this.getLabel()}</div>
              </Typography>
              <div style={{ flex: 1 }} />
              {article.status === 'publish' && (
                <ButtonShare id={article.id} slug={article.slug} title={article.title} />
              )}
              <ButtonPreview id={article.id} slug={article.slug} previewParam={previewParam} />
              <Button
                variant="contained"
                href={`#${getProject().url}/revisions/${article.id}`}
                className={classes.leftButton}
              >
                <HistoryIcon className={classes.leftIcon} />
                {m.button_history}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGenerateBriefFromArticle}
                style={{ marginRight: 8 }}
              >
                {m.button_generateBriefFromArticle}
              </Button>
              <ReturnButton className={classes.leftButton} entity="articles" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu()} history={history} />
            <Route
              exact
              path={match.url}
              render={() => (
                <div>
                  <RevisionNote
                    style={{ margin: 16 }}
                    createdAt={article.createdAt}
                    updatedAt={article.updatedAt}
                    fromBrief={article.fromBrief}
                  />
                  <Form onSubmit={this.handleUpdateWithoutConflicts} data={article} />
                </div>
              )}
            />
            {/* <Route
              path={`${match.url}/publish`}
              render={() => <Publisher data={article} />}
            /> */}
            <Route path={`${match.url}/push`} render={() => <Push data={article} />} />
            <Route path={`${match.url}/stats`} render={() => <Stats data={article} />} />
            <Route
              path={`${match.url}/articleRelations`}
              render={() => <ArticleRelations articleRelations={article.articleRelations} articleSource={article} />}
            />
            <Route
              path={`${match.url}/products`}
              render={() => (
                <ArticleProducts
                  productsIds={article.footdataProductIds}
                  onAdd={this.handleProductAdd}
                  onRemove={this.handleProductRemove}
                  url={`${match.url}/products`}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.url}/briefs`}
              render={() => (
                <ArticleBriefs
                  briefs={briefs}
                  onAdd={this.handleBriefAdd}
                  url={`${match.url}/briefs`}
                  history={history}
                />
              )}
            />
            <ConflictDialog revision={revision} open={conflict} onClose={this.handleDialogClose} />
          </Paper>
        }
      />
    );
  }
}
