// Copied hoverTooltip from '@uiw/react-codemirror'
// node_modules/@codemirror/view/dist/index.js
// @ts-nocheck

import {
  EditorView,
  MapMode,
  StateEffect,
  StateField,
  Tooltip,
  Transaction,
  ViewPlugin,
  showTooltip,
} from '@uiw/react-codemirror';
import { closeHoverTooltipEffect, showHoverTooltip } from './utils';
import { HoverTooltipHost } from './HoverTooltipHost';
import { ClickHoverPlugin } from './ClickHoverPlugin';
type HoverSource = (
  view: EditorView,
  pos: number,
  side: -1 | 1
) => Tooltip | readonly Tooltip[] | null | Promise<Tooltip | readonly Tooltip[] | null>;

type Option = {
  /**
  Controls whether a transaction hides the tooltip. The default
  is to not hide.
  */
  hideOn?: (tr: Transaction, tooltip: Tooltip) => boolean;
  /**
  When enabled (this defaults to false), close the tooltip
  whenever the document changes or the selection is set.
  */
  hideOnChange?: boolean | 'touch';
  /**
  Hover time after which the tooltip should appear, in
  milliseconds. Defaults to 300ms.
  */
  hoverTime?: number;
};

const showHoverTooltipHost = showTooltip.compute([showHoverTooltip], state => {
  let tooltips = state.facet(showHoverTooltip);

  if (tooltips.length === 0) return null;
  return {
    pos: Math.min(...tooltips.map(t => t.pos)),
    end: Math.max(
      ...tooltips.map(t => {
        var _a;
        return (_a = t.end) !== null && _a !== void 0 ? _a : t.pos;
      })
    ),
    create: HoverTooltipHost.create,
    above: tooltips[0].above,
    arrow: tooltips.some(t => t.arrow),
  };
});

export function clickTooltip(source: HoverSource, options: Option = {}) {
  let setHover = StateEffect.define();
  let hoverState = StateField.define({
    create() {
      return [];
    },
    update(value, tr) {
      if (value.length) {
        if (options.hideOnChange && (tr.docChanged || tr.selection)) {
          value = [];
        } else if (options.hideOn) {
          value = value.filter(v => !options.hideOn(tr, v));
        }
        if (tr.docChanged) {
          let mapped = [];
          for (let tooltip of value) {
            let newPos = tr.changes.mapPos(tooltip.pos, -1, MapMode.TrackDel);
            if (newPos != null) {
              let copy = Object.assign(Object.create(null), tooltip);
              copy.pos = newPos;
              if (copy.end != null) {
                copy.end = tr.changes.mapPos(copy.end);
              }
              mapped.push(copy);
            }
          }
          value = mapped;
        }
      }
      for (let effect of tr.effects) {
        if (effect.is(setHover)) {
          value = effect.value;
        }
        if (effect.is(closeHoverTooltipEffect)) {
          value = [];
        }
      }
      return value;
    },
    provide: f => showHoverTooltip.from(f),
  });
  return [
    hoverState,
    ViewPlugin.define(view => new ClickHoverPlugin(view, source, hoverState, setHover /* Hover.Time */)),
    showHoverTooltipHost,
  ];
}
