import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import debounce from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { getProject } from 'Services/project';
import { searchArticlesAdvanced, orderBy } from './actions';
import filterList from 'Actions/lists';
import qs from 'query-string';

import Layout from '../../common/components/Layout';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { TableList } from '../../common/components/TableList';
import { SearchFilter } from '../../common/components/SearchFilter';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import Row from './Row';

const styles = () => ({
  largeField: {
    width: 400,
  },
});

@injectIntl
@connect((store, props) => ({
  articles: store.searchFM.article,
  fetching: false,
  pagination: store.articles.pagination,
  query: parseQuery(props.location.search),
}))
@withStyles(styles)
export default class SearchAdvanced extends Component {
  config = {
    fields: ['title', 'fuzzy'],
    filters: [
      {
        type: 'title',
        labelKey: 'search_title_intro_description',
        classes: this.props.classes.largeField,
      },
      {
        type: 'fuzzy',
        labelKey: 'filter_fuzzy',
        input: Toggle,
        normalize: normalizeBoolean,
        required: true,
      },
    ],
    headers: [
      { labelKey: 'field_title', size: 'small' },
      { id: 'publishedAt', labelKey: 'field_publishedAt', size: 'small' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location.search !== '') {
      this.fetchData(location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const prevQuery = qs.parse(prevProps.location.search);
    const nextQuery = qs.parse(nextProps.location.search);

    if (nextQuery.title !== prevQuery.title || nextQuery.fuzzy !== prevQuery.fuzzy) {
      return this.fetchData(nextProps.location.search);
    }

    if (
      Object.keys(nextProps.articles).length !== 0 &&
      (Object.keys(prevProps.articles).length === 0 ||
        prevQuery['order[publishedAt]'] !== nextQuery['order[publishedAt]'])
    ) {
      return this.orderData(nextProps.location.search);
    }
  }

  handleFilters = change => this.changeParams({ type: 'SET_FILTER', payload: change });
  handleSort = change => this.changeParams({ type: 'SET_SORT', payload: change });

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);

    location.search !== search && location.search === ''
      ? history.replace(`${getProject().url}/search${search}`)
      : history.push(`${getProject().url}/search${search}`);
  }, 500);

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(searchArticlesAdvanced(filter));
    dispatch(filterList('articles', filter));
  };

  orderData = filter => {
    const { dispatch, articles } = this.props;
    dispatch(orderBy(filter, articles));
    dispatch(filterList('articles', filter));
  };

  render() {
    const {
      articles,
      fetching,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="search-advanced"
        dataTest="SearchAdvanced"
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.field_search}</Typography>
            </Toolbar>
            <SearchFilter
              form="articles_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={articles}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              total={articles.length}
            />
          </Paper>
        }
      />
    );
  }
}
