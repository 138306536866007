// Copied from '@uiw/react-codemirror'
// node_modules/@codemirror/view/dist/index.js
// @ts-nocheck

import { Facet, StateEffect } from '@uiw/react-codemirror';

export const closeHoverTooltipEffect = /*@__PURE__*/ StateEffect.define();
export const showHoverTooltip = /*@__PURE__*/ Facet.define({
  combine: inputs => inputs.reduce((a, i) => a.concat(i), []),
});

function windowSpace(view) {
  let { win } = view;
  return { top: 0, left: 0, bottom: win.innerHeight, right: win.innerWidth };
}

let nav = typeof navigator != 'undefined' ? navigator : { userAgent: '', vendor: '', platform: '' };
let doc = typeof document != 'undefined' ? document : { documentElement: { style: {} } };
const ie_edge = /*@__PURE__*/ /Edge\/(\d+)/.exec(nav.userAgent);
const ie_upto10 = /*@__PURE__*/ /MSIE \d/.test(nav.userAgent);
const ie_11up = /*@__PURE__*/ /Trident\/(?:[7-9]|\d{2,})\..*rv:(\d+)/.exec(nav.userAgent);
const ie = !!(ie_upto10 || ie_11up || ie_edge);
const gecko = !ie && /*@__PURE__*/ /gecko\/(\d+)/i.test(nav.userAgent);
const chrome = !ie && /*@__PURE__*/ /Chrome\/(\d+)/.exec(nav.userAgent);
const webkit = 'webkitFontSmoothing' in doc.documentElement.style;
const safari = !ie && /*@__PURE__*/ /Apple Computer/.test(nav.vendor);
const ios = safari && /*@__PURE__*/ (/Mobile\/\w+/.test(nav.userAgent) || nav.maxTouchPoints > 2);

export var browser = {
  mac: ios || /*@__PURE__*/ /Mac/.test(nav.platform),
  windows: /*@__PURE__*/ /Win/.test(nav.platform),
  linux: /*@__PURE__*/ /Linux|X11/.test(nav.platform),
  ie,
  ie_version: ie_upto10 ? doc.documentMode || 6 : ie_11up ? +ie_11up[1] : ie_edge ? +ie_edge[1] : 0,
  gecko,
  gecko_version: gecko ? +/*@__PURE__*/ (/Firefox\/(\d+)/.exec(nav.userAgent) || [0, 0])[1] : 0,
  chrome: !!chrome,
  chrome_version: chrome ? +chrome[1] : 0,
  ios,
  android: /*@__PURE__*/ /Android\b/.test(nav.userAgent),
  webkit,
  safari,
  webkit_version: webkit ? +/*@__PURE__*/ (/\bAppleWebKit\/(\d+)/.exec(navigator.userAgent) || [0, 0])[1] : 0,
  tabSize: doc.documentElement.style.tabSize != null ? 'tab-size' : '-moz-tab-size',
};
export const tooltipConfig = /*@__PURE__*/ Facet.define({
  combine: values => {
    var _a, _b, _c;
    return {
      position: browser.ios
        ? 'absolute'
        : ((_a = values.find(conf => conf.position)) === null || _a === void 0 ? void 0 : _a.position) || 'fixed',
      parent: ((_b = values.find(conf => conf.parent)) === null || _b === void 0 ? void 0 : _b.parent) || null,
      tooltipSpace:
        ((_c = values.find(conf => conf.tooltipSpace)) === null || _c === void 0 ? void 0 : _c.tooltipSpace) ||
        windowSpace,
    };
  },
});
