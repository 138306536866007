import { fetchMatchCommentaries } from '../commentaries/actions';
import { fetchMatch } from '../matches/actions';
import {
  getLiveComposition,
  getLiveMembershipsAndLastMatches,
} from './Composition/requests';

export const fetchLiveData = (id: string) => dispatch => {
  dispatch({ type: 'SET_LIVE_MATCH', payload: id });

  return dispatch(fetchMatch(id)).then(() => {
    dispatch(fetchMatchCommentaries(id));
    dispatch(getLiveComposition(id));
    dispatch(getLiveMembershipsAndLastMatches(id));
  });
};
