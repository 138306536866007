import rest from 'Services/restATInternet';
import moment from 'moment';

export function getAtInternetStat(quizz) {
  const params = {
    columns: ['eventlabel', 'event_name', 'm_visits'],
    sort: ['-m_visits'],
    filter: {
      property: {
        $AND: [
          {
            eventlabel: {
              $eq: quizz.id,
            },
          },
          {
            event_name: {
              $in: ['visible', 'answer', 'start'],
            },
          },
        ],
      },
    },
    space: {
      /* global process*/
      s: process.env.ATINTERNET_IDS,
    },
    period: {
      p1: [
        {
          type: 'D',
          start: moment(quizz.startDate).format('YYYY-MM-DD'),
          end: moment(new Date()).format('YYYY-MM-DD'),
        },
      ],
    },
    'max-results': 50,
    'page-num': 1,
    options: {
      ignore_null_properties: true,
      eco_mode: true,
    },
  };

  return function() {
    return rest.post('data/getData', params).then(res => res.data, err => err.response);
  };
}
