import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { hasAccessToRestrictedArticles } from "../../../../common/services/me";
import { ArticleStatusIcon } from '../ArticleStatusIcon';
import { IComponentProps } from './SelectArticleStatus.connector';

export class SelectArticleStatus extends React.Component<IComponentProps> {
  public componentDidMount() {
    this.props.fetchConstants('article', 'status');
  }

  public render() {
    const {
      input: { name, value },
      meta,
      intl: { messages: m },
      labelKey,
      statuses,
      isRestricted,
    } = this.props;
    const { touched, error } = meta;

    const label = m[labelKey || `field_${name}`];
    if (!statuses) return null;

    return (
      <FormControl fullWidth error={Boolean(touched && error)}>
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>

        <Select
          name={name}
          value={value}
          onChange={this.onChange}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          {Object.entries(statuses).map(([key, status]) => (
            (key !== 'restricted' || hasAccessToRestrictedArticles()) ?
              (<MenuItem value={key} key={key} disabled={key === 'publish' && isRestricted}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArticleStatusIcon status={key} />
                  <div style={{ marginLeft: 8 }}>{status}</div>
                </div>
              </MenuItem>)
              : null
          ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }

  private onChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => this.props.input.onChange(event.target.value);
}
