import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import BroadcastIcon from '@material-ui/icons/LiveTv';
import moment from 'moment';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import ClubAvatar from '../../clubs/Avatar';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
  imgChannel: {
    maxWidth: 60,
    maxHeight: 30,
    margin: '0 8px 0 0',
  },
};

@withStyles(styles)
export default class Row extends Component {
  renderChannel = channel => {
    if (!channel) return null;
    const { classes } = this.props;
    const { name, logo } = channel;
    return logo ? (
      <img src={logo.originalUrl} className={classes.imgChannel} title={logo.name} />
    ) : (
      name
    );
  };

  renderChannelsCell = (chan1, chan2) => {
    const { classes } = this.props;
    return (
      <div className={classes.rowContent}>
        {this.renderChannel(chan1)}
        {this.renderChannel(chan2)}
      </div>
    );
  };
  renderTeamCell = ({ name, club }) => {
    const { classes } = this.props;
    return (
      <div className={classes.rowContent}>
        <ClubAvatar club={{ logo: club && club.logo }} />
        <div className={classes.label}>{name}</div>
      </div>
    );
  };

  render() {
    const { tableData } = this.props;
    const { live, date, channel, secondaryChannel, match = {} } = tableData;
    const href = `#${getProject().url}/broadcasts/${tableData.slug}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          {`${moment(date).format('L')} - ${moment(date).format('LT')}`}
        </TableCell>
        <TableCell padding="none">
          {live && <BroadcastIcon width="30" height="30" />}
        </TableCell>
        <TableCell>{match.homeTeam && this.renderTeamCell(match.homeTeam)}</TableCell>
        <TableCell>{match.awayTeam && this.renderTeamCell(match.awayTeam)}</TableCell>
        <TableCell>{this.renderChannelsCell(channel, secondaryChannel)}</TableCell>
      </TableRow>
    );
  }
}
