import { SuperSub, WordError } from '../../../../../SuperSub';
import { replaceWord } from '../command/commands';
import { clickTooltip } from './clickTooltip';

const findWordErrorInPos = (cursor: number, wordErrors: WordError[]): WordError | null => {
  let errorHover: WordError | null = null;

  wordErrors.some(error => {
    const { from, to } = error;
    // Vérifier si le curseur est à l'intérieur du mot
    if (from <= cursor && cursor < to) {
      errorHover = error;
      return true;
    }
    return false;
  });

  return errorHover;
};
export const wordHover = (supersub: SuperSub) =>
  clickTooltip((_view, pos) => {
    const wordHovered = findWordErrorInPos(pos, supersub.wordErrors);
    if (!wordHovered) return null;

    return {
      pos: wordHovered.from,
      end: wordHovered.to,
      above: false,
      arrow: true,
      create(view) {
        let dom = document.createElement('div');
        dom.className = 'cm-tooltip-suggestions';
        var header = dom.appendChild(document.createElement('div'));
        header.innerHTML = ` 
          <div class="cm-tooltip-suggestions-type cm-tooltip-suggestions-type-${wordHovered.tag}">${wordHovered.tag}</div>
          <div class="cm-tooltip-suggestions-description">${wordHovered.message}</div>
        `;
        var wrapper = dom.appendChild(document.createElement('div'));
        wrapper.className = 'cm-tooltip-suggestions-wrapper';
        wordHovered.replacements.forEach(replacement => {
          var button = wrapper.appendChild(document.createElement('div'));
          button.className = 'cm-tooltip-suggestions-item';
          button.onclick = () => {
            replaceWord(view, wordHovered.from, wordHovered.to, replacement);
          };
          let replacementLabel = replacement;

          // use ␣ char when suggestion is a space
          if (replacement.trim().length === 0) {
            replacementLabel = replacement.replace(' ', '␣');
          }
          button.innerHTML = `
            <div class="cm-tooltip-suggestions-item-text">${replacementLabel}</div>
          `;
        });
        var ignoreButton = wrapper.appendChild(document.createElement('div'));
        ignoreButton.className = 'cm-tooltip-suggestions-item cm-tooltip-suggestions-ignore';
        ignoreButton.innerHTML = `
          <div class="cm-tooltip-suggestions-item-text cm-tooltip-suggestions-ignore-text">Ignore</div>
        `;
        ignoreButton.onclick = () => {
          supersub.ignoreWord(wordHovered.selection);
          // trigger fake change to update markers
          replaceWord(view, wordHovered.from, wordHovered.to, wordHovered.selection);
        };

        return { dom };
      },
    };
  });
