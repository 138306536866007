import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { renderImg } from '../../images/tools';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

const styles = {
  form: {
    width: 24,
    height: 24,
    margin: '0 4px',
    display: 'inline-block',
    lineHeight: '24px',
    borderRadius: '50%',
    color: 'white',
    fontSize: 12,
    textAlign: 'center',
  },
  W: {
    background: '#65d07b',
  },
  D: {
    background: '#d3d3d3',
  },
  L: {
    background: '#f55959',
  },
  displex: {
    display: 'flex',
  },
  flex: {
    flex: '1',
  },
  pad: {
    padding: 16,
  },
  scores: {
    display: 'flex',
    background: '#dadada',
    maxWidth: 500,
    margin: 'auto',
    marginBottom: 10,
    borderRadius: 5,
    padding: '5px 10px',
  },
  oldScore: {
    flex: 1,
    margin: '0 16px',
  },
  right: {
    textAlign: 'right',
  },
};

@injectIntl
@withStyles(styles)
export default class Preview extends Component {
  renderCurrentForm = cf => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <div className={classes.pad}>
        {cf.split('').map(f => (
          <span className={`${classes.form} ${classes[f]}`}>{m[`abr_${f}`]}</span>
        ))}
      </div>
    );
  };

  renderForm = (home, away) => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <div className={classes.displex}>
          <div className={`${classes.pad} ${classes.flex} ${classes.right}`}>
            {this.renderCurrentForm(home.currentForm)}
          </div>
          <div className={classes.pad}>
            <Typography variant="headline" align="center">
              {m.match_form}
            </Typography>
          </div>
          <div className={`${classes.pad} ${classes.flex}`}>
            {this.renderCurrentForm(away.currentForm)}
          </div>
        </div>
        <div className={classes.displex}>
          {this.renderMatchesList(home.lastMatchs)}
          {this.renderMatchesList(away.lastMatchs)}
        </div>
      </div>
    );
  };
  renderMatchesList = lm => {
    const { classes } = this.props;
    return <div className={classes.flex}>{lm.map(this.renderMatchItem)}</div>;
  };

  renderMatchItem = match => {
    const { classes } = this.props;

    return (
      <ButtonBase
        href={`#${getProject().url}/matches/${match.id}`}
        className={classes.scores}
      >
        <div className={`${classes.oldScore} ${classes.right}`}>
          {match.homeTeam.name}
        </div>
        <div>
          {match.scores.home} - {match.scores.away}
        </div>
        <div className={classes.oldScore}>{match.awayTeam.name}</div>
      </ButtonBase>
    );
  };

  renderPreviousMeeting = previousMeetings => {
    const {
      classes,
      awayLogo,
      homeLogo,
      intl: { messages: m },
    } = this.props;

    return (
      <div>
        <div className={classes.displex}>
          <div className={[classes.pad, classes.displex, classes.flex].join(' ')}>
            {renderImg(homeLogo, 150)}
            <div className={classes.flex} />
            <Typography variant="display4">{previousMeetings.homeWins}</Typography>
          </div>
          <div className={classes.pad}>
            <Typography variant="headline" align="center">
              {m.match_historyOfVictories}
            </Typography>
            <Typography variant="h6" align="center">
              {previousMeetings.draws} {m.match_drawns}
            </Typography>
          </div>
          <div className={[classes.pad, classes.displex, classes.flex].join(' ')}>
            <Typography variant="display4">{previousMeetings.awayWins}</Typography>
            <div className={classes.flex} />
            {renderImg(awayLogo, 150)}
          </div>
        </div>
        <div className={classes.flex}>
          {previousMeetings.matchs.map(this.renderMatchItem)}
        </div>
      </div>
    );
  };

  render() {
    const { preview, classes } = this.props;
    if (!preview[0] || !preview[1]) return null;
    return (
      <div>
        <div className={classes.pad}>{this.renderPreviousMeeting(preview[2])}</div>
        <div>{this.renderForm(preview[0], preview[1])}</div>
      </div>
    );
  }
}
