import { IProduct, IProductCategory, IProductType } from './typings';

export const groupProductsBySeason = (products: IProduct[]) => {
  const productsBySeason = {
    other: [],
  };
  for (const product of products) {
    const group = product.season ? product.season.name : 'other';
    if (!productsBySeason[group]) {
      productsBySeason[group] = [];
    }
    productsBySeason[group].push(product);
  }
  return productsBySeason;
};

const PRODUCT_TYPE_PRIORITY: IProductType[] = [
  'home',
  'away',
  'third',
  'event',
  'goalkeeperhome',
  'goalkeeperaway',
  'goalkeeperthird',
  'training',
  'other',
];

const PRODUCT_CATEGORY_PRIORITY: IProductCategory[] = ['shirt', 'shoes', 'balls'];

const sortByType = (a: IProduct, b: IProduct) =>
  a.category !== b.category
    ? PRODUCT_CATEGORY_PRIORITY.indexOf(a.category) -
      PRODUCT_CATEGORY_PRIORITY.indexOf(b.category)
    : PRODUCT_TYPE_PRIORITY.indexOf(a.type) - PRODUCT_TYPE_PRIORITY.indexOf(b.type);

export const sortProductsByType = (products: IProduct[]) => products.sort(sortByType);

export const getProductLabel = (product: IProduct) =>
  product.name ||
  [
    product.categoryTranslated,
    product.typeTranslated,
    product.season && product.season.name,
  ]
    .filter(Boolean)
    .join(' ');
