export const styles = {
  card: {
    maxWidth: 400,
    height: 136,
    margin: 16,
    display: 'flex',
    position: 'relative' as 'relative',
  },
  progress: {
    width: '100%',
    position: 'absolute' as 'absolute',
  },
  cover: {
    width: 120,
  },
  content: {
    paddingRight: 0,
    flex: 1,
  },
  icon: { marginLeft: 8, width: 18 },
};
