import rest from 'Services/restFootData';

export default function fetchAllSeasons() {
  const params = {
    pagination: 0,
  };

  return function(dispatch, getState) {
    const seasons = getState().seasons;
    if (seasons.fetched) {
      return dispatch({ type: 'CACHED_SEASONS' });
    } else {
      dispatch({ type: 'FETCH_SEASONS' });

      // cached data
      return rest
        .get(`seasons`, { params })
        .then(
          res => dispatch({ type: 'FETCH_SEASONS_FULFILLED', payload: res.data }),
          err => dispatch({ type: 'FETCH_SEASONS_REJECTED', payload: err.response })
        );
    }
  };
}
