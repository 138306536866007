import rest from "../../../../common/services/restFootMercato";


export function fetchArticleRelations(id) {
    const params = {
        articleSource: id,
    }
    return function(dispatch) {
        dispatch({ type: 'FETCH_ARTICLE_RELATION' });

        return rest
            .get(`articles_relations`, { params })
            .then(
                res => dispatch({ type: 'FETCH_ARTICLE_RELATION_FULFILLED', payload: { id, list: res.data } }),
                err => dispatch({ type: 'FETCH_ARTICLE_RELATION_REJECTED', payload: err.response })
            );
    };
}