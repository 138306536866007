import rest from 'Services/restFootData';

export default function fetchConstants(entity, field) {
  return function(dispatch, getState) {
    // cached data
    const constantEntity = getState().constants[entity];
    const constant = constantEntity && constantEntity[field];

    if (constant && constant.fetching) {
      return;
    } else if (constant && constant.fetched) {
      return Promise.resolve({ payload: constant });
    } else {
      dispatch({ type: 'FETCH_CONSTANTS', payload: { entity, field } });
      return rest.get(`constants/${entity}/${field}`).then(
        res =>
          dispatch({
            type: 'FETCH_CONSTANTS_FULFILLED',
            payload: { entity, field, list: res.data },
          }),
        err =>
          dispatch({
            type: 'FETCH_CONSTANTS_REJECTED',
            payload: err.response,
          })
      );
    }
  };
}
