import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  field: {
    zIndex: '1',
    margin: 'auto',
    width: 286.2,
    height: 509.2,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
  },
  background: {
    zIndex: '-1',
    position: 'absolute',
    backgroundImage:
      'radial-gradient(circle at 50% 50%,rgba(180,237,80,.78),rgba(66,147,33,.78));background-repeat:no-repeat',
  },
  player: {
    textAlign: 'center',
    fontSize: '9px',
    lineHeight: '12px',
    flex: '1',
    textTransform: 'uppercase',
    fontWeight: '700',
  },
  number: {
    color: '#618446',
  },
  name: {
    color: '#FFF',
    paddingLeft: 2,
    textShadow: '0 0 4px rgba(0,0,0,.28)',
  },
  line: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  homeSide: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
  awaySide: {
    display: 'flex',
    flexDirection: 'column-reverse',
    flex: '1',
  },
  st0: {
    fill: '#fff',
  },
  st1: {
    fill: '#fff',
    opacity: '.3',
  },
});

@withStyles(styles)
export default class Field extends Component {
  renderPlayerFormation = player => (
    <div className={this.props.classes.player}>
      <span className={this.props.classes.number}>
        {player.membership.shirtNumber}
      </span>
      <span className={this.props.classes.name}>
        {player.membership.personMatchName}
      </span>
    </div>
  );

  renderLineFormation = line => (
    <div className={this.props.classes.line}>
      {Object.values(line).map(this.renderPlayerFormation)}
    </div>
  );

  renderHomeFormation = mtp => (
    <div className={this.props.classes.homeSide}>
      {Object.values(mtp).map(this.renderLineFormation)}
    </div>
  );
  renderAwayFormation = mtp => (
    <div className={this.props.classes.awaySide}>
      {Object.values(mtp).map(this.renderLineFormation)}
    </div>
  );

  render() {
    const { classes, formations } = this.props;
    return (
      <div className={classes.field}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Calque_1"
          width="286.2"
          height="509.2"
          viewBox="0 0 286.2 509.2"
          className={classes.background}
        >
          <path
            className={classes.st0}
            d="M286.2 509.2H0V0h286.2v509.2zM5 504.8h276.2V4.5H5v500.3z"
          />
          <path
            className={classes.st1}
            d="M283.5 252.4h-99.8c-1.2-22.1-19.5-39.6-41.8-39.6-22.3 0-40.6 17.6-41.8 39.6H2.8v5h97.3c1.4 21.8 19.6 39.1 41.8 39.1 22.1 0 40.3-17.3 41.8-39.1h99.8v-5zm-141.6-34.6c19.6 0 35.6 15.3 36.8 34.6h-73.6c1.2-19.3 17.2-34.6 36.8-34.6zm0 73.7c-19.4 0-35.3-15.1-36.7-34.1h73.5c-1.5 19-17.4 34.1-36.8 34.1z"
          />
          <path
            className={classes.st0}
            d="M283.8 20.6c-10 0-18.1-8.1-18.1-18.1h5c0 7.2 5.9 13.1 13.1 13.1v5zM2.5 20.6v-5c7.2 0 13.1-5.9 13.1-13.1h5c0 10-8.2 18.1-18.1 18.1zM270.7 506.5h-5c0-10 8.1-18.1 18.1-18.1v5c-7.3 0-13.1 5.9-13.1 13.1zM20.6 506.5h-5c0-7.2-5.9-13.1-13.1-13.1v-5c9.9 0 18.1 8.2 18.1 18.1z"
          />
          <path
            className={classes.st1}
            d="M143.1 80.3c-11.2 0-21.5-5.5-27.7-14.8H77.1V0h132v65.5h-38.3c-6.2 9.3-16.4 14.8-27.7 14.8zm-21.4-14.8c5.3 6.2 13.1 9.8 21.5 9.8s16.1-3.6 21.5-9.8h-43zm-39.6-5h122V5h-122v55.5z"
          />
          <path
            className={classes.st1}
            d="M179.7 37.2h-73.2V0h73.2v37.2zm-68.2-5h63.2V5h-63.2v27.2zM179.7 510.2h-73.2V473h73.2v37.2zm-68.2-5h63.2V478h-63.2v27.2z"
          />
          <path
            className={classes.st1}
            d="M209.1 509.2h-132v-65.5h38.2c6.2-9.4 16.5-15 27.9-15s21.6 5.6 27.9 15h38.2v65.5zm-127-5h122v-55.5h-122v55.5zm39.6-60.6h42.9c-5.3-6.2-13.1-9.8-21.5-9.8s-16.1 3.6-21.4 9.8z"
          />
        </svg>
        {this.renderHomeFormation(formations.home.positions, 'home')}
        {this.renderAwayFormation(formations.away.positions, 'away')}
      </div>
    );
  }
}
