import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  card: {
    width: 220,
    marginTop: 32,
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[200],
    lineHeight: '31px',
  },
});
