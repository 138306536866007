import { Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) => ({
  container: {
    marginLeft: 4,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  chronoContainer: {
    background: '#001f4e',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    borderRadius: 18,
    padding: '4px 8px',
  },
  chronoEditingContainer: {
    background: theme.palette.primary[500],
  },
  chronoEditContainer: {
    display: 'flex',
    color: theme.palette.primary[500],
    alignItems: 'center',
    borderRadius: 18,
    padding: '4px 8px',
  },
  text: {
    color: 'inherit',
    fontSize: 19,
    fontWeight: 'bold' as 'bold',
    minWidth: 60,
    textAlign: 'center' as 'center',
  },
  textEditing: {
    background: 'rgba(255, 255, 255, 0.2)',
  },
  iconButton: {
    padding: 0,
    width: 24,
    height: 24,
  },
  iconSmallButton: {
    padding: 0,
    width: 20,
    height: 20,
  },
  icon: {
    fontSize: 16,
  },
  bigIcon: {
    fontSize: 20,
  },
});
