import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchPartnerOffers } from "../../actions";
import { IRootState } from "../../../../typings";
import { IInput } from "../../../components/InputCollection/InputCollection.component";

export interface IPartnerOfferOption {
    id: string;
    label: string;
    value: string;

}

interface IOwnProps {
    input: IInput;
    selectedPartnerOfferOption: IPartnerOfferOption | null;
    partnerOffers: any;
}

const mapDispatchToProps = {
    fetchPartnerOffers,
};

const mapStateToProps = (state: IRootState) => ({
    partnerOffers: state.partnerOfferList,
})

export type IComponentProps = typeof mapDispatchToProps & InjectedIntlProps & IOwnProps;

const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
    reduxConnector,
    injectIntl
);
