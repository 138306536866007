import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import * as React from 'react';
import { AdvancedBloc } from '../AdvancedBloc';
import { IComponentProps } from './AwardMatch.connector';
import {getProject} from "../../../../../common/services/project";

interface IState {
  awayScore: number;
  homeScore: number;
  winner: 'home' | 'away';
  isRequesting: boolean;
}

export class AwardMatch extends React.Component<IComponentProps, IState> {
  public state: IState = {
    awayScore: 0,
    homeScore: 0,
    winner: 'home',
    isRequesting: false,
  };

  public render() {
    const {
      classes,
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;
    const { awayScore, homeScore, winner, isRequesting } = this.state;

    return (
      <AdvancedBloc
        title={m.live_advanced_awardTitle}
        description={m.live_advanced_awardDescription}
      >
        <FormControl className={classes.input} disabled={mainCommentatorLocale != getProject().lang}>
          <InputLabel htmlFor="winner">{m.field_winner}</InputLabel>
          <Select
            value={winner}
            onChange={this.onChangeWinner}
            input={<Input name="winner" id="winner" />}
          >
            <MenuItem value="home">{m.match_homeTeam}</MenuItem>
            <MenuItem value="away">{m.match_awayTeam}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="number"
          name="homeScore"
          label={m.field_scoreHome}
          className={classes.input}
          value={homeScore}
          onChange={this.onChangeHomeScore}
          inputProps={{
            min: '0',
          }}
          disabled={mainCommentatorLocale != getProject().lang}
        />
        <TextField
          type="number"
          name="awayScore"
          label={m.field_scoreAway}
          className={classes.input}
          value={awayScore}
          onChange={this.onChangeAwayScore}
          inputProps={{
            min: '0',
          }}
          disabled={mainCommentatorLocale != getProject().lang}
        />
        <Button color="primary" onClick={this.handleClick} disabled={isRequesting || mainCommentatorLocale != getProject().lang}>
          {m.button_awardMatch}
        </Button>
      </AdvancedBloc>
    );
  }

  private onChangeAwayScore = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ awayScore: Number(ev.target.value) });
  };

  private onChangeHomeScore = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ homeScore: Number(ev.target.value) });
  };

  private onChangeWinner = (
    ev: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    this.setState({ winner: ev.target.value as 'home' | 'away' });
  };

  private handleClick = () => {
    const { awardMatch } = this.props;
    const { awayScore, homeScore, winner } = this.state;
    this.setState({ isRequesting: true });
    // @ts-ignore dispatch type error
    awardMatch({ awayScore, homeScore, winner }).then(() =>
      this.setState({ isRequesting: false })
    );
  };
}
