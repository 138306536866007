import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { getLinkId } from '../../../common/services/tools';
import { getMatchId } from '../actions';
import { getMatchLabel } from '../utils';

// @ts-ignore
@connect((store: any, props: any) => ({
  match: store.match.detail[getLinkId(props.children)],
}))
export class MatchCollectionChip extends React.Component<any> {
  public componentDidMount() {
    const { dispatch, children } = this.props;
    return dispatch(getMatchId(getLinkId(children)));
  }
  public render() {
    const { children, removeProps, match } = this.props;
    const label = match ? getMatchLabel(match) : children;
    const deleteIcon = match ? <CancelIcon {...removeProps} /> : <CircularProgress size={24} />;

    return (
      <Chip
        tabIndex={-1}
        label={`${label} ${match && match.date ? '(' + moment(match.date).format('L') + ')' : null}`}
        onDelete={removeProps.onClick}
        deleteIcon={deleteIcon}
      />
    );
  }
}
