import { withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { IArticle } from '../../../articles/typings';
import { updateBrief } from '../../liveTransfers/actions';
import { styles } from './styles';
import { connect } from 'react-redux';

interface IOwnProps {
  brief: IArticle;
  isExpanded: boolean;
  onExpend: (id: string, expanded: boolean) => void;
}

const mapDispatchToProps = {
  updateBrief,
};
const mapStateToProps = () => ({});
export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
