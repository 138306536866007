export const notifySuccessAction = (messageKey: string) => ({
  type: 'ADD_NOTIFICATION',
  payload: { messageKey, type: 'success' },
});

export const notifyWarnAction = (messageKey: string) => ({
  type: 'ADD_NOTIFICATION',
  payload: { messageKey, type: 'warn' },
});

export const notifyErrorAction = (messageKey: string) => ({
  type: 'ADD_NOTIFICATION',
  payload: { messageKey, type: 'error' },
});
