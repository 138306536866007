import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { selectMatchCommentariesEvents } from '../../../../matches/selector';
import { ISide } from '../../../../matches/typings';
import { setPlayer2Live, setPlayerCard, setPlayerLive } from '../../actions';
import { styles } from './styles';

const mapDispatchToProps = {
  setPlayerLive,
  setPlayer2Live,
  setPlayerCard,
};

interface IOwnProps {
  team: any;
  side: ISide;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const teamActionSide = state.live.commentary.teamActionSide;

  return {
    matchEvents: selectMatchCommentariesEvents(state, state.live.matchId),
    isOpponentSide: !!teamActionSide && ownProps.side !== teamActionSide,
    type: state.live.commentary.type,
    player: state.live.commentary.player,
    player2: state.live.commentary.player2,
    strictMode: state.live.strictMode,
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps &
  WithStyles &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl,
  withStyles(styles)
);
