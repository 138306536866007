import React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';

function Integer({
  input: { name, value, onChange, ...inputEvents },
  labelKey,
  intl: { formatMessage: f },
  meta: { touched, error } = {},
  hintText,
  startAdornment,
  endAdornment,
  ...custom
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
  return (
    <TextField
      label={label}
      error={Boolean(touched && error)}
      helperText={touched && error}
      type="number"
      InputProps={{
        placeholder: hintText || label,
        startAdornment,
        endAdornment,
      }}
      name={name}
      value={value}
      {...inputEvents}
      onChange={event => onChange(event.target.value)}
      {...custom}
      fullWidth
    />
  );
}
export default injectIntl(Integer);

export const normalizeInt = val => parseInt(val);
