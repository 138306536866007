import rest from 'Services/restFootMercato';
import { SubmissionError } from "redux-form";

export function deleteArticleRelation(id) {
    return function(dispatch) {
        dispatch({ type: 'DELETE_ARTICLE_RELATION', payload: id });

        return rest.delete(`articles_relations/${id}`).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { message: 'Article relationship deleted', type: 'success' },
                });
                return dispatch({ type: 'DELETE_ARTICLE_RELATION_FULFILLED', payload: res.data });
            },
            err => {
                if (err.response && err.response.data.type === 'ConstraintViolationList') {
                    const { errors } = err.response.data;
                    throw new SubmissionError(errors);
                }
                return dispatch({
                    type: 'DELETE_ARTICLE_RELATION_REJECTED',
                    payload: err.response,
                });
            }
        );
    }
}

export function updateArticleRelation(articleRelation) {
    articleRelation.articleLinked = articleRelation.articleLinked['@id'];
    articleRelation.articleSource = articleRelation.articleSource['@id'];

    return function(dispatch) {
        dispatch({ type: 'UPDATE_ARTICLE_RELATION', payload: articleRelation });

        return rest.put(`articles_relations/${articleRelation.id}`, articleRelation).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { message: 'Article relationship modified', type: 'success' },
                });
                return dispatch({ type: 'UPDATE_ARTICLE_RELATION_FULFILLED', payload: res.data });
            },
            err => {
                if (err.response && err.response.data.type === 'ConstraintViolationList') {
                    const { description, errors } = err.response.data;
                    dispatch({
                        type: 'ADD_NOTIFICATION',
                        payload: { message: description, type: 'error' },
                    });
                    throw new SubmissionError(errors);
                }
                return dispatch({ type: 'UPDATE_ARTICLE_RELATION_REJECTED', payload: err.response });
            }
        );
    }

}

export function addArticleRelation(data) {
    return function(dispatch) {
        dispatch({ type: 'ADD_ARTICLE_RELATION', payload: data });

        return rest.post(`articles_relations`, data).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { message: 'Article relationship created', type: 'success' },
                });
                return dispatch({ type: 'ADD_ARTICLE_RELATION_FULFILLED', payload: res.data });
            },
            err => {
                if (err.response && err.response.data.type === 'ConstraintViolationList') {
                    const { description, errors } = err.response.data;
                    dispatch({
                        type: 'ADD_NOTIFICATION',
                        payload: { message: description, type: 'error' },
                    });
                    throw new SubmissionError(errors);
                }
                return dispatch({ type: 'ADD_ARTICLE_RELATION_REJECTED', payload: err.response });
            }
        );
    }

}
