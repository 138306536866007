import qs from 'query-string';
import rest from 'Services/restFootData';

export function fetchPeople(search) {
  const params = qs.parse(search);

  return function(dispatch) {
    dispatch({ type: 'FETCH_PEOPLE' });

    return rest
      .get('people', { params })
      .then(
        res => dispatch({ type: 'FETCH_PEOPLE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PEOPLE_REJECTED', payload: err.response })
      );
  };
}

export function getPersonId(id) {
  return function(dispatch) {
    dispatch({ type: 'GET_PERSON' });

    return rest
      .get(`people/${id}`)
      .then(
        res => dispatch({ type: 'GET_PERSON_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_PERSON_REJECTED', payload: err.response })
      );
  };
}

export function fetchPersonLink(id) {
  const params = {
    groups: ['read_link'],
  };

  return function(dispatch, getState) {
    const link = getState().person.link[id];
    if (link) return Promise.resolve({ payload: link });

    dispatch({ type: 'FETCH_PERSON_LINK', payload: { id } });
    return rest.get(`people/${id}`, { params }).then(
      res => dispatch({ type: 'FETCH_PERSON_LINK_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_PERSON_LINK_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function searchPeople(search) {
  const params = {
    targets: ['Person'],
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_PEOPLE', payload: { search } });
    return rest
      .get(`search/${search}`, { params })
      .then(
        res => dispatch({ type: 'SEARCH_PEOPLE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_PEOPLE_REJECTED', payload: err.response })
      );
  };
}
