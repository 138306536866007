import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

@injectIntl
export default class AuthorArticles extends Component {
  render() {
    const {
      articles = [],
      authorId,
      totalItems,
      intl: { messages: m },
    } = this.props;
    return (
      <List
        subheader={
          <ListSubheader>{`${m.app_articles} (${totalItems || 0}`})</ListSubheader>
        }
        dense
      >
        {articles.map(article => (
          <ListItem
            button
            component="a"
            href={`#${getProject().url}/articles/${article.id}`}
          >
            <ListItemText primary={article.title} />
          </ListItem>
        ))}
        {articles.length == 0 && (
          <ListItem>
            <ListItemText primary={m.error_noData} />
          </ListItem>
        )}
        {articles.length == 10 && (
          <ListItem
            button
            component="a"
            href={`#${getProject().url}/articles?authors=${authorId}`}
          >
            <ListItemText primary={m.button_seeAll} />
          </ListItem>
        )}
      </List>
    );
  }
}
