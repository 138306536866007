export default function reducer(
  state = {
    club: {},
    competition: {},
    person: {},
    team: {},
  },
  action
) {
  switch (action.type) {
    case 'FETCH_REVISIONS': {
      const { entity, id } = action.payload;
      const updateEntity = {
        ...state[entity],
        [id]: {
          fetching: true,
        },
      };
      return {
        ...state,
        [entity]: updateEntity,
      };
    }

    case 'FETCH_REVISIONS_FULFILLED': {
      const { entity, id, list } = action.payload;
      const updateEntity = {
        ...state[entity],
        [id]: {
          list,
          fetched: true,
          fetching: false,
        },
      };
      return {
        ...state,
        [entity]: updateEntity,
      };
    }

    default:
      return state;
  }
}
