import { Typography } from '@material-ui/core';
import React from 'react';
import { IQuizz } from '../typings';

interface IProps {
  quizz: IQuizz;
  intl: any;
  fetchAnalyticStat: any;
}

export class QuizzStat extends React.Component<IProps> {
  state = {};

  public componentDidMount() {
    const { quizz, fetchAnalyticStat } = this.props;
    fetchAnalyticStat(quizz).then(payload => {
      this.setState({ analyticsData: payload });
    });
  }

  public render() {
    const {
      quizz,
      intl: { messages: m },
    } = this.props;

    // @ts-ignore
    return (
      <div>
        <div style={{ padding: 16 }}>
          <Typography variant="h6">{m.app_statistics}</Typography>
          <ul>
            <li>
              {m.survey_totalParticipantsCount}: {quizz.totalParticipantsCount || '-'}
            </li>
            <li>
              {m.survey_totalParticipantsBySource}: {this.renderCountBySource()}
            </li>
            <li>
            </li>
          </ul>

          {this.renderAnalyticsData()}
        </div>
      </div>
    );
  }

  public getPercentageByOption(option) {
    const { quizz } = this.props;
    // @ts-ignore
    if (quizz.totalParticipantsCount > 0) {
      // @ts-ignore
      return Math.ceil((option.vote * 100) / survey.totalParticipantsCount);
    }

    return 0;
  }

  public renderCountBySource() {
    const { quizz } = this.props;
    if (!quizz.participantsCountBySource || quizz.participantsCountBySource.length === 0) {
      return '-';
    }

    return (
      <ul>
        {quizz.participantsCountBySource &&
            quizz.participantsCountBySource.length > 0 &&
            quizz.participantsCountBySource.map((countBySource, index) => (
            <li key={index}>
              {countBySource.source}: {countBySource.count}
            </li>
          ))}
      </ul>
    );
  }

  public renderAnalyticsData() {
    // @ts-ignore
    const { analyticsData } = this.state;
    const {
      intl: { messages: m },
    } = this.props;
    if (!analyticsData || !analyticsData.DataFeed || !analyticsData.DataFeed.Rows) {
      return '';
    }

    return (
      <div>
        <Typography variant="h6">{m.analytics_stats}</Typography>
        <ul>
          <li>
            {m.analytics_totalViews}: {this.getAnalyticsVisiteByEventName('visible')}
          </li>
          <li>
            {m.analytics_totalAnswersCreated}: {this.getAnalyticsVisiteByEventName('answer')}
          </li>
          <li>
            {m.analytics_totalAffiliationClicks}: {this.getAnalyticsVisiteByEventName('clickaffiliation')}
          </li>
        </ul>
      </div>
    );
  }

  public getAnalyticsVisiteByEventName(eventName) {
    // @ts-ignore
    const { analyticsData } = this.state;
    const row = analyticsData.DataFeed.Rows.find(x => x.event_name === eventName);
    if (!row) {
      return '-';
    }

    return row.m_visits;
  }
}
