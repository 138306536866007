import qs from 'query-string';
import rest from 'Services/restFootData';

const memoClubs = new Map();
const memoPlayers = new Map();

export function fetchStatTypeOnce() {
  const params = { pagination: 0 };
  return function(dispatch, getState) {
    dispatch({ type: 'GET_MATCHSTATTYPES' });

    const storedStatTypes = getState().stats.types;
    if (storedStatTypes) {
      return dispatch({ type: 'FETCH_STATSTYPES_ALREADY_FULFILLED' });
    }

    return rest
      .get(`tournament_stat_types`, { params })
      .then(
        res => dispatch({ type: 'GET_STATSTYPES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_STATSTYPES_REJECTED', payload: err.response })
      );
  };
}

export function fetchClubsStats(search) {
  const params = {
    ...search,
  };

  return function(dispatch, getState) {
    dispatch({
      type: 'GET_CLUBSSTATS',
      payload: { type: params['type.code'] },
    });

    const memo = memoClubs.get(qs.stringify(search));
    if (memo) {
      return dispatch({
        type: 'GET_CLUBSSTATS_FULFILLED',
        payload: { type: params['type.code'], collection: memo },
      });
    }

    const notCombinable = getState().stats.notCombinable;

    if (isCombiningNotCombinable(params, notCombinable)) {
      memoClubs.set(qs.stringify(search), []);
      return dispatch({
        type: 'GET_CLUBSSTATS_NOTCOMBINABLE',
        payload: { type: params['type.code'] },
      });
    }

    return rest.get(`tournament_stat_teams`, { params }).then(
      res => {
        memoClubs.set(qs.stringify(search), res.data);
        return dispatch({
          type: 'GET_CLUBSSTATS_FULFILLED',
          payload: { type: params['type.code'], collection: res.data },
        });
      },
      err => dispatch({ type: 'GET_CLUBSSTATS_REJECTED', payload: err.response })
    );
  };
}

export function fetchPlayersStats(search) {
  const params = {
    ...search,
  };

  return function(dispatch, getState) {
    dispatch({
      type: 'GET_PLAYERSSTATS',
      payload: { type: params['type.code'] },
    });

    const memo = memoPlayers.get(qs.stringify(search));
    if (memo) {
      return dispatch({
        type: 'GET_PLAYERSSTATS_FULFILLED',
        payload: { type: params['type.code'], collection: memo },
      });
    }

    const notCombinable = getState().stats.notCombinable;
    if (isCombiningNotCombinable(params, notCombinable)) {
      memoClubs.set(qs.stringify(search), []);
      return dispatch({
        type: 'GET_PLAYERSSTATS_NOTCOMBINABLE',
        payload: { type: params['type.code'] },
      });
    }

    return rest.get(`tournament_stat_players`, { params }).then(
      res => {
        memoPlayers.set(qs.stringify(search), res.data);
        return dispatch({
          type: 'GET_PLAYERSSTATS_FULFILLED',
          payload: { type: params['type.code'], collection: res.data },
        });
      },
      err => dispatch({ type: 'GET_PLAYERSSTATS_REJECTED', payload: err.response })
    );
  };
}

export function clearMemo() {
  memoClubs.clear();
  memoPlayers.clear();
  return { type: 'CLEAR_STATS_FULFILLED' };
}

function isCombiningNotCombinable(params, notCombinable) {
  return (
    notCombinable.includes(params['type.code']) && (!params.season || !params.competition)
  );
}
