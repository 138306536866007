import React, { Component } from 'react';
import { getProject } from 'Services/project';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import MatchField from './Field';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  position: {},
  group: {
    marginBottom: 24,
    '& $position:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  player: {
    display: 'flex',
    fontSize: 12,
    margin: '4px 0',
    color: 'grey',
  },
  number: {
    width: 18,
    fontWeight: '600',
    color: 'black',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    fontWeight: '500',
    margin: '0 4px',
    flex: 1,
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary[500],
    },
  },
});

@injectIntl
@withStyles(styles)
export default class Teams extends Component {
  renderMatchTeamPlayer = mtp => (
    <div className={this.props.classes.player}>
      <span className={this.props.classes.number}>
        {mtp.membership.shirtNumber || '-'}.
      </span>
      <a
        href={`#${getProject().url}/players/${mtp.membership.personSlug}`}
        className={this.props.classes.link}
      >
        {mtp.membership.personMatchName}
      </a>
    </div>
  );

  renderTeamPlayers = (mtp, formation) => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;

    if (!mtp || !mtp.length) {
      return false;
    }
    return (
      <div className={classes.group}>
        <Typography variant="h6" gutterBottom>
          {m.match_team} {formation && formation.name && `(${formation.name})`}
        </Typography>
        {mtp &&
          Object.values(mtp).map(p => (
            <div className={classes.position}>
              {Object.values(p).map(this.renderMatchTeamPlayer)}
            </div>
          ))}
      </div>
    );
  };
  renderBenchPlayers = mtp => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <div className={classes.group}>
        <Typography variant="h6" gutterBottom>
          {m.match_substitutes}
        </Typography>
        {mtp && Object.values(mtp).map(this.renderMatchTeamPlayer)}
      </div>
    );
  };
  renderCoach = coach => {
    const {
      classes,
      intl: { messages: m },
    } = this.props;

    if (!coach) {
      return false;
    }

    return (
      <div className={classes.group}>
        <Typography variant="h6" gutterBottom>
          {m.match_coach}
        </Typography>
        <div className={this.props.classes.player}>
          <a
            href={`#${getProject().url}/coaches/${coach.personSlug}`}
            className={this.props.classes.link}
          >
            {coach.personMatchName}
          </a>
        </div>
      </div>
    );
  };

  renderMatchTeam = ({ positions, substitutes }, { formation, coach }) => (
    <div>
      {this.renderTeamPlayers(positions, formation)}
      {this.renderBenchPlayers(substitutes)}
      {this.renderCoach(coach)}
    </div>
  );

  render() {
    const { formations, match } = this.props;

    if (
      !formations ||
      !formations.home ||
      !formations.home.positions ||
      !formations.home.positions.length
    ) {
      return false;
    }

    return (
      <Grid container spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {this.renderMatchTeam(formations.home, match.matchTeams.home)}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <MatchField formations={formations} />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          {this.renderMatchTeam(formations.away, match.matchTeams.away)}
        </Grid>
      </Grid>
    );
  }
}
