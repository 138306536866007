import { EPosition, ITeamComposition } from '../../compositions/typings';
import { IMatchTeamPlayer } from '../../matchTeamPlayers/typings';
import { createMatchTeamPlayerFromMembership } from '../../matchTeamPlayers/utils';
import { ILiveCompositionActions } from './actions';
import { ICompositionState } from './typings';

const emptyComposition: ITeamComposition = {
  positions: {},
  substitutes: [],
};

const initialState: ICompositionState = {
  composition: {
    home: emptyComposition,
    away: emptyComposition,
  },
  lastMatches: { home: [], away: [] },
  selectedMatchTeamPlayer: { home: null, away: null },
  clubMatchTeamPlayers: { home: [], away: [] },
  teamSeasonMatchTeamPlayers: { home: [], away: [] },
};

export const liveCompositionReducer = (
  state: ICompositionState = initialState,
  action: ILiveCompositionActions
) => {
  switch (action.type) {
    case 'LIVE_SET_COMPOSITION': {
      return {
        ...state,
        composition: {
          home: action.payload.home ? action.payload.home : emptyComposition,
          away: action.payload.away ? action.payload.away : emptyComposition,
        },
      };
    }
    case 'LIVE_IMPORT_TEAM_COMPOSITION': {
      return {
        ...state,
        composition: {
          ...state.composition,
          [action.payload.side]: action.payload.teamComposition,
        },
      };
    }

    case 'LIVE_SET_CLUB_MEMBERSHIPS': {
      return {
        ...state,
        clubMatchTeamPlayers: {
          ...state.clubMatchTeamPlayers,
          [action.payload.side]: action.payload.memberships.map(
            createMatchTeamPlayerFromMembership
          ),
        },
      };
    }

    case 'LIVE_SET_TEAMSEASON_MEMBERSHIPS': {
      return {
        ...state,
        teamSeasonMatchTeamPlayers: {
          ...state.teamSeasonMatchTeamPlayers,
          [action.payload.side]: action.payload.memberships.map(
            createMatchTeamPlayerFromMembership
          ),
        },
      };
    }

    case 'LIVE_SELECT_MATCHTEAMPLAYER': {
      return {
        ...state,
        selectedMatchTeamPlayer: {
          ...state.selectedMatchTeamPlayer,
          [action.payload.side]: action.payload.matchTeamPlayer,
        },
      };
    }

    case 'LIVE_UNSELECT_MATCHTEAMPLAYER': {
      return {
        ...state,
        selectedMatchTeamPlayer: {
          ...state.selectedMatchTeamPlayer,
          [action.payload.side]: null,
        },
      };
    }

    case 'LIVE_UPDATE_MATCHTEAMPLAYER': {
      const { side, matchTeamPlayer } = action.payload;
      const updatedSideTeamSeasonMatchTeamPlayers = state.teamSeasonMatchTeamPlayers[
        side
      ].map(mtp =>
        mtp.membership.id !== matchTeamPlayer.membership.id ? mtp : matchTeamPlayer
      );
      const updatedSideClubMatchTeamPlayers = state.clubMatchTeamPlayers[side].map(mtp =>
        mtp.membership.id !== matchTeamPlayer.membership.id ? mtp : matchTeamPlayer
      );
      return {
        ...state,
        teamSeasonMatchTeamPlayers: {
          ...state.teamSeasonMatchTeamPlayers,
          [side]: updatedSideTeamSeasonMatchTeamPlayers,
        },
        clubMatchTeamPlayers: {
          ...state.clubMatchTeamPlayers,
          [side]: updatedSideClubMatchTeamPlayers,
        },
      };
    }

    case 'LIVE_PLACE_MATCHTEAMPLAYER': {
      const selectedMatchTeamPlayer = state.selectedMatchTeamPlayer[action.payload.side];
      if (!selectedMatchTeamPlayer) {
        return state;
      }
      const newMatchTeamPlayer = {
        ...selectedMatchTeamPlayer,
        position: action.payload.position,
        positionSide: action.payload.positionSide,
        onBench: false,
      };

      const positionPlayers: IMatchTeamPlayer[] =
        state.composition[action.payload.side].positions[action.payload.position] || [];

      const newPositionPlayers = [...positionPlayers, newMatchTeamPlayer];

      return {
        ...state,
        composition: {
          ...state.composition,
          [action.payload.side]: {
            ...state.composition[action.payload.side],
            positions: {
              ...state.composition[action.payload.side].positions,
              [action.payload.position]: newPositionPlayers,
            },
          },
        },
      };
    }

    case 'LIVE_REMOVE_MATCHTEAMPLAYER': {
      const positionPlayers: IMatchTeamPlayer[] =
        state.composition[action.payload.side].positions[action.payload.position] || [];

      const newPositionPlayers =
        action.payload.position === EPosition.Goalkeeper
          ? []
          : positionPlayers.filter(
              (mtp: IMatchTeamPlayer) => mtp.positionSide !== action.payload.positionSide
            );

      return {
        ...state,
        composition: {
          ...state.composition,
          [action.payload.side]: {
            ...state.composition[action.payload.side],
            positions: {
              ...state.composition[action.payload.side].positions,
              [action.payload.position]: newPositionPlayers,
            },
          },
        },
      };
    }

    // Substitutes
    case 'LIVE_ADD_SUBSTITUTE': {
      const newMatchTeamPlayer = {
        ...state.selectedMatchTeamPlayer[action.payload.side],
        onBench: true,
        position: null,
        positionSide: null,
      };

      return {
        ...state,
        composition: {
          ...state.composition,
          [action.payload.side]: {
            ...state.composition[action.payload.side],
            substitutes: [
              ...state.composition[action.payload.side].substitutes,
              newMatchTeamPlayer,
            ],
          },
        },
      };
    }

    case 'LIVE_REMOVE_SUBSTITUTE': {
      const selectedMTP = state.selectedMatchTeamPlayer[action.payload.side];
      if (!selectedMTP) return state;

      const membershipId = selectedMTP.membership.id;

      const newSubstitutes = state.composition[action.payload.side].substitutes.filter(
        (mtp: IMatchTeamPlayer) => mtp.membership.id !== membershipId
      );

      return {
        ...state,
        composition: {
          ...state.composition,
          [action.payload.side]: {
            ...state.composition[action.payload.side],
            substitutes: newSubstitutes,
          },
        },
      };
    }

    case 'LIVE_CHOOSE_COACH': {
      return {
        ...state,
        composition: {
          ...state.composition,
          [action.payload.side]: {
            ...state.composition[action.payload.side],
            coach: action.payload.coach,
          },
        },
      };
    }

    case 'LIVE_SET_TEAM_LAST_MATCHES': {
      return {
        ...state,
        lastMatches: {
          ...state.lastMatches,
          [action.payload.side]: action.payload.collection,
        },
      };
    }
  }
  return state;
};
