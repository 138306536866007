import { getProject } from '../../common/services/project';
import { IPerson } from './typings';

export const getPersonFullName = (person: IPerson): string => {
  if (!person) return '';
  return person.fullName;
};
export const getPersonUsualName = (person: IPerson): string => {
  if (!person) return '';
  return person.usualName;
};
export const getPersonMatchName = (person: IPerson): string => {
  if (!person) return '';
  return person.matchName || '';
};

export const getPersonUsualNameNationalityAge = (person: IPerson, ageUnitTranslation: string = ''): string => {
  if (!person) return '';
  const nationality = person.mainNationality && person.mainNationality.codeFIFA;
  const age = person.age && `${person.age}${ageUnitTranslation}`;

  if (!nationality && !age) {
    return `${person.usualName}`;
  }
  if (!nationality && age) {
    return `${person.usualName} (${age})`;
  }
  if (nationality && !age) {
    return `${person.usualName} (${nationality})`;
  }

  return `${person.usualName} (${nationality} - ${age})`;
};
export const getPersonFisrtName = (person: IPerson): string => {
  if (!person) return '';
  return person.shortFirstName || person.firstName || '';
};
export const getPersonLastName = (person: IPerson): string => {
  if (!person) return '';
  return person.shortLastName || person.lastName || '';
};

export const getDefaultPeoplePath = (subPath: string): string => `${getProject().url}/${subPath}?order[birthDate]=desc`;
