import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TransfersPanels from '../../../transfers/Panels';

@injectIntl
export default class PlayerTransfers extends Component {
  render() {
    const {
      transfers,
      intl: { messages: m },
    } = this.props;

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_transfers}</Typography>
        </Toolbar>
        <div style={{ padding: 16 }}>
          <TransfersPanels transfers={transfers} />
        </div>
      </div>
    );
  }
}
