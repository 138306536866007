import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center' as 'center'
  },
  item: {
    padding: 8,
  },
  highlighted: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
  },
  input: {
    fontSize: 12,
    width: 36,
    marginLeft: 2,
    marginRight: 2,
  },
  icon : {
    color: "rgba(0, 0, 0, 0.26)",
    fontSize: '18px',
  }
});
