export default function reducer(
  state = {
    article: [],
    fetching: false,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_SEARCH_FM': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_SEARCH_FM_FULFILLED': {
      return {
        ...state,
        article: action.payload,
        fetching: true,
      };
    }
    case 'FETCH_SEARCH_FM_REJECTED': {
      return {
        ...state,
        fetching: false,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }

    default:
      return state;
  }
}
