import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'TOURNAMENT',
  inside: {
    competition: { get: 'tournaments' },
  },
  collections: {
    rankingRulesIds: { get: 'rankingRules', set: 'rankingRule' },
  },
  details: {
    phaseId: { get: 'phase' },
    classementId: { get: 'classement' },
  },
});
