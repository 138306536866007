import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import ImageInput from '../../../../images/Input';

export default class Form extends Component {
  config = {
    objectFields: ['picture'],
    disposition: [
      {
        fields: [{ name: 'picture', component: ImageInput, size: 6 }],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`teamSeasonPicture_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
