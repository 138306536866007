import { IMatch } from '../../../matches/typings';
import {
  addCard,
  addGoal,
  addMissedPenalty,
  addPenaltyShot,
  addSubstitute,
  deleteCard,
  deleteGoal,
  deleteMissedPenalty,
  deletePenaltyShot,
  deleteSubstitute,
  updateCard,
  updateGoal,
  updateMissedPenalty,
  updatePenaltyShot,
  updateSubstitute,
} from '../../../matchEvents/actions';
import {
  IMatchEvent,
  IMatchEventCard,
  IMatchEventGoal,
  IMatchEventMissedPenalty,
  IMatchEventPenaltyShot,
  IMatchEventSubstitute,
} from '../../../matchEvents/typings';
import {
  getCommentaryEventName,
  getMatchEventBaseFromCommentary,
  getMatchEventUpdatedFromCommentary,
} from '../../../matchEvents/utils';
import { ILiveCommentary } from '../typings';
import { getScores, getShootoutScores } from '../utils';

export const handleMatchEventPost = (
  eventName: IMatchEvent['name'],
  commentary: ILiveCommentary,
  match: IMatch,
  matchEvents: IMatchEvent[]
) => {
  const matchEvent = getMatchEventBaseFromCommentary(commentary, match);

  const player2 = commentary.player2 ? commentary.player2['@id'] : null;
  switch (eventName) {
    case 'goal':
      const scoresAfterGoal = getScores(matchEvents, matchEvent);
      (matchEvent as IMatchEventGoal).awayScore = scoresAfterGoal.away;
      (matchEvent as IMatchEventGoal).homeScore = scoresAfterGoal.home;
      (matchEvent as IMatchEventGoal).assistPlayer = player2;
      return addGoal(matchEvent as IMatchEventGoal);
    case 'card':
      return addCard(matchEvent as IMatchEventCard);
    case 'missed_penalty':
      (matchEvent as IMatchEventMissedPenalty).savedByPlayer = player2;
      (matchEvent as IMatchEventMissedPenalty).type = player2 ? 'saved' : 'missed';
      return addMissedPenalty(matchEvent as IMatchEventMissedPenalty);
    case 'substitute':
      (matchEvent as IMatchEventSubstitute).playerOff = player2;
      return addSubstitute(matchEvent as IMatchEventSubstitute);
    case 'penalty_shot':
      (matchEvent as IMatchEventPenaltyShot).result =
        commentary.type === 'penalty_shot_saved'
          ? 'saved'
          : commentary.type === 'penalty_shot_missed'
          ? 'missed'
          : 'scored';
      const scoresAfterPenaltyShot = getShootoutScores(
        matchEvents,
        matchEvent as IMatchEventPenaltyShot
      );
      (matchEvent as IMatchEventPenaltyShot).number = scoresAfterPenaltyShot.sideNumber;
      (matchEvent as IMatchEventPenaltyShot).ordering = scoresAfterPenaltyShot.ordering;
      return addPenaltyShot(matchEvent as IMatchEventPenaltyShot);
  }
  return Promise.resolve(null);
};

export const handleMatchEventUpdate = (
  commentary: ILiveCommentary,
  editingMatchEventId: string,
  match: IMatch
) => {
  const matchEvent: Partial<IMatchEvent> = getMatchEventUpdatedFromCommentary(
    commentary,
    match
  );

  const name = getCommentaryEventName(commentary.type);
  const player2 = commentary.player2 ? commentary.player2['@id'] : null;
  switch (name) {
    case 'goal':
      (matchEvent as IMatchEventGoal).assistPlayer = player2;
      return updateGoal(editingMatchEventId, matchEvent as IMatchEventGoal);
    case 'card':
      return updateCard(editingMatchEventId, matchEvent as IMatchEventCard);
    case 'missed_penalty':
      (matchEvent as IMatchEventMissedPenalty).savedByPlayer = player2;
      (matchEvent as IMatchEventMissedPenalty).type = player2 ? 'saved' : 'missed';
      return updateMissedPenalty(
        editingMatchEventId,
        matchEvent as IMatchEventMissedPenalty
      );
    case 'penalty_shot':
      return updatePenaltyShot(editingMatchEventId, matchEvent as IMatchEventPenaltyShot);
    case 'substitute':
      (matchEvent as IMatchEventSubstitute).playerOff = player2;
      return updateSubstitute(editingMatchEventId, matchEvent as IMatchEventSubstitute);
  }
  return Promise.resolve(null);
};

export const handleMatchEventDelete = (matchEvent: IMatchEvent) => {
  switch (matchEvent.name) {
    case 'card':
      return deleteCard(matchEvent.id);
    case 'goal':
      return deleteGoal(matchEvent.id);
    case 'missed_penalty':
      return deleteMissedPenalty(matchEvent.id);
    case 'penalty_shot':
      return deletePenaltyShot(matchEvent.id);
    case 'substitute':
      return deleteSubstitute(matchEvent.id);
  }
  return Promise.resolve(null);
};
