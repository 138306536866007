import rest from '../../common/services/restFootData';

export const getClubActiveMemberships = (clubId: string, gender: 'male' | 'female') =>
  rest.get('memberships', {
    params: {
      club: clubId,
      'person.gender': gender,
      isActive: 1,
      groups: ['read', '_person'],
    },
  });

export const getTeamSeasonMemberships = (
  teamSeasonId: string,
  gender: 'male' | 'female'
) =>
  rest.get('memberships', {
    params: {
      teamSeason: teamSeasonId,
      'person.gender': gender,
      isActive: 1,
      groups: ['read', '_person'],
    },
  });
