import {
  createDetailReducer,
  createListReducer,
} from '../../common/services/reducerCreator';

export const agentCompaniesReducer = createListReducer('AGENTCOMPANIES');
export const agentCompanyReducer = createDetailReducer({
  modelName: 'AGENTCOMPANY',
  collections: {
    playersIds: { get: 'players' },
    agentsIds: { get: 'agents' },
  },
});
