import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function ChampionIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 500 500">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M353 55l-194 0 0 87 23 0 0 12c0 13 10 23 23 23l19 0 0 33c-32 9-60 32-75 62l-4 0c-46 0-84 37-84 84l0 66 76 0 0 35 238 0 0-36 76 0 0-66c0-46-38-84-84-84l-5 0c-15-30-43-52-75-61l0-33 19 0c13 0 24-11 24-23l0-12 23 0 0-87z m-178 16l162 0 0 55-23 0 0 28c0 4-3 7-7 7l-35 0 0 62 6 1c32 7 59 28 72 59l2 4 15 0c37 0 68 31 68 68l0 50-77 0 0 36-205 0 0-35-76 0 0-50c0-38 31-68 68-68l15 0 2-5c13-29 40-51 72-59l6-1 0-62-35 0c-4 0-7-3-7-7l0-28-23 0z m192 239l-9 0 0 8c0 1 0 3 1 4 0 1 0 2 0 3l0 58 54 0 0-28c0-25-21-45-46-45z m8 57l0-40c12 4 21 15 21 28l0 12z m-222-57l-8 0c-25 0-46 20-46 45l0 28 54 0z m-37 57l0-12c0-13 9-24 21-28l0 40z m168-66c-1 0-1 0-1 0l-27 21-27-19c-1-1-2-1-2 0-1 0-1 1-1 2l10 28-26 15c-1 0-2 1-1 2 0 1 1 2 2 2l33 0 10 35c1 1 1 2 2 2 1 0 2-1 2-2l9-27c1-2 0-2-1-3-1 0-2 1-2 2l-7 21-9-30c-1-1-1-2-2-2l-27 0 21-13c1 0 1-1 1-2l-9-23 23 16c1 0 2 0 2 0l23-18-8 25c-1 0 0 2 0 2l22 12-21 0c-1 0-2 1-2 2 0 1 1 2 2 2l28 0c1 0 2-1 2-1 1-1 0-2-1-3l-26-15 10-29c1-1 0-2-1-3 0 1-1 1-1 1z"
        />
      </g>
    </SvgIcon>
  );
}
