import rest from 'Services/restInternal';
import restFM from 'Services/restFootMercato';
import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import { getProject } from '../services/project';

export function fetchUsers(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_USERS' });

    return rest
      .get('users', { params })
      .then(
        res => dispatch({ type: 'FETCH_USERS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_USERS_REJECTED', payload: err.response })
      );
  };
}
export function fetchUser(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_USER' });

    return rest
      .get(`users/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_USER_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_USER_REJECTED', payload: err.response })
      );
  };
}

export function addUser(user, history) {
  return function(dispatch) {
    dispatch({ type: 'ADD_USER' });

    return rest.post('users', user).then(
      res => {
        dispatch({ type: 'ADD_USER_FULFILLED', payload: res.data });
        return history.push(`${getProject().url}/users/${res.data.id}`);
      },
      err => dispatch({ type: 'ADD_USER_REJECTED', payload: err.response })
    );
  };
}

export function deleteUser(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_USER', payload: id });

    return rest.delete(`users/${id}`).then(
      () => {
        dispatch({ type: 'DELETE_USER_FULFILLED', payload: { id } });
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_userDeleted', type: 'success' },
        });
      },
      err => dispatch({ type: 'DELETE_USER_REJECTED', payload: err.response.description })
    );
  };
}

export function updateUser(id, user) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_USER', payload: id });
    return rest.put(`users/${id}`, user).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_userUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_USER_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { description, errors } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: description, type: 'error' },
          });
          throw new SubmissionError(errors);
        } else {
          const message = err.response.data['hydra:title'];
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message, type: 'error' },
          });
        }
        return dispatch({
          type: 'UPDATE_USER_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchAuthor(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'FETCH_AUTHOR' });

    return restFM.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_AUTHOR_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_AUTHOR_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_AUTHOR_REJECTED', payload: err.response })
    );
  };
}
