import React, { Component } from 'react';

import isAdmin from 'Services/me';
import { FormEdit } from 'Components/FormEdit';
import TextInput from 'Components/inputs/Text';

export default class ContentForm extends Component {
  config = {
    fields: ['id', 'username', 'publicName', 'twitterAccount', 'bio', 'role'],
    disposition: [
      {
        fields: [
          { name: 'publicName', component: TextInput, size: 6 },
          { name: 'username', component: TextInput, disabled: !isAdmin(), size: 6 },
          { name: 'twitterAccount', component: TextInput, size: 6 },
          { name: 'bio', component: TextInput, multiline: true, size: 6 },
          { name: 'role', component: TextInput, size: 6 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`author_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
      />
    );
  }
}
