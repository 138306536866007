import { store } from '../../../../store';
import { asyncEmbed } from 'FM/articles/embed/Command/actions';

export const getEmbedCommand = () => ({
  name: 'embed',
  keyCommand: 'embed',
  execute: async (state0, api) => {
    const response = await store.dispatch(asyncEmbed());
    if (response) {
      api.replaceSelection(`\`\`\`${response}\`\`\``);
    }
  },
});

export const getEmbedCommandInfo = async () => {
  const response = await store.dispatch(asyncEmbed());
  if (!response) {
    return null;
  }
  return {
    start: `\`\`\`${response}\`\`\``,
  };

};
