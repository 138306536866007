import React, { Component } from 'react';
import IconCard from 'Components/icons/Card';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  icon: {
    marginRight: 8,
  },
  events: {
    display: 'flex',
    background: '#ebebec',
    padding: 4,
  },
  event: {
    display: 'flex',
    margin: 4,
    background: 'white',
    padding: '4px 8px',
    fontSize: '12px',
    lineHeight: '24px',
  },
  player: {
    fontWeight: '500',
  },
};

@withStyles(styles)
export default class Cards extends Component {
  renderPlayerName = p => p && p.membership.personMatchName;

  renderMatchEvent = ev => {
    const { classes, playersMap } = this.props;

    return (
      <div className={classes.event}>
        <IconCard
          className={classes.icon}
          type={ev.type == 'yellow' ? 'yellow' : 'red'}
        />
        <div>{ev.timeMin}'</div>
        <div style={{ flex: 1 }} />
        <div className={classes.player}>
          {this.renderPlayerName(playersMap.get(ev.matchTeamPlayerId))}
        </div>
      </div>
    );
  };
  render() {
    const { formations, classes } = this.props;

    return (
      <div className={classes.events}>
        <div style={{ flex: 1 }}>
          {formations.home.cards &&
            Object.values(formations.home.cards).map(me =>
              this.renderMatchEvent(me)
            )}
        </div>
        <div style={{ flex: 1 }}>
          {formations.away.cards &&
            Object.values(formations.away.cards).map(me =>
              this.renderMatchEvent(me)
            )}
        </div>
      </div>
    );
  }
}
