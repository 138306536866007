import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCollection, getItemBySlug } from 'Services/connectors';
import { Route } from 'react-router-dom';
import { fetchTeam, updateTeam } from '../actions';
import fetchLastActiveTeamSeasonMembers from '../../teamSeasonMembers/actions';
import { fetchTeamTeamSeasons } from '../../components/teamSeasons/actions';
import RevisionNote from 'Components/revisions/Note';
import Revisions from 'Components/revisions';
import TabsRoute from 'Components/TabsRoute';
import { IdBadge } from 'Components/IdBadge';
import Form from './Form';
import Competitions from './Competitions';
import Club from './Club';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReturnButton from 'Components/ReturnButton';

import TeamSeasonMembersBloc from '../../teamSeasonMembers/Bloc';
import TeamSeasonShirts from './TeamSeasonShirts';
import TeamSeasonPictures from './TeamSeasonPictures';

import Layout from 'Components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  team: getItemBySlug(store.team, props.match.params.topicSlug),
  fetching: store.team.fetching,
  teamSeasonMembers: getCollection(
    'teamSeasonMembersIds',
    store.team,
    store.teamSeasonMember
  ),
  teamSeasons: getCollection('teamSeasonIds', store.team, store.teamSeason),
}))
export default class Detail extends Component {
  state = {
    showRevisions: false,
  };

  menu = [
    { label: 'app_teamInfo', link: '/' },
    { label: 'app_teamSeasonMembers', link: '/teamSeasonMembers' },
    // { label: 'app_teamSeasonShirts', link: '/teamSeasonShirts' },
    { label: 'app_teamSeasonPictures', link: '/teamSeasonPictures' },
  ];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchTeam(targetSlug || slug)).then(({ payload }) => {
      if (payload && payload.id) {
        dispatch(fetchLastActiveTeamSeasonMembers(payload.id));
        dispatch(fetchTeamTeamSeasons(payload.id));
      }
    });
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handleTeamChange = team => {
    const { dispatch } = this.props;
    return dispatch(updateTeam(team.id, team));
  };

  getLabel = team => {
    let label = team.name;
    if (team.ageGroup && team.ageGroup.code !== 'senior') {
      label += ` ${team.ageGroup.name}`;
    }
    if (team.gender !== 'male') {
      label += ` ${team.genderTranslated}`;
    }

    return label;
  };

  render() {
    const {
      fetching,
      team,
      teamSeasonMembers,
      teamSeasons,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!team) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="team-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[{ labelKey: 'app_teams' }, { label: this.getLabel(team) }]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{this.getLabel(team)}</Typography>
              <IdBadge id={team.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="clubs" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route
              exact
              path={match.url}
              render={() => (
                <div>
                  <div>
                    <RevisionNote
                      style={{ margin: 16 }}
                      createdAt={team.createdAt}
                      updatedAt={team.updatedAt}
                      toggle={this.toggleRevisions}
                    />
                    {this.state.showRevisions && (
                      <Revisions
                        entity="team"
                        id={team.id}
                        updatedAt={team.updatedAt}
                        onRollback={() => this.fetchData()}
                      />
                    )}
                  </div>
                  <Form onSubmit={this.handleTeamChange} data={team} />
                </div>
              )}
            />

            <Route
              path={`${match.url}/teamSeasonMembers`}
              render={() => (
                <TeamSeasonMembersBloc
                  teamSeasonMembers={teamSeasonMembers}
                  history={history}
                />
              )}
            />

            <Route
              path={`${match.url}/teamSeasonShirts`}
              render={() => (
                <TeamSeasonShirts teamSeasons={teamSeasons} history={history} />
              )}
            />

            <Route
              path={`${match.url}/teamSeasonPictures`}
              render={() => (
                <TeamSeasonPictures teamSeasons={teamSeasons} history={history} />
              )}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Club club={team.club} />
            <Competitions />
          </Paper>
        }
      />
    );
  }
}
