import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { FormEdit } from 'Components/FormEdit';

import AgentInput from '../../people/agents/Input';
import SelectConstant from 'FDComponents/constants/Select';
import Date from 'Components/inputs/Date';
import { AgentCompanySelect } from '../../agentCompanies/components/AgentCompanySelect';
import { AmountInput } from '../../../common/components/inputs/AmountInput';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';

@injectIntl
export default class Form extends Component {
  config = {
    fields: [
      'id',
      'startDate',
      'endDate',
      'type',
      'salary',
      'releaseClause',
      'yearsInOption',
    ],
    objectFields: ['agent', 'agentCompany'],
    disposition: [
      {
        fields: [
          {
            name: 'type',
            component: SelectConstant,
            entity: 'contract',
            size: 6,
            required: true
          },
          { name: 'startDate', component: Date, size: 6, required: true },
          { name: 'endDate', component: Date, size: 6, required: true },
          {
            name: 'salary',
            labelKey: 'field_salary',
            names: ['salary.value', 'salary.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
          {
            name: 'releaseClause',
            labelKey: 'field_releaseClauseAmount',
            names: ['releaseClause.value', 'releaseClause.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
          {
            name: 'yearsInOption',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          { name: 'agent', component: AgentInput, size: 6 },
          { name: 'agentCompany', component: AgentCompanySelect, size: 6 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit, onDelete } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`contract_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
