import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import Diff from 'Components/Diff';
import PeopleDiff from 'FD/people/Diff';
import CompetitionsDiff from 'FD/competitions/Diff';
import TeamsDiff from 'FD/teams/Diff';
import MatchesDiff from 'FD/matches/Diff';

const styles = theme => ({
  diff: {
    margin: `${theme.spacing(2)}px 0`,
  },
});

@injectIntl
@withStyles(styles)
export default class RevisionDiff extends Component {
  renderDiff(key, value) {
    const {
      intl: { messages: m },
    } = this.props;

    if (key == 'footdataPersonIds') {
      return [
        <h3>{m.app_people}</h3>,
        <PeopleDiff oldValue={value[0]} newValue={value[1]} />,
      ];
    }
    if (key == 'footdataCompetitionIds') {
      return [
        <h3>{m.app_competitions}</h3>,
        <CompetitionsDiff oldValue={value[0]} newValue={value[1]} />,
      ];
    }
    if (key == 'footdataTeamIds') {
      return [
        <h3>{m.app_teams}</h3>,
        <TeamsDiff oldValue={value[0]} newValue={value[1]} />,
      ];
    }
    if (key == 'footdataMatchIds') {
      return [
        <h3>{m.app_matches}</h3>,
        <MatchesDiff oldValue={value[0]} newValue={value[1]} />,
      ];
    }

    if (key == 'publishedAt' || key == 'unpublishedAt') {
      const format = 'YYYY[/]MM[/]DD HH[:]mm[:]ss';
      const oldValueDate =
        value[0] && value[0].date ? moment(value[0].date).format(format) : '';
      const newValueDate =
        value[1] && value[1].date ? moment(value[1].date).format(format) : '';

      return [
        <h3>{m[`field_${key}`]}</h3>,
        <Diff oldValue={oldValueDate} newValue={newValueDate} />,
      ];
    }

    return [
      <h3>{m[`field_${key}`]}</h3>,
      <Diff oldValue={value[0]} newValue={value[1]} />,
    ];
  }

  render() {
    const { revision = {}, classes } = this.props;

    return (
      <div>
        {Object.entries(revision).map(([key, value]) => (
          <div className={classes.diff}>{this.renderDiff(key, value)}</div>
        ))}
      </div>
    );
  }
}
