import { getProject } from '../../common/services/project';
import { IMembership } from './typings';

export const removeDuplicateMemberships = (list: IMembership[]): IMembership[] => {
  // @ts-ignore map typing
  const map = new Map(list.map(x => [x.personId, x]));
  return [...map.values()];
};

export const getMembershipPersonHref = (membership: IMembership) => {
  const path = membership.role === 'coach' ? 'coaches' : `${membership.role}s`;
  return `#${getProject().url}/${path}/${membership.personSlug}`;
};
