import { IRootState } from '../../../typings';
import {
  EPosition,
  EPositionSide,
  IComposition,
  ICompositionPositions,
} from '../../compositions/typings';
import { IMatch, ISide } from '../../matches/typings';
import { IMatchTeamPlayer } from '../../matchTeamPlayers/typings';
import { IMembership } from '../../memberships/typings';

// COMPOSITION
export const selectComposition = (state: IRootState): IComposition =>
  state.liveComposition.composition;

export const selectTeamCompositionPositions = (
  state: IRootState,
  side: ISide
): ICompositionPositions => selectComposition(state)[side].positions;

export const selectPositionMatchTeamPlayers = (
  state: IRootState,
  side: ISide,
  position: EPosition
): IMatchTeamPlayer[] | undefined =>
  selectTeamCompositionPositions(state, side)[position];

export const selectCellMatchTeamPlayer = (
  state: IRootState,
  side: ISide,
  position: EPosition,
  positionSide: EPositionSide
): null | IMatchTeamPlayer => {
  const positionMatchTeamPlayers = selectPositionMatchTeamPlayers(state, side, position);
  if (!positionMatchTeamPlayers) return null;

  // If Goalkeeper position return MTP when asking center position side
  if (position === EPosition.Goalkeeper) {
    return positionSide === EPositionSide.Centre ? positionMatchTeamPlayers[0] : null;
  }

  for (const matchTeamPlayer of positionMatchTeamPlayers) {
    if (matchTeamPlayer.positionSide === positionSide) return matchTeamPlayer;
  }
  return null;
};

// MATCH TEAM PLAYERS
export const selectTeamSeasonMatchTeamPlayers = (
  state: IRootState,
  side: ISide
): IMatchTeamPlayer[] => state.liveComposition.teamSeasonMatchTeamPlayers[side];

export const selectClubMatchTeamPlayers = (
  state: IRootState,
  side: ISide
): IMatchTeamPlayer[] => state.liveComposition.clubMatchTeamPlayers[side];

export const selectMatchTeamPlayersGroupedByPosition = (
  state: IRootState,
  side: ISide,
  allMemberships: boolean
): [ICompositionPositions, IMembership[]] => {
  const list = allMemberships
    ? selectClubMatchTeamPlayers(state, side)
    : selectTeamSeasonMatchTeamPlayers(state, side);

  const teamMatchTeamPlayersGroupByPosition: ICompositionPositions = {
    goalkeeper: [],
    defender: [],
    wing_back: [],
    defensive_midfielder: [],
    midfielder: [],
    attacking_midfielder: [],
    striker: [],
  };
  const coaches: IMembership[] = [];
  for (const mtp of list) {
    const position = mtp.membership.person.position;
    if (mtp.membership.role === 'player' && position) {
      teamMatchTeamPlayersGroupByPosition[position].push(mtp);
    } else if (mtp.membership.role === 'coach') {
      coaches.push(mtp.membership);
    }
  }
  for (const position in teamMatchTeamPlayersGroupByPosition) {
    if (teamMatchTeamPlayersGroupByPosition.hasOwnProperty(position)) {
      teamMatchTeamPlayersGroupByPosition[position].sort(sortMTP);
    }
  }

  return [teamMatchTeamPlayersGroupByPosition, coaches];
};

const sortMTP = (a: IMatchTeamPlayer, b: IMatchTeamPlayer) => {
  if (a.shirtNumber === null) return 1;
  if (b.shirtNumber === null) return -1;
  return a.shirtNumber - b.shirtNumber;
};

export const selectIsMatchTeamPlayerInComposition = (
  state: IRootState,
  side: ISide,
  matchTeamPlayer: IMatchTeamPlayer
): boolean => {
  const teamComposition = selectTeamCompositionPositions(state, side);
  for (const position in teamComposition) {
    if (teamComposition.hasOwnProperty(position)) {
      const positionMatchTeamPlayers = teamComposition[position];
      for (const mtp of positionMatchTeamPlayers) {
        if (mtp.membership.id === matchTeamPlayer.membership.id) {
          return true;
        }
      }
    }
  }
  return false;
};

export const selectSelectedMatchTeamPlayer = (
  state: IRootState,
  side: ISide
): IMatchTeamPlayer | null => state.liveComposition.selectedMatchTeamPlayer[side];

export const selectIsSelectedMatchTeamPlayer = (
  state: IRootState,
  side: ISide,
  matchTeamPlayer: IMatchTeamPlayer | null
): boolean => {
  if (!matchTeamPlayer) return false;
  const mtp = selectSelectedMatchTeamPlayer(state, side);
  if (mtp && mtp.membership.id === matchTeamPlayer.membership.id) {
    return true;
  }
  return false;
};

export const selectTeamCoach = (
  state: IRootState,
  side: ISide
): IMembership | undefined => selectComposition(state)[side].coach;

// SUBSTITUTES
export const selectTeamSubstitutes = (
  state: IRootState,
  side: ISide
): IMatchTeamPlayer[] => selectComposition(state)[side].substitutes;

export const selectIsMatchTeamPlayerInSubstitutes = (
  state: IRootState,
  side: ISide,
  matchTeamPlayer: IMatchTeamPlayer
): boolean => {
  const substitutes = selectTeamSubstitutes(state, side);
  for (const substitute of substitutes) {
    if (substitute.membership.id === matchTeamPlayer.membership.id) {
      return true;
    }
  }
  return false;
};

export const selectUsedShirtNumbers = (state: IRootState, side: ISide): number[] => {
  const usedNumbers: number[] = [];
  const substitutes = selectTeamSubstitutes(state, side);
  usedNumbers.push(...substitutes.map(sub => sub.shirtNumber as number));
  const teamComposition = selectTeamCompositionPositions(state, side);
  for (const position in teamComposition) {
    if (teamComposition.hasOwnProperty(position)) {
      const positionMatchTeamPlayers = teamComposition[position];
      usedNumbers.push(
        ...positionMatchTeamPlayers.map(
          positionPlayers => positionPlayers.shirtNumber as number
        )
      );
    }
  }
  return usedNumbers;
};

// OLD MATCHES
export const selectTeamOldMatches = (state: IRootState, side: ISide): IMatch[] =>
  state.liveComposition.lastMatches[side];
