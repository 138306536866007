import * as React from 'react';
import { ICommentaryType } from '../../../../../commentaries/typings';
import { getSrc } from '../../../../../images/tools';
import { IMatchEventPenaltyShot } from '../../../../../matchEvents/typings';
import { IPerson } from '../../../../../people/typings';
import { getPersonUsualName } from '../../../../../people/utils';
import { Content } from '../Content.component';
import { IComponentProps } from './PenaltyContent.connector';

export class PenaltyContent extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { commentary, membership } = this.props;
    return (
      !nextProps.membership ||
      !membership ||
      nextProps.commentary.type !== commentary.type ||
      nextProps.commentary.content !== commentary.content ||
      nextProps.membership.id !== membership.id
    );
  }

  public render() {
    const { commentary, membership } = this.props;
    const matchEvent = commentary.matchEvent as IMatchEventPenaltyShot;

    if (!membership) {
      return null;
    }
    const person = membership.person;
    const scorerTeam = this.getScorerTeam();

    const text = this.getText(
      person,
      commentary.type,
      matchEvent.number,
      matchEvent.type
    );

    const teamLogo = this.getTeamPhoto(scorerTeam);
    const personPhoto = this.getPersonPhoto(person);

    return (
      <React.Fragment>
        <div className="comment__pictures comment__pictures--round">
          <div className="comment__pictures__player">{personPhoto}</div>
          <div className="comment__pictures__team comment__pictures__team--right">
            {teamLogo}
          </div>
        </div>
        <div className="comment__text">
          <p className="comment__text__title">{text}</p>
        </div>
        <Content content={commentary.content} />
      </React.Fragment>
    );
  }

  private getText = (
    person: IPerson,
    type: ICommentaryType,
    penaltyShotNumber: number,
    matchEventType: string
  ) => {
    const {
      hasSavedByPlayer,
      intl: { formatMessage: f },
    } = this.props;

    if (type === 'missed_penalty') {
      if (matchEventType === 'saved' && hasSavedByPlayer) {
        return `${getPersonUsualName(hasSavedByPlayer.person)} ${f({
          id: `live_commentary_${type}_${matchEventType}`,
        })} ${getPersonUsualName(person)}`;
      }

      if (matchEventType === 'missed') {
        return `${f({
          id: `live_commentary_${type}_${matchEventType}`,
        })} ${getPersonUsualName(person)}`;
      }
    }

    const param = { person: getPersonUsualName(person), number: penaltyShotNumber };

    let key = `live_commentary_${type}`;
    if (penaltyShotNumber && penaltyShotNumber > 1) {
      key += '_plurial';
    }

    return f({ id: key }, param);
  };
  private getPersonPhoto = (person: IPerson) => {
    const src = getSrc(person) || '/img/person.jpg';
    return <img src={src} alt={getPersonUsualName(person)} width={70} height={70} />;
  };

  private getTeamPhoto = team => {
    return team.club.logo ? (
      <img src={getSrc(team.club.logo, 20)} alt={team.name} width={25} height={25} />
    ) : null;
  };

  private getScorerTeam = () => {
    const { commentary, homeTeam, awayTeam } = this.props;
    const { matchEvent, type } = commentary;

    const isOwnGoal = type === 'own_goal';
    const isHome = matchEvent.matchTeamPosition === 'home';
    return isHome !== isOwnGoal ? homeTeam : awayTeam;
  };
}
