import { connect } from 'react-redux';
import { compose } from 'recompose';
import { postPush } from '../../../footmercato/push/actions';
import { IRootState } from '../../../typings';
import { selectMatch } from '../../matches/selector';

const mapDispatchToProps = {
  postPush,
};

interface IOwnProps {}

const mapStateToProps = (state: IRootState) => {
  return {
    matchId: state.live.matchId,
    game: selectMatch(state, state.live.matchId),
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
