import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ContentAdd from '@material-ui/icons/Add';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { UserRestriction } from '../../../common/components/UserRestiction';
import ProductsPanels from '../../products/Panels';
import ProductDialog from '../../products/ProductDialog';
import ShirtDialog from '../../products/ShirtDialog';
import { groupProductsBySeason } from '../../products/utils';

const styles = {
  icon: {
    marginRight: 8,
  },
  buttonLeft: {
    marginRight: 8,
  },
};

@withStyles(styles)
@injectIntl
export default class Products extends Component {
  renderPanelsBySeason = products =>
    Object.entries(groupProductsBySeason(products)).map(([key, value]) =>
      value.length ? (
        <div style={{ padding: 16 }}>
          <Typography variant="subtitle1">{key}</Typography>
          <ProductsPanels products={value} />
        </div>
      ) : null
    );

  render() {
    const {
      products = [],
      classes,
      onAdd,
      onShirtAdd,
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_products}</Typography>
          <div style={{ flex: 1 }} />
          <UserRestriction>
            <ProductDialog
              title={m.dialog_addProduct}
              form="product_add"
              onCreate={onAdd}
            >
              <Button variant="contained" color="primary" className={classes.buttonLeft}>
                <ContentAdd className={classes.icon} />
                {m.button_add}
              </Button>
            </ProductDialog>
          </UserRestriction>
          <UserRestriction>
            <ShirtDialog
              title={m.button_addShirt}
              form="product_add"
              onCreate={onShirtAdd}
            >
              <Button variant="contained" color="primary">
                <ContentAdd className={classes.icon} />
                {m.button_addShirt}
              </Button>
            </ShirtDialog>
          </UserRestriction>
        </Toolbar>
        {this.renderPanelsBySeason(products)}
      </div>
    );
  }
}
