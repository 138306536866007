import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { SimpleBreadcrumbs as SimpleBreadcrumbsComponent } from './SimpleBreadcrumbs.component';

export interface ICrumb {
  labelKey?: string;
  label?: string;
  href?: string;
}
interface IOwnProps {
  crumbStack: ICrumb[];
}
export type IComponentProps = InjectedIntlProps & IOwnProps;

export const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const SimpleBreadcrumbs = connector(SimpleBreadcrumbsComponent);
