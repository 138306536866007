import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusRestrictedIcon({ ...props }) {
    return (
        <SvgIcon {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M20.2925 1H1.70691L10.9997 10.2928L20.2925 1ZM21 1.70669L11.7068 10.9999L21 20.2931V1.70669ZM10.2926 10.9999L1 1.7073V20.2925L10.2926 10.9999ZM1.70669 21L10.9997 11.707L20.2927 21H1.70669ZM0 0V22H22V0H0Z" fill="#B71C1C" />
        </SvgIcon>
    );
}
