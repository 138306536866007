import { Chip, Fab } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import LockIcon from '@material-ui/icons/Lock';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { Field, Fields, initialize } from 'redux-form';
import { getProject } from '../../services/project';
import DeleteButton from '../DeleteButton';
import ShowAdmin from '../ShowAdmin';
import { IComponentProps } from './FormEdit.connector';
import { IFormBloc, IFormField } from './typings';
import { getInitialValues } from './utils';
import UnlinkButton from '@material-ui/icons/LinkOff';

export class FormEdit extends Component<IComponentProps> {
  public render() {
    const {
      config: { disposition },
      main,
      inline,
      classes,
    } = this.props;

    if (!disposition || !disposition.length) {
      return null;
    }

    return (
      <form className={classes.form} onSubmit={this.onSubmit}>
        <Grid container>
          {disposition.map(this.renderFormBloc)}
          {inline && this.renderFlatActions()}
        </Grid>
        {!inline && (main ? this.renderFabActions() : this.renderFlatActions())}
      </form>
    );
  }

  private onSubmit = (event: any) => {
    const { handleSubmit, config } = this.props;

    // Avoid double submit if form inside form
    // https://redmine.dev.football/issues/4515
    event.preventDefault();
    event.stopPropagation();

    handleSubmit(event).then(res => {
      const { payload } = res;
      if (payload) {
        const data = getInitialValues(payload, config);
        initialize(event, data);
      }
    });
  };

  private renderFormBloc = (bloc: IFormBloc, index: number) => {
    const { fields, title, websiteOnly, size = 12, imageBloc } = bloc;
    const {
      img,
      intl: { messages: m },
      classes,
    } = this.props;

    const { name } = getProject();

    if (!fields || (websiteOnly && !websiteOnly.includes(name))) {
      return;
    }

    return (
      <Grid container item xs={12} sm={size} spacing={2} className={classes.inputsContainer} key={index}>
        {title ? (
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.title}>
              {m[title]}
            </Typography>
          </Grid>
        ) : null}
        {imageBloc && img ? (
          <div className={classes.imgContainer}>
            <img src={img} className={classes.img} />
          </div>
        ) : null}
        {fields.map(this.renderFormField)}
      </Grid>
    );
  };

  private renderFormField = (field: IFormField, index: number) => {
    const { isAdmin, size = 12, ...other } = field;
    const formField = (
      <Grid item xs={12} sm={size} data-test={`${this.props.entity}__input_${other.name}`} key={index}>
        {this.renderInput(other)}
      </Grid>
    );
    return isAdmin ? <ShowAdmin>{formField}</ShowAdmin> : formField;
  };

  private renderInput = ({ group, names, name, ...other }) =>
    group ? <Fields name={name} names={names} {...other} /> : <Field name={name} {...other} />;

  private renderFlatActions = () => {
    const {
      pristine,
      isRestricted,
      intl: { messages: m },
      classes,
      onCancel,
      onDelete,
      onRemove,
    } = this.props;

    if (isRestricted) return this.renderRestrictionMessage();

    return (
      <div className={classes.flatContainer}>
        {onCancel ? (
          <Button variant="contained" onClick={onCancel} className={classes.leftButton}>
            <CancelIcon className={classes.leftIcon} />
            {m.button_cancel}
          </Button>
        ) : null}
        {onRemove ? (
          <Button variant="contained" onClick={onRemove} className={classes.leftButton}>
            <UnlinkButton className={classes.leftIcon} />
            {m.button_unlink}
          </Button>
        ) : null}
        {onDelete ? <DeleteButton onRemove={onDelete} /> : null}
        <div style={{ flex: '1' }} />
        <Button variant="contained" color="primary" type="submit" disabled={pristine || this.isDisabled()}>
          <SaveIcon className={classes.leftIcon} />
          {m.button_validate}
        </Button>
      </div>
    );
  };

  private isDisabled = () => {
    const { submitting, invalid, externalDisable } = this.props;
    return invalid || submitting || externalDisable;
  };

  private renderFabActions = () => {
    const {
      classes,
      isRestricted,
      intl: { messages: m },
    } = this.props;

    if (isRestricted) return this.renderRestrictionMessage();

    return (
      <div className={classes.fabContainer}>
        {/*
          // @ts-ignore */}
        <Hidden xsDown>
          <Fab
            data-test={`${this.props.entity}__buttonSubmit`}
            variant="extended"
            color="primary"
            aria-label={m.button_validate}
            type="submit"
            disabled={this.isDisabled()}
            className={classes.fab}
          >
            <SaveIcon className={classes.leftIcon} />
            {m.button_validate}
          </Fab>
        </Hidden>
        {/*
          // @ts-ignore */}
        <Hidden smUp>
          <Fab
            data-test={`${this.props.entity}__buttonSubmit`}
            color="primary"
            aria-label={m.button_validate}
            type="submit"
            disabled={this.isDisabled()}
            className={classes.fab}
          >
            <SaveIcon />
          </Fab>
        </Hidden>
      </div>
    );
  };

  private renderRestrictionMessage = () => {
    const {
      intl: { messages: m },
      classes,
      onCancel,
    } = this.props;

    return (
      <div className={classes.flatContainer}>
        {onCancel ? (
          <Button variant="contained" onClick={onCancel} className={classes.leftButton}>
            <CancelIcon className={classes.leftIcon} />
            {m.button_cancel}
          </Button>
        ) : null}
        <Chip icon={<LockIcon />} label={m.form_restricted} />
      </div>
    );
  };
}
