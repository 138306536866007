import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AgentCompanyDetail } from './Detail';
import { AgentCompaniesList } from './List';

export const AgentCompaniesRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={AgentCompaniesList} />
      <Route path={`${match.url}/:topicId`} component={AgentCompanyDetail} />
    </Switch>
  );
};
