import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function GoalIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M255 457c-111 0-201-90-201-201 0-111 90-201 201-201 111 0 201 90 201 201 0 111-90 201-201 201z m6-66l59 44c34-13 64-34 87-63l-23-69-64-21-59 44z m-70 44l60-44 0-65-59-42-63 21-23 67c21 29 51 50 85 63z m-91-288c-19 32-31 64-34 102l59 45 63-21 23-70-39-56z m208-71c-17-5-33-9-52-9-19 0-36 2-55 9l-23 69 40 54 73 0 38-52z m31 71l-40 56 23 70 63 21 59-45c-2-36-13-70-34-102z"
        />
      </g>
    </SvgIcon>
  );
}
