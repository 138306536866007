import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateCell from 'Components/TableList/cell/Date';
import React, { Component } from 'react';
import { getProject } from '../../../common/services/project';

export default class Row extends Component {
  render() {
    const { tableData } = this.props;
    const { createdAt, objectClass, field, correctedValue, inputValue } = tableData;
    const href = `#${getProject().url}/corrections/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          <DateCell date={createdAt} />
        </TableCell>
        <TableCell>{objectClass}</TableCell>
        <TableCell>{field}</TableCell>
        <TableCell>{correctedValue}</TableCell>
        <TableCell>{inputValue}</TableCell>
      </TableRow>
    );
  }
}
