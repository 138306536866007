import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusPublishIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g>
        <rect fill="#99c455" x="0" y="0" width="24" height="24" />
      </g>
    </SvgIcon>
  );
}
