import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import PageviewIcon from '@material-ui/icons/Pageview';
import filterList from 'Actions/lists';
import DateInput from 'Components/inputs/Date';
import Layout from 'Components/Layout';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { buildFMLink, getProject } from 'Services/project';
import queryReducer from 'Services/queriesReducer';
import debounce, { getLinkId } from 'Services/tools';
import { SearchFilter } from '../../../../common/components/SearchFilter';
import { TableList } from '../../../../common/components/TableList';
import { getDefaultInputsValue } from '../../../../common/services/getDefaultInputsValue';
import { fetchAuthorMe } from '../../../authors/actions';
import SelectConstant from '../../../components/constants/Select';
import TopBar from '../../../components/TopBar';
import { fetchTags } from '../../../tags/actions';
import TagsPanels from '../../../tags/Panels';
import BriefDialog from '../../Dialog';
import { addLiveTransfer, fetchLiveTransfers } from '../actions';
import Row from './Row';
import { hasAccessToRestrictedArticles } from "Services/me";

const styles = theme => ({
  tagPanel: {
    padding: 16,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  liveTransfers: store.briefs.list,
  fetching: store.briefs.fetching,
  pagination: store.briefs.pagination,
  total: store.briefs.totalItems,
  tag: store.tags.list.find(t => t.keyCode === 'live_transfers'),
  query: parseQuery(props.location.search),
  me: store.me,
}))
export default class LiveTransfers extends Component {
  state = {
    url: '',
  };
  config = {
    filters: [
      {
        type: 'createAtAfter',
        input: DateInput,
        labelKey: 'filter_createdAt_after',
        defaultValue: moment().format('YYYY-MM-DD'),
        required: true,
      },
      {
        type: 'publishedAt',
        input: DateInput,
      },
      {
        type: 'status',
        input: SelectConstant,
        entity: 'article',
        labelKey: 'filter_status',
      },
    ],
    headers: [
      { labelKey: 'field_status', size: 'small' },
      { labelKey: 'field_title', size: 'small' },
      { labelKey: 'field_authors', size: 'small' },
      { id: 'publishedAt', labelKey: 'field_publishedAt', size: 'small' },
    ],
  };

  defaultInputsValue = getDefaultInputsValue(this.config.filters);

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    buildFMLink('transferts-en-direct').then(url => this.setState({ url }));
    this.props.dispatch(fetchTags('keyCode=live_transfers'));

    if (this.props.location.search === '') {
      return this.changeParams({
        type: 'SET_FILTER',
        payload: { ...this.defaultInputsValue },
      });
    }

    if (this.props.location.search !== '') {
      return this.fetchData(this.props.location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (prevProps.location.search === '' && nextProps.location.search === '') {
      return this.changeParams({
        type: 'SET_FILTER',
        payload: { ...this.defaultInputsValue },
      });
    }

    if (prevProps.location.search !== nextProps.location.search && nextProps.location.search !== '') {
      return this.fetchData(nextProps.location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchLiveTransfers(filter));
    dispatch(filterList('live-transfers', filter));
  };

  handleCreate = liveTransfer => {
    const { dispatch, history, tag, me } = this.props;

    dispatch(fetchAuthorMe(me.username)).then(({ payload }) => {
      const liveTransferWithAuthor = {
        authors: [payload['@id']],
        tags: [tag['@id']],
        ...liveTransfer,
      };
      dispatch(addLiveTransfer(liveTransferWithAuthor)).then(({ payload }) => {
        if (payload) {
          history.push(`${getProject().url}/live-transfers/${payload.id}`);
        }
      });
    });
  };

  handleFilters = change => {
    const filtersQuery = {
      ...change,
      tags: getLinkId(change.tags),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);

    location.search != search && location.search === ''
      ? history.replace(`${getProject().url}/live-transfers${search}`)
      : history.push(`${getProject().url}/live-transfers${search}`);
  }, 500);

  render() {
    const {
      fetching,
      pagination,
      total,
      query,
      tag,
      intl: { messages: m },
      classes,
    } = this.props;
    let { liveTransfers } = this.props

    if (!hasAccessToRestrictedArticles()) {
      liveTransfers = liveTransfers.filter($brief => $brief.status !== 'restricted');
    }

    return (
      <Layout
        id="liveTransfers-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_liveTransfer' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_liveTransfer}</Typography>
              <div style={{ flex: 1 }} />
              <Button variant="contained" href={this.state.url} target="_blank" className={classes.leftButton}>
                <PageviewIcon className={classes.leftIcon} />
                {m.button_preview}
              </Button>
              <BriefDialog title={m.dialog_addBrief} form="liveTransfer_add" onCreate={this.handleCreate}>
                <Button variant="contained" color="primary">
                  <AddIcon className={classes.leftIcon} />
                  {m.button_add}
                </Button>
              </BriefDialog>
            </Toolbar>
            {tag && (
              <div className={classes.tagPanel}>
                <TagsPanels prefix={`${m.button_updatePageInfo} : `} collection={[tag]} />
              </div>
            )}
            <SearchFilter
              form="liveTransfers_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={liveTransfers}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
