import React from 'react';
import Showdown from 'showdown';
import { handlePreviewMarkdown } from '../../../../../common/components/inputs/TextEdit/utils';

interface IProps {
  content: string;
}
const converter = new Showdown.Converter({
  tables: true,
});
interface IState {
  html: string;
}

export class Content extends React.Component<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps) {
    return {
      html: converter.makeHtml(handlePreviewMarkdown(props.content)),
    };
  }
  public state = {
    html: '',
  };

  public shouldComponentUpdate(_: IProps, nextState: IState) {
    return nextState.html !== this.state.html;
  }

  public render() {
    const { html } = this.state;
    return <div dangerouslySetInnerHTML={{ __html: html }} className="comment__content__extra" />;
  }
}
