import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  content: {
    margin: theme.spacing(2),
  },
  item: {
    paddingRight: 88,
  },
});

@injectIntl
@withStyles(styles)
export default class Versions extends Component {
  state = {
    origin: 1,
    target: 0,
  };

  formatDate = date => moment(date).fromNow();

  selectOrigin = index => {
    this.setState({
      origin: index,
      target: Math.min(index - 1, this.state.target),
    });
  };

  selectTarget = index => {
    this.setState({
      target: index,
      origin: Math.max(index + 1, this.state.origin),
    });
  };

  render() {
    const {
      revision = {},
      classes,
      intl: { messages: m },
      onChange,
      currentOrigin,
      currentTarget,
    } = this.props;
    const { origin, target } = this.state;

    return [
      <List dense subheader={<ListSubheader>{m.revision_versions}</ListSubheader>}>
        {revision.list &&
          revision.list.map((rev, index) => (
            <ListItem key={rev.id} className={classes.item}>
              <ListItemText
                primary={`#${index + 1} ${this.formatDate(rev.createdAt)}`}
                secondary={rev.userName || 'user'}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  onChange={() => this.selectTarget(index)}
                  checked={target == index}
                  disabled={index == revision.list.length - 1}
                />
                <Checkbox
                  onChange={() => this.selectOrigin(index)}
                  checked={origin == index}
                  disabled={!index}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>,
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }} />
        <div className={classes.content}>
          <Button
            variant="contained"
            color="primary"
            className={classes.leftButton}
            onClick={() => onChange(target, origin)}
            disabled={currentOrigin == origin && currentTarget == target}
          >
            <CompareArrowsIcon className={classes.leftIcon} />
            {m.revision_seeDifferences}
          </Button>
        </div>
      </div>,
    ];
  }
}
