export default function reducer(
  state = {
    message: '',
    type: '',
  },
  action
) {
  if (action.type === 'ADD_NOTIFICATION') {
    return action.payload;
  } else if (action.type.includes('_REJECTED')) {

    if (!action.payload) {
      return {
        message: `[${action.type}] No response`,
        type: 'error',
      };
    }
    const { data } = action.payload;

    if (data && data.type === 'hydra:Error') {
      return {
        message: `[${action.type}] ${data.description}`,
        type: 'error',
      };
    }
    if (data.type === 'ConstraintViolationList') {
      return {
        message: `[${action.type}] ${data.description}`,
        type: 'error',
      };
    }

    return {
      message: action.payload.message || 'error',
      type: 'error',
    };
  } else if (action.type.includes('_NOTFOUND')) {
    return {
      message: '[NOTFOUND]',
      type: 'error',
    };
  } else return state;
}
