import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Alert } from "@material-ui/lab";
import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { getProject } from "../../../../common/services/project";
import ClubAvatar from "../../../clubs/Avatar";
import { ITeam } from "../../../teams/typings";
import {Button} from "@material-ui/core";

interface IComponentProps {
  tableData: ITeam;
  intl: any;
  classes: any;
}

const styles = () => ({
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
});

// @ts-ignore
@injectIntl
// @ts-ignore
@withStyles(styles)
// @ts-ignore
@connect()
export class TeamRow extends React.Component<IComponentProps> {
  public render() {
    const {
      tableData,
      classes,
      intl: { messages: m },
    } = this.props;

    return (
      <TableRow hover href={this.getHref(tableData.slug)} component="a">
        <TableCell>
          <div className={classes.rowContent}>
            <ClubAvatar club={tableData.club} />
            <div className={classes.label}>{tableData.name}</div>
          </div>
        </TableCell>
        <TableCell>
          <div>
            { tableData.mercatoBudget != null ? tableData.mercatoBudget : this.renderWarningBudget() }
          </div>
          <div>
            <Button style={{ marginRight: 8 }} href={`#${getProject().url}/teams/${tableData.slug}`} variant="contained" color="primary">
              {m.button_update}
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  private getHref(slug: string) {
    return `#${getProject().url}/teams/${slug}`;
  }

  private renderWarningBudget() {
    const {
      intl: { messages: m },
    } = this.props;

    return (
      <Alert severity="warning">{m.field_mercatoSurveyTeamWithoutBudget}</Alert>
    );
  }
}
