import React, { Component } from 'react';
import TypeSelect from "FD/stats/components/TypeSelect";


export default class TypeSelectField extends Component {
    render() {
        return (
            <TypeSelect
                name="statPlayerType"
                onChange={this.props.input.onChange}
                value={this.props.input.value}
                multiple={false}
            />
        )
    }

}
