import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import Button from '@material-ui/core/Button';

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.secondary[500]}`,
  },
});

@withStyles(styles)
@injectIntl
export default class ConfirmDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleConfirm = () => {
    this.handleClose();
    this.props.onConfirm();
  };

  render() {
    const {
      message,
      intl: { messages: m },
      classes,
      children,
    } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        {React.cloneElement(children, { onClick: this.handleOpen, key: 'open_button' })}
        <Dialog open={open} classes={{ paper: classes.body }}>
          <DialogTitle>{m.dialog_confirmTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button key="cancel" color="primary" onClick={this.handleClose}>
              {m.button_cancel}
            </Button>
            <Button key="confirm" color="secondary" onClick={this.handleConfirm}>
              {m.button_confirm}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
