import { FormEdit } from 'Components/FormEdit';
import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import { injectIntl } from "react-intl";
import SaveIcon from "@material-ui/icons/Save";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { closeEmbed } from "FM/articles/embed/Command/actions";
import { connect } from "react-redux";
import { buildUrl, getEmbedCode, getPathParameters, getQueryParameters } from "FM/articles/embed/utils";
import { fetchStatTypeOnce } from 'FD/stats/actions';

@injectIntl
@connect()
export class EmbedForm extends Component {

    state = {
        iframeUrl : '',
        embedCode : ''
    }

    componentDidMount() {
        const { dispatch, data } = this.props;
        dispatch(fetchStatTypeOnce());
        if (!data.config) {
            this.handleEmbedWithoutCustomField();
        }
    }
    render() {
        const {
            name,
            data,
            handleChangeTab,
            intl: { messages: m },
        } = this.props;
        const { iframeUrl, embedCode } = this.state

        return (
            <div>
                <DialogTitle key="dialog-title">{m[`embed_${name}`]}</DialogTitle>
                <DialogContent key="dialog-content" style={{ minHeight: '40vh' }} >
                    <Grid container spacing={2}>
                        {data.config && (
                          <Grid item xs={8}>
                              <FormEdit
                                key={data.id}
                                form={`embed_${data.id}`}
                                data={data}
                                config={data.config}
                                useUserRestriction
                                onSubmit={this.onSubmit}
                              />
                          </Grid>
                        )}
                        <Grid item xs={4}>
                            <Typography variant="h6">{m.editor_preview}</Typography>
                            { iframeUrl && <iframe frameBorder={0} height={460} src={iframeUrl} width={460} /> }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                    <Button variant="contained" onClick={() => handleChangeTab(0)} >
                        <ArrowBack />{m.button_back}
                    </Button>
                    <Button variant="contained" onClick={() => this.submitEmbedCode()} disabled={embedCode === ''}>
                        <SaveIcon />{m.button_validate}
                    </Button>
                </DialogActions>
            </div>

        );
    }

    handleEmbedWithoutCustomField = () => {
        const { data } = this.props;

        const pathParameters = undefined;
        const queryParameters = '';
        this.setState({ embedCode: getEmbedCode(pathParameters, queryParameters, data) });
        this.setState({ iframeUrl: buildUrl(data.path, pathParameters, queryParameters) });
}

    onSubmit = (formValues) => {
        const { data } = this.props;
        const pathParameters = data.pathParameters !== undefined ? getPathParameters(formValues, data) : undefined;
        const queryParameters = data.queryParameters !== undefined ? getQueryParameters(formValues,data) : '';
        this.setState({ embedCode: getEmbedCode(pathParameters, queryParameters, data) });
        this.setState({ iframeUrl: buildUrl(data.path, pathParameters, queryParameters) });
        return Promise.resolve();
    }
    submitEmbedCode = () => {
        const { embedCode } = this.state;
        const { dispatch,handleChangeTab } = this.props;
        handleChangeTab(0);
        return dispatch(closeEmbed(embedCode));
    }
}
