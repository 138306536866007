import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { fetchAllBrands } from '../actions';

@injectIntl
@connect(store => ({
  brands: store.brands,
}))
export class SelectBrand extends Component {
  componentDidMount() {
    const { dispatch, brands } = this.props;
    if (!brands.fetched) {
      dispatch(fetchAllBrands());
    }
  }

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error },
      intl: { formatMessage: f },
      labelKey,
      brands,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <FormControl fullWidth error={Boolean(touched && error)}>
        <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={event => onChange(event.target.value)}
          input={<Input id={`select_${label}`} />}
          MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
        >
          {brands.list.length ? (
            brands.list.map(brand => <MenuItem value={brand['@id']}>{brand.name}</MenuItem>)
          ) : (
            <MenuItem value={value}>{value}</MenuItem>
          )}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
