import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Row from '../List/Row';

@injectIntl
export default class NextMatches extends Component {
  render() {
    const {
      matches = [],
      intl: { messages: m },
    } = this.props;

    if (matches.length == 0) {
      return (
        <Typography style={{ padding: 24 }} variant="body1">
          {m.error_noData}
        </Typography>
      );
    }

    return (
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{m.field_date}</TableCell>
              <TableCell>{m.match_homeTeam}</TableCell>
              <TableCell>{m.match_awayTeam}</TableCell>
              <TableCell>{m.app_competition}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matches.map(g => (
              <Row tableData={g} />
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
