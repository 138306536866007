import { Button } from '@material-ui/core';
import React from 'react';
import { IComponentProps } from './InterruptionButtons.connector';
import {getProject} from "../../../../../common/services/project";

export class InterruptionButtons extends React.Component<IComponentProps> {
  public state = {
    isRequesting: false,
  };

  public render() {
    const {
      chronoStatus,
      isActivePeriod,
      classes,
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;

    const buttonDisabled = this.state.isRequesting || !isActivePeriod;
    const isCommentatorAllowed = mainCommentatorLocale != getProject().lang;
    return (
      <div className={classes.container}>
        <Button onClick={this.pause} disabled={!chronoStatus || buttonDisabled || !isCommentatorAllowed}>
          {m.button_pause}
        </Button>
        <Button onClick={this.resume} disabled={chronoStatus || buttonDisabled || !isCommentatorAllowed}>
          {m.button_resume}
        </Button>
      </div>
    );
  }

  private pause = () => {
    this.setState({ isRequesting: true });
    // @ts-ignore dispatch type error
    this.props.pauseLiveMatch().then(() => this.setState({ isRequesting: false }));
  };

  private resume = () => {
    this.setState({ isRequesting: true });
    // @ts-ignore dispatch type error
    this.props.resumeLiveMatch().then(() => this.setState({ isRequesting: false }));
  };
}
