import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ClubAvatar from '../../clubs/Avatar';

@injectIntl
export default class Teams extends Component {
  render() {
    const {
      club,
      intl: { messages: m },
    } = this.props;
    return (
      <List dense subheader={<ListSubheader>{m.app_club}</ListSubheader>}>
        <ListItem button component="a" href={`#${getProject().url}/clubs/${club.slug}`}>
          <ListItemIcon>
            <ClubAvatar club={club} />
          </ListItemIcon>
          <ListItemText primary={club.name} />
        </ListItem>
      </List>
    );
  }
}
