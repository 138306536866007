import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateCell from 'Components/TableList/cell/Date';
import NationalityCell from 'Components/TableList/cell/Nationality';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import ClubAvatar from '../../../clubs/Avatar';
import PersonAvatar from '../../Avatar';
import { getPersonUsualName } from '../../utils';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class StaffRow extends Component {
  getClub = () => {
    const {
      tableData: { club },
      classes,
    } = this.props;
    if (!club) {
      return '-';
    }
    return (
      <div className={classes.rowContent}>
        <ClubAvatar club={club} />
        <div className={classes.label}>{club.name}</div>
      </div>
    );
  };

  render() {
    const { classes, tableData } = this.props;
    const { birthDate, mainNationality, secondNationality, roleTranslated } = tableData;
    const href = `#${getProject().url}/staffs/${tableData.slug}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          <div className={classes.rowContent}>
            <PersonAvatar person={tableData} />
            <div className={classes.label}>
              <div>{getPersonUsualName(tableData)}</div>
              <div>{roleTranslated}</div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <NationalityCell main={mainNationality} second={secondNationality} />
        </TableCell>
        <TableCell>{this.getClub()}</TableCell>
        <TableCell>
          <DateCell date={birthDate} />
        </TableCell>
      </TableRow>
    );
  }
}
