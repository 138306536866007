import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import PlayerProfileButton from 'Components/PlayerProfileButton';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getCollection, getObject } from 'Services/connectors';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { addContract, fetchMembershipContracts } from '../../contracts/actions';
import { fetchMembershipTransfer, updateTransfer } from '../../transfers/actions';
import { fetchMembership, fetchPlayerMemberships, updateMembership } from '../actions';
import Contracts from './Contracts';
import Form from './Form';
import Memberships from './Memberships';
import Transfer from './Transfer';

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  membership: store.membership.detail[props.match.params.topicId],
  fetching: store.membership.fetching,
  contracts: getCollection('contractsIds', store.membership, store.contract),
  memberships: getCollection('membershipsIds', store.player, store.membership),
  transfer: getObject('transferId', store.membership, store.transfer),
}))
export default class Detail extends Component {
  menu = [
    { label: 'app_membershipInfo', link: '/' },
    { label: 'app_transfer', link: '/transfer' },
    { label: 'app_contracts', link: '/contracts' },
  ];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchMembership(targetId || id)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchMembershipTransfer(payload.id));
        dispatch(fetchMembershipContracts(payload.id));
        dispatch(fetchPlayerMemberships(payload.personId));
      }
    });
  };

  handleChange = membership => {
    const { dispatch, id } = this.props;
    return dispatch(updateMembership(id, membership));
  };
  handleTransferChange = id => transfer => {
    const { dispatch } = this.props;
    return dispatch(updateTransfer(id, transfer));
  };
  handleContractAdd = contract => {
    const { dispatch, membership } = this.props;
    return dispatch(addContract({ membership: membership['@id'], ...contract }, 'membership'));
  };

  getLabel = () => {
    const { membership } = this.props;
    return `${membership.personMatchName} - ${membership.clubShortName}`;
  };

  render() {
    const {
      membership,
      memberships,
      transfer,
      contracts,
      match,
      history,
      fetching,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!membership) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="membership-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_memberships' }, { label: this.getLabel() }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{this.getLabel()}</Typography>
              <div style={{ flex: 1 }} />
              {membership.personSlug && <PlayerProfileButton slug={membership.personSlug} />}
              <ReturnButton entity="players" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route exact path={match.url} render={() => <Form onSubmit={this.handleChange} data={membership} />} />

            <Route
              path={`${match.url}/transfer`}
              render={() =>
                transfer ? <Transfer transfer={transfer} onChange={this.handleTransferChange(transfer.id)} /> : null
              }
            />

            <Route
              path={`${match.url}/contracts`}
              render={() => (
                <Contracts
                  contracts={contracts}
                  onAdd={this.handleContractAdd}
                  url={`${match.url}/contracts`}
                  history={history}
                />
              )}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Memberships memberships={memberships} except={membership.id} />
          </Paper>
        }
      />
    );
  }
}
