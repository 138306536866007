import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DateCell from 'Components/TableList/cell/Date';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const styles = theme => ({
  highlight: {
    fontWeight: 'bold',
    color: theme.palette.primary[500],
  },
});

@withStyles(styles)
export default class Row extends Component {
  renderTitle = () => {
    const { tableData, classes, query } = this.props;
    const matches = match(tableData.title, query.filters.title);
    const parts = parse(tableData.title, matches);

    return parts.map((part, index) =>
      part.highlight ? (
        <span key={index} className={classes.highlight}>
          {part.text}
        </span>
      ) : (
        <span key={index}>{part.text}</span>
      )
    );
  };

  render() {
    if (!this.props.query.filters.title) return null;

    const { id, publishedAt } = this.props.tableData;
    const href = `#${getProject().url}/articles/${id}`;

    return (
      <TableRow href={href} component="a">
        <TableCell size="small">{this.renderTitle()}</TableCell>
        <TableCell size="small">
          <DateCell date={publishedAt} dateFormat="DD[/]MM[/]YYYY HH[:]mm[:]ss" />
        </TableCell>
      </TableRow>
    );
  }
}
