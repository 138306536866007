import rest from 'Services/restFootData';

export function fetchContract(id) {
  const params = {
    groups: ['read', '_agent'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CONTRACT' });
    return rest
      .get(`contracts/${id}`, { params })
      .then(
        res =>
          dispatch({ type: 'FETCH_CONTRACT_FULFILLED', payload: res.data }),
        err =>
          dispatch({ type: 'FETCH_CONTRACT_REJECTED', payload: err.response })
      );
  };
}

export function fetchMembershipContracts(membership) {
  const params = {
    membership,
    groups: ['read', '_agent'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MEMBERSHIP_CONTRACTS', payload: membership });

    return rest.get('contracts', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_MEMBERSHIP_CONTRACTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_MEMBERSHIP_CONTRACTS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function addContract(contract, target = '') {
  const entity = target ? `_${target.toUpperCase()}` : target;
  return function(dispatch) {
    dispatch({ type: `ADD${entity}_CONTRACT` });

    return rest.post('contracts', contract).then(
      res =>
        dispatch({
          type: `ADD${entity}_CONTRACT_FULFILLED`,
          payload: res.data,
        }),
      err =>
        dispatch({
          type: `ADD${entity}_CONTRACT_REJECTED`,
          payload: err.response,
        })
    );
  };
}

export function updateContract(id, contract) {
  const params = {
    groups: ['read', '_agent'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_CONTRACT', payload: id });

    return rest.put(`contracts/${id}`, contract, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_contractUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_CONTRACT_FULFILLED',
          payload: res.data,
        });
      },
      err => dispatch({
          type: 'UPDATE_CONTRACT_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function deleteContract(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_CONTRACT' });

    return rest
      .delete(`contracts/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_CONTRACT_FULFILLED', payload: { id } }),
        err =>
          dispatch({ type: 'DELETE_CONTRACT_REJECTED', payload: err.response })
      );
  };
}
