export default function reducer(
  state = {
    article: {
      adKV: {
        fetched: true,
        list: { ea: 'ea', g1: 'g1', g2: 'g2' },
      },
    },
    brief: {},
    pushFM: {
      system: {
        fetched: true,
        list: { '@PLATFORM_ANDROID': 'Android', '@PLATFORM_IOS': 'IOS' },
      },
      segment: {
        fetched: true,
        list: { global_news: 'Global', debug: 'Debug', preferences: 'Preferences' },
      },
    },
    pushFDA: {
      system: {
        fetched: true,
        list: { '@PLATFORM_ANDROID': 'Android', '@PLATFORM_IOS': 'IOS' },
      },
      segment: {
        fetched: true,
        list: { global_matches: 'Global', debug: 'Debug', preferences: 'Preferences' },
      },
    },
    focusBlock: {},
    articlesRelation : {},
    matchSide : {},
  },
  action
) {
  switch (action.type) {
    case 'FETCH_CONSTANTS': {
      const { entity, field } = action.payload;
      if (!state[entity]) {
        return state;
      }

      const updateEntity = {
        ...state[entity],
        [field]: {
          fetching: true,
        },
      };
      return {
        ...state,
        [entity]: updateEntity,
      };
    }

    case 'FETCH_CONSTANTS_FULFILLED': {
      const { entity, field, list } = action.payload;
      if (!state[entity]) {
        return state;
      }

      const updateEntity = {
        ...state[entity],
        [field]: {
          list,
          fetched: true,
          fetching: false,
        },
      };
      return {
        ...state,
        [entity]: updateEntity,
      };
    }

    default:
      return state;
  }
}
