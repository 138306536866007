import { WithStyles, withStyles } from '@material-ui/core/styles';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { ISide } from '../../../../matches/typings';
import {
  selectMatchTeamPlayersGroupedByPosition,
  selectSelectedMatchTeamPlayer,
  selectUsedShirtNumbers,
} from '../../selector';
import { styles } from './styles';

const mapDispatchToProps = {};
interface IOwnProps {
  side: ISide;
  showAllMemberships: boolean;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const [
    matchTeamPlayersGroupedByPosition,
    coaches,
  ] = selectMatchTeamPlayersGroupedByPosition(
    state,
    ownProps.side,
    ownProps.showAllMemberships
  );

  return {
    matchTeamPlayersGroupedByPosition,
    coaches,
    selectedMatchTeamPlayer: selectSelectedMatchTeamPlayer(state, ownProps.side),
    usedShirtNumbers: selectUsedShirtNumbers(state, ownProps.side),
  };
};

export type IComponentProps = WithStyles &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
