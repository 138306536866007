import { Breadcrumbs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { IComponentProps, ICrumb } from './index';

export class SimpleBreadcrumbs extends Component<IComponentProps> {
  public render = () => {
    const { crumbStack = [] } = this.props;

    return (
      <Breadcrumbs aria-label="breadcrumb">
        {crumbStack.map(this.renderCrumb)}
      </Breadcrumbs>
    );
  };

  private renderCrumb = ({ label, labelKey, href }: ICrumb) => {
    const {
      intl: { messages: m },
    } = this.props;
    const title = labelKey ? m[labelKey] : label;

    return (
      <Button variant="contained" href={href} key={title} disabled={!href}>
        {title}
      </Button>
    );
  };
}
