import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

export function fetchTeamTeamSeasons(team) {
  const params = {
    team,
    pagination: 0,
    groups: ['read', '_image'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_TEAM_TEAMSEASONS' });

    return rest.get(`team_seasons`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_TEAM_TEAMSEASONS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_TEAM_TEAMSEASONS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateTeamSeason(id, teamSeason) {
  const params = {
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_TEAMSEASON', payload: id });
    return rest.put(`team_seasons/${id}`, teamSeason, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_teamUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_TEAMSEASON_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_TEAMSEASON_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}
