import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import filterList from '../../../common/actions/lists';
import { parseQuery } from '../../../common/services/parseQuery';
import { IRootState } from '../../../typings';
import { addMercatoSurvey, fetchMercatoSurveys } from '../actions';

type IOwnProps = RouteComponentProps;

const mapDispatchToProps = {
  fetchMercatoSurveys,
  addMercatoSurvey,
  filterList,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  me: state.me,
  mercatoSurveys: state.mercatoSurveyList.list,
  pagination: state.mercatoSurveyList.pagination,
  fetching: state.mercatoSurveyList.fetching,
  total: state.mercatoSurveyList.totalItems,
  query: parseQuery(ownProps.location.search),
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
