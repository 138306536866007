import * as React from 'react';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { MatchTeamPlayerCell } from '../MatchTeamPlayerCell';
import { IComponentProps } from './CompositionCell.connector';

export class CompositionCell extends React.Component<IComponentProps> {
  public render() {
    const { matchTeamPlayer, side, disabled, positionSide, position } = this.props;
    return (
      <MatchTeamPlayerCell
        title={disabled ? undefined : `${positionSide} ${position}`}
        matchTeamPlayer={matchTeamPlayer}
        side={side}
        onAdd={this.onAdd}
        onDelete={this.onDelete}
        onSwap={this.onSwap}
        disabled={disabled}
      />
    );
  }

  private onAdd = () => {
    const { side, position, positionSide, addMatchTeamPlayerOnComposition } = this.props;
    addMatchTeamPlayerOnComposition(side, position, positionSide);
  };

  private onDelete = () => {
    const {
      side,
      position,
      positionSide,
      removeMatchTeamPlayerFromComposition,
    } = this.props;
    removeMatchTeamPlayerFromComposition(side, position, positionSide);
  };

  private onSwap = () => {
    const {
      side,
      position,
      positionSide,
      matchTeamPlayer,
      swapMatchTeamPlayerFromComposition,
    } = this.props;

    swapMatchTeamPlayerFromComposition(
      side,
      position,
      positionSide,
      matchTeamPlayer as IMatchTeamPlayer
    );
  };
}
