import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { IRootState } from '../../../typings';
import { getQuizz, updateQuizz } from '../actions';
import { getAtInternetStat } from "../Stats/action";

type IOwnProps = RouteComponentProps<{ topicId: string }>;

const mapDispatchToProps = {
    fetchQuizz: getQuizz,
    fetchAnalyticStat: getAtInternetStat,
    updateQuizz,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
    const id = ownProps.match.params.topicId;
    return {
        id,
        quizz: state.quizz.detail[id],
        fetching: state.survey.fetching,
        me: state.me,
    };
};

export type IComponentProps = typeof mapDispatchToProps &
    ReturnType<typeof mapStateToProps> &
    InjectedIntlProps &
    IOwnProps;

const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
    reduxConnector,
    injectIntl
);
