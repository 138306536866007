import rest from 'Services/restFootData';
import qs from 'query-string';
import { SubmissionError } from 'redux-form';

export function fetchTournament(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_TOURNAMENT' });
    return rest
      .get(`tournaments/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_TOURNAMENT_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TOURNAMENT_REJECTED', payload: err.response })
      );
  };
}

export function fetchTournaments(search) {
  const params = {
    itemsPerPage: 10,
    partial: 0,
    ...qs.parse(search),
    groups: ['read'],
    "order[competition.translations.priority]": "asc",
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TOURNAMENTS' });
    return rest.get('tournaments', { params }).then(
      res => dispatch({ type: 'FETCH_TOURNAMENTS_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_TOURNAMENTS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchCompetitionTournaments(competition) {
  const params = {
    competition,
    pagination: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_COMPETITION_TOURNAMENTS', payload: competition });

    return rest.get('tournaments', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_COMPETITION_TOURNAMENTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_COMPETITION_TOURNAMENTS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateTournament(id, tournament) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_TOURNAMENT', payload: id });
    return rest.put(`tournaments/${id}`, tournament).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_tournamentUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_TOURNAMENT_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_TOURNAMENT_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}
