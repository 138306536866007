import rest, { restFr, restEs, restDe } from 'Services/restFootMercato';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchAuthors(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_AUTHORS' });

    return rest
      .get('authors', { params })
      .then(
        res => dispatch({ type: 'FETCH_AUTHORS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_AUTHORS_REJECTED', payload: err.response })
      );
  };
}

export function fetchAuthorMe(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'FETCH_AUTHORS' });

    return rest.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_AUTHOR_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_AUTHOR_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_AUTHOR_REJECTED', payload: err.response })
    );
  };
}

export function fetchAuthor(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_AUTHOR' });
    return rest
      .get(`authors/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_AUTHOR_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_AUTHOR_REJECTED', payload: err.response })
      );
  };
}

export function updateAuthor(id, author) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_AUTHOR', payload: id });
    return rest.put(`authors/${id}`, author).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_authorUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_AUTHOR_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_AUTHOR_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addAuthor(author) {
  return function(dispatch) {
    dispatch({ type: 'ADD_AUTHOR' });
    return rest.post('authors', author).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            messageKey: 'message_authorCreated',
            type: 'success',
          },
        });
        return dispatch({ type: 'ADD_AUTHOR_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'ADD_AUTHOR_REJECTED', payload: err.response })
    );
  };
}

export function deleteAuthor(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_AUTHOR', payload: id });
    return rest
      .delete(`authors/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_AUTHOR_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_AUTHOR_REJECTED', payload: err.response })
      );
  };
}

export function activateAuthorFr(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'GET_AUTHOR_FR' });
    return restFr.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          dispatch({ type: 'ACTIVATE_AUTHOR_FR' });
          return restFr
            .put(`authors/${res.data.collection[0].id}`, { isActive: true })
            .then(
              () => dispatch({ type: 'ACTIVATE_AUTHOR_FR_FULFILLED' }),
              err =>
                dispatch({
                  type: 'ACTIVATE_AUTHOR_FR_REJECTED',
                  payload: err.response,
                })
            );
        } else {
          dispatch({ type: 'CREATE_AUTHOR_FR' });
          return restFr
            .post(`authors`, {
              username,
              publicName: username,
              isActive: true,
            })
            .then(
              () => dispatch({ type: 'CREATE_AUTHOR_FR_FULFILLED' }),
              err =>
                dispatch({
                  type: 'CREATE_AUTHOR_FR_REJECTED',
                  payload: err.response,
                })
            );
        }
      },
      err => dispatch({ type: 'GET_AUTHOR_FR_REJECTED', payload: err.response })
    );
  };
}

export function activateAuthorEs(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'GET_AUTHOR_ES' });
    return restEs.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          dispatch({ type: 'ACTIVATE_AUTHOR_ES' });
          return restEs
            .put(`authors/${res.data.collection[0].id}`, { isActive: true })
            .then(
              () => dispatch({ type: 'ACTIVATE_AUTHOR_ES_FULFILLED' }),
              err =>
                dispatch({
                  type: 'ACTIVATE_AUTHOR_ES_REJECTED',
                  payload: err.response,
                })
            );
        } else {
          dispatch({ type: 'CREATE_AUTHOR_ES' });
          return restEs
            .post(`authors`, {
              username,
              publicName: username,
              isActive: true,
            })
            .then(
              () => dispatch({ type: 'CREATE_AUTHOR_ES_FULFILLED' }),
              err =>
                dispatch({
                  type: 'CREATE_AUTHOR_ES_REJECTED',
                  payload: err.response,
                })
            );
        }
      },
      err => dispatch({ type: 'GET_AUTHOR_ES_REJECTED', payload: err.response })
    );
  };
}

export function activateAuthorDe(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'GET_AUTHOR_DE' });

    return restDe.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          dispatch({ type: 'ACTIVATE_AUTHOR_DE' });
          return restDe
            .put(`authors/${res.data.collection[0].id}`, { isActive: true })
            .then(
              () => dispatch({ type: 'ACTIVATE_AUTHOR_DE_FULFILLED' }),
              err =>
                dispatch({
                  type: 'ACTIVATE_AUTHOR_DE_REJECTED',
                  payload: err.response,
                })
            );
        } else {
          dispatch({ type: 'CREATE_AUTHOR_DE' });
          return restDe
            .post(`authors`, {
              username,
              publicName: username,
              isActive: true,
            })
            .then(
              () => dispatch({ type: 'CREATE_AUTHOR_DE_FULFILLED' }),
              err =>
                dispatch({
                  type: 'CREATE_AUTHOR_DE_REJECTED',
                  payload: err.response,
                })
            );
        }
      },
      err => dispatch({ type: 'GET_AUTHOR_DE_REJECTED', payload: err.response })
    );
  };
}

export function deactivateAuthorFr(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'GET_AUTHOR_FR' });
    return restFr.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          dispatch({ type: 'DEACTIVATE_AUTHOR_FR' });
          return restFr
            .put(`authors/${res.data.collection[0].id}`, { isActive: false })
            .then(
              () => dispatch({ type: 'DEACTIVATE_AUTHOR_FR_FULLFILLED' }),
              err =>
                dispatch({
                  type: 'DEACTIVATE_AUTHOR_FR_REJECTED',
                  payload: err.response,
                })
            );
        }
        return dispatch({ type: 'GET_AUTHOR_FR_NOTFOUND' });
      },
      err => dispatch({ type: 'GET_AUTHOR_FR_REJECTED', payload: err.response })
    );
  };
}

export function deactivateAuthorDe(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'GET_AUTHOR_DE' });
    return restDe.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          dispatch({ type: 'DEACTIVATE_AUTHOR_DE' });
          return restDe
            .put(`authors/${res.data.collection[0].id}`, { isActive: false })
            .then(
              () => dispatch({ type: 'DEACTIVATE_AUTHOR_DE_FULLFILLED' }),
              err =>
                dispatch({
                  type: 'DEACTIVATE_AUTHOR_DE_REJECTED',
                  payload: err.response,
                })
            );
        }
        return dispatch({ type: 'GET_AUTHOR_DE_NOTFOUND' });
      },
      err => dispatch({ type: 'GET_AUTHOR_DE_REJECTED', payload: err.response })
    );
  };
}

export function deactivateAuthorEs(username) {
  const params = { username };
  return function(dispatch) {
    dispatch({ type: 'GET_AUTHOR_ES' });
    return restEs.get('authors', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          dispatch({ type: 'DEACTIVATE_AUTHOR_ES' });
          return restEs
            .put(`authors/${res.data.collection[0].id}`, { isActive: false })
            .then(
              () => dispatch({ type: 'DEACTIVATE_AUTHOR_ES_FULLFILLED' }),
              err =>
                dispatch({
                  type: 'DEACTIVATE_AUTHOR_ES_REJECTED',
                  payload: err.response,
                })
            );
        }
        return dispatch({ type: 'GET_AUTHOR_ES_NOTFOUND' });
      },
      err => dispatch({ type: 'GET_AUTHOR_ES_REJECTED', payload: err.response })
    );
  };
}
