import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTournament } from './actions';

@connect((store, props) => ({
  tournament: store.tournament.detail[props.id],
}))
export default class TournamentCell extends Component {
  componentDidMount() {
    const { dispatch, id } = this.props;
    return dispatch(fetchTournament(id));
  }
  render() {
    const { tournament } = this.props;
    if (!tournament) {
      return '-';
    }
    return (
      <span>{`${tournament.competitionName || ''} - ${tournament.name ||
        ''} (${tournament.competitionAreaSlug || ''})`}</span>
    );
  }
}
