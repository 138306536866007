import { notifySuccessAction } from '../../common/actions/notifications';
import rest from '../../common/services/restFootData';
import { IMatchTeam } from './typings';

const entityRoute = 'match_teams';

export const updateMatchTeam = (id: string, matchTeam: Partial<IMatchTeam>) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_MATCHTEAM' });

    return rest.put(`${entityRoute}/${id}`, matchTeam).then(
      res => {
        dispatch(notifySuccessAction('message_matchEventUpdated'));
        return dispatch({ type: 'UPDATE_MATCHTEAM_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'UPDATE_MATCHTEAM_REJECTED', payload: err.response })
    );
  };
};
