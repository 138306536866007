import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchImages(search) {
  const queriesString = qs.parse(search);
  const params = {
    groups: ['read', '_search'],
    itemsPerPage: 50,
    ...queriesString,
    partial: 0,
    fuzzy: queriesString.fuzzy || 0,
    hdOnly: queriesString.hdOnly || 0,
  };
  if (params.filename) {
    // filename need encoded value
    params.filename = encodeURIComponent(params.filename);
  }

  return function(dispatch) {
    dispatch({ type: 'FETCH_IMAGES' });

    return rest
      .get('search/images/', { params })
      .then(
        res => dispatch({ type: 'FETCH_IMAGES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_IMAGES_REJECTED', payload: err.response })
      );
  };
}

export function fetchImage(id) {
  const params = {
    groups: ['read', '_person', '_club', '_image', '_search'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_IMAGE' });
    return rest
      .get(`images/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_IMAGE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_IMAGE_REJECTED', payload: err.response })
      );
  };
}

export function updateImage(id, image) {
  const params = {
    groups: ['read', '_person', '_club', '_image', '_search'],
    useCache: true,
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_IMAGE', payload: id });
    return rest.put(`images/${id}`, image, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_imageUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_IMAGE_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_IMAGE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addImage({ filename, file, description }) {
  const data = new FormData();
  data.append('filename', filename || file.name);
  data.append('file', file);
  data.append('description', description);
  data.append('useCache', true);
  return function(dispatch) {
    dispatch({ type: 'ADD_IMAGE' });
    return rest
      .post('images', data, {
        headers: {
          'Content-Type': 'multiform/data',
          Accept: 'application/ld+json',
        },
      })
      .then(
        res => dispatch({ type: 'ADD_IMAGE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_IMAGE_REJECTED', payload: err.response })
      );
  };
}

export function deleteImage(id, successCallBack) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_IMAGE' });

    return rest.delete(`images/delete-file/${id}`).then(
      () => {
        dispatch({ type: 'DELETE_IMAGE_FULFILLED', payload: { id } });
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'image_deleted', type: 'success' },
        });

        if (successCallBack) {
          successCallBack();
        }
      },
      err => {
        dispatch({ type: 'DELETE_IMAGE_REJECTED' });
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: err.response.data.message, type: 'error' },
        });
      }
    );
  };
}
