import rest from 'Services/restFootData';
import qs from 'query-string';

export function fetchCorrections(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CORRECTIONS' });

    return rest.get('corrections', { params }).then(
      res =>
        dispatch({ type: 'FETCH_CORRECTIONS_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_CORRECTIONS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchCorrection(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CORRECTION' });
    return rest
      .get(`corrections/${id}`)
      .then(
        res =>
          dispatch({ type: 'FETCH_CORRECTION_FULFILLED', payload: res.data }),
        err =>
          dispatch({ type: 'FETCH_CORRECTION_REJECTED', payload: err.response })
      );
  };
}

export function deleteCorrection(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_CORRECTION', payload: id });

    return rest.delete(`corrections/${id}`).then(
      () => dispatch({ type: 'DELETE_CORRECTION_FULFILLED', payload: { id } }),
      err =>
        dispatch({
          type: 'DELETE_CORRECTION_REJECTED',
          payload: err.response,
        })
    );
  };
}
