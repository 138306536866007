import rest from 'Services/restFootMercato';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchTags(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TAGS' });

    return rest
      .get('tags', { params })
      .then(
        res => dispatch({ type: 'FETCH_TAGS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TAGS_REJECTED', payload: err.response })
      );
  };
}

export function fetchTagSlug(slug) {
  const params = { slug };
  return function(dispatch) {
    dispatch({ type: 'FETCH_TAG' });

    return rest.get('tags', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_TAG_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_TAG_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_TAG_REJECTED', payload: err.response })
    );
  };
}

export function searchTags(params) {
  return function(dispatch) {
    dispatch({ type: 'SEARCH_TAGS' });
    return rest
      .get('tags', { params })
      .then(
        res => dispatch({ type: 'SEARCH_TAGS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_TAGS_REJECTED', payload: err.response })
      );
  };
}

export function getTagId(id) {
  return function(dispatch) {
    dispatch({ type: 'GET_TAG' });

    return rest
      .get(`tags/${id}`)
      .then(
        res => dispatch({ type: 'GET_TAG_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_TAG_REJECTED', payload: err.response })
      );
  };
}

export function updateTag(id, tag) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_TAG', payload: id });
    return rest.put(`tags/${id}`, tag).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_tagUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_TAG_FULFILLED', payload: res.data });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { description, errors } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: description, type: 'error' },
          });
          throw new SubmissionError(errors);
        }
        return dispatch({ type: 'UPDATE_TAG_REJECTED', payload: err.response });
      }
    );
  };
}

export function addTag(tag) {
  return function(dispatch) {
    dispatch({ type: 'ADD_TAG' });

    return rest
      .post('tags', tag)
      .then(
        res => dispatch({ type: 'ADD_TAG_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_TAG_REJECTED', payload: err.response })
      );
  };
}

export function deleteTag(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_TAG', payload: id });
    return rest
      .delete(`tags/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_TAG_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_TAG_REJECTED', payload: err.response })
      );
  };
}
