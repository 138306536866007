import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

const groups = ['read'];

export function getPartner(id) {
  const params = {
    groups,
  };
  return function(dispatch) {
    dispatch({ type: 'GET_PARTNER' });

    return rest
      .get(`partners/${id}`, { params })
      .then(
        res => dispatch({ type: 'GET_PARTNER_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_PARTNER_REJECTED', payload: err.response })
      );
  };
}

export function addPartner(partner) {
  return function(dispatch) {
    dispatch({ type: `ADD_PARTNER` });

    return rest.post('partners', partner).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerAdded', type: 'success' },
        });
        return dispatch({
          type: `ADD_PARTNER_FULFILLED`,
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerAdd_error', type: 'error' },
        });
        return dispatch({
          type: `ADD_PARTNER_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}

export function fetchPartners(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PARTNER_LIST' });

    return rest
      .get('partners', { params })
      .then(
        res => dispatch({ type: 'FETCH_PARTNER_LIST_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PARTNER_LIST_REJECTED', payload: err.response })
      );
  };
}

export function updatePartner(id, partner) {
  const params = {
    groups,
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_PARTNER', payload: id });

    return rest.put(`partners/${id}`, partner, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_PARTNER_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PARTNER_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchPartnerOffers(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    'order[updatedAt]': 'DESC',
    groups: ['read', '_partner', '_competition'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PARTNER_OFFER_LIST' });

    return rest
        .get('partner_offers', { params })
        .then(
            res => dispatch({ type: 'FETCH_PARTNER_OFFER_LIST_FULFILLED', payload: res.data }),
            err => dispatch({ type: 'FETCH_PARTNER_OFFER_LIST_REJECTED', payload: err.response })
        );
  };
}

export function fetchPartnerOffersByPartner(partnerId, locale) {
  const params = {
    'partner.id': partnerId,
    locale,
    'order[updatedAt]': 'DESC',
    groups: ['read', '_competition'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PARTNER_OFFER_BY_PARTNER_LIST', payload: partnerId });

    return rest.get('partner_offers', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PARTNER_OFFER_BY_PARTNER_LIST_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PARTNER_OFFER_BY_PARTNER_LIST_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function addPartnerOffer(partnerOffer) {
  return function(dispatch) {
    dispatch({ type: 'ADD_PARTNER_OFFER' });

    return rest.post('partner_offers', partnerOffer).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerOfferAdded', type: 'success' },
        });
        return dispatch({
          type: `ADD_PARTNER_OFFER_FULFILLED`,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerOfferAdd_error', type: 'error' },
        });
        return dispatch({
          type: `ADD_PARTNER_OFFER_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}

export function updatePartnerOffer(id, partnerOffer) {
  const params = {
    groups: ['write', 'read', '_competition'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_PARTNER_OFFER' });

    return rest.put(`partner_offers/${id}`, partnerOffer, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerOfferUpdated', type: 'success' },
        });
        return dispatch({
          type: `UPDATE_PARTNER_OFFER_FULFILLED`,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_partnerOfferUpdated_error', type: 'error' },
        });
        return dispatch({
          type: `UPDATE_PARTNER_OFFER_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}
