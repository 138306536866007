import { FormEdit } from 'Components/FormEdit';
import { maxLength, required } from 'Components/FormEdit/validator';
import Checkbox, { normalizeCheckbox } from 'Components/inputs/Checkbox';
import DatetimeInput, { formatUtc } from 'Components/inputs/Datetime';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import TextInput from 'Components/inputs/Text';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import { SurveyInput } from 'FD/surveys/components/SurveyInput';
import { getLinkId } from 'Services/tools';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import TextEdit, { NewTextEdit } from '../../../common/components/inputs/TextEdit';
import { hasAccessToRestrictedArticles } from '../../../common/services/me';
import { CompetitionInputCollection } from '../../../footdata/competitions/CompetitionInputCollection';
import ImageInput from '../../../footdata/images/Input';
import { MatchInputCollection } from '../../../footdata/matches/MatchInputCollection';
import { PersonInputCollection } from '../../../footdata/people/PersonInputCollection';
import { TeamInputCollection } from '../../../footdata/teams/TeamInputCollection';
import { AuthorsInputCollection } from '../../authors/InputCollection';
import SelectConstant from '../../components/constants/Select';
import { TagsInputCollection } from '../../tags/InputCollection';
import { SelectArticleStatus } from '../components/SelectArticleStatus';
@connect((store, props) => ({
  formValues: getFormValues(`article_${props.data.id}`)(store),
  isNewEditorEnabled: store.me.isNewEditorEnabled,
}))
export default class ContentForm extends Component {
  config = isNewEditorEnabled => ({
    fields: [
      'id',
      'adKV',
      'content',
      'deviceRestriction',
      'fixedPosition',
      'fixedPositionStartAt',
      'fixedPositionEndAt',
      'footdataMainImageId',
      'hasVideo',
      'isFeatured',
      'isCommentDisabled',
      'isLive',
      'isNoIndex',
      'lead',
      'neededNewRedirection',
      'postScriptum',
      'publishedAt',
      'redirectUrl',
      'seoMetaDescription',
      'seoMetaTitle',
      'seoText',
      'slug',
      'specificity',
      'status',
      'title',
      'type',
      'unpublishedAt',
      'isHomepageDisabled',
      'footdataCompetitionIds',
      'footdataTeamIds',
      'footdataMatchIds',
      'footdataPersonIds',
      'footdataSurveyId',
      'opinionText',
      'opinionLevel',
      'source',
      'useBrief',
      'coverVideoUrl',
    ],
    collectionObjectFields: ['authors', 'tags'],
    disposition: [
      {
        fields: [
          {
            name: 'title',
            component: TextInput,
            validate: value => required(value) || maxLength(250)(value),
          },
          {
            name: 'neededNewRedirection',
            component: Checkbox,
            normalize: normalizeCheckbox,
            helperKey: 'field_neededNewRedirectionHelper',
            size: 6,
          },
        ],
      },
      {
        size: 3,
        fields: [
          {
            name: 'footdataMainImageId',
            component: ImageInput,
            normalize: getLinkId,
          },
        ],
      },
      {
        size: 9,
        fields: [
          { name: 'status', component: SelectArticleStatus },
          {
            name: 'publishedAt',
            component: DatetimeInput,
            normalize: formatUtc,
            required: this.props.data.status === 'publish',
          },
          {
            name: 'unpublishedAt',
            component: DatetimeInput,
            normalize: formatUtc,
          },
          { name: 'type', component: SelectConstant, entity: 'article' },
          {
            name: 'isFeatured',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 4,
          },
          {
            name: 'isLive',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 4,
          },
          {
            name: 'useBrief',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 4,
          },
          {
            name: 'hasVideo',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 4,
          },
          {
            name: 'isHomepageDisabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 4,
          },
          {
            name: 'isCommentDisabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 4,
          },
        ],
      },
      {
        fields: [
          { name: 'lead', component: TextInput, multiline: true },
          {
            name: 'content',
            component: isNewEditorEnabled ? NewTextEdit : TextEdit,
            mode: 'article',
          },
        ],
      },
      {
        websiteOnly: ['footmercato_de'],
        title: 'form_opinion',
        fields: [
          { name: 'opinionText', component: TextEdit, mode: 'article' },
          {
            name: 'opinionLevel',
            component: SelectConstant,
            nullable: true,
            entity: 'article',
          },
          { name: 'source', component: TextInput },
        ],
      },
      {
        fields: [{ name: 'postScriptum', component: TextInput, multiline: true }],
      },
      {
        title: 'form_linkedData',
        fields: [
          { name: 'authors', component: AuthorsInputCollection, size: 6 },
          { name: 'tags', component: TagsInputCollection, size: 6 },
          {
            name: 'footdataCompetitionIds',
            component: CompetitionInputCollection,
            size: 6,
          },
          { name: 'footdataTeamIds', component: TeamInputCollection, size: 6 },
          {
            name: 'footdataMatchIds',
            component: MatchInputCollection,
            size: 6,
          },
          { name: 'footdataPersonIds', component: PersonInputCollection, size: 6 },
          { name: 'coverVideoUrl', component: TextInput, size: 6 },
          { name: 'footdataSurveyId', component: SurveyInput, size: 6, labelKey: 'field_survey' },
        ],
      },
      {
        size: 6,
        title: 'form_config',
        fields: [
          {
            name: 'deviceRestriction',
            component: SelectConstant,
            entity: 'article',
            nullable: true,
          },
          {
            name: 'specificity',
            component: SelectConstant,
            entity: 'article',
            nullable: true,
          },
          {
            name: 'adKV',
            component: SelectConstant,
            entity: 'article',
            nullable: true,
          },
          {
            name: 'redirectUrl',
            component: TextInput,
            hintText: 'http://',
            helperKey: 'article_redirect',
          },
        ],
      },
      {
        size: 6,
        title: 'form_fixedPosition',
        fields: [
          {
            name: 'fixedPosition',
            component: IntInput,
            normalize: normalizeInt,
          },
          {
            name: 'fixedPositionStartAt',
            component: DatetimeInput,
            normalize: formatUtc,
          },
          {
            name: 'fixedPositionEndAt',
            component: DatetimeInput,
            normalize: formatUtc,
            validate: this.validateFixedPositionEndAt,
            alwaysDisplayError: true,
          },
        ],
      },
      {
        size: 6,
        title: 'form_seo',
        fields: [
          { name: 'seoMetaTitle', component: TextInput },
          { name: 'seoText', component: TextInput },
          { name: 'seoMetaDescription', component: TextInput, multiline: true },
          { name: 'isNoIndex', component: Toggle, normalize: normalizeBoolean },
        ],
      },
    ],
  });

  render() {
    const { data, onSubmit, isNewEditorEnabled } = this.props;

    if (data.status === 'restricted' && !hasAccessToRestrictedArticles()) {
      return null;
    }

    return (
      <FormEdit
        entity="ArticleDetail" // used for tests
        key={data.id}
        form={`article_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config(isNewEditorEnabled)}
        main
      />
    );
  }

  validateFixedPositionEndAt = (value, allValues) => {
    if (allValues.fixedPosition) {
      return !value && 'error_required';
    }
  };
}
