import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'TRANSFER',
  inside: {
    player: { get: 'transfers' },
  },
  of: {
    membership: { get: 'transfer' },
  },
});
