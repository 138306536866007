import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchPlayersWithClub(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    role: 'player',
    'current_team[role]': 'player', // needed for current_team[player]
    partial: 0,
    'order[translations.priority]': 'asc',
    groups: ['read', '_image', '_club'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYERS' });

    return rest
      .get(`people/with_club/`, { params })
      .then(
        res => dispatch({ type: 'FETCH_PLAYERS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PLAYERS_REJECTED', payload: err.response })
      );
  };
}

export function fetchPlayers(search) {
  const params = {
    ...qs.parse(search),
    role: 'player',
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYERS' });

    return rest
      .get('people', { params })
      .then(
        res => dispatch({ type: 'FETCH_PLAYERS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PLAYERS_REJECTED', payload: err.response })
      );
  };
}

export function fetchPlayer(slug) {
  const params = {
    slug,
    // commenting this allows to display player tabs (on direct access to /players/slug) for people that were players and are now agent/staff/coach/etc.
    // role: 'player',
    groups: ['read', '_image', '_club', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYER' });

    return rest.get('people/with_club/', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_PLAYER_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_PLAYER_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_PLAYER_REJECTED', payload: err.response })
    );
  };
}

export function updatePlayer(id, player) {
  const params = {
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_PLAYER', payload: id });
    return rest.put(`people/${id}`, player, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_playerUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_PLAYER_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PLAYER_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function deletePlayer(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_PLAYER', payload: id });

    return rest
      .delete(`people/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_PLAYER_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_PLAYER_REJECTED', payload: err.response })
      );
  };
}

export function fetchAgentPlayers(agent) {
  const params = {
    agent,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_AGENT_PLAYERS', payload: agent });

    return rest.get('people', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_AGENT_PLAYERS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_AGENT_PLAYERS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchAgentCompanyPlayers(agentCompany) {
  const params = {
    agentCompany,
    role: 'player',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_AGENTCOMPANY_PLAYERS', payload: agentCompany });

    return rest.get('people', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_AGENTCOMPANY_PLAYERS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_AGENTCOMPANY_PLAYERS_REJECTED',
          payload: err.response,
        })
    );
  };
}
