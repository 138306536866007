import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from 'Services/project';
import ClubAvatar from '../Avatar';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class Row extends Component {
  render() {
    const { classes, tableData } = this.props;
    const { name, country, competitionName } = tableData;
    const href = `#${getProject().url}/clubs/${tableData.slug}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          <div className={classes.rowContent}>
            <ClubAvatar club={tableData} />
            <div className={classes.label}>{name}</div>
          </div>
        </TableCell>
        <TableCell>{country && country.name}</TableCell>
        <TableCell>{competitionName}</TableCell>
      </TableRow>
    );
  }
}
