import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  list: {
    maxHeight: 600,
    position: 'relative' as 'relative',
    overflow: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[200],
    lineHeight: '31px',
    textTransform: 'capitalize' as 'capitalize',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
});
