import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchTransfers(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read', '_transfer_teams', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TRANSFERS' });
    return rest
      .get('transfers', { params })
      .then(
        res => dispatch({ type: 'FETCH_TRANSFERS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TRANSFERS_REJECTED', payload: err.response })
      );
  };
}

export function fetchTransfer(id) {
  const params = {
    groups: ['read', '_transfer_teams'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_TRANSFER' });
    return rest
      .get(`transfers/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_TRANSFER_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TRANSFER_REJECTED', payload: err.response })
      );
  };
}

export function updateTransfer(id, transfer) {
  const params = {
    groups: ['read', '_transfer_teams', '_image'],
  };
  return function(dispatch) {
    dispatch({ type: 'UPDATE_TRANSFER' });
    return rest.put(`transfers/${id}`, transfer, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_transferUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_TRANSFER_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_TRANSFER_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchPlayerTransfers(person) {
  const params = {
    person,
    groups: ['read', '_transfer_teams', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYER_TRANSFERS', payload: person });
    return rest.get('transfers', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PLAYER_TRANSFERS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PLAYER_TRANSFERS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchMembershipTransfer(membership) {
  const params = { membership };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MEMBERSHIP_TRANSFER' });
    return rest.get('transfers', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_MEMBERSHIP_TRANSFER_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        // return dispatch({ type: "FETCH_MEMBERSHIP_TRANSFER_NOTFOUND" })
      },
      err =>
        dispatch({
          type: 'FETCH_MEMBERSHIP_TRANSFER_REJECTED',
          payload: err.response,
        })
    );
  };
}
