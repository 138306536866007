import { withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { pauseLiveMatch, resumeLiveMatch } from '../../actions/matchActions';
import { selectIsActiveMatchPeriod } from '../../selector';
import { styles } from './styles';

const mapDispatchToProps = {
  pauseLiveMatch,
  resumeLiveMatch,
};

interface IOwnProps {}

const mapStateToProps = (state: IRootState) => ({
  isActivePeriod: selectIsActiveMatchPeriod(state),
  chronoStatus: state.live.chronoStatus,
  mainCommentatorLocale: state.live.mainCommentatorLocale,
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  WithStyles &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
