import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PartnerDetail } from './Detail';
import { PartnersList } from './List';
import { PartnerOffersList } from "./components/PartnerOffersList";

export const PartnersRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={PartnersList} />
    <Route path={`${match.url}/:topicId`} component={PartnerDetail} />
  </Switch>
);

export const PartnerOffersRoutes = ({ match }) => (
    <Switch>
        <Route exact path={match.url} component={PartnerOffersList} />
    </Switch>
);
