import { MatchInputCollection } from 'FD/matches/MatchInputCollection';
import TextInput from 'Components/inputs/Text';
import { TeamInputCollection } from 'FD/teams/TeamInputCollection';
import { PersonInputCollection } from 'FD/people/PersonInputCollection';
import { AmountInput } from 'Components/inputs/AmountInput';
import SelectConstant from 'FDComponents/constants/Select';
import { CompetitionTournamentInput } from 'FD/competitions/CompetitionTournamentInput';
import Integer from 'Components/inputs/Integer';
import { getProject } from 'Services/project';
import { CompetitionInputCollection } from 'FD/competitions/CompetitionInputCollection';
import ArticleInput from 'FM/articles/Input';
import { getLinkId } from 'Services/tools';
import TypeSelectField from 'FD/stats/components/TypeSelectField';
import TypeCategorySelect from 'FD/stats/components/TypeCategorySelect';
import { SurveyInput } from 'FD/surveys/components/SurveyInput';
import clubTypeSelect from 'FDComponents/clubType/clubTypeSelect';
import groupByStatsSelect from 'FD/people/players/components/groupByStatsSelect';
import { QuizzInput } from 'FD/quizz/components/QuizzInput';
// import SelectSurveyFormat from 'FM/articles/embed/selectSurveyFormat';

export const getEmbedConfig = () => ({
  teamsForm: {
    path: 'teams-form',
    pathParameters: ['matchId'],
    queryParameters: ['title'],
    tags: ['match'],
    config: {
      fields: ['matchId', 'title'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_match', name: 'matchId', component: MatchInputCollection },
            { name: 'title', component: TextInput },
          ],
        },
      ],
    },
  },
  matchTeamsStandings: {
    path: 'match-teams-standings',
    pathParameters: ['matchId'],
    queryParameters: ['title'],
    tags: ['match'],
    config: {
      fields: ['matchId', 'title'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_match', name: 'matchId', component: MatchInputCollection },
            { name: 'title', component: TextInput },
          ],
        },
      ],
    },
  },
  matchLastHeadToHead: {
    path: 'match-last-head-to-head',
    pathParameters: ['matchId'],
    queryParameters: ['title'],
    tags: ['match'],
    config: {
      fields: ['matchId', 'title'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_match', name: 'matchId', component: MatchInputCollection },
            { name: 'title', component: TextInput },
          ],
        },
      ],
    },
  },
  teamCard: {
    path: 'team-presentation',
    pathParameters: ['teamId'],
    tags: ['team'],
    config: {
      fields: ['teamId'],
      disposition: [
        {
          size: 6,
          fields: [{ name: 'teamId', component: TeamInputCollection }],
        },
      ],
    },
  },
  matchFormation: {
    path: 'match-formation',
    pathParameters: ['matchId'],
    queryParameters: ['position'],
    tags: ['match'],
    config: {
      fields: ['matchId', 'position'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_match', name: 'matchId', component: MatchInputCollection },
            { name: 'position', component: SelectConstant, entity: 'matchTeam' },
          ],
        },
      ],
    },
  },
  match: {
    path: 'match',
    pathParameters: ['matchId'],
    tags: ['match'],
    config: {
      fields: ['matchId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_match', name: 'matchId', component: MatchInputCollection }],
        },
      ],
    },
  },
  matchStats: {
    path: 'match-stats',
    pathParameters: ['matchId'],
    queryParameters: ['statCategory', 'title'],
    tags: ['match'],
    config: {
      fields: ['matchId', 'statCategory', 'title'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_match', name: 'matchId', component: MatchInputCollection },
            { name: 'statCategory', component: TypeCategorySelect },
            { name: 'title', component: TextInput, size: 6 },
          ],
        },
      ],
    },
  },
  matchVideos: {
    path: 'match-videos',
    pathParameters: ['matchId'],
    tags: ['match'],
    config: {
      fields: ['matchId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_match', name: 'matchId', component: MatchInputCollection }],
        },
      ],
    },
  },
  competitionCard: {
    path: 'competition-presentation',
    pathParameters: ['competitionId'],
    tags: ['tournament'],
    config: {
      fields: ['competitionId'],
      disposition: [
        {
          size: 9,
          fields: [
            {
              labelKey: 'field_competition',
              name: 'competitionId',
              component: CompetitionInputCollection,
            },
          ],
        },
      ],
    },
  },
  playersStandings: {
    tags: ['tournament', 'player'],
    pathParameters: ['tournamentId', 'statTypeCode'],
    queryParameters: ['title', 'limit'],
    path: 'players-standings',
    config: {
      fields: ['competition', 'statTypeCode', 'title', 'limit'],
      disposition: [
        {
          size: 9,
          fields: [
            { name: 'competition', component: CompetitionTournamentInput },
            { name: 'statTypeCode', component: TypeSelectField, required: true, size: 6 },
            { name: 'title', component: TextInput, size: 6 },
            { name: 'limit', component: Integer, size: 6 },
          ],
        },
      ],
    },
  },
  standings: {
    tags: ['tournament'],
    pathParameters: ['tournamentId'],
    queryParameters: ['title', 'limit', 'offset'],
    path: 'standings',
    config: {
      fields: ['competition', 'limit', 'offset', 'title'],
      disposition: [
        {
          size: 9,
          fields: [
            { labelKey: 'field_competition', name: 'competition', component: CompetitionTournamentInput },
            { name: 'title', component: TextInput },
            { name: 'limit', component: Integer },
            { name: 'offset', component: Integer },
          ],
        },
      ],
    },
  },
  tournamentMatches: {
    path: 'tournament-matches',
    pathParameters: ['tournamentId'],
    queryParameters: ['limit', 'status'],
    tags: ['tournament'],
    config: {
      fields: ['competition'],
      disposition: [
        {
          size: 9,
          fields: [
            { labelKey: 'field_competition', name: 'competition', component: CompetitionTournamentInput },
            { labelKey: 'field_team', name: 'team', component: TeamInputCollection, size: 6 },
            { name: 'status', component: SelectConstant, entity: 'match', size: 6, required: true },
            { name: 'limit', component: Integer, size: 6 },
          ],
        },
      ],
    },
  },
  transfer: {
    path: 'transfer',
    tags: ['player'],
    pathParameters: ['playerId'],
    queryParameters: ['previous_team', 'team', 'player', 'type', 'amount'],
    config: {
      fields: ['previous_team', 'team', 'player', 'type', 'amount'],
      disposition: [
        {
          size: 6,
          fields: [
            { name: 'previous_team', component: TeamInputCollection },
            { name: 'team', component: TeamInputCollection },
            { labelKey: 'field_player', name: 'playerId', component: PersonInputCollection, size: 9 },
            {
              name: 'amount',
              labelKey: 'field_amount',
              names: ['amount.value', 'amount.currency'],
              component: AmountInput,
              group: true,
              size: 6,
            },
            {
              name: 'type',
              component: SelectConstant,
              entity: 'transfer',
              size: 6,
            },
          ],
        },
      ],
    },
  },
  article: {
    tags: ['article'],
    path: 'article',
    pathParameters: ['articleId'],
    config: {
      fields: ['articleId'],
      disposition: [
        {
          size: 6,
          fields: [
            {
              labelKey: 'app_article',
              name: 'articleId',
              component: ArticleInput,
              normalize: getLinkId,
            },
          ],
        },
      ],
    },
  },
  product: {
    path: 'product',
    tags: ['product'],
    pathParameters: ['productId'],
    config: {
      fields: ['productId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_product', name: 'productId', component: Integer }],
        },
      ],
    },
  },
  playerCard: {
    path: 'player-presentation',
    pathParameters: ['playerId'],
    tags: ['player'],
    config: {
      fields: ['playerId'],
      disposition: [
        {
          size: 9,
          fields: [{ labelKey: 'field_player', name: 'playerId', component: PersonInputCollection }],
        },
      ],
    },
  },
  playersStats: {
    tags: ['player'],
    pathParameters: ['playerId'],
    queryParameters: ['selection', 'groupBy', 'title'],
    path: 'player-stats',
    config: {
      fields: ['competition', 'statTypeCode', 'title', 'limit'],
      disposition: [
        {
          size: 9,
          fields: [
            { labelKey: 'field_player', name: 'playerId', component: PersonInputCollection, size: 9 },
            { name: 'groupBy', component: groupByStatsSelect, size: 3 },
            { labelKey: 'field_clubType', name: 'selection', component: clubTypeSelect, size: 9 },
            { name: 'title', component: TextInput, size: 6 },
          ],
        },
      ],
    },
  },
  coachCard: {
    path: 'coach-presentation',
    pathParameters: ['coachId'],
    tags: ['coach'],
    config: {
      fields: ['coachId'],
      disposition: [
        {
          size: 9,
          fields: [{ labelKey: 'field_coach', name: 'coachId', component: PersonInputCollection }],
        },
      ],
    },
  },
  survey: {
    path: 'survey',
    pathParameters: ['surveyId'],
    // queryParameters: ['format'],
    tags: ['survey', 'userGame'],
    config: {
      fields: ['surveyId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_survey', name: 'surveyId', component: SurveyInput }],
        },
        /*
        {
          size: 6,
          fields: [{ labelKey: 'field_format', name: 'format', component: SelectSurveyFormat }],
        },
        */
      ],
    },
  },
  quizz: {
    path: 'quizz',
    pathParameters: ['quizzId'],
    // queryParameters: ['format'],
    tags: ['quiz', 'userGame'],
    config: {
      fields: ['surveyId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_quiz', name: 'quizzId', component: QuizzInput }],
        },
      ],
    },
  },
  matchPronostic: {
    path: 'match-pronostic',
    pathParameters: ['matchId'],
    tags: ['match', 'userGame'],
    config: {
      fields: ['matchId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_match', name: 'matchId', component: MatchInputCollection }],
        },
      ],
    },
  },
  matchUserPlayerRatings: {
    path: 'match-user-player-ratings',
    pathParameters: ['matchId'],
    tags: ['match', 'userGame'],
    config: {
      fields: ['matchId'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_match', name: 'matchId', component: MatchInputCollection },
          ],
        },
      ],
    }
  },
  button: {
    path: 'button',
    tags: ['misc'],
    queryParameters: ['text', 'url'],
    config: {
      fields: ['text', 'url'],
      disposition: [
        {
          size: 6,
          fields: [
            { labelKey: 'field_title', name: 'text', component: TextInput },
            { labelKey: 'field_url', name: 'url', component: TextInput },
          ],
        },
      ],
    }
  },
  matchPlayerRatingStanding: {
    path: 'match-player-ratings-standing',
    pathParameters: ['matchId'],
    tags: ['match'],
    config: {
      fields: ['matchId'],
      disposition: [
        {
          size: 6,
          fields: [{ labelKey: 'field_match', name: 'matchId', component: MatchInputCollection }],
        },
      ],
    },
  },
  matchLineupSurveys: {
    path: 'match-lineup-surveys',
    pathParameters: ['matchId'],
    queryParameters: ['mode'],
    tags: ['userGame'],
    config: {
      fields: ['matchId'],
      disposition: [
        {
          fields: [{ labelKey: 'field_match', name: 'matchId', component: MatchInputCollection }],
        },
      ],
    },
  },
  mercatoSurveyCard: {
    path: 'mercato-survey-card',
    tags: ['userGame'],
  },
});

export const getPathParameters = (formValues, data) => {
  const pathParameters = {};
  data.pathParameters.forEach(param => {
    if (Array.isArray(formValues[param])) {
      if (param === 'statTypeCode') {
        pathParameters[param] = formValues[param][0].code;
      } else {
        pathParameters[param] = formValues[param] !== undefined ? formValues[param][0] : '';
      }
    } else {
      pathParameters[param] = formValues[param] !== undefined ? formValues[param] : '';
    }
  });
  return pathParameters;
};

export const getQueryParameters = (formValues, data) => {
  const queryParameters = { embedded: 1 };
  data.queryParameters.forEach(param => {
    if (formValues[param] === undefined) {
      return;
    }
    if (param === 'amount') {
      queryParameters.amount = formValues[param].value;
      queryParameters.currency = formValues[param].currency;
    } else {
      if (Array.isArray(formValues[param])) {
        queryParameters[param] = formValues[param] !== undefined ? formValues[param][0] : '';
      } else {
        queryParameters[param] = formValues[param] !== undefined ? formValues[param] : '';
      }
    }
  });
  if (data.path === 'match-lineup-surveys') {
    queryParameters.mode = 'button';
  }
  return queryParameters;
};

export const getEmbedCode = (pathParameters, queryParameters, data) => {
  const key = { key: `api_embed_${data.path.replaceAll('-', '_')}` };
  delete queryParameters.embedded;
  const params = { ...pathParameters, ...queryParameters };

  return JSON.stringify({ ...key, ...params });
};

export const buildUrl = (path, pathParameters, queryParameters) => {
  let url = `${getProject().baseUrlFM}embed/${path}/`;
  if (pathParameters !== undefined) {
    url += Object.values(pathParameters).join('/');
  }
  if (queryParameters !== undefined && queryParameters !== '') {
    const queryParamsString = new URLSearchParams(queryParameters).toString();
    url += `?${queryParamsString}`;
  }

  return url;
};
