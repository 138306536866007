import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import ImageInput from '../../../../images/Input';

export default class Form extends Component {
  config = {
    objectFields: ['homeShirt', 'awayShirt', 'thirdShirt'],
    disposition: [
      {
        fields: [
          { name: 'homeShirt', component: ImageInput, size: 4 },
          { name: 'awayShirt', component: ImageInput, size: 4 },
          { name: 'thirdShirt', component: ImageInput, size: 4 },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`teamSeasonShirts_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
