import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getCollection, getItemBySlug } from 'Services/connectors';
import { getProject } from 'Services/project';
import DeleteButton from '../../../../common/components/DeleteButton';
import { IdBadge } from '../../../../common/components/IdBadge';
import Layout from '../../../../common/components/Layout';
import ReturnButton from '../../../../common/components/ReturnButton';
import Revisions from '../../../../common/components/revisions';
import RevisionNote from '../../../../common/components/revisions/Note';
import { SimpleBreadcrumbs } from '../../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../../common/components/TabsRoute';
import { UserRestriction } from '../../../../common/components/UserRestiction';
import { getLinkId } from '../../../../common/services/tools';
import { fetchAgentPlayers, updatePlayer } from '../../players/actions';
import { PlayersColumn } from '../../players/components/PlayersColumn';
import { getDefaultPeoplePath, getPersonUsualName } from '../../utils';
import { deleteAgent, fetchAgent, updateAgent } from '../actions';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  agent: getItemBySlug(store.agent, props.match.params.topicSlug),
  fetching: store.agent.fetching,
  players: getCollection('playersIds', store.agent, store.player),
}))
export default class AgentDetail extends Component {
  state = {
    showRevisions: false,
  };
  menu = [{ label: 'app_personInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchAgent(targetSlug || slug)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchAgentPlayers(payload.id));
      }
    });
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handleAgentChange = person => {
    const { dispatch } = this.props;
    return dispatch(updateAgent(person.id, person));
  };

  handleRemove = () => {
    const { agent, dispatch, history } = this.props;
    return dispatch(deleteAgent(agent.id)).then(() => {
      history.push(`${getProject().url}/agents`);
    });
  };

  handleSetPlayerAgent = newPlayerAId => {
    const { agent, dispatch } = this.props;
    const playerId = getLinkId(newPlayerAId);
    return dispatch(updatePlayer(playerId, { agent: agent['@id'] })).then(
      dispatch(fetchAgentPlayers(agent.id))
    );
  };

  handleUnsetPlayerAgent = oldPlayerId => {
    const { agent, dispatch } = this.props;
    const playerId = getLinkId(oldPlayerId);
    return dispatch(updatePlayer(playerId, { agent: null })).then(
      dispatch(fetchAgentPlayers(agent.id))
    );
  };

  getLabel = () => {
    const { agent } = this.props;
    return getPersonUsualName(agent);
  };

  render() {
    const {
      fetching,
      agent,
      players,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!agent) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="agent-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_agents', href: `#${getDefaultPeoplePath('agents')}` },
              { label: this.getLabel() },
            ]}
          />
        }
        main={
          <div>
            <Paper elevation={2}>
              <Toolbar>
                <Typography variant="h6">{this.getLabel()}</Typography>
                <IdBadge id={agent.id} />
                <div style={{ flex: 1 }} />
                <UserRestriction>
                  <DeleteButton onRemove={this.handleRemove} />
                </UserRestriction>

                <ReturnButton entity="agents" />
              </Toolbar>

              <TabsRoute match={match} menu={this.menu} history={history} />

              <Route
                exact
                path={match.url}
                render={() => (
                  <div>
                    <div>
                      <RevisionNote
                        style={{ margin: 16 }}
                        createdAt={agent.createdAt}
                        updatedAt={agent.updatedAt}
                        toggle={this.toggleRevisions}
                      />
                      {this.state.showRevisions && (
                        <Revisions
                          entity="person"
                          id={agent.id}
                          updatedAt={agent.updatedAt}
                          onRollback={() => this.fetchData()}
                        />
                      )}
                    </div>
                    <Form onSubmit={this.handleAgentChange} data={agent} />
                  </div>
                )}
              />
            </Paper>
          </div>
        }
        right={
          <Paper elevation={2}>
            <PlayersColumn
              players={players}
              onRemove={this.handleUnsetPlayerAgent}
              onSelect={this.handleSetPlayerAgent}
            />
          </Paper>
        }
      />
    );
  }
}
