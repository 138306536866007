import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getProject } from 'Services/project';
import { renderImg } from '../../images/tools';
import { getPersonUsualName } from '../../people/utils';

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
    margin: '0 4px',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary[500],
    },
  },
});

@injectIntl
@withStyles(styles)
export class MatchHead extends Component {
  getScroreText(sc) {
    return sc ? `${sc.home} - ${sc.away}` : '-';
  }
  renderTimeTitle() {
    const { match } = this.props;
    const time = moment(match.date);
    return (
      <div>{`Journée ${match.phaseName} | ${time.format('dddd')} ${time.format('ll')} | ${time.format('LT')}`}</div>
    );
  }
  renderScore() {
    const { match } = this.props;
    return (
      <div style={{ padding: 16 }}>
        <Typography variant="h6">{this.getScroreText(match.scores)}</Typography>
        {match.matchPeriods['1_first_half'] && (
          <Typography variant="caption">({this.getScroreText(match.matchPeriods['1_first_half'].scores)})</Typography>
        )}
      </div>
    );
  }

  renderVenue({ venue, attendance }) {
    const {
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <span>
        {venue && (
          <a className={classes.link} href={`#${getProject().url}/venues/${venue.id}`}>
            {venue.name}
          </a>
        )}
        {attendance && (
          <span>
            {' '}
            - {attendance} {m.match_spectators}
          </span>
        )}
      </span>
    );
  }
  renderReferee(referees) {
    if (!referees.main) return null;
    return <span>{getPersonUsualName(referees.main.person)}</span>;
  }
  renderTeam(t) {
    const { classes } = this.props;
    return (
      <Typography variant="h6" style={{ padding: 16 }}>
        <a className={classes.link} href={`#${getProject().url}/teams/${t.slug}`}>
          {t.shortName || t.name}
        </a>
      </Typography>
    );
  }
  renderMatchTeam(team, isReversed) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: isReversed ? 'row-reverse' : 'row',
          padding: 16,
        }}
      >
        <div>{renderImg(team.club && team.club.logo, 150)}</div>
        <Hidden only="sm">{this.renderTeam(team)}</Hidden>
      </div>
    );
  }

  render() {
    const { match } = this.props;

    return (
      <Grid container spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {this.renderMatchTeam(match.homeTeam)}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <div style={{ textAlign: 'center' }}>
            <div>{match.phaseTournamentCompetitionName}</div>
            {this.renderTimeTitle()}
            {this.renderScore()}
            {this.renderVenue(match)}
            <div>{this.renderReferee(match.matchReferees)}</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          {this.renderMatchTeam(match.awayTeam, true)}
        </Grid>
      </Grid>
    );
  }
}
