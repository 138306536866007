import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import { connect } from 'react-redux';
import { getLinkId } from '../../../common/services/tools';
import { getCompetitionId } from '../actions';

// @ts-ignore
@connect((store: any, props: any) => ({
  competition: store.competition.detail[getLinkId(props.children)],
}))
export class CompetitionCollectionChip extends React.Component<any> {
  public componentDidMount() {
    const { dispatch, children } = this.props;
    return dispatch(getCompetitionId(getLinkId(children)));
  }
  public render() {
    const { removeProps } = this.props;
    const label = this.getLabel();
    const deleteIcon = this.getIcon();
    return <Chip tabIndex={-1} label={label} onDelete={removeProps.onClick} deleteIcon={deleteIcon} />;
  }

  private getLabel = () => {
    const { children, competition } = this.props;
    if (!competition) {
      return children;
    }

    let areaLabel = '';
    if (competition.area) {
      areaLabel = competition.area.codeFIFA || competition.area.codeISO;
    }
    return areaLabel ? `${competition.name} (${areaLabel})` : competition.name;
  };
  private getIcon = () => {
    const { removeProps, competition } = this.props;
    return competition ? <CancelIcon {...removeProps} /> : <CircularProgress size={24} />;
  };
}
