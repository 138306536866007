import {
  notifyErrorAction,
  notifySuccessAction,
  notifyWarnAction,
} from '../../../../../common/actions/notifications';
import {
  extractMatchComposition,
  getMatchComposition,
} from '../../../../compositions/requests';
import { ITeamComposition } from '../../../../compositions/typings';
import { getNumberOfPlayer } from '../../../../compositions/utils';
import { IMatch, ISide } from '../../../../matches/typings';
import { importLiveTeamCompositionAction } from '../../actions';

const getNotifationMessageAction = (teamFormation: ITeamComposition) => {
  const numberOfPlayer = getNumberOfPlayer(teamFormation);
  if (numberOfPlayer === 11) {
    return notifySuccessAction('message_importFullFormationFinished');
  }
  if (numberOfPlayer > 0) {
    return notifyWarnAction('message_importPartialFormationFinished');
  }
  return notifyWarnAction('message_importEmptyFormationFinished');
};

export const importPreviousMatchComposition = (previousMatch: IMatch, side: ISide) => (
  dispatch,
  getState
) =>
  getMatchComposition(previousMatch.id)
    .then(extractMatchComposition)
    .then(composition => {
      if (composition) {
        const teamId =
          side === 'home' ? getState().live.homeTeam.id : getState().live.awayTeam.id;
        const previousSide = previousMatch.homeTeam.id === teamId ? 'home' : 'away';
        const previousTeamFormation =
          previousSide === 'home' ? composition.home : composition.away;
        if (previousTeamFormation) {
          dispatch(getNotifationMessageAction(previousTeamFormation));
          return dispatch(importLiveTeamCompositionAction(previousTeamFormation, side));
        }
      }
      return dispatch(notifyErrorAction('message_importFormationFailed'));
    });
