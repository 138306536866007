import Toggle from 'Components/inputs/Toggle';
import React, { Component } from 'react';
import { FormEdit } from '../../../common/components/FormEdit';
import TextInput from '../../../common/components/inputs/Text';
import { normalizeBoolean } from '../../../common/components/inputs/Toggle';
import isAdmin from '../../../common/services/me';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'name',
      'longName',
      'slug',
      'active',
      'startDate',
      'endDate',
      'type',
      'position',
      'hasCrossGroupMatches',
    ],
    disposition: [
      {
        fields: [
          { name: 'name', component: TextInput, size: 6 },
          { name: 'longName', component: TextInput, size: 6 },
          { name: 'slug', component: TextInput, size: 6, disabled: true },
          { name: 'active', component: Toggle, size: 6, disabled: true },
          { name: 'startDate', component: TextInput, size: 6, disabled: true },
          { name: 'endDate', component: TextInput, size: 6, disabled: true },
          { name: 'type', component: TextInput, size: 6, disabled: true },
          { name: 'position', component: TextInput, size: 6, disabled: true },
          {
            name: 'hasCrossGroupMatches',
            component: Toggle,
            normalize: normalizeBoolean,
            disabled: !isAdmin(),
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`phase_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
