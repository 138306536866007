import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchBookmakers } from './actions';

@injectIntl
@connect(store => ({
  bookmakers: store.bookmakers,
}))
export default class SelectBookmaker extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchBookmakers());
  }

  render() {
    const {
      input: { name, value, onChange },
      meta: { touched, error },
      intl: { formatMessage: f },
      labelKey,
      bookmakers,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <div style={{ display: 'flex' }}>
        <FormControl fullWidth error={Boolean(touched && error)}>
          <InputLabel htmlFor={`select_${label}`}>{label}</InputLabel>
          <Select
            name={name}
            value={value}
            onChange={event => onChange(event.target.value === '' ? null : event.target.value)}
            input={<Input id={`select_${label}`} />}
            MenuProps={{
              PaperProps: { style: { maxHeight: 500 } },
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {bookmakers &&
            bookmakers.list &&
            bookmakers.list.map(value => (
                <MenuItem value={value['@id']}>{value.name}</MenuItem>
              ))}
          </Select>
          {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </div>
    );
  }
}
