import rest from 'Services/restFootData';

export function fetchAllOrganizations() {
  const params = {
    pagination: 0,
  };
  return function(dispatch, getState) {
    const organizations = getState().organizations;
    if (organizations.fetched) {
      return dispatch({ type: 'CACHED_ORGANIZATIONS' });
    }

    dispatch({ type: 'FETCH_ORGANIZATIONS' });
    return rest.get(`organizations`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ORGANIZATIONS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ORGANIZATIONS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function addOrganization(organization) {
  return function(dispatch) {
    dispatch({ type: 'ADD_ORGANIZATION' });
    return rest.post(`organizations`, organization).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            messageKey: 'message_organizationCreated',
            type: 'success',
          },
        });
        return dispatch({
          type: 'ADD_ORGANIZATION_FULFILLED',
          payload: res.data,
        });
      },
      err => dispatch({ type: 'ADD_ORGANIZATION_REJECTED', payload: err.response })
    );
  };
}
