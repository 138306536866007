import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import React,{ Component } from 'react';
import { injectIntl } from 'react-intl';
import { getProject } from '../../../common/services/project';
import { ILineupSurvey } from '../typings';

interface IProps {
  tableData: ILineupSurvey;
  intl: any;
  dispatch: any;
  history: any;
}

// @ts-ignore
@injectIntl
export class LineupSurveyRow extends Component<IProps> {
  public render() {
    const {
      tableData,
      intl: { messages: m },
    } = this.props;

    return (
      <TableRow hover href={`#${getProject().url}/lineup-surveys/${tableData.id}`} component="a">
        <TableCell>{tableData.title}</TableCell>
        <TableCell>{tableData.typeTranslated}</TableCell>
        <TableCell>{tableData.isActive ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{tableData.isEnabled ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{this.formatDate(tableData.startDate)}</TableCell>
        <TableCell>{this.formatDate(tableData.endDate)}</TableCell>
        <TableCell>{tableData.team?.name || '-'}</TableCell>
        <TableCell>{tableData.tournament?.competitionName || '-'}</TableCell>
        <TableCell>{tableData.totalParticipantsCount}</TableCell>
      </TableRow>
    )
  }

  private formatDate(date) {
    if (!date) return '-';

    return `${moment(date).format('DD/MM')} - ${moment(date).format('LT')}`;
  }
}
