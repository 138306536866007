import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './common/theme';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ConnectedIntlProvider from './common/providers/intl';
import { Provider } from 'react-redux';
import { store } from './store';
import 'typeface-roboto';
import { Home } from './Home';
import Footdata from './footdata';
import Footmercato from './footmercato';
import * as Sentry from "@sentry/react";

const history = createBrowserHistory();
const app = document.getElementById('app');

/* global process*/
if (!process.env.DEBUG) {
  ['log', 'warn', 'info', 'table', 'trace'].forEach(method => (console[method] = () => {}));
}

Sentry.init({
  dsn: 'https://52396d3b4019b9d613b3388faae41246@sentry.adversport.com/7',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedIntlProvider>
        <HashRouter history={history}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/footdatafr" component={Footdata} />
            <Route path="/footdatade" component={Footdata} />
            <Route path="/footdataes" component={Footdata} />
            <Route path="/footdatait" component={Footdata} />
            <Route path="/footdataen" component={Footdata} />
            <Route path="/footdatabr" component={Footdata} />
            <Route path="/footmercato" component={Footmercato} />
            <Route path="/fussballtransfers" component={Footmercato} />
            <Route path="/fichajes" component={Footmercato} />
          </Switch>
        </HashRouter>
      </ConnectedIntlProvider>
    </Provider>
  </ThemeProvider>,
  app
);
