export const styles = {
  header: {
    background: '#f5f5fe',
    display: 'flex',
  },
  errorButtonContainer: {
    padding: 40,
    textAlign: 'center' as 'center',
  },
  flex1: {
    flex: 1,
  },
  statusContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 32px',
  },
  refreshIcon: {
    marginRight: 8,
  },
  iconContainer: { padding: 8 },
};
