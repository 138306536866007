import { Card, ListSubheader } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import * as React from 'react';
import { IMatch } from '../../../../matches/typings';
import { IComponentProps } from './CompositionsSelector.connector';

export class CompositionsSelector extends React.Component<IComponentProps> {
  public render() {
    const {
      oldMatches,
      classes,
      intl: { messages: m },
    } = this.props;

    return (
      <Card className={classes.card}>
        <List
          dense={true}
          subheader={
            <ListSubheader className={classes.listSubheader}>
              {m.live_compo_lastMatches}
            </ListSubheader>
          }
        >
          {oldMatches.map(match => (
            <ListItem key={`last-match-composition-${match.id}`}>
              <ListItemText
                primary={match.description}
                secondary={moment(match.date).format('L')}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={this.import(match)}>
                  <ArrowForwardIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Card>
    );
  }

  private import = (match: IMatch) => () => {
    const { importPreviousMatchComposition, side } = this.props;
    importPreviousMatchComposition(match, side);
  };
}
