import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { buildFMLink } from 'Services/project';
const styles = theme => ({
  leftButton: {
    marginRight: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
export class ButtonPreview extends PureComponent {
  state = {
    url: '',
  };
  componentDidMount() {
    const { id, slug } = this.props;
    buildFMLink(`a${id}-${slug}`).then(url => this.setState({ url }));
  }

  render() {
    const {
      classes,
      intl: { messages: m },
      previewParam
    } = this.props;

    const { url } = this.state;
    const uniquePreviewUrl = url.indexOf('?') === -1 ? `${url}?preview=${previewParam}` : `${url}&preview=${previewParam}`

    return (
      <Button
        variant="contained"
        href={uniquePreviewUrl}
        target="_blank"
        className={classes.leftButton}
      >
        <PageviewIcon className={classes.leftIcon} />
        {m.button_preview}
      </Button>
    );
  }
}
