import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  box: {
    border: `1px solid ${theme.palette.divider}`,
  },
  paper: {
    display: 'flex' as 'flex',
    flexWrap: 'wrap' as 'wrap',
    alignItems: 'center' as 'center',
  },
  divider: {
    margin: theme.spacing(1),
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  groupedButtons: {
    '& span': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  groupedCustomButtons: {
    '& span': {
      color: 'rgba(34, 119, 239, 0.90)',
    },
  },
  supersubSwitch: {
    '& .MuiSwitch-switchBase': {
      color: 'black',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      backgroundImage: 'linear-gradient(to right, #f50057, #ff3d00)',
      backgroundClip: 'content-box',
      color: 'transparent',
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'linear-gradient(to right, rgb(248 200 217), rgb(249 212 200))',
    },
    '& .MuiSwitch-thumb::after': {
      content: 'url("img/FMicons/supersub.svg")',
      display: 'block' /* Assure que le contenu est affiché comme un élément de bloc */,
      position: 'absolute',
      top: 5,
      left: 11,
    },
    '& .Mui-checked .MuiSwitch-thumb::after': {
      left: 12,
    },
  },
});
