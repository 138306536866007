import { Button } from '@material-ui/core';
import * as React from 'react';
import { AdvancedBloc } from '../AdvancedBloc';
import { IComponentProps } from './PostponeMatch.connector';
import {getProject} from "../../../../../common/services/project";

export class PostponeMatch extends React.Component<IComponentProps> {
  public render() {
    const {
      intl: { messages: m },
      isUpdating,
      mainCommentatorLocale,
    } = this.props;

    return (
      <AdvancedBloc
        title={m.live_advanced_postponeTitle}
        description={m.live_advanced_postponeDescription}
      >
        <Button
            color="primary"
            onClick={this.handleClick}
            disabled={isUpdating || mainCommentatorLocale != getProject().lang}
        >
          {m.button_postponeMatch}
        </Button>
      </AdvancedBloc>
    );
  }

  private handleClick = () => {
    const { updateMatch, matchId } = this.props;
    updateMatch(matchId, { status: 'postponed' });
  };
}
