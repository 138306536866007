export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_HIGHLIGHTING_MENU': {
      return {
        ...state,
        fetching: true,
      };
    }

    case 'FETCH_HIGHLIGHTING_MENU_FULFILLED': {
      const { list } = action.payload;
      return {
        ...state,
        menu: list.collection,
        fetching: false,
      };
    }

    case 'UPDATE_MENU_ITEM_REJECTED':
    case 'UPDATE_MENU_ITEM_FULFILLED': {
      const item = action.payload;
      const menu = state.menu.slice(0);
      menu[item.position] = item;

      return {
        ...state,
        menu,
        fetching: false,
      };
    }

    default:
      return state;
  }
}
