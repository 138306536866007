import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { parseQuery } from 'Services/parseQuery';
import Layout from '../../../common/components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { fetchInjuryTypes, updateInjuryType } from '../actions';
import Row from './Row';

@injectIntl
@connect((store, props) => ({
  injuryTypes: store.injuryTypes.list,
  fetching: store.injuryTypes.fetching,
  query: parseQuery(props.location.search),
}))
export default class InjuryTypes extends Component {
  config = {
    headers: [{ labelKey: 'field_code' }, { labelKey: 'field_name' }],
  };

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchInjuryTypes(filter));
  };

  handleChange = injury => {
    const { dispatch } = this.props;
    return dispatch(updateInjuryType(injury.id, injury));
  };

  render() {
    const {
      injuryTypes,
      fetching,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="injury_types-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_injury_types' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_injury_types}</Typography>
            </Toolbar>
            <TableList
              tableData={injuryTypes}
              tableHeaders={this.config.headers}
              query={query}
              Row={Row}
              rowCallback={this.handleChange}
              loading={fetching}
            />
          </Paper>
        }
      />
    );
  }
}
