import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  container: {
    width: '100%',
    overflowX: 'auto' as 'auto',
    position: 'relative' as 'relative',
  },
  body: {
    fontSize: '14px',
    color: theme.palette.grey[700],
  },
});
