import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import fetchConstants from './actions';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';

@injectIntl
@connect((store, props) => ({
  constants: store.constantsFM[props.entity][props.input.name],
}))
export default class RadioConstant extends Component {
  componentDidMount() {
    const { dispatch, entity, input } = this.props;
    dispatch(fetchConstants(entity, input.name));
  }

  render() {
    const {
      input: { name, value, onChange },
      meta = {},
      intl: { formatMessage: f },
      labelKey,
      constants,
      dataTest,
    } = this.props;

    const { touched, error } = meta;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

    return (
      <FormControl
        component="fieldset"
        fullWidth
        error={Boolean(touched && error)}
        style={{ marginTop: 16 }}
      >
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          aria-label={label}
          name={name}
          value={value}
          onChange={event => onChange(event.target.value)}
        >
          {constants &&
            constants.list &&
            Object.entries(constants.list).map(([key, value]) => (
              <FormControlLabel
                value={key}
                control={<Radio color="primary" />}
                label={value}
                data-test={dataTest}
              />
            ))}
        </RadioGroup>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}
