// Copied HoverPlugin from '@uiw/react-codemirror'
// node_modules/@codemirror/view/dist/index.js
// @ts-nocheck

import { Direction, ViewPlugin, logException, showTooltip } from '@uiw/react-codemirror';
import { HoverTooltipHost } from './HoverTooltipHost';
import { TooltipViewManager } from './TooltipViewManager';
import { browser, tooltipConfig } from './utils';
const Outside = '-10000px';
const knownHeight = /*@__PURE__*/ new WeakMap();
const noOffset = { x: 0, y: 0 };

export class ClickHoverPlugin {
  view: any;
  source: any;
  field: any;
  setHover: any;
  pending: null;
  lastClick: { x: number; y: number; target: any; time: number };
  constructor(view, source, field, setHover) {
    this.view = view;
    this.source = source;
    this.field = field;
    this.setHover = setHover;
    this.pending = null;
    this.lastClick = { x: 0, y: 0, target: view.dom, time: 0 };
    view.dom.addEventListener('click', (this.mouseclick = this.mouseclick.bind(this)));
  }
  update() {
    if (this.pending) {
      this.pending = null;
    }
  }
  get active() {
    return this.view.state.field(this.field);
  }
  startHover() {
    let { view, lastClick } = this;
    let desc = view.docView.nearest(lastClick.target);

    if (!desc) return;
    let pos,
      side = 1;
    if (desc.isWidget) {
      pos = desc.posAtStart;
    } else {
      pos = view.posAtCoords(lastClick);
      if (pos == null) return;
      let posCoords = view.coordsAtPos(pos);

      if (
        !posCoords ||
        lastClick.y < posCoords.top ||
        lastClick.y > posCoords.bottom ||
        lastClick.x < posCoords.left - view.defaultCharacterWidth ||
        lastClick.x > posCoords.right + view.defaultCharacterWidth
      ) {
        return;
      }
      let bidi = view.bidiSpans(view.state.doc.lineAt(pos)).find(s => s.from <= pos && s.to >= pos);
      let rtl = bidi && bidi.dir == Direction.RTL ? -1 : 1;
      side = lastClick.x < posCoords.left ? -rtl : rtl;
    }

    let open = this.source(view, pos, side);

    if (open === null || open === void 0 ? void 0 : open.then) {
      let pending = (this.pending = { pos });

      open.then(
        result => {
          if (this.pending == pending) {
            this.pending = null;
            if (result && !(Array.isArray(result) && !result.length))
              view.dispatch({ effects: this.setHover.of(Array.isArray(result) ? result : [result]) });
          }
        },
        e => logException(view.state, e, 'hover tooltip')
      );
    } else if (open && !(Array.isArray(open) && !open.length)) {
      view.dispatch({ effects: this.setHover.of(Array.isArray(open) ? open : [open]) });
    }
  }
  get tooltip() {
    let plugin = this.view.plugin(tooltipPlugin);
    let index = plugin ? plugin.manager.tooltips.findIndex(t => t.create == HoverTooltipHost.create) : -1;
    return index > -1 ? plugin.manager.tooltipViews[index] : null;
  }

  mouseclick(event) {
    this.lastClick = { x: event.clientX, y: event.clientY, target: event.target, time: Date.now() };
    let { active, view } = this;
    let desc = view.docView.nearest(event.target);

    if (active.length === 0) {
      this.startHover();
    } else if (!desc) {
      // click on tooltip
    } else {
      this.closeTooltip();
    }
  }
  closeTooltip() {
    this.view.dispatch({ effects: this.setHover.of([]) });
  }

  destroy() {
    this.view.dom.removeEventListener('click', this.mouseclick);
  }
}

const tooltipPlugin = /*@__PURE__*/ ViewPlugin.fromClass(
  class {
    position: any;
    view: any;
    above: never[];
    inView: boolean;
    madeAbsolute: boolean;
    lastTransaction: number;
    measureTimeout: ReturnType<typeof setTimeout> | -1;
    parent: any;
    classes: any;
    measureReq: any;
    resizeObserver: ResizeObserver | null;
    manager: TooltipViewManager;
    intersectionObserver: IntersectionObserver | null;
    container: HTMLDivElement | undefined;
    constructor(view) {
      this.view = view;
      this.above = [];
      this.inView = true;
      this.madeAbsolute = false;
      this.lastTransaction = 0;
      this.measureTimeout = -1;
      let config = view.state.facet(tooltipConfig);
      this.position = config.position;
      this.parent = config.parent;
      this.classes = view.themeClasses;
      this.createContainer();
      this.measureReq = { read: this.readMeasure.bind(this), write: this.writeMeasure.bind(this), key: this };
      this.resizeObserver = typeof ResizeObserver == 'function' ? new ResizeObserver(() => this.measureSoon()) : null;
      this.manager = new TooltipViewManager(
        view,
        showTooltip,
        (t, p) => this.createTooltip(t, p),
        t => {
          if (this.resizeObserver) this.resizeObserver.unobserve(t.dom);
          t.dom.remove();
        }
      );
      this.above = this.manager.tooltips.map(t => !!t.above);
      this.intersectionObserver =
        typeof IntersectionObserver == 'function'
          ? new IntersectionObserver(
              entries => {
                if (
                  Date.now() > this.lastTransaction - 50 &&
                  entries.length > 0 &&
                  entries[entries.length - 1].intersectionRatio < 1
                )
                  this.measureSoon();
              },
              { threshold: [1] }
            )
          : null;
      this.observeIntersection();
      view.win.addEventListener('resize', (this.measureSoon = this.measureSoon.bind(this)));
      this.maybeMeasure();
    }
    createContainer() {
      if (this.parent) {
        this.container = document.createElement('div');
        this.container.style.position = 'relative';
        this.container.className = this.view.themeClasses;
        this.parent.appendChild(this.container);
      } else {
        this.container = this.view.dom;
      }
    }
    observeIntersection() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
        for (let tooltip of this.manager.tooltipViews) this.intersectionObserver.observe(tooltip.dom);
      }
    }
    measureSoon() {
      if (this.measureTimeout === -1)
        this.measureTimeout = setTimeout(() => {
          this.measureTimeout = -1;
          this.maybeMeasure();
        }, 50);
    }
    update(update) {
      if (update.transactions.length) this.lastTransaction = Date.now();
      let updated = this.manager.update(update, this.above);
      if (updated) this.observeIntersection();
      let shouldMeasure = updated || update.geometryChanged;
      let newConfig = update.state.facet(tooltipConfig);
      if (newConfig.position != this.position && !this.madeAbsolute) {
        this.position = newConfig.position;
        for (let t of this.manager.tooltipViews) t.dom.style.position = this.position;
        shouldMeasure = true;
      }
      if (newConfig.parent != this.parent) {
        if (this.parent) this.container.remove();
        this.parent = newConfig.parent;
        this.createContainer();
        for (let t of this.manager.tooltipViews) this.container.appendChild(t.dom);
        shouldMeasure = true;
      } else if (this.parent && this.view.themeClasses != this.classes) {
        this.classes = this.container.className = this.view.themeClasses;
      }
      if (shouldMeasure) this.maybeMeasure();
    }
    createTooltip(tooltip, prev) {
      let tooltipView = tooltip.create(this.view);
      let before = prev ? prev.dom : null;
      tooltipView.dom.classList.add('cm-tooltip');
      if (tooltip.arrow && !tooltipView.dom.querySelector('.cm-tooltip > .cm-tooltip-arrow')) {
        let arrow = document.createElement('div');
        arrow.className = 'cm-tooltip-arrow';
        tooltipView.dom.insertBefore(arrow, before);
      }
      tooltipView.dom.style.position = this.position;
      tooltipView.dom.style.top = Outside;
      tooltipView.dom.style.left = '0px';
      this.container.insertBefore(tooltipView.dom, before);
      if (tooltipView.mount) tooltipView.mount(this.view);
      if (this.resizeObserver) this.resizeObserver.observe(tooltipView.dom);
      return tooltipView;
    }
    destroy() {
      var _a, _b, _c;
      this.view.win.removeEventListener('resize', this.measureSoon);
      for (let tooltipView of this.manager.tooltipViews) {
        tooltipView.dom.remove();
        (_a = tooltipView.destroy) === null || _a === void 0 ? void 0 : _a.call(tooltipView);
      }
      if (this.parent) this.container.remove();
      (_b = this.resizeObserver) === null || _b === void 0 ? void 0 : _b.disconnect();
      (_c = this.intersectionObserver) === null || _c === void 0 ? void 0 : _c.disconnect();
      clearTimeout(this.measureTimeout);
    }
    readMeasure() {
      let editor = this.view.dom.getBoundingClientRect();
      let scaleX = 1,
        scaleY = 1,
        makeAbsolute = false;
      if (this.position == 'fixed' && this.manager.tooltipViews.length) {
        let { dom } = this.manager.tooltipViews[0];
        if (browser.gecko) {
          // Firefox sets the element's `offsetParent` to the
          // transformed element when a transform interferes with fixed
          // positioning.
          makeAbsolute = dom.offsetParent != this.container.ownerDocument.body;
        } else if (dom.style.top == Outside && dom.style.left == '0px') {
          // On other browsers, we have to awkwardly try and use other
          // information to detect a transform.
          let rect = dom.getBoundingClientRect();
          makeAbsolute = Math.abs(rect.top + 10000) > 1 || Math.abs(rect.left) > 1;
        }
      }
      if (makeAbsolute || this.position == 'absolute') {
        if (this.parent) {
          let rect = this.parent.getBoundingClientRect();
          if (rect.width && rect.height) {
            scaleX = rect.width / this.parent.offsetWidth;
            scaleY = rect.height / this.parent.offsetHeight;
          }
        } else {
          ({ scaleX, scaleY } = this.view.viewState);
        }
      }
      return {
        editor,
        parent: this.parent ? this.container.getBoundingClientRect() : editor,
        pos: this.manager.tooltips.map((t, i) => {
          let tv = this.manager.tooltipViews[i];
          return tv.getCoords ? tv.getCoords(t.pos) : this.view.coordsAtPos(t.pos);
        }),
        size: this.manager.tooltipViews.map(({ dom }) => dom.getBoundingClientRect()),
        space: this.view.state.facet(tooltipConfig).tooltipSpace(this.view),
        scaleX,
        scaleY,
        makeAbsolute,
      };
    }
    writeMeasure(measured) {
      var _a;
      if (measured.makeAbsolute) {
        this.madeAbsolute = true;
        this.position = 'absolute';
        for (let t of this.manager.tooltipViews) t.dom.style.position = 'absolute';
      }
      let { editor, space, scaleX, scaleY } = measured;
      let others = [];
      for (let i = 0; i < this.manager.tooltips.length; i++) {
        let tooltip = this.manager.tooltips[i],
          tView = this.manager.tooltipViews[i],
          { dom } = tView;
        let pos = measured.pos[i],
          size = measured.size[i];
        // Hide tooltips that are outside of the editor.
        if (
          !pos ||
          pos.bottom <= Math.max(editor.top, space.top) ||
          pos.top >= Math.min(editor.bottom, space.bottom) ||
          pos.right < Math.max(editor.left, space.left) - 0.1 ||
          pos.left > Math.min(editor.right, space.right) + 0.1
        ) {
          dom.style.top = Outside;
          continue;
        }
        let arrow = tooltip.arrow ? tView.dom.querySelector('.cm-tooltip-arrow') : null;
        let arrowHeight = arrow ? 7 /* Arrow.Size */ : 0;
        let width = size.right - size.left,
          height = (_a = knownHeight.get(tView)) !== null && _a !== void 0 ? _a : size.bottom - size.top;
        let offset = tView.offset || noOffset,
          ltr = this.view.textDirection == Direction.LTR;
        let left =
          size.width > space.right - space.left
            ? ltr
              ? space.left
              : space.right - size.width
            : ltr
            ? Math.min(pos.left - (arrow ? 14 /* Arrow.Offset */ : 0) + offset.x, space.right - width)
            : Math.max(space.left, pos.left - width + (arrow ? 14 /* Arrow.Offset */ : 0) - offset.x);
        let above = this.above[i];
        if (
          !tooltip.strictSide &&
          (above
            ? pos.top - (size.bottom - size.top) - offset.y < space.top
            : pos.bottom + (size.bottom - size.top) + offset.y > space.bottom) &&
          above == space.bottom - pos.bottom > pos.top - space.top
        )
          above = this.above[i] = !above;
        let spaceVert = (above ? pos.top - space.top : space.bottom - pos.bottom) - arrowHeight;
        if (spaceVert < height && tView.resize !== false) {
          if (spaceVert < this.view.defaultLineHeight) {
            dom.style.top = Outside;
            continue;
          }
          knownHeight.set(tView, height);
          dom.style.height = (height = spaceVert) / scaleY + 'px';
        } else if (dom.style.height) {
          dom.style.height = '';
        }
        let top = above ? pos.top - height - arrowHeight - offset.y : pos.bottom + arrowHeight + offset.y;
        let right = left + width;
        if (tView.overlap !== true)
          for (let r of others)
            if (r.left < right && r.right > left && r.top < top + height && r.bottom > top)
              top = above ? r.top - height - 2 - arrowHeight : r.bottom + arrowHeight + 2;
        if (this.position == 'absolute') {
          dom.style.top = (top - measured.parent.top) / scaleY + 'px';
          dom.style.left = (left - measured.parent.left) / scaleX + 'px';
        } else {
          dom.style.top = top / scaleY + 'px';
          dom.style.left = left / scaleX + 'px';
        }
        if (arrow) {
          let arrowLeft = pos.left + (ltr ? offset.x : -offset.x) - (left + 14 /* Arrow.Offset */ - 7) /* Arrow.Size */;
          arrow.style.left = arrowLeft / scaleX + 'px';
        }
        if (tView.overlap !== true) others.push({ left, top, right, bottom: top + height });
        dom.classList.toggle('cm-tooltip-above', above);
        dom.classList.toggle('cm-tooltip-below', !above);
        if (tView.positioned) tView.positioned(measured.space);
      }
    }
    maybeMeasure() {
      if (this.manager.tooltips.length) {
        if (this.view.inView) this.view.requestMeasure(this.measureReq);
        if (this.inView != this.view.inView) {
          this.inView = this.view.inView;
          if (!this.inView) for (let tv of this.manager.tooltipViews) tv.dom.style.top = Outside;
        }
      }
    }
  },
  {
    eventObservers: {
      scroll() {
        this.maybeMeasure();
      },
    },
  }
);
