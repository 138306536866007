import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getProject } from 'Services/project';
import { fetchChannel, updateChannel } from '../actions';
import { ChannelForm } from './Form';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  channel: store.channel.detail[props.match.params.topicId],
  fetching: store.channel.fetching,
}))
export default class ChannelDetail extends Component {
  menu = [{ label: 'app_channelInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchChannel(targetId || id));
  };

  handleChannelChange = channel => {
    const { dispatch } = this.props;
    return dispatch(updateChannel(channel.id, channel));
  };

  render() {
    const {
      fetching,
      channel,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!channel) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="channel-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_channels',
                href: `#${getProject().url}/channels`,
              },
              { label: channel.name },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">
                {channel.name || `${m.app_channel}: ${channel.id}`}
              </Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="channels" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route
              exact
              path={match.url}
              render={() => (
                <ChannelForm onSubmit={this.handleChannelChange} data={channel} />
              )}
            />
          </Paper>
        }
      />
    );
  }
}
