import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
// @ts-ignore
import { withRouter } from 'react-router';
import { getProject } from '../../../common/services/project';
import { translateSurvey } from '../actions';
import { ISurvey } from '../typings';

interface IProps {
  tableData: ISurvey;
  intl: any;
  dispatch: any;
  history: any;
}

// @ts-ignore
@injectIntl
// @ts-ignore
@connect()
// @ts-ignore
@withRouter
export class SurveyRow extends Component<IProps> {
  public render() {
    const {
      tableData,
      intl: { messages: m },
    } = this.props;
    const { startDate, endDate, totalParticipantsCount } = tableData;

    return (
      // @ts-ignore href
      <TableRow hover href={this.getHref(tableData)} component="a">
        <TableCell>{this.getTranslation() ? this.getTranslation().question : '-'}</TableCell>
        <TableCell>{this.getIsAvailable() ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{this.getTranslation() && this.getTranslation().isEnabled ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{this.formatDate(startDate)}</TableCell>
        <TableCell>{this.formatDate(endDate)}</TableCell>
        <TableCell>{this.getTranslatedLocales()}</TableCell>
        <TableCell>{this.getTranslation() && this.getTranslation().hasPartner ? m.common_yes : m.common_no}</TableCell>
        <TableCell>{totalParticipantsCount}</TableCell>
        <TableCell>{this.renderTranslationButton(tableData)}</TableCell>
      </TableRow>
    );
  }

  private getTranslation() {
    const { tableData } = this.props;

    if (tableData.translations[getProject().lang]) return tableData.translations[getProject().lang];

    return tableData.translations[tableData.mainLocale];
  }

  private getTranslatedLocales() {
    const { tableData } = this.props;
    const locales = Object.keys(tableData.translations).filter(locale => locale !== getProject().lang);
    if (locales.length === 0) return '-';

    return locales.join(', ').toUpperCase();
  }

  private formatDate(date) {
    if (!date) return '-';

    return `${moment(date).format('DD/MM')} - ${moment(date).format('LT')}`;
  }

  private handleClick = () => {
    const { dispatch, tableData, history } = this.props;
    dispatch(translateSurvey(tableData.id)).then(() => {
      history.push(`${getProject().url}/surveys/${tableData.id}`);
    });
  };

  private renderTranslationButton = ({ mainLocale, translations }) => {
    const {
      intl: { messages: m },
    } = this.props;
    if (mainLocale === getProject().lang || translations[getProject().lang]) {
      return '-';
    }

    return (
      <Button onClick={this.handleClick} variant="contained" color="primary" data-test="ArticleList__buttonAdd">
        {m.field_translate}
      </Button>
    );
  };

  private getHref({ id, mainLocale, translations }) {
    if (mainLocale === getProject().lang || translations[getProject().lang]) {
      return `#${getProject().url}/surveys/${id}`;
    }

    return null;
  }

  private getIsAvailable() {
    const { tableData } = this.props;
    if (!tableData.translations[getProject().lang]) return false;
    if (!tableData.translations[getProject().lang].isEnabled) return false;
    if (tableData.endDate && moment(tableData.endDate).isBefore(moment())) return false;
    if (moment(tableData.startDate).isAfter(moment())) return false;

    return true;
  }
}
