import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { connect } from 'react-redux';
import { updatePartnerOffer } from '../actions';
import { PartnerOfferForm } from '../components/PartnerOfferForm';
import {IPartnerOffer} from "../typings";

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
});

// @ts-ignore
@connect()
// @ts-ignore
@withStyles(styles)
export class PartnerOffersPanels extends React.Component<any> {
  public state = {
    expanded: null,
  };

  public componentDidMount() {
    const anchor = window.location.hash.split("partner-offers#")[1];
    if (anchor) {
      this.setState({
        expanded: anchor,
      });
    }
  }

  // @ts-ignore
  public handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  public handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updatePartnerOffer(id, data));
  };

  public getPanelLabel = (partnerOffer: IPartnerOffer) =>
    [
      partnerOffer.shortText,
      partnerOffer.usedForTranslated,
    ]
      .filter(Boolean)
      .join(' - ');

  public renderPanel = partnerOffer => {
    if (!partnerOffer) return null;
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <Accordion
        id={partnerOffer.id}
        expanded={expanded === partnerOffer.id}
        onChange={this.handleExpand(partnerOffer.id)}
        classes={{ root: classes.panel }}
        ref={expanded === partnerOffer.id ? this.scrollTo : null}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{this.getPanelLabel(partnerOffer)}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detail }}>
          {expanded === partnerOffer.id && (
            <PartnerOfferForm onSubmit={this.handleChange(partnerOffer.id)} data={partnerOffer} />
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  public scrollTo = element => {
    if (element) {
      window.scrollTo(0, element.offsetTop);
    }
  };

  public render() {
    const { partnerOffers = [] } = this.props;

    return partnerOffers.map(this.renderPanel);
  }
}
