import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCollection, getItemBySlug } from 'Services/connectors';
import { Route } from 'react-router-dom';

import { fetchPlayer, updatePlayer } from '../actions';
import { addProduct, fetchPlayerProducts } from '../../../products/actions';
import { addPersonSponsor, fetchPlayerSponsors } from '../../../personSponsors/actions';
import { fetchPlayerMemberships } from '../../../memberships/actions';
import { fetchPlayerTransfers } from '../../../transfers/actions';

import RevisionNote from 'Components/revisions/Note';
import Revisions from 'Components/revisions';
import TabsRoute from 'Components/TabsRoute';
import { IdBadge } from 'Components/IdBadge';
import Form from './Form';
import Transfers from './Transfers';
import Products from './Products';
import Sponsors from './Sponsors';
import Injuries from './Injuries';
import Memberships from './Memberships';
import Breadcrumb from 'FDComponents/Breadcrumb';
import ReturnButton from 'Components/ReturnButton';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from 'Components/Layout';
import { getPersonUsualName } from '../../utils';
import { fetchPlayerInjuries } from '../../../injuries/actions';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  player: getItemBySlug(store.player, props.match.params.topicSlug),
  fetching: store.player.fetching,
  memberships: getCollection('membershipsIds', store.player, store.membership),
  transfers: getCollection('transfersIds', store.player, store.transfer),
  injuries: getCollection('injuriesIds', store.player, store.injury),
  products: getCollection('productsIds', store.player, store.product),
  personSponsors: getCollection('personSponsorsIds', store.player, store.personSponsor),
}))
export default class PlayerDetail extends Component {
  state = {
    showRevisions: false,
  };

  menu = [
    { label: 'app_personInfo', link: '/' },
    { label: 'app_transfers', link: '/transfers' },
    { label: 'app_products', link: '/products' },
    { label: 'app_sponsors', link: '/person-sponsors' },
    { label: 'app_injuries', link: '/injuries' },
  ];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchPlayer(targetSlug || slug)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchPlayerProducts(payload.id));
        dispatch(fetchPlayerMemberships(payload.id));
        dispatch(fetchPlayerSponsors(payload.id));
        dispatch(fetchPlayerTransfers(payload.id));
        dispatch(fetchPlayerInjuries(payload.id));
      }
    });
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handlePlayerChange = person => {
    const { dispatch } = this.props;
    return dispatch(updatePlayer(person.id, person));
  };

  handleProductAdd = values => {
    const { dispatch, player } = this.props;
    const product = {
      ...values,
      persons: [player['@id']],
    };
    return dispatch(addProduct(product, 'player'));
  };

  handleSponsorAdd = values => {
    const { dispatch, player } = this.props;
    const sponsor = {
      ...values,
      person: player['@id'],
    };
    return dispatch(addPersonSponsor(sponsor, 'player'));
  };

  render() {
    const {
      fetching,
      player,
      personSponsors,
      products,
      memberships,
      transfers,
      match,
      history,
      injuries,
      intl: { messages: m },
    } = this.props;

    if (!player && fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }

    if (!player) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="player-layout"
        top={<Breadcrumb />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{getPersonUsualName(player)}</Typography>
              <IdBadge id={player.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="players" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route
              exact
              path={match.url}
              render={() => (
                <div>
                  <div>
                    <RevisionNote
                      style={{ margin: 16 }}
                      createdAt={player.createdAt}
                      updatedAt={player.updatedAt}
                      toggle={this.toggleRevisions}
                    />
                    {this.state.showRevisions && (
                      <Revisions
                        entity="person"
                        id={player.id}
                        updatedAt={player.updatedAt}
                        onRollback={() => this.fetchData()}
                      />
                    )}
                  </div>
                  <Form onSubmit={this.handlePlayerChange} data={player} />
                </div>
              )}
            />
            <Route
              path={`${match.url}/transfers`}
              render={() => <Transfers transfers={transfers} url={`${match.url}/transfers`} history={history} />}
            />
            <Route
              path={`${match.url}/products`}
              render={() => (
                <Products
                  products={products}
                  onAdd={this.handleProductAdd}
                  url={`${match.url}/products`}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.url}/person-sponsors`}
              render={() => (
                <Sponsors
                  sponsors={personSponsors}
                  onAdd={this.handleSponsorAdd}
                  url={`${match.url}/person-sponsors`}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.url}/injuries`}
              render={() => <Injuries injuries={injuries} url={`${match.url}/injuries`} history={history} />}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Memberships memberships={memberships} />
          </Paper>
        }
      />
    );
  }
}
