import React from 'react';
import { formatUtc } from '../../../common/components/inputs/Datetime';
import { MatchPushForm } from '../../../footmercato/push/MatchForm';
import { getAppMatchLink } from '../../utils/href';
import { IComponentProps } from './Push.connector';

export class Push extends React.Component<IComponentProps> {
  private initialValues: { [key: string]: string | string[] } = {
    title: '',
    text: '',
    system: 'all',
    segment: 'global_matches',
    footdataCompetitionIds: [],
    footdataTeamIds: [],
    footdataMatchIds: [],
    footdataPersonIds: [],
    excludedFootdataCompetitionIds: [],
    excludedFootdataTeamIds: [],
    excludedFootdataMatchIds: [],
    excludedFootdataPersonIds: [],
  };

  public UNSAFE_componentWillMount() {
    const { matchId, game: match } = this.props;
    (this.initialValues.footdataMatchIds as string[]).push(matchId);
    if (match) {
      if (match.description) {
        this.initialValues.title = match.description;
      }
      if (match.homeTeam) {
        (this.initialValues.footdataTeamIds as string[]).push(match.homeTeam.id);
      }
      if (match.awayTeam) {
        (this.initialValues.footdataTeamIds as string[]).push(match.awayTeam.id);
      }
      if (match.phaseTournamentCompetitionId) {
        (this.initialValues.footdataCompetitionIds as string[]).push(
          match.phaseTournamentCompetitionId
        );
      }
    }
  }

  public render() {
    return (
      <div style={{ padding: 8, background: 'white' }}>
        <MatchPushForm onSubmit={this.submitPush} data={this.initialValues} />
      </div>
    );
  }

  private submitPush = form => {
    const { matchId, postPush, game } = this.props;
    const { segment, system, scheduledAt, ...otherFields } = form;

    const push = {
      targetSegmentId: segment,
      osSegmentId: system === 'all' ? undefined : system,
      scheduledAt: formatUtc(scheduledAt),
      targetUrl: getAppMatchLink(matchId, game?.slug),
      idempotencyKey: `fwm_${Date.now()}`,
      ...otherFields,
    };

    // @ts-ignore
    return postPush(push).then(() => ({ payload: this.initialValues }));
  };
}
