import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ClubAvatar from '../../clubs/Avatar';
import PersonAvatar from '../../people/Avatar';

@injectIntl
export default class Similars extends Component {
  render() {
    const {
      club,
      person,
      intl: { messages: m },
    } = this.props;
    return (
      <List dense subheader={<ListSubheader>{m.media_similarImages}</ListSubheader>}>
        {club && (
          <ListItem
            button
            component="a"
            href={`#${getProject().url}/images?search=${club.name}`}
          >
            <ClubAvatar club={club} />
            <ListItemText primary={club.name} />
          </ListItem>
        )}
        {person && (
          <ListItem
            button
            component="a"
            href={`#${getProject().url}/images?search=${person.firstName} ${
              person.lastName
            }`}
          >
            <ListItemIcon>
              <PersonAvatar person={person} />
            </ListItemIcon>
            <ListItemText primary={person.matchName} />
          </ListItem>
        )}
        {!person && !club && (
          <ListItem>
            <ListItemText primary={m.error_noData} />
          </ListItem>
        )}
      </List>
    );
  }
}
