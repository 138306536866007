import { IChronoUnit } from '../../../../commentaries/typings';
import { EPeriod, getPeriodInfo } from '../PeriodSelector/utils';

export const getChrono = (
  period: EPeriod,
  chronoStart: number,
  chronoEnd: number,
  offset: number = 0
): IChronoUnit[] => {
  const [periodStart, periodEnd] = getPeriodInfo(period);

  if (!chronoStart || periodStart === null || periodEnd === null) {
    return [null, null, null, null];
  }
  const lastTimeStamp = chronoEnd && chronoEnd > chronoStart ? chronoEnd : +new Date();

  const diff = new Date(lastTimeStamp - chronoStart + offset);
  const hours = diff.getHours() - 1;
  const periodMinutes = diff.getMinutes() + 60 * hours;
  let minutes = periodMinutes + periodStart;
  let secondes = diff.getSeconds();

  if (minutes < 0) {
    minutes += 1;
    secondes -= 60;
  }

  if (minutes >= periodEnd) {
    return [periodEnd, 0, minutes - periodEnd, secondes];
  }
  return [minutes, secondes, null, null];
};

export const formatChrono = (
  minutes: IChronoUnit,
  secondes: IChronoUnit,
  isDiff: boolean = false
) => {
  const formatedTime = `${formatChronoValues(minutes)}:${formatChronoValues(secondes)}`;

  if ((minutes !== null && minutes < 0) || (secondes !== null && secondes < 0)) {
    return `-${formatedTime}`;
  }
  if (isDiff) {
    return `+${formatedTime}`;
  }
  return formatedTime;
};

export const formatChronoValues = (n: IChronoUnit) => {
  if (n === null) return '--';
  const value = Math.abs(n);
  return `${value < 10 ? '0' : ''}${value}`;
};

export const isSyncWithChrono = (
  chronoPeriod: EPeriod,
  chronoStart: number,
  chronoEnd: number,
  timeMin: IChronoUnit,
  additionnalTimeMin: IChronoUnit,
  period: EPeriod
) => {
  const [chronoTimeMin, , chronoAdditionnalTimeMin] = getMatchChrono(
    chronoPeriod,
    chronoStart,
    chronoEnd
  );

  return (
    period === chronoPeriod &&
    timeMin === chronoTimeMin &&
    additionnalTimeMin === chronoAdditionnalTimeMin
  );
};

export const getMatchChrono = (
  period: EPeriod,
  chronoStart: number,
  chronoEnd: number
) => {
  const chrono = getChrono(period, chronoStart, chronoEnd);
  const [chronoTimeMin, , chronoAdditionnalTimeMin] = chrono;
  const additionnalTimeMin =
    chronoAdditionnalTimeMin === null
      ? chronoAdditionnalTimeMin
      : chronoAdditionnalTimeMin + 1;
  const timeMin =
    additionnalTimeMin || chronoTimeMin === null ? chronoTimeMin : chronoTimeMin + 1;
  return [timeMin, chrono[1], additionnalTimeMin, chrono[3]];
};
