import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

export function updatePersonSponsor(id, sponsor) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_PERSONSPONSOR', payload: id });

    return rest.put(`person_sponsors/${id}`, sponsor).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_sponsorUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_PERSONSPONSOR_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PERSONSPONSOR_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addPersonSponsor(sponsor, target = '') {
  const entity = target ? `_${target.toUpperCase()}` : target;
  return function(dispatch) {
    dispatch({ type: `ADD${entity}_PERSONSPONSOR` });

    return rest.post('person_sponsors', sponsor).then(
      res =>
        dispatch({
          type: `ADD${entity}_PERSONSPONSOR_FULFILLED`,
          payload: res.data,
        }),
      err =>
        dispatch({
          type: `ADD${entity}_PERSONSPONSOR_REJECTED`,
          payload: err.response,
        })
    );
  };
}

export function deletePersonSponsor(sponsor) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_PERSONSPONSOR' });

    return rest.delete(`person_sponsors/${sponsor.id}`).then(
      () =>
        dispatch({
          type: 'DELETE_PERSONSPONSOR_FULFILLED',
          payload: sponsor,
        }),
      err =>
        dispatch({
          type: 'DELETE_PERSONSPONSOR_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchPlayerSponsors(person) {
  const params = { person };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYER_PERSONSPONSORS', payload: person });

    return rest.get('person_sponsors', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PLAYER_PERSONSPONSORS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PLAYER_PERSONSPONSORS_REJECTED',
          payload: err.response,
        })
    );
  };
}
