import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Live as LiveComponent } from './Live.component';
import { connector } from './Live.connector';

const Live = connector(LiveComponent);

export const LiveRoutes = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/:topicId`} component={Live} />
    </Switch>
  );
};
