import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchFocusBlocks } from "FM/highlighting/components/focusBlocks/actions";
import FocusBlock from './FocusBlock';
import fetchConstants from '../../../components/constants/actions';

@injectIntl
@connect(store => ({
    focusBlock: store.homepage.focusBlock,
    fetching: store.homepage.fetching,
    keyCode: store.constantsFM.focusBlock.keyCode,
}))
export default class FocusBlocks extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(fetchConstants('focusBlock', 'keyCode'));
        dispatch(fetchFocusBlocks());

    }

    render() {
        const {
            fetching = true,
            keyCode
        } = this.props;

        if (fetching || !keyCode || keyCode.fetching) {
            return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
        }

        return (
            this.renderFocusBlocks()
        );
    }

    renderFocusBlocks() {
        const { focusBlock } = this.props;
        return focusBlock && focusBlock.map(entry => <FocusBlock item={entry} label={this.getLabel(entry.keyCode)} />);
    }

    getLabel = (key) => {
        const { keyCode } = this.props;

        return keyCode.list[key] || key;
    }
}
