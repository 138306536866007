import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BookmakerDetail } from './Detail';
import { BookmakersList } from './List';

export const BookmakersRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={BookmakersList} />
      <Route path={`${match.url}/:topicId`} component={BookmakerDetail} />
    </Switch>
  );
};
