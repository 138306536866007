import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function SubstituteInIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          fill="green"
          d="M347 247l-182 0 89 183z"
        />
      </g>
    </SvgIcon>
  );
}
