import { IMembership } from '../memberships/typings';
import { IMatchTeamPlayer } from './typings';

export const createMatchTeamPlayerFromMembership = (
  membership: IMembership
): IMatchTeamPlayer => {
  const shirtNumber =
    membership.shirtNumber !== null ? Number(membership.shirtNumber) : null;
  return { membership, shirtNumber, onBench: undefined };
};
