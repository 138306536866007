import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';

export function fetchPhase(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_PHASE', payload: id });

    return rest
      .get(`phases/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_PHASE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PHASE_REJECTED', payload: err.response })
      );
  };
}

export function fetchPhaseWithTournament(id) {
  const params = {
    groups: ['read', '_tournament'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_PHASE', payload: id });

    return rest
      .get(`phases/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_PHASE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PHASE_REJECTED', payload: err.response })
      );
  };
}

export function updatePhase(id, phase) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_PHASE', payload: id });

    return rest.put(`phases/${id}`, phase).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_phaseUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_PHASE_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PHASE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchTournamentPhase(id) {
  const params = {
    groups: ['read', '_children', '_children_matches'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_TOURNAMENT_PHASE', payload: id });

    return rest.get(`phases/${id}`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_TOURNAMENT_PHASE_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_TOURNAMENT_PHASE_REJECTED',
          payload: err.response,
        })
    );
  };
}
