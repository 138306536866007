import { IFormConfig, IFormField } from './typings';
interface IObject {
  [key: string]: any;
}

export const getInitialValues = (raw: IObject, config: IFormConfig) => {
  const data: IObject = {};
  const { fields, objectFields, collectionObjectFields } = config;
  if (fields) {
    fields.forEach(fieldName => (data[fieldName] = fieldName === 'footballManagerUuid' ? raw[fieldName]?.toString() : raw[fieldName]));
  }
  if (objectFields) {
    objectFields.forEach(fieldName => (data[fieldName] = getObjectValue(raw[fieldName])));
  }
  if (collectionObjectFields) {
    collectionObjectFields.forEach(
      fieldName => (data[fieldName] = getCollectionObjectValue(raw[fieldName]))
    );
  }
  return data;
};

const getObjectValue = (object: IObject): string => (object ? object['@id'] : null);
const getCollectionObjectValue = (collectionObject: IObject[]) =>
  collectionObject ? collectionObject.map(item => item['@id']) : [];


export const addFieldConfigToDisposition = (config : IFormConfig, fieldConfig : IFormField, position: number) => {
  config.disposition[0].fields.splice(position, 0, fieldConfig)
  return config;
}

