// Copied TooltipHost from '@uiw/react-codemirror'
// node_modules/@codemirror/view/dist/index.js
// @ts-nocheck

import { TooltipViewManager } from './TooltipViewManager';
import { showHoverTooltip } from './utils';

export class HoverTooltipHost {
  view: any;
  mounted: boolean;
  dom: HTMLDivElement;
  manager: TooltipViewManager;
  // Needs to be static so that host tooltip instances always match
  static create(view) {
    return new HoverTooltipHost(view);
  }
  constructor(view) {
    this.view = view;
    this.mounted = false;
    this.dom = document.createElement('div');
    this.dom.classList.add('cm-tooltip-hover');
    this.manager = new TooltipViewManager(
      view,
      showHoverTooltip,
      (t, p) => this.createHostedView(t, p),
      t => t.dom.remove()
    );
  }
  createHostedView(tooltip, prev) {
    let hostedView = tooltip.create(this.view);
    hostedView.dom.classList.add('cm-tooltip-section');
    this.dom.insertBefore(hostedView.dom, prev ? prev.dom.nextSibling : this.dom.firstChild);
    if (this.mounted && hostedView.mount) hostedView.mount(this.view);
    return hostedView;
  }
  mount(view) {
    for (let hostedView of this.manager.tooltipViews) {
      if (hostedView.mount) hostedView.mount(view);
    }
    this.mounted = true;
  }
  positioned(space) {
    for (let hostedView of this.manager.tooltipViews) {
      if (hostedView.positioned) hostedView.positioned(space);
    }
  }
  update(update) {
    this.manager.update(update);
  }
  destroy() {
    var _a;
    for (let t of this.manager.tooltipViews) (_a = t.destroy) === null || _a === void 0 ? void 0 : _a.call(t);
  }
  passProp(name) {
    let value = undefined;
    for (let view of this.manager.tooltipViews) {
      let given = view[name];
      if (given !== undefined) {
        if (value === undefined) value = given;
        else if (value !== given) return undefined;
      }
    }
    return value;
  }
  get offset() {
    return this.passProp('offset');
  }
  get getCoords() {
    return this.passProp('getCoords');
  }
  get overlap() {
    return this.passProp('overlap');
  }
  get resize() {
    return this.passProp('resize');
  }
}
