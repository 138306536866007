import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { addProduct, deleteProduct } from '../../../../footdata/products/actions';

interface IOwnProps {
  productsIds: string[];
  onAdd: (id: string) => void;
  onRemove: (id: string) => void;
}

const mapDispatchToProps = {
  addProduct,
  deleteProduct,
};

export type IComponentProps = typeof mapDispatchToProps & InjectedIntlProps & IOwnProps;

const reduxConnector = connect(
  null,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
