import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProject } from 'Services/project';

import { addPageseo, fetchPageseos } from '../actions';
import filterList from 'Actions/lists';

import debounce from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';

import { SearchFilter } from '../../../common/components/SearchFilter';
import { TableList } from '../../../common/components/TableList';
import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';

import Row from './Row';
import PageseoDialog from '../Dialog';
import TopBar from '../../components/TopBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

@injectIntl
@connect((store, props) => ({
  pageseos: store.pageseos.list,
  fetching: store.pageseos.fetching,
  pagination: store.pageseos.pagination,
  total: store.pageseos.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Pageseos extends Component {
  config = {
    filters: [
      {
        type: 'url',
        labelKey: 'filter_url',
      },
    ],
    headers: [{ id: 'url', labelKey: 'field_url' }, { labelKey: 'field_seoMetaTitle' }],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchPageseos(filter));
    dispatch(filterList('pageseos', filter));
  };

  handleCreate = pageseo => {
    const { dispatch, history } = this.props;
    dispatch(addPageseo(pageseo)).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/pageseos/${payload.id}`);
      }
    });
  };

  handleFilters = change => {
    const { url } = change;
    const filtersQuery = {
      url,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/pageseos${search}`);
    }
  }, 500);

  render() {
    const {
      pageseos,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="pageseo-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_pageseos' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_pageseosList}</Typography>
              <div style={{ flex: 1 }} />

              <PageseoDialog
                title={m.dialog_addPageseo}
                form="pageseo_add"
                onCreate={this.handleCreate}
              >
                <Button variant="contained" color="primary">
                  <AddIcon style={{ marginRight: 8 }} />
                  {m.button_add}
                </Button>
              </PageseoDialog>
            </Toolbar>
            <SearchFilter
              form="pageseo_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={pageseos}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
