import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../../typings';
import { ICommentary } from '../../../../../commentaries/typings';
import { getLiveMembership, getLiveTeam } from '../../../selector';

interface IOwnProps {
  commentary: ICommentary;
}

const mapDispatchToProps = {};
const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const teamSide = ownProps.commentary.matchEvent.matchTeamPosition;
  const mtpId = ownProps.commentary.matchEvent.matchTeamPlayerId;
  return {
    team: getLiveTeam(state, teamSide),
    membership: getLiveMembership(state, mtpId),
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps &
  InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
