import { TableCellProps, withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { styles } from './styles';

interface IHeader {
  id: string;
  padding?: TableCellProps['padding'];
  size?: TableCellProps['size'];
  labelKey: string;
}

interface IOwnProps {
  loading: boolean;
  onPaginate: () => void;
  onSort: (s: string) => void;
  total: number;
  pagination: any;
  tableHeaders: IHeader[];
  query: any;
  tableData: any;
  Row: any;
  rowCallback?: (r: any) => void;
}

export type IComponentProps = WithStyles & InjectedIntlProps & IOwnProps;

export const connector = compose<IComponentProps, IOwnProps>(
  withStyles(styles),
  injectIntl
);
