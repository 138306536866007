import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { setTypeLive, setStrictModeLive } from '../../actions';
import { styles } from './styles';

const mapDispatchToProps = {
  setTypeLive,
  setStrictModeLive,
};

const mapStateToProps = (state: IRootState) => ({
  type: state.live.commentary.type,
  editingCommentaryId: state.live.editingCommentaryId,
  period: state.live.period,
  strictMode: state.live.strictMode,
  mainCommentatorLocale: state.live.mainCommentatorLocale
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles &
  InjectedIntlProps;
const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
