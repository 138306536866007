import { IMatchTeamPlayer } from '../matchTeamPlayers/typings';
import {
  EPosition,
  IComposition,
  ICompositionPositions,
  ITeamComposition,
} from './typings';

// We use composition with array subtitutes instead of object
export const decodeComposition = (
  composition: any
): { home: ITeamComposition | null; away: ITeamComposition | null } => {
  const home = decodeTeamComposition(composition.home);
  const away = decodeTeamComposition(composition.away);
  return { home, away };
};

const filterMTPWithoutShirtNumber = (mtp: IMatchTeamPlayer) =>
  Number.isInteger(mtp.shirtNumber as number);
const filterMTPWithoutPositionSide = (mtp: IMatchTeamPlayer) =>
  mtp.positionSide || mtp.position === EPosition.Goalkeeper;

export const decodeTeamComposition = (teamComposition): ITeamComposition | null => {
  if (Array.isArray(teamComposition)) return null;

  // transform subs: {} => []
  const substitutes = teamComposition.substitutes
    ? (Object.values(teamComposition.substitutes) as IMatchTeamPlayer[]).filter(
        filterMTPWithoutShirtNumber
      )
    : [];

  // remove field players with no positionSide
  const positions: ICompositionPositions = {};
  for (const position in teamComposition.positions) {
    if (teamComposition.positions.hasOwnProperty(position)) {
      positions[position] = teamComposition.positions[position]
        .filter(filterMTPWithoutShirtNumber)
        .filter(filterMTPWithoutPositionSide);
    }
  }

  return {
    ...teamComposition,
    substitutes,
    positions,
  };
};

// We post composition with object substitutes instead of array
export const encodeComposition = (composition: any): IComposition => {
  return {
    home: {
      ...composition.home,
      substitutes: { ...composition.home.substitutes },
    },
    away: {
      ...composition.away,
      substitutes: { ...composition.away.substitutes },
    },
  };
};

export const getNumberOfPlayer = (teamComposition: ITeamComposition): number => {
  let count = 0;
  for (const position in teamComposition.positions) {
    if (teamComposition.positions.hasOwnProperty(position)) {
      count += teamComposition.positions[position].length;
    }
  }
  return count;
};
