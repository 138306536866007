import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import SelectConstant from 'FDComponents/constants/Select';
import TextInput from 'Components/inputs/Text';
import AreaInput from 'FDComponents/areas/Input';
import ImageInput from '../../../images/Input';
import Date from 'Components/inputs/Date';
import { AgentCompanySelect } from '../../../agentCompanies/components/AgentCompanySelect';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'firstName',
      'shortFirstName',
      'lastName',
      'shortLastName',
      'nickName',
      'role',
      'gender',
      'birthDate',
      'placeOfBirth',
      'twitterLink',
      'facebookLink',
      'instagramLink',
      'websiteLink',
      'priority',
      'metaImportGSMUuid',
    ],
    objectFields: ['mainNationality', 'secondNationality', 'photo', 'company'],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'photo', component: ImageInput }],
      },
      {
        size: 9,
        fields: [
          { name: 'firstName', component: TextInput },
          { name: 'shortFirstName', component: TextInput },
          { name: 'lastName', component: TextInput },
          { name: 'shortLastName', component: TextInput },
          { name: 'nickName', component: TextInput },
        ],
      },
      {
        fields: [
          {
            name: 'role',
            component: SelectConstant,
            entity: 'person',
            size: 6,
          },
          {
            name: 'gender',
            component: SelectConstant,
            entity: 'person',
            size: 6,
          },
          { name: 'birthDate', component: Date, size: 6 },
          { name: 'placeOfBirth', component: TextInput, size: 6 },
          { name: 'mainNationality', component: AreaInput, size: 6 },
          { name: 'secondNationality', component: AreaInput, size: 6 },
          { name: 'company', component: AgentCompanySelect, size: 6 },
          {
            name: 'priority',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          { name: 'metaImportGSMUuid', component: TextInput, size: 6 },
        ],
      },
      {
        size: 6,
        title: 'form_socialLinks',
        fields: [
          {
            name: 'facebookLink',
            component: TextInput,
            hintText: 'https://www.facebook.com/user',
          },
          {
            name: 'twitterLink',
            component: TextInput,
            hintText: 'https://twitter.com/user',
          },
          {
            name: 'instagramLink',
            component: TextInput,
            hintText: 'https://instagram.com/user',
          },
          {
            name: 'websiteLink',
            component: TextInput,
            hintText: 'http://www.link.com',
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`agent_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
