import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getLinkId } from '../../../common/services/tools';
import { getPersonId } from '../actions';
import { getPersonUsualNameNationalityAge } from '../utils';

// @ts-ignore
@injectIntl
// @ts-ignore
@connect((store: any, props: any) => ({
  person: store.person.detail[getLinkId(props.children)],
}))
export class PersonCollectionChip extends React.Component<any> {
  public componentDidMount() {
    const { dispatch, children } = this.props;
    return dispatch(getPersonId(getLinkId(children)));
  }
  public render() {
    const {
      children,
      removeProps,
      person,
      intl: { messages: m },
    } = this.props;
    const label = person ? getPersonUsualNameNationalityAge(person, m.unit_short_years_old) : children;
    const deleteIcon = person ? <CancelIcon {...removeProps} /> : <CircularProgress size={24} />;
    return <Chip tabIndex={-1} label={label} onDelete={removeProps.onClick} deleteIcon={deleteIcon} />;
  }
}
