// use of events to transform non-async method into async
import EventEmitter from 'events';
const myEmitter = new EventEmitter();

export function asyncEmbed() {
    return function(dispatch) {
        dispatch({
            type: 'EDITOR_OPEN_EMBED',
        });

        return new Promise(resolve => {
            myEmitter.on('closeEmbed', resolve);
        });
    };
}

export function closeEmbed(response) {
    myEmitter.emit('closeEmbed', response);

    return {
        type: 'EDITOR_CLOSE_EMBED',
        payload: { response },
    };
}
