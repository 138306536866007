import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import CompetitionAvatar from '../Avatar';
import { getProject } from '../../../common/services/project';

const styles = {
  rowContent: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: 16,
  },
};

@withStyles(styles)
export default class Row extends Component {
  render() {
    const { classes, tableData } = this.props;
    const { name, area, type, priority } = tableData;
    const href = `#${getProject().url}/competitions/${tableData.area.slug}/${
      tableData.slug
    }`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>
          <div className={classes.rowContent}>
            <CompetitionAvatar data={tableData} />
            <div className={classes.label}>{name}</div>
          </div>
        </TableCell>
        <TableCell>{area && area.name}</TableCell>
        <TableCell>{type}</TableCell>
        <TableCell>{priority}</TableCell>
      </TableRow>
    );
  }
}
