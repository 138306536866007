import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import { IdBadge } from '../../../common/components/IdBadge';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { PartnerForm } from '../components/DetailForm';
import { PartnerOffers } from '../components/PartnerOffers';
import { IPartner } from '../typings';
import { IComponentProps } from './PartnerDetail.connector';

export class PartnerDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_info', link: '/' }, { label: 'app_partnerOffers', link: '/partner-offers' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      partner,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!partner) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="partner-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[{ labelKey: 'app_partners', href: `#${getProject().url}/partners` }, { label: partner.name }]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{partner.name}</Typography>
              <IdBadge id={partner.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="partners" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
            <Route exact path={`${match.url}/partner-offers`} render={this.renderPartnerOffers} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { partner } = this.props;
    return <PartnerForm onSubmit={this.handleUpdate} data={partner} />;
  };

  private renderPartnerOffers = () => {
    const { partner } = this.props;
    return <PartnerOffers partner={partner} />;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchPartner } = this.props;
    fetchPartner(targetId || id);
  };

  private handleUpdate = (partner: IPartner) => {
    const { id, updatePartner } = this.props;
    return updatePartner(id, partner);
  };
}
