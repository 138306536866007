import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { updatePersonSponsor, deletePersonSponsor } from '../actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Form from './Form';
import moment from 'moment';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
});

@connect()
@withStyles(styles)
export default class PersonSponsorsPanels extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updatePersonSponsor(id, data));
  };
  handleRemove = data => () => {
    const { dispatch } = this.props;
    return dispatch(deletePersonSponsor(data));
  };

  renderPanel = personSponsor => {
    const { classes } = this.props;
    const { expanded } = this.state;

    if (!personSponsor) return null;

    return (
      <ExpansionPanel
        expanded={expanded === personSponsor.id}
        onChange={this.handleExpand(personSponsor.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{this.renderTitle(personSponsor)}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === personSponsor.id && (
            <Form
              onSubmit={this.handleChange(personSponsor.id)}
              data={personSponsor}
              onDelete={this.handleRemove(personSponsor)}
            />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { sponsors = [] } = this.props;
    return sponsors.sort(this.sortPersonSponsors).map(this.renderPanel);
  }

  renderTitle = personSponsor => {
    let title = personSponsor.typeTranslated;
    if (personSponsor.startedAt) {
      const date = moment(personSponsor.startedAt).format('L');
      title += ` (${date})`;
    }
    return title;
  };

  sortPersonSponsors = (a, b) => {
    if (!a.startedAt) {
      return -1;
    }
    if (!b.startedAt) {
      return 1;
    }
    return a.startedAt < b.startedAt ? 1 : -1;
  };
}
