import rest from 'Services/restFootData';

export function fetchCountryCompetitions(area) {
  const params = {
    area,
    pagination: 0,
    groups: ['read_link'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BREADCRUMB_COMPETITIONS' });
    return rest.get(`competitions`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_BREADCRUMB_COMPETITIONS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_BREADCRUMB_COMPETITIONS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchCompetitionClubs(club_competition) {
  const params = {
    club_competition,
    pagination: 0,
    groups: ['read_link'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BREADCRUMB_CLUBS' });
    return rest.get(`clubs`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_BREADCRUMB_CLUBS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_BREADCRUMB_CLUBS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchClubPlayers(club) {
  const params = {
    club,
    role: 'player',
    pagination: 0,
    groups: ['read_link'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BREADCRUMB_PLAYERS' });
    return rest.get(`people`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_BREADCRUMB_PLAYERS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_BREADCRUMB_PLAYERS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function getClubCompetition(slug) {
  const params = {
    slug,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BREADCRUMB_CLUBCOMPETITION' });

    return rest.get('clubs/with_tournament/', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_BREADCRUMB_CLUBCOMPETITION_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_BREADCRUMB_CLUBCOMPETITION_NOTFOUND' });
      },
      err =>
        dispatch({
          type: 'FETCH_BREADCRUMB_CLUBCOMPETITION_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function selectCountry(country) {
  return { type: 'SELECT_BREADCRUMB_COUNTRY', payload: country };
}
export function selectCompetition(competition) {
  return { type: 'SELECT_BREADCRUMB_COMPETITION', payload: competition };
}
export function selectClub(club) {
  return { type: 'SELECT_BREADCRUMB_CLUB', payload: club };
}
export function selectPlayer(player) {
  return { type: 'SELECT_BREADCRUMB_PLAYER', payload: player };
}

export function resetBreadcrumb() {
  return { type: 'RESET_BREADCRUMB' };
}
