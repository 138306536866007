export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'FETCH_CONFIGURATION': {
      return {
        ...state,
        fetching: true,
      };
    }

    case 'FETCH_CONFIGURATION_GLOBAL_FULFILLED': {
      return {
        ...state,
        fetching: false,
        global: action.payload,
      };
    }
    case 'FETCH_CONFIGURATION_MOBILE_FULFILLED': {
      return {
        ...state,
        fetching: false,
        mobile: action.payload,
      };
    }
    case 'UPDATE_CONFIGURATION_GLOBAL_FULFILLED': {
      return {
        ...state,
        global: action.payload,
      };
    }
    case 'UPDATE_CONFIGURATION_MOBILE_FULFILLED': {
      return {
        ...state,
        mobile: action.payload,
      };
    }

    default:
      return state;
  }
}
