import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IdBadge } from 'Components/IdBadge';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCollection, getObject } from 'Services/connectors';
import { fetchTournamentClassement } from '../../classements/actions';
import { getCompetitionHref, getCompetitionsHref } from '../../competitions/utils';
import { fetchTournamentPhase } from '../../phases/actions';
import { fetchCompetitionTournaments, fetchTournament } from '../actions';
import { fetchTournamentRankingRules } from '../rankingRules/actions';
import Championship from './Championship';
import Cup from './Cup';
import Tournaments from './Tournaments';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  tournament: store.tournament.detail[props.match.params.topicId],
  tournaments: getCollection('tournamentsIds', store.competition, store.tournament),
  phase: getObject('phaseId', store.tournament, store.phase),
  classement: getObject('classementId', store.tournament, store.classement),
  fetching: store.tournament.fetching,
}))
export default class Detail extends Component {
  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchTournament(targetId || id)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchCompetitionTournaments(payload.competitionId)).then(() => {
          // issue fetchCompetitionTournaments delete rankingRulesIds of main tournament
          dispatch(fetchTournamentRankingRules(id));
        });
      }
      if (payload && payload.phaseId) {
        dispatch(fetchTournamentPhase(payload.phaseId)).then(({ payload }) => {
          dispatch(fetchTournamentClassement(payload.children[0].id, 'total'));
        });
      }
    });
  };

  getLabel = () => {
    const { tournament } = this.props;
    return `${tournament.competitionName} ${tournament.name}`;
  };

  render() {
    const {
      fetching,
      tournament,
      tournaments,
      phase,
      classement,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!tournament) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="tournament-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_competitions', href: getCompetitionsHref() },
              {
                label: tournament.competitionName,
                href: getCompetitionHref(
                  tournament.competitionAreaSlug,
                  tournament.competitionSlug
                ),
              },
              { labelKey: 'app_tournaments' },
              { label: tournament.name },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{this.getLabel()}</Typography>
              <IdBadge id={tournament.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="tournaments" />
            </Toolbar>
            {tournament.competitionType == 'championship' && (
              <Championship
                match={match}
                history={history}
                tournament={tournament}
                classement={classement}
                rankingRulesIds={tournament.rankingRulesIds}
                phase={phase}
              />
            )}
            {tournament.competitionType == 'cup' && (
              <Cup
                match={match}
                history={history}
                tournament={tournament}
                rankingRulesIds={tournament.rankingRulesIds}
                phase={phase}
              />
            )}
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Tournaments tournaments={tournaments} />
          </Paper>
        }
      />
    );
  }
}
