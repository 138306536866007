import moment from 'moment';
import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import { getApiFormatDatetime } from '../../common/services/datetime';
import rest from '../../common/services/restFootMercato';
import { ICollection } from '../../common/services/typings';
import { IArticle } from './typings';

type ISearch = ICollection<any>;

export function fetchArticles(search: string) {
  const params = {
    itemsPerPage: 10,
    partial: 0,
    groups: ['read', '_authors', '_tags', '_stats'],
    ...qs.parse(search),
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES' });

    return rest
      .get('articles', { params })
      .then(
        res => dispatch({ type: 'FETCH_ARTICLES_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_ARTICLES_REJECTED', payload: err.response })
      );
  };
}

export function fetchArticle(id) {
  const params = {
    groups: ['read', 'read_details', '_authors', '_tags', '_stats', '_brief', '_embed'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLE' });

    return rest.get(`articles/${id}`, { params }).then(
      res => {
        if (res.data.briefs) {
          dispatch({
            type: 'FETCH_ARTICLE_BRIEFS_FULFILLED',
            payload: { parentId: id, collection: res.data.briefs },
          });
        }

        return dispatch({
          type: 'FETCH_ARTICLE_FULFILLED',
          payload: res.data,
        });
      },
      err => dispatch({ type: 'FETCH_ARTICLE_REJECTED', payload: err.response })
    );
  };
}

export function fetchArticleId(id: string) {
  const params = {
    groups: ['read', '_stats'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLE' });

    return rest
      .get(`articles/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_ARTICLE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_ARTICLE_REJECTED', payload: err.response })
      );
  };
}

export function updateArticle(id: string, article: Partial<IArticle>) {
  const params = {
    groups: ['read', 'read_details', '_authors', '_tags', '_stats', '_brief', '_embed'],
  };
  return function(dispatch) {
    dispatch({ type: 'UPDATE_ARTICLE', payload: id });
    return rest.put(`articles/${id}`, article, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_articleUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_ARTICLE_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_ARTICLE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchNewestArticles(search?: ISearch) {
  const now = getApiFormatDatetime();
  const params = {
    'publishedAt[after]': moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
    'publishedAt[before]': now,
    status: 'publish',
    'order[publishedAt]': 'desc',
    itemsPerPage: 5,
    partial: 0,
    groups: ['read', '_authors', '_tags', '_stats'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_NEWEST' });
    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ARTICLES_NEWEST_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ARTICLES_NEWEST_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchOwnPostPublishedArticles(search?: ISearch) {
  const now = getApiFormatDatetime();
  const params = {
    itemsPerPage: 10,
    'publishedAt[after]': now,
    status: 'publish',
    'order[createdAt]': 'asc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_POSTPUBLISHED' });
    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ARTICLES_POSTPUBLISHED_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ARTICLES_POSTPUBLISHED_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchFixedArticles(search?: ISearch) {
  const now = getApiFormatDatetime();
  const params = {
    itemsPerPage: 10,
    'publishedAt[before]': now,
    withFixedPosition: 1,
    status: 'publish',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_FIXED' });
    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ARTICLES_FIXED_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ARTICLES_FIXED_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchOwnPrewrittenArticles(search?: ISearch) {
  const params = {
    itemsPerPage: 10,
    status: 'prewritten',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_PREWRITTEN' });
    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ARTICLES_PREWRITTEN_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ARTICLES_PREWRITTEN_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchTemplateArticles(search?: ISearch) {
  const params = {
    itemsPerPage: 10,
    status: 'template',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_TEMPLATE' });
    return rest.get('articles', { params }).then(
        res =>
            dispatch({
              type: 'FETCH_ARTICLES_TEMPLATE_FULFILLED',
              payload: res.data,
            }),
        err =>
            dispatch({
              type: 'FETCH_ARTICLES_TEMPLATE_REJECTED',
              payload: err.response,
            })
    );
  };
}

export function fetchOwnInProgressArticles(search?: ISearch) {
  const params = {
    itemsPerPage: 10,
    status: 'in_progress',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_INPROGRESS' });
    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ARTICLES_INPROGRESS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ARTICLES_INPROGRESS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchProposalArticles(search?: ISearch) {
  const params = {
    itemsPerPage: 10,
    status: 'proposal',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_PROPOSAL' });
    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_ARTICLES_PROPOSAL_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_ARTICLES_PROPOSAL_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchRestrictedArticles(search?: ISearch) {
  const params = {
    itemsPerPage: 10,
    status: 'restricted',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_ARTICLES_RESTRICTED' });
    return rest.get('articles', { params }).then(
        res =>
            dispatch({
              type: 'FETCH_ARTICLES_RESTRICTED_FULFILLED',
              payload: res.data,
            }),
        err =>
            dispatch({
              type: 'FETCH_ARTICLES_RESTRICTED_REJECTED',
              payload: err.response,
            })
    );
  };
}

export function addArticle(article: IArticle) {
  return function(dispatch) {
    dispatch({ type: 'ADD_ARTICLE' });

    return rest
      .post('articles', article)
      .then(
        res => dispatch({ type: 'ADD_ARTICLE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_ARTICLE_REJECTED', payload: err.response })
      );
  };
}

export function callTweetExtraction(tweetId) {
  return function(dispatch) {
    dispatch({ type: 'CALL_TWEET_EXTRACTION' });
    return rest.post(`twitter/extract/${tweetId}`).then(
      res =>
        dispatch({
          type: `CALL_TWEET_EXTRACTION_FULFILLED`,
          payload: res.data,
        }),
      err =>
        dispatch({
          type: `CALL_TWEET_EXTRACTION_REJECTED`,
          payload: err.response,
        })
    );
  };
}

export function briefToFlash(briefId: string) {
  return dispatch => {
    dispatch({ type: 'ADD_ARTICLE' });
    return rest
      .post(`brief-to-flash/${briefId}`)
      .then(
        res => dispatch({ type: 'ADD_ARTICLE_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_ARTICLE_REJECTED', payload: err.response })
      );
  };
}

export function briefFromArticle(articleId: string) {
  return dispatch => {
    dispatch({ type: 'ADD_BRIEF' });
    return rest
      .post(`brief-from-article/${articleId}`)
      .then(
        res => dispatch({ type: 'ADD_BRIEF_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_BRIEF_REJECTED', payload: err.response })
      );
  };
}

export function fetchTagArticles(tags) {
  const params = {
    'tags.id': tags,
    'order[createdAt]': 'desc',
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TAG_ARTICLES', payload: tags });

    return rest.get('articles', { params }).then(
      res => dispatch({ type: 'FETCH_TAG_ARTICLES_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_TAG_ARTICLES_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchAuthorArticles(authors) {
  const params = {
    authors,
    partial: false,
    'order[createdAt]': 'desc',
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_AUTHOR_ARTICLES', payload: authors });

    return rest.get('articles', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_AUTHOR_ARTICLES_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_AUTHOR_ARTICLES_REJECTED',
          payload: err.response,
        })
    );
  };
}
