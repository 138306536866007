import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function ActionIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M361 412c22 0 41 18 41 41 0 22-18 41-41 41-23 0-41-18-41-41 0-23 18-41 41-41z m87-96l-20 17-51 52c-5 4-12 7-19 7l-77 0-143 3c-5 1-11-1-16-5-8-8-9-20-2-29 4-5 10-7 16-7l97 0 52 0-77-92-1 0-42-50c-1-1-2-3-3-5-7-13-5-29 8-36 11-5 27-2 35 7l53 63 3 4 6-4-2-2-145-176c-2-2-3-4-4-6-7-12-3-28 10-35 11-6 24-4 32 5l167 196 69 85 11-9-47-59c-3-4-4-9-4-14 1-11 11-20 22-20 5 0 10 2 13 6l57 69c18 20 2 35 2 35z m-355-109c-19 0-35-15-35-35 0-19 16-35 35-35 19 0 35 16 35 35 0 20-16 35-35 35z m1-11l10 8c6-2 11-6 15-11l-4-13-11-3-10 7 0 12z m-13 7l11-7 0-12-10-7-11 4-4 11c4 6 9 9 14 11z m-15-50c-4 6-6 12-6 18l10 8 11-4 4-12-7-9-12 0z m36-12c-3-1-6-2-9-2-3 0-6 1-10 2l-4 12 7 9 13 0 7-9z m5 12l-6 10 3 12 11 4 11-8c-1-6-3-12-6-18z"
        />
      </g>
    </SvgIcon>
  );
}

