export const markdownEditorReducer = (
  state = {
    image: {},
    embed: {},
  },
  action
) => {
  switch (action.type) {
    case 'EDITOR_OPEN_IMAGE':
      return {
        ...state,
        image: {
          open: true,
          id: null,
        },
      };
    case 'EDITOR_CLOSE_IMAGE':
      return {
        ...state,
        image: {
          open: false,
          id: action.payload.id,
        },
      };
    case 'EDITOR_OPEN_EMBED':
        return {
            ...state,
            embed: {
                open: true,
            }
        };
    case 'EDITOR_CLOSE_EMBED':
        return {
            ...state,
            embed: {
                open: false,
            }
        };
    default:
      return state;
  }
};
