import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import toggleDrawer from './actions';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Nav from './Nav';

const drawerWidth = 216;

const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      height: '100%',
    },
  },
  drawerDocked: {
    height: '100%',
    marginTop: 64,
  },
});
@withStyles(styles, { withTheme: true })
@connect(store => ({
  open: store.navigation.open,
}))
export default class Navigation extends Component {
  handleDrawerToggle = () => {
    const { dispatch } = this.props;
    dispatch(toggleDrawer(false));
  };

  render() {
    const {
      classes,
      open,
      menu,
      menuOption,
      menuSmallDevice,
      isAdmin,
    } = this.props;

    const navProps = {
      menu,
      menuOption,
      menuSmallDevice,
      isAdmin,
    };

    return [
      <Hidden lgUp>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={this.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Nav {...navProps} />
        </Drawer>
      </Hidden>,
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
            docked: classes.drawerDocked,
          }}
        >
          <Nav {...navProps} />
        </Drawer>
      </Hidden>,
    ];
  }
}
