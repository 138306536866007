import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getProject } from 'Services/project';

import { addTag, fetchTags } from '../actions';
import filterList from 'Actions/lists';

import debounce from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';

import { SearchFilter } from '../../../common/components/SearchFilter';
import { TableList } from '../../../common/components/TableList';
import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';

import Row from './Row';
import TagDialog from '../Dialog';
import TopBar from '../../components/TopBar';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

@injectIntl
@connect((store, props) => ({
  tags: store.tags.list,
  fetching: store.tags.fetching,
  pagination: store.tags.pagination,
  total: store.tags.totalItems,
  query: parseQuery(props.location.search),
}))
export default class Tags extends Component {
  config = {
    filters: [
      {
        type: 'title',
        labelKey: 'filter_title',
      },
    ],
    headers: [
      { id: 'title', labelKey: 'field_title' },
      { labelKey: 'field_shortDescription' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchTags(filter));
    dispatch(filterList('tags', filter));
  };

  handleCreate = tag => {
    const { dispatch, history } = this.props;
    dispatch(addTag(tag)).then(({ payload }) => {
      if (payload && payload.slug) {
        history.push(`${getProject().url}/tags/${payload.slug}`);
      }
    });
  };

  handleFilters = change => {
    const { title } = change;
    const filtersQuery = {
      title,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/tags${search}`);
    }
  }, 500);

  render() {
    const {
      tags,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="tags-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_tags' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_tagsList}</Typography>
              <div style={{ flex: 1 }} />
              <TagDialog
                title={m.dialog_addTag}
                form="tag_add"
                onCreate={this.handleCreate}
              >
                <Button variant="contained" color="primary">
                  <AddIcon style={{ marginRight: 8 }} />
                  {m.button_add}
                </Button>
              </TagDialog>
            </Toolbar>
            <SearchFilter
              form="tags_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={tags}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
