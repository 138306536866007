export const styles = {
  container: {
    display: 'flex',
    background: 'white',
    padding: 8,
  },
  mainContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-evenly',
  },
  buttonContainer: {
    padding: 8,
    width: 400,
  },
  disabledMessage: {
    marginTop: 8,
  },
};
