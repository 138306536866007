import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  card: {
    width: 220,
    marginBottom: theme.spacing(2),
  },
  switchContainer: {
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginRight: 0,
  },
});
