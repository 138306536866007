import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CardIcon({ type, ...props }) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(8 6)" fill={type || 'yellow'}>
        <rect width="8" height="12" />
      </g>
    </SvgIcon>
  );
}
