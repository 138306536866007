import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';
import { injectIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';

const ICONNAME = [
  {
    label: 'hot',
    value: 'hot',
  },
  {
    label: 'field',
    value: 'field',
  },
  {
    label: 'play',
    value: 'play',
  },
  {
    label: 'transfer',
    value: 'transfer',
  },
  {
    label: 'cup',
    value: 'cup',
  },
  {
    label: 'player',
    value: 'player',
  },
  {
    label: 'infoFeed',
    value: 'infoFeed',
  },
  {
    label: 'poll',
    value: 'poll',
  },
  {
    label: 'tv',
    value: 'tv',
  },
];

function SelectIconName({
  input: { name, value, onChange },
  labelKey,
  intl: { formatMessage: f },
  meta: { error, touched },
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
  return (
    <FormControl style={{ minWidth: 150 }} error={touched && error}>
      <InputLabel htmlFor={`select_${name}`}>{label}</InputLabel>
      <Select value={value} label={label} onChange={event => onChange(event.target.value || '')}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {ICONNAME.map(cur => (
          <MenuItem key={cur.value} value={cur.value}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={`/img/FMicons/${cur.value}.svg`}
                alt={cur.label}
                style={{ width: 20, height: 20, marginRight: 10 }}
              />
              {cur.label}
            </div>
          </MenuItem>
        ))}
      </Select>
      {touched && error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  );
}

export default injectIntl(SelectIconName);
