import { Divider, Paper, Switch, Tooltip, Typography } from '@material-ui/core';
import { FormatItalic, FormatQuote } from '@material-ui/icons';
import Crop75Icon from '@material-ui/icons/Crop75';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import EditIcon from '@material-ui/icons/Edit';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import TitleIcon from '@material-ui/icons/Title';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CodeMirror, { EditorView, ReactCodeMirrorRef } from '@uiw/react-codemirror';
import React, { Component, RefObject } from 'react';
import 'react-mde/lib/styles/css/react-mde-all.css';
import Showdown from 'showdown';
import ImageModal from '../../../../../footdata/images/Command/Modal';
import EmbedModal from '../../../../../footmercato/articles/embed/Command/Modal';
import debounce from '../../../../services/tools';
import { SuperSub, SuperSubError } from '../../../SuperSub';
import { markdown } from '@codemirror/lang-markdown';
import SimpleQuoteGlobal from '../../../icons/SimpleQuoteGlobal';
import { IComponentProps } from './MarkDownEditor.connector';
import { CustomCommand } from './codemirror/command/commands';
import { underlineErrors } from './codemirror/command/underlineSelection';
import { wordHover } from './codemirror/extension/wordHover';
import './style.css';
import { cleanMarkdown, handlePreviewMarkdown } from '../utils';

const converter = new Showdown.Converter({
  tables: true,
});

// TODO
/*
 - test editor on live commentary
   - ajust compact mode
   - add setRef for focus
 - loadSuggestions + suggestionTriggerCharacters
*/
interface IState {
  value: string;
  selectedTab: 'write' | 'preview';
  editorMode: 'write' | 'preview';
  codeMirror: RefObject<ReactCodeMirrorRef>;
  enableSupersub: boolean;
}

export class MarkDownEditor extends Component<IComponentProps, IState> {
  public state: IState = {
    selectedTab: 'write',
    value: this.props.input.value,
    editorMode: 'write',
    codeMirror: React.createRef<ReactCodeMirrorRef>(),
    enableSupersub: true,
  };
  private superSubs = new SuperSub();

  private handleOnChange = debounce(input => {
    const cleanInput = cleanMarkdown(input);
    this.props.input.onChange(cleanInput);
    this.triggerSuperSubCheck(cleanInput);
  }, 150);

  constructor(props: IComponentProps) {
    super(props);
  }

  public componentDidMount() {
    this.triggerSuperSubCheck(this.props.input.value);
  }

  public render() {
    const { value, editorMode, enableSupersub } = this.state;

    const {
      input: { name },
      labelKey,
      intl: { formatMessage: f, messages: m },
      compact,
      classes,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
    const additionnalParams: any = {};

    if (compact) {
      additionnalParams.minEditorHeight = 96;
    }

    return (
      <div className="mde-container">
        <Typography variant="caption" style={{ color: '#757575', marginTop: 8 }}>
          {label}
        </Typography>
        <Paper elevation={0} className={classes.box}>
          <Paper elevation={0} className={classes.paper}>
            <ToggleButtonGroup
              value={editorMode}
              exclusive
              size="small"
              onChange={this.handleEditorModeChange}
              aria-label="editor mode"
              className={classes.grouped}
            >
              <Tooltip title={m.editor_write} placement="top">
                <ToggleButton value="write" aria-label={m.editor_write}>
                  <EditIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={m.editor_preview} placement="top">
                <ToggleButton value="preview" aria-label={m.editor_preview}>
                  <VisibilityIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
            <Divider flexItem orientation="vertical" className={classes.divider} />
            <ToggleButtonGroup
              size="small"
              aria-label="style commands"
              className={`${classes.grouped} ${classes.groupedButtons}`}
            >
              <ToggleButton aria-label={m.editor_commandHeader} onClick={this.callCommand(CustomCommand.h3)}>
                <TitleIcon />
              </ToggleButton>
              <ToggleButton aria-label={m.editor_commandBold} onClick={this.callCommand(CustomCommand.bold)}>
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton aria-label={m.editor_commandItalic} onClick={this.callCommand(CustomCommand.italic)}>
                <FormatItalic />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              size="small"
              aria-label="other commands"
              className={`${classes.grouped} ${classes.groupedButtons}`}
            >
              <ToggleButton aria-label={m.editor_commandLink} onClick={this.callCommand(CustomCommand.link)}>
                <InsertLinkIcon />
              </ToggleButton>
              <ToggleButton aria-label={m.editor_commandButton} onClick={this.callCommand(CustomCommand.button)}>
                <Crop75Icon />
              </ToggleButton>
              <ToggleButton aria-label={m.editor_commandButton} onClick={this.callCommand(CustomCommand.textBlue)}>
                <InsertLinkIcon color={"primary"}/>
              </ToggleButton>
              <ToggleButton aria-label="double quote" onClick={this.callCommand(CustomCommand.doubleQuote)}>
                <FormatQuote />
              </ToggleButton>
              <ToggleButton aria-label="simple quote" onClick={this.callCommand(CustomCommand.simpleQuote)}>
                <SimpleQuoteGlobal />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              size="small"
              aria-label="list commands"
              className={`${classes.grouped} ${classes.groupedButtons}`}
            >
              <ToggleButton aria-label={m.editor_commandUnorderedList} onClick={this.callCommand(CustomCommand.ul)}>
                <FormatListBulletedIcon />
              </ToggleButton>
              <ToggleButton aria-label={m.editor_commandOrderedList} onClick={this.callCommand(CustomCommand.ol)}>
                <FormatListNumberedIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              size="small"
              aria-label="custom commands"
              className={`${classes.grouped} ${classes.groupedCustomButtons}`}
            >
              <Tooltip title={m.editor_commandImage} placement="top">
                <ToggleButton aria-label={m.editor_commandImage} onClick={this.callCommand(CustomCommand.media)}>
                  <InsertPhotoIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={m.editor_commandMedia} placement="top">
                <ToggleButton aria-label={m.editor_commandMedia} onClick={this.callCommand(CustomCommand.embed)}>
                  <DynamicFeedIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
            <Tooltip title={m.editor_commandSuperSub} placement="top">
              <Switch
                className={classes.supersubSwitch}
                checked={enableSupersub}
                onChange={this.toggleSupersub}
                name="supersub"
                inputProps={{ 'aria-label': m.editor_commandSuperSub }}
              />
            </Tooltip>
          </Paper>
          <Divider orientation="horizontal" />
          {editorMode === 'write' && (
            <CodeMirror
              height={compact ? '200px' : '300px'}
              basicSetup={{
                foldGutter: false,
                dropCursor: false,
                allowMultipleSelections: false,
                indentOnInput: false,
                lineNumbers: false,
                highlightActiveLine: false,
              }}
              ref={this.state.codeMirror}
              onChange={this.handleValueChange}
              value={value}
              extensions={[markdown(), EditorView.lineWrapping, wordHover(this.superSubs)]}
            />
          )}
          {editorMode === 'preview' && (
            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(handlePreviewMarkdown(value)) }}></div>
          )}
        </Paper>
        <ImageModal imageType={name === 'content' ? 'articleContentImage' : 'editorImage'} />
        <EmbedModal />
      </div>
    );
  }

  public callCommand = (command: (target: EditorView) => boolean | Promise<boolean>) => () => {
    const codeMirror = this.state.codeMirror.current;
    if (!codeMirror || !codeMirror.view) {
      return;
    }

    codeMirror.view.focus();
    command(codeMirror.view);
  };

  private handleEditorModeChange = (_: React.MouseEvent<HTMLElement>, newEditorMode: 'write' | 'preview') => {
    if (newEditorMode !== null) {
      this.setState({ editorMode: newEditorMode });
    }
  };
  private handleValueChange = (value: string) => {
    this.setState({ value });
    this.handleOnChange(value);
  };

  private toggleSupersub = () => {
    this.setState(
      prevState => ({
        enableSupersub: !prevState.enableSupersub,
      }),
      () => {
        if (this.state.enableSupersub) {
          this.triggerSuperSubCheck(this.state.value);
        } else {
          this.handleSuperSubErrors([]);
        }
      }
    );
  };

  private triggerSuperSubCheck = debounce((value: string) => {
    if (!this.state.enableSupersub) {
      return;
    }
    this.superSubs.getGrammaticalErrors(value).then(errors => {
      if (errors) {
        this.handleSuperSubErrors(errors);
      }
    });
  }, 1000);

  private handleSuperSubErrors = (errors: SuperSubError[]) => {
    const codeMirror = this.state.codeMirror.current;
    const view = codeMirror && codeMirror.view;
    if (!view) {
      return;
    }

    this.superSubs.formatWordErrors(errors, view);
    underlineErrors(view, this.superSubs);
  };
}
