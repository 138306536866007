import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  badge: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    margin: 8,
    padding: 4,
    borderRadius: 4,
    color: theme.palette.grey[600],
    background: theme.palette.grey[200],
  },
});

@withStyles(styles)
export class IdBadge extends PureComponent {
  render() {
    const { classes, id } = this.props
    return (
      <div className={classes.badge}>{id}</div>
    );
  }
}
