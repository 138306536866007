import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DeleteButton from 'Components/DeleteButton';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getCollection } from 'Services/connectors';
import { getProject } from 'Services/project';
import { fetchAuthorArticles } from '../../articles/actions';
import TopBar from '../../components/TopBar';
import { deleteAuthor, fetchAuthor, updateAuthor } from '../actions';
import Articles from './Articles';
import Form from './Form';

const styles = theme => ({
  leftButton: {
    marginRight: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  id: props.match.params.topicId,
  author: store.author.detail[props.match.params.topicId],
  fetching: store.author.fetching,
  articles: getCollection('articlesIds', store.author, store.article),
  totalItems: store.article.totalItems,
}))
export default class Detail extends Component {
  menu = [{ label: 'app_authorInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchAuthor(targetId || id)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchAuthorArticles(payload.id));
      }
    });
  };

  handleAuthorUpdate = changes => {
    const { dispatch, author } = this.props;
    return dispatch(updateAuthor(author.id, changes));
  };

  handleRemove = () => {
    const { author, dispatch, history } = this.props;
    return dispatch(deleteAuthor(author.id)).then(() => {
      history.push(`${getProject().url}/authors`);
    });
  };

  getLabel = () => {
    const {
      author,
      intl: { messages: m },
    } = this.props;
    return author.publicName || `${m.app_author}: ${author.id}`;
  };

  render() {
    const {
      fetching,
      author,
      articles,
      totalItems,
      match,
      history,
      classes,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!author) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="author-layout"
        top={
          <TopBar
            crumbStack={[
              { labelKey: 'app_authors', href: `#${getProject().url}/authors` },
              { label: this.getLabel() },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{this.getLabel()}</Typography>
              <div style={{ flex: 1 }} />
              <DeleteButton onRemove={this.handleRemove} />
              <ReturnButton className={classes.leftButton} entity="authors" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route
              exact
              path={match.url}
              render={() => <Form onSubmit={this.handleAuthorUpdate} data={author} />}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Articles articles={articles} authorId={author.id} totalItems={totalItems} />
          </Paper>
        }
      />
    );
  }
}
