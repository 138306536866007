import React, { Component } from 'react';
import { IComponentProps } from './UserRestiction.connector';

/**
 * Component that inject the prop disabled to true if isUserLimited
 * Can be updated to handle different types of restrictions
 */
export class UserRestriction extends Component<IComponentProps> {
  public render() {
    const { isRestricted, children } = this.props;

    return isRestricted ? React.cloneElement(children, { disabled: true }) : children;
  }
}
