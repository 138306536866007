import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { selectMatchCommentaries } from '../../../../matches/selector';
import { recoverLiveCommentary, removeLiveCommentary } from '../../actions/liveActions';

const mapDispatchToProps = {
  removeLiveCommentary,
  recoverLiveCommentary,
};
interface IOwnProps {
  callback: () => void;
}
const mapStateToProps = (state: IRootState) => {
  let lastUpdated = '';

  const commentaries = selectMatchCommentaries(state, state.live.matchId) || [];
  lastUpdated = commentaries.reduce(
    (acc, commentary) => (acc < commentary.updatedAt ? commentary.updatedAt : acc),
    lastUpdated
  );

  return {
    matchId: state.live.matchId,
    lastUpdated,
    commentaries,
    editingCommentaryId: state.live.editingCommentaryId,
  };
};

export type IComponentProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
