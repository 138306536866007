import { ICommentaryType } from '../../commentaries/typings';
import { getMatchChrono } from './components/Chrono/utils';
import { buildPlayersSuggestions, buildTeamsSuggestions } from './components/ContentEditor/utils';
import { EPeriod } from './components/PeriodSelector/utils';
import { ILiveCommentary, ILiveState } from './typings';
import { getCustomContent, getInitializeCommentaryState, getIsTypeCompleted } from './utils';

const initialCommentary: ILiveCommentary = {
  type: 'text',
  isTypeCompleted: true,
  content: '',
  timeMin: null,
  timeSec: null,
  additionnalTimeMin: null,
  additionnalTimeSec: null,
  isImportant: false,
  hasVideo: false,
  player: null,
  player2: null,
  teamActionSide: null,
  period: EPeriod.PreMatch,
};
const initialState: ILiveState = {
  matchId: '',
  error: null,
  homeTeam: null,
  awayTeam: null,
  awayMatchTeamPlayers: null,
  homeMatchTeamPlayers: null,
  editingCommentaryId: null,
  editingMatchEventId: null,
  commentary: initialCommentary,
  period: EPeriod.PreMatch,
  chronoStartTimeStamp: +new Date(),
  chronoEndTimeStamp: +new Date(),
  chronoStatus: false,
  playerCard: null,
  defaultContents: {},
  playersSuggestions: [],
  teamsSuggestions: [],
  resetCount: 0,
  strictMode: true,
};
export const liveReducer = (state: ILiveState = initialState, action) => {
  switch (action.type) {
    case 'SET_LIVE_MATCH': {
      return {
        ...initialState,
        matchId: action.payload,
      };
    }
    case 'FETCH_MATCH_FULFILLED': {
      if (action.payload.id !== state.matchId) {
        return state;
      }
      const chronoStartTimeStamp = +new Date(action.payload.chronoStart);
      const chronoEndTimeStamp = +new Date(action.payload.chronoEnd);
      const [timeMin, timeSec, additionnalTimeMin, additionnalTimeSec] = getMatchChrono(
        action.payload.period,
        chronoStartTimeStamp,
        chronoEndTimeStamp
      );
      const homeTeam = action.payload.homeTeam;
      const awayTeam = action.payload.awayTeam;

      const awayMatchTeamPlayers = action.payload.matchTeams.away
        ? action.payload.matchTeams.away.matchTeamPlayers
        : null;
      const homeMatchTeamPlayers = action.payload.matchTeams.home
        ? action.payload.matchTeams.home.matchTeamPlayers
        : null;

      const playersSuggestions = buildPlayersSuggestions(homeMatchTeamPlayers, awayMatchTeamPlayers);
      const teamsSuggestions = buildTeamsSuggestions(homeTeam, awayTeam);

      return {
        ...state,
        error: null,
        homeTeam,
        awayTeam,
        awayMatchTeamPlayers,
        homeMatchTeamPlayers,
        period: action.payload.period,
        chronoStartTimeStamp,
        chronoEndTimeStamp,
        chronoStatus: action.payload.chronoStatus,
        playersSuggestions,
        teamsSuggestions,
        commentary: {
          ...state.commentary,
          period: action.payload.period,
          timeMin,
          timeSec,
          additionnalTimeMin,
          additionnalTimeSec,
        },
        mainCommentatorLocale: action.payload.mainCommentatorLocale
      };
    }
    case 'FETCH_MATCH_REJECTED': {
      if (action.id !== state.matchId) {
        return state;
      }
      return {
        ...state,
        error: { type: 'error', payload: action.payload },
      };
    }
    case 'UPDATE_MATCH_FULFILLED': {
      if (action.payload.id !== state.matchId) {
        return state;
      }
      return {
        ...state,
        chronoStartTimeStamp: +new Date(action.payload.chronoStart),
        chronoEndTimeStamp: +new Date(action.payload.chronoEnd),
        chronoStatus: action.payload.chronoStatus,
        period: Number(action.payload.period),
      };
    }

    case 'LIVE_RESET': {
      return {
        ...state,
        commentary: getInitializeCommentaryState(state),
        editingCommentaryId: null,
        editingMatchEventId: null,
        resetCount: state.resetCount + 1,
      };
    }
    // ACTION
    case 'LIVE_SET_TYPE': {
      const type: ICommentaryType = action.payload;
      const oldType: ICommentaryType = state.commentary.type;

      let player = state.commentary.player;
      let player2 = state.commentary.player2;
      let teamActionSide = state.commentary.teamActionSide;

      if (state.editingCommentaryId) {
        if (type === 'own_goal' || oldType === 'own_goal') {
          player = null;
          player2 = null;
          teamActionSide = teamActionSide === 'away' ? 'home' : 'away';
        }
      }

      const isTypeCompleted = getIsTypeCompleted(type, player, player2);

      let timeInfo = {};
      if (!state.editingCommentaryId) {
        const [timeMin, timeSec, additionnalTimeMin, additionnalTimeSec] = getMatchChrono(
          state.period,
          state.chronoStartTimeStamp,
          state.chronoEndTimeStamp
        );
        timeInfo = {
          timeMin,
          timeSec,
          additionnalTimeMin,
          additionnalTimeSec,
          period: state.period,
        };
      }

      const commentary = {
        ...state.commentary,
        ...timeInfo,
        player,
        player2,
        teamActionSide,
        isTypeCompleted,
        type,
      };

      const customContent = getCustomContent(commentary, state.defaultContents);
      if (customContent) {
        commentary.content = customContent;
      }

      return {
        ...state,
        commentary,
      };
    }
    // PLAYERS
    case 'LIVE_SET_PLAYER': {
      const commentary = {
        ...state.commentary,
        player: action.payload.player,
      };
      const isTypeCompleted = getIsTypeCompleted(state.commentary.type, commentary.player, commentary.player2);

      return {
        ...state,
        commentary: {
          ...commentary,
          isTypeCompleted,
          teamActionSide: action.payload.side,
        },
      };
    }
    case 'LIVE_SET_PLAYER_CARD': {
      return {
        ...state,
        playerCard: action.payload.player,
      };
    }
    case 'LIVE_SET_PLAYER2': {
      const isTypeCompleted = getIsTypeCompleted(state.commentary.type, state.commentary.player, action.payload.player);

      return {
        ...state,
        commentary: {
          ...state.commentary,
          isTypeCompleted,
          player2: action.payload.player,
          // allow side for substitutes and prevent savedByPerson opponent side
          teamActionSide: state.commentary.teamActionSide || action.payload.side,
        },
      };
    }
    case 'LIVE_REMOVE_PLAYER': {
      const isTypeCompleted = getIsTypeCompleted(state.commentary.type, null, state.commentary.player2);

      let teamActionSide = state.commentary.teamActionSide;
      if (!state.commentary.player2 && !state.editingCommentaryId) {
        teamActionSide = null;
      }

      return {
        ...state,
        commentary: {
          ...state.commentary,
          player: null,
          isTypeCompleted,
          teamActionSide,
        },
      };
    }
    case 'LIVE_REMOVE_PLAYER2': {
      let teamActionSide = state.commentary.teamActionSide;
      if (!state.commentary.player && !state.editingCommentaryId) {
        teamActionSide = null;
      }
      const commentary = {
        ...state.commentary,
        player2: null,
        teamActionSide,
      };
      commentary.isTypeCompleted = getIsTypeCompleted(state.commentary.type, state.commentary.player, null);

      return {
        ...state,
        commentary,
      };
    }
    case 'LIVE_REMOVE_PLAYER_CARD': {
      return {
        ...state,
        playerCard: null,
      };
    }
    // PARAMS
    case 'LIVE_SET_PARAMS': {
      return {
        ...state,
        commentary: {
          ...state.commentary,
          [action.payload.type]: action.payload.value,
        },
      };
    }
    case 'LIVE_SYNC_CHRONO_TO_COMMENTARY': {
      const [timeMin, timeSec, additionnalTimeMin, additionnalTimeSec] = getMatchChrono(
        state.period,
        state.chronoStartTimeStamp,
        state.chronoEndTimeStamp
      );
      return {
        ...state,
        commentary: {
          ...state.commentary,
          timeMin,
          timeSec,
          additionnalTimeMin,
          additionnalTimeSec,
          period: state.period,
        },
      };
    }

    // CONTENT
    case 'LIVE_SET_CONTENT': {
      return {
        ...state,
        commentary: {
          ...state.commentary,
          content: action.payload,
        },
      };
    }
    // COMMENTARY
    case 'RECOVER_COMMENTARY': {
      const {
        type,
        isImportant,
        hasVideo,
        content,
        timeMin,
        timeSec,
        additionnalTimeMin,
        additionnalTimeSec,
        period,
        id,
        matchEvent,
        player,
        player2,
        teamActionSide,
      } = action.payload;

      return {
        ...state,
        editingCommentaryId: id,
        editingMatchEventId: matchEvent ? matchEvent.id : null,
        commentary: {
          ...state.commentary,
          timeMin,
          timeSec,
          additionnalTimeMin,
          additionnalTimeSec,
          period,
          isImportant,
          hasVideo,
          player,
          player2,
          type,
          isTypeCompleted: true,
          content,
          teamActionSide,
        },
      };
    }

    case 'SET_COMMENTARY_DEFAULT_CONTENT': {
      return {
        ...state,
        defaultContents: action.payload,
      };
    }

    case 'LIVE_SET_STRICT_MODE': {
      return {
        ...state,
        strictMode: action.payload,
      };
    }

    default:
      return state;
  }
};
