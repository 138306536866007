import navigation from 'Components/Nav/reducer';
import revisions from 'Components/revisions/reducer';
import { reducer as formReducer } from 'redux-form';
import { markdownEditorReducer } from './components/inputs/TextEdit/reducer';
import intl from './reducers/intl';
import lists from './reducers/lists';
import me from './reducers/me';
import notifications from './reducers/notifications';
import { userReducer, usersReducer } from './users/reducers';

export default {
  editor: markdownEditorReducer,
  intl,
  lists,
  me,
  navigation,
  notifications,
  revisions,
  user: userReducer,
  users: usersReducer,
  form: formReducer,
};
