import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getItemBySlug } from 'Services/connectors';
import { getProject } from 'Services/project';
import DeleteButton from '../../../../common/components/DeleteButton';
import { IdBadge } from '../../../../common/components/IdBadge';
import Layout from '../../../../common/components/Layout';
import ReturnButton from '../../../../common/components/ReturnButton';
import Revisions from '../../../../common/components/revisions';
import RevisionNote from '../../../../common/components/revisions/Note';
import { SimpleBreadcrumbs } from '../../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../../common/components/TabsRoute';
import { UserRestriction } from '../../../../common/components/UserRestiction';
import { getDefaultPeoplePath, getPersonUsualName } from '../../utils';
import { deletePresident, fetchPresident, updatePresident } from '../actions';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  president: getItemBySlug(store.president, props.match.params.topicSlug),
  fetching: store.president.fetching,
}))
export default class PresidentDetail extends Component {
  state = {
    showRevisions: false,
  };
  menu = [{ label: 'app_personInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchPresident(targetSlug || slug));
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handlePresidentChange = person => {
    const { dispatch } = this.props;
    return dispatch(updatePresident(person.id, person));
  };

  handleRemove = () => {
    const { president, dispatch, history } = this.props;
    return dispatch(deletePresident(president.id)).then(() => {
      history.push(`${getProject().url}/presidents`);
    });
  };

  getLabel = () => {
    const { president } = this.props;
    return getPersonUsualName(president);
  };

  render() {
    const {
      fetching,
      president,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!president) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="president-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_presidents',
                href: `#${getDefaultPeoplePath('presidents')}`,
              },
              { label: this.getLabel() },
            ]}
          />
        }
        main={
          <div>
            <Paper elevation={2}>
              <Toolbar>
                <Typography variant="h6">{this.getLabel()}</Typography>
                <IdBadge id={president.id} />
                <div style={{ flex: 1 }} />
                <UserRestriction>
                  <DeleteButton onRemove={this.handleRemove} />
                </UserRestriction>
                <ReturnButton entity="presidents" />
              </Toolbar>

              <TabsRoute match={match} menu={this.menu} history={history} />

              <Route
                exact
                path={match.url}
                render={() => (
                  <div>
                    <div>
                      <RevisionNote
                        style={{ margin: 16 }}
                        createdAt={president.createdAt}
                        updatedAt={president.updatedAt}
                        toggle={this.toggleRevisions}
                      />
                      {this.state.showRevisions && (
                        <Revisions
                          entity="person"
                          id={president.id}
                          updatedAt={president.updatedAt}
                          onRollback={() => this.fetchData()}
                        />
                      )}
                    </div>
                    <Form onSubmit={this.handlePresidentChange} data={president} />
                  </div>
                )}
              />
            </Paper>
          </div>
        }
      />
    );
  }
}
