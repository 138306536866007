import { Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import {PartnerOfferRow} from "./PartnerOfferRow.component";
import {TableList} from "../../../../common/components/TableList";
import queryReducer from '../../../../common/services/queriesReducer';
import debounce from "../../../../common/services/tools";
import {getProject} from "../../../../common/services/project";
import {stringifyQuery} from "../../../../common/services/parseQuery";
import {IComponentProps} from "./PartnerOffersList.connector";
import Layout from "../../../../common/components/Layout";
import {SimpleBreadcrumbs} from "../../../../common/components/SimpleBreadcrumbs";
import {SearchFilter} from "../../../../common/components/SearchFilter";

// @ts-ignore
export class PartnerOffersList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'locale',
        labelKey: 'field_language',
      }
    ],
    headers: [
      { id: 'partner.name', labelKey: 'field_partner' },
      { id: 'shortText', labelKey: 'field_shortText' },
      { id: 'usedForTranslated', labelKey: 'field_usedFor' },
      { id: 'locale', labelKey: 'field_language' },
      { id: 'competition.name', labelKey: 'field_competition' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/partner-offers${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      partnerOffers,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;
    return (
      <Layout
        id="partner-offers-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_partnerOffers' }]} />
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_partnerOffers}</Typography>
              <div style={{ flex: 1 }} />
            </Toolbar>
            <SearchFilter
                form="partnerOffers_filters"
                filters={this.config.filters}
                onChange={this.handleFilters}
                initialValues={query.filters}
            />
            <TableList
              tableData={partnerOffers}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={PartnerOfferRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchPartnerOffers } = this.props;
    fetchPartnerOffers(filter);
  };

  handleFilters = change => {
    const { locale } = change;
    const filtersQuery = {
      locale,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
}
