import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { QuizzDetail } from './Detail';
import { QuizzesList } from './List';

export const QuizzesRoutes = ({ match }) => {
    return (
        <Switch>
            <Route exact path={match.url} component={QuizzesList} />
            <Route path={`${match.url}/:topicId`} component={QuizzDetail} />
        </Switch>
    );
};
