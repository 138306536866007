import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'MATCH',
  collections: {
    broadcastsIds: { get: 'broadcasts' },
    commentariesIds: { get: 'commentaries', set: 'commentary' },
    matchEventsIds: { get: 'matchEvents', set: 'matchEvent' },
  },
});
