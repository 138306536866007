import { Button, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ArrowBack from '@material-ui/icons/ArrowBack';
// import StatsIcon from '@material-ui/icons/Equalizer';
import LinkIcon from '@material-ui/icons/Link';
import Refresh from '@material-ui/icons/Refresh';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Notification from '../../common/components/Notification';
import {
  // getClubStatsHref,
  getMatchDetailHref,
  getSiteMatchHref,
} from '../utils/href';
import { Advanced } from './Advanced';
import { Commentary } from './Commentary';
import { InterruptionButtons } from './Commentary/components/InterruptionButtons';
import { PlayerCard } from './Commentary/components/PlayerCard';
import { ScoreBoard } from './Commentary/components/ScoreBoard';
import { Composition } from './Composition';
import { Documentation } from './Documentation';
import { IComponentProps } from './Live.connector';
import { Push } from './Push';
import { getFDProjectUrl } from '../../common/services/project';

export class Live extends React.PureComponent<IComponentProps> {
  public componentDidMount() {
    const {
      setCommentaryDefaultContent,
      intl: { messages: m },
    } = this.props;
    this.fetchData();

    setCommentaryDefaultContent({
      additionalTimeFirstHalf: m.live_commentary_default_additional_time_first_half,
      additionalTimeSecondHalf: m.live_commentary_default_additional_time_second_half,
    });
  }

  public componentDidUpdate(prevProps: IComponentProps) {
    if (this.props.id !== prevProps.id) {
      this.fetchData();
    }
  }

  public render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderTabs()}
        {this.renderTabContent()}
        <Notification />
      </div>
    );
  }

  private renderHeader = () => {
    const {
      classes,
      id,
      game,
      intl: { messages: m },
    } = this.props;

    const matchDetailHref = getMatchDetailHref(id);
    // const clubStatsHref = getClubStatsHref();
    const siteMatchHref = game ? getSiteMatchHref(game) : '';

    return (
      <div className={classes.header}>
        <div className={classes.flex1}>
          <div className={classes.iconContainer}>
            <IconButton href={matchDetailHref} title={m.button_back}>
              <ArrowBack />
            </IconButton>
            <IconButton href={siteMatchHref} title={m.link_to_website} target="_blank">
              <LinkIcon />
            </IconButton>
            {/* <IconButton href={clubStatsHref} title={m.app_statsClubs} target="_blank">
              <StatsIcon />
            </IconButton> */}
          </div>
          <PlayerCard />
        </div>
        <ScoreBoard />
        <div className={classes.flex1}>
          {game ? (
            <div className={classes.statusContainer}>
              <Typography variant="h5">{m['live_status_' + game.status]}</Typography>
            </div>
          ) : null}
          <InterruptionButtons />
        </div>
      </div>
    );
  };

  private renderTabs = () => {
    const {
      id,
      intl: { messages: m },
    } = this.props;

    const path = this.getCurrentTab();

    const matchLiveHref = `#${getFDProjectUrl()}/live/${id}`;
    return (
      <Tabs value={path}>
        <Tab label={m.live_tab_commentaries} href={matchLiveHref} value="/" />
        <Tab label={m.live_tab_composition} href={matchLiveHref + '/compo'} value="/compo" />
        <Tab label={m.live_tab_push} href={matchLiveHref + '/push'} value="/push" />
        <Tab label={m.live_tab_documentation} href={matchLiveHref + '/documentation'} value="/documentation" />
        <Tab label={m.live_tab_advanced} href={matchLiveHref + '/advanced'} value="/advanced" />
      </Tabs>
    );
  };

  private renderTabContent = () => {
    const {
      classes,
      match,
      error,
      intl: { messages: m },
    } = this.props;

    if (error) {
      return (
        <Paper elevation={2} className={classes.paper}>
          <div className={classes.errorButtonContainer}>
            <Button color="secondary" onClick={this.fetchData}>
              <Refresh className={classes.refreshIcon} />
              {m.message_liveRequestFailed}
            </Button>
          </div>
        </Paper>
      );
    }

    return (
      <Switch>
        <Route exact path={match.url} component={Commentary} />
        <Route path={`${match.url}/compo`} component={Composition} />
        <Route path={`${match.url}/push`} component={Push} />
        {/*
          // @ts-ignore */}
        <Route path={`${match.url}/documentation`} component={Documentation} />
        <Route path={`${match.url}/advanced`} component={Advanced} />
      </Switch>
    );
  };

  private fetchData = () => {
    const { id, fetchLiveData } = this.props;
    fetchLiveData(id);
  };

  private getCurrentTab = () => {
    const { history, match } = this.props;
    return history.location.pathname.substr(match.url.length) || '/';
  };
}
