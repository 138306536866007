import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PersonAvatar from '../../people/Avatar';
import { getDefaultPeoplePath } from '../../people/utils';
import { getMembershipPersonHref } from '../../memberships/utils';

@injectIntl
export default class Teams extends Component {
  render() {
    const {
      memberships = [],
      intl: { messages: m },
    } = this.props;
    return (
      <List dense subheader={<ListSubheader>{m.app_memberships}</ListSubheader>}>
        {memberships.map(membership => (
          <ListItem button component="a" href={getMembershipPersonHref(membership)}>
            <ListItemIcon>
              <PersonAvatar person={membership.person} />
            </ListItemIcon>
            <ListItemText primary={membership.personMatchName} />
          </ListItem>
        ))}
        <ListItem button component="a" href={`#${getDefaultPeoplePath('players')}`}>
          <ListItemText primary={m.button_seeAll} />
        </ListItem>
      </List>
    );
  }
}
