import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MercatoSurveyDetail } from './Detail';
import { MercatoSurveysList } from './List';

export const MercatoSurveysRoutes = ({ match }) => (
    <Switch>
      <Route exact path={match.url} component={MercatoSurveysList} />
       <Route path={`${match.url}/:topicId`} component={MercatoSurveyDetail} />
    </Switch>
  );
