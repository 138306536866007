export default function reducer(
  state = {
    list: [],
    fetched: false,
    fetching: false,
    adding: false,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_ORGANIZATIONS': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_ORGANIZATIONS_FULFILLED': {
      const { collection, pagination, totalItems } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: collection,
        pagination,
        totalItems,
      };
    }
    case 'FETCH_ORGANIZATIONS_REJECTED': {
      return {
        ...state,
        fetching: false,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }

    case 'ADD_ORGANIZATION': {
      return { ...state, adding: true };
    }

    case 'ADD_ORGANIZATION_FULFILLED': {
      return {
        ...state,
        adding: false,
        list: [...state.list, action.payload],
      };
    }

    case 'ADD_ORGANIZATION_REJECTED': {
      return { ...state, adding: false };
    }

    default:
      return state;
  }
}
