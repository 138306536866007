import { fetchMatch } from '../../matches/actions';
import { awardMatch, changeMatchDate, changeMatchScores, cleanMatch, suspendMatch } from '../../matches/requests';
import { fetchLiveData } from '../actions';
import { ILiveState } from '../Commentary/typings';

export const suspendLiveMatch = () => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const matchId = liveState.matchId;

    return suspendMatch(matchId).then(() => {
      return dispatch(fetchMatch(matchId));
    });
  };
};

export const awardLiveMatch = params => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const matchId = liveState.matchId;

    return awardMatch(matchId, params).then(() => {
      return dispatch(fetchMatch(matchId));
    });
  };
};

export const cleanLiveMatch = () => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const matchId = liveState.matchId;

    return cleanMatch(matchId).then(() => {
      return dispatch(fetchLiveData(matchId));
    });
  };
};

export const changeLiveMatchDate = date => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const matchId = liveState.matchId;

    const params = { date };

    return changeMatchDate(matchId, params).then(() => {
      return dispatch(fetchLiveData(matchId));
    });
  };
};

export const changeLiveMatchScores = scores => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const matchId = liveState.matchId;

    const params = { scores };

    return changeMatchScores(matchId, params).then(() => {
      return dispatch(fetchLiveData(matchId));
    });
  };
};
