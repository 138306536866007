import { Typography } from '@material-ui/core';
import React from 'react';
import { ICountByFormation, ILineupSurvey } from '../typings';

interface IProps {
  lineupSurvey: ILineupSurvey;
  intl: any;
}

export class LineupSurveyStat extends React.Component<IProps> {
  public render() {
    const {
      lineupSurvey,
      intl: { messages: m },
    } = this.props;

    // @ts-ignore
    return (
      <div>
        <div style={{ padding: 16 }}>
          <Typography variant="h6">{m.app_statistics}</Typography>
          <ul>
            <li>
              {m.survey_totalParticipantsCount}: {lineupSurvey.totalParticipantsCount || '-'}
            </li>
            <li>
              {m.survey_totalParticipantsBySource}: {this.renderCountBySource()}
            </li>
            <li>
              {m.lineupSurvey_countByFormation}:
              <ul>
                {lineupSurvey.countByFormation &&
                  lineupSurvey.countByFormation.length > 0 &&
                  lineupSurvey.countByFormation.map((item, index) => (
                    <li key={index}>
                      {item.formation}: {item.count} ({this.getPercentageByFormation(item)}%)
                    </li>
                  ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  public renderCountBySource() {
    const { lineupSurvey } = this.props;
    if (!lineupSurvey.participantsCountBySource || lineupSurvey.participantsCountBySource.length === 0) {
      return '-';
    }

    return (
      <ul>
        {lineupSurvey.participantsCountBySource &&
          lineupSurvey.participantsCountBySource.length > 0 &&
          lineupSurvey.participantsCountBySource.map((countBySource, index) => (
            <li key={index}>
              {countBySource.source}: {countBySource.count}
            </li>
          ))}
      </ul>
    );
  }

  public getPercentageByFormation(item: ICountByFormation) {
    const { lineupSurvey } = this.props;
    if (!lineupSurvey.totalParticipantsCount) return 0;

    if (lineupSurvey.totalParticipantsCount > 0) {
      return Math.ceil((item.count * 100) / lineupSurvey.totalParticipantsCount);
    }

    return 0;
  }
}
