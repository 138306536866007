import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DescriptionIcon from '@material-ui/icons/Description';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import debounce, { getLinkId } from 'Services/tools';
import { fetchArticleId, fetchArticles } from './actions';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    zIndex: '10',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
});

@injectIntl
@withStyles(styles)
@connect(store => ({
  suggestions: store.articles.list,
}))
export default class ArticleInput extends Component {
  state = {
    searchText: '',
    suggestions: [],
  };

  componentDidMount() {
    const { input, dispatch } = this.props;

    if (input.value) {
      dispatch(fetchArticleId(getLinkId(input.value))).then(({ payload }) => {
        this.setState({
          searchText: this.getLabel(payload),
        });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(next) {
    const { input } = this.props;
    if (!next.input || !input) {
      return true;
    }
    if (input.value != next.input.value && !next.input.value) {
      this.setState({ searchText: '' });
    }
  }

  getLabel = article => article.title;

  handleSuggestionsFetchRequested = debounce(({ value }) => {
    const { dispatch } = this.props;
    dispatch(fetchArticles(`?title=${value}`)).then(() => {
      this.setState({
        suggestions: this.getSuggestions(value),
      });
    });
  }, 500);

  handleChange = (event, { newValue }) => {
    this.setState({
      searchText: newValue,
    });
  };
  handleSuggestionSelected = (event, { suggestion }) => {
    const { useObject } = this.props
    if (useObject) {
      this.props.input.onChange(suggestion);
    } else {
      this.props.input.onChange(suggestion['@id']);
    }
  };
  renderInput = inputProps => {
    const { classes, className, value, ref, label, hintText, ...other } = inputProps;

    return (
      <TextField
        className={className}
        value={value}
        inputRef={ref}
        label={label}
        fullWidth
        InputProps={{
          classes: {
            input: classes.input,
          },
          placeholder: hintText || label,
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
          ...other,
        }}
      />
    );
  };
  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const name = this.getLabel(suggestion);
    const matches = match(name, query);
    const parts = parse(name, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={index} style={{ fontWeight: 300 }}>
                {part.text}
              </span>
            ) : (
              <strong key={index} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;

    return (
      <div>
        {children && (
          <Paper {...containerProps} square>
            {children}
          </Paper>
        )}
      </div>
    );
  };

  handleSuggestionsClearRequested = () => {
    if (this.state.searchText == '') this.props.input.onChange(null);
  };

  getSuggestions = value => {
    const { suggestions } = this.props;
    const inputLength = value.trim().length;
    return inputLength === 0 ? [] : suggestions;
  };

  render() {
    const {
      input,
      labelKey,
      hintText,
      intl: { formatMessage: f },
      classes,
      className,
      disabled,
    } = this.props;

    const { searchText, suggestions } = this.state;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={this.renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionSelected}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.getLabel}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          value: searchText || '',
          onChange: this.handleChange,
          autoFocus: input.autoFocus,
          classes,
          className,
          label,
          hintText,
          disabled,
        }}
      />
    );
  }
}
