import * as React from 'react';
import {
  EPosition,
  EPositionSide,
  POSITION_SIDES,
  POSITIONS,
} from '../../../../compositions/typings';
import { CompositionCell } from '../CompositionCell';
import { IComponentProps } from './TeamCompositionGrid.connector';

export class TeamCompositionGrid extends React.Component<IComponentProps> {
  public render() {
    const { side } = this.props;
    const positions = [...POSITIONS];
    if (side === 'away') {
      positions.reverse();
    }
    return <div key={`grid-side-${side}`}>{positions.map(this.renderPosition)}</div>;
  }

  private renderPosition = (position: EPosition) => {
    const { classes, side } = this.props;
    const positionSides = [...POSITION_SIDES];
    if (side === 'away') {
      positionSides.reverse();
    }
    return (
      <div key={`grid-row-${position}`} className={classes.gridRow}>
        {positionSides.map(this.renderPositionSide(position))}
      </div>
    );
  };

  private renderPositionSide = (position: EPosition) => (positionSide: EPositionSide) => {
    const { side } = this.props;
    const disabled =
      position === EPosition.Goalkeeper && positionSide !== EPositionSide.Centre;
    return (
      <CompositionCell
        key={`grid-cell-${positionSide}`}
        side={side}
        position={position}
        positionSide={positionSide}
        disabled={disabled}
      />
    );
  };
}
