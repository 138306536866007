import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import { getProject } from '../../common/services/project';
import rest from '../../common/services/restFootData';
import { IAgentCompany } from './typings';

const entityRoute = 'agent_companies';

export const fetchAgentCompanies = (search: string) => {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return dispatch => {
    dispatch({ type: 'FETCH_AGENTCOMPANIES' });
    return rest.get(entityRoute, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_AGENTCOMPANIES_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_AGENTCOMPANIES_REJECTED',
          payload: err.response,
        })
    );
  };
};

export const fetchAllAgentCompanies = () => {
  const params = { pagination: 0 };

  return dispatch => {
    dispatch({ type: 'FETCH_AGENTCOMPANIES' });
    return rest.get(entityRoute, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_AGENTCOMPANIES_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_AGENTCOMPANIES_REJECTED',
          payload: err.response,
        })
    );
  };
};

/*
    get method looks if the data is in the store
    if not goes fetch the data
*/
export const getAllAgentCompanies = () => {
  return (dispatch, getState) => {
    const agentCompanies = getState().agentCompanies;
    if (agentCompanies.fetched) {
      return dispatch({ type: 'CACHED_AGENTCOMPANIES' });
    }
    return dispatch(fetchAllAgentCompanies());
  };
};
export const fetchAgentCompany = (id: string) => {
  const params = {
    groups: ['read', '_image'],
  };

  return dispatch => {
    dispatch({ type: 'FETCH_AGENTCOMPANY' });

    return rest
      .get(`${entityRoute}/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_AGENTCOMPANY_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_AGENTCOMPANY_REJECTED', payload: err.response })
      );
  };
};

export const addAgentCompany = (agentCompany: IAgentCompany, historyPush) => {
  return dispatch => {
    dispatch({ type: 'ADD_AGENTCOMPANY' });
    return rest.post(entityRoute, agentCompany).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            messageKey: 'message_agentCompanyCreated',
            type: 'success',
          },
        });
        dispatch({
          type: 'ADD_AGENTCOMPANY_FULFILLED',
          payload: res.data,
        });

        if (historyPush) {
          historyPush(`${getProject().url}/agent-companies/${res.data.id}`);
        }
      },
      err => dispatch({ type: 'ADD_AGENTCOMPANY_REJECTED', payload: err.response })
    );
  };
};

export const updateAgentCompany = (id: string, agentCompany: IAgentCompany) => {
  return dispatch => {
    dispatch({ type: 'UPDATE_AGENTCOMPANY', payload: id });
    return rest.put(`${entityRoute}/${id}`, agentCompany).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: 'Channel modifié', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_AGENTCOMPANY_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_AGENTCOMPANY_REJECTED',
          payload: err.response,
        });
      }
    );
  };
};
