import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function PlayerOfTheMatchIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 500 500">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M463 391c0 2-1 5-3 7l-48 73c0 1-1 1-2 2 0 0 0 1-1 1 0 0-1 0-1 0-1 0-1 1-1 1-1 0-1 0-2 0 0 0-1 0-1 0 0 0 0 0-1 0l-294 0c0 0-1 0-1 0-1 0-1 0-1 0-1 0-1 0-2 0 0 0-1-1-1-1 0 0-1 0-1 0-1 0-1-1-1-1-1 0-2-1-2-2l-49-74c-1-1-1-2-1-3 0-1 0-1 0-1 0-1-1-2-1-2 0-1 0-1 0-2 0 0 0-1 1-2 0 0 0-1 0-1 0-1 0-1 1-1 0-1 0-1 0-1 0 0 0 0 1 0l107-152c-17-21-26-46-26-73 0-68 55-122 123-122 67 0 122 54 122 122 0 27-9 52-24 72l107 152c1 2 2 5 2 8z m-208-330c-53 0-97 44-97 98 0 53 44 97 97 97 54 0 98-44 98-97 0-54-44-98-98-98z m-180 330l34 51 117-165c-20-5-37-14-52-28z m180-110c0 0-1 0-2 0l-69 97 143 0-69-97c-1 0-1 0-3 0z m89 122l-177 0-35 49 246 0z m-7-153c-15 13-33 22-52 27l117 165 34-51z m-81-15c-42 0-76-34-76-76 0-42 34-76 76-76 42 0 76 34 76 76 0 42-34 76-76 76z m-49-63l24-8 8-24-15-20-27 0c-7 10-11 22-12 37z m-7 30c8 11 18 19 31 24l23-17 0-24-22-17-24 8z m42-62l28 0 14-22-8-28c-6-1-13-3-20-3-7 0-14 0-21 3l-8 28z m16 44l0 25 23 17c13-5 24-13 33-24l-9-26-24-8z m15-44l9 25 24 8 22-15c-1-14-5-28-12-37l-27 0z"
        />
      </g>
    </SvgIcon>
  );
}
