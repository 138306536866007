import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProductDetail } from './Detail';
import { ProductsList } from './List';

export const ProductsRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.url} component={ProductsList} />
      <Route path={`${match.url}/:topicId`} component={ProductDetail} />
    </Switch>
  );
};
