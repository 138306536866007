import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from '../../../common/services/project';
import { IBookmaker } from '../typings';

interface IProps {
  tableData: IBookmaker;
}
export class BookmakerRow extends Component<IProps> {
  public render() {
    const { tableData } = this.props;
    const { name, code, priority, affiliationText, cssColor } = tableData;
    const href = `#${getProject().url}/bookmakers/${tableData.id}`;

    return (
      // @ts-ignore href
      <TableRow hover href={href} component="a">
        <TableCell>{name}</TableCell>
        <TableCell>{code}</TableCell>
        <TableCell>{priority}</TableCell>
        <TableCell>{affiliationText}</TableCell>
        <TableCell><span style={{backgroundColor: cssColor}}>{cssColor}</span></TableCell>
      </TableRow>
    );
  }
}
