import { notifyErrorAction } from '../../../../common/actions/notifications';
import { callTweetExtraction } from '../../../../footmercato/articles/actions';
import { IRootState } from '../../../../typings';
import { addMatchCommentary, deleteCommentary, updateCommentary } from '../../../commentaries/actions';
import { ICommentary } from '../../../commentaries/typings';
import { fetchMatch, updateMatch, updateMatchPeriod } from '../../../matches/actions';
import { selectMatch, selectMatchCommentariesEvents } from '../../../matches/selector';
import {
  IMatchEvent,
  IMatchEventGoal,
  IMatchEventMissedPenalty,
  IMatchEventSubstitute,
} from '../../../matchEvents/typings';
import { getCommentaryEventName } from '../../../matchEvents/utils';
import { recoverCommentary, resetLive } from '../actions';
import { getMatchChrono } from '../components/Chrono/utils';
import { EPeriod, PERIODS } from '../components/PeriodSelector/utils';
import { getLivePlayerAndSide } from '../selector';
import { ILiveState } from '../typings';
import { extractTweetId, getCommentaryInfo } from '../utils';
import { updateMatchScore, updateMatchScoreAfterDelete } from './matchActions';
import { handleMatchEventDelete, handleMatchEventPost, handleMatchEventUpdate } from './matchEventActions';

/**
 * createLiveCommentary
 *
 * if has event
 *  -> post match event
 *  -> update match score if is goal
 * post commentary
 * reset live
 */
export const createLiveCommentary = () => {
  return async (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const match: any = selectMatch(getState(), liveState.matchId);
    const matchAId = match['@id'];
    const { commentary } = liveState;
    let matchEvent: IMatchEvent | null = null;

    const eventName = getCommentaryEventName(commentary.type);
    if (eventName) {
      const matchEvents = selectMatchCommentariesEvents(getState(), getState().live.matchId);
      const matchEventPostResponse = await dispatch(handleMatchEventPost(eventName, commentary, match, matchEvents));
      matchEvent = matchEventPostResponse.payload as IMatchEvent;
      if (!matchEvent) {
        // Prevent creation of commentary without related matchEvent
        return dispatch(notifyErrorAction('message_matchEventFailedAndCommentaryCanceled'));
      }
      await dispatch(updateMatchScore(eventName, match, commentary, matchEvent));
    }
    const newCommentary = getCommentaryInfo(commentary, matchAId, matchEvent);
    await dispatch(addMatchCommentary(newCommentary));
    if (commentary.content.indexOf('twitter-tweet') !== -1) {
      const tweetId = extractTweetId(commentary.content);
      if (tweetId) {
        dispatch(callTweetExtraction(tweetId));
      }
    }
    return dispatch(resetLive());
  };
};

/**
 * updateLiveCommentary
 *
 * if has event -> update match event
 * if goal -> update match score
 * update commentary
 * reset live
 */
export const updateLiveCommentary = () => {
  return async (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    const match: any = selectMatch(getState(), liveState.matchId);
    const matchAId = match['@id'];
    const { commentary, editingCommentaryId, editingMatchEventId } = liveState;
    let matchEvent = null;

    if (editingMatchEventId) {
      const result = await dispatch(handleMatchEventUpdate(commentary, editingMatchEventId, match));
      matchEvent = result.payload;
    }

    const newCommentary = getCommentaryInfo(commentary, matchAId, matchEvent);
    await dispatch(updateCommentary(editingCommentaryId, newCommentary));
    if (commentary.content.indexOf('twitter-tweet') !== -1) {
      const tweetId = extractTweetId(commentary.content);
      if (tweetId) {
        dispatch(callTweetExtraction(tweetId));
      }
    }
    return dispatch(resetLive());
  };
};

/**
 * removeLiveCommentary
 *
 * delete commentary
 * if has event -> delete match event
 * if goal -> update match score
 */
export const removeLiveCommentary = (commentary: ICommentary) => {
  return async dispatch => {
    await dispatch(deleteCommentary(commentary.id));
    if (commentary.matchEvent) {
      await dispatch(handleMatchEventDelete(commentary.matchEvent));
      await dispatch(updateMatchScoreAfterDelete(commentary));
    }
  };
};

export const recoverLiveCommentary = (commentary: ICommentary) => {
  return (dispatch, getState) => {
    const { matchEvent } = commentary;
    const liveCommentary: any = commentary;

    if (matchEvent) {
      const state = getState();
      const playerAndSide = getLivePlayerAndSide(state, matchEvent.matchTeamPlayerId);
      liveCommentary.player = playerAndSide[0];
      liveCommentary.teamActionSide = playerAndSide[1];
      const player2Id =
        (matchEvent as IMatchEventSubstitute).playerOffId ||
        (matchEvent as IMatchEventGoal).assistPlayerId ||
        (matchEvent as IMatchEventMissedPenalty).savedByPlayerId;
      liveCommentary.player2 = getLivePlayerAndSide(state, player2Id)[0];
    }

    return dispatch(recoverCommentary(liveCommentary));
  };
};

export const updateLivePeriod = (period: EPeriod) => {
  return async (dispatch, getState) => {
    const state: IRootState = getState();
    const { live, intl } = state;

    const formData = new FormData();
    formData.append('period', String(period));

    await dispatch(updateMatchPeriod(live.matchId, formData));
    const match = (await dispatch(fetchMatch(live.matchId))).payload;

    const [timeMin, timeSec, additionnalTimeMin, additionnalTimeSec] = getMatchChrono(
      period,
      +new Date(match.chronoStart),
      +new Date(match.chronoEnd)
    );

    const commentary: { [key: string]: any } = {
      match: match['@id'],
      type: 'timer',
      isImportant: true,
      content: `## ${intl.messages['live_' + PERIODS[period as number]]}`,
      period,
      timeMin,
      timeSec,
      additionnalTimeMin,
      additionnalTimeSec,
    };

    await dispatch(addMatchCommentary(commentary));
    return dispatch(resetLive());
  };
};

export const updateChrono = (chronoStartTimeStamp: number) => {
  return (dispatch, getState) => {
    const liveState: ILiveState = getState().live;
    return dispatch(updateMatch(liveState.matchId, { chronoStart: new Date(chronoStartTimeStamp) }));
  };
};
