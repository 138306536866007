import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import ImageInput from 'FD/images/Input';
import { getLinkId } from 'Services/tools';
import TextEdit from 'Components/inputs/TextEdit';

export default class TagForm extends Component {
  config = {
    fields: ['id', 'description', 'footdataMainImageId'],
    objectFields: ['parent'],
    disposition: [
      {
        size: 3,
        fields: [
          {
            name: 'footdataMainImageId',
            component: ImageInput,
            normalize: getLinkId,
          },
        ],
      },
      {
        size: 9,
        fields: [{ name: 'description', component: TextEdit }],
      },
    ],
  };

  render() {
    const { data, onSubmit, onDelete } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`tag_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        data={data}
        config={this.config}
      />
    );
  }
}
