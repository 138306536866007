import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { StyledComponentProps, Theme, withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect, DispatchProp } from 'react-redux';
import AsyncSelect from 'react-select/async';
import debounce from '../../../common/services/tools';

export interface IInput {
  name: string;
  value: any;
  onChange: (value: any) => void;
}

interface IInputProps {
  input: IInput;
  labelKey: string;
  hintText: string;
  className: string;
  classes: any;
}

type IProps = StyledComponentProps &
  InjectedIntlProps &
  IInputProps &
  DispatchProp & {
    search: (text: string) => any;
    meta: any;
    getLabel: (data: any, translation: string) => string;
    CollectionChip: new (props: any) => React.Component;
  };

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'inherit',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 5,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

// @ts-ignore
@injectIntl
// @ts-ignore
@withStyles(styles, { withTheme: true })
// @ts-ignore
@connect()
export class InputCollection extends React.Component<IProps> {
  private loadOptions = debounce((inputValue: string, callback: any) => {
    const {
      dispatch,
      search,
      getLabel,
      intl: { messages: m },
    } = this.props;
    return dispatch(search(inputValue)).then(response => {
      if (Array.isArray(response.payload)) {
        const options = response.payload.map(result => ({
          value: result.object.id,
          label: getLabel(result.object, m.unit_short_years_old),
        }));
        callback(options);
      }
      callback([]);
    });
  }, 300);

  public render() {
    const {
      input,
      labelKey,
      // hintText,
      intl: { formatMessage: f },
      // @ts-ignore
      theme,
      classes,
      CollectionChip,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.primary[500],
        '& input': {
          font: 'inherit',
        },
      }),
    };

    const value = Array.isArray(input.value)
      ? input.value.map(s => ({ value: s, label: s }))
      : [];

    return (
      <div className={classes.root}>
        <AsyncSelect
          classes={classes}
          styles={selectStyles}
          components={{ ...components, MultiValue: CollectionChip }}
          isMulti
          textFieldProps={{
            label,
            InputLabelProps: { shrink: true },
          }}
          loadOptions={this.loadOptions}
          value={value}
          onChange={this.handleChange}
          placeholder="Select multiple"
        />
      </div>
    );
  }

  private handleChange = values => {
    const { input } = this.props;
    input.onChange(values ? values.map(v => v.value) : []);
  };
}
