import moment from 'moment';
import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import TextInput from '../../../../common/components/inputs/Text';
import { IPartnerOffer } from '../../typings';
import SelectConstant from '../../../../footdata/components/constants/Select';
import {CompetitionInput} from "../../../competitions/CompetitionInput";
import {getCompetitionLabel} from "../../../utils/competition";
import {buildLink} from "../../../../common/services/tools";

interface IProps {
  data: any;
  onSubmit: (partnerOffer: IPartnerOffer) => void;
  onDelete?: (partnerOffer: IPartnerOffer) => void;
  onRemove?: (id: string) => void;
}

export class PartnerOfferForm extends Component<IProps> {
  public config = {
    fields: ['id', 'shortText', 'url', 'createdAt', 'updatedAt', 'trackingPixel', 'usedFor'],
    objectFields: ['competition'],
    disposition: [
      {
        fields: [
          { name: 'id', component: TextInput, size: 6, required: true, disabled: true },
          { name: 'shortText', component: TextInput, size: 6, required: true },
          { name: 'usedFor', component: SelectConstant, entity: 'partnerOffer', size: 6, required: true },
          { name: 'trackingPixel', component: TextInput, size: 6 },
          { name: 'url', component: TextInput, size: 12 },
          { name: 'createdAt', component: TextInput, size: 6, disabled: true, format: this.formatDate },
          { name: 'updatedAt', component: TextInput, size: 6, disabled: true, format: this.formatDate },
        ],
      },
      {
        title: 'form_linkedData',
        fields: [
          {
            name: 'competition',
            component: CompetitionInput,
            size: 6,
            selectedCompetitionOption: this.props.data.competition ? {
              value: buildLink('Competition', this.props.data.competition.id),
              label: getCompetitionLabel(this.props.data.competition)
            } : null,
          },
        ],
      },
    ],
  };

  public formatDate(date) {
    if (!date) return '-';

    return `${moment(date).format('L')} - ${moment(date).format('LT')}`;
  }

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`partner_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
