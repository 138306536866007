import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusTemplateIcon({ ...props }) {
    return (
        <SvgIcon {...props}>
            <g fill="none">
                <rect width="22" height="22" fill="white" />
                <rect x="3" y="3" width="8" height="8" fill="#B1B1B1" />
                <rect x="0.5" y="0.5" width="21" height="21" stroke="#B1B1B1" />
                <rect x="11" y="11" width="8" height="8" fill="#B1B1B1" />
            </g>
        </SvgIcon>
    );
}
