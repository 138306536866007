import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NavigationClose from '@material-ui/icons/Close';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import ChannelInput from '../../channels/Input';
import { fetchBroadcasts } from '../actions';

@connect()
export default class MatchChannelRow extends React.Component {
  state = {
    channel: '',
    secondaryChannel: '',
  };

  componentDidMount() {
    const { dispatch, match } = this.props;
    dispatch(fetchBroadcasts(`match=${match.id}&live=1`)).then(({ payload: { collection } }) => {
      if (!collection || !collection[0]) return 0;

      this.setState({
        channel: collection[0].channel ? collection[0].channel['@id'] : '',
        secondaryChannel: collection[0].secondaryChannel ? collection[0].secondaryChannel['@id'] : '',
        broadcastId: collection[0].id,
      });
    });
  }

  handleChannel = channel => {
    this.setState({ channel }, this.handleUpdate);
  };

  handleSecondaryChannel = secondaryChannel => {
    this.setState({ secondaryChannel }, this.handleUpdate);
  };

  clearChannel = () => {
    this.handleChannel('');
  };

  clearSecondaryChannel = () => {
    this.handleSecondaryChannel('');
  };

  handleUpdate = () => {
    const { match, onChange } = this.props;
    const { channel, secondaryChannel, broadcastId } = this.state;
    const values = {
      channel: channel || null,
      secondaryChannel: secondaryChannel || null,
      broadcastId,
      date: match.date,
    };
    onChange(match['@id'], values);
  };

  render() {
    const { match, channels } = this.props;
    const { channel, secondaryChannel } = this.state;
    const activeChannel = channels.filter(channel => channel.isActive === true);

    return (
      <TableRow>
        <TableCell padding="none">{`${moment(match.date).format('L')} - ${moment(match.date).format('LT')}`}</TableCell>
        <TableCell>{match.description}</TableCell>
        <TableCell padding="none">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <ChannelInput
                key={`input_match_${match.id}_channel`}
                channels={activeChannel}
                input={{
                  name: 'channel',
                  onChange: this.handleChannel,
                  value: channel,
                }}
              />
              {channel ? (
                <IconButton onClick={this.clearChannel}>
                  <NavigationClose />
                </IconButton>
              ) : null}
            </div>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <ChannelInput
                key={`input_match_${match.id}_secondaryChannel`}
                channels={activeChannel}
                input={{
                  name: 'secondaryChannel',
                  onChange: this.handleSecondaryChannel,
                  value: secondaryChannel,
                }}
                style={{ marginLeft: 16 }}
              />
              {secondaryChannel ? (
                <IconButton onClick={this.clearSecondaryChannel}>
                  <NavigationClose />
                </IconButton>
              ) : null}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}
