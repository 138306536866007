import { TextFieldProps } from '@material-ui/core/TextField';
import { RefObject } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Suggestion } from 'react-mde';
import { compose } from 'recompose';
import { MarkDownEditor as MarkDownEditorComponent } from './MarkDownEditor.component';

type IOwnProps = TextFieldProps & {
  labelKey?: string;
  key?: string;
  name?: string;
  input: any;
  setRef?: (element: RefObject<HTMLElement>) => void;
  compact: boolean;
  mode?: string;
  loadSuggestions?: (text: string, character: string) => Promise<Suggestion[]>;
  suggestionTriggerCharacters?: string[];
};
export type IComponentProps = InjectedIntlProps & IOwnProps;

const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const MarkDownEditor = connector(MarkDownEditorComponent);
