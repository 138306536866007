import QuoteIcon from '@material-ui/icons/FormatQuote';
import VideocamIcon from '@material-ui/icons/Videocam';
import { cleanMarkdown } from '../../../common/components/inputs/TextEdit/utils';
import { ICommentaryType } from '../../commentaries/typings';
import { IMatchEvent, IMatchEventPenaltyShot } from '../../matchEvents/typings';
import { getMatchChrono } from './components/Chrono/utils';
import { EPeriod } from './components/PeriodSelector/utils';
import { ActionIcon } from './svg/Action';
import { AdditionnalTimeIcon } from './svg/AdditionnalTime';
import { ChampionIcon } from './svg/Champion';
import { GoalIcon } from './svg/Goal';
import { InjuryIcon } from './svg/Injury';
import { OwnGoalIcon } from './svg/OwnGoal';
import { PenaltyIcon } from './svg/Penalty';
import { PenaltyGoalIcon } from './svg/PenaltyGoal';
import { PenaltyShotMissedIcon } from './svg/PenaltyShotMissed';
import { PenaltyShotSavedIcon } from './svg/PenaltyShotSaved';
import { PenaltyShotScoredIcon } from './svg/PenaltyShotScored';
import { PlayerOfTheMatchIcon } from './svg/PlayerOfTheMach';
import { RedCardIcon } from './svg/RedCard';
import { SecondYellowCardIcon } from './svg/SecondYellowCard';
import { StatisticIcon } from './svg/Statistic';
import { SubstituteIcon } from './svg/Substitute';
import { SubstituteInIcon } from './svg/SubstituteIn';
import { SubstituteOutIcon } from './svg/SubstituteOut';
import { TextIcon } from './svg/Text';
import { TimerIcon } from './svg/Timer';
import { WhistleIcon } from './svg/Whistle';
import { YellowCardIcon } from './svg/YellowCard';
import { ILiveCommentary, ILiveState, IPlayer } from './typings';

const actionsWithPlayer: ICommentaryType[] = [
  'goal',
  'own_goal',
  'penalty_goal',
  'missed_penalty',
  'substitute',
  'yellow_card',
  'second_yellow_card',
  'red_card',
  'penalty_shot_scored',
  'penalty_shot_saved',
  'penalty_shot_missed',
];
const actionsWithPlayer2: ICommentaryType[] = ['goal', 'substitute', 'missed_penalty'];

export const isActionWithPlayer = (action: ICommentaryType) => actionsWithPlayer.indexOf(action) !== -1;

export const isActionWithPlayer2 = (action: ICommentaryType) => actionsWithPlayer2.indexOf(action) !== -1;

export const getPlayersFieldTranslationKey = (type: ICommentaryType) => {
  const player1Keys = getPlayer1InputTextKeys(type);
  const player2Keys = getPlayer2InputTextKeys(type);
  return {
    player1Title: player1Keys[0],
    player1HelperText: player1Keys[1],
    player2Title: player2Keys[0],
    player2HelperText: player2Keys[1],
  };
};

const getPlayer1InputTextKeys = (type: ICommentaryType): string[] => {
  let key = 'player';
  switch (type) {
    case 'goal':
    case 'own_goal':
    case 'penalty_goal':
      key = 'scorer';
      break;
    case 'substitute':
      key = 'incomingPlayer';
      break;
  }
  return [`field_player1_${key}`, `field_player1_${key}_helper`];
};

const getPlayer2InputTextKeys = (type: ICommentaryType): string[] => {
  let key = '';

  switch (type) {
    case 'goal':
      key = 'assist';
      break;
    case 'substitute':
      key = 'outgoingPlayer';
      break;
    case 'missed_penalty':
      key = 'goalkeeper';
      break;
    default:
      return ['', ''];
  }
  return [`field_player2_${key}`, `field_player2_${key}_helper`];
};

export const getIsTypeCompleted = (type: ICommentaryType, player: IPlayer | null, player2: IPlayer | null) => {
  if (!isActionWithPlayer(type)) {
    return true;
  }
  if (type === 'substitute') {
    return !!player && !!player2;
  }
  return !!player;
};

const iconCollection = {
  text: TextIcon,
  goal: GoalIcon,
  own_goal: OwnGoalIcon,
  penalty_goal: PenaltyGoalIcon,
  missed_penalty: PenaltyShotMissedIcon,
  penalty: PenaltyIcon,
  substitute: SubstituteIcon,
  substitute_in: SubstituteInIcon,
  substitute_out: SubstituteOutIcon,
  yellow_card: YellowCardIcon,
  second_yellow_card: SecondYellowCardIcon,
  red_card: RedCardIcon,
  injury: InjuryIcon,
  action: ActionIcon,
  whistle: WhistleIcon,
  var: VideocamIcon,
  quote: QuoteIcon,
  player_of_the_match: PlayerOfTheMatchIcon,
  champion: ChampionIcon,
  statistic: StatisticIcon,
  timer: TimerIcon,
  additional_time: AdditionnalTimeIcon,
  penalty_shot_scored: PenaltyShotScoredIcon,
  penalty_shot_saved: PenaltyShotSavedIcon,
  penalty_shot_missed: PenaltyShotMissedIcon,
};
export const getTypeIcon = type => {
  return iconCollection[type];
};

export const getIconNameFromEvent = (event: IMatchEvent) => {
  switch (event.name) {
    case 'goal':
      if (event.type === 'own_goal') {
        return 'own_goal';
      } else {
        return 'goal';
      }
      break;
    case 'card':
      if (event.type === 'yellow') {
        return 'yellow_card';
      } else if (event.type === 'second_yellow') {
        return 'second_yellow_card';
      } else return 'red_card';
      break;
    case 'substitute':
      return 'substitute_in';
    case 'penalty_shot':
      if (event.result === 'scored') {
        return 'penalty_shot_scored';
      }
      if (event.result === 'saved') {
        return 'penalty_shot_saved';
      }
      if (event.result === 'missed') {
        return 'penalty_shot_missed';
      }
      break;
  }
  return '';
};
export const getIconNameFromOffEvent = () => {
  return 'substitute_out';
};

export const getInitializeCommentaryState = (state: ILiveState) => {
  const period = state.period;

  const [timeMin, timeSec, additionnalTimeMin, additionnalTimeSec] = getMatchChrono(
    period,
    state.chronoStartTimeStamp,
    state.chronoEndTimeStamp
  );
  return {
    type: 'text',
    isTypeCompleted: true,
    content: '',
    timeMin,
    timeSec,
    additionnalTimeMin,
    additionnalTimeSec,
    isImportant: false,
    hasVideo: false,
    player: null,
    player2: null,
    teamActionSide: null,
    period,
  };
};

export const getScores = (matchEvents: IMatchEvent[], pendingGoal?: Partial<IMatchEvent>) => {
  const scores = {
    home: 0,
    away: 0,
  };
  if (pendingGoal && pendingGoal.matchTeamPosition) {
    scores[pendingGoal.matchTeamPosition]++;
  }

  matchEvents.filter(ev => ev.name === 'goal').forEach(ev => ev.matchTeamPosition && scores[ev.matchTeamPosition]++);
  return scores;
};

export const getShootoutScores = (matchEvents: IMatchEvent[], pendingShootout?: Partial<IMatchEventPenaltyShot>) => {
  const scores = {
    home: 0,
    away: 0,
    sideNumber: 0,
    ordering: -1,
  };

  if (pendingShootout && pendingShootout.matchTeamPosition) {
    if (pendingShootout.result === 'scored') {
      scores[pendingShootout.matchTeamPosition]++;
    }
    scores.ordering++;
    scores.sideNumber++;
  }

  matchEvents
    .filter(ev => ev.name === 'penalty_shot' && ev.matchTeamPosition)
    .forEach(ev => {
      // scores
      if ((ev as IMatchEventPenaltyShot).result === 'scored') {
        scores[ev.matchTeamPosition]++;
      }
      // sideNumber
      if (pendingShootout && ev.matchTeamPosition === pendingShootout.matchTeamPosition) {
        scores.sideNumber++;
      }
      scores.ordering++;
    });

  return scores;
};

export const getMatchTeamPosition = (teamActionSide, type: ICommentaryType) => {
  if (type !== 'own_goal') return teamActionSide;
  return teamActionSide === 'home' ? 'away' : 'home';
};

export const getCommentaryInfo = (commentary: ILiveCommentary, matchAId: string, matchEvent: IMatchEvent | null) => ({
  match: matchAId,
  matchEvent: matchEvent ? matchEvent['@id'] : null,
  type: commentary.type,
  isImportant: commentary.isImportant,
  hasVideo: commentary.hasVideo,
  content: cleanMarkdown(commentary.content),
  timeMin: commentary.timeMin,
  timeSec: commentary.timeSec,
  additionnalTimeMin: commentary.additionnalTimeMin,
  additionnalTimeSec: commentary.additionnalTimeSec,
  period: commentary.period,
});

export const getCustomContent = (commentary: ILiveCommentary, defaultContents: { [key: string]: string }): string => {
  switch (commentary.type) {
    case 'additional_time':
      const additionalTimeKeyLabel =
        commentary.period === EPeriod.FirstHalf ? 'additionalTimeFirstHalf' : 'additionalTimeSecondHalf';

      return defaultContents[additionalTimeKeyLabel];

    default:
      return '';
  }
};

export const extractTweetId = (markdown: string) => {
  const regex = /https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/g;
  const result = regex.exec(markdown);
  if (result && result.length > 3) {
    return result[3];
  }
  return null;
};
