import React, { Component } from 'react';
import ReactMde from 'react-mde';
import { CommandGroup } from 'react-mde/lib/definitions/types';
import 'react-mde/lib/styles/css/react-mde-all.css';
import Showdown from 'showdown';
import ImageModal from '../../../../../footdata/images/Command/Modal';
import EmbedModal from '../../../../../footmercato/articles/embed/Command/Modal';
import debounce from '../../../../services/tools';
import { cleanMarkdown, handlePreviewMarkdown } from '../utils';
import { getCommands } from './commands';
import { getCommandIconInfo, getCommandLabelKey } from './commands/utils';
import { IComponentProps } from './index';
import './style.css';

const converter = new Showdown.Converter({
  tables: true,
});

interface IState {
  value: string;
  selectedTab: 'write' | 'preview';
}

export class MarkDownEditor extends Component<IComponentProps, IState> {
  public state: IState = {
    selectedTab: 'write',
    value: this.props.input.value,
  };
  private commands: CommandGroup[] = [];

  private handleOnChange = debounce(input => {
    this.props.input.onChange(cleanMarkdown(input));
  }, 150);

  constructor(props: IComponentProps) {
    super(props);
    this.commands = getCommands();
  }

  public render() {
    const { selectedTab, value } = this.state;

    const {
      input: { name },
      labelKey,
      intl: { formatMessage: f, messages: m },
      setRef,
      compact,
      loadSuggestions,
      suggestionTriggerCharacters,
    } = this.props;

    const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
    const additionnalParams: any = {};

    if (compact) {
      additionnalParams.minEditorHeight = 96;
    }

    return (
      <div className="mde-container">
        <div style={{ fontSize: 16, color: '#757575', marginTop: 8 }}>{label}</div>
        <ReactMde
          key="textarea"
          name={name}
          setRef={setRef}
          value={value}
          getIcon={this.getIcon}
          onChange={this.handleValueChange}
          selectedTab={selectedTab}
          onTabChange={this.setSelectedTab}
          commands={this.commands}
          generateMarkdownPreview={this.generateMarkdownPreview}
          l18n={{
            write: m.editor_write,
            preview: m.editor_preview,
          }}
          loadSuggestions={loadSuggestions}
          suggestionTriggerCharacters={suggestionTriggerCharacters}
          {...additionnalParams}
        />
        <ImageModal imageType={name === 'content' ? 'articleContentImage' : 'editorImage'} />
        <EmbedModal />
      </div>
    );
  }

  private handleValueChange = value => {
    this.setState({ value });
    this.handleOnChange(value);
  };

  private generateMarkdownPreview = (markdown: string) =>
    Promise.resolve(converter.makeHtml(handlePreviewMarkdown(markdown)));

  private setSelectedTab = (selectedTab: 'write' | 'preview') => this.setState({ selectedTab });

  private getIcon = (commandName: string) => {
    const icon = getCommandIconInfo(commandName);
    if (!icon) return null;
    const TagName = icon.tag;
    const label = this.props.intl.messages[getCommandLabelKey(commandName)];
    return <TagName color={icon.color || 'inherit'} title={label} />;
  };
}
