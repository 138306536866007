import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IdBadge } from 'Components/IdBadge';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getCollection } from 'Services/connectors';
import { getFMProject, getProject } from 'Services/project';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { getSiteMatchHref } from '../../../footdata/utils/href';
import { addArticle } from '../../../footmercato/articles/actions';
import { fetchAuthorMe } from '../../../footmercato/authors/actions';
import { addBroadcast, fetchMatchBroadcasts } from '../../broadcasts/actions';
import {
  fetchMatch,
  fetchMatchFormations,
  fetchMatchPreview,
  updateMatch,
  getMatchPronosticStat,
} from '../actions';
import Bloc from '../Bloc';
import { getDefaultMatchPath, shouldEnableLiveButton } from '../utils';
import Broadcasts from './Broadcasts';
import MatchForm from './Form';
import Preview from './Preview';
import moment from 'moment';
import { MatchPronosticStat } from "../Pronostics/MatchPronosticStat.component";
import { getFDProjectUrl } from "../../../common/services/project";

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  game: store.match.detail[props.match.params.topicId],
  fetching: store.match.fetching,
  broadcasts: getCollection('broadcastsIds', store.match, store.broadcast),
  me: store.me,
}))
export default class Detail extends Component {
  menu = [
    { label: 'app_matchInfo', link: '/' },
    { label: 'app_matchPresentation', link: '/presentation' },
    { label: 'app_matchPreview', link: '/preview' },
    { label: 'app_broadcasts', link: '/broadcasts' },
    { label: 'app_pronostics', link: '/pronostics' },
  ];

  state = {
    preview: {},
    formations: {},
    matchPronosticStat: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    const matchId = targetId || id;

    dispatch(fetchMatch(matchId)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchMatchBroadcasts(matchId));
        dispatch(fetchMatchPreview(matchId)).then(({ payload }) => {
          if (payload && payload.collection) {
            this.setState({ preview: payload.collection });
          }
        });
        dispatch(fetchMatchFormations(matchId)).then(({ payload }) => {
          if (payload && payload.collection) {
            this.setState({ formations: payload.collection[0] });
          }
        });
        dispatch(getMatchPronosticStat(matchId)).then(({ payload }) => {
          if (payload && payload.collection) {
            this.setState({ matchPronosticStat: payload.collection[0] });
          }
        });
      }
    });
  };

  handleMatchChange = match => {
    const { dispatch } = this.props;
    return dispatch(updateMatch(match.id, match));
  };

  handleActivateLiveChange = () => {
    const { dispatch, id, game } = this.props;

    const params = {
      slug: game.slug,
      isCommented: true,
      live: 'human',
      mainCommentatorLocale: 'fr',
    };

    return dispatch(updateMatch(id, params));
  };

  handleGenerateArticleWithMatchFeatured = () => {
    const {
      game,
      intl: { formatMessage: f },
      dispatch,
      history,
      me,
    } = this.props;

    const formatUTC = 'YYYY-MM-DDTHH:mm:ssZZ';
    const publishedAt = moment
      .utc(game.date)
      .subtract(10, 'minutes')
      .format(formatUTC);
    const unpublishedAt = moment
      .utc(game.date)
      .add(133, 'minutes')
      .format(formatUTC);
    const fixedPositionEndAt = moment
        .utc(game.date)
        .add(133, 'minutes')
        .format(formatUTC);
    // 90 fulltime
    // 3 + 5 additionnals times
    // 15 halftime break
    // 20 after end match

    dispatch(fetchAuthorMe(me.username)).then(({ payload }) => {
      const article = {
        authors: [payload['@id']],
        title: f(
          { id: 'artitcle_title_matchFeatured' },
          {
            home: game.homeTeam.name,
            away: game.awayTeam.name,
          }
        ),
        status: 'publish',
        publishedAt,
        unpublishedAt,
        isFeatured: true,
        type: 'center',
        redirectUrl: getSiteMatchHref(game),
        footdataCompetitionIds: [game.phaseTournamentCompetitionId],
        footdataMatchIds: [game.id],
        footdataTeamIds: [game.homeTeam.id, game.awayTeam.id],
        fixedPosition: 1,
        fixedPositionEndAt,
        specificity: 'live',
      };

      dispatch(addArticle(article)).then(({ payload }) => {
        if (payload && payload.id) {
          const urlFM = getFMProject(getProject().lang).url;
          history.push(`${urlFM}/articles/${payload.id}`);
        }
      });
    });
  };

  handleBroadcastAdd = ({ live, ...broadcast }) => {
    const { dispatch, game, history } = this.props;
    dispatch(
      addBroadcast({
        live: !!live,
        ...broadcast,
        match: game['@id'],
      })
    ).then(({ payload }) => {
      history.push(`${getProject().url}/broadcasts/${payload.id}`);
    });
  };

  getLabel = game => game.description || `${game.homeTeam.name} vs ${game.awayTeam.name}`;

  render() {
    const {
      fetching,
      game,
      broadcasts,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    const { preview, formations, matchPronosticStat } = this.state;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!game) {
      return <div>{m.error_noData}</div>;
    }
    const liveHref = `#${getFDProjectUrl()}/live/${game.id}`;
    const liveDisabled = !shouldEnableLiveButton(game);

    return (
      <Layout
        id="match-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_matches', href: `#${getDefaultMatchPath()}` },
              { label: this.getLabel(game) },
            ]}
          />
        }
        main={
          <div>
            <Paper elevation={2}>
              <Toolbar>
                <Typography variant="h6">{game.description}</Typography>
                <IdBadge id={game.id} />
                <div style={{ flex: 1 }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleGenerateArticleWithMatchFeatured}
                  style={{ marginRight: 8 }}
                >
                  {m.button_generateArticleWithMatchFeatured}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleActivateLiveChange}
                  style={{ marginRight: 8 }}
                >
                  Active live FR
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  href={liveHref}
                  style={{ marginRight: 8 }}
                  disabled={liveDisabled}
                >
                  {m.live_commentary}
                </Button>
                <ReturnButton entity="matches" />
              </Toolbar>

              <TabsRoute match={match} menu={this.menu} history={history} />

              <Route
                exact
                path={match.url}
                render={() => <MatchForm onSubmit={this.handleMatchChange} data={game} />}
              />
              <Route
                path={`${match.url}/presentation`}
                render={() => <Bloc match={game} formations={formations} />}
              />
              <Route
                path={`${match.url}/preview`}
                render={() => (
                  <Preview
                    preview={preview}
                    awayLogo={
                      game.awayTeam && game.awayTeam.club && game.awayTeam.club.logo
                    }
                    homeLogo={
                      game.homeTeam && game.homeTeam.club && game.homeTeam.club.logo
                    }
                  />
                )}
              />
              <Route
                path={`${match.url}/broadcasts`}
                render={() => (
                  <Broadcasts broadcasts={broadcasts} onAdd={this.handleBroadcastAdd} />
                )}
              />
              <Route
                path={`${match.url}/pronostics`}
                render={() => (
                    <MatchPronosticStat match={game} matchPronosticStat={matchPronosticStat} />
                )}
              />
            </Paper>
          </div>
        }
      />
    );
  }
}
