import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
  listSubheader: {
    backgroundColor: theme.palette.grey[200],
    lineHeight: '31px',
  },
});
