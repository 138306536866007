import TextField from '@material-ui/core/TextField';
import React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';

function DatetimeInput({
  input: { value, ...input },
  labelKey,
  intl: { formatMessage: f },
  meta: { touched, error },
  hintText,
  helperKey,
  required,
  alwaysDisplayError
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${input.name}` });
  const displayError = alwaysDisplayError || touched;
  /*
    Date Format
    2019-11-14T15:30:00+00:00
    2019-11-14T16:30:00+01:00
    2019-11-14T16:30
  */
  const localDateValue = value
    ? moment(value)
        .format()
        .substring(0, 16)
    : undefined;

  return (
    <TextField
      label={label}
      value={localDateValue}
      error={displayError && error}
      helperText={(displayError && error && f({ id: error })) || (helperKey && f({ id: helperKey }))}
      type="datetime-local"
      InputLabelProps={{
        shrink: true,
        placeholder: hintText || label,
      }}
      inputProps={{ max: '2999-01-01T00:00:00' }}
      {...input}
      fullWidth
      required={required}
    />
  );
}
export default injectIntl(DatetimeInput);

export function formatUtc(datetime) {
  if (!datetime) return null;
  return moment(datetime)
    .utc()
    .format();
}
