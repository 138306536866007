import { MarkdownUtil } from 'react-mde';
import { getProject } from 'Services/project';

export const quoteCommand = {
  name: 'quote',
  execute: (state0, api) => {
    const { lang } = getProject();

    let start = '"';
    let end = start;

    if (lang === 'fr' || lang === 'es') {
      start = '«';
      end = '»';
    }

    if (lang === 'de') {
      start = '„';
      end = '“';
    }

    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = MarkdownUtil.selectWord({
      text: state0.text,
      selection: state0.selection,
    });
    const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the current word
    const state2 = api.replaceSelection(`${start}${state1.selectedText}${end}`);
    // Adjust the selection to not contain the markdown syntax
    api.setSelectionRange({
      start: state2.selection.end - 2 - state1.selectedText.length,
      end: state2.selection.end,
    });
  },
  keyCommand: 'quote',
};
