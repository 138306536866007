import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import TvIcon from '@material-ui/icons/Tv';
import filterList from 'Actions/lists';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { parseQuery, stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce, { getLinkId } from '../../../common/services/tools';
import { fetchBroadcastsList } from '../actions';
import Row from './Row';
import DateInput from 'Components/inputs/Date';
import CompetitionInput from '../../competitions/Input';
import TeamInput from '../../teams/Input';
import { UserRestriction } from '../../../common/components/UserRestiction';

@injectIntl
@connect((store, props) => ({
  broadcasts: store.broadcasts.list,
  fetching: store.broadcasts.fetching,
  pagination: store.broadcasts.pagination,
  total: store.broadcasts.totalItems,
  query: parseQuery(props.location.search),
}))
export default class List extends Component {
  config = {
    filters: [
      {
        type: 'name',
        labelKey: 'filter_name',
      },
      {
        type: 'broadcastRelationsCompetitions',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
      {
        type: 'broadcastRelationsTeams',
        input: TeamInput,
        labelKey: 'filter_team',
      },
      {
        type: 'dateStrictlyBefore',
        input: DateInput,
      },
      {
        type: 'dateStrictlyAfter',
        input: DateInput,
      },
    ],
    headers: [
      { id: 'date', labelKey: 'field_date' },
      { id: 'live', labelKey: 'field_live', padding: 'none' },
      {
        id: 'match.matchteamHomeTeamseasonTeamName',
        labelKey: 'match_homeTeam',
      },
      {
        id: 'match.matchteamAwayTeamseasonTeamName',
        labelKey: 'match_awayTeam',
      },
      { id: 'channel.name', labelKey: 'field_channel' },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;
    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchBroadcastsList(filter));
    dispatch(filterList('broadcasts', filter));
  };

  handleFilters = change => {
    const filtersQuery = {
      ...change,
      broadcastRelationsCompetitions: getLinkId(change.broadcastRelationsCompetitions),
      broadcastRelationsTeams: getLinkId(change.broadcastRelationsTeams),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search != search) {
      history.push(`${getProject().url}/broadcasts${search}`);
    }
  }, 500);

  render() {
    const {
      broadcasts,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="broadcasts-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_broadcasts' }]} />
            <Button
              style={{ marginRight: 8 }}
              href={`#${getProject().url}/channels`}
              variant="contained"
              color="primary"
            >
              <TvIcon style={{ marginRight: 8 }} />
              {m.button_manageChannels}
            </Button>
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_broadcastList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <Button href={`#${getProject().url}/broadcasts/add`} variant="contained" color="primary">
                  <AddIcon style={{ marginRight: 8 }} />
                  {m.button_add}
                </Button>
              </UserRestriction>
            </Toolbar>
            <SearchFilter
              form="broadcasts_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={broadcasts}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
