import { Suggestion } from 'react-mde';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { getPersonMatchName, getPersonUsualName } from '../../../../people/utils';

const createSuggestions = (character: string) => (name: string) => ({
  preview: character + name,
  value: name,
});

export const buildPlayersSuggestions = (
  homeMatchTeamPlayers: { [key: string]: IMatchTeamPlayer },
  awayMatchTeamPlayers: { [key: string]: IMatchTeamPlayer }
): Suggestion[] => {
  const players: IMatchTeamPlayer[] = [];
  if (homeMatchTeamPlayers) {
    players.push(...Object.values(homeMatchTeamPlayers));
  }
  if (awayMatchTeamPlayers) {
    players.push(...Object.values(awayMatchTeamPlayers));
  }
  const suggestionsLabels: string[] = [];
  players.forEach((player: IMatchTeamPlayer) => {
    suggestionsLabels.push(getPersonMatchName(player.membership.person));
    suggestionsLabels.push(getPersonUsualName(player.membership.person));
  });
  return suggestionsLabels.map(createSuggestions('@'));
};

export const buildTeamsSuggestions = (homeTeam, awayTeam): Suggestion[] => {
  if (!homeTeam || !awayTeam) {
    return [];
  }
  const teams = [homeTeam, awayTeam];
  return teams.map(team => team.name).map(createSuggestions('#'));
};
