import { withStyles, WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { IRootState } from '../../../typings';
import { isUserLimited } from '../../users/utils';
import { styles } from './styles';
import { IFormConfig } from './typings';
import { getInitialValues } from './utils';

interface IOwnProps {
  data: any;
  config: IFormConfig;
  main: boolean;
  entity: string;
  img?: string;
  useUserRestriction?: boolean;
  externalDisable?: boolean;
  inline?: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onRemove: () => void;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    isRestricted: ownProps.useUserRestriction && isUserLimited(state.me),
    enableReinitialize: true,
    initialValues: getInitialValues(ownProps.data, ownProps.config),
  };
};

export type IComponentProps = ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps &
  InjectedFormProps &
  WithStyles;

const reduxConnector = connect(mapStateToProps);

export const connector = compose<IComponentProps, IOwnProps>(
  withStyles(styles),
  injectIntl,
  reduxConnector,
  reduxForm({})
);
