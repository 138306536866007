import CodeIcon from '@material-ui/icons/Code';
import Crop75Icon from '@material-ui/icons/Crop75';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import Quote from '@material-ui/icons/FormatQuote';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import TitleIcon from '@material-ui/icons/Title';
import SimpleQuoteDeutsch from '../../../../icons/SimpleQuoteDeutsch';
import SimpleQuoteGlobal from '../../../../icons/SimpleQuoteGlobal';

const icons = {
  header: { tag: TitleIcon },
  bold: { tag: FormatBoldIcon },
  italic: { tag: FormatItalicIcon },
  link: { tag: InsertLinkIcon },
  button: { tag: Crop75Icon },
  code: { tag: CodeIcon },
  media: { tag: InsertPhotoIcon, color: 'primary' },
  embed: { tag: DynamicFeedIcon, color: 'primary' },
  quote: { tag: Quote },
  simpleQuoteDeutsch: { tag: SimpleQuoteDeutsch },
  simpleQuoteGlobal: { tag: SimpleQuoteGlobal },
  'unordered-list': { tag: FormatListBulletedIcon },
  'ordered-list': { tag: FormatListNumberedIcon },
};

export const getCommandIconInfo = (commandName: string) => icons[commandName];

export const getCommandLabelKey = (commandName: string) =>
  `editor_command${toPascalCase(commandName)}`;

const toPascalCase = name =>
  name
    .split('-')
    .reduce((acc, cur) => acc + cur.charAt(0).toUpperCase() + cur.slice(1), '');
