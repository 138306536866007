import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import DeleteButton from 'Components/DeleteButton';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getCollection, getItemBySlug } from 'Services/connectors';
import { getProject } from 'Services/project';
import { buildFMLink } from '../../../common/services/project';
import { fetchTagArticles } from '../../articles/actions';
import TopBar from '../../components/TopBar';
import { deleteTag, fetchTagSlug, updateTag } from '../actions';
import Articles from './Articles';
import Children from './Children';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  tag: getItemBySlug(store.tag, props.match.params.topicSlug),
  fetching: store.tag.fetching,
  articles: getCollection('articlesIds', store.tag, store.article),
}))
export default class TagDetail extends Component {
  state = {
    siteUrl: '',
  };
  menu = [{ label: 'app_tagInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchTagSlug(targetSlug || slug)).then(({ payload }) => {
      if (payload) {
        dispatch(fetchTagArticles(payload.id));
        buildFMLink(`c${payload.id}-${payload.slug}`).then(siteUrl => this.setState({ siteUrl }));
      }
    });
  };

  handleTagUpdate = changes => {
    const { dispatch, tag } = this.props;
    return dispatch(updateTag(tag.id, changes));
  };

  handleRemove = () => {
    const { tag, dispatch, history } = this.props;
    return dispatch(deleteTag(tag.id)).then(() => {
      history.push(`${getProject().url}/tags`);
    });
  };

  getLabel = () => {
    const {
      tag,
      intl: { messages: m },
    } = this.props;
    return tag.title || `${m.app_tag}: ${tag.id}`;
  };

  render() {
    const {
      fetching,
      tag,
      articles,
      match,
      history,
      intl: { messages: m },
    } = this.props;
    const { siteUrl } = this.state;

    if (!tag && fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!tag) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="tag-layout"
        top={
          <TopBar
            crumbStack={[{ labelKey: 'app_tags', href: `#${getProject().url}/tags` }, { label: this.getLabel() }]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{this.getLabel()}</Typography>
              <div style={{ flex: 1 }} />
              <Button variant="contained" href={siteUrl} target="_blank" style={{ marginRight: 8 }}>
                <LinkIcon style={{ marginRight: 8 }} />
                {m.link_to_website}
              </Button>
              <DeleteButton onRemove={this.handleRemove} />
              <ReturnButton entity="tags" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={() => <Form onSubmit={this.handleTagUpdate} data={tag} />} />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Children tags={tag.children} />
            <Articles articles={articles} tagId={tag.id} />
          </Paper>
        }
      />
    );
  }
}
