import axios from 'axios';
import jwt from 'jsonwebtoken';

const documentApp = document.getElementById('app');
const refreshToken = documentApp
  ? documentApp.getAttribute('data-refresh-token')
  : null;
let authorizationToken = documentApp
  ? documentApp.getAttribute('data-token')
  : null;

/* global process*/
const baseURL = process.env.INTERNAL_API_URL;
const rest = axios.create({
  baseURL,
});

export default function getAuthorizationToken() {
  return new Promise((resolve, reject) => {
    // If authorization token is expired, generate a new one using refresh token
    const tokenData = jwt.decode(authorizationToken);
    if (tokenData.exp < new Date().valueOf() / 1000) {
      rest.post('token/refresh', { refresh_token: refreshToken }).then(
        res => {
          authorizationToken = res.data.token;
          resolve(res.data.token);
        },
        err => {
          reject(err);
        }
      );
    } else {
      // Return last token if still valid
      resolve(authorizationToken);
    }
  });
}
