import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import Datetime, { formatUtc } from 'Components/inputs/Datetime';
import TextInput from 'Components/inputs/Text';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'name',
      'slug',
      'isOverSingleYear',
      'isActive',
      'hasRankings',
      'isMultiGroupChampionship',
      'hasMatches',
      'nextDrawDate',
    ],
    disposition: [
      {
        size: 6,
        fields: [
          { name: 'name', component: TextInput },
          { name: 'slug', component: TextInput, disabled: true },
        ],
      },
      {
        size: 9,
        fields: [
          { name: 'nextDrawDate', component: Datetime, normalize: formatUtc, size: 6 },
        ],
      },
      {
        fields: [
          {
            name: 'isActive',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isOverSingleYear',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'hasRankings',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isMultiGroupChampionship',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'hasMatches',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`tournament_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
