import {
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { getProject } from '../../../common/services/project';
import { getLinkId } from '../../../common/services/tools';
import TeamInput from '../../teams/Input';
import TournamentCell from '../../tournaments/Cell';
import TournamentInput from '../../tournaments/Input';
import { IComponentProps } from './AddBroadcasts.connector';
import MatchChannelRow from './MatchChannelRow';

interface IState {
  activeStep: number;
  tournamentAId: string;
  teamAId: string;
  channelUpdates: Map<any, any>;
}

export class AddBroadcasts extends React.Component<IComponentProps, IState> {
  public state: IState = {
    activeStep: 0,
    tournamentAId: '',
    teamAId: '',
    channelUpdates: new Map(),
  };

  public componentDidMount() {
    this.props.fetchAllChannels();
  }

  public render() {
    const {
      classes,
      matches = [],
      fetching,
      intl: { messages: m },
      channels,
    } = this.props;
    const { activeStep, tournamentAId, teamAId, channelUpdates } = this.state;

    return (
      <Layout
        id="broadcasts-add-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_broadcasts',
                href: `#${getProject().url}/broadcasts`,
              },
              { labelKey: 'app_broadcastAdd' },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_broadcastAdd}</Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="broadcasts" />
            </Toolbar>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step key="target">
                <StepLabel>{this.getStepOneLabel()}</StepLabel>
                <StepContent>
                  <Typography>{m.broadcast_step1Description}</Typography>
                  <div className={classes.input}>
                    <TournamentInput
                      input={{
                        name: 'tournament',
                        onChange: this.handleTournamentChange,
                      }}
                    />
                  </div>
                  <div className={classes.input}>
                    <TeamInput
                      input={{
                        name: 'team',
                        onChange: this.handleTeamChange,
                      }}
                    />
                  </div>
                  <div className={classes.actionsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                      disabled={!tournamentAId && !teamAId}
                    >
                      {m.button_next}
                    </Button>
                  </div>
                </StepContent>
              </Step>

              <Step key="matches">
                <StepLabel>{m.broadcast_step2Title}</StepLabel>
                <StepContent>
                  <Typography>{m.broadcast_step2Description}</Typography>
                  <div className={classes.tableContainer}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="none">{m.field_date}</TableCell>
                          <TableCell>{m.app_match}</TableCell>
                          <TableCell padding="none">{m.app_broadcast}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!fetching && channels.length > 0
                          ? matches.map(matchItem => (
                              <MatchChannelRow
                                key={matchItem.id}
                                onChange={this.handleChannelChange}
                                match={matchItem}
                                channels={channels}
                              />
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </div>
                  <div className={classes.actionsContainer}>
                    <Button onClick={this.handleRestart} className={classes.button}>
                      {m.button_back}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleConfirm}
                      className={classes.button}
                      disabled={!channelUpdates.size}
                    >
                      {m.button_validate}
                    </Button>
                  </div>
                </StepContent>
              </Step>
            </Stepper>
            {activeStep === 2 && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Button variant="contained" color="primary" onClick={this.handleRestart} className={classes.button}>
                  {m.button_restart}
                </Button>
              </Paper>
            )}
          </Paper>
        }
      />
    );
  }

  private handleNext = () => {
    const { fetchNextMatches } = this.props;
    const { tournamentAId, teamAId } = this.state;

    let query = '';
    if (tournamentAId) {
      query += `&phase.tournament=${getLinkId(tournamentAId)}`;
    }
    if (teamAId) {
      query += `&team=${getLinkId(teamAId)}`;
    }

    fetchNextMatches(query, 100);
    this.setState({ activeStep: 1 });
  };

  private handleConfirm = () => {
    const { updateBroadcast, addBroadcast, deleteBroadcast } = this.props;
    const { channelUpdates } = this.state;

    for (const [key, { channel, secondaryChannel, date, broadcastId }] of channelUpdates) {
      const broadcast = {
        match: key,
        channel,
        secondaryChannel,
        date,
        live: true,
      };

      if (broadcastId) {
        if (channel) {
          updateBroadcast(broadcastId, broadcast);
        } else {
          deleteBroadcast(broadcastId);
        }
      } else {
        addBroadcast(broadcast);
      }
    }
    this.setState({ activeStep: 2 });
  };

  private handleRestart = () => {
    this.setState({
      activeStep: 0,
      tournamentAId: '',
      teamAId: '',
      channelUpdates: new Map(),
    });
  };

  private handleTournamentChange = (tournamentAId: string) => {
    this.setState({ tournamentAId });
  };

  private handleTeamChange = (teamAId: string) => {
    this.setState({ teamAId });
  };

  private handleChannelChange = (matchId, channels) => {
    const { channelUpdates } = this.state;
    channelUpdates.set(matchId, channels);
    this.setState({ channelUpdates });
  };

  private getStepOneLabel = () => {
    const {
      intl: { messages: m },
    } = this.props;
    const { tournamentAId, activeStep } = this.state;
    if (tournamentAId && activeStep > 0) {
      return <TournamentCell id={getLinkId(tournamentAId)} />;
    }
    return m.broadcast_step1Title;
  };
}
