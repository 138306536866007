import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';

export function fetchTeams(search) {
  const params = qs.parse(search);

  return function(dispatch) {
    dispatch({ type: 'FETCH_TEAMS' });

    return rest
      .get('teams', { params })
      .then(
        res => dispatch({ type: 'FETCH_TEAMS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TEAMS_REJECTED', payload: err.response })
      );
  };
}

export function fetchTeam(slug) {
  const params = {
    slug,
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TEAM' });

    return rest.get('teams', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_TEAM_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_TEAM_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_TEAM_REJECTED', payload: err.response })
    );
  };
}

export function fetchTeamId(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_TEAM' });

    return rest
      .get(`teams/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_TEAM_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TEAM_REJECTED', payload: err.response })
      );
  };
}

export function updateTeam(id, team) {
  const params = {
    groups: ['read', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_TEAM', payload: id });

    return rest.put(`teams/${id}`, team, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_teamUpdated', type: 'success' },
        });
        return dispatch({ type: 'UPDATE_TEAM_FULFILLED', payload: res.data });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_TEAM_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function deleteTeam(team) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_TEAM' });

    return rest
      .delete(`teams/${team.id}`)
      .then(
        () => dispatch({ type: 'DELETE_TEAM_FULFILLED', payload: team }),
        err => dispatch({ type: 'DELETE_TEAM_REJECTED', payload: err.response })
      );
  };
}

export function fetchClubTeams(club) {
  const params = {
    club,
    'order[translations.priority]': 'asc',
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUB_TEAMS', payload: club });

    return rest
      .get('teams', { params })
      .then(
        res => dispatch({ type: 'FETCH_CLUB_TEAMS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_CLUB_TEAMS_REJECTED', payload: err.response })
      );
  };
}

export function fetchTeamLink(id) {
  const params = {
    groups: ['read_link'],
  };
  return function(dispatch, getState) {
    const link = getState().team.link[id];
    if (link) return Promise.resolve({ payload: link });

    dispatch({ type: 'FETCH_TEAM_LINK', payload: { id } });
    return rest
      .get(`teams/${id}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_TEAM_LINK_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_TEAM_LINK_REJECTED', payload: err.response })
      );
  };
}

export function searchTeams(search) {
  const params = {
    targets: ['Team'],
    limit: 30,
    groups: ['read_link', '_country'],
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_TEAMS', payload: { search } });
    return rest
      .get(`search/${search}`, { params })
      .then(
        res => dispatch({ type: 'SEARCH_TEAMS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_TEAMS_REJECTED', payload: err.response })
      );
  };
}
