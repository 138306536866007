import { createDetailReducer } from '../../../common/services/reducerCreator';

export default createDetailReducer({
  modelName: 'ARTICLE',
  inside: {
    tag: { get: 'articles' },
    author: { get: 'articles' },
  },
  collections: {
    briefsIds: { get: 'briefs', set: 'brief' },
  },
});
