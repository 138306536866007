import React from 'react';
import { Switch, Route } from 'react-router-dom';
import View from './View';

export default function PlayersStatsRoutes({ match }) {
  return (
    <Switch>
      <Route exact path={match.url} component={View} />
    </Switch>
  );
}
