import { ICommentaryType, IChronoUnit } from '../commentaries/typings';
import { ILiveCommentary, IPlayer } from '../live/Commentary/typings';
import { getMatchTeamPosition } from '../live/Commentary/utils';
import { IMatch } from '../matches/typings';
import { getMatchMatchPeriod } from '../matches/utils';
import { IMatchEvent } from './typings';
import {
  getDefaultPeriodTime,
  EPeriod,
} from '../live/Commentary/components/PeriodSelector/utils';

const goalTypes = ['goal', 'penalty_goal', 'own_goal'];
const cardCommentaryTypes: ICommentaryType[] = [
  'red_card',
  'yellow_card',
  'second_yellow_card',
];
const penaltyShotCommentaryTypes = [
  'penalty_shot_scored',
  'penalty_shot_saved',
  'penalty_shot_missed',
];

const missedPenaltyCommentaryTypes = ['missed_penalty', 'missed_penalty_saved'];
const substituteTypes = 'substitute';

export const getCommentaryEventName = (
  commentaryTypetype: ICommentaryType
): IMatchEvent['name'] | null => {
  if (goalTypes.indexOf(commentaryTypetype) !== -1) return 'goal';
  if (cardCommentaryTypes.indexOf(commentaryTypetype) !== -1) return 'card';
  if (penaltyShotCommentaryTypes.indexOf(commentaryTypetype) !== -1) {
    return 'penalty_shot';
  }
  if (missedPenaltyCommentaryTypes.indexOf(commentaryTypetype) !== -1) {
    return 'missed_penalty';
  }
  if (substituteTypes === commentaryTypetype) return 'substitute';
  return null;
};

export const getEventType = (
  commentaryTypetype: ICommentaryType
): IMatchEvent['type'] | null => {
  if (goalTypes.indexOf(commentaryTypetype) !== -1) {
    return commentaryTypetype as 'goal' | 'penalty_goal' | 'own_goal';
  }
  if (cardCommentaryTypes.indexOf(commentaryTypetype) !== -1) {
    return commentaryTypetype.split('_card')[0] as 'red' | 'yellow' | 'second_yellow';
  }
  if (missedPenaltyCommentaryTypes.indexOf(commentaryTypetype) !== -1) {
    return commentaryTypetype === 'missed_penalty' ? 'missed' : 'saved';
  }
  return null;
};

export const getMatchEventBaseFromCommentary = (
  commentary: ILiveCommentary,
  match: IMatch
): Partial<IMatchEvent> => {
  const matchPeriod = getMatchMatchPeriod(match, commentary.period);

  return {
    match: match['@id'],
    matchTeamPlayer: (commentary.player as IPlayer)['@id'],
    matchTeamPosition: getMatchTeamPosition(
      commentary.teamActionSide,
      commentary.type as ICommentaryType
    ),
    matchPeriod: matchPeriod ? matchPeriod['@id'] : null,
    timeMin: getMatchEventTime(commentary),
    additionnalTimeMin: commentary.additionnalTimeMin,
    type: getEventType(commentary.type),
  };
};

export const getMatchEventUpdatedFromCommentary = (
  commentary: ILiveCommentary,
  match: IMatch
): Partial<IMatchEvent> => {
  const matchPeriod = getMatchMatchPeriod(match, commentary.period);

  return {
    matchTeamPlayer: (commentary.player as IPlayer)['@id'],
    matchPeriod: matchPeriod ? matchPeriod['@id'] : null,
    timeMin: getMatchEventTime(commentary),
    additionnalTimeMin: commentary.additionnalTimeMin,
    type: getEventType(commentary.type),
  };
};

const getMatchEventTime = (commentary: ILiveCommentary): IChronoUnit => {
  if (commentary.period === EPeriod.PenaltyShootout) {
    return null;
  }
  if (commentary.timeMin === null) {
    return getDefaultPeriodTime(commentary.period);
  }
  return commentary.timeMin;
};
