import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';

export default function InjuryTypesRoutes({ match }) {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={List} />
    </Switch>
  );
}
