import rest from 'Services/restFootMercato';

export function fetchArticleRevision(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_REVISION', payload: { id } });

    return rest.get(`revisions/article/${id}`).then(
      res =>
        dispatch({
          type: 'FETCH_REVISION_FULFILLED',
          payload: { id, list: res.data },
        }),
      err =>
        dispatch({ type: 'FETCH_REVISION_REJECTED', payload: err.response })
    );
  };
}

export function rollbackRevision(id) {
  return function(dispatch) {
    dispatch({ type: 'ROLLBACK_REVISION' });

    return rest.post(`revisions/rollback/${id}`).then(
      res =>
        dispatch({ type: 'ROLLBACK_REVISION_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'ROLLBACK_REVISION_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchDiffRevisions(idNewer, idOlder) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_DIFF' });

    return rest
      .get(`revisions/diff/${idNewer}/${idOlder}`)
      .then(
        res => dispatch({ type: 'FETCH_DIFF_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_DIFF_REJECTED', payload: err.response })
      );
  };
}
