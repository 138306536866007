import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { IPlayer } from '../../typings';
import { PlayersColumn as PlayersColumnComponent } from './PlayersColumn.component';

interface IOwnProps {
  players: IPlayer[];
  onRemove: (id: string) => void;
  onSelect: (id: string) => void;
}
export type IComponentProps = InjectedIntlProps & IOwnProps;

const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const PlayersColumn = connector(PlayersColumnComponent);
