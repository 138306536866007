import qs from 'query-string';
import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';

export function fetchPresidents(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    role: 'president',
    partial: 0,
    'order[translations.priority]': 'asc',
    groups: ['read', '_image', '_club'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_PRESIDENTS' });

    return rest
      .get('people/with_club/', { params })
      .then(
        res => dispatch({ type: 'FETCH_PRESIDENTS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PRESIDENTS_REJECTED', payload: err.response })
      );
  };
}

export function fetchPresident(slug) {
  const params = {
    slug,
    role: 'president',
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PRESIDENT' });

    return rest.get('people', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_PRESIDENT_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_PRESIDENT_NOTFOUND' });
      },
      err => dispatch({ type: 'FETCH_PRESIDENT_REJECTED', payload: err.response })
    );
  };
}

export function fetchPresidentById(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_PRESIDENT' });

    return rest
      .get(`people/${id}}`)
      .then(
        res => dispatch({ type: 'FETCH_PRESIDENT_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PRESIDENT_REJECTED', payload: err.response })
      );
  };
}

export function updatePresident(id, president) {
  const params = {
    groups: ['read', '_image', '_meta'],
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_PRESIDENT', payload: id });
    return rest.put(`people/${id}`, president, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_presidentUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_PRESIDENT_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PRESIDENT_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addPresident(president) {
  return function(dispatch) {
    dispatch({ type: 'ADD_PRESIDENT' });

    return rest
      .post('people', { ...president, role: 'president' })
      .then(
        res => dispatch({ type: 'ADD_PRESIDENT_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'ADD_PRESIDENT_REJECTED', payload: err.response })
      );
  };
}

export function deletePresident(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_PRESIDENT', payload: id });

    return rest
      .delete(`people/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_PRESIDENT_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_PRESIDENT_REJECTED', payload: err.response })
      );
  };
}

export function searchPresidents(search) {
  const params = {
    role: 'president',
    'translations.lastName': search,
  };
  return function(dispatch) {
    dispatch({ type: 'SEARCH_PRESIDENTS', payload: { search } });
    return rest.get('search/people/', { params }).then(
      res => dispatch({ type: 'SEARCH_PRESIDENTS_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'SEARCH_PRESIDENTS_REJECTED',
          payload: err.response,
        })
    );
  };
}
