import rest from 'Services/restFootData';

export function fetchInjuryTypes() {
  const params = {
    pagination: 0,
  };

  return function(dispatch, getState) {
    const storedInjuryTypes = getState().injuryTypesField;
    if (storedInjuryTypes.list && storedInjuryTypes.list.length) {
      return Promise.resolve({
        type: 'CACHED_INJURY_TYPES_FIELD',
        payload: storedInjuryTypes,
      });
    }

    dispatch({ type: 'FETCH_INJURY_TYPES_FIELD' });
    return rest
      .get('injury_types', { params })
      .then(
        res => dispatch({ type: 'FETCH_INJURY_TYPES_FIELD_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_INJURY_TYPES_FIELD_REJECTED', payload: err.response })
      );
  };
}

export function fetchInjuryType(id) {
  return function(dispatch, getState) {
    return dispatch(fetchInjuryTypes()).then(() => {
      const storedInjuryTypes = getState().injuryTypesField;
      const res = storedInjuryTypes.list.find(type => type.id == id);
      return res
        ? dispatch({ type: 'FETCH_INJURY_TYPE_FIELD_FULFILLED', payload: res })
        : dispatch({
            type: 'FETCH_INJURY_TYPES_FIELD_REJECTED',
            payload: { message: 'Injury type not found' },
          });
    });
  };
}
