import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../components/Layout';
import { SearchFilter } from '../../components/SearchFilter';
import { SimpleBreadcrumbs } from '../../components/SimpleBreadcrumbs';
import { TableList } from '../../components/TableList';
import { UserRestriction } from '../../components/UserRestiction';
import { stringifyQuery } from '../../services/parseQuery';
import { getProject } from '../../services/project';
import queryReducer from '../../services/queriesReducer';
import debounce from '../../services/tools';
import { UserDialog } from '../components/UserDialog';
import { UserRow } from '../components/UserRow.component';
import { IUser } from '../typings';
import { IComponentProps } from './UsersList.connector';

export class UsersList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'username',
        labelKey: 'filter_username',
      },
      {
        type: 'email',
        labelKey: 'filter_email',
      },
    ],
    headers: [
      { labelKey: 'field_email' },
      { labelKey: 'field_username' },
      { labelKey: 'field_firstName' },
      { labelKey: 'field_lastName' },
      { labelKey: 'field_isActive' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/users${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      users,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="users-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_users' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_usersList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <UserDialog title={m.dialog_addUser} form="user_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </UserDialog>
              </UserRestriction>
            </Toolbar>
            <SearchFilter
              form="users_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={users}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={UserRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchUsers, filterList } = this.props;
    fetchUsers(filter);
    filterList('users', filter);
  };

  private handleFilters = (change: any) => {
    const { username, email } = change;
    const filtersQuery = {
      username,
      email,
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  private submitAdd = (user: Partial<IUser>) => {
    const { history, me, addUser } = this.props;
    user.roles = ['ROLE_USER'];
    user.language = me.language;
    addUser(user, history);
  };
}
