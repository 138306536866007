import React, { Component } from 'react';

import TextInput from 'Components/inputs/Text';
import { FormEdit } from 'Components/FormEdit';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'name',
    ],
    disposition: [
      {
        size: 9,
        title: '',
        fields: [
          {
            name:'name',
            component: TextInput,
            size: 12,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`injury_type_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        inline
      />
    );
  }
}
