import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import TabsRoute from 'Components/TabsRoute';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { formatUtc } from '../../../common/components/inputs/Datetime';
import TopBar from '../../components/TopBar';
import { postPush } from '../actions';
import Form from './Form';

@injectIntl
@connect()
export default class PushDetail extends Component {
  initialValues = {
    text: '',
    title: '',
    targetUrl: '',
    system: 'all',
    segment: 'global_news',
    excludedFootdataCompetitionIds: [],
    excludedFootdataTeamIds: [],
    excludedFootdataMatchIds: [],
    excludedFootdataPersonIds: [],
    footdataCompetitionIds: [],
    footdataTeamIds: [],
    footdataMatchIds: [],
    footdataPersonIds: [],
  };

  menu = [{ label: 'app_push', link: '/' }];

  submitPush = form => {
    const { dispatch } = this.props;
    const { segment, system, scheduledAt, targetUrl, ...otherFields } = form;

    const push = {
      targetSegmentId: getRealSegment(),
      targetUrl,
      osSegmentId: system === 'all' ? undefined : system,
      scheduledAt: formatUtc(scheduledAt),
      idempotencyKey: `fwg_${Date.now()}`,
      ...otherFields,
    };

    function getRealSegment() {
      if (segment === 'global_news') {
        return targetUrl.startsWith('match') ? 'global_matches' : 'global_news';
      }

      return segment
    }

    return dispatch(postPush(push)).then(() => ({
      payload: this.initialValues,
    }));
  };

  render() {
    const {
      match,
      history,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="push-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_push' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_push}</Typography>
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route
              exact
              path={match.url}
              render={() => <Form onSubmit={this.submitPush} data={this.initialValues} />}
            />
          </Paper>
        }
      />
    );
  }
}
