import { ChangeSpec, Command, EditorSelection, EditorView } from '@uiw/react-codemirror';
import { getMediaCommandInfo } from '../../../../../../../footdata/images/Command';
import { getEmbedCommandInfo } from '../../../../../../../footmercato/articles/embed/Command';
import { getProject } from '../../../../../../services/project';

// copied/inspired from 'codemirror-markdown-commands';

const button: Command = view => {
  const { state } = view;
  const { doc } = state;

  view.dispatch(
    state.changeByRange(range => {
      const { from, to } = range;
      const text = doc.sliceString(from, to);
      const link = `[${text}](){.btn}`;
      const cursor = from + (text.length ? 3 + text.length : 1);
      return {
        changes: [{ from, to, insert: link }],
        range: EditorSelection.range(cursor, cursor),
      };
    })
  );

  view.focus();
  return true;
};
const linkBlue: Command = view => {
    const { state } = view;
    const { doc } = state;

    view.dispatch(
        state.changeByRange(range => {
        const { from, to } = range;
        const text = doc.sliceString(from, to);
        const link = `[${text}](){.textBlue}`;
        const cursor = from + (text.length ? 3 + text.length : 1);
        return {
            changes: [{ from, to, insert: link }],
            range: EditorSelection.range(cursor, cursor),
        };
        })
    );

    view.focus();
    return true;
}

const simpleQuote: Command = view => {
  const { lang } = getProject();
  let prefix = "'";
  let suffix = "'";
  if (lang === 'de') {
    prefix = '‚';
    suffix = '‘';
  }

  const currentRange = view.state.selection.main;

  if (currentRange.from >= 1) {
    const charStart = view.state.sliceDoc(currentRange.from - 1, currentRange.from);
    const chatEnd = view.state.sliceDoc(currentRange.to, currentRange.to + 1);

    if (charStart === prefix && chatEnd === suffix) {
      view.focus();
      return false;
    }
  }

  view.dispatch(
    view.state.changeByRange(range => {
      return {
        changes: [{ from: range.from, insert: prefix }, { from: range.to, insert: suffix }],
        range: EditorSelection.range(range.from + 1, range.to + 1),
      };
    })
  );

  view.focus();

  return true;
};

const doubleQuote: Command = view => {
  const { lang } = getProject();
  let prefix = '"';
  let suffix = '"';
  if (lang === 'fr' || lang === 'es') {
    prefix = '«';
    suffix = '»';
  } else if (lang === 'de') {
    prefix = '„';
    suffix = '“';
  }

  const currentRange = view.state.selection.main;

  if (currentRange.from >= 1) {
    const charStart = view.state.sliceDoc(currentRange.from - 1, currentRange.from);
    const chatEnd = view.state.sliceDoc(currentRange.to, currentRange.to + 1);

    if (charStart === prefix && chatEnd === suffix) {
      view.focus();
      return false;
    }
  }

  view.dispatch(
    view.state.changeByRange(range => {
      return {
        changes: [{ from: range.from, insert: prefix }, { from: range.to, insert: suffix }],
        range: EditorSelection.range(range.from + 1, range.to + 1),
      };
    })
  );

  view.focus();

  return true;
};

const media: Command = view => {
  getMediaCommandInfo().then(mediaCommandInfo => {
    if (!mediaCommandInfo) {
      return;
    }
    const { start, end } = mediaCommandInfo;
    const defaultAltAttribute = 'defaultAltAttribute';

    view.dispatch(
      view.state.changeByRange(range => {
        const changes = [{ from: range.from, insert: start }];
        const empty = range.from === range.to;
        let endRange = range.to + start.length;
        if (empty) {
          changes.push({ from: range.from, insert: defaultAltAttribute });
          endRange += defaultAltAttribute.length;
        }
        changes.push({ from: range.to, insert: end });
        return {
          changes,
          range: EditorSelection.range(range.from + start.length, endRange),
        };
      })
    );

    view.focus();
  });

  return true;
};

const embed: Command = view => {
  getEmbedCommandInfo().then(embedInfo => {
    if (!embedInfo) {
      return;
    }
    const { start } = embedInfo;
    view.dispatch(
      view.state.changeByRange(range => {
        return {
          changes: { from: range.from, insert: start },
          range: EditorSelection.range(range.from + start.length, range.to + start.length),
        };
      })
    );

    view.focus();
  });

  return true;
};

const quote: Command = view => {
  const { state } = view;

  const { doc } = state;

  view.dispatch(
    view.state.changeByRange(range => {
      const startLine = doc.lineAt(range.from);

      const text = doc.slice(range.from, range.to);

      const lineCount = text.lines;

      const changes: ChangeSpec[] = [];

      let selectionStart: number = range.from;
      let selectionLength: number = range.to - range.from;

      new Array(lineCount).fill(0).forEach((_, index) => {
        const line = doc.line(startLine.number + index);

        if (line.text.startsWith('> ')) {
          return;
        }
        changes.push({
          from: line.from,
          insert: '> ',
        });

        if (index === 0) {
          selectionStart = selectionStart + 2;
        } else {
          selectionLength += 2;
        }
      });

      return {
        changes,
        range: EditorSelection.range(selectionStart, selectionStart + selectionLength),
      };
    })
  );

  view.focus();

  return true;
};
const createHeading = (level: 1 | 2 | 3 | 4 | 5 | 6): Command => {
  return view => {
    const state = view.state;

    const flags = '#'.repeat(level) + ' ';

    view.dispatch(
      state.changeByRange(range => {
        const line = state.doc.lineAt(range.from);

        const content = line.text.replace(/^((#+) )?/, flags);

        const diffLength = content.length - line.length;

        return {
          changes: {
            from: line.from,
            to: line.to,
            insert: content,
          },
          range: EditorSelection.range(range.anchor + diffLength, range.head + diffLength),
        };
      })
    );

    view.focus();

    return true;
  };
};
const bold: Command = view => {
  const currentRange = view.state.selection.main;

  if (currentRange.from >= 2) {
    const charStart = view.state.sliceDoc(currentRange.from - 2, currentRange.from);
    const chatEnd = view.state.sliceDoc(currentRange.to, currentRange.to + 2);

    if (charStart === '**' && chatEnd === '**') {
      view.focus();
      return false;
    }
  }

  view.dispatch(
    view.state.changeByRange(range => {
      return {
        changes: [{ from: range.from, insert: '**' }, { from: range.to, insert: '**' }],
        range: EditorSelection.range(range.from + 2, range.to + 2),
      };
    })
  );

  view.focus();

  return true;
};
const italic: Command = view => {
  const currentRange = view.state.selection.main;

  if (currentRange.from >= 1) {
    const charStart = view.state.sliceDoc(currentRange.from - 1, currentRange.from);
    const chatEnd = view.state.sliceDoc(currentRange.to, currentRange.to + 1);

    if (charStart === '*' && chatEnd === '*') {
      if (
        currentRange.from < 2 ||
        view.state.sliceDoc(currentRange.from - 1, currentRange.from) !== '*' ||
        view.state.sliceDoc(currentRange.to, currentRange.to + 1) !== '*' ||
        (currentRange.from >= 3 &&
          view.state.sliceDoc(currentRange.from - 3, currentRange.from) === '***' &&
          view.state.sliceDoc(currentRange.to, currentRange.to + 3) === '***')
      ) {
        view.focus();
        return false;
      }
    }
  }

  view.dispatch(
    view.state.changeByRange(range => {
      return {
        changes: [{ from: range.from, insert: '*' }, { from: range.to, insert: '*' }],
        range: EditorSelection.range(range.from + 1, range.to + 1),
      };
    })
  );

  view.focus();

  return true;
};
export const link: Command = view => {
  const { state } = view;

  const { doc } = state;

  view.dispatch(
    state.changeByRange(range => {
      const { from, to } = range;

      const text = doc.sliceString(from, to);

      const link = `[${text}]()`;

      const cursor = from + (text.length ? 3 + text.length : 1);

      return {
        changes: [
          {
            from,
            to,
            insert: link,
          },
        ],
        range: EditorSelection.range(cursor, cursor),
      };
    })
  );

  view.focus();
  return true;
};
type ListType = 'ul' | 'ol' | 'todo';

const getListTypeOfLine = (text: string): [ListType, number?] | undefined => {
  text = text && text.trimStart();

  if (!text) {
    return undefined;
  }

  if (text.startsWith('- ')) {
    if (text.startsWith('- [ ] ') || text.startsWith('- [x] ')) {
      return ['todo'];
    }

    return ['ul'];
  }

  const v = text.match(/^(\d+)\. /);

  if (v) {
    return ['ol', Number.parseInt(v[1], 10)];
  }

  return undefined;
};

export const createList = (type: ListType): Command => {
  return view => {
    const { state } = view;

    const { doc } = state;

    let olIndex = 1;

    view.dispatch(
      view.state.changeByRange(range => {
        const startLine = doc.lineAt(range.from);

        const text = doc.slice(range.from, range.to);

        const lineCount = text.lines;

        const changes: ChangeSpec[] = [];

        let selectionStart: number = range.from;
        let selectionLength: number = range.to - range.from;

        new Array(lineCount).fill(0).forEach((_, index) => {
          const line = doc.line(startLine.number + index);

          const currentListType = getListTypeOfLine(line.text);

          if (currentListType && currentListType[0] === type) {
            if (currentListType[0] === 'ol' && currentListType[1]) {
              olIndex = currentListType[1];
            }

            return;
          }

          const content = line.text.replace(/^((?<space> *)(-( \[[x ]])?|\d+\.) )?/, (...args) => {
            const params = args[args.length - 1];

            const { space = '' } = params;

            let newFlag = '- ';

            if (type === 'ol') {
              newFlag = `${olIndex}. `;
              olIndex++;
            } else if (type === 'todo') {
              newFlag = '- [ ] ';
            }

            return space + newFlag;
          });

          const diffLength = content.length - line.length;

          changes.push({
            from: line.from,
            to: line.to,
            insert: content,
          });

          if (index === 0) {
            selectionStart = selectionStart + diffLength;
          } else {
            selectionLength = selectionLength + diffLength;
          }
        });

        return {
          changes,
          range: EditorSelection.range(selectionStart, selectionStart + selectionLength),
        };
      })
    );

    view.focus();

    return true;
  };
};

export const CustomCommand = {
  button,
  simpleQuote,
  doubleQuote,
  media,
  embed,
  quote,
  h3: createHeading(3),
  bold,
  italic,
  link,
  ul: createList('ul'),
  ol: createList('ol'),
  textBlue: linkBlue,
};

export const replaceWord = (view: EditorView, from: number, to: number, word: string) => {
  let change = { from, to, insert: word };
  view.dispatch({ changes: change });
};
