import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FileUploadIcon from '@material-ui/icons/Publish';
import InfoIcon from '@material-ui/icons/Info';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getLinkId } from 'Services/tools';
import { fetchImage } from '../actions';
import ImageDialog from '../Dialog';
import { getOriginalSrc } from '../tools';
import MediaLibrary from './MediaLibrary';
import Upload from './Upload';
import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import TextInput from '../../../common/components/inputs/Text';
import ImageIcon from '@material-ui/icons/Image';

/* Move to transitions components ? */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  labelContainer: {
    height: 16,
    padding: 16,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    position: 'absolute',
    width: 'calc(100% - 32px)',
  },
  label: {
    color: 'white',
    fontSize: 16,
  },
  container: {
    position: 'relative',
    minWidth: 150,
    minHeight: 150,
    height: '100%',
    textAlign: 'center',
  },
  image: {
    maxWidth: '100%',
  },
  actions: {
    display: 'flex',
    position: 'absolute',
    bottom: 12,
    right: 0,
  },
  buttons: {
    marginRight: theme.spacing(1),
  },
  buttonSelect: {
    lineHeight: 1.2,
    marginLeft: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  image: props.input && store.image.detail[getLinkId(props.input.value)],
}))
@withMobileDialog()
export default class ImageInput extends Component {
  state = {
    open: false,
    value: 0,
    imagesDimensions: {},
  };

  componentDidMount() {
    const { input, dispatch, returnInputUrl } = this.props;
    if (!returnInputUrl && input && input.value) {
      dispatch(fetchImage(getLinkId(input.value)));
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = answer => {
    const { returnInputUrl, input } = this.props;
    this.setState({ open: false });

    const image = answer && answer.image;
    if (!image) {
      return;
    }

    if (returnInputUrl) {
      return input.onChange(image.urls['1200x675']);
    }

    const { dispatch } = this.props;
    dispatch(fetchImage(image.id));
    return input.onChange(image['@id']);
  };

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  hasImage = () => {
    const { image, input } = this.props;
    if (!image || !input.value) return null;
    return image && image['@id'].indexOf(input.value) != -1;
  };

  renderImg = () => {
    const { image, classes } = this.props;
    if (this.hasImage()) {
      const src = getOriginalSrc(image);
      return <img className={classes.image} src={src} alt={image.name} onLoad={this.onImgLoad(image.id)} />;
    }
  };

  onImgLoad = id => e => {
    const { imagesDimensions } = this.state;

    if (!imagesDimensions[id]) {
      const img = e.currentTarget;
      this.setState({
        imagesDimensions: {
          ...imagesDimensions,
          [id]: { w: img.naturalWidth, h: img.naturalHeight },
        },
      });
    }
  };

  imagesDialog = () => {
    const {
      input,
      fullScreen,
      width,
      intl: { messages: m },
    } = this.props;

    const { value, open } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.handleClose()}
        TransitionComponent={Transition}
        maxWidth={false}
        fullScreen={fullScreen}
        fullWidth
      >
        <AppBar position="static" color="inherit">
          <Tabs value={value} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" fullWidth>
            <Tab label={m.media_upload} />
            <Tab label={m.media_library} />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <Upload
            input={input}
            imageType={input.name === 'footdataMainImageId' ? 'articleMainImage' : 'InputImage'}
            close={this.handleClose}
          />
        )}
        {value === 1 && <MediaLibrary input={input} close={this.handleClose} width={width} />}
      </Dialog>
    );
  };

  render() {
    const {
      classes,
      input,
      labelKey,
      intl: { messages: m },
      image,
      returnInputUrl,
    } = this.props;

    const { imagesDimensions } = this.state;

    const label = labelKey ? m[labelKey] : m[`field_${input.name}`];

    const dimensions = image && imagesDimensions[image.id] ? imagesDimensions[image.id] : null;

    if (returnInputUrl) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <TextInput {...this.props} fullWidth={false} />
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.buttonSelect}
            onClick={this.handleClickOpen}
          >
            {m.button_select_image} <ImageIcon className={classes.rightIcon} />
          </Button>

          {this.imagesDialog()}
        </div>
      );
    }

    return (
      <div>
        <div className={classes.container}>
          <div className={classes.labelContainer}>
            <span className={classes.label}>{label}</span>
          </div>
          {this.renderImg()}
          <div className={classes.actions}>
            <div style={{ flex: 1 }} />
            {this.hasImage() ? (
              [
                <Button size="small" variant="contained" className={classes.buttons} onClick={this.handleClickOpen}>
                  {m.button_change}
                  <SwapVertIcon className={classes.rightIcon} />
                </Button>,
                <ImageDialog imageId={image.id} dimensions={dimensions} title={m.dialog_infoMedia}>
                  <Button size="small" variant="contained" className={classes.buttons} onClick={this.handleOpenInfo}>
                    {m.button_info}
                    <InfoIcon className={classes.rightIcon} />
                  </Button>
                </ImageDialog>,
              ]
            ) : (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.buttons}
                onClick={this.handleClickOpen}
              >
                {m.button_add} <FileUploadIcon className={classes.rightIcon} />
              </Button>
            )}
          </div>
        </div>
        {this.imagesDialog()}
        {image && !image.description && (
          <Typography color="error" variant="caption">
            <WarningIcon style={{ verticalAlign: 'bottom' }} />
            {m.warning_image_description_empty}
          </Typography>
        )}
      </div>
    );
  }
}
