import { InjectedIntlProps, injectIntl } from 'react-intl';
import { TableCreated as TableCreatedComponent } from './TableCreated.component';
import { compose } from 'recompose';

type IOwnProps = {
  collection: any;
  onPaginate: (q: any) => void;
  defaultItemPerPage?: number;
};
export type IComponentProps = InjectedIntlProps & IOwnProps;

const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const TableCreated = connector(TableCreatedComponent);
