import { Input } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';

type IProps = {
  close: (response?) => void;
  dispatch: () => void;
} & WithStyles &
  InjectedIntlProps;

const styles = () => ({
  input: {},
  bouttonContainer: {
    textAlign: 'center' as 'center',
    padding: 16,
  },
});

// @ts-ignore
@withStyles(styles)
// @ts-ignore
@injectIntl
export class UrlMedia extends Component<IProps> {
  public state = {
    url: '',
  };

  public render() {
    const {
      classes,
      intl: { messages: m },
    } = this.props;

    const { url } = this.state;

    return (
      <React.Fragment>
        <DialogTitle key="dialog-title">{m.media_urlTitle}</DialogTitle>
        <DialogContent key="dialog-content">
          <DialogContentText>{m.media_urlDescription}</DialogContentText>
          <div className={classes.bouttonContainer}>
            <Input value={url} className={classes.input} onChange={this.onChange} placeholder='url' fullWidth/>
          </div>
        </DialogContent>
        <DialogActions key="dialog-actions">
          <Button onClick={this.onCancel} color="primary">
            {m.button_cancel}
          </Button>
          <Button onClick={this.onConfirm} disabled={!url} color="primary">
            {m.button_choose}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }

  private onConfirm = () => {
    const { url } = this.state;
    this.props.close({ url });
  };

  private onChange = event => {
    this.setState({url: event.target.value} );
  };

  private onCancel = () => {
    this.props.close();
  };
}
