export default function reducer(
  state = {
    club: null,
    clubs: null,
    competition: null,
    competitions: null,
    countries: null,
    country: null,
    player: null,
    players: null,
  },
  action
) {
  switch (action.type) {
    case 'RESET_BREADCRUMB': {
      return {
        ...state,
        club: null,
        clubs: null,
        competition: null,
        competitions: null,
        country: null,
        player: null,
        players: null,
      };
    }
    case 'FETCH_BREADCRUMB_COUNTRIES_FULFILLED': {
      return {
        ...state,
        countries: action.payload.collection,
      };
    }
    case 'FETCH_BREADCRUMB_COMPETITIONS_FULFILLED': {
      return {
        ...state,
        competitions: action.payload.collection,
      };
    }
    case 'FETCH_BREADCRUMB_CLUBS_FULFILLED': {
      return {
        ...state,
        clubs: action.payload.collection,
      };
    }
    case 'FETCH_BREADCRUMB_PLAYERS_FULFILLED': {
      return {
        ...state,
        players: action.payload.collection,
      };
    }

    case 'SELECT_BREADCRUMB_COUNTRY': {
      return {
        ...state,
        country: action.payload,
        competition: null,
        club: null,
        player: null,
      };
    }
    case 'SELECT_BREADCRUMB_COMPETITION': {
      return {
        ...state,
        competition: action.payload,
        club: null,
        player: null,
      };
    }
    case 'SELECT_BREADCRUMB_CLUB': {
      return {
        ...state,
        club: action.payload,
        player: null,
      };
    }
    case 'SELECT_BREADCRUMB_PLAYER': {
      return {
        ...state,
        player: action.payload,
      };
    }

    case 'FETCH_COMPETITION_FULFILLED': {
      return {
        ...state,
        country: action.payload.area,
        competition: action.payload,
        club: null,
        player: null,
      };
    }
    case 'FETCH_CLUB_FULFILLED': {
      const {
        country,
        competitionId,
        competitionName,
        competitionSlug,
      } = action.payload;
      let competition = null;
      if (competitionId && competitionName && competitionSlug) {
        competition = {
          '@id': competitionId,
          name: competitionName,
          slug: competitionSlug,
          area: {
            slug: country.slug,
          },
        };
      }

      return {
        ...state,
        country,
        competition,
        club: action.payload,
        player: null,
      };
    }
    case 'FETCH_BREADCRUMB_CLUBCOMPETITION_FULFILLED': {
      const { mainTournament } = action.payload;

      if (!mainTournament) {
        return state;
      }

      const {
        competitionAreaSlug,
        competitionId,
        competitionName,
        competitionSlug,
      } = mainTournament;
      let competition = null;
      if (competitionId && competitionName && competitionSlug) {
        competition = {
          '@id': competitionId,
          name: competitionName,
          slug: competitionSlug,
          area: {
            slug: competitionAreaSlug,
          },
        };
      }
      return {
        ...state,
        competition,
      };
    }

    case 'FETCH_PLAYER_FULFILLED': {
      return {
        ...state,
        country: null,
        competition: null,
        club: action.payload.club,
        player: action.payload,
      };
    }

    default:
      return state;
  }
}
