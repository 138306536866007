import { ISide } from '../../../../matches/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import {
  addSubstituteAction,
  placeMatchTeamPlayerAction,
  removeMatchTeamPlayerAction,
  removeSubstituteAction,
  selectMatchTeamPlayerAction,
  unselectMatchTeamPlayerAction,
} from '../../actions';
import { ICompositionState } from '../../typings';

export const addMatchTeamPlayerOnSubstitutes = (side: ISide) => {
  return (dispatch, getState) => {
    const liveCompositionState: ICompositionState = getState().liveComposition;
    const selectedMatchTeamPlayer = liveCompositionState.selectedMatchTeamPlayer[side];

    if (selectedMatchTeamPlayer) {
      // if was on Compo remove from compo
      if (selectedMatchTeamPlayer.position && selectedMatchTeamPlayer.positionSide) {
        dispatch(
          removeMatchTeamPlayerAction(
            side,
            selectedMatchTeamPlayer.position,
            selectedMatchTeamPlayer.positionSide
          )
        );
      }
      // can not come from substitutes
    }
    dispatch(addSubstituteAction(side));
    dispatch(unselectMatchTeamPlayerAction(side));
  };
};

export const removeMatchTeamPlayerFromSubtitutes = (side: ISide) => {
  return dispatch => {
    dispatch(removeSubstituteAction(side));
    dispatch(unselectMatchTeamPlayerAction(side));
  };
};

export const swapMatchTeamPlayerFromSubtitutes = (
  side: ISide,
  matchTeamPlayer: IMatchTeamPlayer
) => {
  return (dispatch, getState) => {
    const liveCompositionState: ICompositionState = getState().liveComposition;
    const selectedMatchTeamPlayer = liveCompositionState.selectedMatchTeamPlayer[side];
    if (!selectedMatchTeamPlayer) return;

    dispatch(addMatchTeamPlayerOnSubstitutes(side));
    dispatch(selectMatchTeamPlayerAction(matchTeamPlayer, side));
    if (selectedMatchTeamPlayer.position) {
      dispatch(
        removeMatchTeamPlayerAction(
          side,
          selectedMatchTeamPlayer.position,
          selectedMatchTeamPlayer.positionSide
        )
      );
      dispatch(
        placeMatchTeamPlayerAction(
          side,
          selectedMatchTeamPlayer.position,
          selectedMatchTeamPlayer.positionSide
        )
      );
    }
    return dispatch(removeMatchTeamPlayerFromSubtitutes(side));
  };
};
