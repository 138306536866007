import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { Component } from 'react';
import { getProject } from 'Services/project';

const styles = theme => ({
  chip: {
    margin: theme.spacing(0.5),
  },
});

@withStyles(styles)
export default class Row extends Component {
  renderAuthor = publicName => {
    const { classes } = this.props;
    return (
      <Chip
        avatar={<Avatar>{publicName[0]}</Avatar>}
        label={publicName}
        className={classes.chip}
      />
    );
  };

  render() {
    const { tableData } = this.props;
    const { publicName, username, twitterAccount, role } = tableData;
    const href = `#${getProject().url}/authors/${tableData.id}`;

    return (
      <TableRow hover href={href} component="a">
        <TableCell>{this.renderAuthor(publicName)}</TableCell>
        <TableCell>{username}</TableCell>
        <TableCell>{role}</TableCell>
        <TableCell>{twitterAccount}</TableCell>
      </TableRow>
    );
  }
}
