import React from 'react';
import { injectIntl } from 'react-intl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

function SelectField({
  input: { name, value, onChange },
  meta: { touched, error } = {},
  intl: { formatMessage: f },
  labelKey,
  children,
  ...custom
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

  return (
    <FormControl fullWidth error={Boolean(touched && error)}>
      <InputLabel key="label" htmlFor={`select_${label}`}>{label}</InputLabel>
      <Select
        key="select"
        {...custom}
        name={name}
        value={value}
        onChange={event => onChange(event.target.value)}
        input={<Input id={`select_${label}`} />}
        MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
      >
        {children}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
export default injectIntl(SelectField);
