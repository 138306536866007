import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';

export function fetchTournamentRankingRules(tournament) {
  const params = {
    tournament,
    pagination: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_TOURNAMENT_RANKINGRULES', payload: tournament });

    return rest.get(`ranking_rules`, { params }).then(
      res =>
        dispatch({
          type: 'FETCH_TOURNAMENT_RANKINGRULES_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_TOURNAMENT_RANKINGRULES_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateRankingRule(id, rankingRule) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_RANKINGRULE', payload: id });

    return rest.put(`ranking_rules/${id}`, rankingRule).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            messageKey: 'message_rankingRuleUpdated',
            type: 'success',
          },
        });
        return dispatch({
          type: 'UPDATE_RANKINGRULE_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { description, errors } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: description, type: 'error' },
          });
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_RANKINGRULE_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}
