import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  container: {
    padding: 8,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  title: {
    cursor: 'pointer',
    userSelect: 'none' as 'none',
    position: 'relative' as 'relative',
  },
  expanded: {},
  expandIcon: {
    position: 'absolute' as 'absolute',
    top: '50%',
    right: theme.spacing(),
    transform: 'translateY(-50%) rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&$expanded': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
});
