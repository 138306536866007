import moment from 'moment';
import { getProject } from '../../common/services/project';
import { EPeriod } from '../live/Commentary/components/PeriodSelector/utils';
import { IMatch } from './typings';

export const getMatchLabel = (match: IMatch) => match.description;

export const getDefaultMatchPath = (): string =>
  `${
    getProject().url
  }/matches?order[date]=asc&status=fixture&date[after]=${moment().format('YYYY-MM-DD')}`;

export const getMatchMatchPeriod = (match: IMatch, period: EPeriod) => {
  const matchPeriods = match.matchPeriods;
  if (!matchPeriods) return null;
  switch (period) {
    case EPeriod.FirstHalf:
      return matchPeriods['1_first_half'];
    case EPeriod.SecondHalf:
      return matchPeriods['2_second_half'];
    case EPeriod.ExtraTimeFirstHalf:
      return matchPeriods['3_first_half_extra_time'];
    case EPeriod.ExtraTimeSecondHalf:
      return matchPeriods['4_second_half_extra_time'];
    case EPeriod.PenaltyShootout:
      return matchPeriods['5_penalties'];
    default:
      return null;
  }
};

export const shouldEnableLiveButton = ({
  live,
  isCommented
}: IMatch): boolean =>
  live === 'human' && isCommented;
