import { CircularProgress, Paper, Toolbar } from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import React from 'react';
import { Route } from 'react-router-dom';
import { IdBadge } from '../../../common/components/IdBadge';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { MercatoSurveyForm } from '../components/MercatoSurveyForm';
import { IMercatoSurvey } from '../typings';
import { IComponentProps } from './MercatoSurveyDetail.connector';
import Teams from "./Teams/Teams";

export class MercatoSurveyDetail extends React.Component<IComponentProps> {
  public state = {
    teams: [],
  };

  private menu = [{ label: 'app_info', link: '/' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      mercatoSurvey,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    const { teams } = this.state;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!mercatoSurvey) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="mercato-survey-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_mercatoSurveys', href: `#${getProject().url}/mercatosurveys?order[startDate]=DESC` },
              { label: mercatoSurvey.id },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <IdBadge id={mercatoSurvey.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="mercatosurveys" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Alert severity="warning">{m.field_mercatoSurveySummerTournamentWarning}</Alert>
            <Alert severity="warning">{m.field_mercatoSurveyWinterTournamentWarning}</Alert>
            <Alert severity="warning">{m.field_mercatoSurveyOnlyOneActive}</Alert>
            <Route exact path={match.url} render={this.renderForm} />
            <Teams teams={teams}/>
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { mercatoSurvey } = this.props;
    return <MercatoSurveyForm onSubmit={this.handleUpdate} data={mercatoSurvey} />;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchMercatoSurvey, fetchTeams } = this.props;
    // @ts-ignore
    fetchMercatoSurvey(targetId || id).then( ({payload}) => {
      if (payload && payload.tournament.id) {
        // @ts-ignore
        fetchTeams(payload.tournament.id).then( ({payload: teamsPayload}) => {
          this.setState({ teams: teamsPayload.collection })
        });
      }
    });
  };

  private handleUpdate = (mercatoSurvey: IMercatoSurvey) => {
    const { id, updateMercatoSurvey } = this.props;
    return updateMercatoSurvey(id, mercatoSurvey);
  };
}
