import { MarkdownUtil } from 'react-mde';

export const buttonCommand = {
  name: 'button',
  execute: (state0, api) => {
    // Adjust the selection to encompass the whole word if the caret is inside one
    const newSelectionRange = MarkdownUtil.selectWord({
      text: state0.text,
      selection: state0.selection,
    });
    const state1 = api.setSelectionRange(newSelectionRange);
    // Replaces the current selection with the current word
    const state2 = api.replaceSelection(`[${state1.selectedText}](url){.btn}`);
    // Adjust the selection to not contain the markdown syntax
    api.setSelectionRange({
      start: state2.selection.end - 12 - state1.selectedText.length,
      end: state2.selection.end - 12,
    });
  },
  keyCommand: 'button',
};
