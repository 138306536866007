import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PersonIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(1.8 5)">
        <g transform="translate(6 7)">
          <path
            d="M2.05605995,0.161704545 C1.94878968,0.158295455 1.84360909,0.19125 1.75839656,0.255340909 C0.703572236,1.04670455 0.0186213759,2.29556818 0.0186213759,3.69102273 L0.0186213759,7.27284091 C0.0186213759,7.52375 0.226428501,7.72738636 0.48299484,7.72738636 L8.80433514,7.72738636 C9.06090147,7.72738636 9.2687086,7.52375 9.2687086,7.27284091 L9.2687086,3.69102273 C9.2687086,2.29556818 8.58329337,1.04647727 7.52637936,0.254886364 C7.37569017,0.142386364 7.17066929,0.130340909 7.0076742,0.224659091 C6.31575774,0.625568182 5.50937322,0.854659091 4.64378108,0.854659091 C3.77818894,0.854659091 2.97157224,0.625568182 2.27663735,0.224431818 C2.20976757,0.185795455 2.13384251,0.164204545 2.05605995,0.161704545"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(7)">
          <path
            d="M3.48273133,0.129636364 C1.68328415,0.129636364 0.213542138,1.56554545 0.213542138,3.32736364 C0.213542138,5.08895455 1.68328415,6.52509091 3.48273133,6.52509091 C5.28194631,6.52509091 6.74936646,5.0885 6.74936646,3.32736364 C6.74936646,1.566 5.28194631,0.129636364 3.48273133,0.129636364"
            mask="url(#d)"
          />
        </g>
        <g transform="translate(13.699 6.461)">
          <path
            d="M1.03162887,0.189022727 C0.925287346,0.185613636 0.821035504,0.218113636 0.736287346,0.281295455 C0.590474079,0.389477273 0.457198894,0.506977273 0.3346043,0.629704545 C0.152337715,0.806522727 0.150944595,1.09425 0.331353686,1.27265909 C0.511994963,1.45106818 0.805943366,1.45243182 0.988209951,1.27561364 C0.991460565,1.27243182 0.994943366,1.26902273 0.997961794,1.26584091 C1.02396671,1.23993182 1.04997162,1.21493182 1.07644091,1.19015909 C1.66874926,1.47447727 2.33582174,1.63402273 3.03702568,1.63402273 C3.73962273,1.63402273 4.40576646,1.47425 4.99737826,1.19038636 C5.49495442,1.65515909 5.80469152,2.30675 5.80469152,3.02970455 L5.80469152,5.35697727 L2.10108096,5.35697727 C1.84474681,5.35265909 1.63345688,5.55288636 1.62904533,5.80402273 C1.62486597,6.05470455 1.82942248,6.26175 2.08575663,6.26606818 L2.10108096,6.26606818 L6.26906499,6.26606818 C6.52539914,6.26584091 6.73343845,6.06243182 6.73343845,5.81152273 L6.73343845,3.02970455 C6.73343845,1.91356818 6.1847812,0.91425 5.33869275,0.281977273 C5.18846794,0.169931818 4.98414361,0.157886364 4.82138071,0.251522727 C4.298264,0.552659091 3.69156007,0.725159091 3.03702568,0.725159091 C2.38481314,0.725159091 1.77509079,0.552431818 1.25220627,0.251522727 C1.18533649,0.212886364 1.10941143,0.191295455 1.03162887,0.189022727"
            mask="url(#f)"
          />
        </g>
        <path d="M16.7359732 1.15229545C15.2822521 1.15229545 14.0936882 2.31570455 14.0936882 3.73843182 14.0936882 5.15979545 15.2822521 6.32252273 16.7359732 6.32252273 18.1896943 6.32252273 19.3782582 5.15979545 19.3782582 3.73843182 19.3782582 2.31570455 18.1896943 1.15229545 16.7359732 1.15229545M1.68871732 6.65025C1.58167924 6.64684091 1.47673084 6.67979545 1.39175049 6.74365909.548216093 7.37593182.0000232186721 8.37479545.0000232186721 9.49093182L.0000232186721 12.27275C.0000232186721 12.5236591.207830344 12.7270682.464396683 12.7272955L4.62982666 12.7272955C4.88616081 12.7313864 5.09768292 12.5316136 5.10232666 12.2807045 5.10673821 12.0295682 4.90241388 11.8225227 4.64607973 11.8182045L4.62982666 11.8182045.928537961 11.8182045.928537961 9.49093182C.928537961 8.76843182 1.23827506 8.11684091 1.73422592 7.65184091 2.32630209 7.93593182 2.99314238 8.09525 3.69411413 8.09525 4.39671118 8.09525 5.06285491 7.93570455 5.65446671 7.65161364 5.680936 7.67615909 5.7071731 7.70115909 5.73294582 7.72706818 5.90940774 7.90911364 6.20335614 7.91638636 6.38933771 7.74365909 6.57531929 7.57070455 6.58298145 7.28297727 6.40628735 7.10093182 6.40303673 7.09752273 6.39978612 7.09434091 6.39630332 7.09093182 6.27370872 6.96820455 6.14066572 6.85070455 5.99485246 6.74252273 5.84485983 6.63115909 5.64076769 6.61934091 5.47846916 6.71275 4.95535246 7.01388636 4.34864853 7.18638636 3.69411413 7.18638636 3.0419016 7.18638636 2.43217924 7.01365909 1.9095269 6.71275 1.84242494 6.67411364 1.76649988 6.65252273 1.68871732 6.65025M3.69406769 1.15229545C2.24034656 1.15229545 1.05410455 2.31615909 1.05410455 3.73843182 1.05410455 5.15911364 2.24034656 6.32252273 3.69406769 6.32252273 5.14778882 6.32252273 6.33612052 5.15979545 6.33612052 3.73843182 6.33612052 2.31570455 5.14778882 1.15229545 3.69406769 1.15229545" />
      </g>
    </SvgIcon>
  );
}
