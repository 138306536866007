import React, { Component } from 'react';
import { FormEdit } from '../../../common/components/FormEdit';
import Text from '../../../common/components/inputs/Text';
import ImageInput from '../../images/Input';
import { IAgentCompany } from '../typings';

interface IProps {
  data: any;
  onSubmit: (agentCompany: IAgentCompany) => void;
}
export class AgentCompanyForm extends Component<IProps> {
  public config = {
    fields: ['id', 'name'],
    objectFields: ['logo'],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'logo', component: ImageInput }],
      },
      {
        size: 9,
        fields: [{ name: 'name', component: Text }],
      },
    ],
  };

  public render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`agentCompany_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
