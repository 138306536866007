import { InjectedIntlProps, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { TableFixed as TableFixedComponent } from './TableFixed.component';

interface IOwnProps {
  collection: any;
  onPaginate: (q: any) => void;
  defaultItemPerPage?: number;
}
export type IComponentProps = InjectedIntlProps & IOwnProps;

const connector = compose<IComponentProps, IOwnProps>(injectIntl);
export const TableFixed = connector(TableFixedComponent);
