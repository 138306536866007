export const PLAYER_CELL_SIZE = {
  WIDTH: 78,
  HEIGHT: 50,
};

export const styles = {
  container: {
    width: PLAYER_CELL_SIZE.WIDTH,
    height: PLAYER_CELL_SIZE.HEIGHT,
    padding: 2,
  },
  cell: {
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    padding: 2,
    position: 'relative' as 'relative',
    display: 'flex',
  },
  homeCell: { background: 'rgb(66,139,202)' },
  homeEmptyCell: { background: 'rgba(66,139,202,.5)' },
  awayCell: { background: 'rgb(217,83,79)' },
  awayEmptyCell: { background: 'rgba(217,83,79,.5)' },
  button: {
    flex: 1,
    minWidth: 0,
    height: '100%',
    padding: 2,
    fontSize: 13,
    lineHeight: 1,
    overflow: 'hidden',
  },
  backLabel: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    margin: 2,
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
  },
};
