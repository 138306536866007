import { EPosition, EPositionSide } from '../../../../compositions/typings';
import { ISide } from '../../../../matches/typings';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import {
  addSubstituteAction,
  placeMatchTeamPlayerAction,
  removeMatchTeamPlayerAction,
  removeSubstituteAction,
  selectMatchTeamPlayerAction,
  unselectMatchTeamPlayerAction,
} from '../../actions';
import { ICompositionState } from '../../typings';

export const addMatchTeamPlayerOnComposition = (
  side: ISide,
  position: EPosition,
  positionSide?: EPositionSide
) => {
  return (dispatch, getState) => {
    const liveCompositionState: ICompositionState = getState().liveComposition;
    const selectedMatchTeamPlayer = liveCompositionState.selectedMatchTeamPlayer[side];

    if (selectedMatchTeamPlayer) {
      // if was on Compo remove from compo
      if (selectedMatchTeamPlayer.position) {
        dispatch(
          removeMatchTeamPlayerAction(
            side,
            selectedMatchTeamPlayer.position,
            selectedMatchTeamPlayer.positionSide
          )
        );
      }
      // if was on Bench remove from bench
      if (selectedMatchTeamPlayer.onBench === true) {
        dispatch(removeSubstituteAction(side));
      }
    }
    dispatch(placeMatchTeamPlayerAction(side, position, positionSide));
    dispatch(unselectMatchTeamPlayerAction(side));
  };
};

export const removeMatchTeamPlayerFromComposition = (
  side: ISide,
  position: EPosition,
  positionSide?: EPositionSide
) => {
  return dispatch => {
    dispatch(removeMatchTeamPlayerAction(side, position, positionSide));
    dispatch(unselectMatchTeamPlayerAction(side));
  };
};

export const swapMatchTeamPlayerFromComposition = (
  side: ISide,
  position: EPosition,
  positionSide: EPositionSide | undefined,
  matchTeamPlayer: IMatchTeamPlayer
) => {
  return (dispatch, getState) => {
    const liveCompositionState: ICompositionState = getState().liveComposition;
    const selectedMatchTeamPlayer = liveCompositionState.selectedMatchTeamPlayer[side];
    if (!selectedMatchTeamPlayer) return;

    dispatch(selectMatchTeamPlayerAction(matchTeamPlayer, side));
    dispatch(
      removeMatchTeamPlayerAction(
        side,
        matchTeamPlayer.position as EPosition,
        matchTeamPlayer.positionSide
      )
    );
    dispatch(selectMatchTeamPlayerAction(selectedMatchTeamPlayer, side));
    dispatch(addMatchTeamPlayerOnComposition(side, position, positionSide));
    if (selectedMatchTeamPlayer.onBench) {
      dispatch(selectMatchTeamPlayerAction(matchTeamPlayer, side));
      dispatch(addSubstituteAction(side));
      dispatch(unselectMatchTeamPlayerAction(side));
    }
    if (selectedMatchTeamPlayer.position) {
      dispatch(selectMatchTeamPlayerAction(matchTeamPlayer, side));
      dispatch(
        placeMatchTeamPlayerAction(
          side,
          selectedMatchTeamPlayer.position,
          selectedMatchTeamPlayer.positionSide
        )
      );
      dispatch(unselectMatchTeamPlayerAction(side));
    }
  };
};
