import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SimpleQuoteDeutsch({ ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="M5 20h3l2-4v-6H4v6h3l-2 4zM17 6h-3l-2 4v6h6v-6h-3l2-4z" />
    </SvgIcon>
  );
}
