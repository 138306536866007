import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { EPosition, EPositionSide } from '../../../../compositions/typings';
import { ISide } from '../../../../matches/typings';
import { selectCellMatchTeamPlayer } from '../../selector';
import {
  addMatchTeamPlayerOnComposition,
  removeMatchTeamPlayerFromComposition,
  swapMatchTeamPlayerFromComposition,
} from './actions';

const mapDispatchToProps = {
  addMatchTeamPlayerOnComposition,
  removeMatchTeamPlayerFromComposition,
  swapMatchTeamPlayerFromComposition,
};
interface IOwnProps {
  side: ISide;
  position: EPosition;
  positionSide: EPositionSide;
  disabled: boolean;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    matchTeamPlayer: selectCellMatchTeamPlayer(
      state,
      ownProps.side,
      ownProps.position,
      ownProps.positionSide
    ),
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
