import axios from 'axios';

const rest = axios.create({
  /* global process*/
  baseURL: process.env.ATINTERNET_API_URL,

  transformResponse: [
    function(data) {
      if (!data) return null;

      return JSON.parse(data);
    },
  ],
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },
});

// Add a request interceptor
rest.interceptors.request.use(async config => {
  config.headers['x-api-key'] = process.env.ATINTERNET_APIKEY;

  return { ...config };
});

export default rest;
