import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { changeLiveMatchScores } from '../../actions';

interface IOwnProps {}

const mapDispatchToProps = {
  changeLiveMatchScores,
};

const mapStateToProps = (state: IRootState) => ({
  match: state.match,
  mainCommentatorLocale: state.live.mainCommentatorLocale,
});

export type IComponentProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & InjectedIntlProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
