import {CircularProgress, Paper, Toolbar, Typography} from "@material-ui/core";
import React from "react";
import {Route} from "react-router-dom";
import {IdBadge} from "../../../common/components/IdBadge";
import Layout from "../../../common/components/Layout";
import ReturnButton from "../../../common/components/ReturnButton";
import {SimpleBreadcrumbs} from "../../../common/components/SimpleBreadcrumbs";
import TabsRoute from "../../../common/components/TabsRoute";
import {getProject} from "../../../common/services/project";
import {QuizzForm} from "../components/QuizzForm";
import {IQuizz} from "../typings";
import { IComponentProps } from "./QuizzDetail.connector";
import {QuizzStat} from "../Stats/QuizzStat.component";


export class QuizzDetail extends React.Component<IComponentProps> {
    private menu = [{ label: 'app_info', link: '/' }, { label: 'app_statistics', link: '/stats' }];

    public componentDidMount() {
        this.fetchData();
    }

    public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
        const { id } = this.props;

        if (next.id !== id) {
            this.fetchData(next.id);
        }
    }

    public render() {
        const {
            fetching,
            quizz,
            match,
            history,
            intl: { messages: m },
        } = this.props;

        if (fetching) {
            return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
        }
        if (!quizz) {
            return <div>{m.error_noData}</div>;
        }


        return (
            <Layout
                id="quizz-layout"
                top={
                    <SimpleBreadcrumbs
                        crumbStack={[{ labelKey: 'app_quizz', href: `#${getProject().url}/quizzes` }, { label: quizz.title }]}
                    />
                }
                main={
                    <Paper elevation={2}>
                        <Toolbar>
                            <Typography variant="h6">{quizz.title}</Typography>
                            <IdBadge id={quizz.id} />
                            <div style={{ flex: 1 }} />
                            <ReturnButton entity="quizzes" />
                        </Toolbar>
                        <TabsRoute match={match} menu={this.menu} history={history} />
                        <Route exact path={match.url} render={this.renderForm} />
                        {<Route exact path={`${match.url}/stats`} render={this.renderStats} />}
                    </Paper>
                }
            />
        );
    }

    private fetchData = (targetId?: string) => {
        const { id, fetchQuizz } = this.props;
        fetchQuizz(targetId || id);
    };

    private renderForm = () => {
        const { quizz } = this.props;
        return <QuizzForm onSubmit={this.handleUpdate} data={quizz} />;
    };

    private renderStats = () => {
        const { quizz, intl, fetchAnalyticStat } = this.props;
        return <QuizzStat quizz={quizz} intl={intl} fetchAnalyticStat={fetchAnalyticStat} />;
    };

    private handleUpdate = (quizz: IQuizz) => {
        // check if questions are the same
        if (JSON.stringify(quizz.questions) === JSON.stringify(this.props.quizz.questions)) {
            // if they are the same, remove them from the quizz object
            delete quizz.questions;
        }
        else {
            quizz.questions = quizz.questions.sort((a, b) => a.ordering - b.ordering);
        }

        const { id, updateQuizz } = this.props;
        return updateQuizz(id, quizz);
    };
}
