import { WithStyles, withStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { IRootState } from '../../typings';
import {
  fetchFixedArticles,
  fetchNewestArticles,
  fetchOwnInProgressArticles,
  fetchOwnPostPublishedArticles,
  fetchOwnPrewrittenArticles,
  fetchProposalArticles,
  fetchRestrictedArticles,
  fetchTemplateArticles,
} from '../articles/actions';
import { fetchProposalLiveTransfers } from '../briefs/liveTransfers/actions';

const mapDispatchToProps = {
  fetchNewestArticles,
  fetchOwnPrewrittenArticles,
  fetchOwnInProgressArticles,
  fetchOwnPostPublishedArticles,
  fetchProposalArticles,
  fetchProposalLiveTransfers,
  fetchFixedArticles,
  fetchTemplateArticles,
  fetchRestrictedArticles
};

const mapStateToProps = (state: IRootState) => ({
  newestArticles: state.articles.newest,
  postpublishedArticles: state.articles.postpublished,
  proposalArticles: state.articles.proposal,
  inprogressArticles: state.articles.inprogress,
  prewrittenArticles: state.articles.prewritten,
  proposalBriefs: state.briefs.proposal,
  fixedArticles: state.articles.fixed,
  templateArticles: state.articles.template,
  restrictedArticles: state.articles.restricted,
});

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles &
  InjectedIntlProps &
  RouteComponentProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

const styles = {
  marginTop: {
    marginTop: 16,
  },
  highligth: {
    background: 'antiquewhite',
  },
};

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  withStyles(styles),
  injectIntl
);
