import { WithStyles } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { updateLivePeriod } from '../../actions/liveActions';

const mapDispatchToProps = {
  updateLivePeriod,
};

const mapStateToProps = (state: IRootState) => ({
  period: state.live.period,
  isMatchUpdating: state.match.updating,
  mainCommentatorLocale: state.live.mainCommentatorLocale,
});
export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  injectIntl
);
