import { LinearProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { Component } from 'react';
import FooterPagination from './FooterPagination';
import { IComponentProps } from './TableList.connector';

export class TableList extends Component<IComponentProps> {
  public render() {
    const { loading, classes, onPaginate, total, pagination } = this.props;
    return (
      <div className={classes.container}>
        {loading ? <LinearProgress /> : null}
        <Table size="medium">
          {this.renderHead()}
          {this.renderBody()}
          <FooterPagination
            pagination={pagination}
            total={total}
            onPaginate={onPaginate}
          />
        </Table>
      </div>
    );
  }

  private updateSort = (id: string) => () => {
    this.props.onSort(id);
  };

  private renderHead = () => {
    const {
      tableHeaders,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {tableHeaders.map((header, index) =>
            header.id ? (
              <TableCell
                key={header.id}
                padding={header.padding}
                size={header.size}
                sortDirection={query.sort.field === header.id ? query.sort.order : false}
              >
                <TableSortLabel
                  active={query.sort.field === header.id}
                  direction={query.sort.order}
                  data-id={header.id}
                  onClick={this.updateSort(header.id)}
                >
                  {m[header.labelKey]}
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell key={index} padding={header.padding} size={header.size}>
                {m[header.labelKey]}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  };

  private renderBody = () => {
    const { tableData, Row, classes, query, rowCallback } = this.props;
    if (!tableData || !tableData.length) {
      return null;
    }

    return (
      <TableBody classes={{ root: classes.body }}>
        {tableData.map((values: any, index: number) => (
          <Row
            key={index}
            tableData={values}
            {...Row.props}
            query={query}
            update={rowCallback}
          />
        ))}
      </TableBody>
    );
  };
}
