import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ActionDone from '@material-ui/icons/Done';
import moment from 'moment';
import ClubAvatar from '../../../clubs/Avatar';

const styles = {
  secondary: {
    fontSize: '11px !important',
  },
};

@withStyles(styles)
@injectIntl
export default class Memberships extends Component {
  formatDate = date => (date ? moment(date).calendar(null, { sameElse: 'l' }) : '?');
  formatRange = (start, end) => `${this.formatDate(start)} - ${this.formatDate(end)}`;

  render() {
    const {
      memberships = [],
      intl: { messages: m },
      classes,
    } = this.props;

    return (
      <List dense subheader={<ListSubheader>{m.app_memberships}</ListSubheader>}>
        {memberships.map(membership => (
          <ListItem
            button
            component="a"
            href={`#${getProject().url}/memberships/${membership.id}`}
          >
            <ListItemIcon>
              <ClubAvatar club={membership.club} />
            </ListItemIcon>
            <ListItemText
              primary={membership.clubShortName}
              secondary={this.formatRange(membership.startDate, membership.endDate)}
              classes={{
                secondary: classes.secondary,
              }}
            />
            {membership.isActive && (
              <ListItemSecondaryAction>
                <ActionDone style={{ padding: 8 }} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    );
  }
}
