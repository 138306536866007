import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { IProduct } from '../../typings';
import { getProductLabel } from '../../utils';
import Form from './Form';
import { IComponentProps } from './ProductIdPanel.connector';

export class ProductIdPanel extends Component<IComponentProps> {
  public componentDidMount() {
    const { getProduct, id } = this.props;
    getProduct(id);
  }

  private handleExpand = (_, expanded: boolean) => {
    const { onExpend, id } = this.props;
    onExpend(id, expanded);
  };

  private handleChange = (data: Partial<IProduct>) => {
    const { updateProduct, id } = this.props;
    return updateProduct(id, data);
  };

  private handleDelete = () => {
    const { onDelete, id } = this.props;
    return onDelete(id);
  };
  private handleRemove = () => {
    const { onRemove, id } = this.props;
    return onRemove(id);
  };

  public render() {
    const { classes, product, isExpanded } = this.props;
    if (!product) return null;

    return (
      <ExpansionPanel expanded={isExpanded} onChange={this.handleExpand} classes={{ root: classes.panel }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{getProductLabel(product)}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {isExpanded && (
            <Form
              onSubmit={this.handleChange}
              data={product}
              onDelete={this.handleDelete}
              onRemove={this.handleRemove}
            />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
