import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getProject } from 'Services/project';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

@injectIntl
export default class TagChildren extends Component {
  render() {
    const {
      tags = [],
      intl: { messages: m },
    } = this.props;
    return (
      <List subheader={<ListSubheader>Tags Enfants</ListSubheader>} dense>
        {tags
          ? tags.map(tag => (
              <ListItem
                button
                component="a"
                href={`#${getProject().url}/tags/${tag.slug}`}
              >
                <ListItemText primary={tag.title} />
              </ListItem>
            ))
          : null}
        {tags.length == 0 && (
          <ListItem>
            <ListItemText primary={m.error_noData} />
          </ListItem>
        )}
        {tags.length == 10 && (
          <ListItem button component="a" href={`#${getProject().url}/tags`}>
            <ListItemText primary={m.button_seeAll} />
          </ListItem>
        )}
      </List>
    );
  }
}
