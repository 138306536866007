import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCollection, getItemBySlug } from 'Services/connectors';
import { Route } from 'react-router-dom';

import { fetchClub, updateClub } from '../actions';
import { addProduct, fetchClubProducts } from '../../products/actions';
import { addClubSponsor, fetchClubSponsors } from '../../clubSponsors/actions';
import { fetchClubVenues } from '../../venues/actions';
import { fetchClubTeams } from '../../teams/actions';
import { fetchClubActiveMemberships } from '../../memberships/actions';

import RevisionNote from 'Components/revisions/Note';
import Revisions from 'Components/revisions';
import { IdBadge } from 'Components/IdBadge';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';

import Breadcrumb from 'FDComponents/Breadcrumb';
import Form from './Form';
import Products from './Products';
import Sponsors from './Sponsors';
import Venues from './Venues';
import Teams from './Teams';
import Memberships from './Memberships';

import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from 'Components/Layout';

@injectIntl
@connect((store, props) => ({
  slug: props.match.params.topicSlug,
  club: getItemBySlug(store.club, props.match.params.topicSlug),
  fetching: store.club.fetching,
  teams: getCollection('teamsIds', store.club, store.team),
  memberships: getCollection('membershipsIds', store.club, store.membership),
  venues: getCollection('venuesIds', store.club, store.venue),
  products: getCollection('productsIds', store.club, store.product),
  clubSponsors: getCollection('clubSponsorsIds', store.club, store.clubSponsor),
  seasons: store.seasons.list,
  types: store.constants.product && store.constants.product.type && store.constants.product.type.list,
}))
export default class Detail extends Component {
  state = {
    showRevisions: false,
  };

  menu = [
    { label: 'app_clubInfo', link: '/' },
    { label: 'app_products', link: '/products' },
    { label: 'app_sponsors', link: '/club-sponsors' },
    { label: 'app_venues', link: '/venues' },
  ];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.slug != this.props.slug) {
      this.fetchData(next.slug);
    }
  }

  fetchData = targetSlug => {
    const { slug, dispatch } = this.props;
    dispatch(fetchClub(targetSlug || slug)).then(({ payload }) => {
      if (payload && payload.id) {
        dispatch(fetchClubProducts(payload.id));
        dispatch(fetchClubVenues(payload.id));
        dispatch(fetchClubTeams(payload.id));
        dispatch(fetchClubActiveMemberships(payload.id));
        dispatch(fetchClubSponsors(payload.id));
      }
    });
  };

  toggleRevisions = () => {
    const { showRevisions } = this.state;
    this.setState({ showRevisions: !showRevisions });
  };

  handleChange = id => club => {
    const { dispatch } = this.props;
    return dispatch(updateClub(id, club));
  };

  handleProductAdd = values => {
    const { dispatch, club } = this.props;
    const product = {
      ...values,
      club: club['@id'],
    };
    return dispatch(addProduct(product, 'club'));
  };

  handleShirtProductAdd = values => {
    this.handleProductAdd({
      ...values,
      category: 'shirt',
    });
  };

  handleSponsorAdd = values => {
    const { dispatch, club } = this.props;
    const clubSponsor = {
      ...values,
      club: club['@id'],
    };
    return dispatch(addClubSponsor(clubSponsor, 'club'));
  };

  render() {
    const {
      fetching,
      club,
      clubSponsors,
      products,
      memberships,
      venues,
      teams,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (!club && fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!club) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="club-layout"
        top={<Breadcrumb />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{club.name}</Typography>
              <IdBadge id={club.id} />
              <div style={{ flex: 1 }} />
              <ReturnButton entity="clubs" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route
              exact
              path={match.url}
              render={() => (
                <div>
                  <div>
                    <RevisionNote
                      style={{ margin: 16 }}
                      createdAt={club.createdAt}
                      updatedAt={club.updatedAt}
                      toggle={this.toggleRevisions}
                    />
                    {this.state.showRevisions && (
                      <Revisions
                        entity="club"
                        id={club.id}
                        updatedAt={club.updatedAt}
                        onRollback={() => this.fetchData()}
                      />
                    )}
                  </div>
                  <Form onSubmit={this.handleChange(club.id)} data={club} />
                </div>
              )}
            />
            <Route
              path={`${match.url}/products`}
              render={() => (
                <Products
                  products={products}
                  onAdd={this.handleProductAdd}
                  onShirtAdd={this.handleShirtProductAdd}
                  url={`${match.url}/products`}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.url}/club-sponsors`}
              render={() => (
                <Sponsors
                  sponsors={clubSponsors}
                  onAdd={this.handleSponsorAdd}
                  url={`${match.url}/club-sponsors`}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.url}/venues`}
              render={() => <Venues venues={venues} url={`${match.url}/venues`} history={history} />}
            />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <Teams teams={teams} />
            <Memberships memberships={memberships} />
          </Paper>
        }
      />
    );
  }
}
