import { SubmissionError } from 'redux-form';
import rest from 'Services/restFootData';

export function fetchBrand(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_BRAND' });

    return rest
      .get(`brands/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_BRAND_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_BRAND_REJECTED', payload: err.response })
      );
  };
}

export function fetchAllBrands(disabledCache) {
  const params = {
    pagination: 0,
  };

  return function(dispatch, getState) {
    const brands = getState().brands;
    if (!disabledCache && brands.fetched) {
      return dispatch({ type: 'CACHED_BRANDS' });
    } else {
      dispatch({ type: 'FETCH_BRANDS' });

      // cached data
      return rest
        .get(`brands`, { params })
        .then(
          res => dispatch({ type: 'FETCH_BRANDS_FULFILLED', payload: res.data }),
          err => dispatch({ type: 'FETCH_BRANDS_REJECTED', payload: err.response })
        );
    }
  };
}

export function updateBrand(id, brand) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_BRAND', payload: id });

    return rest.put(`brands/${id}`, brand).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_brandUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_BRAND_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_BRAND_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addBrand(brand) {
  return function(dispatch) {
    dispatch({ type: `ADD_BRAND` });

    return rest.post('brands', brand).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_brandAdded', type: 'success' },
        });
        return dispatch({
          type: `ADD_BRAND_FULFILLED`,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_brandAdd_error', type: 'error' },
        });
        return dispatch({
          type: `ADD_BRAND_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}
