import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TeamSeasonPicturesPanels from './Panels';

@injectIntl
export default class TeamSeasonPictures extends Component {
  render() {
    const {
      teamSeasons = [],
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_teamSeasonPicturesList}</Typography>
        </Toolbar>
        <div style={{ padding: 16 }}>
          <TeamSeasonPicturesPanels teamSeasons={teamSeasons} />
        </div>
      </div>
    );
  }
}
