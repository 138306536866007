import { Toolbar, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { TableList } from "Components/TableList";
import { TeamRow } from "./TeamRow.component";

@injectIntl
export default class Teams extends Component {
  config = {
    headers: [
      { id: 'translations.name', labelKey: 'field_name' },
      { id: 'mercatoBudget', labelKey: 'field_mercatoBudget' },
    ],
  }

  render() {
    const {
      teams = [],
      intl: { messages: m },
    } = this.props;

    return (
      <div>
        <Typography variant="h6">{m.app_mercatoSurveyTeamList}</Typography>
        <Toolbar>
          <TableList
            tableData={teams}
            tableHeaders={this.config.headers}
            Row={TeamRow}
            onSort={() => {}}
            query={{
              sort: {},
              toggles: {},
              filters: {},
            }}
          />
        </Toolbar>
      </div>
    );
  }
}
