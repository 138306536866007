import { WithStyles, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { selectMatch } from '../../../../matches/selector';
import { styles } from './styles';

interface IOwnProps {}
const mapDispatchToProps = {};

const mapStateToProps = (state: IRootState) => {
  return {
    game: selectMatch(state, state.live.matchId),
    homeTeam: state.live.homeTeam,
    awayTeam: state.live.awayTeam,
  };
};
export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  WithStyles;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  withStyles(styles)
);
