import { Paper, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import ToggleFilter from '../../../common/components/ToggleFilter';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce, { getLinkId } from '../../../common/services/tools';
import CompetitionInput from '../../competitions/Input';
import SelectConstant from "../../components/constants/Select";
import MercatoSurveyDialog from "../MercatoSurveyDialog";
import { IMercatoSurvey } from '../typings';
import { MercatoSurveyRow } from './MercatoSurveyRow.component';
import { IComponentProps } from './MercatoSurveysList.connector';

// @ts-ignore
export class MercatoSurveysList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'id',
        labelKey: 'field_id',
      },
      {
        type: 'mercatoSurveyCompetition',
        input: CompetitionInput,
        labelKey: 'filter_competition',
      },
      {
        type: 'type',
        input: SelectConstant,
        entity: 'mercatoSurvey',
        labelKey: 'field_type',
      },
    ],
    toggles: [
      {
        type: 'isActive',
        labelKey: 'field_isAvailable',
      },
      {
        type: 'isEnabled',
        labelKey: 'field_isEnabled',
      },
    ],
    headers: [
      { id: 'tournament.competition.translations.name', labelKey: 'field_competition' },
      { id: 'type', labelKey: 'field_type' },
      { id: 'isActive', labelKey: 'field_isAvailable' },
      { id: 'isEnabled', labelKey: 'field_isEnabled' },
      { id: 'startDate', labelKey: 'field_startDate' },
      { id: 'endDate', labelKey: 'field_endDate' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/mercatosurveys${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: oldLocation } = this.props;
    const { location } = nextProps;

    if (oldLocation.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      mercatoSurveys,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="mercato-surveys-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_mercatoSurveys' }]} />
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_mercatoSurveys}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <MercatoSurveyDialog title={m.dialog_addMercatoSurvey} form="mercatoSurvey_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </MercatoSurveyDialog>
              </UserRestriction>
            </Toolbar>
            <ToggleFilter
              form="mercatoSurveys_togglefilters"
              toggles={this.config.toggles}
              onChange={this.handleToggles}
              initialValues={query.toggles}
            />
            <SearchFilter
              form="mercatoSurveys_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={mercatoSurveys}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={MercatoSurveyRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchMercatoSurveys, filterList } = this.props;
    fetchMercatoSurveys(filter);
    filterList('mercatoSurveys', filter);
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  private handleFilters = change => {
    const { id, type, mercatoSurveyCompetition } = change;
    const filtersQuery = {
      id,
      type,
     'tournament.competition.id': getLinkId(mercatoSurveyCompetition),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private handleToggles = change => {
    this.changeParams({ type: 'SET_TOGGLE', payload: change });
  };

  private submitAdd = (mercatoSurvey: Partial<IMercatoSurvey>) => {
    const { addMercatoSurvey, history } = this.props;

    // @ts-ignore
    addMercatoSurvey(mercatoSurvey).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/mercatosurveys/${payload.id}`);
      }
    });
  };
}
