import { withStyles } from "@material-ui/core";
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from "redux-form";
import { IRootState } from "../../../../typings";
import {fetchArticle} from "../../actions";
import { IArticle, IArticleRelations } from "../../typings";
import { fetchArticleRelations } from "../ArticleRelations/actions";
import { addArticleRelation, deleteArticleRelation, updateArticleRelation } from "./action";
import { styles } from "./styles";
import validate from './validate';

interface IOwnProps {
    articleSource: IArticle,
    articleRelations: IArticleRelations[],
    initialize: (formData: FormData) => void,
    handleSubmit: (handleSubmit: (form) => void) => void,
    classes: any,
}

const mapDispatchToProps = {
    addArticleRelation,
    updateArticleRelation,
    deleteArticleRelation,
    fetchArticle,
    fetchArticleRelations
};

export type IComponentProps = typeof mapDispatchToProps & InjectedIntlProps & IOwnProps;

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
    const articleRelations = state.articleRelations[ownProps.articleSource.id].list.collection;

    return {
        articleRelations,
        initialValues : {
            articleRelations : getInitialValues(articleRelations)
        }
    };
}

 const getInitialValues = (articleRelations: IArticleRelations[]) => {

     return articleRelations.map((articleRelation) => {
        return {
            id: articleRelation.id,
            articleLinked: articleRelation.articleLinked,
            type: articleRelation.type,
            ordering: articleRelation.ordering,
            articleSource: articleRelation.articleSource
        };
    });

}
const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
    withStyles(styles),
    reduxConnector,
    injectIntl,
    // @ts-ignore
    reduxForm({ form: 'articleRelations_form' , enableReinitialize: true, validate })
);
