import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchBroadcasts(search) {
  const params = qs.parse(search);

  return function(dispatch) {
    dispatch({ type: 'FETCH_BROADCASTS' });

    return rest
      .get('broadcasts', { params })
      .then(
        res =>
          dispatch({ type: 'FETCH_BROADCASTS_FULFILLED', payload: res.data }),
        err =>
          dispatch({ type: 'FETCH_BROADCASTS_REJECTED', payload: err.response })
      );
  };
}

export function fetchBroadcastsList(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
    groups: ['read', '_match', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BROADCASTS' });
    return rest
      .get('broadcasts', { params })
      .then(
        res =>
          dispatch({ type: 'FETCH_BROADCASTS_FULFILLED', payload: res.data }),
        err =>
          dispatch({ type: 'FETCH_BROADCASTS_REJECTED', payload: err.response })
      );
  };
}

export function fetchBroadcast(slug) {
  const params = { slug };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BROADCAST' });

    return rest.get('broadcasts', { params }).then(
      res => {
        if (res.data.collection && res.data.collection[0]) {
          return dispatch({
            type: 'FETCH_BROADCAST_FULFILLED',
            payload: res.data.collection[0],
          });
        }
        return dispatch({ type: 'FETCH_BROADCAST_NOTFOUND' });
      },
      err =>
        dispatch({ type: 'FETCH_BROADCAST_REJECTED', payload: err.response })
    );
  };
}

export function updateBroadcast(id, broadcast) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_BROADCAST', payload: id });
    return rest.put(`broadcasts/${id}`, broadcast).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: 'Broadcast modifié', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_BROADCAST_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_BROADCAST_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function fetchMatchBroadcasts(match) {
  const params = { match };

  return function(dispatch) {
    dispatch({ type: 'FETCH_MATCH_BROADCASTS', payload: match });

    return rest.get('broadcasts', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_MATCH_BROADCASTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_MATCH_BROADCASTS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function addBroadcast(broadcast) {
  return function(dispatch) {
    dispatch({ type: 'ADD_BROADCAST' });

    return rest.post('broadcasts', broadcast).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { message: 'Broadcast Créé', type: 'success' },
        });
        return dispatch({ type: 'ADD_BROADCAST_FULFILLED', payload: res.data });
      },
      err => dispatch({ type: 'ADD_BROADCAST_REJECTED', payload: err.response })
    );
  };
}

export function deleteBroadcast(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_BROADCAST', payload: id });

    return rest
      .delete(`broadcasts/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_BROADCAST_FULFILLED', payload: { id } }),
        err =>
          dispatch({ type: 'DELETE_BROADCAST_REJECTED', payload: err.response })
      );
  };
}
