import React, { Component } from 'react';
import { FormEdit } from '../../../../common/components/FormEdit';
import DatetimeInput, { formatUtc } from '../../../../common/components/inputs/Datetime';
import Toggle, { normalizeBoolean } from '../../../../common/components/inputs/Toggle';
import SelectConstant from '../../../components/constants/Select';
import TournamentInput from "../../../tournaments/Input";
import { IMercatoSurvey } from '../../typings';

interface IProps {
  data: any;
  onSubmit: (mercatoSurvey: IMercatoSurvey) => void;
  onDelete?: (mercatoSurvey: IMercatoSurvey) => void;
  onRemove?: (id: string) => void;
}

export class MercatoSurveyForm extends Component<IProps> {

  public config = {
    fields: [
      'id',
      'type',
      'isEnabled',
      'isActive',
      'startDate',
      'endDate',
    ],
    objectFields: ['tournament'],
    disposition: [
      {
        title: 'form_general',
        fields: [
          { name: 'type', component: SelectConstant, entity: 'mercatoSurvey', size: 2, required: true },
          {
            name: 'tournament',
            component: TournamentInput,
            isActiveOnly: false,
            size: 4,
            required: true
          },
          { name: 'startDate', component: DatetimeInput, normalize: formatUtc, size: 2, required: true },
          { name: 'endDate', component: DatetimeInput, normalize: formatUtc, size: 2, required: true },
          { name: 'isEnabled', component: Toggle, normalize: normalizeBoolean, size: 3 },
          { name: 'isActive', labelKey: 'field_isAvailable', component: Toggle, normalize: normalizeBoolean, size: 3, disabled: true },
        ],
      }
    ],
  };

  public render() {
    const { data, onSubmit, onDelete, onRemove } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`mercatoSurvey_${data.id}`}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onRemove={onRemove}
        data={data}
        config={this.config}
        useUserRestriction
        main
      />
    );
  }
}
