import { Card, ListSubheader } from '@material-ui/core';
import * as React from 'react';
import { IMatchTeamPlayer } from '../../../../matchTeamPlayers/typings';
import { SubstituteCell } from '../SubstituteCell';
import { IComponentProps } from './SubstitutesBloc.connector';

const MINIMUM_SUBSTITUTES_CELLS = 10;

export class SubstitutesBloc extends React.Component<IComponentProps> {
  public render() {
    const {
      classes,
      teamSubstitutes,
      intl: { messages: m },
    } = this.props;
    const length = teamSubstitutes.length;
    const emptySubstitutesNumber =
      Math.max(MINIMUM_SUBSTITUTES_CELLS, length + 1) - length;
    const emptySubstitutes: null[] = Array(emptySubstitutesNumber).fill(null);

    return (
      <Card>
        <ListSubheader className={classes.listSubheader}>
          {m.live_compo_substitutes}
        </ListSubheader>
        <div className={classes.container}>
          {teamSubstitutes.map(this.renderSubstituteCell)}
          {emptySubstitutes.map(this.renderEmptySubstituteCell)}
        </div>
      </Card>
    );
  }

  private renderSubstituteCell = (substitute: IMatchTeamPlayer) => {
    const { side } = this.props;
    return (
      <SubstituteCell
        key={`substitute-${substitute.membership.id}`}
        side={side}
        substitute={substitute}
      />
    );
  };

  private renderEmptySubstituteCell = (substitute: null, index: number) => {
    const { side } = this.props;
    return (
      <SubstituteCell
        key={`substitute-empty-${index}`}
        side={side}
        substitute={substitute}
      />
    );
  };
}
