import React from 'react';
import { injectIntl } from 'react-intl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';

function MultiSelectField({
  input: { name, value, onChange },
  meta: { touched, error },
  intl: { formatMessage: f },
  list = [],
  labelKey,
  ...custom
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

  return (
    <FormControl fullWidth error={Boolean(touched && error)}>
      <InputLabel htmlFor={`multiselect_${label}`}>{label}</InputLabel>
      <Select
        {...custom}
        multiple
        name={name}
        value={value}
        onChange={event => onChange(event.target.value)}
        input={<Input id={`multiselect_${label}`} />}
        renderValue={selected => selected.join(', ')}
        MenuProps={{ PaperProps: { style: { maxHeight: 500 } } }}
      >
        {list.map(item => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={value.includes(item)} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
export default injectIntl(MultiSelectField);
