import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { CardContent, CardMedia } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { getEmbedConfig } from "FM/articles/embed/utils";


const styles = theme => ({
    grid: {
        padding: '24px 0',
        overflowY: 'auto',
    },
    selectedTile: {
        border: `4px solid ${theme.palette.primary[500]}`,
    },
    closeButton: {
        position: 'absolute',
        zIndex: '15',
        top: 0,
        right: 0,
    },
    inputContainer: {
        display: 'flex',
        margin: '0 24px',
    },
    input: {
        marginLeft: 16,
        position: 'relative',
    },
    img: {
        position: 'absolute',
    },
    infoIcon: {
        position: 'absolute',
        zIndex: '15',
        bottom: 4,
        right: 4,
    },
    size: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: 'white',
        background: 'black',
        padding: '0 4px',
    },
    loader: { position: 'absolute', bottom: 0, width: '100%' },
});
@withStyles(styles)
@injectIntl
export default class EmbedLibrary extends Component {
    state = {
        selectedEmbed: null,
        filter: 'all'
    };

renderTile(tile, name) {
    const { filter } = this.state;
    const {
        intl: { messages: m },
    } = this.props;
    if(filter === 'all' || tile.tags.includes(filter)) {
        return (
            <Grid item xs={3} key={name}>
                <Card variant="outlined" onClick={() => this.selectEmbed(name)}>
                    <CardContent>
                        {tile.tags.map(tag => (
                            <Chip label={tag} color="primary" />
                        ))}
                        <Typography variant="h5" gutterBottom>
                            {m[`embed_${name}`]}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        style={{ maxHeight: '340px' }}
                        image={`/img/embed/${name}.jpg`}
                    />
                </Card>
            </Grid>)
    }
}

    onChange = event => {
        this.setState({ filename: event.target.value }, this.search);
    };

    onFilterChange = tag => {
        this.setState({ filter: tag });
    }
    renderTagsFilter = (tags) => tags.map(tag => (
            <Chip label={tag} style={{ margin: 4 }} color={this.state.filter === tag ? 'primary' : ''}
                  onClick={() => this.onFilterChange(tag)}
            />
        ))


    render() {
        const {
            intl: { messages: m },
            onclose
        } = this.props;
        const tags = ['all', 'match', 'tournament', 'team', 'article','player','userGame', 'misc']
        return (
            <React.Fragment>
                <DialogTitle key="dialog-title">{m.app_embed_libraryTitle}<span>{this.renderTagsFilter(tags)}</span></DialogTitle>
                {/* <div style={{ margin: '0 24px' }}>*/}
                {/*    <DialogContentText>{m.app_embed_libraryDescription}</DialogContentText>*/}
                {/* </div>*/}
                <DialogContent key="dialog-content">
                    <Grid container spacing={2}>
                        {Object.entries(getEmbedConfig()).map(([name,tile]) => (
                            this.renderTile(tile, name)
                            ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onclose()} >
                        <CloseIcon />
                        {m.button_close}
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }

    selectEmbed(embed) {
        const {
            handleChangeTab
        } = this.props;
        return handleChangeTab(embed)
    }
}
