import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import TabsRoute from 'Components/TabsRoute';
import FeaturedMatches from 'FD/matches/Featured';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import { fetchMenu } from '../actions';
import MenuItem from './MenuItem';
import FocusBlocks from "FM/highlighting/components/focusBlocks";

@injectIntl
@connect(store => ({
  menu: store.highlighting.menu,
  fetching: store.highlighting.fetching,
}))
export default class Detail extends Component {
  menu = [{ label: 'app_menu', link: '/' }, { label: 'app_match', link: '/matches' } , { label: 'app_focusBlocks', link: '/focusBlock' }];

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMenu());
  }

  renderMenuItem = () => {
    const { menu } = this.props;
    return menu && menu.map(entry => <MenuItem item={entry} />);
  };

  render() {
    const {
      fetching = true,
      intl: { formatMessage: f },
      match,
      history,
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }

    return (
      <Layout
        id="highlighting-layout"
        top={<TopBar crumbStack={[{ labelKey: 'app_highlighting' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{f({ id: 'app_doNotMiss' })}</Typography>
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route exact path={match.url} render={this.renderMenuItem} />

            <Route
              exact
              path={`${match.url}/matches`}
              render={() => <FeaturedMatches />}
            />
            <Route
                exact
                path={`${match.url}/focusBlock`}
                render={() => <FocusBlocks />}
            />
          </Paper>
        }
      />
    );
  }
}
