import React, { Component } from 'react';

import { FormEdit } from 'Components/FormEdit';
import Date from 'Components/inputs/Date';
import SelectConstant from 'FDComponents/constants/Select';
import Float from 'Components/inputs/Float';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import { AmountInput } from '../../../common/components/inputs/AmountInput';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'date',
      'type',
      'amount',
      'percentageOnResale',
      'loanDuration',
      'purchaseOption',
    ],
    disposition: [
      {
        fields: [
          {
            name: 'type',
            component: SelectConstant,
            entity: 'transfer',
            size: 6,
          },
          { name: 'date', component: Date, size: 6 },
        ],
      },
      {
        fields: [
          {
            name: 'amount',
            labelKey: 'field_amountTransferLoan',
            names: ['amount.value', 'amount.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
          {
            name: 'percentageOnResale',
            labelKey: 'field_percentageOnResale',
            component: Float,
            size: 6,
          },
        ],
      },
      {
        fields: [
          {
            name: 'purchaseOption',
            labelKey: 'field_purchaseOption',
            names: ['purchaseOption.value', 'purchaseOption.currency'],
            component: AmountInput,
            group: true,
            size: 6,
          },
          {
            name: 'loanDuration',
            labelKey: 'field_loanDuration',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`transfer_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        useUserRestriction
      />
    );
  }
}
