import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../../common/components/Layout';
import { Markdown } from '../../common/components/Markdown';
import { getProject } from '../../common/services/project';

const styleSheet = {
  paper: {
    padding: '16px',
  },
};

const pagesContext = {
  index: {
    path: '',
    label: 'Markdown',
    doc: 'markdown.md',
    locales: ['fr', 'es', 'de', 'en'],
  },
  users: {
    path: 'users',
    label: 'Users management',
    doc: 'users.md',
    locales: ['fr', 'es', 'de', 'en'],
  },
  graphic_design: {
    path: 'graphic_design',
    label: 'Graphic design',
    doc: 'graphic_design.md',
    locales: ['fr', 'es', 'de'],
  },
  article: {
    path: 'article',
    label: 'Article',
    doc: 'article.md',
    locales: ['fr', 'es', 'de'],
  },
  article_images: {
    path: 'article_images',
    label: 'Article images',
    doc: 'article_images.md',
    locales: ['fr', 'es', 'de', 'en'],
  },
  embed: {
    path: 'embed',
    label: 'Embed',
    doc: 'embed.md',
    locales: ['fr', 'es', 'de'],
  },
  highlighting: {
    path: 'highlighting',
    label: 'Highlighting',
    doc: 'highlighting.md',
    locales: ['fr', 'es', 'de'],
  },
  liveTransfers: {
    path: 'liveTransfers',
    label: 'Live Transfers',
    doc: 'live_transfers.md',
    locales: ['fr', 'es', 'de', 'en'],
  },
  footPlayers: {
    path: 'footPlayers',
    label: 'Foot Players',
    doc: 'footPlayers.md',
    locales: ['fr'],
  },
};

// @ts-ignore
@withStyles(styleSheet)
export class DocumentationPages extends React.Component<any> {
  public displayMarkdown = () => {
    const currentPathname = this.props.location.pathname.split('/').pop();
    const currentIndex = currentPathname === '' ? 'index' : currentPathname;

    if (pagesContext[currentIndex] && pagesContext[currentIndex].locales.includes(getProject().lang)) {
      return <Markdown key={currentIndex} filePath={'docs/footmercato/' + getProject().lang + '/' + pagesContext[currentIndex].doc} />;
    }

    return <Redirect to={`${this.props.match.url}/`} />;
  };

  public render() {
    const { match, location, classes } = this.props;
    const baseHref = `${getProject().url}/documentation`;

    return (
      <Layout
        id="guide"
        main={
          <Paper elevation={2} className={classes.paper}>
            <Route exact path={`${match.path}/users`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/graphic_design`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/article`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/article_images`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/embed`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/highlighting`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/liveTransfers`} render={this.displayMarkdown} />
            <Route exact path={`${match.path}/footPlayers`} render={this.displayMarkdown} />
            <Route exact path={match.path} render={this.displayMarkdown} />
          </Paper>
        }
        right={
          <Paper elevation={2}>
            <List component="nav">
              {Object.keys(pagesContext).map(key => {
                const href = `${baseHref}/${pagesContext[key].path}`;
                const id = pagesContext[key].label;
                const disabled = href === location.pathname;

                if (pagesContext[key].locales.includes(getProject().lang)) {
                  return (
                      <ListItem key={id} button component="a" href={`#${href}`} disabled={disabled}>
                        <ListItemText primary={pagesContext[key].label} />
                      </ListItem>
                  );
                }
                return;
              })}
            </List>
          </Paper>
        }
      />
    );
  }
}
