import { FormEdit } from 'Components/FormEdit';
import Date from 'Components/inputs/Date';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import TextInput from 'Components/inputs/Text';
import TextEdit from 'Components/inputs/TextEdit';
import Toggle, { normalizeBoolean } from 'Components/inputs/Toggle';
import SelectAgeGroup from 'FDComponents/ageGroups/Select';
import AreaInput from 'FDComponents/areas/Input';
import SelectBookmaker from 'FDComponents/bookmakers/Select';
import SelectConstant from 'FDComponents/constants/Select';
import SelectOrganization from 'FDComponents/organizations/Select';
import React, { Component } from 'react';
import Competition from '../../competitions/Input';
import ImageInput from '../../images/Input';

export default class Form extends Component {
  config = {
    fields: [
      'id',
      'name',
      'shortName',
      'slug',
      'trophyName',
      'format',
      'gender',
      'type',
      'priority',
      'foundationDate',
      'isFriendly',
      'featuredForBroadcast',
      'settlement',
      'twitterLink',
      'facebookLink',
      'instagramLink',
      'websiteLink',
      'isBetEnabled',
      'isPronosticEnabled',
      'isLineupSurveyEnabled',
      'isUserPlayerRatingEnabled',
    ],
    objectFields: [
      'area',
      'organizer',
      'upperCompetition',
      'qualifyingCompetition',
      'ageGroup',
      'image',
      'mainBookmaker',
    ],
    disposition: [
      {
        size: 3,
        fields: [{ name: 'image', component: ImageInput }],
      },
      {
        size: 9,
        fields: [
          { name: 'name', component: TextInput },
          { name: 'shortName', component: TextInput },
          { name: 'slug', component: TextInput, disabled: true },
        ],
      },
      {
        fields: [
          { name: 'trophyName', component: TextInput, size: 6 },
          {
            name: 'gender',
            component: SelectConstant,
            entity: 'competition',
            size: 6,
          },
          {
            name: 'type',
            component: SelectConstant,
            entity: 'competition',
            size: 6,
          },
          {
            name: 'format',
            component: SelectConstant,
            entity: 'competition',
            size: 6,
          },
          { name: 'area', component: AreaInput, size: 6 },
          {
            name: 'priority',
            component: IntInput,
            normalize: normalizeInt,
            size: 6,
          },
          { name: 'foundationDate', component: Date, size: 6 },
          {
            name: 'isFriendly',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          { name: 'organizer', component: SelectOrganization, size: 6 },
          { name: 'ageGroup', component: SelectAgeGroup, size: 6 },
          { name: 'upperCompetition', component: Competition, size: 6 },
          { name: 'qualifyingCompetition', component: Competition, size: 6 },
          { name: 'mainBookmaker', component: SelectBookmaker, size: 6 },
          {
            name: 'featuredForBroadcast',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isBetEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          { name: 'settlement', component: TextEdit },
        ],
      },
      {
        size: 12,
        title: 'form_community',
        fields: [
          {
            name: 'isPronosticEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isLineupSurveyEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          },
          {
            name: 'isUserPlayerRatingEnabled',
            component: Toggle,
            normalize: normalizeBoolean,
            size: 6,
          }
        ]
      },
      {
        size: 6,
        title: 'form_socialLinks',
        fields: [
          {
            name: 'facebookLink',
            component: TextInput,
            hintText: 'https://www.facebook.com/user',
          },
          {
            name: 'twitterLink',
            component: TextInput,
            hintText: 'https://twitter.com/user',
          },
          {
            name: 'instagramLink',
            component: TextInput,
            hintText: 'https://instagram.com/user',
          },
          {
            name: 'websiteLink',
            component: TextInput,
            hintText: 'http://www.link.com',
          },
        ],
      },
    ],
  };

  render() {
    const { data, onSubmit } = this.props;

    return (
      <FormEdit
        key={data.id}
        form={`competition_${data.id}`}
        onSubmit={onSubmit}
        data={data}
        config={this.config}
        main
        useUserRestriction
      />
    );
  }
}
