import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import Layout from '../../../common/components/Layout';
import { SearchFilter } from '../../../common/components/SearchFilter';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import { TableList } from '../../../common/components/TableList';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { stringifyQuery } from '../../../common/services/parseQuery';
import { getProject } from '../../../common/services/project';
import queryReducer from '../../../common/services/queriesReducer';
import debounce, { getLinkId } from '../../../common/services/tools';
import ClubInput from '../../clubs/Input';
import ProductDialog from '../ProductDialog';
import { IProduct } from '../typings';
import { ProductRow } from './ProductRow.component';
import { IComponentProps } from './ProductsList.connector';

export class ProductsList extends React.Component<IComponentProps> {
  private config = {
    filters: [
      {
        type: 'translationsName',
        labelKey: 'filter_name',
      },
      {
        type: 'club',
        input: ClubInput,
        labelKey: 'filter_club',
      },
    ],
    headers: [
      { labelKey: 'field_name' },
      { labelKey: 'field_category' },
      { labelKey: 'field_type' },
      { labelKey: 'field_season' },
    ],
  };

  private changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);
    if (location.search !== search) {
      history.push(`${getProject().url}/products${search}`);
    }
  }, 500);

  public componentWillUnmount() {
    debounce(null, null, true);
  }

  public componentDidMount() {
    const { location } = this.props;
    this.fetchData(location.search);
  }
  public componentDidUpdate(prevProps: IComponentProps) {
    const { location } = this.props;
    if (prevProps.location.search !== location.search) {
      this.fetchData(location.search);
    }
  }

  public render() {
    const {
      products,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;
    return (
      <Layout
        id="products-layout"
        top={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_products' }]} />
            <Button style={{ marginRight: 8 }} href={`#${getProject().url}/brands`} variant="contained" color="primary">
              {m.button_manageBrands}
            </Button>
          </div>
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_productsList}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <ProductDialog title={m.dialog_addProduct} form="product_add" onCreate={this.submitAdd}>
                  <Button variant="contained" color="primary">
                    <AddIcon style={{ marginRight: 8 }} />
                    {m.button_add}
                  </Button>
                </ProductDialog>
              </UserRestriction>
            </Toolbar>
            <SearchFilter
              form="products_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={products}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={ProductRow}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }

  private fetchData = (filter: string) => {
    const { fetchProducts, filterList } = this.props;
    fetchProducts(filter);
    filterList('products', filter);
  };

  private handleFilters = (change: any) => {
    const { translationsName, club } = change;
    const filtersQuery = {
      translationsName,
      club: getLinkId(club),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };

  private handlePagination = (change: any) => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };

  private handleSort = (field: any) => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };

  private submitAdd = (product: Partial<IProduct>) => {
    const { addProduct, history } = this.props;

    // @ts-ignore
    addProduct(product).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/products/${payload.id}`);
      }
    });
  };
}
