import rest from 'Services/restFootData';
import { SubmissionError } from "redux-form";

export function fetchPlayerInjuries(person) {
  const params = {
    person,
    groups: ['read'],
    'order[startDate]': 'desc'
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYER_INJURIES', payload: person });
    return rest.get('injuries', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PLAYER_INJURIES_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PLAYER_INJURIES_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateInjury(id, injury) {
  const params = {
    groups: ['read', 'write'],
  };
  return function(dispatch) {
    dispatch({ type: 'UPDATE_INJURY' });
    return rest.put(`injuries/${id}`, injury, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_injuryUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_INJURY_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (
          err.response &&
          err.response.data.type === 'ConstraintViolationList'
        ) {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_INJURY_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}
