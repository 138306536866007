import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';

import { updateImage, fetchImage, deleteImage } from '../actions';
import { isUserLimited } from '../../../common/users/utils';

import { withStyles } from '@material-ui/core/styles';
import Form from './Form';
import { LinearProgress } from '@material-ui/core';
import { DeleteLoading } from '../Components/DeleteLoading';

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.primary[500]}`,
  },
});

/* Move to transitions components ? */
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

@withStyles(styles)
@withMobileDialog()
@connect((store, props) => ({
  image: store.image.detail[props.imageId],
  imageDeleting: store.image.deleting,
  fetching: store.image.fetching,
  me: store.me,
}))
export default class ImageDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.handleFetchImage();
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.handleFetchImage();
  };

  handleDelete = () => {
    const { imageId, dispatch, deleteCallback } = this.props;

    return dispatch(
      deleteImage(imageId, () => {
        this.setState({ open: false });

        if (deleteCallback) {
          deleteCallback();
        }
      })
    );
  };

  handleConfirm = data => {
    const { dispatch } = this.props;
    this.handleClose();
    return dispatch(updateImage(data.id, data));
  };

  handleFetchImage = () => {
    const { imageId, dispatch } = this.props;
    if (imageId) {
      dispatch(fetchImage(imageId));
    }
  };

  render() {
    const { me, title, fullScreen, classes, image, fetching, imageDeleting, dimensions, children } = this.props;
    const { open } = this.state;
    return (
      <div>
        {React.cloneElement(children, { onClick: this.handleOpen, key: 'open_button' })}
        <Dialog
          TransitionComponent={Transition}
          maxWidth="md"
          fullScreen={fullScreen}
          fullWidth
          open={open}
          classes={{ paper: classes.body }}
        >
          {fetching ? <LinearProgress /> : null}
          <DialogTitle>
            {title} {dimensions && `(${dimensions.w}x${dimensions.h})`}
          </DialogTitle>

          {imageDeleting && <DeleteLoading />}

          {image && !imageDeleting ? (
            <Form
              isRestrictedUser={isUserLimited(me)}
              onSubmit={this.handleConfirm}
              onCancel={this.handleClose}
              onDelete={this.handleDelete}
              data={image}
            />
          ) : null}
        </Dialog>
      </div>
    );
  }
}
