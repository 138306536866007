import { FormControlLabel, Switch } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import * as React from 'react';
import { MatchTeamPlayerList } from '../MatchTeamPlayerList';
import { IComponentProps } from './MatchTeamPlayerPanel.connector';

interface IState {
  showAllMemberships: boolean;
}

export class MatchTeamPlayerPanel extends React.Component<IComponentProps, IState> {
  public state = {
    showAllMemberships: false,
  };
  public render() {
    const {
      side,
      classes,
      intl: { messages: m },
    } = this.props;
    const { showAllMemberships } = this.state;

    const switchLabel = showAllMemberships
      ? m.live_compo_clubMemberships
      : m.live_compo_teamSeasonMemberships;

    return (
      <Card className={classes.card}>
        <div className={classes.switchContainer}>
          <FormControlLabel
            className={classes.formControl}
            control={
              <Switch
                checked={!showAllMemberships}
                onChange={this.handleChange}
                name="showFullClub"
              />
            }
            label={switchLabel}
          />
        </div>
        <MatchTeamPlayerList side={side} showAllMemberships={showAllMemberships} />
      </Card>
    );
  }

  private handleChange = () => {
    this.setState(({ showAllMemberships }) => ({
      showAllMemberships: !showAllMemberships,
    }));
  };
}
