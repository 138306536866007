import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import { SearchFilter } from '../../../../common/components/SearchFilter';
import debounce, { getLinkId } from '../../../../common/services/tools';
import ClubInput from '../../../clubs/Input';
import { IProduct } from '../../typings';
import { IComponentProps } from './FindProductDialog.connector';

interface IState {
  selected: null | IProduct;
}

export class FindProductDialog extends React.Component<IComponentProps, IState> {
  public state: IState = {
    selected: null,
  };
  private config = {
    filters: [
      {
        type: 'translationsName',
        labelKey: 'filter_name',
      },
      {
        type: 'club',
        input: ClubInput,
        labelKey: 'filter_club',
      },
    ],
  };

  public render() {
    const {
      open,
      classes,
      intl: { messages: m },
      fullScreen,
      products,
    } = this.props;
    const { selected } = this.state;

    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="find-product-modal" fullScreen={fullScreen}>
        <DialogTitle>{m.dialog_findProduct}</DialogTitle>
        <DialogContent>
          <SearchFilter
            form="products_search_filters"
            filters={this.config.filters}
            onChange={this.handleFilters}
            initialValues={{}}
          />
          <List className={classes.root} dense>
            {products.map(this.renderSuggestion)}
          </List>
        </DialogContent>
        <DialogActions key="dialog-actions">
          <Button onClick={this.onCancel} color="primary">
            {m.button_cancel}
          </Button>
          <Button onClick={this.onConfirm} disabled={!Boolean(selected)} color="primary">
            {m.button_choose}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private renderSuggestion = (product: IProduct) => {
    const { selected } = this.state;
    const isSelected = Boolean(selected && selected.id === product.id);
    let secondary = product.categoryTranslated;
    if (product.typeTranslated) {
      secondary += ` / ${product.typeTranslated}`;
    }
    if (product.season) {
      secondary += ` / ${product.season.shortName}`;
    }
    const onClick = isSelected ? this.clearSelected : this.handleListItemClick(product);

    return (
      <ListItem button selected={isSelected} onClick={onClick}>
        <ListItemText primary={product.name} secondary={secondary} />
      </ListItem>
    );
  };

  private handleClose = res => {
    return res;
  };

  private onConfirm = () => {
    const { onClose } = this.props;
    const { selected } = this.state;
    onClose(selected);
  };

  private onCancel = () => {
    this.props.onClose(null);
  };

  private handleListItemClick = (product: IProduct) => () => {
    this.setState({ selected: product });
  };
  private clearSelected = () => {
    this.setState({ selected: null });
  };
  private handleFilters = (change: any) => {
    const { translationsName, club } = change;
    const filtersQuery = {
      'translations.name': translationsName,
      club: getLinkId(club),
    };
    this.changeParams(filtersQuery);
  };

  private changeParams = debounce(query => {
    const { searchProducts } = this.props;
    searchProducts(query);
  }, 500);
}
