import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import * as React from 'react';
import { connect } from 'react-redux';
import { getLinkId } from '../../../common/services/tools';
import { fetchTeamId } from '../actions';
import { getTeamLabel } from '../utils';

// @ts-ignore
@connect((store: any, props: any) => ({
  team: store.team.detail[getLinkId(props.children)],
}))
export class TeamCollectionChip extends React.Component<any> {
  public componentDidMount() {
    const { dispatch, children } = this.props;
    return dispatch(fetchTeamId(getLinkId(children)));
  }
  public render() {
    const { children, removeProps, team } = this.props;
    const label = team ? getTeamLabel(team) : children;
    const deleteIcon = team ? <CancelIcon {...removeProps} /> : <CircularProgress size={24} />;
    return <Chip tabIndex={-1} label={label} onDelete={removeProps.onClick} deleteIcon={deleteIcon} />;
  }
}
