import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { AdvancedBloc } from '../AdvancedBloc';
import { IComponentProps } from './ChangeMatchScores.connector';
import {getProject} from "../../../../../common/services/project";

interface IState {
  scoreHome: string | undefined;
  scoreAway: string | undefined;
  isRequesting: boolean;
}

export class ChangeMatchScores extends React.Component<IComponentProps> {
  public state: IState = {
    scoreHome: '',
    scoreAway: '',
    isRequesting: false,
  };

  public componentDidMount() {
    const { match } = this.props;
    const scores = match.detail[match.selectedId].scores;

    if (!scores) return;

    this.setState({ scoreHome: scores.home, scoreAway: scores.away });
  }

  public render() {
    const {
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;

    const { isRequesting, scoreHome, scoreAway } = this.state;

    return (
      <AdvancedBloc
        title={m.live_advanced_change_match_scores_title}
        description={m.live_advanced_change_match_scores_description}
      >
        <TextField label="Home" value={scoreHome} onChange={this.handleScoreHome} inputProps={{ type: 'number' }} disabled={mainCommentatorLocale != getProject().lang} />
        <TextField label="Away" value={scoreAway} onChange={this.handleScoreAway} inputProps={{ type: 'number' }} disabled={mainCommentatorLocale != getProject().lang} />

        <Button color="primary" onClick={this.handleChangeMatchScore} disabled={isRequesting || mainCommentatorLocale != getProject().lang}>
          {m.button_updateMatchScores}
        </Button>
      </AdvancedBloc>
    );
  }

  private isValidScore = score => {
    if (score === '') return true;
    const regexp = new RegExp('^\\d+$');
    return regexp.test(score);
  };

  private handleScoreHome = e => {
    const value = e.target.value;
    this.isValidScore(value) ? this.setState({ scoreHome: e.target.value }) : this.setState({ scoreHome: 0 });
  };

  private handleScoreAway = e => {
    const value = e.target.value;
    this.isValidScore(value) ? this.setState({ scoreAway: e.target.value }) : this.setState({ scoreAway: 0 });
  };

  private handleChangeMatchScore = () => {
    this.setState({ isRequesting: true });
    const { scoreHome, scoreAway } = this.state;
    const { changeLiveMatchScores } = this.props;

    if (scoreHome || scoreAway) {
      const scores = {
        home: scoreHome ? parseInt(scoreHome, 10) : 0,
        away: scoreAway ? parseInt(scoreAway, 10) : 0,
      };
      // @ts-ignore dispatch type error
      return changeLiveMatchScores(scores).then(() =>
        this.setState({ isRequesting: false, scoreHome: scores.home, scoreAway: scores.away })
      );
    }

    // @ts-ignore dispatch type error
    return changeLiveMatchScores(null).then(() => this.setState({ isRequesting: false }));
  };
}
