import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Notification from 'Components/Notification';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100vh',
    zIndex: 1,
    overflow: 'hidden',
  },
  app: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    width: '100%',
    height: 'calc(100% - 56px)',
    marginTop: 56,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
    },
  },
});

@withStyles(styles, { withTheme: true })
export default class MainLayout extends Component {
  render() {
    const { classes, toolbar, navigation, children } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.app}>
          {toolbar}
          {navigation}
          <div className={classes.contentContainer}>{children}</div>
          <Notification />
        </div>
      </div>
    );
  }
}
