import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Text from 'Components/inputs/Text';

const styles = theme => ({
  body: {
    borderLeft: `3px solid ${theme.palette.primary[500]}`,
  },
});

@withStyles(styles)
@reduxForm({})
@injectIntl
export default class CompanyDialog extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
    this.props.reset();
  };
  handleConfirm = company => {
    this.handleClose();
    this.props.onCreate(company);
  };

  render() {
    const {
      title,
      handleSubmit,
      pristine,
      submitting,
      classes,
      children,
      intl: { messages: m },
      disabled,
    } = this.props;
    const { open } = this.state;

    return (
      <div>
        {React.cloneElement(children, { onClick: this.handleOpen, disabled })}
        <Dialog open={open} classes={{ paper: classes.body }}>
          <DialogTitle>{title}</DialogTitle>
          <form onSubmit={handleSubmit(this.handleConfirm)}>
            <DialogContent>
              <div>
                <Field
                  name="name"
                  component={Text}
                  label={m.field_agentCompany_name}
                  autoFocus
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose} disabled={submitting}>
                {m.button_cancel}
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={pristine || submitting}
              >
                {m.button_create}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}
