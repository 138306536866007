import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GoalIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(5 5)">
        <polygon points="6.5 4 4 5.91 4.956 9 8.044 9 9 5.91" />
        <path d="M0.86923024,7.78633537 L3.25120726,9.51641352 L2.79173144,10.9290215 C3.69494394,11.6855842 4.80938295,12.1473914 5.98194951,12.2532744 L8.36392653,10.5221232 L9.56618284,11.3951213 C10.5634725,10.7696604 11.3481048,9.8530213 11.8100846,8.77004568 L10.9016854,5.96915415 L12.103584,5.09615596 C11.8161656,3.95380001 11.1867786,2.92448132 10.2994841,2.14967531 L7.35536906,2.14967531 L6.89571438,0.737067305 C5.72100158,0.65640306 4.54843503,0.937028782 3.53665818,1.54156348 L2.62664924,4.34173959 L1.1409094,4.34173959 C0.701644081,5.43508888 0.605241797,6.63718287 0.86923024,7.78633537 M13,6.49982114 C13,10.0900061 10.0898672,13 6.49955287,13 C2.91120589,13 0,10.0900061 0,6.49982114 C0,2.91035166 2.91102703,0 6.49955287,0 C10.0896884,0.00017885642 13,2.91035166 13,6.49982114" />
      </g>
    </SvgIcon>
  );
}
