import React, { Component } from 'react';
import { updateFocusBlock } from './actions';
import { FormEdit } from 'Components/FormEdit';
import { connect } from 'react-redux';
import { Chip, Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IntInput, { normalizeInt } from 'Components/inputs/Integer';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import { injectIntl } from 'react-intl';
import moment from "moment";
import TagInput from "FM/tags/Input";

@connect()
@injectIntl
export default class Form extends Component {
    config = () => ({
            fields: ['id', 'tag', 'initialWeight'],
            disposition: [
                {
                    fields: [
                        { name: 'initialWeight', component: IntInput, size: 1, normalize: normalizeInt },
                        { name: 'tag', component:  TagInput, size: 4 },
                    ],
                },
            ],
        })

    handleSubmit = item => {
        const { dispatch } = this.props;
        return dispatch(updateFocusBlock(item.id, item));
    };

    render() {
        const {
            item,
            label,
        } = this.props;

        return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h6" style={{ justifyContent: "space-between" }}>{label} - {item.keyCode} -  <Chip icon={<FitnessCenterIcon />} color="primary" label={` ${item.calculatedWeight} - initial: ${item.initialWeight} `} /> - <span> {moment(item.lastBuildAt).format('YYYY-MM-DD HH:MM')}</span>  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormEdit
                        key={item.id}
                        form={`focusBlock_${item.id}`}
                        onSubmit={this.handleSubmit}
                        data={item}
                        config={this.config()}
                    />
                </AccordionDetails>
            </Accordion>
        );
    }

}
