import rest from 'Services/restFootData';
import qs from 'query-string';
import { SubmissionError } from "redux-form";

const read_groups = ['read_mercato_survey'];

export function getMercatoSurvey(id) {
    const params = {
        groups: read_groups,
    };

    return function(dispatch) {
        dispatch({ type: 'GET_MERCATOSURVEY' });

        return rest
            .get(`mercato_surveys/${id}`, { params })
            .then(
                res => dispatch({ type: 'GET_MERCATOSURVEY_FULFILLED', payload: res.data }),
                err => dispatch({ type: 'GET_MERCATOSURVEY_REJECTED', payload: err.response })
            );
    };
}

export function fetchMercatoSurveys(search) {
    const params = {
        groups: read_groups,
        itemsPerPage: 10,
        ...qs.parse(search),
        partial: 0
    };

    return function(dispatch) {
        dispatch({ type: 'FETCH_MERCATOSURVEY_LIST' });

        return rest
            .get('mercato_surveys', { params })
            .then(
                res => dispatch({ type: 'FETCH_MERCATOSURVEY_LIST_FULFILLED', payload: res.data }),
                err => dispatch({ type: 'FETCH_MERCATOSURVEY_LIST_REJECTED', payload: err.response })
            );
    };
}

export function updateMercatoSurvey(id, mercatoSurveyPayload) {
    return function(dispatch) {
        dispatch({ type: 'UPDATE_MERCATOSURVEY', payload: id });

        return rest.put(`mercato_surveys/${id}`, mercatoSurveyPayload).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_mercatoSurveyUpdated', type: 'success' },
                });
                return dispatch({
                    type: 'UPDATE_MERCATOSURVEY_FULFILLED',
                    payload: res.data,
                });
            },
            err => {
                dispatch({
                    type: 'UPDATE_MERCATOSURVEY_REJECTED',
                    payload: err.response,
                });
                if (err.response && err.response.data.type === 'ConstraintViolationList') {
                    const { errors } = err.response.data;
                    throw new SubmissionError(errors);
                }
            }
        );
    };
}

export function addMercatoSurvey(mercatoSurveyPayload) {
    return function(dispatch) {
        dispatch({ type: `ADD_MERACTOSURVEY` });

        return rest.post('mercato_surveys', mercatoSurveyPayload).then(
            res => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_mercatoSurveyAdded', type: 'success' },
                });
                return dispatch({
                    type: `ADD_MERCATOSURVEY_FULFILLED`,
                    payload: res.data,
                });
            },
            err => {
                dispatch({
                    type: 'ADD_NOTIFICATION',
                    payload: { messageKey: 'message_mercatoSurveyAdd_error', type: 'error' },
                });
                return dispatch({
                    type: `ADD_MERCATOSURVEY_REJECTED`,
                    payload: err.response,
                });
            }
        );
    };
}

export function fetchTeams(tournamentId) {
    const params = {
        tournament: tournamentId,
        imageFilterNames: ['60x60'],
        groups: ['read_user_game', '_image'],
        'pagination[enablePagination]': 0,
        'order[mercatoBudget]': 'desc',
    };

    return function(dispatch) {
        dispatch({ type: 'FETCH_MERCATOSURVEYTEAMS_LIST' });

        return rest
            .get('teams', { params })
            .then(
                res => dispatch({ type: 'FETCH_MERCATOSURVEYTEAMS_LIST_FULFILLED', payload: res.data }),
                err => dispatch({ type: 'FETCH_MERCATOSURVEYTEAMS_LIST_REJECTED', payload: err.response })
            );
    };
}
