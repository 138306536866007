import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { updateProduct, deleteProduct } from '../actions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import Form from './Form';
import { sortProductsByType, getProductLabel } from '../utils';

const styles = theme => ({
  panel: {
    background: theme.palette.grey[100],
  },
  detail: {
    background: 'white',
    padding: 0,
    display: 'block',
  },
});

@connect()
@withStyles(styles)
export default class ProductsPanels extends Component {
  state = {
    expanded: null,
  };

  handleExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = id => data => {
    const { dispatch } = this.props;
    return dispatch(updateProduct(id, data));
  };
  handleRemove = id => () => {
    const { dispatch } = this.props;
    return dispatch(deleteProduct(id));
  };

  renderPanel = product => {
    const { classes } = this.props;
    const { expanded } = this.state;

    if (!product) return null;

    return (
      <ExpansionPanel
        expanded={expanded === product.id}
        onChange={this.handleExpand(product.id)}
        classes={{ root: classes.panel }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{getProductLabel(product)}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {expanded === product.id && (
            <Form onSubmit={this.handleChange(product.id)} data={product} onDelete={this.handleRemove(product.id)} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  render() {
    const { products = [] } = this.props;
    return sortProductsByType(products).map(this.renderPanel);
  }
}
