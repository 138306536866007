import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getLinkId } from 'Services/tools';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { fetchStatTypeOnce, fetchClubsStats, clearMemo } from '../../actions';
import fetchAllSeasons from '../../../components/seasons/actions';
import { fetchCompetition } from '../../../competitions/actions';
import TypeSelect from '../../components/TypeSelect';
import Layout from 'Components/Layout';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Ranking from './Ranking';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Select from 'Components/inputs/Select';
import Toggle from 'Components/inputs/Toggle';
import CompetitionInput from '../../../competitions/Input';
import SelectSeason from '../../../components/seasons/Select';
import AreaInput from 'FDComponents/areas/Input';
import { SimpleBreadcrumbs } from '../../../../common/components/SimpleBreadcrumbs';

const styles = theme => ({
  closeButton: {
    position: 'absolute',
    zIndex: '15',
    top: 0,
    right: 0,
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 8,
    background: theme.palette.grey[100],
  },
  rankingsContainer: {
    display: 'flex',
    margin: '0 16px',
    flexWrap: 'wrap',
  },
  input: {
    margin: '0 8px',
    position: 'relative',
    minWidth: 160,
  },
});
@withStyles(styles)
@injectIntl
@connect(store => ({
  stats: store.stats,
  seasons: store.seasons,
}))
export default class ClubsStats extends Component {
  state = {
    types: [],
    competition: null,
    season: null,
    isBigFive: null,
    country: null,
    order: 'DESC',
    itemsPerPage: 10,
    initialized: false,
    touched: false,
  };
  componentDidMount() {
    const { dispatch } = this.props;
    Promise.all([
      dispatch(fetchStatTypeOnce()),
      dispatch(fetchAllSeasons()),
      dispatch(fetchCompetition('ligue-1', 'france')),
    ])
      .then(res => {
        const { stats, seasons } = this.props;
        const types = stats.types.attacking;
        const season = seasons.list.filter(s => s.shortName == '2017')[0]['@id'];
        const competition = res[2].payload['@id'];
        return this.setState({ types, competition, season }, this.search);
      })
      .then(() => this.setState({ initialized: true }));
  }
  componentWillUnmount() {
    this.props.dispatch(clearMemo());
  }

  onChangeType = types => {
    this.setState({ types, touched: true });
  };
  onChangeCompetition = competition => {
    this.setState({ competition, touched: true });
  };
  onChangeSeason = season => {
    this.setState({ season, touched: true });
  };
  onChangeOrder = order => {
    this.setState({ order, touched: true });
  };
  onChangeItemsPerPage = itemsPerPage => {
    this.setState({ itemsPerPage, touched: true });
  };
  onChangeIsBigFive = e => {
    this.setState({ isBigFive: e.currentTarget.checked, touched: true });
  };
  onChangeCountry = country => {
    this.setState({ country, touched: true });
  };
  search = () => {
    const {
      competition,
      country,
      isBigFive,
      itemsPerPage,
      order,
      season,
      types,
    } = this.state;
    const { dispatch } = this.props;

    this.setState({ touched: false });

    const query = {
      groups: ['read_tournament_stat_team'],
      'order[value]': order,
      itemsPerPage,
    };

    if (competition) {
      query.competition = getLinkId(competition);
    }
    if (season) {
      query.season = getLinkId(season);
    }
    if (country) {
      query.country = getLinkId(country);
    }
    if (isBigFive) {
      query.isBigFive = 1;
    }

    for (let i = 0; i < types.length; i += 1) {
      dispatch(
        fetchClubsStats({
          ...query,
          'type.code': types[i].code,
        })
      );
    }
  };

  render() {
    const {
      stats,
      classes,
      intl: { messages: m },
    } = this.props;
    const {
      competition,
      country,
      isBigFive,
      itemsPerPage,
      order,
      season,
      types,
      initialized,
      touched,
    } = this.state;
    return (
      <Layout
        id="clubsstats-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_statistics' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_statsClubs}</Typography>
              <div style={{ flex: 1 }} />
              <Button
                variant="contained"
                color="primary"
                onClick={this.search}
                disabled={!touched}
              >
                {m.button_search}
              </Button>
            </Toolbar>
            {initialized ? (
              <div>
                <div className={classes.inputContainer}>
                  <div className={classes.input}>
                    <TypeSelect
                      types={stats.types}
                      value={types}
                      onChange={this.onChangeType}
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.input}>
                    <SelectSeason
                      input={{
                        name: 'season',
                        value: season,
                        onChange: this.onChangeSeason,
                      }}
                      nullable
                    />
                  </div>
                  <div className={classes.input}>
                    <Toggle
                      input={{
                        name: 'isBigFive',
                        value: isBigFive,
                        onChange: this.onChangeIsBigFive,
                      }}
                    />
                  </div>
                  <div className={classes.input}>
                    <CompetitionInput
                      margin="none"
                      input={{
                        name: 'competition',
                        value: competition,
                        onChange: this.onChangeCompetition,
                      }}
                      labelKey="filter_competition"
                    />
                    <IconButton
                      className={classes.closeButton}
                      onClick={() => this.onChangeCompetition(null)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <div className={classes.input}>
                    <AreaInput
                      input={{
                        name: 'country',
                        value: country,
                        onChange: this.onChangeCountry,
                      }}
                      labelKey="filter_country"
                    />
                    <IconButton
                      className={classes.closeButton}
                      onClick={() => this.onChangeCountry(null)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.input}>
                    <Select
                      input={{
                        name: 'order',
                        value: order,
                        onChange: this.onChangeOrder,
                      }}
                      labelKey="filter_order"
                    >
                      <MenuItem value={'DESC'}>{'DESC'}</MenuItem>)
                      <MenuItem value={'ASC'}>{'ASC'}</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.input}>
                    <Select
                      input={{
                        name: 'itemsPerPage',
                        value: itemsPerPage,
                        onChange: this.onChangeItemsPerPage,
                      }}
                      labelKey="filter_items"
                    >
                      <MenuItem value={5}>{5}</MenuItem>
                      <MenuItem value={10}>{10}</MenuItem>
                      <MenuItem value={20}>{20}</MenuItem>
                      <MenuItem value={50}>{50}</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
            ) : (
              <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />
            )}
            <div className={classes.rankingsContainer}>
              {types.map(type => (
                <Ranking type={type} stats={stats.clubs[type.code]} />
              ))}
            </div>
          </Paper>
        }
      />
    );
  }
}
