import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { setContentLive } from '../../actions';
import { selectPlayersSuggestions, selectTeamsSuggestions } from '../../selector';

const mapDispatchToProps = {
  setContentLive,
};

const mapStateToProps = (state: IRootState) => ({
  isTypeCompleted: state.live.commentary.isTypeCompleted,
  player2: state.live.commentary.player2,
  content: state.live.commentary.content,
  type: state.live.commentary.type,
  editingCommentaryId: state.live.editingCommentaryId,
  playersSuggestions: selectPlayersSuggestions(state),
  teamsSuggestions: selectTeamsSuggestions(state),
  resetCount: state.live.resetCount,
});

export type IComponentProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(reduxConnector);
