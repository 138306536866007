import { IMatchTeamPlayer } from '../matchTeamPlayers/typings';
import { IMembership } from '../memberships/typings';

export enum EPosition {
  Goalkeeper = 'goalkeeper',
  Defender = 'defender',
  WingBack = 'wing_back',
  Midfielder = 'midfielder',
  DefensiveMidfielder = 'defensive_midfielder',
  AttackingMidfielder = 'attacking_midfielder',
  Striker = 'striker',
}

export const POSITIONS = [
  EPosition.Goalkeeper,
  EPosition.Defender,
  EPosition.WingBack,
  EPosition.DefensiveMidfielder,
  EPosition.Midfielder,
  EPosition.AttackingMidfielder,
  EPosition.Striker,
];

export enum EPositionSide {
  Left = 'left',
  CentreLeft = 'centre_left',
  Centre = 'centre',
  CentreRight = 'centre_right',
  Right = 'right',
}
export const POSITION_SIDES = [
  EPositionSide.Right,
  EPositionSide.CentreRight,
  EPositionSide.Centre,
  EPositionSide.CentreLeft,
  EPositionSide.Left,
];

export interface ICompositionPositions {
  goalkeeper?: IMatchTeamPlayer[];
  defender?: IMatchTeamPlayer[];
  wing_back?: IMatchTeamPlayer[];
  defensive_midfielder?: IMatchTeamPlayer[];
  midfielder?: IMatchTeamPlayer[];
  attacking_midfielder?: IMatchTeamPlayer[];
  striker?: IMatchTeamPlayer[];
}
export interface ITeamComposition {
  positions: ICompositionPositions;
  coach?: IMembership;
  substitutes: IMatchTeamPlayer[];
}
export interface IComposition {
  home: ITeamComposition;
  away: ITeamComposition;
}
