import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { IPhase } from '../typings';
import { getPhaseHref } from '../utils';

interface IProps {
  phase: IPhase;
  level: 0 | 1 | 2;
}

export class PhaseToolbar extends Component<IProps> {
  public render() {
    const { phase, level } = this.props;
    if (!phase) {
      return null;
    }
    const { variant, background, color } = [
      {
        variant: 'h5' as 'h5',
        background: undefined,
        color: 'textSecondary' as 'textSecondary',
      },
      {
        variant: 'subtitle1' as 'subtitle1',
        background: '#edecec',
        color: 'inherit' as 'inherit',
      },
      {
        variant: 'body2' as 'body2',
        background: '#f5f5f5',
        color: 'inherit' as 'inherit',
      },
    ][level];

    return (
      <Toolbar style={{ background }}>
        <Typography variant={variant} color={color}>
          {phase.longName || phase.name}
        </Typography>
        <div style={{ flex: 1 }} />
        <IconButton href={getPhaseHref(phase)}>
          <InfoIcon />
        </IconButton>
      </Toolbar>
    );
  }
}
