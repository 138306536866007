import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusDeleteIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g>
        <rect fill="#221f20" x="0" y="0" width="24" height="24" />
        <polygon fill="#fff" points="2,2 12,12 22,2" />
        <polygon fill="#fff" points="2,22 12,12 22,22" />
      </g>
    </SvgIcon>
  );
}
