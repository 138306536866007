import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { getLinkId } from '../../../common/services/tools';
import { AgentsColumn } from '../../people/agents/components/AgentsColumn';
import { PlayersColumn } from '../../people/players/components/PlayersColumn';
import { AgentCompanyForm } from '../components/AgentCompanyForm.component';
import { IAgentCompany } from '../typings';
import { IComponentProps } from './AgentCompanyDetail.connector';
export class AgentCompanyDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_agentCompanyInfo', link: '/' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    if (next.id !== this.props.id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      agentCompany,
      match,
      history,
      intl: { messages: m },
      players,
      agents,
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!agentCompany) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="agent-company-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_agentCompanies',
                href: `#${getProject().url}/agent-companies`,
              },
              { label: agentCompany.name },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{agentCompany.name || `${m.app_agentCompany}: ${agentCompany.id}`}</Typography>
              <div style={{ flex: 1 }} />
              <ReturnButton entity="agent-companies" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderInfo} />
          </Paper>
        }
        right={
          <React.Fragment>
            <Paper elevation={2}>
              <PlayersColumn
                players={players}
                onRemove={this.handleUnsetPlayerAgentCompany}
                onSelect={this.handleSetPlayerAgentCompany}
              />
            </Paper>
            <Paper elevation={2} style={{ marginTop: 16 }}>
              <AgentsColumn
                agents={agents}
                onRemove={this.handleUnsetAgentAgentCompany}
                onSelect={this.handleSetAgentAgentCompany}
              />
            </Paper>
          </React.Fragment>
        }
      />
    );
  }

  private renderInfo = () => {
    const { agentCompany } = this.props;
    return <AgentCompanyForm onSubmit={this.handleChange} data={agentCompany} />;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchAgentCompany, fetchAgentCompanyPlayers, fetchAgentCompanyAgents } = this.props;
    // @ts-ignore
    fetchAgentCompany(targetId || id).then(({ payload }) => {
      if (payload) {
        fetchAgentCompanyPlayers(payload.id);
        fetchAgentCompanyAgents(payload.id);
      }
    });
  };

  private handleChange = (agentCompany: IAgentCompany) => {
    const { updateAgentCompany, id } = this.props;
    return updateAgentCompany(id, agentCompany);
  };

  private handleSetPlayerAgentCompany = (newPlayerAId: string) => {
    const { agentCompany, updatePlayer, fetchAgentCompanyPlayers } = this.props;
    const playerId = getLinkId(newPlayerAId);
    // @ts-ignore
    return updatePlayer(playerId, { agentCompany: agentCompany['@id'] }).then(
      fetchAgentCompanyPlayers(agentCompany.id)
    );
  };

  private handleUnsetPlayerAgentCompany = (oldPlayerId: string) => {
    const { agentCompany, updatePlayer, fetchAgentCompanyPlayers } = this.props;
    const playerId = getLinkId(oldPlayerId);
    // @ts-ignore
    return updatePlayer(playerId, { agentCompany: null }).then(fetchAgentCompanyPlayers(agentCompany.id));
  };

  private handleSetAgentAgentCompany = (newPlayerAId: string) => {
    const { agentCompany, updatePlayer, fetchAgentCompanyAgents } = this.props;
    const playerId = getLinkId(newPlayerAId);
    // @ts-ignore
    return updatePlayer(playerId, { agentCompany: agentCompany['@id'] }).then(fetchAgentCompanyAgents(agentCompany.id));
  };

  private handleUnsetAgentAgentCompany = (oldPlayerId: string) => {
    const { agentCompany, updatePlayer, fetchAgentCompanyAgents } = this.props;
    const playerId = getLinkId(oldPlayerId);
    // @ts-ignore
    return updatePlayer(playerId, { agentCompany: null }).then(fetchAgentCompanyAgents(agentCompany.id));
  };
}
