import {
  createBaseReducer,
  createDetailReducer,
} from '../../common/services/reducerCreator';

export const matchEventReducer = createDetailReducer({
  modelName: 'MATCHEVENT',
  inside: {
    match: { get: 'matchEvents', set: 'matchEvent' },
  },
});

export const cardReducer = createBaseReducer({
  modelName: 'CARD',
});
export const goalReducer = createBaseReducer({
  modelName: 'GOAL',
});
export const substituteReducer = createBaseReducer({
  modelName: 'SUBSTITUTE',
});
export const missedPenaltyReducer = createBaseReducer({
  modelName: 'MISSEDPENALTY',
});
export const penaltyShotReducer = createBaseReducer({
  modelName: 'PENALTYSHOT',
});
