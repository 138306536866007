import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {getCollection, getObject} from "../../../common/services/connectors";
import { IInput } from "../../components/InputCollection/InputCollection.component";
import { fetchCompetitionTournaments } from "../../tournaments/actions";
import { ITournament } from "../../tournaments/typings";


interface IOwnProps {
    meta: any;
    input: IInput;
    intl: any;
    tournaments: ITournament[];

}

const mapDispatchToProps = {
    fetchCompetitionTournaments,
};


const mapStateToProps = (state) => ({
    tournaments: getCollection('tournamentsIds', state.competition, state.tournament),
    phase: getObject('phaseId', state.tournament, state.phase),
})
export type IComponentProps = typeof mapDispatchToProps & InjectedIntlProps & IOwnProps;

const reduxConnector = connect(
    mapStateToProps,
    mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
    reduxConnector,
    injectIntl
);
