import * as React from 'react';
import { InputCollection } from '../../components/InputCollection';
import { IInput } from '../../components/InputCollection/InputCollection.component';
import { searchTeams } from '../actions';
import { getTeamLabel } from '../utils';
import { TeamCollectionChip } from './TeamCollectionChip.component';
interface IProps {
  meta: any;
  input: IInput;
}
export class TeamInputCollection extends React.Component<IProps> {
  public render() {
    const { input, meta } = this.props;

    return (
      // @ts-ignore
      <InputCollection
        input={input}
        meta={meta}
        search={searchTeams}
        getLabel={getTeamLabel}
        CollectionChip={TeamCollectionChip}
      />
    );
  }
}
