import rest from 'Services/restFootMercato';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchPageseos(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PAGESEOS' });

    return rest
      .get('page_seos', { params })
      .then(
        res => dispatch({ type: 'FETCH_PAGESEOS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PAGESEOS_REJECTED', payload: err.response })
      );
  };
}

export function fetchPageseo(id) {
  return function(dispatch) {
    dispatch({ type: 'FETCH_PAGESEO' });

    return rest
      .get(`page_seos/${id}`)
      .then(
        res => dispatch({ type: 'FETCH_PAGESEO_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PAGESEO_REJECTED', payload: err.response })
      );
  };
}

export function fetchConstantsSeo() {
  return function(dispatch) {
    dispatch({ type: 'FETCH_CONSTANTSSEO' });

    return rest
      .get(`constants/seo`)
      .then(
        res =>
          dispatch({
            type: 'FETCH_CONSTANTSSEO_FULFILLED',
            payload: { collection: res.data },
          }),
        err => dispatch({ type: 'FETCH_CONSTANTSSEO_REJECTED', payload: err.response })
      );
  };
}

export function updatePageseo(id, pageseo) {
  return function(dispatch) {
    dispatch({ type: 'UPDATE_PAGESEO', payload: id });
    return rest.put(`page_seos/${id}`, pageseo).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_pageseoUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_PAGESEO_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { description, errors } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: description, type: 'error' },
          });
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PAGESEO_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addPageseo(pageseo) {
  return function(dispatch) {
    dispatch({ type: 'ADD_PAGESEO' });

    return rest.post('page_seos', pageseo).then(
      res => dispatch({ type: 'ADD_PAGESEO_FULFILLED', payload: res.data }),
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { description } = err.response.data;
          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: { message: description, type: 'error' },
          });
        }
        return dispatch({ type: 'ADD_PAGESEO_REJECTED', payload: err });
      }
    );
  };
}

export function deletePageseo(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_PAGESEO', payload: id });
    return rest
      .delete(`page_seos/${id}`)
      .then(
        () => dispatch({ type: 'DELETE_PAGESEO_FULFILLED', payload: { id } }),
        err => dispatch({ type: 'DELETE_PAGESEO_REJECTED', payload: err.response })
      );
  };
}
