import React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';

function Text({
  input: { name, value, onChange, ...inputEvents },
  labelKey,
  intl: { formatMessage: f },
  meta: { error, touched },
  hintText,
  startAdornment,
  endAdornment,
  helperKey,
  ...custom
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });
  return (
    <TextField
      label={label}
      error={error && touched}
      helperText={((touched && error) || helperKey) && f({ id: (touched && error) || helperKey })}
      InputProps={{
        placeholder: hintText || label,
        startAdornment,
        endAdornment,
      }}
      name={name}
      value={value}
      {...inputEvents}
      onChange={event => onChange(event.target.value || '')}
      {...custom}
      fullWidth
    />
  );
}

export default injectIntl(Text);
