import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { parseQuery } from '../../../common/services/parseQuery';
import { IRootState } from '../../../typings';
import { addPartner, fetchPartners } from '../actions';

type IOwnProps = RouteComponentProps;

const mapDispatchToProps = {
  fetchPartners,
  addPartner,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  me: state.me,
  partners: state.partnerList.list,
  pagination: state.partnerList.pagination,
  fetching: state.partnerList.fetching,
  total: state.partnerList.totalItems,
  query: parseQuery(ownProps.location.search),
});

export type IComponentProps = typeof mapDispatchToProps &
  // eslint-disable-next-line no-undef
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
