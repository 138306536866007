import rest from 'Services/restFootMercato';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

export function fetchLiveTransfers(search) {
  const params = {
    itemsPerPage: 50,
    partial: 0,
    'tag.keyCode': 'live_transfers',
    groups: ['read', '_authors'],
    ...qs.parse(search),
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BRIEFS' });

    return rest.get('briefs', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_BRIEFS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_BRIEFS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchProposalLiveTransfers(search) {
  const params = {
    itemsPerPage: 5,
    status: 'proposal',
    'order[createdAt]': 'desc',
    partial: 0,
    groups: ['read', '_authors', '_tags'],
    ...search,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_BRIEFS_PROPOSAL' });
    return rest.get('briefs', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_BRIEFS_PROPOSAL_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_BRIEFS_PROPOSAL_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchLiveTransfer(id) {
  const params = {
    groups: ['read', '_tags', '_authors'],
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_BRIEF' });

    return rest.get(`briefs/${id}`, { params }).then(
      res => dispatch({ type: 'FETCH_BRIEF_FULFILLED', payload: res.data }),
      err =>
        dispatch({
          type: 'FETCH_BRIEF_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function updateBrief(id, brief) {
  const params = {
    groups: ['read', '_tags', '_authors'],
  };
  return function(dispatch) {
    dispatch({ type: 'UPDATE_BRIEF', payload: id });
    return rest.put(`briefs/${id}`, brief, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_briefUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_BRIEF_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_BRIEF_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addLiveTransfer(brief, target = '') {
  const entity = target ? `_${target.toUpperCase()}` : target;

  return function(dispatch) {
    dispatch({ type: `ADD${entity}_BRIEF` });

    return rest
      .post('briefs', brief)
      .then(
        res => dispatch({ type: `ADD${entity}_BRIEF_FULFILLED`, payload: res.data }),
        err => dispatch({ type: `ADD${entity}_BRIEF_REJECTED`, payload: err.response })
      );
  };
}

export function deleteLiveTransfer(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_BRIEF', payload: id });
    return rest.delete(`briefs/${id}`).then(
      () => dispatch({ type: 'DELETE_BRIEF_FULFILLED', payload: { id } }),
      err =>
        dispatch({
          type: 'DELETE_BRIEF_REJECTED',
          payload: err.response,
        })
    );
  };
}
