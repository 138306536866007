import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function StatisticIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M457 89l-368 0 0 368-17 0 0-368-17 0 0-17 17 0 0-17 17 0 0 17 368 0z m-349 285l60 0 0-269-60 0z m172-67l60 0 0-202-60 0z m87-160l59 0 0-42-59 0z m-173 59l60 0 0-101-60 0z"
        />
      </g>
    </SvgIcon>
  );
}
