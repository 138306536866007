import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React from 'react';
import { Route } from 'react-router-dom';
import { IdBadge } from '../../../common/components/IdBadge';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import { SurveyForm } from '../components/SurveyForm';
import { SurveyStat } from '../Stats/SurveyStat.component';
import { ISurvey } from '../typings';
import { IComponentProps } from './SurveyDetail.connector';

export class SurveyDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_info', link: '/' }, { label: 'app_statistics', link: '/stats' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      survey,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!survey) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="survey-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_surveys', href: `#${getProject().url}/surveys?locale=${getProject().lang}` },
              { label: survey.question },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{survey.question}</Typography>
              <IdBadge id={survey.id} />
              <div style={{ flex: 1 }} />
              <Button
                onClick={this.handleDuplicateClick}
                variant="contained"
                color="primary"
                style={{ marginRight: 8 }}
              >
                {m.survey_duplicate}
              </Button>
              <ReturnButton entity="surveys" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
            <Route exact path={`${match.url}/stats`} render={this.renderStats} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { survey } = this.props;
    return <SurveyForm onSubmit={this.handleUpdate} data={survey} />;
  };

  private renderStats = () => {
    const { survey, intl, fetchAnalyticStat } = this.props;
    return <SurveyStat survey={survey} intl={intl} fetchAnalyticStat={fetchAnalyticStat} />;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchSurvey } = this.props;
    fetchSurvey(targetId || id);
  };

  private handleUpdate = (product: ISurvey) => {

    const { id, updateSurvey } = this.props;
    return updateSurvey(id, product);
  };

  private handleDuplicateClick = () => {
    // tslint:disable-next-line:no-shadowed-variable
    const { id, duplicateSurvey, history } = this.props;

    // @ts-ignore
    duplicateSurvey(id).then(({ payload }) => {
      if (payload && payload.id) {
        history.push(`${getProject().url}/surveys/${payload.id}`);
      }
    });
  };
}
