import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getProject } from 'Services/project';
import DeleteButton from '../../../common/components/DeleteButton';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { UserRestriction } from '../../../common/components/UserRestiction';
import { deleteCorrection, fetchCorrection } from '../actions';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  correction: store.correction.detail[props.match.params.topicId],
  fetching: store.correction.fetching,
}))
export default class Detail extends Component {
  menu = [{ label: 'app_correctionInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchCorrection(targetId || id));
  };

  handleRemove = () => {
    const { correction, dispatch, history } = this.props;
    return dispatch(deleteCorrection(correction.id)).then(() => {
      history.push(`${getProject().url}/corrections`);
    });
  };

  formatDate = date => moment(date).format('L');

  render() {
    const {
      fetching,
      correction,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!correction) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="correction-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              {
                labelKey: 'app_corrections',
                href: `#${getProject().url}/corrections`,
              },
              { label: correction.id },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">
                {`${m.app_correctionTitle} ${this.formatDate(correction.createdAd)}`}
              </Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <DeleteButton onRemove={this.handleRemove} />
              </UserRestriction>
              <ReturnButton entity="corrections" />
            </Toolbar>

            <TabsRoute match={match} menu={this.menu} history={history} />

            <Route exact path={match.url} render={() => <Form data={correction} />} />
          </Paper>
        }
      />
    );
  }
}
