import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import Form from './Form';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

@injectIntl
@withRouter
@connect(store => ({
  fetching: store.author.fetching,
  author: store.author.detail[store.author.selectedId],
}))
export default class Author extends Component {
  componentDidMount() {
    this.props.fetchDataAuthor();
  }

  render() {
    const {
      author,
      fetching,
      user,
      addAuthor,
      updateAuthor,
      intl: { messages: m },
    } = this.props;

    const { username, firstname, lastname } = user;

    if (fetching)
      return (
        <div style={{ textAlign: 'center', padding: '60px 0' }}>
          <CircularProgress />
        </div>
      );

    return (
      <div>
        <Toolbar>
          <Typography variant="h6">
            {author ? m.app_userAuthorTitle : m.app_createUserAuthorTitle}
          </Typography>
          <div style={{ flex: 1 }} />
        </Toolbar>

        <Form
          onSubmit={author ? updateAuthor : addAuthor}
          data={author || { publicName: `${firstname} ${lastname}`, username }}
        />
      </div>
    );
  }
}
