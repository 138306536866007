import React from 'react';
import { injectIntl } from 'react-intl';
import Input from '@material-ui/core/Input';

function FileInput({
  input: { name, value: omitValue, onChange, onBlur, ...inputProps },
  labelKey,
  intl: { formatMessage: f },
  meta: omitMeta,
  hintText,
  ...custom
}) {
  const label = labelKey ? f({ id: labelKey }) : f({ id: `field_${name}` });

  const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

  omitValue;
  omitMeta;

  return (
    <Input
      label={label}
      placeholder={hintText || label}
      inputProps={inputProps}
      onChange={adaptFileEventToValue(onChange)}
      onBlur={adaptFileEventToValue(onBlur)}
      type="file"
      fullWidth
      {...custom}
    />
  );
}

export default injectIntl(FileInput);
