import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Layout from '../../../common/components/Layout';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getCompetitionHref, getCompetitionsHref } from '../../competitions/utils';
import { getTournamentHref } from '../../tournaments/utils';
import { fetchPhaseWithTournament, updatePhase } from '../actions';
import Form from './Form';

@injectIntl
@connect((store, props) => ({
  id: props.match.params.topicId,
  phase: store.phase.detail[props.match.params.topicId],
  fetching: store.phase.fetching,
}))
export default class Detail extends Component {
  menu = [{ label: 'app_phaseInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchPhaseWithTournament(targetId || id));
  };

  handleChange = phase => {
    const { dispatch, id } = this.props;
    return dispatch(updatePhase(id, phase));
  };

  getPhaseLabel = () => {
    const { phase } = this.props;
    return phase.longName || phase.name || phase.slug;
  };
  getTournamentLabel = () => {
    const { phase } = this.props;
    return phase.tournament ? phase.tournament.name : '';
  };
  getCompetitionLabel = () => {
    const { phase } = this.props;
    return phase.tournament ? phase.tournament.competitionName : '';
  };
  getLabel = () =>
    [this.getCompetitionLabel(), this.getTournamentLabel(), this.getPhaseLabel()].filter(Boolean).join(' / ');

  render() {
    const {
      phase,
      match,
      history,
      fetching,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!phase || !phase.tournament) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="phase-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[
              { labelKey: 'app_competitions', href: getCompetitionsHref() },
              {
                label: this.getCompetitionLabel(),
                href: getCompetitionHref(phase.tournament.competitionAreaSlug, phase.tournament.competitionSlug),
              },
              { labelKey: 'app_tournaments' },
              {
                label: this.getTournamentLabel(),
                href: getTournamentHref(phase.tournament),
              },
              { labelKey: 'app_phases' },
              { label: this.getPhaseLabel() },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{this.getLabel()}</Typography>
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={() => <Form onSubmit={this.handleChange} data={phase} />} />
          </Paper>
        }
      />
    );
  }
}
