import rest from 'Services/restFootData';
import { SubmissionError } from 'redux-form';
import qs from 'query-string';

const detailGroups = ['read', '_brand', '_club', '_image'];

export function getProduct(id) {
  const params = {
    groups: detailGroups,
  };
  return function(dispatch) {
    dispatch({ type: 'GET_PRODUCT' });

    return rest
      .get(`products/${id}`, { params })
      .then(
        res => dispatch({ type: 'GET_PRODUCT_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'GET_PRODUCT_REJECTED', payload: err.response })
      );
  };
}

export function searchProducts(searchObj) {
  const params = {
    itemsPerPage: 10,
    ...searchObj,
  };

  return function(dispatch) {
    dispatch({ type: 'SEARCH_PRODUCTS' });

    return rest
      .get('products', { params })
      .then(
        res => dispatch({ type: 'SEARCH_PRODUCTS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'SEARCH_PRODUCTS_REJECTED', payload: err.response })
      );
  };
}

export function fetchProducts(search) {
  const params = {
    itemsPerPage: 10,
    ...qs.parse(search),
    partial: 0,
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PRODUCTS' });

    return rest
      .get('products', { params })
      .then(
        res => dispatch({ type: 'FETCH_PRODUCTS_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_PRODUCTS_REJECTED', payload: err.response })
      );
  };
}

export function updateProduct(id, product) {
  const params = {
    groups: detailGroups,
  };

  return function(dispatch) {
    dispatch({ type: 'UPDATE_PRODUCT', payload: id });

    return rest.put(`products/${id}`, product, { params }).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_productUpdated', type: 'success' },
        });
        return dispatch({
          type: 'UPDATE_PRODUCT_FULFILLED',
          payload: res.data,
        });
      },
      err => {
        if (err.response && err.response.data.type === 'ConstraintViolationList') {
          const { errors } = err.response.data;
          throw new SubmissionError(errors);
        }
        return dispatch({
          type: 'UPDATE_PRODUCT_REJECTED',
          payload: err.response,
        });
      }
    );
  };
}

export function addProduct(product, target = '') {
  const entity = target ? `_${target.toUpperCase()}` : target;
  return function(dispatch) {
    dispatch({ type: `ADD${entity}_PRODUCT` });

    return rest.post('products', product).then(
      res => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_productAdded', type: 'success' },
        });
        return dispatch({
          type: `ADD${entity}_PRODUCT_FULFILLED`,
          payload: res.data,
        });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_productAdd_error', type: 'error' },
        });
        return dispatch({
          type: `ADD${entity}_PRODUCT_REJECTED`,
          payload: err.response,
        });
      }
    );
  };
}

export function deleteProduct(id) {
  return function(dispatch) {
    dispatch({ type: 'DELETE_PRODUCT' });

    return rest.delete(`products/${id}`).then(
      () => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_productDeleted', type: 'success' },
        });
        return dispatch({ type: 'DELETE_PRODUCT_FULFILLED', payload: { id } });
      },
      err => {
        dispatch({
          type: 'ADD_NOTIFICATION',
          payload: { messageKey: 'message_productDelete_error', type: 'error' },
        });
        return dispatch({ type: 'DELETE_PRODUCT_REJECTED', payload: err.response });
      }
    );
  };
}

export function fetchClubProducts(club) {
  const params = {
    club,
    groups: ['read', '_image'],
    'order[season.name]': 'DESC',
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_CLUB_PRODUCTS', payload: club });

    return rest.get('products', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_CLUB_PRODUCTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_CLUB_PRODUCTS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchPlayerProducts(persons) {
  const params = {
    persons,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PLAYER_PRODUCTS', payload: persons });

    return rest.get('products', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PLAYER_PRODUCTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PLAYER_PRODUCTS_REJECTED',
          payload: err.response,
        })
    );
  };
}

export function fetchCompetitionProducts(competition) {
  const params = {
    competition,
    groups: ['read', '_image'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_PRODUCTS', payload: competition });

    return rest.get('products', { params }).then(
      res =>
        dispatch({
          type: 'FETCH_PRODUCTS_FULFILLED',
          payload: res.data,
        }),
      err =>
        dispatch({
          type: 'FETCH_PRODUCTS_REJECTED',
          payload: err.response,
        })
    );
  };
}
