import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Layout from 'Components/Layout';
import ReturnButton from 'Components/ReturnButton';
import TabsRoute from 'Components/TabsRoute';
import moment from 'moment';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getFMProject, getProject } from 'Services/project';
import { cleanMarkdown } from '../../../../common/components/inputs/TextEdit/utils';
import { ArticleStatusIcon } from '../../../articles/components/ArticleStatusIcon';
import TopBar from '../../../components/TopBar';
import { fetchLiveTransfer, updateBrief } from '../actions';
import Form from './Form';
import { Button } from '@material-ui/core';
import { briefToFlash } from 'FM/articles/actions';

const styles = theme => ({
  titleFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  status: {
    marginLeft: theme.spacing(1),
  },
});

@injectIntl
@withStyles(styles)
@connect((store, props) => ({
  id: props.match.params.topicId,
  liveTransfer: store.brief.detail[props.match.params.topicId],
  fetching: store.brief.fetching,
}))
export default class Detail extends Component {
  menu = [{ label: 'app_liveTransferInfo', link: '/' }];

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (next.id != this.props.id) {
      this.fetchData(next.id);
    }
  }

  fetchData = targetId => {
    const { id, dispatch } = this.props;
    dispatch(fetchLiveTransfer(targetId || id));
  };

  handleLiveTransferUpdate = changes => {
    const { dispatch, liveTransfer } = this.props;

    if (changes.content) {
      changes.content = cleanMarkdown(changes.content);
    }

    return dispatch(updateBrief(liveTransfer.id, changes));
  };

  handleGenerateFlashFromBrief = () => {
    const { dispatch, liveTransfer, history } = this.props;

    dispatch(briefToFlash(liveTransfer.id)).then(({ payload }) => {
      if (payload && payload.id) {
        const urlFM = getFMProject(getProject().lang).url;
        history.push(`${urlFM}/articles/${payload.id}`);
      }
    });
  };

  render() {
    const {
      fetching,
      liveTransfer,
      match,
      history,
      classes,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!liveTransfer) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="liveTransfer-layout"
        top={
          <TopBar
            crumbStack={[
              {
                labelKey: 'app_liveTransfer',
                href: `#${getProject().url}/live-transfers?publishedAt=${moment().format('YYYY-MM-DD')}`,
              },
              { label: liveTransfer.title },
            ]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6" className={classes.titleFlex}>
                <ArticleStatusIcon status={liveTransfer.status} />
                <div className={classes.status}>{liveTransfer.title}</div>
              </Typography>
              <div style={{ flex: 1 }} />
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleGenerateFlashFromBrief}
                B
                style={{ marginRight: 8 }}
              >
                {m.button_generateFlashArticleFromBrief}
              </Button>
              <ReturnButton className={classes.leftButton} entity="live-transfers" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route
              exact
              path={match.url}
              render={() => <Form onSubmit={this.handleLiveTransferUpdate} data={liveTransfer} />}
            />
          </Paper>
        }
      />
    );
  }
}
