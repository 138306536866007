import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { selectMatchCommentaries } from '../../../../matches/selector';
import { syncChronoToCommentaryLive } from '../../actions';
import { createLiveCommentary, recoverLiveCommentary, updateLiveCommentary } from '../../actions/liveActions';
import { isActionEnable } from '../../selector';

const mapDispatchToProps = {
  syncChronoToCommentaryLive,
  createLiveCommentary,
  updateLiveCommentary,
  recoverLiveCommentary,
};

interface IOwnProps {}

const mapStateToProps = (state: IRootState) => {
  return {
    editingCommentaryId: state.live.editingCommentaryId,
    isSubmitButtonEnable: state.live.commentary.isTypeCompleted && isActionEnable(state),
    lastCommentary: selectMatchCommentaries(state, state.live.matchId)[0],
  };
};

export type IComponentProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps> & IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(reduxConnector);
