import React, { Component } from 'react';
import { getProject } from 'Services/project';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { fetchReferees } from '../actions';
import filterList from 'Actions/lists';

import { TableList } from '../../../../common/components/TableList';
import Row from './Row';

import AreaInput from 'FDComponents/areas/Input';

import Layout from 'Components/Layout';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { SimpleBreadcrumbs } from '../../../../common/components/SimpleBreadcrumbs';

import debounce, { getLinkId } from 'Services/tools';
import queryReducer from 'Services/queriesReducer';
import { parseQuery, stringifyQuery } from 'Services/parseQuery';
import { SearchFilter } from '../../../../common/components/SearchFilter';

@injectIntl
@connect((store, props) => ({
  referees: store.referees.list,
  fetching: store.referees.fetching,
  pagination: store.referees.pagination,
  total: store.referees.totalItems,
  query: parseQuery(props.location.search),
}))
export default class RefereesList extends Component {
  config = {
    filters: [
      {
        type: 'name',
        labelKey: 'filter_name',
      },
      {
        type: 'mainNationality',
        input: AreaInput,
        labelKey: 'filter_nationality',
      },
    ],
    headers: [
      { id: 'translations.lastName', labelKey: 'field_lastName' },
      {
        id: 'mainNationality.translations.name',
        labelKey: 'field_mainNationality',
      },
    ],
  };

  componentWillUnmount() {
    debounce(null, null, true);
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.search === '') {
      this.changeParams({ type: 'SET_SORT', payload: 'translations.lastName' });
    }
    if (location.search !== '') {
      this.fetchData(location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (nextProps.location.search === '') {
      this.changeParams({ type: 'SET_SORT', payload: 'translations.lastName' });
    }

    if (
      prevProps.location.search !== nextProps.location.search &&
      nextProps.location.search !== ''
    ) {
      this.fetchData(nextProps.location.search);
    }
  }

  fetchData = filter => {
    const { dispatch } = this.props;
    dispatch(fetchReferees(filter));
    dispatch(filterList('referees', filter));
  };

  handleFilters = change => {
    const { name, mainNationality } = change;
    const filtersQuery = {
      name,
      mainNationality: getLinkId(mainNationality),
    };
    this.changeParams({ type: 'SET_FILTER', payload: filtersQuery });
  };
  handlePagination = change => {
    if (change.page) {
      this.changeParams({ type: 'SET_PAGE', payload: change.page });
    } else {
      this.changeParams({ type: 'SET_ITEMS', payload: change.itemsPerPage });
    }
  };
  handleSort = field => {
    this.changeParams({ type: 'SET_SORT', payload: field });
  };
  changeParams = debounce(action => {
    const { history, query, location } = this.props;
    const newParams = queryReducer(query, action);
    const search = stringifyQuery(newParams);

    location.search != search && location.search === ''
      ? history.replace(`${getProject().url}/referees${search}`)
      : history.push(`${getProject().url}/referees${search}`);
  }, 500);

  render() {
    const {
      referees,
      fetching,
      pagination,
      total,
      query,
      intl: { messages: m },
    } = this.props;

    return (
      <Layout
        id="referees-layout"
        top={<SimpleBreadcrumbs crumbStack={[{ labelKey: 'app_referees' }]} />}
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{m.app_refereesList}</Typography>
            </Toolbar>
            <SearchFilter
              form="players_filters"
              filters={this.config.filters}
              onChange={this.handleFilters}
              initialValues={query.filters}
            />
            <TableList
              tableData={referees}
              tableHeaders={this.config.headers}
              query={query}
              onSort={this.handleSort}
              Row={Row}
              loading={fetching}
              pagination={pagination}
              total={total}
              onPaginate={this.handlePagination}
            />
          </Paper>
        }
      />
    );
  }
}
