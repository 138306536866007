import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const styleSheet = {
  success: {
    backgroundColor: green[500],
  },
  warn: {
    backgroundColor: amber[500],
  },
  error: {
    backgroundColor: red[500],
  },
  twitter: {
    backgroundColor: '#1da1f2',
  },
  facebook: {
    backgroundColor: '#4267b2',
  },
};

@injectIntl
@withStyles(styleSheet)
@connect(store => ({
  notifications: store.notifications,
}))
// TODO: implement Consecutive Snackbars
export default class Notification extends Component {
  state = {
    open: false,
  };

  UNSAFE_componentWillReceiveProps() {
    this.handleOpen();
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      notifications,
      classes,
      intl: { messages: m },
    } = this.props;

    const { message, messageKey, type } = notifications;
    const msg = message || m[messageKey];
    if (!msg) return null;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        ContentProps={{ className: classes[type] }}
        open={this.state.open}
        message={msg}
        autoHideDuration={4000}
        onClose={this.handleClose}
      />
    );
  }
}
