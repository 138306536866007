import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import {fetchPartnerOffers} from "../../actions";
import {IRootState} from "../../../../typings";
import {parseQuery} from "../../../../common/services/parseQuery";

type IOwnProps = RouteComponentProps;

const mapDispatchToProps = {
  fetchPartnerOffers,
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  me: state.me,
  partnerOffers: state.partnerOfferList.list,
  pagination: state.partnerOfferList.pagination,
  fetching: state.partnerOfferList.fetching,
  total: state.partnerOfferList.totalItems,
  query: parseQuery(ownProps.location.search),
});

export type IComponentProps = typeof mapDispatchToProps &
  // eslint-disable-next-line no-undef
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  IOwnProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, IOwnProps>(
  reduxConnector,
  injectIntl
);
