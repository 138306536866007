import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  card: {
    width: 220,
  },
  switchContainer: {
    backgroundColor: theme.palette.grey[300],
    paddingLeft: 16,
    paddingRight: 16,
  },
  formControl: {
    marginRight: 0,
  }
});
