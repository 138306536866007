import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getPersonUsualName } from '../../people/utils';

@injectIntl
export default class Crumb extends Component {
  state = {
    open: false,
    value: '',
  };

  handleFilter = event => {
    this.setState({
      value: event.target.value,
    });
  };

  handleTouchTap = event => {
    const { onOpen } = this.props;
    onOpen && onOpen();
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleMenu = event => {
    this.setState({
      open: false,
    });
    this.props.onSelect(JSON.parse(event.target.getAttribute('value')));
  };

  render() {
    const {
      source,
      entity,
      disabled,
      crumb,
      filter,
      intl: { messages: m },
    } = this.props;
    const { open, value } = this.state;

    return [
      <Button
        style={{ backgroundColor: 'rgb(245, 245, 245)' }}
        onClick={this.handleTouchTap}
        disabled={disabled}
      >
        {(crumb && (crumb.name || getPersonUsualName(crumb))) || m[`field_${entity}`]}
      </Button>,
      <Popover
        open={open}
        anchorEl={this.state.anchorEl}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {filter && (
          <div style={{ margin: '0 16px' }}>
            <Input
              name="filter"
              key={`filter-input${entity}`}
              autoFocus
              type="text"
              placeholder={m[`filter_${entity}`]}
              fullWidth
              value={value}
              onChange={this.handleFilter}
            />
          </div>
        )}
        <div style={{ maxHeight: 500, overflow: 'auto' }}>
          {source &&
            [...source]
              .filter(
                item =>
                  !value || item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
              )
              .map(item => (
                <MenuItem dense onClick={this.handleMenu} value={JSON.stringify(item)}>
                  {item.name || getPersonUsualName(item)}
                </MenuItem>
              ))}
        </div>
      </Popover>,
    ];
  }
}
