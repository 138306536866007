import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import Button from '@material-ui/core/Button';

import ConfirmDialog from '../dialogs/Confirm';
import RevisionDiff from './Diff';
import CircularProgress from '@material-ui/core/CircularProgress';

import { fetchRevisions, rollbackRevision } from './actions';

const styles = theme => ({
  block: {
    padding: 32,
    margin: '8px 0',
    display: 'flex',
    background: 'rgba(34, 119, 239, 0.1)',
    position: 'relative',
  },
  list: {
    maxWidth: 300,
    background: 'rgba(34, 119, 239, 0.2)',
  },
  revision: {
    flex: '1',
    padding: '0 16px',
    marginLeft: 16,
  },
  button: {
    marginTop: 16,
    textAlign: 'right',
  },
  selectedItem: {
    borderLeft: `4px solid ${theme.palette.primary[500]}`,
  },
  item: {
    borderLeft: '4px solid transparent',
  },
  refresh: {
    width: 50,
    height: 50,
    margin: 'auto',
  },
});

@withStyles(styles)
@connect((store, props) => ({
  revisions: store.revisions[props.entity][props.id],
}))
export default class Block extends Component {
  state = {
    selectedRev: null,
  };

  componentDidMount() {
    this.getData();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updatedAt !== this.props.updatedAt) {
      this.getData();
    }
  }
  getData = () => {
    const { dispatch, entity, id } = this.props;
    dispatch(fetchRevisions(entity, id));
  };

  formatDate = date => moment(date).calendar(null, { sameElse: 'lll' });

  showDiff = event => {
    this.setState({ selectedRev: event.currentTarget.dataset.index });
  };
  rollback = () => {
    const { dispatch, revisions, onRollback } = this.props;
    const { selectedRev } = this.state;

    dispatch(rollbackRevision(revisions.list[selectedRev].id)).then(onRollback);
  };

  render() {
    const { revisions, classes } = this.props;
    const { selectedRev } = this.state;

    return (
      <div className={classes.block}>
        {revisions && !revisions.fetched && (
          <CircularProgress className={classes.refresh} />
        )}
        {revisions && revisions.list && (
          <List
            className={classes.list}
            subheader={<ListSubheader>Révisions</ListSubheader>}
          >
            {revisions.list.map((rev, index) => (
              <ListItem
                key={rev.id}
                className={index == selectedRev ? classes.selectedItem : classes.item}
                data-index={index}
                onClick={this.showDiff}
              >
                <ListItemText
                  primary={this.formatDate(rev.updatedAt)}
                  secondary={rev.userName}
                />
              </ListItem>
            ))}
          </List>
        )}
        {selectedRev && revisions && revisions.list && (
          <div className={classes.revision}>
            <RevisionDiff rev={revisions.list[selectedRev]} />
            <div className={classes.button}>
              <ConfirmDialog
                message="Etes vous sûr de vouloir revenir à cette version."
                onConfirm={this.rollback}
              >
                <Button color="primary">Revenir à cette version</Button>
              </ConfirmDialog>
            </div>
          </div>
        )}
      </div>
    );
  }
}
