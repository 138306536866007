import { CircularProgress, Paper, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../common/components/Layout';
import ReturnButton from '../../../common/components/ReturnButton';
import { SimpleBreadcrumbs } from '../../../common/components/SimpleBreadcrumbs';
import TabsRoute from '../../../common/components/TabsRoute';
import { getProject } from '../../../common/services/project';
import DeleteButton from '../../../common/components/DeleteButton';
import { UserRestriction } from '../../../common/components/UserRestiction';
// import { UserForm } from '../../../common/components/UserForm.component';
import { IProduct } from '../typings';
import { IComponentProps } from './ProductDetail.connector';
import { ProductForm } from '../components/ProductForm';

export class ProductDetail extends React.Component<IComponentProps> {
  private menu = [{ label: 'app_info', link: '/' }];

  public componentDidMount() {
    this.fetchData();
  }

  public UNSAFE_componentWillReceiveProps(next: IComponentProps) {
    const { id } = this.props;

    if (next.id !== id) {
      this.fetchData(next.id);
    }
  }

  public render() {
    const {
      fetching,
      product,
      match,
      history,
      intl: { messages: m },
    } = this.props;

    if (fetching) {
      return <CircularProgress size={100} style={{ width: 100, margin: 'auto' }} />;
    }
    if (!product) {
      return <div>{m.error_noData}</div>;
    }

    return (
      <Layout
        id="product-layout"
        top={
          <SimpleBreadcrumbs
            crumbStack={[{ labelKey: 'app_products', href: `#${getProject().url}/products` }, { label: product.name }]}
          />
        }
        main={
          <Paper elevation={2}>
            <Toolbar>
              <Typography variant="h6">{product.name}</Typography>
              <div style={{ flex: 1 }} />
              <UserRestriction>
                <DeleteButton onRemove={this.handleDelete} />
              </UserRestriction>
              <ReturnButton entity="products" />
            </Toolbar>
            <TabsRoute match={match} menu={this.menu} history={history} />
            <Route exact path={match.url} render={this.renderForm} />
          </Paper>
        }
      />
    );
  }

  private renderForm = () => {
    const { product } = this.props;
    return <ProductForm onSubmit={this.handleUpdate} onDelete={this.handleDelete} data={product} />;
  };

  private fetchData = (targetId?: string) => {
    const { id, fetchProduct } = this.props;
    fetchProduct(targetId || id);
  };

  private handleUpdate = (product: IProduct) => {
    const { id, updateProduct } = this.props;
    return updateProduct(id, product);
  };

  private handleDelete = () => {
    const { deleteProduct, product } = this.props;
    // @ts-ignore
    return deleteProduct(product).then(history.push(`${getProject().url}/products`));
  };
}
