import List from '@material-ui/core/List';
import * as React from 'react';
import { ICommentary } from '../../../../commentaries/typings';
import { Commentary } from '../Commentary';
import { IComponentProps } from './Commentaries.connector';
import './webCss/_match_comments.css';
import './webCss/supplement.css';

export class Commentaries extends React.Component<IComponentProps> {
  public shouldComponentUpdate(nextProps: IComponentProps) {
    const { lastUpdated, commentaries, editingCommentaryId } = this.props;

    if (!commentaries !== !nextProps.commentaries) return true;
    if (
      lastUpdated !== nextProps.lastUpdated ||
      commentaries.length !== nextProps.commentaries.length ||
      editingCommentaryId !== nextProps.editingCommentaryId
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const { commentaries, editingCommentaryId } = this.props;

    if (!commentaries) return null;
    return (
      <List dense style={{ width: 652 }}>
        {commentaries.map((c: ICommentary, index: number) => (
          <Commentary
            isFirst={index === 0}
            key={c.id}
            commentary={c}
            isEditing={c.id === editingCommentaryId}
            isEditingMode={Boolean(editingCommentaryId)}
            onEdit={this.edit(c)}
            onRemove={this.remove(c)}
          />
        ))}
      </List>
    );
  }

  private edit = (commentary: ICommentary) => () => {
    const { callback, recoverLiveCommentary } = this.props;
    recoverLiveCommentary(commentary);
    callback();
  };

  private remove = (commentary: ICommentary) => () => {
    this.props.removeLiveCommentary(commentary);
  };
}
