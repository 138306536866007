export default function reducer(
  state = {
    list: [],
    search: [],
    detail: {},
  },
  action
) {
  switch (action.type) {
    case 'FETCH_BOOKMAKERS': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'FETCH_BOOKMAKERS_FULFILLED': {
      const { collection } = action.payload;

      return {
        ...state,
        list: collection,
      };
    }
    case 'FETCH_BOOKMAKERS_REJECTED': {
      return {
        ...state,
        error: action.payload ? action.payload.error : `[${action.type}] No response`,
      };
    }

    case 'FETCH_BOOKMAKER_FULFILLED': {
      const newDetail = { ...state.detail };
      newDetail[action.payload.id] = action.payload;
      return {
        ...state,
        detail: newDetail,
      };
    }

    default:
      return state;
  }
}
