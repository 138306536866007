import { Button } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { AdvancedBloc } from '../AdvancedBloc';
import { IComponentProps } from './ChangeMatchDate.connector';
import {getProject} from "../../../../../common/services/project";

export class ChangeMatchDate extends React.Component<IComponentProps> {
  public state = {
    dateTimeValue: undefined,
    isRequesting: false,
  };

  public componentDidMount() {
    const { match } = this.props;
    const date = this.dateFormat(match.detail[match.selectedId].date);
    this.setState({ dateTimeValue: date });
  }

  public render() {
    const {
      intl: { messages: m },
      mainCommentatorLocale,
    } = this.props;

    const { isRequesting, dateTimeValue } = this.state;

    return (
      <AdvancedBloc
        title={m.live_advanced_change_match_date_title}
        description={m.live_advanced_change_match_date_description}
      >
        <input
            type="datetime-local"
            value={dateTimeValue}
            onChange={this.handleChangeInputDateTime}
            disabled={mainCommentatorLocale != getProject().lang}
        />

        <Button
            color="primary"
            onClick={this.handleChangeDate}
            disabled={isRequesting || mainCommentatorLocale != getProject().lang}
        >
          {m.button_updateMatchDate}
        </Button>
      </AdvancedBloc>
    );
  }

  private dateFormat = date => {
    return moment(date).format('YYYY-MM-DD[T]HH:mm');
  };

  private handleChangeDate = () => {
    this.setState({ isRequesting: true });
    const { dateTimeValue } = this.state;
    const dateFormatApi = moment(dateTimeValue).format('YYYY-MM-DD[T]HH:mm:00+00:00');

    // @ts-ignore dispatch type error
    this.props.changeLiveMatchDate(dateFormatApi).then(() => this.setState({ isRequesting: false }));
  };

  private handleChangeInputDateTime = e => {
    const newDate = this.dateFormat(e.target.value);
    this.setState({ dateTimeValue: newDate });
  };
}
