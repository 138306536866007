import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  form: {
    flex: 1,
    padding: theme.spacing(0.5),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  leftButton: {
    marginRight: theme.spacing(1),
  },
  flatContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    background: '#f5f5f5',
  },
  fabContainer: {
    display: 'flex',
    flexDirection: 'row-reverse' as 'row-reverse',
    marginRight: theme.spacing(2),
  },
  fab: {
    position: 'fixed' as 'fixed',
    bottom: 16,
    zIndex: 15,
  },
  title: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  inputsContainer: {
    margin: 0,
    alignSelf: 'start',
  },
  imgContainer: {
    textAlign: 'center' as 'center',
    background: '#fafafa',
    width: '100%',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
