import { Avatar, Chip } from '@material-ui/core';
import React, { Component } from 'react';
import { IAuthor } from '../../../authors/typings';

interface IComponentProps {
  authors: IAuthor[];
}
export class ArticleAuthorsCell extends Component<IComponentProps> {
  public render() {
    const { authors } = this.props;
    const [first, second, ...rest] = authors;
    const chips = [first, second].map(a =>
      a ? (
        <Chip
          key={a.publicName}
          avatar={<Avatar>{a.publicName[0]}</Avatar>}
          label={a.publicName}
          style={{ margin: 4 }}
        />
      ) : null
    );
    if (rest.length) {
      chips.push(<Chip key={rest.length} label={`+${rest.length}`} style={{ margin: 4 }} />);
    }
    return chips;
  }
}
