import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function PenaltyIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M457 391l-402 0 0-225 111 0c10-12 40-45 88-45 46 0 77 32 89 45l114 0z m-91-15l0-72-211 0 0 72z m-112-240c-32 0-55 18-68 30l136 0c-14-13-37-30-68-30z m188 46l-372 0 0 194 70 0 0-87 241 0 0 87 61 0 0-194z m-178 60c0-4-4-8-8-8-5 0-8 4-8 8 0 5 3 8 8 8 4 0 8-3 8-8z"
        />
      </g>
    </SvgIcon>
  );
}
