import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import ConfirmDialog from 'Components/dialogs/Confirm';

import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

@injectIntl
export default class DeleteButton extends Component {
  render() {
    const {
      className,
      onRemove,
      disabled,
      intl: { messages: m },
    } = this.props;

    return (
      <ConfirmDialog message={m.dialog_deleteMessage} onConfirm={onRemove}>
        <Button
          variant="contained"
          color="secondary"
          className={className}
          style={{ marginRight: 8 }}
          disabled={disabled}
        >
          <DeleteIcon style={{ marginRight: 8 }} />
          {m.button_delete}
        </Button>
      </ConfirmDialog>
    );
  }
}
