import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { groupProductsBySeason } from '../../products/utils';
import { addProduct } from '../../products/actions';

import { UserRestriction } from '../../../common/components/UserRestiction';
import ProductsPanels from '../../products/Panels/index';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ContentAdd from '@material-ui/icons/Add';
import ProductDialog from '../../products/ProductDialog';

import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  icon: {
    marginRight: 8,
  },
  buttonLeft: {
    marginRight: 8,
  },
};

@withStyles(styles)
@injectIntl
@connect(store => ({
  updating: store.product.updating,
  posting: store.product.posting,
  deleting: store.product.deleting,
  products: store.products.list,
  fetching: store.products.fetching,
}))
export class Products extends Component {
  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (
      prevProps.posting !== nextProps.posting ||
      prevProps.updating !== nextProps.updating ||
      prevProps.deleting !== nextProps.deleting
    ) {
      nextProps.fetchData();
    }
  }

  handleProductAdd = values => {
    const { dispatch } = this.props;
    const product = {
      ...values,
    };
    return dispatch(addProduct(product));
  };

  renderPanelsBySeason = products =>
    Object.entries(groupProductsBySeason(products)).map(([key, value]) =>
      value.length ? (
        <div style={{ padding: 16 }}>
          <Typography variant="subtitle1">{key}</Typography>
          <ProductsPanels products={value} />
        </div>
      ) : null
    );

  render() {
    const {
      fetching,
      products,
      classes,
      intl: { messages: m },
    } = this.props;
    return (
      <div>
        <Toolbar>
          <Typography variant="h6">{m.app_products}</Typography>
          <div style={{ flex: 1 }} />
          <UserRestriction>
            <ProductDialog
              title={m.dialog_addProduct}
              form="product_add"
              onCreate={this.handleProductAdd}
            >
              <Button variant="contained" color="primary" className={classes.buttonLeft}>
                <ContentAdd className={classes.icon} />
                {m.button_add}
              </Button>
            </ProductDialog>
          </UserRestriction>
        </Toolbar>

        {fetching && <LinearProgress />}
        {this.renderPanelsBySeason(products)}
      </div>
    );
  }
}
