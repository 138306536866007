import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

@injectIntl
export default class FooterPagination extends Component {
  handleChangeRowsPerPage = event => {
    const params = {};
    const { onPaginate, defaultItemPerPage = 10 } = this.props;
    const value = event.target.value;

    if (value != defaultItemPerPage) {
      params.itemsPerPage = value;
    }

    onPaginate(params);
  };
  handleChangePage = (event, page) => {
    const params = {};
    const { onPaginate, pagination, defaultItemPerPage = 10 } = this.props;
    const items = pagination.itemsPerPage;

    params.page = page + 1;

    if (items != defaultItemPerPage) {
      params.itemsPerPage = items;
    }

    onPaginate(params);
  };

  render() {
    const {
      pagination,
      defaultItemPerPage = 10,
      total,
      intl: { messages: m },
    } = this.props;
    const displayedRow = ({ from, to, count }) => `${from}-${to} / ${count}`;
    if (!pagination || !pagination.current) return null;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            count={total}
            rowsPerPage={pagination.itemsPerPage || defaultItemPerPage}
            page={pagination.current.page - 1}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            labelRowsPerPage={m.pagination_rowsPerPage}
            labelDisplayedRows={displayedRow}
          />
        </TableRow>
      </TableFooter>
    );
  }
}
