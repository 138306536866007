import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LineupSurveyDetail } from './Detail';
import { LineupSurveyList } from './List';

export const LineupSurveysRoutes = ({ match }) => (
  <Switch>
    <Route exact path={match.url} component={LineupSurveyList} />
    <Route path={`${match.url}/:topicId`} component={LineupSurveyDetail} />
  </Switch>
);
