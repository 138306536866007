import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusProposalIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <g>
        <rect
          fill="#fff"
          x="1"
          y="1"
          width="22"
          height="22"
          stroke="#c8345d"
          strokeWidth="2"
        />
        <rect fill="#c8345d" x="6" y="11" width="12" height="2" />
      </g>
    </SvgIcon>
  );
}
