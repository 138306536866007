const validate = values => {
    const errors = { articleRelations: { } };
    if (values.articleRelations === undefined) {
        return errors;
    }
    if (values.articleRelations.filter(articleRelation => articleRelation.type === 'related').length > 1) {
        errors.articleRelations = { _error: 'message_articleRelationMaxRelatedError' };
    }
    if (values.articleRelations.filter(articleRelation => articleRelation.type === 'more_on').length > 2) {
        errors.articleRelations = { _error: 'message_articleRelationMaxMoreOnError' };
    }

    return errors;
};

export default validate;
