import rest from 'Services/restFootMercato';
import qs from 'query-string';

export default function search(query) {
  const params = {
    limit: 30,
    groups: ['read_link'],
    status: 'publish',
    'order[createdAt]': 'desc',
  };
  return function(dispatch) {
    dispatch({ type: 'FETCH_SEARCH_FM' });

    return rest
      .get(`search/${query}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_SEARCH_FM_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_SEARCH_FM_REJECTED', payload: err.response })
      );
  };
}

export function searchArticlesAdvanced(query) {
  const { title, fuzzy } = qs.parse(query);

  const params = {
    fuzzy: fuzzy ? 1 : 0,
    limit: 100,
    status: 'publish',
    groups: ['read_link'],
  };

  return function(dispatch) {
    dispatch({ type: 'FETCH_SEARCH_FM', payload: {} });

    if (!title) {
      return dispatch({ type: 'FETCH_SEARCH_FM_FULFILLED', payload: {} });
    }

    return rest
      .get(`search/${title}`, { params })
      .then(
        res => dispatch({ type: 'FETCH_SEARCH_FM_FULFILLED', payload: res.data }),
        err => dispatch({ type: 'FETCH_SEARCH_FM_REJECTED', payload: err.response })
      );
  };
}

export function orderBy(query, data) {
  const dataToArray = Object.values(data);
  const queryParsed = qs.parse(query);
  const directionSort = queryParsed['order[publishedAt]'];

  return function(dispatch) {
    const data = dataToArray.sort((a, b) =>
      !directionSort || directionSort === 'asc'
        ? new Date(b.publishedAt) - new Date(a.publishedAt)
        : new Date(a.publishedAt) - new Date(b.publishedAt)
    );
    return dispatch({ type: 'FETCH_SEARCH_FM_FULFILLED', payload: data });
  };
}
