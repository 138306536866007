import {
  createDetailReducer,
  createListReducer,
} from '../../common/services/reducerCreator';

export const broadcastsReducer = createListReducer('BROADCASTS');
export const broadcastReducer = createDetailReducer({
  modelName: 'BROADCAST',
  inside: {
    match: { get: 'broadcasts' },
  },
});
