import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export function InjuryIcon({ props }: { props?: any }) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <path
          transform="matrix(1 0 0 -1 0 512)"
          d="M386 256l64 63c4 5 7 11 7 18 0 7-3 13-7 18l-95 95c-4 4-11 7-18 7-6 0-13-3-18-7l-63-64-64 64c-4 4-11 7-18 7-6 0-13-3-18-7l-94-95c-9-9-9-26 0-35l64-64-64-63c-4-5-7-11-7-18 0-7 3-13 7-18l95-95c4-4 11-7 18-7 6 0 13 3 18 7l63 64 63-64c5-5 12-7 18-7 7 0 13 2 18 7l94 95c5 4 8 11 8 18 0 6-3 13-8 18z m-54 181c3 4 9 4 12 0l94-94c2-1 2-3 2-6 0-2-1-4-2-6l-64-63-106 106z m-151-363c-4-3-9-3-12 0l-94 94c-4 4-4 9 0 12l63 64 106-106z m257 94l-94-94c-4-3-9-3-12 0l-257 257c-4 4-4 9 0 12l94 94c1 2 3 3 6 3 2 0 4-1 6-3l257-257c2-1 2-4 2-6 0-2 0-4-2-6z m-166 163c-8 9-23 9-31 0l-60-60c-9-8-9-23 0-31l59-59c4-5 10-7 16-7 6 0 11 2 15 7l61 60c8 8 8 23 0 31z m52-83l-60-60c-4-5-12-5-17 0l-59 59c-5 5-5 13 0 17l60 61c2 2 5 3 9 3 3 0 6-1 8-3l60-60c4-5 4-13-1-17z m-106 9c0 2-2 5-4 5-3 0-5-3-5-5 0-3 2-5 5-5 2 0 4 2 4 5z m21 20c0 3-2 5-5 5-2 0-4-2-4-5 0-2 2-4 4-4 3 0 5 2 5 4z m21 22c0 2-2 4-5 4-3 0-5-2-5-4 0-3 2-5 5-5 3 0 5 2 5 5z m-20-64c0 3-2 5-5 5-2 0-4-2-4-5 0-2 2-5 4-5 3 0 5 3 5 5z m21 21c0 3-2 5-5 5-2 0-5-2-5-5 0-3 3-5 5-5 3 0 5 2 5 5z m21 21c0 2-2 4-5 4-3 0-5-2-5-4 0-3 2-5 5-5 3 0 5 2 5 5z m-20-64c0 3-2 5-5 5-3 0-5-2-5-5 0-2 2-4 5-4 3 0 5 2 5 4z m20 21c0 3-2 5-5 5-2 0-4-2-4-5 0-2 2-4 4-4 3 0 5 2 5 4z m21 21c0 3-2 5-5 5-2 0-4-2-4-5 0-2 2-5 4-5 3 0 5 3 5 5z"
        />
      </g>
    </SvgIcon>
  );
}
