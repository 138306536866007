import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { IArticle } from '../../../articles/typings';
import { IComponentProps } from './BriefPanel.connector';
import BriefForm from '../../liveTransfers/Detail/Form';
import { ArticleStatusIcon } from '../../../articles/components/ArticleStatusIcon';
import moment from 'moment';

export class BriefPanel extends Component<IComponentProps> {
  private handleExpand = (_, expanded: boolean) => {
    const { onExpend, brief } = this.props;
    onExpend(brief.id, expanded);
  };

  private handleChange = (data: Partial<IArticle>) => {
    const { updateBrief, brief } = this.props;
    return updateBrief(brief.id, data);
  };

  public render() {
    const { classes, brief, isExpanded } = this.props;
    if (!brief) return null;

    return (
      <ExpansionPanel expanded={isExpanded} onChange={this.handleExpand} classes={{ root: classes.panel }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <ArticleStatusIcon status={brief.status} />
          <Typography style={{ marginLeft: 8, marginRight: 8, flex: 1 }}>{brief.title}</Typography>
          {brief.publishedAt ? (
            <Typography>{moment(brief.publishedAt).format('DD[/]MM[/]YYYY HH[:]mm[:]ss')}</Typography>
          ) : null}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detail }}>
          {isExpanded && <BriefForm onSubmit={this.handleChange} data={brief} />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
