import { StyledComponentProps } from '@material-ui/core';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { IRootState } from '../../../../../typings';
import { resetLive } from '../../actions';
import { createLiveCommentary, updateLiveCommentary } from '../../actions/liveActions';
import { isActionEnable } from '../../selector';

const mapDispatchToProps = {
  resetLive,
  createLiveCommentary,
  updateLiveCommentary,
};

const mapStateToProps = (state: IRootState) => {
  return {
    editingCommentaryId: state.live.editingCommentaryId,
    isSubmitButtonEnable: state.live.commentary.isTypeCompleted && isActionEnable(state),
  };
};

export type IComponentProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  InjectedIntlProps &
  StyledComponentProps;

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export const connector = compose<IComponentProps, {}>(
  reduxConnector,
  injectIntl
);
